import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || '',
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL || '',
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.VUE_APP_FIREBASE_APP_ID || '',
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID || ''
};

let firebaseApp = null;

export const initFirebase = () => {
    if (!firebaseApp && process.env.NODE_ENV === 'production' && firebaseConfig.apiKey !== '') {
        firebaseApp = firebase.initializeApp(firebaseConfig);
        firebaseApp.analytics();
    }
};

export const getFirebaseApp = () => {
    return firebaseApp;
};

export const GALogEvent = (eventName, eventParams) => {
    if (firebaseApp) {
        firebaseApp.analytics().logEvent(eventName, eventParams);
    }
};
