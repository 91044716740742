export const GET_MAP = 'GET_MAP';
export const SET_MAP = 'SET_MAP';
export const UPDATE_MAP_FIELD = 'UPDATE_MAP_FIELD';
export const PUBLISH_MAP = 'PUBLISH_MAP';
export const SET_PUBLISHED = 'SET_PUBLISHED';
export const RENAME_MAP = 'RENAME_MAP';
export const DELETE_MAP = 'DELETE_MAP';
export const APPROVE_MAP = 'APPROVE_MAP';
export const DISAPPROVE_MAP = 'DISAPPROVE_MAP';
export const SET_MAP_APPROVALS = 'SET_MAP_APPROVALS';
export const SET_MAP_APPROVAL = 'SET_MAP_APPROVAL';
