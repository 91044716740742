import http from '@http';

class ApplicationService {
    constructor() {
        if (ApplicationService.instance) {
            return ApplicationService.instance;
        }

        ApplicationService.instance = this;
    }

    userApplications() {
        return http.get('/api/common/applications').then(res => res.data);
    }

    store(data) {
        return http.post('/api/common/applications/store', data).then(res => res.data);
    }

    update(data) {
        return http.put(`/api/common/applications/${data.id}/update`, data).then(res => res.data);
    }

    kindergartenApplications(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/applications`, {
                params: queryObj
            })
            .then(res => res.data);
    }

    show(kindergartenId, applicationId) {
        return http.get(`/api/kindergartens/${kindergartenId}/applications/${applicationId}/show`)
            .then(res => res.data);
    }

    reject(kindergartenId, applicationId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/applications/${applicationId}/reject`, data)
            .then(res => res.data);
    }

    confirm(kindergartenId, applicationId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/applications/${applicationId}/confirm`, data)
            .then(res => res.data);
    }

    addRemoveChild(kindergartenId, applicationId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/applications/${applicationId}/add-remove-child`, data)
            .then(res => res.data);
    }
}

export default new ApplicationService();
