<template>
    <div>
        <div>
            <span v-if="showKindergarten"> ({{ notification.kindergarten.name}}) </span>
            <span v-if="type === 'new'">
                {{$t('Parent')}} {{notification.creator.name}} {{$t('Notification - new application with name')}}
                <span class="float-right">{{ $t('Status') }}: <span :class="statusColor(data.status)" class="font-weight-bold">{{$t(data.status)}}</span></span>
            </span>
            <div>
                <span>{{$t('Child name')}}: </span>
                <span class="font-weight-bold">
                    <router-link :to="{name: 'applications.view-application', params: {applicationId: data.id}}">
                        {{data.child_name}}
                    </router-link>
                </span>
            </div>
            <div class="text-black-50">
                {{ formatDateTime(notification.created_at) }}
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {formatDateTime} from '@/filters';

export default {
    name: 'ApplicationNotification',
    props: {
        notification: Object
    },
    data() {
        return {
            applicationStatuses: {
                pending: 'Pending',
                confirmed: 'Confirmed',
                cancelled: 'Cancelled',
                rejected: 'Rejected'
            },
        };
    },
    computed: {
        ...mapGetters(['userKindergartens']),
        data() {
            return this.notification.data;
        },
        type() {
            return this.data?.type;
        },
        showKindergarten() {
            return this.notification.kindergarten && this.userKindergartens.length > 1;
        }
    },
    methods: {
        formatDateTime, // TODO: Vue3 remove
        statusColor(status) {
            if(status === this.applicationStatuses.pending) {
                return 'text-main';
            } else if(status === this.applicationStatuses.confirmed) {
                return 'text-success';
            } else if(status === this.applicationStatuses.cancelled || status === this.applicationStatuses.rejected) {
                return 'text-danger';
            } else {
                return 'text-muted';
            }
        },
    }
};
</script>
