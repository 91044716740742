import GalleryIndex from '@views/gallery/views/GalleryIndex';
import GalleryFolder from '@views/gallery/folder/GalleryFolder';
import GalleryChildProfileFolder from '@views/gallery/folder/GalleryChildProfileFolder';
import GalleryDiaryIndex from '@views/gallery/views/GalleryDiaryIndex';
import GalleryDiaryFolder from '@views/gallery/views/GalleryDiaryFolder';
import store from '@/store';

export default [
    {
        path: '/gallery',
        name: 'gallery.index',
        components: {base: GalleryIndex},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessGalleryIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        }
    },
    {
        path: '/gallery/child-profile/:child_id?',
        name: 'gallery.builtin.child-profile',
        components: {base: GalleryChildProfileFolder},
        beforeEnter: (to, from, next) => {
            const canAccessBuiltin =
                store.getters.canAccessChildFilesEmployee ||
                store.getters.canAccessChildFilesGuardian;
            if (store.getters.canAccessGalleryIndex && canAccessBuiltin) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        }
    },
    {
        path: '/gallery/diary',
        name: 'gallery.builtin.diary.index',
        components: {base: GalleryDiaryIndex},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessGalleryIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        }
    },
    {
        path: '/gallery/diary/:course_id',
        name: 'gallery.builtin.diary',
        components: {base: GalleryDiaryFolder},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessGalleryIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        }
    },
    {
        path: '/gallery/:folder',
        name: 'gallery.folder',
        components: {base: GalleryFolder},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessGalleryIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        }
    },
];


