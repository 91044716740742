import store from '@/store';

// const Shop = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/Shop');z
// const AllProductsAndServices = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/index');

const ShopIndex = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/index.vue');
const ShopLanding = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/ShopLanding');

const ProductCategory = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Items/ProductCategory');
const ProductSubcategory = () => import(/* webpackChunkName: "shop-module" */ '@/views/shop/views/Items/views/product/ProductSubcategory');
const Products = () => import(/* webpackChunkName: "shop-module" */ '@/views/shop/views/Items/views/product/Products');
const Product = () => import(/* webpackChunkName: "shop-module" */ '@/views/shop/views/Items/views/product/Product');

const ServiceCategory = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Items/ServiceCategory');
const ServiceSubcategory = () => import(/* webpackChunkName: "shop-module" */ '@/views/shop/views/Items/views/service/ServiceSubcategory');
const Services = () => import(/* webpackChunkName: "shop-module" */ '@/views/shop/views/Items/views/service/Services');
const Service = () => import(/* webpackChunkName: "shop-module" */ '@/views/shop/views/Items/views/service/Service');

const Wishlist = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Wishlist');
const WishlistPending = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Wishlist/views/Wishlist');
const WishlistOrdered = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Wishlist/views/Wishlist');
const WishlistDeleted = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Wishlist/views/Wishlist');

const AllOrders = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Orders');
const PersonalOrder = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Orders/views/PersonalOrder');
const OrdersKindergarten = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Orders/views/Kindergarten');
const OrdersKindergartenOrder = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Orders/views/KindergartenOrder');
const OrdersPersonal = () => import(/* webpackChunkName: "shop-module" */ '@views/shop/views/Orders/views/Personal');

const MyCart = () => import(/* webpackChunkName: "shop-module" */ '@/views/shop/views/Cart/MyCart.vue');

const Supplier = () => import(/* webpackChunkName: "shop-module" */ '@/views/shop/views/Supplier/Supplier.vue');
const SupplierProducts = () => import(/* webpackChunkName: "shop-module" */ '@/views/shop/views/Supplier/Products.vue');
const SupplierServices = () => import(/* webpackChunkName: "shop-module" */ '@/views/shop/views/Supplier/Services.vue');

export default [
    {
        path: '/shop',
        components: {base: ShopIndex},
        children: [
            {
                path: '',
                name: 'shop.main',
                component: ShopLanding,
            },
            {
                path:'supplier/:supplier_name',
                name: 'shop.supplier',
                component: Supplier,
            },
            {
                path:'supplier/:supplier_name/products',
                name: 'shop.supplier.products',
                component: SupplierProducts,
            },
            {
                path:'supplier/:supplier_name/services',
                name: 'shop.supplier.services',
                component: SupplierServices,
            },
            {
                path: 'product',
                name: 'shop.product.main',
                component: ProductCategory,
            },
            {
                path: 'products/:category_slug',
                name: 'shop.product.category',
                component: ProductSubcategory,
            },

            {
                path: 'products/:category_slug/:subcategory_slug',
                name: 'shop.product.subcategory',
                component: Products,
            },
            {
                path: 'product/:category_slug/:subcategory_slug?/:item_slug',
                name: 'shop.product',
                component: Product,
            },

            {
                path: 'service',
                name: 'shop.service.main',
                component: ServiceCategory,
            },
            {
                path: 'services/:category_slug',
                name: 'shop.service.category',
                component: ServiceSubcategory,
            },

            {
                path: 'services/:category_slug/:subcategory_slug',
                name: 'shop.service.subcategory',
                component: Services,
            },
            {
                path: 'service/:category_slug/:subcategory_slug?/:item_slug',
                name: 'shop.service',
                component: Service,
            },

            {
                path: 'cart',
                component: MyCart,
                name: 'shop.cart'
            },

            {
                path: 'wishlist',
                component: Wishlist,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAddToWishlist) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
                children: [
                    {
                        path: 'pending',
                        meta: {type: 'pending'},
                        name: 'shop.wishlist',
                        component: WishlistPending,
                    },
                    {
                        path: 'ordered',
                        meta: {type: 'ordered'},
                        name: 'shop.wishlist.ordered',
                        component: WishlistOrdered,
                    },
                    {
                        path: 'deleted',
                        meta: {type: 'deleted'},
                        name: 'shop.wishlist.deleted',
                        component: WishlistDeleted,
                    },
                ]
            },
            {
                path: 'orders',
                component: AllOrders,
                children: [
                    {
                        name: 'shop.orders.kindergarten',
                        path: 'kindergarten',
                        components: { orders: OrdersKindergarten },
                        beforeEnter: (to, from, next) => {
                            if (store.getters.canAddToShopOrder) {
                                next();
                            } else {
                                next({ path: '/access-restricted' });
                            }
                        },
                    },
                    {
                        name: 'shop.orders.kindergarten.order',
                        path: 'kindergarten/order/:order_id',
                        components: { orders: OrdersKindergartenOrder },
                        beforeEnter: (to, from, next) => {
                            if (store.getters.canAddToShopOrder) {
                                next();
                            } else {
                                next({ path: '/access-restricted' });
                            }
                        },
                    },
                    {
                        name: 'shop.orders.personal',
                        path: 'personal',
                        components: { orders: OrdersPersonal },
                    },
                    {
                        name: 'shop.orders.personal.order',
                        path: 'personal/order/:order_id',
                        components: { orders: PersonalOrder },
                    },
                ]
            },
        ]
    },
];
