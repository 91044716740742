<template>
    <div class="eliis-bootstrap-modal" :style="{position: 'absolute', zIndex: zIndex}">
        <transition name="fade" @after-leave="backdropTransitionEnd">
            <div
                v-if="isVisible"
                class="modal-backdrop"
                @click.self="handleBackdropClick"></div>
        </transition>
        <transition name="slide-down">
            <div
                v-if="isVisible"
                :id="id"
                class="modal"
                :class="{ show: isVisible }"
                tabindex="-1"
                role="dialog"
                style="display: block;"
                @click.self="handleBackdropClick">
                <div class="modal-dialog" :class="dialogClasses" role="document">
                    <div class="modal-content">
                        <div v-if="!hideHeader" class="modal-header" :class="headerClass">
                            <slot name="modal-header">
                                <div class="d-flex align-items-center w-100">
                                    <h5 class="m-0 pr-3 mr-auto">{{ title }}</h5>
                                    <RoundIconButton type="bg-white" @click="close()">
                                        <SvgIcon icon="mdi-close-circle" size="27" class="text-danger"/>
                                    </RoundIconButton>
                                </div>
                            </slot>
                        </div>
                        <div class="modal-body" :class="bodyClass">
                            <slot></slot>
                        </div>
                        <div v-if="!hideFooter" name="modal-footer" class="modal-footer" :class="footerClass">
                            <slot name="modal-footer">
                                <button type="button" class="btn btn-secondary" @click="close">{{ $t('Close') }}</button>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import EventBus from '@/utils/eventBus';

export default {
    name: 'BootstrapModal',
    props: {
        id: {
            type: String,
            default: 'bootstrap-modal'
        },
        title: {
            type: String,
            default: 'Modal Title'
        },
        noCloseOnBackdrop: {
            type: Boolean,
            default: false
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: false
        },
        centered: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: ''
        },
        headerClass: {
            type: String,
            default: ''
        },
        bodyClass: {
            type: String,
            default: ''
        },
        footerClass: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            isVisible: false,
            modalOpenCount: 0,
            modalPadding: 15,
        };
    },
    computed: {
        dialogClasses() {
            const dialogClasses = [];

            if (this.size !== '') {
                dialogClasses.push(`modal-${this.size}`);
            }

            if (this.scrollable) {
                dialogClasses.push('modal-dialog-scrollable');
            }

            if (this.centered) {
                dialogClasses.push('modal-dialog-centered');
            }

            return dialogClasses;
        },
        zIndex() {
            return 1039 + this.modalOpenCount;
        },
    },
    methods: {
        open() {
            this.isVisible = true;
            this.incrementModalCount();
            document.addEventListener('keydown', this.onEscape);
            this.$emit('open');
            this.$emit('shown');
        },
        close() {
            this.isVisible = false;
            document.removeEventListener('keydown', this.onEscape);
            this.decrementModalCount();
            this.$emit('close');
            this.$emit('hidden');
        },
        confirm() {
            this.$emit('confirm');
        },
        handleBackdropClick() {
            if (!this.noCloseOnBackdrop) {
                this.close();
            }
        },
        updateModalCount() {
            const modalAttrCount = document.body.getAttribute('data-modal-open-count');
    
            if (modalAttrCount) {
                this.modalOpenCount = parseInt(modalAttrCount, 10);
            }
        },
        incrementModalCount() {
            this.updateModalCount();
            this.modalOpenCount++;
            document.body.setAttribute('data-modal-open-count', this.modalOpenCount);

            if (this.modalOpenCount === 1) {
                this.setModalPadding();
            }
        },
        decrementModalCount() {
            if (this.modalOpenCount > 0) {
                this.modalOpenCount--;
                document.body.setAttribute('data-modal-open-count', this.modalOpenCount);
            }
        },
        getModalPadding() {
            const scrollbarWidth = document.body.getAttribute('data-scrollbar-width');

            if (scrollbarWidth) {
                this.modalPadding = parseInt(scrollbarWidth, 10);
            }
        },
        isTopModal() {
            const modalElements = document.querySelectorAll('.eliis-bootstrap-modal');
            let largestZIndex = 0;

            modalElements.forEach(el => {
                const zIndex = parseInt(el.style.zIndex, 10);
                if (zIndex > largestZIndex) {
                    largestZIndex = zIndex;
                }
            });

            return largestZIndex === this.zIndex;
        },
        setModalPadding() {
            document.body.classList.add('modal-open');
            document.body.style.paddingRight = `${this.modalPadding}px`;
        },
        removeModalPadding() {
            document.body.style.removeProperty('padding-right');
            document.body.classList.remove('modal-open');
        },
        backdropTransitionEnd() {
            if (this.modalOpenCount === 0) {
                this.removeModalPadding();
            }
        },
        onEscape(event) {
            if (event.key === 'Escape' && this.isTopModal() && !this.noCloseOnBackdrop) {
                this.close();
            }
        },
        showModal(id) {
            if (id === this.id && !this.isVisible) {
                this.open();
            }
        },
        hideModal(id) {
            if (id === this.id) {
                this.close();
            }
        }
    },
    mounted() {
        EventBus.on('show-modal', this.showModal);
        EventBus.on('hide-modal', this.hideModal);
        this.getModalPadding();
        this.updateModalCount();
    },
    beforeDestroy() {  // Vue 3 Rename beforeUnmount
        if (this.visible) {
            this.decrementModalCount();
        }
        EventBus.off('show-modal', this.showModal);
        EventBus.off('hide-modal', this.hideModal);
    }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.slide-down-enter-active, .slide-down-leave-active {
    transition: transform 0.4s ease, opacity 0.4s ease;
}
.slide-down-enter, .slide-down-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}
</style>
