import {
    DEACTIVATE_SUPPLIER,
    AGREE_SUPPLIER_TERMS,
    RESET_SUPPLIER_STATE,
    SET_SUPPLIER,
    SET_SUPPLIER_AGREED_AT,
    UPDATE_SUPPLIER,
    ACTIVATE_SUPPLIER,
    SAVE_SUPPLIER_SETTING,
    ADD_SUPPLIER_SETTING,
    SET_SUPPLIER_LOGO,
    GET_SHOP_ITEM_FILTERS,
    SET_SHOP_ITEM_FILTERS,
    GET_SUPPLIER_ORDERS,
    SET_SUPPLIER_ORDERS,
    DELETE_SUPPLIER_COUNTRY,
    SET_SUPPLIER_COUNTRIES,
    GET_SUPPLIER_SETTINGS,
    SET_SUPPLIER_SETTINGS,
    GET_SUPPLIER_CATEGORIES,
    SET_SUPPLIER_CATEGORIES,
    GET_SUPPLIER_PRODUCT_CATEGORIES,
    SET_SUPPLIER_PRODUCT_CATEGORIES,
    GET_SUPPLIER_SERVICE_CATEGORIES,
    SET_SUPPLIER_SERVICE_CATEGORIES,
    GET_AVAILABLE_SUPPLIER_INTEGRATION_CLIENTS,
    SET_AVAILABLE_SUPPLIER_INTEGRATION_CLIENTS,
    GET_SUPPLIER_INTEGRATION_CLIENTS,
    SET_SUPPLIER_INTEGRATION_CLIENTS,
    STORE_SUPPLIER_INTEGRATION_CLIENT,
    ADD_SUPPLIER_INTEGRATION_CLIENT,
    UPDATE_SUPPLIER_INTEGRATION_CLIENT,
    SET_SUPPLIER_INTEGRATION_CLIENT,
    GET_SUPPLIER_INTEGRATION,
    SET_SELECTED_SUPPLIER_INTEGRATION, SET_SUPPLIER_INTEGRATION_PRICE_LISTS,
} from './actions';
import {pricelistActions, pricelistGetters, pricelistMutations, pricelistState} from './price_lists';
import {productActions, productGetters, productMutations, productState} from './products';
import {serviceActions, serviceGetters, serviceMutations, serviceState} from './services';
import {supplierIntegrationActions, supplierIntegrationGetters, supplierIntegrationMutations, supplierIntegrationState} from './integration';
import SupplierIntegrationService from '@/common/services/supplier_integration.service';
import SupplierService from '@services/supplier.service';
import UserService from '@services/user.service';
import {vm} from '@/main';
import Vue from 'vue';

const getDefaultState = () => {
    return {
        ...pricelistState,
        ...productState,
        ...serviceState,
        ...supplierIntegrationState,
        supplier: {},
        filters: [],
        supplier_account: null,
        managed_suppliers: [],
        orders: {},
        supplierProductCategories: [],
        supplierServiceCategories: [],
        shop_target_countries: [
            {id: 63, name: 'Estonia', code: 'ee'},
            {id: 134, name: 'Latvia', code: 'lv'},
            {id: 132, name: 'Lithuania', code: 'lt'},
            // {id: 178, name: 'Poland', code: 'pl'},
        ],
        availableIntegrationClients: [],
        integrations: [],
        selectedSupplierIntegration: {}
    };
};

const state = getDefaultState();

const mutations = {
    ...pricelistMutations,
    ...productMutations,
    ...serviceMutations,
    ...supplierIntegrationMutations,
    [RESET_SUPPLIER_STATE](state) {
        Object.assign(state, getDefaultState());
        localStorage.removeItem('supplier_id');
    },
    [SET_SUPPLIER](state, data) {
        state.supplier_account = data;
        state.supplier = data;
        localStorage.setItem('supplier_id', data.id);
        localStorage.removeItem('organization_id');
        localStorage.removeItem('kindergarten_id');
    },
    [SET_SUPPLIER_AGREED_AT](state, data) {
        state.supplier.agreed_at = data;
    },
    [ADD_SUPPLIER_SETTING](state, data) {
        state.supplier.settings.push(data);
    },
    [SET_SUPPLIER_LOGO](state, data) {
        state.supplier = {...state.supplier, logo: data};
    },
    [SET_SHOP_ITEM_FILTERS] (state, data) {
        state.filters = data;
    },
    [SET_SUPPLIER_ORDERS] (state, data) {
        state.orders = data;
    },
    [SET_SUPPLIER_COUNTRIES] (state, data) {
        state.supplier.settings = data;
    },
    [SET_SUPPLIER_SETTINGS] (state, data) {
        state.supplier = data;
    },
    [SET_SUPPLIER_CATEGORIES] (state, data) {
        state.supplierProductCategories = data.filter(c => c.type === 'product');
        state.supplierServiceCategories = data.filter(c => c.type === 'service');
    },
    [SET_SUPPLIER_PRODUCT_CATEGORIES] (state, data) {
        state.supplierProductCategories = data;
    },
    [SET_SUPPLIER_SERVICE_CATEGORIES] (state, data) {
        state.supplierServiceCategories = data;
    },
    [SET_AVAILABLE_SUPPLIER_INTEGRATION_CLIENTS] (state, data) {
        state.availableIntegrationClients = data;
    },
    [SET_SUPPLIER_INTEGRATION_CLIENTS] (state, data) {
        state.integrations = data;
    },
    [ADD_SUPPLIER_INTEGRATION_CLIENT](state, data) {
        state.integrations.push(data);
    },
    [SET_SUPPLIER_INTEGRATION_CLIENT] (state, data) {
        let index = state.integrations.findIndex(i => i.id === data.id);
        if(index !== -1) {
            Vue.set(state.integrations, index, data);
        }
    },
    [SET_SELECTED_SUPPLIER_INTEGRATION] (state, data) {
        state.selectedSupplierIntegration = data;
    }
};
const actions = {
    ...pricelistActions,
    ...productActions,
    ...serviceActions,
    ...supplierIntegrationActions,
    [ACTIVATE_SUPPLIER]({commit}, data) {
        return UserService.activateSupplier(data)
        .then(res => {
            commit('SET_USER', res.user);
            commit('SET_USER_SUPPLIERS', res.suppliers);
            commit('SET_USER_SUPPLIER_REPS', res.supplier_reps);
            commit(SET_SUPPLIER, res.supplier);
            commit('RESET_ORGANIZATION_STATE');
            commit('RESET_OFFICIAL_STATE');
            commit('RESET_KINDERGARTEN_STATE');
            commit('RESET_EMPLOYEE_STATE');
            commit('RESET_CDM_STORE');
        })
        .catch(err => {
            throw err;
        });
    },
    [DEACTIVATE_SUPPLIER]({commit}, supplierId) {
        SupplierService.deactivateSupplier(supplierId).then(res => {
            // commit('SET_USER', res);
            commit(SET_SUPPLIER, res);
            // dispatch('GET_USER_MENU');
        });
    },
    [AGREE_SUPPLIER_TERMS]({commit, dispatch}, supplierId) {
        SupplierService.agreeTerms(supplierId).then((res) => {
            commit('SET_SUPPLIER_AGREED_AT', res);
            dispatch('GET_PERMISSIONS').then(() => {
                vm.$router.push({name: 'supplier.settings', params: {supplier_id: supplierId}});
            });
            dispatch('GET_USER_MENU');
        });
    },
    [UPDATE_SUPPLIER]({commit}, form) {
        return SupplierService.update(form.id, form).then(res => {
            commit(SET_SUPPLIER, res);
            return res;
        });
    },
    [SAVE_SUPPLIER_SETTING]({commit}, {supplierId, form}) {
        return SupplierService.addCountry(supplierId, form).then(res => {
            commit(ADD_SUPPLIER_SETTING, res);
        });
    },
    [GET_SHOP_ITEM_FILTERS]({commit, getters}, id) {
        if (getters.supplierFilters.length) {
            return;
        }
        return SupplierService.filters(id).then(res => {
            commit(SET_SHOP_ITEM_FILTERS, res);
        });
    },
    [GET_SUPPLIER_ORDERS]({commit, getters}, queryObj) {
        return SupplierService.orders(getters.activeSupplierId, queryObj).then(res => {
            commit(SET_SUPPLIER_ORDERS, res);
        });
    },
    [GET_SUPPLIER_SETTINGS]({commit, getters}) {
        commit('START_CONTENT_LOADER');
        return SupplierService.getSupplierSettings(getters.activeSupplierId).then(res => {
            commit(SET_SUPPLIER_SETTINGS, res);
        }).finally(() => {
            commit('STOP_CONTENT_LOADER');
        });
    },
    [DELETE_SUPPLIER_COUNTRY]({commit, getters}, countryId) {
        return SupplierService.removeCountry(getters.activeSupplierId, countryId).then(res => {
            commit(SET_SUPPLIER_COUNTRIES, res);
            return res;
        });
    },
    [GET_SUPPLIER_CATEGORIES]({commit, getters}) {
        return SupplierService.categories(getters.activeSupplierId).then(res => {
            commit(SET_SUPPLIER_CATEGORIES, res);
        });
    },
    [GET_SUPPLIER_PRODUCT_CATEGORIES]({commit, getters}) {
        if (getters.supplierProductCategories.length) {
            return getters.supplierProductCategories;
        }
        return SupplierService.productCategories(getters.activeSupplierId).then(res => {
            commit(SET_SUPPLIER_PRODUCT_CATEGORIES, res);
            return res;
        });
    },
    [GET_SUPPLIER_SERVICE_CATEGORIES]({commit, getters}) {
        if (getters.supplierServiceCategories.length) {
            return getters.supplierServiceCategories;
        }
        return SupplierService.serviceCategories(getters.activeSupplierId).then(res => {
            commit(SET_SUPPLIER_SERVICE_CATEGORIES, res);
            return res;
        });
    },
    [GET_AVAILABLE_SUPPLIER_INTEGRATION_CLIENTS]({commit, getters}) {
        return SupplierIntegrationService.availableIntegrationClients(getters.activeSupplierId).then(res => {
            commit(SET_AVAILABLE_SUPPLIER_INTEGRATION_CLIENTS, res);
        });
    },
    [GET_SUPPLIER_INTEGRATION_CLIENTS]({commit, getters}) {
        return SupplierIntegrationService.supplierIntegrationClients(getters.activeSupplierId).then(res => {
            commit(SET_SUPPLIER_INTEGRATION_CLIENTS, res.integrations);
            commit(SET_AVAILABLE_SUPPLIER_INTEGRATION_CLIENTS, res.available);
        });
    },
    [GET_SUPPLIER_INTEGRATION]({commit, getters}, integrationId) {
        commit(SET_SUPPLIER_INTEGRATION_PRICE_LISTS, []);
      return SupplierIntegrationService.supplierIntegrationClient(getters.activeSupplierId, integrationId).then(res => {
          commit(SET_SELECTED_SUPPLIER_INTEGRATION, res);
      });
    },
    [STORE_SUPPLIER_INTEGRATION_CLIENT]({commit, getters}, data) {
        return SupplierIntegrationService.addSupplierIntegrationClient(getters.activeSupplierId, data).then(res => {
            commit(ADD_SUPPLIER_INTEGRATION_CLIENT, res);
            return res;
        });
    },
    [UPDATE_SUPPLIER_INTEGRATION_CLIENT]({commit, getters}, data) {
        return SupplierIntegrationService.updateSupplierIntegrationClient(getters.activeSupplierId, data).then(res => {
            commit(SET_SELECTED_SUPPLIER_INTEGRATION, res);
        });
    }
};
const getters = {
    ...pricelistGetters,
    ...productGetters,
    ...serviceGetters,
    ...supplierIntegrationGetters,
    supplierAccount: state => state.supplier_account,
    managedSuppliers: state => state.managed_suppliers,
    selectedSupplier: state => state.supplier,
    activeSupplierId: state => state.supplier?.id,
    supplierAgreedAt: state => state.supplier?.agreed_at,
    shopTargetCountries: state => state.shop_target_countries,
    supplierName: state => state.supplier?.name,
    supplierFilters: state => state.filters,
    supplierProductFilters: state => state.filters.filter(f => f.type === 'product' || f.type === 'all'),
    supplierServiceFilters: state => state.filters.filter(f => f.type === 'service' || f.type === 'all'),
    supplierOrders : state => state.orders,
    supplierProductCategories: state => state.supplierProductCategories,
    supplierServiceCategories: state => state.supplierServiceCategories,
    supplierAvailableIntegrationClients: state => state.availableIntegrationClients,
    supplierIntegrations: state => state.integrations,
    selectedSupplierIntegration: state => state.selectedSupplierIntegration
};

export default {
    state,
    mutations,
    actions,
    getters
};
