export const DELETE_IDEA = 'DELETE_IDEA';
export const DELETE_IDEA_COMMENT = 'DELETE_IDEA_COMMENT';
export const DELETE_IDEA_IMAGE = 'DELETE_IDEA_IMAGE';

export const GET_IDEA_COMMENTS = 'GET_IDEA_COMMENTS';
export const GET_IDEA = 'GET_IDEA';
export const GET_IDEAS = 'GET_IDEAS';
export const GET_IDEAS_FROM_AUTHOR = 'GET_IDEAS_FROM_AUTHOR';

export const GET_IDEA_AGE_GROUPS = 'GET_IDEA_AGE_GROUPS';
export const GET_IDEA_CATEGORIES = 'GET_IDEA_CATEGORIES';

export const REMOVE_IDEA = 'REMOVE_IDEA';
export const REMOVE_IDEA_COMMENT = 'REMOVE_IDEA_COMMENT';
export const REMOVE_IDEA_IMAGE = 'REMOVE_IDEA_IMAGE';

export const SAVE_IDEA_COMMENT = 'SAVE_IDEA_COMMENT';
export const SAVE_IDEA_FAVORITE = 'SAVE_IDEA_FAVORITE';
export const SAVE_IDEA_LIKE = 'SAVE_IDEA_LIKE';

export const SET_IDEA_COMMENTS = 'SET_IDEA_COMMENTS';
export const SET_IDEA = 'SET_IDEA';
export const SET_IDEAS = 'SET_IDEAS';
export const SET_IDEAS_FROM_AUTHOR = 'SET_IDEAS_FROM_AUTHOR';

export const SET_IDEA_AGE_GROUPS = 'SET_IDEA_AGE_GROUPS';
export const SET_IDEA_CATEGORIES = 'SET_IDEA_CATEGORIES';

export const STORE_IDEA = 'STORE_IDEA';

export const UPDATE_IDEA = 'UPDATE_IDEA';
export const ADD_IDEA_COMMENT = 'ADD_IDEA_COMMENT';
export const UPDATE_IDEA_FAVORITE = 'UPDATE_IDEA_FAVORITE';
export const UPDATE_IDEA_LIKE = 'UPDATE_IDEA_LIKE';

export const UPDATE_IDEAS_SINGLE_FAVORITE = 'UPDATE_IDEAS_SINGLE_FAVORITE';
export const UPDATE_IDEAS_SINGLE_LIKE = 'UPDATE_IDEAS_SINGLE_LIKE';
export const UPDATE_IDEA_COMMENT = 'UPDATE_IDEA_COMMENT';

export const SET_IDEAS_CURRENT_PAGE = 'SET_IDEAS_CURRENT_PAGE';
export const SET_IDEAS_CURRENT_SEARCH = 'SET_IDEAS_CURRENT_SEARCH';
export const SET_IDEAS_CURRENT_TYPE = 'SET_IDEAS_CURRENT_TYPE';
export const RESET_IDEAS_CURRENTS = 'RESET_IDEAS_CURRENTS';
export const UPDATE_CURRENT_IDEAS_TYPE = 'UPDATE_CURRENT_IDEAS_TYPE';
