import http from '@http';

class AdminChildDevService {
    constructor() {
        if (AdminChildDevService.instance) {
            return AdminChildDevService.instance;
        }

        AdminChildDevService.instance = this;
    }

    // settings
    getSettingsTemplate(queryObj) {
        return http.get('/api/admin/child-dev/settings', {params: queryObj}).then(res => res.data);
    }
    updateSettingsTemplate(data) {
        return http.put('/api/admin/child-dev/settings', data).then(res => res.data);
    }


    // age groups
    ageGroups(queryObj) {
        return http.get('/api/admin/child-dev/age-groups', {params: queryObj}).then(res => res.data);
    }
    storeAgeGroup(data) {
        return http.post('/api/admin/child-dev/age-groups/store', data).then(res => res.data);
    }
    updateAgeGroup(groupId, data) {
        return http.put(`/api/admin/child-dev/age-groups/${groupId}/update`, data).then(res => res.data);
    }
    destroyAgeGroup(groupId) {
        return http.delete(`/api/admin/child-dev/age-groups/${groupId}/destroy`).then(res => res.data);
    }

    // categories
    categories(queryObj) {
        return http.get('/api/admin/child-dev/categories', {params: queryObj}).then(res => res.data);
    }
    storeCategory(data) {
        return http.post('/api/admin/child-dev/categories/store', data).then(res => res.data);
    }
    updateCategory(categoryId, data) {
        return http.put(`/api/admin/child-dev/categories/${categoryId}/update`, data).then(res => res.data);
    }
    destroyCategory(categoryId) {
        return http.delete(`/api/admin/child-dev/categories/${categoryId}/destroy`).then(res => res.data);
    }

    // goals
    storeGoal(data) {
        return http.post('/api/admin/child-dev/goals/store', data).then(res => res.data);
    }
    updateGoal(goalId, data) {
        return http.put(`/api/admin/child-dev/goals/${goalId}/update`, data).then(res => res.data);
    }
    destroyGoal(goalId) {
        return http.delete(`/api/admin/child-dev/goals/${goalId}/destroy`).then(res => res.data);
    }
}
export default new AdminChildDevService();
