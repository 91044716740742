import {
    ADD_BANNER,
    GET_BANNERS,
    SET_BANNER,
    SET_BANNERS,
    EDIT_BANNER,
    REMOVE_BANNER,
    DELETE_BANNER,
    STORE_BANNER,
    REPRIORITIZE_BANNERS
} from './actions';
import Vue from 'vue';
import BannerService from '@services/banner.service';
import store from '../../index';
import { isEmpty, orderBy } from 'lodash';

function formatBannerData(data) {
        if (data.start_time) {
            data.start_time = Vue.moment.utc(data.start_time).tz(store.getters.user.timezone);
        }
        if (data.end_time) {
            data.end_time = Vue.moment.utc(data.end_time).tz(store.getters.user.timezone);
        }

    return data;
}

const state = {
    banners: []
};

const mutations = {
    [ADD_BANNER](state, data) {
        state.banners.push(data);
    },
    [SET_BANNER](state, banner) {
        let index = state.banners.findIndex(b => b.id === banner.id);
        if(index !== -1) {
            Vue.set(state.banners, index, formatBannerData(banner));
        }
    },
    [SET_BANNERS](state, data) {
        data.forEach(banner => {
            formatBannerData(banner);
        });
        state.banners = data;
    },
    [REMOVE_BANNER](state, id) {
        let index = state.banners.findIndex(b => b.id === id);
        if(index !== -1) {
            for (let i = index; i < state.banners.length; i++) {
                state.banners[i].priority--;
            }
            state.banners.splice(index, 1);
        }
    }
};

const actions = {
    [STORE_BANNER]({ commit }, data) {
        return BannerService.store(data).then(res => {
            commit(ADD_BANNER, res);
        });
    },
    [GET_BANNERS]({ commit }) {
        return BannerService.getAllBanners().then(res => {
            commit(SET_BANNERS, res);
        });
    },
    [EDIT_BANNER]({ commit }, data) {
        return BannerService.update(data).then(res => {
            commit(SET_BANNER, res);
        });
    },
    [DELETE_BANNER]({ commit }, banner_id) {
        return BannerService.deleteBanner(banner_id).then(() => {
            commit(REMOVE_BANNER, banner_id);
        });
    },
    [REPRIORITIZE_BANNERS]({ getters, commit }, new_data) {
        let banners = orderBy(getters.bannerList, ['priority'], ['asc']);
        let first_changed = {};
        let last_changed = {};
        for (let i = 0; i < new_data.length; i++) {
            if (banners[i] !== undefined) {
                if (isEmpty(first_changed) && new_data[i].id !== banners[i].id) {
                    // Find first reordered row
                    first_changed = {
                        id: new_data[i].id,
                        priority: i+1,
                        old_priority: new_data[i].priority
                    };
                } else if (!isEmpty(first_changed) && new_data[i].id === banners[i].id) {
                    // Find last reordered row
                    last_changed = {
                        id: new_data[i-1].id,
                        priority: i,
                        old_priority: new_data[i-1].priority
                    };
                    break;
                }
            }
        }
        // There is something to change
        if (!isEmpty(first_changed)) {
            // Set last element to the last banner in changed data array
            if (isEmpty(last_changed)) {
                last_changed = {
                    id: new_data[new_data.length-1].id,
                    priority: new_data.length,
                    old_priority: new_data[new_data.length-1].priority
                };
            }
            // Find reorder direction
            let direction = last_changed.old_priority === first_changed.priority ? 'decrement' : 'increment';
            // Prepare data
            let data = {
                first_changed: first_changed,
                last_changed: last_changed,
                direction: direction
            };
            return BannerService.reprioritize(data).then((res) => {
                commit(SET_BANNERS, res);
            });
        }
    }
};

const getters = {
    bannerList: state => {
        return state.banners;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
