<template>
    <button
        type="button"
        :disabled="disabled"
        class="btn e3-rounded-icon-btn"
        :class="[type, {'e3-pointer' : !isDisabled, 'bg-transparent' : loading}]"
        :style="{'width': `${btnSize}px`, 'height': `${btnSize}px`}"
        @click="click">
        <slot v-if="!loading">
            <SvgIcon icon="mdi-lead-pencil" :size="size" class="text-white"/>
        </slot>
        <div v-else class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </button>
</template>

<script>
export default {
    props: {
        disabled: Boolean,
        btnSize: String,
        size: {
            type: String,
            default: '18'
        },
        type: {
            type: String,
            default: 'btn-primary'
        },
        loading: Boolean,
    },
    computed: {
        isDisabled() {
            return this.disabled;
        }
    },
    methods: {
        click() {
            this.$emit('click');
        }
    }
};
</script>

<style>

</style>
