import http from '@http';
import { download } from '@services/helper.service';

class FoodMenuService {
    constructor() {
        if (FoodMenuService.instance) {
            return FoodMenuService.instance;
        }

        FoodMenuService.instance = this;
    }

    uploadFoodMenu(kindergartenId, weekId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/foodmenu/${weekId}/upload-menu`, data).then(res => res.data);
    }

    deleteUploadedMenu(kindergartenId, fileId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/foodmenu/${fileId}/delete-uploaded-menu`).then(res => res.data);
    }

    getUploadedMenuBase64(kindergartenId, weekId) {
        return http.get(`/api/kindergartens/${kindergartenId}/foodmenu/v2/${weekId}/uploaded-menu-base64`).then(res => res.data);
    }

    downloadUploadedPdf(kindergartenId, fileId) {
        return http.download(`/api/kindergartens/${kindergartenId}/foodmenu/${fileId}/download`)
            .then(res => {
                const file = {
                    mime_type: 'application/pdf',
                    name: 'menu.pdf'
                };
                download(res.data, file);
            });
    }

    downloadWeekMenuPdf(kindergartenId, date) {
        return http.download(`/api/kindergartens/${kindergartenId}/foodmenu/week-menu-pdf?date=${date}`)
            .then(res => {
                const file = {
                    mime_type: 'application/pdf',
                    name: 'week_plan.pdf'
                };
                download(res.data, file);
            });
    }

    downloadDayMenuPdf(kindergartenId, date) {
        return http.download(`/api/kindergartens/${kindergartenId}/foodmenu/day-menu-pdf?date=${date}`)
            .then(res => {
                const file = {
                    mime_type: 'application/pdf',
                    name: 'day_plan.pdf'
                };
                download(res.data, file);
            });
    }

    getWeekMenu(kindergartenId, date) {
        return http.get(`/api/kindergartens/${kindergartenId}/foodmenu/week?date=${date}`)
            .then(res => res.data);
    }

    getDayMenu(kindergartenId, date) {
        return http.get(`/api/kindergartens/${kindergartenId}/foodmenu/day?date=${date}`)
            .then(res => res.data);
    }

    updateDay(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/foodmenu/day/${data.id}`, data)
            .then(res => res.data);
    }

    updateWeek(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/foodmenu/week/${data.id}`, data)
            .then(res => res.data);
    }

    insertMenu(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/foodmenu/week`, data)
            .then(res => res.data);
    }

    createMissingWeekend(kindergartenId, foodMenuId) {
        return http.post(`/api/kindergartens/${kindergartenId}/foodmenu/create-missing/${foodMenuId}`)
            .then(res => res.data);
    }
}

export default new FoodMenuService();
