import http from '@http';

class SurveyService {
    constructor() {
        if(SurveyService.instance) {
            return SurveyService.instance;
        }

        SurveyService.instance = this;
    }

    getManagedQuestionnaires(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/surveys/managed-questionnaires`, { params: queryObj }).then(res => res.data);
    }

    storeQuestionnaire(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/surveys/managed-questionnaires`, data).then(res => res.data);
    }

    storePoll(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/surveys/managed-polls`, data).then(res => res.data);
    }

    getAvailableSurveys(kindergartenId, type) {
        return http.get(`/api/kindergartens/${kindergartenId}/surveys/available`, {params: {type}}).then(res => res.data);
    }

    getSurvey(kindergartenId, id) {
        return http.get(`/api/kindergartens/${kindergartenId}/surveys/edit/${id}`).then(res => res.data);
    }

    updateSurvey(kindergartenId, id, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/surveys/edit/${id}`, data).then(res => res.data);
    }

    deleteSurvey(kindergartenId, id) {
        return http.delete(`/api/kindergartens/${kindergartenId}/surveys/${id}`).then(res => res.data);
    }

    updateSurveySettings(kindergartenId, id, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/surveys/edit/${id}/settings`, data).then(res => res.data);
    }

    addQuestion(kindergartenId, surveyId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/surveys/${surveyId}/question`, data).then(res => res.data);
    }

    updateQuestion(kindergartenId, surveyId, questionId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/surveys/${surveyId}/question/${questionId}`, data).then(res => res.data);
    }

    deleteQuestion(kindergartenId, surveyId, questionId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/surveys/${surveyId}/question/${questionId}`).then(res => res);
    }

    reorderQuestion(kindergartenId, surveyId, questionId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/surveys/${surveyId}/question/${questionId}/order`, data).then(res => res);
    }

    addQuestionOption(kindergartenId, surveyId, questionId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/surveys/${surveyId}/question/${questionId}/options`, data).then(res => res.data);
    }

    updateQuestionOption(kindergartenId, surveyId, questionId, optionId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/surveys/${surveyId}/question/${questionId}/options/${optionId}`, data).then(res => res.data);
    }

    deleteQuestionOption(kindergartenId, surveyId, questionId, optionId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/surveys/${surveyId}/question/${questionId}/options/${optionId}`).then(res => res);
    }

    reorderQuestionOption(kindergartenId, surveyId, questionId, optionId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/surveys/${surveyId}/question/${questionId}/options/${optionId}/order`, data).then(res => res);
    }

    addAcl(kindergartenId, surveyId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/surveys/edit/${surveyId}/access`, data).then(res => res.data);
    }

    removeAcl(kindergartenId, surveyId, data) {
        return http.delete(`/api/kindergartens/${kindergartenId}/surveys/edit/${surveyId}/access`, { params: data }).then(res => res.data);
    }
}

export default new SurveyService();