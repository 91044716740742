export const STORE_GUARDIAN = 'STORE_GUARDIAN';
export const ADD_GUARDIAN = 'ADD_GUARDIAN';
export const SET_GUARDIANS = 'SET_GUARDIANS';
export const GET_CHILD_GUARDIANS = 'GET_CHILD_GUARDIANS';
export const GET_GUARDIAN_DIARY = 'GET_GUARDIAN_DIARY';
export const SET_GUARDIAN_DIARY = 'SET_GUARDIAN_DIARY';
export const GET_CHILD = 'GET_CHILD';
export const SET_CHILD = 'SET_CHILD';
export const GET_CHILD_PROFILE = 'GET_CHILD_PROFILE';
export const GET_CHILD_MAPS = 'GET_CHILD_MAPS';
export const SET_CHILD_MAPS = 'SET_CHILD_MAPS';
export const STORE_CHILD_MAP = 'STORE_CHILD_MAP';
export const ADD_CHILD_MAP = 'ADD_CHILD_MAP';
export const SAVE_CHILD_PROFILE = 'SAVE_CHILD_PROFILE';
export const UPDATE_CHILD_PROFILE = 'UPDATE_CHILD_PROFILE';
export const UPDATE_CHILD_MAP = 'UPDATE_CHILD_MAP';
export const GET_CHILD_COURSES = 'GET_CHILD_COURSES';
export const SET_CHILD_COURSES = 'SET_CHILD_COURSES';
export const GET_CHILD_PLAN = 'GET_CHILD_PLAN';
export const GET_CHILD_PLAN_TYPES = 'GET_CHILD_PLAN_TYPES';
export const REMOVE_CHILD_COURSE_DATES = 'REMOVE_CHILD_COURSE_DATES';
export const UPDATE_GUARDIAN = 'UPDATE_GUARDIAN';
export const DETACH_GUARDIAN = 'DETACH_GUARDIAN';
export const DELETE_GUARDIAN = 'DELETE_GUARDIAN';
export const ADD_CHILD_GUARDIAN = 'ADD_CHILD_GUARDIAN';
export const GET_CHILD_ABSENCES = 'GET_CHILD_ABSENCES';
export const SET_CHILD_ABSENCES = 'SET_CHILD_ABSENCES';
export const STORE_CHILD_ABSENCE = 'STORE_CHILD_ABSENCE';
export const ADD_CHILD_ABSENCE = 'ADD_CHILD_ABSENCE';
export const GET_CHILD_PROJECT_PLANS = 'GET_CHILD_PROJECT_PLANS';
export const GET_CHILD_PROJECT_PLAN = 'GET_CHILD_PROJECT_PLAN';
export const SET_CHILD_PROJECT_PLANS = 'SET_CHILD_PROJECT_PLANS';
export const ARCHIVE_CHILD = 'ARCHIVE_CHILD';
export const RESTORE_CHILD = 'RESTORE_CHILD';
export const GET_CHILD_COURSES_WITH_FEEDBACK = 'GET_CHILD_COURSES_WITH_FEEDBACK';
export const SET_CHILD_COURSES_WITH_FEEDBACK = 'SET_CHILD_COURSES_WITH_FEEDBACK';
export const GET_CHILD_APPROVALS = 'GET_CHILD_APPROVALS';
export const SET_CHILD_APPROVALS = 'SET_CHILD_APPROVALS';
export const UPDATE_CHILD_APPROVAL_ANSWERS = 'UPDATE_CHILD_APPROVAL_ANSWERS';
export const SET_CHILD_APPROVAL_ANSWERS = 'SET_CHILD_APPROVAL_ANSWERS';
export const SET_CHILD_DEV_EVALUATIONS_IN_PROFILE = 'SET_CHILD_DEV_EVALUATIONS_IN_PROFILE';
export const SET_GUARDIAN_DIARY_LOAD_MORE = 'SET_GUARDIAN_DIARY_LOAD_MORE';
export const SET_DOOR_CARD_ENABLED = 'SET_DOOR_CARD_ENABLED';
export const SET_CHILD_PIC = 'SET_CHILD_PIC';
export const GET_CHILD_FILES = 'GET_CHILD_FILES';
export const SET_CHILD_FILES = 'SET_CHILD_FILES';
export const UPDATE_CHILD_FILE = 'UPDATE_CHILD_FILE';
export const SAVE_CHILD_FILE = 'SAVE_CHILD_FILE';
export const DELETE_CHILD_FILE = 'DELETE_CHILD_FILE';
export const REMOVE_CHILD_FILE = 'REMOVE_CHILD_FILE';
export const UPLOAD_CHILD_FILE = 'UPLOAD_CHILD_FILE';
export const ADD_CHILD_FILE = 'ADD_CHILD_FILE';
export const SET_CHILD_PROFILE_SETTINGS = 'SET_CHILD_PROFILE_SETTINGS';
export const ADD_CHILD_COURSE_DATES = 'ADD_CHILD_COURSE_DATES';
export const UPDATE_CHILD_COURSE_DATES = 'UPDATE_CHILD_COURSE_DATES';
export const SET_CHILD_COURSE_DATES = 'SET_CHILD_COURSE_DATES';
export const GET_CHILD_YEAR_PLANS = 'GET_CHILD_YEAR_PLANS';
export const GET_CHILD_YEAR_PLAN = 'GET_CHILD_YEAR_PLAN';
export const SET_CHILD_YEAR_PLANS = 'SET_CHILD_YEAR_PLANS';
export const GET_CHILD_PLANS_LIST = 'GET_CHILD_PLANS_LIST';
export const SET_CHILD_PLANS_LIST = 'SET_CHILD_PLANS_LIST';
export const RESET_SELECTED_CHILD_PLAN = 'RESET_SELECTED_CHILD_PLAN';
