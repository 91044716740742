import http from '@services/http.service';

class FolderService {
    constructor() {
        if (FolderService.instance) {
            return FolderService.instance;
        }
        FolderService.instance = this;
    }

    getFolderData(kindergartenId, id, queryObj) {
        return http
            .get(`/api/kindergartens/${kindergartenId}/folders/${id}`, {params: queryObj})
            .then(res => res.data);
    }

    getSubFolders(kindergartenId, folderId, queryObj) {
        return http
            .get(`/api/kindergartens/${kindergartenId}/folders/${folderId}/folders`, {params: queryObj})
            .then(res => res.data);
    }

    uploadFiles(kindergartenId, data, folder) {
        return http
            .post(
                `/api/kindergartens/${kindergartenId}/folders/${folder}/upload`,
                data
            )
            .then(res => res.data);
    }

    addFolder(kindergartenId, data) {
        return http
            .post(`/api/kindergartens/${kindergartenId}/folders`, data)
            .then(res => res.data);
    }

    updateFolder(kindergartenId, data) {
        return http
            .put(
                `/api/kindergartens/${kindergartenId}/folders/${data.folder_id}`,
                data
            )
            .then(res => res.data);
    }

    deleteFolder(kindergartenId, id) {
        return http
            .delete(`/api/kindergartens/${kindergartenId}/folders/${id}`)
            .then(res => res.data);
    }

    getFolderAcl(kindergartenId, folderId) {
        return http
            .get(`/api/kindergartens/${kindergartenId}/folders/${folderId}/acl`)
            .then(res => res.data);
    }

    syncFolderAcl(kindergartenId, folderId) {
        return http.post(`/api/kindergartens/${kindergartenId}/folders/${folderId}/sync`).then(res => res.data);
    }

    getFolderFiles(kindergartenId, folderId, queryObj) {
        return http
            .get(`/api/kindergartens/${kindergartenId}/folders/${folderId}/files`, {params: queryObj})
            .then(res => res.data);
    }

    moveFolders(kindergartenId, destinationId, form) {
        return http
            .put(`/api/kindergartens/${kindergartenId}/folders/${destinationId}/move`, form)
            .then(res => res.data);
    }

    moveFiles(kindergartenId, destinationId, form) {
        return http
            .put(`/api/kindergartens/${kindergartenId}/folders/${destinationId}/files/move`, form)
            .then(res => res.data);
    }

    deleteFiles(kindergartenId, folderId, form) {
        return http
            .delete(`/api/kindergartens/${kindergartenId}/folders/${folderId}/files`, {data: form})
            .then(res => res.data);
    }

    getBuiltinChildProfile(kindergartenId, child, params) {
        return http
            .get(`/api/kindergartens/${kindergartenId}/folders/child-profile/${child}`, {params: params})
            .then(res => res.data);
    }

    getBuiltinDiaryFolders(kindergartenId, params) {
        return http
            .get(`/api/kindergartens/${kindergartenId}/folders/diary`, {params: params})
            .then(res => res.data);
    }

    getBuiltinDiaryFiles(kindergartenId, course, params) {
        return http
            .get(`/api/kindergartens/${kindergartenId}/folders/diary/${course}/files`, {params: params})
            .then(res => res.data);
    }

    getBuiltinDiaryFilesCount(kindergartenId, course, params) {
        return http
            .get(`/api/kindergartens/${kindergartenId}/folders/diary/${course}/files/count`, {params: params})
            .then(res => res.data);
    }
}

export default new FolderService();
