<template>
    <div>
        <div v-if="!isContentLoading">
            <PageTitle>
                {{ $t('Album') }}: {{ folder.name }}
            </PageTitle>
            <FolderModal v-if="canEditFolder() || canAddToFolder()" type="gallery"/>
            <MoveModal v-if="canEditFolder" type="gallery"/>
            <NotAccessibleModal v-if="canEditFolder"/>
            <div class="d-flex flex-column flex-lg-row">
                <div class="mr-auto">
                    <h5 v-if="folder.breadcrumbs" class="d-flex flex-wrap">
                        <router-link :to="{ name: 'gallery.index' }">
                            {{ $t("Gallery") }}
                        </router-link>
                        <span v-for="crumb in folder.breadcrumbs" :key="crumb.id" class="d-flex">
                            <SvgIcon icon="mdi-chevron-right" class="text-black" size="24"/>
                            <router-link
                                v-if="crumb.id !== folder.id"
                                :to="{
                                    name: 'gallery.folder',
                                    params: { folder: crumb.id }
                                }"
                                class="nav-link p-0"
                                :class="$route.params.folder === crumb.id ? 'text-success' : ''"
                                :title="crumb.name">
                                {{ truncate(crumb.name, 20) }}
                            </router-link>
                            <span v-else :title="crumb.name">{{ truncate(crumb.name, 20) }}</span>
                        </span>
                    </h5>
                </div>
                <div class="ml-auto">
                    <div class="d-flex justify-content-end mb-3">
                        <button class="btn ml-sm-2" :class="[view_mode==='grid' ? 'btn-primary' : 'btn-secondary']" @click="view_mode='grid'">{{ $t("Grid") }}</button>
                        <button class="btn ml-2" :class="[view_mode==='list' ? 'btn-primary' : 'btn-secondary']" @click="view_mode='list'">{{ $t("List") }}</button>
                    </div>
                    <div v-if="hasInitialFolders || hasInitialFiles" class="d-flex flex-row ml-auto">
                        <div class="d-flex flex-row ml-auto">
                            <div v-if="hasInitialFolders" class="d-flex mr-3 align-items-center">
                                <label for="lunch-check" class="mr-2">{{ $t('Folders') }}</label>
                                <ECheckbox
                                    id="folders-check"
                                    v-model="searchFolders"
                                    :disabled="anyLoading"
                                    success
                                    class="ml-auto"
                                    @input="resetFolderSearch()"/>
                            </div>
                            <div v-if="hasInitialFiles || hasInitialFolders" class="d-flex mr-3 align-items-center">
                                <label for="lunch-check" class="mr-2">{{ $t('Files') }}</label>
                                <ECheckbox
                                    id="document-check"
                                    v-model="searchDocuments"
                                    :disabled="anyLoading"
                                    success
                                    class="ml-auto"
                                    @input="resetDocumentSearch()"/>
                            </div>
                        </div>
                        <ESearchInput
                            v-model="searchValue"
                            :disabled="anyLoading"
                            class="ml-auto"
                            :placeholder="$t('Type to search')"
                            @getEntities="search()">
                        </ESearchInput>
                    </div>
                </div>
            </div>
            <template>
                <template>
                    <FolderGrid
                        v-if="view_mode === 'grid' && (subFolders.length || folderLoading || (!subFolders.length && searchValue))"
                        :searchValue="searchFolders ? searchValue : ''"
                        :searchLoading="folderLoading"
                        :searching="searchFolders && hasInitialFolders && initialSearch"
                        :folders="subFolders"
                        type="gallery"
                        :page="page"
                        @loadMore="loadMore"/>
                    <FolderList
                        v-if="view_mode === 'list' && (subFolders.length || folderLoading || (!subFolders.length && searchValue))"
                        :searchValue="searchFolders ? searchValue : ''"
                        :searchLoading="folderLoading"
                        :searching="searchFolders && hasInitialFolders && initialSearch"
                        :folders="subFolders"
                        type="gallery"
                        :page="page"
                        @loadMore="loadMore"/>
                </template>
                <template>
                    <FileGrid
                        v-if="view_mode === 'grid' && (folderFiles.length || documentLoading || (!folderFiles.length && searchValue))"
                        :searchValue="searchDocuments ? searchValue : ''"
                        :searchLoading="documentLoading"
                        :searching="searchDocuments  && hasInitialFiles && initialSearch"
                        :files="sortedFiles"
                        type="gallery">
                        <template v-if="(canEditFolder() || canAddToFolder()) && folder.shared_with && !initialSearch">
                            <div class="border rounded p-2 mb-4">
                                <span class="font-weight-bold mr-1">{{ $t('Images shared in this album are visible to:') }}</span>
                                <span class="text-muted">{{ folder.shared_with }}</span>
                            </div>
                        </template>
                    </FileGrid>
                    <FileList
                        v-if="view_mode === 'list' && (folderFiles.length || documentLoading || (!folderFiles.length && searchValue))"
                        :searchValue="searchDocuments ? searchValue : ''"
                        :searchLoading="documentLoading"
                        :searching="searchDocuments  && hasInitialFiles && initialSearch"
                        title="Images and videos"
                        :files="sortedFiles"
                        type="gallery">
                        <template v-if="(canEditFolder() || canAddToFolder()) && folder.shared_with && !initialSearch">
                            <div class="border rounded p-2 mb-4 row">
                                <span class="font-weight-bold mr-1">{{ $t('Images shared in this album are visible to:') }}</span>
                                <span class="text-muted">{{ folder.shared_with }}</span>
                            </div>
                        </template>
                    </FileList>
                </template>
            </template>
            <EmptyListMessage v-if="!searchValue && !subFolders.length && !folderFiles.length && !folderLoading" class="border rounded p-2 bg-white mt-3">
                {{ $t("The album is empty") }}
            </EmptyListMessage>
            <div class="fixed-buttons">
                <button v-if="canEditFolder()" class="e3-folder-fixed-edit" @click="openEditModal">
                    <span>
                        <SvgIcon icon="mdi-lead-pencil" size="36" class="text-white"/>
                    </span>
                </button>
                <AddNewButton v-if="canAddToFolder()" :title="$t('Add folder')" @addNewClicked="openModal"/>
            </div>
        </div>
        <ELoader v-else/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {sortBy} from 'lodash';
import {truncate} from '@/filters';
import FolderModal from '@views/folder/modals/FolderModal.vue';
import FolderGrid from '@views/folder/components/grid/FolderGrid.vue';
import FolderList from '@views/folder/components/list/FolderList.vue';
import FileList from '@views/folder/components/list/FileList.vue';
import FileGrid from '@views/folder/components/grid/FileGrid.vue';
import MoveModal from '@views/folder/modals/MoveModal.vue';
import NotAccessibleModal from '@views/folder/modals/NotAccessibleModal.vue';
import DocumentGalleryFolderMixin from '@common/mixins/DocumentGalleryFolder.mixin';

export default {
    name: 'GalleryFolder',
    mixins: [DocumentGalleryFolderMixin],
    data() {
        return {
            view_mode : 'grid',
            searchValue: '',
            page: 1,
            perPage: 20,
            filesPerPage: 20,
            folderLoading: false,
            documentLoading: false,
            searchFolders: false,
            searchDocuments: false,
            hasInitialFolders: false,
            hasInitialFiles: false,
            initialSearch: false,
            checkedFiles: [],
            checkedFolders: [],
        };
    },
    watch: {
        '$route.params.folder': function (folder) {
            this.getFolderData(folder, '/gallery');
            this.resetData();
        }
    },
    computed : {
        ...mapGetters(['isContentLoading', 'folder', 'subFolders', 'folderFiles', 'activeKindergartenId']),
        sortedFiles() {
            return sortBy(this.folderFiles, f => f.uploaded_at);
        },
        anyLoading() {
            return this.folderLoading || this.documentLoading;
        }
    },
    methods: {
        truncate, // TODO: Vue3 remove
        getDocuments(noTerm = false) {
            this.documentLoading = true;
            this.$store.dispatch('GET_FOLDER_FILES', {
                folder_id: this.folder.id,
                queryObj: {
                    folder_id:this.folder.id,
                    page: this.page,
                    perPage: this.filesPerPage,
                    term: noTerm ? '' : this.searchValue,
                    sortBy: 'created_at',
                    sortDir: 'desc',
                    type: 'gallery'
                }
            }).finally(() => {
                this.documentLoading = false;
            });
        },
    },
    components: {
        FolderModal,
        FolderGrid,
        FolderList,
        FileList,
        FileGrid,
        MoveModal,
        NotAccessibleModal,
    },
    created() {
        if(this.$route.params.folder) {
            this.getFolderData(this.$route.params.folder, '/gallery');
        }
    },
    destroyed() {
        this.$store.commit('RESET_FOLDER_STATE');
    }
};

</script>

<style lang="scss" scoped>
.e3-folder-fixed-edit {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  background-color: #f79e80;
  border: none;
  border-radius: 50%;
  transition: all .2s ease-in;
  box-shadow: 4px 4px 8px 0px rgba(#f79e80, 0.4);
  animation: pulse 2s 5;

  &:hover, &:focus {
    box-shadow: 8px 8px 16px 0px rgba(#f79e80, 0.4);
    outline: none;
    animation: none;
  }
}
</style>
