import {
    RESET_ANNOUNCEMENT_STATE,
    ADD_SENT_ANNOUNCEMENT,
    DELETE_ANNOUNCEMENT,
    GET_KINDERGARTEN_ANNOUNCEMENTS,
    GET_SENT_KINDERGARTEN_ANNOUNCEMENTS,
    SET_ANNOUNCEMENTS,
    SET_SENT_ANNOUNCEMENTS,
    SET_UNREAD_ANNOUNCEMENT_COUNT,
    SEND_ANNOUNCEMENT,
    UPDATE_ANNOUNCEMENT,
    READ_ANNOUNCEMENTS,
    GET_AVAILABLE_ANNOUNCEMENT_TARGETGROUPS,
    SET_AVAILABLE_ANNOUNCEMENT_TARGETGROUPS,
    SET_ANNOUNCEMENT_LOAD_MORE,
    GET_RECENT_KINDERGARTEN_ANNOUNCEMENTS,
    SET_RECENT_KINDERGARTEN_ANNOUNCEMENTS
} from './actions';
import Vue from 'vue';
import AnnouncementService from '@services/announcement.service';

const getDefaultState = () => {
    return {
        announcements: [],
        target_groups: [],
        sent_announcements: [],
        recent_announcements: [],
        has_more: false,
        unread_announcements: 0,
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_ANNOUNCEMENT_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_ANNOUNCEMENTS](state, announcements) {
        announcements.forEach(announcement => {
            state.announcements.push(announcement);
        });
        // state.sent_announcements = announcements;
    },
    [SET_UNREAD_ANNOUNCEMENT_COUNT](state, count) {
        state.unread_announcements = count;
    },
    [SET_SENT_ANNOUNCEMENTS](state, data) {
        // state.sent_announcements = data;
        data.forEach(announcement => {
            state.sent_announcements.push(announcement);
        });
    },
    [ADD_SENT_ANNOUNCEMENT](state, announcement) {
        state.sent_announcements.unshift(announcement);
    },
    [UPDATE_ANNOUNCEMENT](state, announcement) {
        let index = state.sent_announcements.findIndex(
            ann => ann.id === announcement.id
        );
        Vue.set(state.sent_announcements, index, announcement);
    },
    [DELETE_ANNOUNCEMENT](state, id) {
        let index = state.sent_announcements.findIndex(ann => ann.id === id);
        state.sent_announcements.splice(index, 1);
    },
    [SET_AVAILABLE_ANNOUNCEMENT_TARGETGROUPS] (state, groups) {
        state.target_groups = groups;
    },
    [SET_ANNOUNCEMENT_LOAD_MORE](state, hasMore) {
        state.has_more = hasMore;
    },
    [SET_RECENT_KINDERGARTEN_ANNOUNCEMENTS](state, recent) {
        state.recent_announcements = recent;
    }
};
const actions = {
    [GET_KINDERGARTEN_ANNOUNCEMENTS]({ state, commit, getters }, options) {
        if(options.page === 1) {
            state.announcements = [];
        }
        return AnnouncementService.getKindergartenAnnouncements(getters.activeKindergartenId, options).then(res => {
            commit(SET_ANNOUNCEMENTS, res.announcements);
            commit(SET_ANNOUNCEMENT_LOAD_MORE, res.has_more);
        });
    },
    [GET_SENT_KINDERGARTEN_ANNOUNCEMENTS]({commit, getters}, options) {
        if(options.page === 1) {
            state.sent_announcements = [];
        }

        return AnnouncementService.getSentKindergartenAnnouncements(getters.activeKindergartenId, options).then(res => {
            commit(SET_SENT_ANNOUNCEMENTS, res.announcements);
            commit(SET_ANNOUNCEMENT_LOAD_MORE, res.has_more);
        });
    },
    [SEND_ANNOUNCEMENT]({ commit, getters}, data) {
        let url = undefined;
        if(getters.activeKindergartenId) {
            url = `kindergartens/${getters.activeKindergartenId}`;

        }// else if(getters.activeOrganizationId) {
        //    TODO organization store
        //    url = `organizations/${getters.activeOrganizationId}`;
        // }
        if(url !== undefined) {
            return AnnouncementService.send(url, data).then(res => {
                commit(ADD_SENT_ANNOUNCEMENT, res);
            });
        }

    },
    [UPDATE_ANNOUNCEMENT]({ commit }, {id, data}) {
        return AnnouncementService.update(id, data).then(res => {
            commit(UPDATE_ANNOUNCEMENT, res);
        });
    },
    [DELETE_ANNOUNCEMENT]({ commit }, announcementId) {
        return AnnouncementService.delete(announcementId).then(() => {
            commit(DELETE_ANNOUNCEMENT, announcementId);
        });
    },
    [READ_ANNOUNCEMENTS]({commit, getters}) {
        return AnnouncementService.readAnnouncements(getters.activeKindergartenId).then(() => {
            commit('SET_UNREAD_ANNOUNCEMENT_COUNT', 0);
        });
    },
    [GET_AVAILABLE_ANNOUNCEMENT_TARGETGROUPS]({ commit, getters }) {
        return AnnouncementService.getUserTargetGroups(getters.activeKindergartenId).then(res => {
            commit(SET_AVAILABLE_ANNOUNCEMENT_TARGETGROUPS, res);
        });
    },
    [GET_RECENT_KINDERGARTEN_ANNOUNCEMENTS]({commit, getters}) {
        return AnnouncementService.getRecentKindergartenAnnouncements(getters.activeKindergartenId).then(res => {
            commit(SET_RECENT_KINDERGARTEN_ANNOUNCEMENTS, res.announcements);
            commit(SET_UNREAD_ANNOUNCEMENT_COUNT, res.unread_count);
        });
    }
};
const getters = {
    recentAnnouncements: state => state.recent_announcements,
    announcements: state => state.announcements,
    sentAnnouncements: state => state.sent_announcements,
    unreadAnnouncements: state => state.unread_announcements,
    // unreadAnnouncements: state => {
    //     return state.announcements.filter(msg => msg.receiver.seen === null).length;
    // },
    tabAnnouncements: (state, getters) => tab => {
        if (tab === getters.messageTypes.sent) {
            return state.sent_announcements;
        } else {
            return state.announcements;
        }
    },
    announcementTargetGroups: state => state.target_groups,
    hasMoreAnnouncements: state => state.has_more
};

export default {
    state,
    mutations,
    actions,
    getters
};
