<template>
    <div class="d-flex align-items-center w-100 e3-modal-header">
        <h5 class="d-flex w-100 m-0 mr-3 text-break">
            <slot/>
        </h5>
        <RoundIconButton class="ml-auto" type="bg-transparent" @click="closeFunc ? closeModal() : $bvModal.hide(modalName)">
            <SvgIcon icon="mdi-close-circle" class="text-danger" size="27"/>
        </RoundIconButton>
    </div>
</template>

<script>

export default {
    props: {
        modalName: String,
        closeFunc: Boolean
    },
    methods: {
        closeModal() {
            this.$emit('modalCloseFunc', this.modalName);
        }
    }
};
</script>

<style lang="scss" scoped>
.e3-modal-header {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
}
</style>
