<template>
    <div :class="{ 'position-relative': !dropup, 'dropup': dropup }">
        <template v-if="boxSelect">
            <div class="login-language-select">
                <ul>
                    <li v-for="(lang, i) in languages" :key="i" :class="{ active: language == lang.key }">
                        <a class="e3-pointer" @click="setLanguage(lang.key); closeMobile();">{{ lang.key }}</a>
                    </li>
                </ul>
            </div>
        </template>
        <template v-else>
            <div v-click-outside="closeCollapse" class="d-flex align-items-center lang-switcher" data-toggle="collapse"
                 data-target="langCollapse" aria-expanded="false" aria-controls="langCollapse" @click="showLanguages = !showLanguages">
                <span v-if="!landingPage" class="font-weight-bold mr-2 text-uppercase">{{ $t('Choose language') }}: </span>
                <span class="e3-pointer align-items-center d-inline-flex">
                    <span class="font-weight-bold text-primary mr-auto">
                        <img :src="`/images/lang_flags/${language}.svg`" class="e3-user-flag mr-1" height="15" :alt="`${language}.svg`"/>
                        <span>{{selectedLangName}}</span>
                    </span>
                    <SvgIcon icon="mdi-chevron-down" class="text-primary ml-auto" size="22"/>
                </span>
            </div>
            <div id="langCollapse" class="collapse" :class="{'show': showLanguages}">
                <div
                    v-for="lang in unselectedLanguages" id="userLangSelect" :key="lang.key" class="pl-0"
                    @click="setLanguage(lang.key, true)">
                    <span v-if="lang.key === language" class="text-success">
                        {{lang.text}}
                    </span>
                    <span v-else class="e3-user-language e3-pointer">
                        <img :src="`/images/lang_flags/${lang.key}.svg`" class="e3-user-flag mr-1" height="15" :alt="`${lang.key}.svg`"/> {{lang.text}}
                    </span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {SET_LANGUAGE} from '@store/localization/constants';

export default {
    props: {
        landingPage: {
            type: Boolean,
            default: false
        },
        boxSelect: {
            type: Boolean,
            default: false
        },
        burger: {
            type: Boolean,
            default: false
        },
        dropup: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showLanguages: false,
            selectedLanguage: this.$i18n.locale,
        };
    },
    computed: {
        ...mapGetters(['languages', 'language', 'mobileMenuVisible']),
        unselectedLanguages() {
            return this.languages.filter(el => el.key !== this.language);
        },
        selectedLangName() {
            const lang = this.languages.find(el => el.key === this.language);
            return lang ? lang.text : '';
        },
    },
    methods: {
        setLanguage(lang, clicked) {
            if(clicked) {
                this.$emit('close');
                this.showLanguages = false;
            }
            this.$store.dispatch(SET_LANGUAGE, lang).then(() => {
                const html = document.documentElement;
                html.setAttribute('lang', lang);
                this.selectedLanguage = lang;
            }).catch(() => {
                if (lang !== 'en') {
                    this.setLanguage('en');
                }
            });
        },
        closeMobile() {
            if (this.burger && this.mobileMenuVisible) {
                this.$store.dispatch('CLOSE_MOBILE_MENU');
            }
        },
        closeCollapse() {
            if (this.showLanguages) {
                this.showLanguages = false;
            }
        }
    },
    created() {
        if (!this.landingPage) {
            this.setLanguage(this.$i18n.locale, false);
        }
    }
};
</script>

<style lang="scss" scoped>
    .nav-link {
        .fas {
            transition: all 0.225s linear;
        }
    }

    .nav-link[aria-expanded='true'] > .fas {
        transform: rotate(180deg);
    }

    .dropdown-menu {
        .dropdown-item:hover,
        .dropdown-item:focus {
            background-color: #e3f1e2;
        }
    }
</style>
