import http from '@http';

class AdminUserService {
    constructor() {
        if (AdminUserService.instance) {
            return AdminUserService.instance;
        }

        AdminUserService.instance = this;
    }

    getUsers(queryObj) {
        return http.get('/api/admin/users', {params: queryObj}).then(res => res.data);
    }

    getAdmins(queryObj) {
        return http.get('/api/admin/users/admins', {params: queryObj}).then(res => res.data);
    }

    addAdminUser(data) {
        return http.post('/api/admin/users/admins', data).then(res => res.data);
    }

    updateAdminUser(data) {
        return http.put(`/api/admin/users/admins/${data.admin_id}`, data).then(res => res.data);
    }

    deleteAdminUser(adminId) {
        return http.delete(`/api/admin/users/admins/${adminId}`).then(res => res.data);
    }

    getAdminRoles(queryObj) {
        return http.get('/api/admin/admin-roles', {params: queryObj}).then(res => res.data);
    }

    updateAdminRole(role) {
        return http.put(`/api/admin/admin-roles/${role.id}`, role).then(res => res.data);
    }

    addAdminRole(data) {
        return http.post('/api/admin/admin-roles', data).then(res => res.data);
    }

    deleteAdminRole(role_id) {
        return http.delete(`/api/admin/admin-roles/${role_id}`).then(res => res.data);
    }

    getAdminPermissions() {
        return http.get('/api/admin/admin-permissions').then(res => res.data);
    }

    getAdminCountry() {
        return http.get('/api/admin/admin-country').then(res => res.data);
    }

    getUser(id) {
        return http.get(`/api/admin/users/${id}`).then(res => res.data);
    }
}
export default new AdminUserService();