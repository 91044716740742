import http from '@http';

class RecentService {
    constructor() {
        if (RecentService.instance) {
            return RecentService.instance;
        }

        RecentService.instance = this;
    }

    getKindergartenActivity(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/activity`).then(res => res.data);
    }

    getOrganizationActivity(organizationId) {
        return http.get(`/api/organizations/${organizationId}/activity`).then(res => res.data);
    }
}
export default new RecentService();