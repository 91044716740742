import {eventBus} from '@/main';

export default {
    data() {
        return {
            fileSizeMax: 200000000,
            nameMaxLength: 191,
        };
    },
    methods: {
        childSingleFileUpload(file, childId, index) {
            if (!childId) {
                this.$toastr.e(this.$t('File upload failed'));
                return;
            }

            let formData = new FormData();
            formData.append('file', file);
            formData.append('uploaded_at', this.$moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS'));

            this.$store.dispatch('UPLOAD_CHILD_FILE', {childId, formData: formData}).then(() => {
                this.sendStatusToChild(file, 'success', index);
                this.uploading = false;
                this.$emit('setFinished', this.index);
            }).catch(() => {
                this.sendStatusToChild(file, 'catch', index);
                this.statusType = 'danger';
                this.uploading = false;
                this.$emit('setFinished', this.index);
            });
        },
        sendStatusToChild(file, status, index) {
            let data = {
                status: '',
                statusType: '',
                uploading: false
            };

            switch (status) {
            case 'success':
                data.statusType = 'success';
                data.status = 'mdi-check';
                data.color = 'text-success';
                break;
            case 'catch':
                data.statusType = 'danger';
                data.color = 'text-danger';
                break;
            default:
                break;
            }

            eventBus.$emit(`${file.name}-${index}`, data);
        },
    }
};
