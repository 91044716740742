export default {
    methods: {
        returnFileIcon(givenFile) {
            let type;
            if (givenFile === 'file') {
                type = this.file.mime_type ? this.file.mime_type.split('/') : this.file.type.split('/');
            } else {
                type = this.displayFile.type.split('/');
            }

            if (type[0] == 'image') {
                return { img: 'mdi-file-image', color: 'e3-file'};
            }
            else if(type[0] == 'application') {
                if (type[1].includes('word') || type[1].includes('opendocument.text')) {
                    return { img: 'mdi-file-word', color: 'e3-word'};
                }
                else if(type[1].includes('powerpoint') || type[1].includes('presentation')) {
                    return { img: 'mdi-file-powerpoint', color: 'e3-powerpoint'};
                }
                else if(type[1].includes('spreadsheet') || type[1].includes('excel')) {
                    return { img: 'mdi-file-excel', color: 'e3-excel'};
                }
                else if(type[1].includes('pdf')) {
                    return { img: 'mdi-file-pdf', color: 'e3-pdf'};
                }
                return { img: 'mdi-file', color: 'e3-file'};
            }
            else if(type[0] == 'text') {
                return { img: 'mdi-file-document-outline', color: 'e3-file'};
            }
            return { img: 'mdi-file', color: 'e3-file'};
        },
        returnFileSize(bytes, decimals = 2) {
            if (bytes === 0){
                return '0 Bytes';
            }
            const k = 1000;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
    },
};
