import {
    RESET_DOOR_CARD_STATE,
    GET_DOOR_CARD_CHILDREN,
    SET_DOOR_CARD_CHILDREN,
    GET_DOOR_CARD_DOORS,
    SET_DOOR_CARD_DOORS,
    SET_DOOR_CARD_DOOR,
    UPDATE_DOOR_CARD_DOOR,
    STORE_DOOR_CARD,
    UPDATE_DOOR_CARD,
    DELETE_DOOR_CARD,
    ADD_DOOR_CARD,
    SET_DOOR_CARD,
    REMOVE_DOOR_CARD,
    SET_DOOR_CARD_MESSAGE
} from './actions';
import Vue from 'vue';
import DoorCardService from '@services/door_card.service';
import orderBy from 'lodash/orderBy';

const getDefaultState = () => {
    return {
        children: [],
        doors: [],
        message: null
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_DOOR_CARD_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_DOOR_CARD_MESSAGE](state, message) {
        state.message = message;
    },
    [SET_DOOR_CARD_CHILDREN](state, children) {
        state.children = orderBy(children, ['mainCourse', 'lname'], ['asc', 'asc']);
    },
    [SET_DOOR_CARD_DOORS](state, doors) {
        state.doors = doors;
    },
    [SET_DOOR_CARD_DOOR](state, door) {
        let index = state.doors.findIndex(d => d.door_id === door.door_id);
        if (index !== -1) {
            Vue.set(state.doors, index, door);
        }
    },
    [ADD_DOOR_CARD](state, card) {
        let child = state.children.find(c => c.id === card.child_id);
        if(child) {
            child.door_cards.push(card);
            Vue.set(state.children, state.children.findIndex(c => c.id === child.id), child);
        }

    },
    [SET_DOOR_CARD](state, card) {
        let child = state.children.find(c => c.id === card.child_id);
        if(child) {
            let index = child.door_cards.findIndex(c => c.guardian_id === card.guardian_id);
            if(index > -1) {
                Vue.set(child.door_cards, index, card);
                Vue.set(state.children, state.children.findIndex(c => c.id === child.id), child);
            }
        }
    },
    [REMOVE_DOOR_CARD](state, data) {
        let child = state.children.find(c => c.id === data.childId);
        if(child) {
            let index = child.door_cards.findIndex(c => c.guardian_id === data.guardianId);
            if(index > -1) {
                child.door_cards.splice(index, 1);
                Vue.set(state.children, state.children.findIndex(c => c.id === child.id), child);
            }
        }
    }
};

const actions = {
    [GET_DOOR_CARD_CHILDREN]({commit, getters}, queryObj) {
        commit(SET_DOOR_CARD_MESSAGE, null);
        return DoorCardService.getDoorCardChildren(getters.activeKindergartenId, queryObj).then(res => {
            if(res.message) {
                commit(SET_DOOR_CARD_MESSAGE, res.message);
            } else {
                commit(SET_DOOR_CARD_CHILDREN, res);
            }

        });
    },
    [GET_DOOR_CARD_DOORS]({commit, getters}) {
        commit(SET_DOOR_CARD_MESSAGE, null);
        return DoorCardService.getDoorCardDoors(getters.activeKindergartenId).then(res => {
            if(res.message) {
                commit(SET_DOOR_CARD_MESSAGE, res.message);
            } else {
                commit(SET_DOOR_CARD_DOORS, res);
            }

        });
    },
    [UPDATE_DOOR_CARD_DOOR]({commit, getters}, data) {
        return DoorCardService.updateDoor(getters.activeKindergartenId, data).then(res => {
            commit(SET_DOOR_CARD_DOOR, res);
        });
    },
    [STORE_DOOR_CARD]({commit, getters}, data) {
        return DoorCardService.addGuardianCard(getters.activeKindergartenId, data.childId, data.guardianId, data).then(res => {
            commit(ADD_DOOR_CARD, res);
        });
    },
    [UPDATE_DOOR_CARD]({commit, getters}, data) {
        return DoorCardService.updateGuardianCard(getters.activeKindergartenId, data.childId, data.guardianId, data).then(res => {
            commit(SET_DOOR_CARD, res);
        });
    },
    [DELETE_DOOR_CARD]({commit, getters}, data) {
        return DoorCardService.deleteGuardianCard(getters.activeKindergartenId, data.childId, data.guardianId).then(res => {
            if(res.deleted) {
                commit(REMOVE_DOOR_CARD, data);
            }
            return res;
        });
    }
};

const getters = {
    doorCardChildren: state => state.children,
    doorCardDoors: state => state.doors,
    doorCardMessage: state => state.message
};

export default {
    state,
    mutations,
    actions,
    getters
};