<template>
    <div class="d-flex">
        <button v-if="isIcon" :disabled="disable" class="btn e3-rounded-icon-btn p-0 bg-danger" :class="{'e3-pointer' : !isDisabled}" @click="askConfirmation">
            <SvgIcon :icon="iconImg ? iconImg : 'mdi-trash-can'" :size="small ? '14' : '18'" class="text-white"/>
        </button>
        <button
            v-else
            type="button"
            :disabled="disable"
            class="btn"
            :class="[btnType, {'btn-sm':small}, {'w-100':btnWidth}]" @click="askConfirmation">
            <slot>
                {{ label !== '' ? label : $t('Delete') }}
            </slot>
        </button>
    </div>
</template>

<script>
import {eventBus} from '@/main';

export default {
    name: 'ConfirmButton',
    props: {
        iconImg: String,
        noConfirm: Boolean,
        icon: Boolean,
        small: {
            type: Boolean,
            default: false
        },
        btnWidth: Boolean,
        msg: {
            type: String,
            default: ''
        },
        type: String,
        success: String,
        extra: String,
        color: {
            type: String,
            default: 'danger'
        },
        label: {
            type: String,
            default: ''
        },
        confirmText: {
            type: String,
            default: 'Yes'
        },
        confirmHeader: {
            type: String,
            default: ''
        },
        disable: {
            type: Boolean,
            default: false
        },
        msgClass: {
            type: String,
            default: ''
        }
    },
    computed: {
        isIcon() {
            return this.icon;
        },
        isDisabled() {
            return this.disabled;
        },
        btnType() {
            if (this.type === 'warning') {
                return 'btn-warning';
            } else if (this.type === 'success') {
                return 'btn-success';
            } else if (this.type === 'primary') {
                return 'btn-primary';
            } else if (this.type === 'link') {
                return 'btn-link';
            }
            return 'btn-danger';
        }
    },
    methods: {
        askConfirmation() {
            if (this.noConfirm) {
                this.$emit('confirmed');
            } else {
                eventBus.$emit('show-action-confirmation', {
                    uid: this._uid,
                    msg: this.msg !== '' ? this.msg : this.$t('Are you sure?'),
                    msgClass: this.msgClass,
                    extra: this.extra,
                    color: this.color,
                    confirmText: this.confirmText,
                    confirmHeader: this.confirmHeader !== '' ? this.confirmHeader : this.$t('Confirm')
                });
            }
        }
    },
    mounted() {
        eventBus.$on('action-confirmed', ({uid}) => {
            if (uid === this._uid) {
                this.$emit('confirmed');
            }
        });
    }
};
</script>

<style scoped lang="scss">
    @import "@common/styles/variables/_variables.scss";
    .btn-link {
        color: $danger;

        &:hover,
        &:focus,
        &:active {
            color: $danger !important;
        }
    }
</style>
