export function getScrollbarWidth() {
  const div = document.createElement('div');
  div.style.visibility = 'hidden';
  div.style.overflow = 'scroll';
  div.style.width = '100px';
  document.body.appendChild(div);
  const scrollbarWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  return scrollbarWidth;
}

export function addScrollbarWidthToBody() {
  const scrollbarWidth = getScrollbarWidth();
  document.body.setAttribute('data-scrollbar-width', scrollbarWidth);
}
