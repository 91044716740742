import {
    DELETE_PRODUCT,
    DELETE_PRODUCT_IMG,
    GET_SUPPLIER_PRODUCTS,
    GET_SUPPLIER_PRODUCT,
    REMOVE_PRODUCT,
    SET_PRODUCTS,
    SET_PRODUCT,
    SET_PRODUCT_FORM,
    STORE_PRODUCT,
    UPDATE_PRODUCT
} from './actions';
import SupplierService from '@services/supplier.service';
import Vue from 'vue';

const getDefaultState = () => {
    return {
        products: {
            data: []
        },
        productForm: null
    };
};

export const productState = getDefaultState();

export const productMutations = {
    [SET_PRODUCT](state, product) {
        const index = state.products?.data.findIndex(p => p.id === product.id);
        if (index > -1) {
            Vue.set(state.products.data, index, product);
        } else {
            state.products.data.push(product);
        }
    },
    [SET_PRODUCTS](state, data) {
        state.products = data;
    },
    [SET_PRODUCT_FORM](state, data) {
        state.productForm = data;
    },
    [REMOVE_PRODUCT](state, product) {
        const index = state.products.data.findIndex(p => p.id === product.id);
        state.products.data.splice(index, 1);
    }
};

export const productActions = {
    [GET_SUPPLIER_PRODUCTS]({getters, commit}, queryObj) {
        return SupplierService.products(getters.activeSupplierId, queryObj).then(res => {
            commit(SET_PRODUCTS, res);
        });
    },
    [GET_SUPPLIER_PRODUCT]({commit}, {supplierId, productId}) {
        return SupplierService.product(supplierId, productId).then(res => {
            commit(SET_PRODUCT_FORM, res);
        });
    },
    [STORE_PRODUCT]({getters, commit}, {form}) {
        return SupplierService.storeProduct(getters.activeSupplierId, form).then(res => {
            commit(SET_PRODUCT, res);
        });
    },
    [UPDATE_PRODUCT]({getters, commit}, {form, productId}) {
        return SupplierService.updateProduct(getters.activeSupplierId, form, productId).then(res => {
            commit(SET_PRODUCT_FORM, res);
        });
    },
    [DELETE_PRODUCT]({getters, commit}, product) {
        return SupplierService.deleteProduct(getters.activeSupplierId, product.id).then(() => {
            commit(REMOVE_PRODUCT, product);
        });
    },
    [DELETE_PRODUCT_IMG]({getters, commit}, imgId) {
        return SupplierService.deleteProductImg(getters.activeSupplierId, imgId).then(res => {
            commit(SET_PRODUCT, res);
        });
    }
};

export const productGetters = {
    supplierProducts: state => state.products,
    productForm: state => state.productForm
};
