export const RESET_PLAN_STATE = 'RESET_PLAN_STATE';
export const GET_PLAN = 'GET_PLAN';
export const SET_PLAN = 'SET_PLAN';
export const STORE_PLAN = 'STORE_PLAN';
export const UPDATE_PLAN_FIELD = 'UPDATE_PLAN_FIELD';
export const GET_PLAN_TYPES = 'GET_PLAN_TYPES';
export const SET_PLAN_TYPES = 'SET_PLAN_TYPES';
export const GET_COURSE_PLANS = 'GET_COURSE_PLANS';
export const SET_COURSE_PLANS = 'SET_COURSE_PLANS';
export const GET_COURSE_PLAN_TYPES = 'GET_COURSE_PLAN_TYPES';
export const SET_COURSE_PLAN_TYPES = 'SET_COURSE_PLAN_TYPES';
export const ADD_COURSE_PLAN = 'ADD_COURSE_PLAN';
export const STORE_PLAN_SETTING = 'STORE_PLAN_SETTING';
export const GET_PLAN_SETTINGS = 'GET_PLAN_SETTINGS';
export const SET_PLAN_SETTINGS = 'SET_PLAN_SETTINGS';
export const UPDATE_PLAN_SETTING = 'UPDATE_PLAN_SETTING';
export const ADD_PLAN_SETTING = 'ADD_PLAN_SETTING';
export const DELETE_PLAN_SETTING = 'DELETE_PLAN_SETTING';
export const REMOVE_PLAN_SETTING = 'REMOVE_PLAN_SETTING';
export const GET_PROJECT_PLANS = 'GET_PROJECT_PLANS';
export const SET_PROJECT_PLANS = 'SET_PROJECT_PLANS';
export const GET_PROJECT_PLAN = 'GET_PROJECT_PLAN';
export const UPDATE_PROJECT_PLAN = 'UPDATE_PROJECT_PLAN';
export const STORE_PROJECT_PLAN = 'STORE_PROJECT_PLAN';
export const CONFIRM_PLAN = 'CONFIRM_PLAN';
export const REJECT_PLAN = 'REJECT_PLAN';
export const SET_PLAN_STATUS = 'SET_PLAN_STATUS';
export const PUBLISH_PLAN = 'PUBLISH_PLAN';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_PLAN_DATE = 'SET_PLAN_DATE';
export const SET_PROJECT_PLAN = 'SET_PROJECT_PLAN';
export const SELECT_PROJECT_PLAN = 'SELECT_PROJECT_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';
export const REMOVE_COURSE_PLAN = 'REMOVE_COURSE_PLAN';
export const SET_PLAN_ERROR = 'SET_PLAN_ERROR';
export const SET_SELECTED_PLAN_TYPE = 'SET_SELECTED_PLAN_TYPE';
export const SET_SUMMARY_OPTIONS = 'SET_SUMMARY_OPTIONS';
export const UPLOAD_PLAN_DOCUMENT = 'UPLOAD_PLAN_DOCUMENT';
export const ADD_PLAN_DOCUMENT = 'ADD_PLAN_DOCUMENT';
export const SET_PLAN_DOCUMENTS = 'SET_PLAN_DOCUMENTS';
export const DELETE_PLAN_DOCUMENT = 'DELETE_PLAN_DOCUMENT';
export const REMOVE_PLAN_DOCUMENT = 'REMOVE_PLAN_DOCUMENT';
export const GET_YEAR_PLANS = 'GET_YEAR_PLANS';
export const SET_YEAR_PLANS = 'SET_YEAR_PLANS';
export const GET_YEAR_PLAN = 'GET_YEAR_PLAN';
export const SELECT_YEAR_PLAN = 'SELECT_YEAR_PLAN';
export const STORE_YEAR_PLAN = 'STORE_YEAR_PLAN';
export const GET_COURSE_PLAN_OVERVIEW = 'GET_COURSE_PLAN_OVERVIEW';
export const UPDATE_YEAR_PLAN = 'UPDATE_YEAR_PLAN';
