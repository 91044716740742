<template>
    <div>
        <label class="switch mb-0">
            <input id="id" v-model="valModel" :value="val" :disabled="disabled" type="checkbox">
            <span class="slider" :class="{round : rounded}"></span>
        </label>
        <span v-if="label" class="text-break ml-2" :class="[{'d-none d-xl-inline-flex' : hideText}, textClass]">{{ label }}</span>
    </div>
</template>

<script>
export default {
    name: 'EToggle',
    props: {
        value: [Object, Boolean, Array, Number, String],
        val: [Boolean, Number, String, Object],
        id: {
            type: String,
            required: true
        },
        label: String,
        disabled: Boolean,
        rounded: Boolean,
        hideText: {
            type: Boolean,
            default: false
        },
        textClass: String,
    },
    computed: {
        valModel: {
            get() {
                return !!+this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    min-width: 44px;
    height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e082b1;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #a5c365;
}

input:focus + .slider {
    box-shadow: 0 0 1px #a5c365;
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>