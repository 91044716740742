import routes from '@/routes/routes';
import http from '@http';
import store from '@/store';

class AuthService {
    constructor() {
        if (AuthService.instance) {
            return AuthService.instance;
        }

        this._isAuthorized = !!localStorage.getItem('authentication');

        AuthService.instance = this;
    }

    get isAuthorized() {
        return this._isAuthorized;
    }

    socialLogin(data) {
        let url = `/api/auth/callback/${data.social}/${data.action}`;

        return http.post(url, data).then(res => {
            if (res.status === 200) {
                this._isAuthorized = true;
                localStorage.setItem('authentication', 'done');
                localStorage.removeItem('register_role');
                localStorage.removeItem('register_country');

                const proceed = document.cookie
                    .split(';')
                    .find(row => row.trim().startsWith('sso='))
                    ?.split('=')[1] ? 'sso_auth' : 'dashboard';

                this.getUserAndPermissions(data.platform, proceed);
            } else {
                console.error('res', res);
            }
        }).catch(error => {
            if (error.response.status === 404 && error.response.data.message.startsWith('No associated user found')) {
                // Set modal flag and redirect to front page
                const email = error.response.data.message.split(': ').pop();
                localStorage.setItem('social_login_no_user', email);
            } else if (error.response.status !== 200) {
                console.error('res', error.response.status, error.response.data.message);
                localStorage.setItem('social_login_error', error.response.data.message);
            }
            location.reload();
        });
    }

    socialLoginHandled() {
        localStorage.setItem('authentication', 'done');
    }

    registerUser(data) {
        return http.post('/api/auth/register', data).then(res => {
            if (res.status === 200) {
                localStorage.removeItem('register_role');
                localStorage.removeItem('register_country');
                return res.data;
            } else {
                console.error('reg error', res);
            }
        });
    }

    /**
     * This is triggered from employee user register invite and will create a verified user
     */
    registerEmployeeUser(data) {
        return http.post('/api/auth/verification/register', data).then(res => res.data);
    }

    login(data) {
        return http.post('/api/auth/login', data).then(res => {
        if (res.status === 200) {
                this._isAuthorized = true;
                localStorage.setItem('authentication', 'done');
                const proceed = routes.currentRoute.name === 'sso_auth' ? '' : 'dashboard';
                this.getUserAndPermissions(data.platform, proceed);
            }
        }).catch(error => {
            store.dispatch('STOP_LOGIN_LOADER');
            if(error.status === 202) {
                return error.data.message;
            }
        });
    }

    getUserAndPermissions(platform, proceed = 'dashboard') {
        store.dispatch('GET_USER', platform).then(() => {
            if (store.getters.user && store.getters.user.email_verified_at) {
                store.dispatch('GET_PERMISSIONS').then(() => {
                    if (proceed === 'dashboard') {
                        store.dispatch('GET_USER_HEADER_INFO');
                        routes.push('/dashboard');
                    } else if (proceed === 'sso_auth') {
                        routes.push('/sso/auth');
                    }
                });
            }
            // else {
            //     console.log('email not verified');
            // }
        });
    }

    loginAs(id) {
        http.get(`/api/admin/login/${id}`).then(() => {
            localStorage.setItem('login_as', 'done');
            window.location = '/dashboard';
        });
    }

    logout(redirectPath) {
        if (!this._isAuthorized || !localStorage.getItem('authentication')) {
            location.reload();
            return;  //location.reload(); before login reload bad, after login reload good when suddenly unauthenticated
        }

        this._isAuthorized = false;

        http.get('/api/auth/logout', {params:{platform: localStorage.getItem('platform')}})
            .catch(() => console.error('Already logged out')).finally(() => {
                clearStorage();
                if (redirectPath) {
                    routes.replace(redirectPath, () => location.reload());
                } else {
                    location.reload();
                }
            });
    }

    reloadEnvironment() {
        if(this._isAuthorized) {
            this._isAuthorized = false;
            clearStorage();
            location.reload();
        }
    }

    /**
     * Do a reload after calling this!
     * @param id
     * @returns {Promise<AxiosResponse<any> | void>}
     */
    logoutAs(id) {
        return http.post(`/api/admin/logout/${id}`).then(() => {
            localStorage.removeItem('login_as');
        }).catch(() => console.error('Logout failed'));
    }

    requestForgotPassword(data) {
        return http.post('/api/auth/password/email', data).then(res => res.data);
    }

    resendEmailVerification(username) {
        return http.post('/api/auth/verification/resend', username).then(res => res.data);
    }

    resetPassword(data) {
        return http.post('/api/auth/password/reset', {
                email: data.email,
                token: data.token,
                password: data.password,
                password_confirmation: data.password_confirmation
            }).then(res => {
                if (res.status === 200) {
                    return res.data;
                }
                // else {
                //     console.log(res);
                // }
            });
    }

    verifyResetToken(data) {
        return http.post('/api/auth/password/verify', data).then(res => res.data);
    }
}

function clearStorage() {
    const lang = localStorage.getItem('lang');
    const cookieAccepted = localStorage.getItem('cookie:accepted');
    const eliisPopupClosed = localStorage.getItem('eliisPopupClosed');
    const buildVersion = localStorage.getItem('buildVersion');
    localStorage.clear();
    cookieAccepted && localStorage.setItem('cookie:accepted', 'true');
    buildVersion && localStorage.setItem('buildVersion', buildVersion);
    lang && localStorage.setItem('lang', lang);
    eliisPopupClosed && localStorage.setItem('eliisPopupClosed', eliisPopupClosed);
}

export default new AuthService();
