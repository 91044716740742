export const SET_SUB_FOLDERS = 'SET_SUB_FOLDERS';
export const GET_FOLDER = 'GET_FOLDER';
export const SET_FOLDER = 'SET_FOLDER';
export const SET_FOLDER_FILES = 'SET_FOLDER_FILES';
export const GET_FOLDER_ACL = 'GET_FOLDER_ACL';
export const ADD_ACL_TO_FOLDER = 'ADD_ACL_TO_FOLDER';
export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const UPDATE_SUB_FOLDER = 'UPDATE_SUB_FOLDER';
export const EDIT_FOLDER = 'EDIT_FOLDER';
export const EDIT_SUB_FOLDER = 'EDIT_SUB_FOLDER';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const ADD_FOLDER = 'ADD_FOLDER';
export const ADD_FILE = 'ADD_FILE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const REMOVE_FOLDER = 'REMOVE_FOLDER';
export const RESET_FOLDER_STATE = 'RESET_FOLDER_STATE';
export const REMOVE_FOLDER_FILE = 'REMOVE_FOLDER_FILE';
export const UPDATE_FOLDER_FILE = 'UPDATE_FOLDER_FILE';
export const GET_SUB_FOLDERS = 'GET_SUB_FOLDERS';
export const RESET_SUB_FOLDERS = 'RESET_SUB_FOLDERS';
export const SET_FOLDER_MAX_PAGE = 'SET_FOLDER_MAX_PAGE';
export const SET_FOLDER_TOTAL = 'SET_FOLDER_TOTAL';
export const GET_FOLDER_FILES = 'GET_FOLDER_FILES';
export const ADD_TO_SUB_FOLDERS = 'ADD_TO_SUB_FOLDERS';
export const SET_ACTIVE_FOLDER_ID = 'SET_ACTIVE_FOLDER_ID';
export const MOVE_FOLDER_FILES = 'MOVE_FOLDER_FILES';
export const MOVE_FOLDER_SUBFOLDERS = 'MOVE_FOLDER_SUBFOLDERS';
export const REMOVE_FOLDER_FILES_BY_ID = 'REMOVE_FOLDER_FILES_BY_ID';
export const REMOVE_FOLDER_SUBFOLDERS_BY_ID = 'REMOVE_FOLDER_SUBFOLDERS_BY_ID';
export const BULK_DELETE_FOLDER_FILES = 'BULK_DELETE_FOLDER_FILES';
export const SET_FOLDER_SETTINGS = 'SET_FOLDER_SETTINGS';
