<template>
    <div class="align-items-center">
        <ErrorContent v-if="!courseId"/>
        <ELoader v-else-if="initialLoad && isContentLoading"/>
        <template v-else>
            <PageTitle>
                {{ pageTitle }}
            </PageTitle>

            <!-- Section header -->
            <div class="d-flex flex-row flex-wrap">
                <!-- Breadcrumbs -->
                <h5 class="d-flex flex-wrap mb-0 mr-auto">
                    <router-link :to="{name: 'gallery.index' }">
                        {{ $t('Gallery') }}
                    </router-link>
                    <span v-for="crumb in breadcrumbs" :key="`c-${crumb.id}`" class="d-flex">
                        <SvgIcon icon="mdi-chevron-right" source="menu-icons" size="24"/>
                        <router-link
                            v-if="crumb.id !== courseId"
                            :to="{
                                name: 'gallery.builtin.diary.index',
                                params: { course_id: typeof crumb.id === 'string' ? null : crumb.id }
                            }"
                            class="nav-link p-0"
                            :title="crumbName(crumb)">
                            {{ truncate(crumbName(crumb), 20) }}
                        </router-link>
                        <span v-else :title="crumbName(crumb)">{{ truncate(crumbName(crumb), 20) }}</span>
                    </span>
                </h5>

                <!-- Section header.search -->
                <div class="ml-auto d-flex flex-column align-items-center mt-4 mt-md-0">
                    <div class="d-flex align-items-center w-100">
                        <ESearchInput
                            v-model="searchValue"
                            :disabled="isContentLoading"
                            :placeholder="$t('Type to search')"
                            class="flex-grow-1"
                            @getEntities="search"/>
                        <button class="btn btn-primary ml-1" :title="$t('Change date range')" @click="openDatesModal">
                            <SvgIcon icon="mdi-calendar" size="22" class="text-white"/>
                        </button>
                    </div>
                    <div class="mt-2 w-100">
                        <EDropdown
                            :text="orderByLabel"
                            variant="primary"
                            :items="orderByOptions"
                            :active="order"
                            keyName="key"/>
                    </div>
                </div>
            </div>

            <!-- Section content -->
            <div v-if="files.length" class="container-fluid px-0">
                <div class="row d-flex flex-wrap justify-content-center justify-content-sm-start">
                    <FileGrid
                        :files="files"
                        customFilesCount
                        :filesCount="filesCount"
                        type="gallery_builtin_diary"
                        class="container-fluid"/>
                </div>
                <div class="row d-flex flex-wrap justify-content-center">
                    <div v-if="showLoadMore" class="d-flex mt-3">
                        <EBtnLoader
                            color="primary"
                            :isSubmitLoading="isContentLoading"
                            @clicked="loadMore">
                            {{ $t("Load more") }}
                        </EBtnLoader>
                    </div>
                </div>
            </div>

            <EmptyListMessage v-else class="border rounded p-2 bg-white mt-3">
                {{ $t('No files found') }}
            </EmptyListMessage>
        </template>
        <GalleryDiaryDatesModal @apply-dates="applyDates"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {eventBus} from '@/main';
import {truncate} from '@/filters';
import FolderService from '@common/services/folder.service';
import ErrorContent from '@components/ErrorContent.vue';
import FileGrid from '@/views/folder/components/grid/FileGrid.vue';
import GalleryDiaryDatesModal from '@/views/gallery/modals/GalleryDiaryDatesModal.vue';

export default {
    name: 'GalleryDiaryFolder',
    data() {
        return {
            initialLoad: true,
            breadcrumbs: [],
            courseId: 0,
            files: [],
            filesCount: null,
            filesPerPage: 18,
            page: 1,
            lastPage: 1,
            hasMoreFiles: false,
            searchValue: '',
            startDate: this.$moment().format('YYYY-MM-DD'),
            endDate: this.$moment().format('YYYY-MM-DD'),
            fileIds: [],
            order: 'desc',
            orderByOptions: [
                { key: 'desc', name: this.$t('Newest first'), action: () => this.setOrder('desc') },
                { key: 'asc' , name: this.$t('Oldest first'), action: () => this.setOrder('asc') },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'activeKindergartenId',
            'isContentLoading',
        ]),
        pageTitle() {
            const title = this.$t('Album');
            const folder = this.crumbName(this.breadcrumbs.slice(-1)[0]);
            return title.concat(': ', folder);
        },
        showLoadMore() {
            return this.hasMoreFiles;
        },
        orderByLabel() {
            return this.orderByOptions.find(o => o.key === this.order).name;
        },
    },
    methods: {
        truncate, // TODO: Vue3 remove
        crumbName(crumb) {
            if (!crumb) {
                return '';
            }
            if (crumb.name_string) {
                return this.$t(crumb.name_string);
            } else {
                return crumb.name;
            }
        },
        /** @param {string} searchTerm */
        getFiles(searchTerm) {
            this.$store.dispatch('START_CONTENT_LOADER');

            let params = {
                module: 'gallery',
                page: this.page,
                perPage: this.filesPerPage,
                order: this.order,
            };

            if (searchTerm && searchTerm.trim()) {
                params.term = searchTerm;
            }

            if (!this.initialLoad) {
                params.start_date = this.startDate;
                params.end_date = this.endDate;
            }

            const promise = FolderService.getBuiltinDiaryFiles(this.activeKindergartenId, this.courseId, params).then(res => {
                if (this.initialLoad) {
                    this.initialLoad = false;
                    this.startDate = res.start_date;
                    this.endDate = res.end_date;
                }
                this.breadcrumbs = res.breadcrumbs;
                this.hasMoreFiles = res.files.has_more;
                return res.files.data;
            }).catch(err => {
                this.initialLoad = false;
                return Promise.reject(err);
            }).finally(() => {
                this.$store.dispatch('STOP_CONTENT_LOADER');
            });

            if (this.initialLoad || this.page == 1) {
                FolderService.getBuiltinDiaryFilesCount(this.activeKindergartenId, this.courseId, params).then(res => {
                    this.filesCount = res.count;
                });
            }

            return promise;
        },
        search() {
            this.page = 1;
            this.fileIds = [];
            this.getFiles(this.searchValue).then(files => {
                this.files = files;
            });
        },
        loadMore() {
            ++this.page;
            this.getFiles(this.searchValue).then(files => {
                files = files.filter(f => !this.fileIds.includes(f.id));
                this.files = this.files.concat(files);
                this.fileIds = this.fileIds.concat(files.map(file => file.id));
            });
        },
        setOrder(order) {
            if (order !== this.order) {
                this.order = order;
                this.page = 1;
                this.fileIds = [];
                this.getFiles(this.searchValue).then(files => {
                    this.files = files;
                });
            }
        },
        openDatesModal() {
            eventBus.$emit('open-gallery-diary-dates-modal', { startDate: this.startDate, endDate: this.endDate});
        },
        applyDates({ startDate, endDate }) {
            this.startDate = startDate;
            this.endDate = endDate;
            this.search();
        }
    },
    components: {
        ErrorContent,
        FileGrid,
        GalleryDiaryDatesModal,
    },
    created() {
        this.courseId = parseInt(this.$route.params.course_id);
        if (this.courseId) {
            this.getFiles().then(files => {
                this.fileIds = files.map(file => file.id);
                this.files = files;
            });
        }
    },
};
</script>
