<template>
    <div>
        <template v-if="folders.length || searchLoading">
            <h3 class="h4 d-flex align-items-center mb-2 mt-4">
                <span class="mr-2">{{ type === 'document' ? $t("Folders") : $t("Albums") }}</span>
                <small>({{ folderTotalFolders }})</small>
            </h3>
            <div v-if="!searchLoading">
                <div class="row d-flex flex-wrap mx-n2 justify-content-center justify-content-sm-start">
                    <div v-for="folder in folders" :key="folder.id" class="mx-1 mx-lg-2 my-1 my-lg-2">
                        <FolderContainer
                            :id="`folder-${folder.id}`"
                            :folder="folder" :type="type"
                            isGrid tools
                            class="d-inline-block" :searchValue="searchValue"/>
                    </div>
                </div>
                <div v-if="page < folderMaxPage" class="d-flex mt-3">
                    <button type="button" class="btn btn-primary mx-auto" @click="loadMore">
                        {{ $t("Load more") }}
                    </button>
                </div>
            </div>
            <ELoader v-else class="p-0"/>
        </template>
        <EmptyListMessage v-else-if="searching" class="border rounded p-2 bg-white mt-3">
            {{ $t("No folders found") }}
        </EmptyListMessage>
    </div>
</template>

<script>
import FolderContainer from '@views/folder/components/FolderContainer';
import {mapGetters} from 'vuex';
export default {
    props: {
        folders: Array,
        type: String,
        page: Number,
        searchValue: String,
        searchLoading: Boolean,
        searching: Boolean,
    },
    data() {
        return {
            newCreated: false
        };
    },
    computed: {
        ...mapGetters([
            'folderMaxPage',
            'folderTotalFolders',
        ]),
    },
    methods: {
        loadMore() {
            this.$emit('loadMore');
        },
    },
    components: {
        FolderContainer
    }
};
</script>