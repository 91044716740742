<template>
    <div :class="{'form-group': isLabelSlot}">
        <label v-if="isLabelSlot" :for="id" :class="labelClass">
            <slot name="label"/>
        </label>
        <multiselect
            :id="id"
            v-model="val"
            :placeholder="placeholder !== '' ? placeholder : $t('Search to add a service') + '...'"
            :options="services"
            searchable
            :label="label"
            :select-label="$t('Choose')"
            :selected-label="$t('Selected')"
            :deselect-label="$t('Remove')"
            :loading="isLoading"
            :internal-search="false"
            :custom-label="
                service => services.some(k => k.id === service.id)
                    ? `${$t(services.find(k => k.id === service.id).category.name)} - ${services.find(k => k.id === service.id).name.et} ${services.find(k => k.id === service.id).supplier_price_net}€`
                    : null"
            @select="onSelect"
            @search-change="search"
            @remove="onRemove">
            <span slot="noResult">{{ $t('Nothing found') }}</span>
            <span slot="noOptions">{{ $t('Type to search') }}</span>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import SupplierService from '@services/supplier.service';

export default {
    name: 'SupplierServiceSearch',
    props: {
        id: [String, Number],
        value: [Object, String, Number, Array],
        label: String,
        placeholder: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            services: [],
            isLoading: false
        };
    },
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        labelClass() {
            if (this.inline) {
                return 'col-md-5 col-form-label text-md-right text-sm-left';
            }

            return '';
        }
    },
    methods: {
        search(query) {
            this.isLoading = true;
            SupplierService.searchSupplierServices(this.$store.getters.activeSupplierId, query).then(res => {
                this.services = res;
                this.isLoading = false;
            });
        },
        onSelect(value) {
            this.$emit('select', value);
        },
        onRemove(value) {
            this.$emit('remove', value);
        }
    },
    components: {
        Multiselect
    }
};
</script>
