import Vue from 'vue';
import {
    GET_USER_DASHBOARD,
    SET_DASHBOARD_MESSAGES,
    SET_DASHBOARD_ANNOUNCEMENT,
    SET_DASHBOARD_APPLICATIONS,
    SET_DASHBOARD_BIRTHDAYS,
    GET_DASHBOARD_BIRTHDAYS,
    SET_DASHBOARD_ATTENDANCE_COUNT,
    GET_DASHBOARD_ATTENDANCE_COUNT,
    SET_DASHBOARD_DIARY_COMMENTS,
    GET_DASHBOARD_DIARY_COMMENTS,
    SET_DASHBOARD_ACTIVITY,
    RESET_DASHBOARD_STORE,
    SET_DASHBOARD_EVENTS,
    SET_DASHBOARD_WORK_SCHEDULES,
    SET_DASHBOARD_EMPLOYEE_BIRTHDAYS,
    SET_DASHBOARD_POLL_DATA,
    SET_DASHBOARD_QUESTIONNAIRES,
    SUBMIT_DASHBOARD_POLL,
    GET_DASHBOARD_POLL,
    GET_EMPLOYEE_DASHBOARD_SYNC,
    SET_DASHBOARD_COURSES_LOADING,
} from './actions';
import UserService from '@services/user.service';
import CourseService from '@services/course.service';
import DiaryService from '@services/diary.service';
import {convertTimesFromUtc} from '../../storeHelpers';
import store from '../../../store';
import PollService from '@services/poll.service';

function convertFromUtc(data) {
    const dataCopy = {...data};
    if (dataCopy.start_time) {
        let moment = convertTimesFromUtc(dataCopy.start_date, dataCopy.start_time, store.getters.user.timezone, dataCopy.repeat_start);
            dataCopy.start_date = moment.date;
            dataCopy.start_time = moment.time;
    }

    if (dataCopy.end_time) {
        let moment = convertTimesFromUtc(dataCopy.end_date, dataCopy.end_time, store.getters.user.timezone, dataCopy.repeat_start);
        dataCopy.end_time = moment.time;
    }

    return dataCopy;
}

const defaultState = () => {
    return {
        applications: {
            applications: [],
            count: 0
        },
        messages: [],
        announcement: {},
        child_birthdays: [],
        attendance_count: 0,
        diary_comments: [],
        events: [],
        work_schedules: [],
        activity: null,
        employee_birthdays: [],
        dashboardPollData: {poll: null, poll_count: 0},
        dashboardQuestionnaires: [],
        dashboardCoursesLoading: false,
    };
};
const state = defaultState;
const mutations = {
    [SET_DASHBOARD_MESSAGES](state, messages) {
        state.messages = messages;
    },
    [SET_DASHBOARD_ANNOUNCEMENT](state, announcement) {
        state.announcement = announcement;
    },
    [SET_DASHBOARD_APPLICATIONS](state, applications) {
        state.applications = applications;
    },
    [SET_DASHBOARD_BIRTHDAYS](state, birthdays) {
        state.child_birthdays = birthdays;
    },
    [SET_DASHBOARD_ATTENDANCE_COUNT](state, count) {
        state.attendance_count = count;
    },
    [SET_DASHBOARD_DIARY_COMMENTS](state, comments) {
        state.diary_comments = comments;
    },
    [SET_DASHBOARD_ACTIVITY](state, date) {
        state.activity = date;
    },
    [SET_DASHBOARD_EVENTS](state, events) {
        const convertedEvents = [];
        events.forEach(event => {
            const e = convertFromUtc(event);
            if (e.is_recurring) {
                e.end_date = Vue.moment(e.start_date).add(e.duration, 'days').format('YYYY-MM-DD');
            }
            convertedEvents.push(e);
        });

        state.events = convertedEvents;
    },
    [SET_DASHBOARD_WORK_SCHEDULES](state, schedules) {
        const converted = [];
        schedules.forEach(s => {
            const e = convertFromUtc(s);

            converted.push(e);
        });

        state.work_schedules = converted;
    },
    [SET_DASHBOARD_EMPLOYEE_BIRTHDAYS](state, birthdays) {
      state.employee_birthdays = birthdays;
    },
    [SET_DASHBOARD_POLL_DATA](state, data) {
        state.dashboardPollData = data;
    },
    [SET_DASHBOARD_QUESTIONNAIRES](state, data) {
       state.dashboardQuestionnaires = data;
    },
    [RESET_DASHBOARD_STORE](state) {
        Object.assign(state, defaultState());
    },
    [SET_DASHBOARD_COURSES_LOADING](state, value) {
        state.dashboardCoursesLoading = value;
    },

};
const actions = {
    [GET_USER_DASHBOARD]({commit, getters}) {
        commit('START_CONTENT_LOADER');
        let options = {};
        if(getters.activeKindergartenId) {
            options.kindergarten_id = getters.activeKindergartenId;
            if(getters.selectedCourse) {
                options.course_id = getters.selectedCourse.id;
            }
        }
        commit(RESET_DASHBOARD_STORE);

        return UserService.getUserDashboard({params: options}).then(res => {
            if(res.employee_applications) {
                commit(SET_DASHBOARD_APPLICATIONS, res.employee_applications);
            }
            if(res.messages) {
                commit(SET_DASHBOARD_MESSAGES, res.messages);
            }
            if(res.announcement) {
                commit(SET_DASHBOARD_ANNOUNCEMENT, res.announcement);
            }
            if(res.child_birthdays) {
                commit(SET_DASHBOARD_BIRTHDAYS, res.child_birthdays);
            }
            if(res.attendance_count) {
                commit(SET_DASHBOARD_ATTENDANCE_COUNT, res.attendance_count);
            }
            if(res.diary_comments) {
                commit(SET_DASHBOARD_DIARY_COMMENTS, res.diary_comments);
            }
            if(res.activity) {
                commit(SET_DASHBOARD_ACTIVITY, res.activity);
            }

            if(res.events) {
                commit(SET_DASHBOARD_EVENTS, res.events);
            }

            if(res.work_schedules) {
                commit(SET_DASHBOARD_WORK_SCHEDULES, res.work_schedules);
            }

            if(res.employee_birthdays) {
                commit(SET_DASHBOARD_EMPLOYEE_BIRTHDAYS, res.employee_birthdays);
            }

            if(res.poll_data) {
                commit(SET_DASHBOARD_POLL_DATA, res.poll_data);
            }
            if(res.questionnaires) {
                commit(SET_DASHBOARD_QUESTIONNAIRES, res.questionnaires);
            }

        }).finally(() => {
            commit('STOP_CONTENT_LOADER');
        });
    },
    [GET_EMPLOYEE_DASHBOARD_SYNC]({commit, getters}, courseId) {
        if(getters.currentEmployee) {
            commit(SET_DASHBOARD_COURSES_LOADING, true);
            return CourseService.getEmployeeCoursesDashboardSync(getters.activeKindergartenId, courseId).then(res => {
                commit(SET_DASHBOARD_DIARY_COMMENTS, res.diary_comments);
                commit(SET_DASHBOARD_ATTENDANCE_COUNT, res.attendance_count);
                commit(SET_DASHBOARD_BIRTHDAYS, res.child_birthdays);
            }).finally(() => {
                commit(SET_DASHBOARD_COURSES_LOADING, false);
            });
        }
    },
    [GET_DASHBOARD_BIRTHDAYS]({commit, getters}, courseId) {
        return CourseService.getCourseBirthdays(getters.activeKindergartenId, courseId).then(res => {
                commit(SET_DASHBOARD_BIRTHDAYS, res.child_birthdays);
            });
    },
    [GET_DASHBOARD_ATTENDANCE_COUNT]({commit, getters}, courseId) {
        return DiaryService.getTodaysAttendance(getters.activeKindergartenId, courseId).then(res => {
            commit(SET_DASHBOARD_ATTENDANCE_COUNT, res.attendance_count);
        });
    },
    [GET_DASHBOARD_DIARY_COMMENTS]({commit, getters}, courseId) {
        return DiaryService.getTodaysComments(getters.activeKindergartenId, courseId).then(res => {
            commit(SET_DASHBOARD_DIARY_COMMENTS, res.diary_comments);
        });
    },
    [GET_DASHBOARD_POLL]({commit, getters}, skip) {
      return PollService.getDashboardPoll(getters.activeKindergartenId, skip).then(res => {
          commit(SET_DASHBOARD_POLL_DATA, res.poll_data);
          return res;
      });
    },
    [SUBMIT_DASHBOARD_POLL]({commit, getters}, data) {
        return PollService.submitDashboardPoll(getters.activeKindergartenId, data.survey_id, data).then(res => {
            commit(SET_DASHBOARD_POLL_DATA, res);
        });
    }
};
const getters = {
    dashboardMessages: state => {
        return state.messages;
    },
    dashboardAnnouncement: state => {
        return state.announcement;
    },
    dashboardApplications: state => {
        return state.applications;
    },
    dashboardChildBirthdays: state => {
        return state.child_birthdays;
    },
    dashboardAttendance: state => {
        return state.attendance_count;
    },
    dashboardDiaryComments: state => {
        return state.diary_comments.filter(function(item) {
            return item.comment && item.comment !== '';
        });
    },
    dashboardActivity: state => {
        return state.activity;
    },
    dashboardEvents: state => state.events,
    dashboardWorkSchedules: state => state.work_schedules,
    dashboardEmployeeBirthdays: state => state.employee_birthdays,
    dashboardPollData: state => state.dashboardPollData,
    dashboardQuestionnaires: state => state.dashboardQuestionnaires,
    dashboardCoursesLoading: state => state.dashboardCoursesLoading,
};

export default {
    state,
    mutations,
    actions,
    getters
};
