import http from '@http';

class IdeaService 
{
    constructor() 
    {
        if (IdeaService.instance) {
            return IdeaService.instance;
        }
        IdeaService.instance = this;
    }

    deleteIdea(kindergartenId, ideaId) 
    {
        return http
            .delete(`/api/kindergartens/${kindergartenId}/idea/${ideaId}`)
            .then(res => res.data);
    }

    deleteIdeaComment(kindergartenId, ideaId, commentId) 
    {
        return http
            .delete(`/api/kindergartens/${kindergartenId}/idea/${ideaId}/comment/${commentId}`)
            .then(res => res.data);
    }

    deleteIdeaImage(kindergartenId, filename) 
    {
        return http
            .delete(`/api/kindergartens/${kindergartenId}/files/idea/${filename}`)
            .then(res => res.data);
    }

    getIdea(kindergartenId, ideaId) 
    {
        return http
            .get(`/api/kindergartens/${kindergartenId}/idea/${ideaId}`)
            .then(res => res.data);
    }

    getIdeas(kindergartenId, form) 
    { 
        return http
            .get(`/api/kindergartens/${kindergartenId}/ideas/`, {params: form})
            .then(res => res.data);
    }

    getIdeasFromAuthor(kindergartenId, ideaId) 
    { 
        return http
            .get(`/api/kindergartens/${kindergartenId}/idea/${ideaId}/others`)
            .then(res => res.data);
    }

    getIdeaAgeGroups(kindergartenId, queryObj) 
    {
        return http.get(`/api/kindergartens/${kindergartenId}/idea/age-groups`, {params: queryObj}).then(res => res.data);
    }

    getIdeaCategories(kindergartenId, queryObj) 
    {
        return http.get(`/api/kindergartens/${kindergartenId}/idea/categories`, {params: queryObj}).then(res => res.data);
    }
    
    getIdeaComments(kindergartenId, ideaId, queryObj) 
    {
        return http.get(`/api/kindergartens/${kindergartenId}/idea/${ideaId}/comments`, {params: queryObj}).then(res => res.data);
    }

    storeIdeaComment(kindergartenId, ideaId, data)
    {
        return http.post(`/api/kindergartens/${kindergartenId}/idea/${ideaId}/comment`, data).then(res => res.data);
    }

    addIdeaToFavorites(kindergartenId, ideaId)
    {
        return http.post(`/api/kindergartens/${kindergartenId}/idea/${ideaId}/favorite`).then(res => res.data);
    }

    likeIdea(kindergartenId, ideaId)
    {
        return http.post(`/api/kindergartens/${kindergartenId}/idea/${ideaId}/like`).then(res => res.data);
    }

    storeIdea(kindergartenId, data)
    {
        return http.post(`/api/kindergartens/${kindergartenId}/idea`, data).then(res => res.data);
    }

    updateIdea(kindergartenId, ideaId, data)
    {
        return http.post(`/api/kindergartens/${kindergartenId}/idea/${ideaId}`, data).then(res => res.data);
    }
}

export default new IdeaService();