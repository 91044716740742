// Working on
import {CREATE_FOODMENU, GET_DAY_MENU, GET_WEEK_MENU, SET_DAY_MENU, SET_WEEK_MENU, UPDATE_MENU_DAY, UPDATE_MENU_WEEK, REMOVE_FOOD_MENU_FILE, SET_FOOD_MENU_FILES, GET_MENU_BASE64, SET_MENU_BASE64, CREATE_FOODMENU_MISSING_WEEKEND} from './actions';

import Vue from 'vue';
import FoodMenuService from '@services/foodmenu.service';

const state = {
    week: {},
    days: [],
    food_menu_base64: null,
    meal_options: [
        {key: 'breakfast', value: 'Breakfast'},
        {key: 'lunch', value: 'Lunch'},
        {key: 'supper', value: 'Light snack'},
        {key: 'dinner', value: 'Dinner'},
    ],
    settings: {
        workday_weekend: 0,
        workday_holiday: 0,
    },
};

const mutations = {
    [SET_MENU_BASE64](state, base64) {
        state.food_menu_base64 = base64;
    },
    [SET_WEEK_MENU](state, menu) {
        state.week = {};
        state.days = [];
        if (menu.settings) {
            Vue.set(state, 'settings', {...menu.settings});
        }
        if (menu.id) {
            Vue.set(state, 'days', [...menu.days]);
            state.week = menu;
        }
    },
    [SET_DAY_MENU](state, menu) {
        state.days.push(menu);
    },
    [REMOVE_FOOD_MENU_FILE](state, fileId) {
        const filteredFiles = state.week.uploaded_files.filter(f => f.id !== fileId);
        Vue.set(state.week, 'uploaded_files', [...filteredFiles]);
    },
    [SET_FOOD_MENU_FILES](state, file) {
        const filesArr = state.week.uploaded_files.push(file);
        Vue.set(state.week, 'uploaded_files', [...filesArr]);
    },
    [UPDATE_MENU_DAY](state, day) {
        let index = state.days.findIndex(d => d.id === day.id);

        if (index !== -1) {
            Vue.set(state.days, index, day);
        }
    }
};

const actions = {
    [GET_MENU_BASE64]({commit, getters}, date) {
        FoodMenuService.getUploadedMenuBase64(getters.activeKindergartenId, date).then(res => {
            commit(SET_MENU_BASE64, res);
        });
    },
    [GET_WEEK_MENU]({commit, getters}, date) {
        commit('START_CONTENT_LOADER');
        return FoodMenuService.getWeekMenu(getters.activeKindergartenId, date).then(res => {
            commit(SET_WEEK_MENU, res);
        }).finally(() => {
            commit('STOP_CONTENT_LOADER');
        });
    },
    [GET_DAY_MENU]({commit, getters, dispatch, rootState}, date) {
        let storedDay = getters.foodMenuWeekDays.find(day => day.date === date);

        if (storedDay === undefined) {
            commit('START_CONTENT_LOADER');
            return FoodMenuService.getDayMenu(getters.activeKindergartenId, date).then(res => {
                let foodmenu_edit = rootState.user.permissions.foodmenu_can_edit;
                if (!res.id && foodmenu_edit) {
                    dispatch(CREATE_FOODMENU, {date});
                } else {
                    commit(SET_DAY_MENU, res);
                    return res;
                }
            }).finally(() => {
                commit('STOP_CONTENT_LOADER');
            });
        } else {
            return storedDay;
        }
    },
    [UPDATE_MENU_DAY]({commit, getters}, data) {
        return FoodMenuService.updateDay(getters.activeKindergartenId, data).then(res => {
            commit(UPDATE_MENU_DAY, res);
        });
    },
    [UPDATE_MENU_WEEK]({commit, getters}, data) {
        return FoodMenuService.updateWeek(getters.activeKindergartenId, data).then(res => {
            commit(SET_WEEK_MENU, res);
        });
    },
    [CREATE_FOODMENU]({commit, getters}, data) {
        commit('START_CONTENT_LOADER');
        FoodMenuService.insertMenu(getters.activeKindergartenId, data).then(res => {
            commit(SET_WEEK_MENU, res);
            commit('STOP_CONTENT_LOADER');
        });
    },
    [CREATE_FOODMENU_MISSING_WEEKEND]({commit, getters}, foodMenuId) {
        commit('START_CONTENT_LOADER');
        FoodMenuService.createMissingWeekend(getters.activeKindergartenId, foodMenuId).then(res => {
            commit(SET_WEEK_MENU, res);
            commit('STOP_CONTENT_LOADER');
        });
    },
};

const getters = {
    foodMenuWeek: state => {
        return state.week || {};
    },
    foodMenuHasFiles: state => state.week.uploaded_files?.length ? true : false,
    foodMenuFiles: state => state.week.uploaded_files,
    foodMenuBase64: state => state.food_menu_base64,
    foodMenuWeekDays: state => {
        return state.days;
    },
    mealOptions: state => {
        return state.meal_options;
    },
    dayMenu: (state) => (date) => {
        return state.days.find(day => day.date === date) || {};
    },
    foodMenuSettings: state => state.settings,
};

export default {
    state,
    mutations,
    actions,
    getters
};
