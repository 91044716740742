import http from '@http';

class ImportService {
    constructor() {
        if (ImportService.instance) {
            return ImportService.instance;
        }

        ImportService.instance = this;
    }

    uploadFile(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/import/upload-file`, data).then(res => res.data);
    }

    importData(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/import/import-data`, data).then(res => res.data);
    }
}
export default new ImportService();
