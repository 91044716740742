import http from '@http';

class WorkScheduleService {
    constructor() {
        if (WorkScheduleService.instance) {
            return WorkScheduleService.instance;
        }

        WorkScheduleService.instance = this;
    }

    insertWorkSchedule(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/work-schedule`, data).then(res => res.data);
    }

    getWorkSchedule(kindergartenId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/work-schedule`, {params: options}).then(res => res.data);
    }

    updateWorkSchedule(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/work-schedule/${data.id}`, data).then(res => res.data);
    }

    deleteWorkSchedule(kindergartenId, data) {
        return http.delete(`/api/kindergartens/${kindergartenId}/work-schedule/${data.id}`,{ params: data })
            .then(res => res.data);
    }

    getWorkScheduleTypes(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/work-schedule/types`).then(res => res.data);
    }

    addWorkScheduleType(kindergartenId, type) {
        return http.post(`/api/kindergartens/${kindergartenId}/work-schedule/types`, type).then(res => res.data);
    }

    updateWorkScheduleType(kindergartenId, typeId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/work-schedule/types/${typeId}`, data).then(res => res.data);
    }

    getWorkScheduleSettings(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/work-schedule/settings`).then(res => res.data);
    }

    updateWorkScheduleSettings(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/work-schedule/settings`, data).then(res => res.data);
    }
}

export default new WorkScheduleService();
