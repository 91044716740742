import store from '@/store';

const Announcements = () =>
    import(/* webpackChunkName: "announcement-module" */ '@views/announcement');
const AnnouncementTab = () =>
    import(/* webpackChunkName: "announcement-module" */ '@views/announcement/views/AnnouncementTab');

export default [
    {
        path: '/announcements',
        components: { base: Announcements },
        children: [
            {
                path: '',
                name: 'announcements',
                components: { message: AnnouncementTab },
                meta: 'announcements'
            },
            {
                path: 'sent',
                name: 'announcements.sent',
                components: { message: AnnouncementTab },
                meta: 'sent'
            },

        ],
        beforeEnter: (to, from, next) => {
            if (store.getters.activeKindergartenId || store.getters.activeOrganizationId) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    }
];
