<template>
    <b-modal id="accessible-modal" hide-footer size="md" lazy static no-close-on-backdrop>
        <template v-slot:modal-header>
            <ModalHeader modalName="accessible-modal">
                <span v-if="type === 'delete'">{{ $t('Can not delete all selected items') }}</span>
                <span v-if="type === 'move'">{{ $t('Can not move all selected items') }}</span>
            </ModalHeader>
        </template>
        <div class="d-flex align-items-center text-muted">
            <SvgIcon icon="mdi-alert-circle-outline" class="text-muted mr-1"/>
            <span v-if="type === 'delete'">{{ $t('You lack the proper permissions to delete these items') }}</span>
            <span v-if="type === 'move'">{{ $t('You lack the proper permissions to move these items') }}</span>
        </div>
        <div v-if="!allItemsInaccessible" class="my-4">
            <div v-for="(item, index) in items" :key="index" class="d-flex align-items-center py-1">
                <File
                    v-if="itemsType === 'file'"
                    :file="item"
                    class="mdi-36px mr-2"
                    :type="pageType"
                    :showName="false"/>
                <FolderIcon
                    v-else
                    class="small mr-2"
                    :isGallery="pageType === 'gallery'"
                    :isReadonly="item.access === 'view'"/>
                <span>{{ item.name }}</span>
            </div>
        </div>
        <div v-if="!allItemsInaccessible" class="font-weight-bold">
            <span v-if="type === 'delete'">{{ $t('Do you still want to delete the other items?', {count: checkedItems - items.length}) }}</span>
            <span v-if="type === 'move'">{{ $t('Do you still want to move the other items to another folder?', {count: checkedItems - items.length}) }}</span>
        </div>
        <div class="d-flex mt-4">
            <button class="btn btn-secondary ml-auto" @click="$bvModal.hide('accessible-modal')">
                {{ allItemsInaccessible ? $t('Close') : $t('Cancel') }}
            </button>
            <EBtnLoader
                v-if="!allItemsInaccessible"
                :color="type === 'delete' ? 'danger' : 'success'"
                class="ml-2"
                @clicked="action()">
                <span v-if="type === 'delete'">{{ $t('Delete') }}</span>
                <span v-if="type === 'move'">{{ $t('Move') }}</span>
            </EBtnLoader>
        </div>
    </b-modal>
</template>

<script>
import { eventBus } from '@/main';
import { mapGetters } from 'vuex';
import File from '@components/File';
import FolderIcon from '@views/folder/components/FolderIcon';

export default {
    data() {
        return {
            items: [],
            checkedItems: false,
            type: '',
            pageType: '',
            itemsType: '',
            done: null,
        };
    },
    components: {
        File,
        FolderIcon,
    },
    computed: {
        ...mapGetters(['activeKindergartenId', 'subFolders', 'folder']),
        allItemsInaccessible() {
            return this.items.length === this.checkedItems;
        }
    },
    methods: {
        openModal() {
            this.$bvModal.show('accessible-modal');
        },
        action() {
            this.done && this.done();
            this.$bvModal.hide('accessible-modal');
        },
    },
    created() {
        eventBus.$on('show-accessible-modal', (data) => {
            this.openModal();
            this.items = data.items;
            this.checkedItems = data.checkedItems;
            this.pageType = data.pageType;
            this.itemsType = data.itemsType;
            this.type = data.type;
            this.done = data.done;
        });
    },
    destroyed() {
        eventBus.$off('show-accessible-modal');
    }
};
</script>

<style lang="scss" scoped>

</style>