const ReportOccupancy = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportOccupancy');
const OrgReportOccupancy = () => import(/* webpackChunkName: "reports-module" */ '@views/report/organization/ReportOccupancy');
const ReportAttendance = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportAttendance');
const OrgReportAttendance = () => import(/* webpackChunkName: "reports-module" */ '@views/report/organization/ReportAttendance');
const ChildFeedback = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportChildFeedback');
const CdmChildWithNumbers = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportCdmChildWithNumbers');
const CdmGroupWithNumbers = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportCdmGroupWithNumbers');
const ReportMeals = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportMeals');
const ReportEmployeeMeals = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportEmployeeMeals');
const ReportApprovals = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportApprovals');
const ReportGuardiansStatistics = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportGuardianStatistics');
const ReportDiary = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportDiary');
const ReportWorkSchedule = () => import(/* webpackChunkName: "reports-module" */ '@views/report/kindergarten/ReportWorkSchedule');
import store from '@/store';

export default [
    {
        path: '/reports/occupancy',
        name: 'reports.occupancy',
        components: { base: ReportOccupancy },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsOccupancy) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/reports/attendance',
        name: 'reports.attendance',
        components: { base: ReportAttendance },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsAttendance) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/reports/meals',
        name: 'reports.todaysmeals',
        components: {base: ReportMeals},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsMeals) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/reports/child-feedback',
        name: 'reports.childfeedback',
        components: {base: ChildFeedback},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsChildFeedback) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/reports/child-development/child/with-numbers',
        name: 'reports.cdm.child.with.numbers',
        components: {base: CdmChildWithNumbers},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsCdmChildWithNumbers) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
    },
    {
        path: '/reports/child-development/group/with-numbers',
        name: 'reports.cdm.group.with.numbers',
        components: {base: CdmGroupWithNumbers},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsCdmGroupWithNumbers) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
    },
    {
        path: '/reports/employee-meals',
        name: 'reports.employeemeals',
        components: {base: ReportEmployeeMeals},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsEmployeeMeals) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        }
    },
    {
        path: '/reports/approvals',
        name: 'reports.approvals',
        components: {base: ReportApprovals},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsApprovals) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/reports/guardians-statistics',
        name: 'reports.guardiansstatistics',
        components: {base: ReportGuardiansStatistics},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsGuardianStatistics) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/reports/diary',
        name: 'reports.diary',
        components: {base: ReportDiary},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsDiary) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/reports/work-schedule',
        name: 'reports.workschedule',
        components: { base: ReportWorkSchedule },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessReportsWorkschedule) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/reports/org-occupancy',
        name: 'reports.organization.occupancy',
        components: { base: OrgReportOccupancy },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessOrgReportsOccupancy) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/reports/org-attendance',
        name: 'reports.organization.attendance',
        components: { base: OrgReportAttendance },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessOrgReportsAttendance) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    // {
    //     path: '/reports/5',
    //     name: 'reports.annualsummary'
    // },
    // {
    //     path: '/reports/6',
    //     name: 'reports.childfeedback'
    // },
];
