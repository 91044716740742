import {
    RESET_EMPLOYEE_STATE,
    GET_EMPLOYEE_COURSES,
    SAVE_EMPLOYEE_COURSE,
    SET_EMPLOYEE_COURSE,
    SET_EMPLOYEE_COURSES,
    UPDATE_EMPLOYEE,
    ARCHIVE_EMPLOYEE,
    RESTORE_EMPLOYEE,
    SET_EMPLOYEE_CHILDREN,
    GET_EMPLOYEE_CHILDREN,
    SET_EMPLOYEE_COURSES_DATE,
    // GET_EMPLOYEE_ACTIVE_COURSES,
    // GET_EMPLOYEE_ALL_COURSES
} from './actions';
// import Vue from 'vue';
import CourseService from '@services/course.service';
import EmployeeService from '@services/employee.service';
import {REMOVE_EMPLOYEE, SET_EMPLOYEE} from '../kindergarten/actions';

//It was nice while it lasted
// function checkCourseDataAge(date) {
//     return Vue.moment().diff(Vue.moment(date), 'minutes');
// }


const getDefaultState = () => {
    return {

        //Active only
        employeeActiveCourses: [],
        activeCoursesDate: null,
        //Active and future
        employeeCourses: [],
        employeeCoursesDate: null,
        selected_course: {},
        //Active, archived, future
        allEmployeeCourses: [],
        allCoursesDate: null,

        employee_children: [],
        //Legacy
        employee_courses: [],
        employee_courses_date: null
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_EMPLOYEE_STATE](state) {
        Object.assign(state, getDefaultState());
        localStorage.removeItem('employee_course_id');
    },
    [SET_EMPLOYEE_CHILDREN](state, data) {
        state.employee_children = data;
    },
    [SET_EMPLOYEE_COURSES](state, data) {
        state.employeeCourses = data;
    },
    [SET_EMPLOYEE_COURSE](state, data) {
        state.selected_course = data;
        if(data.id !== undefined) {
            localStorage.setItem('employee_course_id', data.id);
        } else {
            localStorage.removeItem('employee_course_id');
        }
    },
    [SET_EMPLOYEE_COURSES_DATE](state, date) {
        state.employeeCoursesDate = date;
    }
};
const actions = {
    [GET_EMPLOYEE_CHILDREN]({commit, getters}) {
        if (getters.employeeChildren.length) {
            return getters.employeeChildren;
        }
        if (getters.activeKindergartenId && getters.currentEmployee) {
            return EmployeeService.employeeChildren(getters.activeKindergartenId, getters.currentEmployee.id).then(res => {
                if (res.length) {
                    commit(SET_EMPLOYEE_CHILDREN, res);
                }
            });
        }
    },
    [GET_EMPLOYEE_COURSES]({commit, getters}, options = {active: true, future: true}) {
        // if (getters.employeeCourses.length && getters.employeeCoursesDate && checkCourseDataAge(getters.employeeCoursesDate) < 60) {
        //     return getters.employeeCourses;
        // }

        //Reset existing data
        commit(SET_EMPLOYEE_COURSES, []);

        //Interesting way of nesting dashboard request inside employee request @ Dashboard.vue
        if(getters.currentEmployee) {
            return CourseService.getEmployeeCourses(getters.activeKindergartenId, getters.currentEmployee.id, options).then(res => {
                if (res.length) {
                    commit(SET_EMPLOYEE_COURSES, res);
                    // commit(SET_EMPLOYEE_COURSES_DATE, Vue.moment());
                    const courseId = localStorage.getItem('employee_course_id');
                    const selectedCourse = courseId ? getters.employeeCourses.find(course => course.id == courseId) : false;
                    commit(SET_EMPLOYEE_COURSE, selectedCourse || res[0]);
                } else {
                    commit(SET_EMPLOYEE_COURSE, {});
                }
            });
        }
    },
    // [GET_EMPLOYEE_ACTIVE_COURSES]({commit, getters}) {
    //     if (getters.employeeCourses.length && getters.employeeActiveCoursesDate && checkCourseDataAge(getters.employeeActiveCoursesDate) < 60) {
    //         return getters.employeeCourses;
    //     }
    //     if (getters.activeKindergartenId && getters.currentEmployee) {
    //         return CourseService.getEmployeeActiveCourses(getters.activeKindergartenId, getters.currentEmployee.id).then(res => {
    //
    //         });
    //     }
    // },
    // [GET_EMPLOYEE_ALL_COURSES]({commit, getters}) {
    //
    // },
    [SAVE_EMPLOYEE_COURSE]({commit, getters}, data) {
        commit(SET_EMPLOYEE_COURSE, getters.getCourseById(data.id));
    },

    [UPDATE_EMPLOYEE]({commit, getters}, {employeeId, form}) {
        return EmployeeService.update(getters.activeKindergartenId, employeeId, form).then(res => {
            commit(SET_EMPLOYEE, res);
        });
    },
    [ARCHIVE_EMPLOYEE]({commit, getters}, employeeId) {
        return EmployeeService.archiveEmployee(getters.activeKindergartenId, employeeId).then(res => {
            if(res.deleted) {
                commit(REMOVE_EMPLOYEE, employeeId);
            }
        });
    },
    [RESTORE_EMPLOYEE]({commit, getters}, {employeeId, data}) {
        return EmployeeService.restoreEmployee(getters.activeKindergartenId, employeeId, data).then(() => {
            commit(REMOVE_EMPLOYEE, employeeId);
        });
    },
};
const getters = {
    employeeCourses: state => {
        return state.employeeCourses || [];
    },
    employeeChildren: state => {
        return state.employee_children || [];
    },
    selectedCourse: state => {
        return state.selected_course;
    },
    getCourseById: state => id => {
        return state.employeeCourses.find(course => course.id === id);
    },
    employeeCoursesDate: state => state.employee_courses_date,
    employeeActiveCoursesDate: state => state.activeCoursesDate,
};

export default {
    state,
    mutations,
    actions,
    getters
};
