import Vue from 'vue';
import {
    DELETE_IDEA,
    DELETE_IDEA_COMMENT,
    DELETE_IDEA_IMAGE,
    GET_IDEA_COMMENTS,
    GET_IDEA,
    GET_IDEAS,
    GET_IDEAS_FROM_AUTHOR,
    GET_IDEA_AGE_GROUPS,
    GET_IDEA_CATEGORIES,
    REMOVE_IDEA,
    REMOVE_IDEA_COMMENT,
    REMOVE_IDEA_IMAGE,
    SAVE_IDEA_FAVORITE,
    SAVE_IDEA_LIKE,
    SAVE_IDEA_COMMENT,
    SET_IDEA_COMMENTS,
    SET_IDEA,
    SET_IDEAS,
    SET_IDEAS_FROM_AUTHOR,
    SET_IDEA_AGE_GROUPS,
    SET_IDEA_CATEGORIES,
    STORE_IDEA,
    UPDATE_IDEA,
    UPDATE_IDEA_FAVORITE,
    UPDATE_IDEA_LIKE,
    ADD_IDEA_COMMENT,
    UPDATE_IDEAS_SINGLE_FAVORITE,
    UPDATE_IDEAS_SINGLE_LIKE,
    UPDATE_IDEA_COMMENT,
    SET_IDEAS_CURRENT_PAGE,
    SET_IDEAS_CURRENT_SEARCH,
    SET_IDEAS_CURRENT_TYPE,
    RESET_IDEAS_CURRENTS,
    UPDATE_CURRENT_IDEAS_TYPE,
} from './actions';

import IdeaService from '@services/idea.service';

const state = {
    idea: {},
    idea_age_groups: [],
    idea_categories: [],
    idea_comments: {},
    ideas: {},
    ideas_from_author: [],
    permissions: {
        manage: 'manage',
        view: 'view',
        edit: 'edit'
    },
    //TODO make this not static?
    visibilities: [
        { id: 'world', name: 'All' },
        { id: 'kindergarten', name: 'My kindergarten' },
        { id: '63', name: 'Estonia' },
        { id: '134', name: 'Latvia' },
        { id: '132', name: 'Lithuania' },
        { id: '178', name: 'Poland' },
        { id: '56', name: 'Germany'},
        { id: '104', name: 'India'},
        { id: '227', name: 'Ukraine'},
        { id: '228', name: 'Uganda'},
        { id: '64', name: 'Egypt'},
        { id: '138', name: 'Moldova'},
        { id: '226', name: 'Tanzania'},
        { id: '222', name: 'Turkey'},
    ],
    search_term: '',
    last_visited_page: 1,
    ideas_type: '',
};

const mutations = {
    [REMOVE_IDEA](state, ideaId) {
        if (state.idea && state.idea.id === ideaId) {
            state.idea = {};
        }

        const filtered = state.ideas.data.filter(c => c.id !== ideaId);
        Vue.set(state.ideas, 'data', [...filtered]);
        state.ideas.total = state.ideas.total - 1;
    },
    [REMOVE_IDEA_COMMENT](state, commentId) {
        const index = state.idea_comments.data.findIndex(c => c.id === commentId);
        if(index !== -1) {
            state.idea_comments.data.splice(index, 1);
            state.idea_comments.total = state.idea_comments.total - 1;
            state.idea.comments_total = state.idea.comments_total -1;

            Vue.set(state.idea, 'comments_count', state.idea.comments_count - 1);
        }
    },
    [UPDATE_IDEA_COMMENT](state, comment) {
        const index = state.idea_comments.data.findIndex(c => c.id === comment.id);
        if (index !== -1) {
            Vue.set(state.idea_comments.data, index, comment);
            state.idea_comments.total = state.idea_comments.total - 1;
            state.idea.comments_total = state.idea.comments_total -1;

            Vue.set(state.idea, 'comments_count', state.idea.comments_count - 1);
        }
    },

    [REMOVE_IDEA_IMAGE](state, filename) {
        if (!state.idea) {
            return;
        }
        const filtered = state.idea.images.filter(c => c.filename !== filename);
        Vue.set(state.idea, 'images', [...filtered]);
    },

    [SET_IDEA_COMMENTS](state, data) {
        state.idea_comments = data;
        if (state.idea) {
            state.idea.comments = data;
        }
    },

    [SET_IDEA](state, data) {
        state.idea = data;
        state.idea_comments = data.comments;
        state.ideas_from_author = data.ideasFromAuthor;
    },
    [SET_IDEAS](state, data) {
        state.ideas = data;
    },
    [SET_IDEAS_FROM_AUTHOR](state, data) {
        state.ideas_from_author = data;
    },
    [SET_IDEA_AGE_GROUPS](state, data) {
        state.idea_age_groups = data;
    },
    [SET_IDEA_CATEGORIES](state, data) {
        state.idea_categories = data;
    },
    [ADD_IDEA_COMMENT](state, data) {
        state.idea_comments.data.unshift(data);
        if (state.idea_comments.data.length > 5) {
            state.idea_comments.data.pop();
        }
        state.idea_comments.total += 1;
        state.idea_comments.last_page = Math.ceil(state.idea_comments.total / state.idea_comments.per_page);

        Vue.set(state.idea, 'comments_count', state.idea.comments_count + 1);
    },
    [UPDATE_IDEA_FAVORITE](state, data) {
        state.idea.favorite = data;
    },
    [UPDATE_IDEAS_SINGLE_LIKE](state, data) {
        let index = data.type == 'ideas' ?
            state.ideas.data.findIndex(i => i.id == data.id) :
            state.idea.ideasFromAuthor.findIndex(i => i.id == data.id);

        let idea = data.type == 'ideas' ?
            state.ideas.data[index] :
            state.idea.ideasFromAuthor[index];

        if (idea.is_liked == 1) {
            idea.is_liked = 0;
            idea.likes_count = idea.likes_count - 1;
        } else {
            idea.is_liked = 1;
            idea.likes_count = idea.likes_count + 1;
        }

        Vue.set(data.type == 'ideas' ? state.ideas.data : state.idea.ideasFromAuthor, index, idea);
    },
    [UPDATE_IDEAS_SINGLE_FAVORITE](state, data) {
        let index = data.type == 'ideas' ? 
            state.ideas.data.findIndex(i => i.id == data.id) :
            state.idea.ideasFromAuthor.findIndex(i => i.id == data.id);

        let idea = data.type == 'ideas' ?
            state.ideas.data[index] :
            state.idea.ideasFromAuthor[index];

        if (idea.is_favorited == 1) {
            idea.is_favorited = 0;
            idea.favorites_count--;
        } else {
            idea.is_favorited = 1;
            idea.favorites_count++;
        }

        Vue.set(data.type == 'ideas' ? state.ideas.data : state.idea.ideasFromAuthor, index, idea);
    },
    [UPDATE_IDEA_LIKE](state, data) {
        if (state.idea) {
            state.idea.is_liked = data.result;
        }

        if (state.ideas.data && state.ideas.data.length) {
            for (let i = 0; i < state.ideas.data.length; i++) {
                if (state.ideas.data[i].id === data.idea_id) {
                    state.ideas.data[i].liked_count += data.result ? 1 : -1;
                    state.ideas.data[i].is_liked = data.result;
                }
            }
        }
    },
    [SET_IDEAS_CURRENT_PAGE](state, data) {
        state.last_visited_page = data;
    },
    [SET_IDEAS_CURRENT_SEARCH](state, data) {
        state.search_term = data;
    },
    [SET_IDEAS_CURRENT_TYPE](state, data) {
        state.ideas_type = data;
    },
};

const actions = {
    [DELETE_IDEA]({commit, getters}, idea_id) {
        return IdeaService.deleteIdea(getters.activeKindergartenId, idea_id).then(() => {
            commit(SET_IDEA, {
                comments: {},
                ideasFromAuthor: []
            });
        });
    },

    [DELETE_IDEA_COMMENT]({commit, getters}, {idea_id, comment_id}) {
        return IdeaService.deleteIdeaComment(getters.activeKindergartenId, idea_id, comment_id).then(res => {
            if(res.deleted) {
                commit(REMOVE_IDEA_COMMENT, comment_id);
            } else {
                commit(UPDATE_IDEA_COMMENT, res);
            }

        });
    },

    [DELETE_IDEA_IMAGE]({commit, getters}, filename) {
        return IdeaService.deleteIdeaImage(getters.activeKindergartenId, filename).then(res => {
            commit(REMOVE_IDEA_IMAGE, filename);
            return res;
        });
    },

    [GET_IDEA_COMMENTS]({commit, getters}, {ideaId, queryObj}) {
        return IdeaService.getIdeaComments(getters.activeKindergartenId, ideaId, queryObj).then(res => {
            commit(SET_IDEA_COMMENTS, res);
        });
    },

    [GET_IDEA]({commit, getters}, idea_id) {
        return IdeaService.getIdea(getters.activeKindergartenId, idea_id).then(res => {
            commit(SET_IDEA, res);
        });
    },

    [GET_IDEAS]({commit, getters}, options) {
        return IdeaService.getIdeas(getters.activeKindergartenId, options).then(res => {
            commit(SET_IDEAS, res);
        });
    },

    [GET_IDEAS_FROM_AUTHOR]({commit, getters}, ideaId) {
        return IdeaService.getIdeasFromAuthor(getters.activeKindergartenId, ideaId).then(res => {
            commit(SET_IDEAS_FROM_AUTHOR, res);
        });
    },

    [GET_IDEA_AGE_GROUPS]({commit, getters}, queryObj) {
        //store already contains the data, data is static at the moment so no need to request it again
        if(getters.ideaAgeGroups.length) {
            return;
        }
        return IdeaService.getIdeaAgeGroups(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_IDEA_AGE_GROUPS, res);
        });
    },

    [GET_IDEA_CATEGORIES]({commit, getters}, queryObj) {
        //store already contains the data, data is static at the moment so no need to request it again
        if(getters.ideaCategories.length) {
            return;
        }
        return IdeaService.getIdeaCategories(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_IDEA_CATEGORIES, res);
        });
    },

    [SAVE_IDEA_COMMENT]({commit, getters}, {idea_id, form}) {
        return IdeaService.storeIdeaComment(getters.activeKindergartenId, idea_id, form).then(res => {
            commit(ADD_IDEA_COMMENT, res);
        });
    },

    [SAVE_IDEA_FAVORITE]({commit, getters}, idea_id) {
        return IdeaService.addIdeaToFavorites(getters.activeKindergartenId, idea_id).then(res => {
            commit(UPDATE_IDEA_FAVORITE, res);
            return res;
        });
    },

    [SAVE_IDEA_LIKE]({commit, getters}, idea_id) {
        return IdeaService.likeIdea(getters.activeKindergartenId, idea_id).then(res => {
            commit(UPDATE_IDEA_LIKE, res);
            return res;
        });
    },

    [STORE_IDEA]({getters}, data) {
        return IdeaService.storeIdea(getters.activeKindergartenId, data);
    },

    [UPDATE_IDEA]({commit, getters}, {idea_id, data}) {
        return IdeaService.updateIdea(getters.activeKindergartenId, idea_id, data).then(res => {
            commit(SET_IDEA, res);
            return res;
        });
    },
    [UPDATE_CURRENT_IDEAS_TYPE]({commit, dispatch, getters}, data) {
        if (getters.ideasType != data) {
            dispatch(RESET_IDEAS_CURRENTS);
            commit(SET_IDEAS_CURRENT_TYPE, data);
        }
    },
    [RESET_IDEAS_CURRENTS]({commit}) {
        commit(SET_IDEAS_CURRENT_PAGE, 1);
        commit(SET_IDEAS_CURRENT_SEARCH, '');
    },
};

const getters = {
    idea: state => state.idea,
    ideaAgeGroups: state => state.idea_age_groups,
    ideaCategories: state => state.idea_categories,
    ideaComments: state => state.idea_comments,
    ideas: state => state.ideas,
    ideasFromAuthor: state => state.ideas_from_author,
    ideaVisibilities: state => state.visibilities,
    lastSearchTerm: state => state.search_term,
    lastVisitedPage: state => state.last_visited_page,
    ideasType: state => state.ideas_type,
};

export default {
    state,
    mutations,
    actions,
    getters
};
