// Mutations
export const SET_TRANSLATES = 'SET_TRANSLATES';

// Both
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_COUNTRY = 'SET_COUNTRY';
export const LANGUAGE_LOADED = 'LANGUAGE_LOADED';
export const SET_RTL = 'SET_RTL';
export const SET_COUNTRY_LANGUAGES = 'SET_COUNTRY_LANGUAGES';
