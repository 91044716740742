export const OPEN_FOODMENU_MODAL = 'OPEN_FOODMENU_MODAL';
export const GET_WEEK_MENU = 'GET_WEEK_MENU';
export const SET_WEEK_MENU = 'SET_WEEK_MENU';
export const GET_DAY_MENU = 'GET_DAY_MENU';
export const SET_DAY_MENU = 'SET_DAY_MENU';
export const UPDATE_MENU_DAY = 'UPDATE_MENU_DAY';
export const UPDATE_MENU_WEEK = 'UPDATE_MENU_WEEK';
export const CREATE_FOODMENU = 'CREATE_FOODMENU';
export const REMOVE_FOOD_MENU_FILE = 'REMOVE_FOOD_MENU_FILE';
export const SET_FOOD_MENU_FILES = 'SET_FOOD_MENU_FILES';
export const GET_MENU_BASE64 = 'GET_MENU_BASE64';
export const SET_MENU_BASE64 = 'SET_MENU_BASE64';
export const CREATE_FOODMENU_MISSING_WEEKEND = 'CREATE_FOODMENU_MISSING_WEEKEND';
