import {eventBus} from '@/main';

export default {
    methods: {
        getFolderData(id, catchUrl) {
            this.hasInitialFolders = false;
            this.hasInitialFiles = false;
            this.searchValue = '';
            this.page = 1;

            this.$store.dispatch('START_CONTENT_LOADER');
            this.$store.dispatch(
                'GET_FOLDER',
                {
                    id: id,
                    queryObj: {
                        page: this.page,
                        perPage: this.perPage,
                        term: this.searchValue
                    }
                }).then(() => {
                if (this.subFolders.length) {
                    this.hasInitialFolders = true;
                    this.searchFolders = true;
                }
                if (this.folderFiles.length) {
                    this.hasInitialFiles = true;
                }
            }).catch(() => {
                this.$router.push(catchUrl);
            }).finally(() => {
                this.$store.dispatch('STOP_CONTENT_LOADER');
            });
        },
        getSubFolders(reset = false, noTerm = false) {
            if (reset) {
                this.folderLoading = true;
            }
            this.$store.dispatch('GET_SUB_FOLDERS', {
                reset: reset,
                queryObj: {
                    folder_id: this.folder.id,
                    page: this.page,
                    perPage: this.perPage,
                    term: noTerm ? '' : this.searchValue
                }
            }).finally(() => {
                this.folderLoading = false;
            });
        },
        search() {
            if (this.searchValue.length) {
                this.initialSearch = true;
            }

            if (this.searchValue.length || this.initialSearch) {
                this.page = 1;
                if (this.searchFolders) {
                    this.getSubFolders(true);
                }
                if (this.searchDocuments) {
                    this.getDocuments();
                }
            }

            if (!this.searchValue.length && this.initialSearch) {
                this.initialSearch = false;
            }
        },
        loadMore() {
            this.page++;
            if(this.folder.id) {
                this.getSubFolders();
            } else {
                this.getFolders();
            }
        },
        resetDocumentSearch() {
            if (this.initialSearch) {
                if (!this.searchDocuments) {
                    this.getDocuments(true);
                } else {
                    this.getDocuments();
                }
            }
        },
        resetFolderSearch() {
            if (this.initialSearch) {
                if (!this.searchFolders) {
                    this.getSubFolders(true, true);
                } else {
                    this.getSubFolders(true);
                }
            }
        },
        clearFilter() {
            if (this.searchValue.trim() !== '') {
                this.searchValue = '';
                this.search();
            }
        },
        openModal() {
            eventBus.$emit('show-add-folder-modal', this.folder);
        },
        openEditModal() {
            eventBus.$emit('show-edit-folder-modal', this.folder);
        },
        canAddToFolder() {
            if (this.folder.access === 'edit' ||
                this.folder.acl_groups !== undefined) {
                return true;
            }
        },
        canEditFolder() {
            return this.folder.access === 'manage';
        },
        resetData() {
            this.initialSearch = false;
            if (!this.hasInitialFolders) {
                this.searchFolders = false;
            }
            this.searchDocuments = false;
        },
    },
};
