<template>
    <div :class="{'form-group': isLabelSlot,'is-invalid': validation && validation.$error}">
        <label v-if="isLabelSlot" :for="id" :class="labelClass">
            <slot name="label"/>
        </label>
        <multiselect
            :id="id"
            v-model="val"
            :placeholder="placeholder !== '' ? placeholder : $t('Search') + '...'"
            :class="{ 'is-invalid': validation && validation.$error }"
            :options="kindergartens.map(k => k.id)"
            :searchable="true"
            :label="label"
            :select-label="$t('Choose')"
            :selected-label="$t('Selected')"
            :deselect-label="$t('Remove')"
            :loading="isLoading"
            :internal-search="false"
            :custom-label="
                value => kindergartens.some(k => k.id === value)
                    ? kindergartens.find(k => k.id === value).name
                    : null"
            @select="onSelect"
            @search-change="search"
            @remove="onRemove">
            <span slot="noResult">{{ $t('Nothing found') }}</span>
            <span slot="noOptions">{{ $t('Type to search') }}</span>
        </multiselect>
        <ValitationMessages :validation="validation"/>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import KindergartenService from '@services/kindergarten.service';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'EKindergartenSearch',
    props: {
        id: [String, Number],
        value: [Object, String, Number, Array],
        label: String,
        placeholder: {
            type: String,
            default: ''
        },
        validation: Object
    },
    data() {
        return {
            kindergartens: [],
            isLoading: false
        };
    },
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        labelClass() {
            if (this.inline) {
                return 'col-md-5 col-form-label text-md-right text-sm-left';
            }

            return '';
        }
    },
    methods: {
        search(query) {
            this.isLoading = true;
            KindergartenService.searchByName(query).then(res => {
                this.kindergartens = res;
                this.isLoading = false;
            });
        },
        onSelect(value) {
            this.$emit('select', value);
        },
        onRemove(value) {
            this.$emit('remove', value);
        }
    },
    components: {
        Multiselect
    },
    validations: {
        kindergarten: {
            required
        }
    }
};
</script>
