import http from '@http';
import {download} from '@services/helper.service';

class MapService {
    constructor() {
        if (MapService.instance) {
            return MapService.instance;
        }

        MapService.instance = this;
    }

    add(kindergartenId, form) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${form.childId}/maps`, form).then(res => res.data);
    }

    getMap(kindergartenId, childId, mapId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/maps/${mapId}`).then(res => res.data);
    }

    updateField(kindergartenId, childId, mapId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/children/${childId}/maps/${mapId}/update-field`, data).then(res => res.data);
    }

    publishMap(kindergartenId, childId, mapId, form) {
        return http.put(`/api/kindergartens/${kindergartenId}/children/${childId}/maps/${mapId}/publish`, form).then(res => res.data);
    }

    renameMap(kindergartenId, childId, mapId, form) {
        return http.put(`/api/kindergartens/${kindergartenId}/children/${childId}/maps/${mapId}`, form).then(res => res.data);
    }

    deleteMap(kindergartenId, childId, mapId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/children/${childId}/maps/${mapId}`).then(res => res.data);
    }

    getMassFillData(kindergartenId, childId, mapId, fieldId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/maps/${mapId}/mass-fill`, {params: {field_id: fieldId}}).then(res => res.data);
    }
    approveMap(kindergartenId, childId, mapId, form) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/maps/${mapId}/approve`, form).then(res => res.data);
    }

    disapproveMap(kindergartenId, childId, mapId, form) {
        return http.delete(`/api/kindergartens/${kindergartenId}/children/${childId}/maps/${mapId}/approve`, {params: form}).then(res => res.data);
    }
    mapPdf(kindergartenId, childId, mapId, options, name) {
        return http.download(`/api/kindergartens/${kindergartenId}/children/${childId}/maps/${mapId}/pdf`, {params: options})
            .then(res => {
                const file = {
                    mime_type: 'application/pdf',
                    name: `${name}.pdf`
                };
                download(res.data, file);
            });
    }
}

export default new MapService();
