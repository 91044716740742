import {
    ADD_REMOVE_CHILD,
    COMFIRM_KINDERGARTEN_APPLICATION,
    GET_APPLICATION,
    KINDERGARTEN_APPLICATIONS,
    REJECT_KINDERGARTEN_APPLICATION,
    SAVE_APPLICATION,
    SET_APPLICATION,
    SET_CONFIRMED_KINDERGARTEN_APPLICATIONS,
    SET_PENDING_KINDERGARTEN_APPLICATIONS,
    SET_REJECTED_KINDERGARTEN_APPLICATIONS,
    SET_TERMINATED_KINDERGARTEN_APPLICATIONS,
    SET_USER_APPLICATIONS,
    UPDATE_KINDERGARTEN_APPLICATION,
    USER_APPLICATIONS,
} from './actions';
import Vue from 'vue';
import ApplicationService from '@services/application.service';
import {START_CONTENT_LOADER, STOP_CONTENT_LOADER} from '@store/loader/actions';

const state = {
    user_applications: [],
    pending_applications: {
        data: []
    },
    confirmed_applications: {
        data: []
    },
    rejected_applications: {
        data: []
    },
    terminated_applications: {
        data: []
    },
    statuses: {
        pending: 'Pending',
        cancelled: 'Cancelled',
        rejected: 'Rejected',
        confirmed: 'Confirmed',
        terminated: 'Terminated',
    }
};
const mutations = {
    [SET_USER_APPLICATIONS](state, data) {
        state.user_applications = data;
    },
    [SET_APPLICATION](state, data) {
        state.user_applications.unshift(data);
    },
    [SET_PENDING_KINDERGARTEN_APPLICATIONS](state, data) {
        state.pending_applications = data;
    },
    [SET_CONFIRMED_KINDERGARTEN_APPLICATIONS](state, data) {
        state.confirmed_applications = data;
    },
    [SET_REJECTED_KINDERGARTEN_APPLICATIONS](state, data) {
        state.rejected_applications = data;
    },
    [SET_TERMINATED_KINDERGARTEN_APPLICATIONS](state, data) {
        state.terminated_applications = data;
    },
    // [UPDATE_APPLICATION](state, application) {
    //     const index = state.applications.findIndex((e) => {
    //         return application.id === e.id;
    //     });
    //     if (index !== -1) {
    //         Vue.set(state.applications, index, application);
    //     }
    // },
    [UPDATE_KINDERGARTEN_APPLICATION](state, application) {
        let index = state.pending_applications.data.findIndex(app => app.id === application.id);

        if (application.status === state.statuses.pending) {
            if (index !== -1) {
                Vue.set(state.pending_applications.data, index, application);
            }
        } else if (application.status === state.statuses.cancelled) {
            state.pending_applications.data.splice(index, 1);
        } else if (application.status === state.statuses.rejected) {
            state.pending_applications.data.splice(index, 1);
            state.rejected_applications.data.unshift(application);
        } else if (application.status === state.statuses.confirmed) {
            if (index !== -1) {
                state.pending_applications.data.splice(index, 1);
                state.confirmed_applications.data.unshift(application);
            } else {
                let index = state.confirmed_applications.data.findIndex(app => app.id === application.id);
                Vue.set(state.confirmed_applications.data, index, application);
            }
        } else if (application.status === state.statuses.terminated) {
            Vue.set(state.confirmed_applications.data, index, application);
        }
    }
};
const actions = {
    [USER_APPLICATIONS]({commit}) {
        commit(START_CONTENT_LOADER);
        ApplicationService.userApplications().then(res => {
            commit(SET_USER_APPLICATIONS, res);
            commit(STOP_CONTENT_LOADER);
        });
    },
    [SAVE_APPLICATION]({commit}, data) {
        return ApplicationService.store(data).then(res => {
            commit(SET_APPLICATION, res);
            return res;
        });
    },
    [KINDERGARTEN_APPLICATIONS]({state, commit, getters}, queryObj) {
        commit(START_CONTENT_LOADER);
        ApplicationService.kindergartenApplications(getters.activeKindergartenId, queryObj).then(res => {
            if (queryObj.status === state.statuses.pending) {
                commit(SET_PENDING_KINDERGARTEN_APPLICATIONS, res);
            } else if (queryObj.status === state.statuses.confirmed) {
                commit(SET_CONFIRMED_KINDERGARTEN_APPLICATIONS, res);
            } else if (queryObj.status === state.statuses.rejected) {
                commit(SET_REJECTED_KINDERGARTEN_APPLICATIONS, res);
            } else if (queryObj.status === state.statuses.terminated) {
                commit(SET_TERMINATED_KINDERGARTEN_APPLICATIONS, res);
            }
            commit(STOP_CONTENT_LOADER);
        });
    },
    [REJECT_KINDERGARTEN_APPLICATION]({commit, getters}, {applicationId, data}) {
        return ApplicationService.reject(getters.activeKindergartenId, applicationId, data).then(res => {
            commit(UPDATE_KINDERGARTEN_APPLICATION, res);
            return res;
        });
    },
    [COMFIRM_KINDERGARTEN_APPLICATION]({commit, getters}, {applicationId, data}) {
        return ApplicationService.confirm(getters.activeKindergartenId, applicationId, data).then(res => {
            commit(UPDATE_KINDERGARTEN_APPLICATION, res);
            return res;
        });
    },
    async [ADD_REMOVE_CHILD]({commit, getters}, {applicationId, data}) {
        return ApplicationService.addRemoveChild(getters.activeKindergartenId, applicationId, data).then(res => {
            commit(UPDATE_KINDERGARTEN_APPLICATION, res);
            return res;
        });
    },
    // [UPDATE_KINDERGARTEN_APPLICATION]({commit}, data) {
    //     ApplicationService.update(data).then(res => {
    //         commit(UPDATE_KINDERGARTEN_APPLICATION, res);
    //     });
    // },
    [GET_APPLICATION](context, applicationId) {
        return ApplicationService.show(context.getters.activeKindergartenId, applicationId).then(res => res);
    }
};

const getters = {
    userApplications: state => {
        return state.user_applications;
    },
    pendingKindergartenApplications: state => {
        return state.pending_applications;
    },
    confirmedKindergartenApplications: state => {
        return state.confirmed_applications;
    },
    rejectedKindergartenApplications: state => {
        return state.rejected_applications;
    },
    terminatedKindergartenApplications: state => {
        return state.terminated_applications;
    },
    applicationStatuses: state => {
        return state.statuses;
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};
