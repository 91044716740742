<template>
    <div>
        <div v-if="data.length">
            <div v-for="message in data" :key="message.id" class="e3-pointer d-flex flex-row px-3 py-2"
                 @click="message.type === messageTypes.message ? showMessage(message) : showAnnouncements()">
                <div class="profile-image-container">
                    <ProfileImage
                        :imageUrl="message.sender.profile_picture"
                        :name="message.sender.fname + ' ' + message.sender.lname"
                        size="sm"/>
                </div>
                <div class="d-flex flex-column ml-2 w-100">
                    <div class="d-flex flex-row">
                        <div class="font-weight-bold">
                            {{ message.sender.fname }} {{ message.sender.lname }}
                        </div>
                        <div class="ml-auto">
                            <span v-if="message.receiver.seen === null" class="badge badge-primary text-white ml-auto p-2 text-uppercase">
                                {{ $t('New') }}
                            </span>
                        </div>
                    </div>
                    <div class="text-primary text-break">{{ truncate(message.subject, 64) }}</div>
                    <div class="text-break">{{ truncate(stripHtmlTags(message.body), 64) }}</div>
                    <small class="text-muted">{{ formatDateTime(message.created_at) }}</small>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-list-message v-if="tab === messageTypes.messages" wrapperClass="p-2">
                {{ $t('There are no received messages') }}
            </empty-list-message>
            <empty-list-message v-if="tab === messageTypes.announcements" wrapperClass="p-2">
                {{ $t('There are no received announcements') }}
            </empty-list-message>
        </div>
    </div>
</template>

<script>
import {eventBus} from '@/main';
import {mapGetters} from 'vuex';
import {formatDateTime, truncate, stripHtmlTags} from '@/filters';

export default {
    props: {
        data: Array,
        tab: String
    },
    computed: {
        ...mapGetters(['messageTypes'])
    },
    methods: {
        truncate, // TODO: Vue3 remove
        stripHtmlTags, // TODO: Vue3 remove
        formatDateTime, // TODO: Vue3 remove
        showMessage(message) {
            eventBus.$emit('show-message-modal', {message, tab:this.messageTypes.received});
        },
        showAnnouncements() {
            if (this.$route.path !== '/announcements/') this.$router.push('/announcements');
        }
    },
};
</script>

<style scoped>
    .profile-image-container {
        padding-top: 4px;
    }
</style>
