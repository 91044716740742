export const GET_ADMIN_ORGANIZATIONS = 'GET_ADMIN_ORGANIZATIONS';
export const SET_ADMIN_ORGANIZATIONS = 'SET_ADMIN_ORGANIZATIONS';
export const STORE_ORGANIZATION = 'STORE_ORGANIZATION';
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const SET_ADMIN_USERS = 'SET_ADMIN_USERS';
export const ADD_ADMIN_USER = 'ADD_ADMIN_USER';
export const STORE_ADMIN_USER = 'STORE_ADMIN_USER';
export const EDIT_ADMIN_USER = 'EDIT_ADMIN_USER';
export const SET_ADMIN_USER = 'SET_ADMIN_USER';
export const DELETE_ADMIN_USER = 'DELETE_ADMIN_USER';
export const REMOVE_ADMIN_USER = 'REMOVE_ADMIN_USER';
export const GET_ADMIN_ROLES = 'GET_ADMIN_ROLES';
export const SET_ADMIN_ROLES = 'SET_ADMIN_ROLES';
export const GET_ADMIN_PERMISSIONS = 'GET_ADMIN_PERMISSIONS';
export const SET_ADMIN_PERMISSIONS = 'SET_ADMIN_PERMISSIONS';
export const UPDATE_ADMIN_ROLE = 'UPDATE_ADMIN_ROLE';
export const SET_ADMIN_ROLE = 'SET_ADMIN_ROLE';
export const STORE_ADMIN_ROLE = 'STORE_ADMIN_ROLE';
export const ADD_ADMIN_ROLE = 'ADD_ADMIN_ROLE';
export const DELETE_ADMIN_ROLE = 'DELETE_ADMIN_ROLE';
export const REMOVE_ADMIN_ROLE = 'REMOVE_ADMIN_ROLE';
export const GET_ADMIN_COUNTRIES = 'GET_ADMIN_COUNTRIES';
export const SET_ADMIN_COUNTRIES = 'SET_ADMIN_COUNTRIES';
export const GET_ADMIN_KINDERGARTENS = 'GET_ADMIN_KINDERGARTENS';
export const SET_ADMIN_KINDERGARTENS = 'SET_ADMIN_KINDERGARTENS';

// Admin Child Development
export const GET_ADMIN_KINDERGARTEN_SETTING_TEMPLATES   = 'GET_ADMIN_KINDERGARTEN_SETTING_TEMPLATES';
export const SET_ADMIN_KINDERGARTEN_SETTING_TEMPLATES   = 'SET_ADMIN_KINDERGARTEN_SETTING_TEMPLATES';
export const SET_ADMIN_KINDERGARTEN_SETTING_TEMPLATE    = 'SET_ADMIN_KINDERGARTEN_SETTING_TEMPLATE';
export const UPDATE_ADMIN_KINDERGARTEN_SETTING_TEMPLATE = 'UPDATE_ADMIN_KINDERGARTEN_SETTING_TEMPLATE';

export const GET_ADMIN_CHILDDEV_AGE_GROUPS = 'GET_ADMIN_CHILDDEV_AGE_GROUPS';
export const SET_ADMIN_CHILDDEV_AGE_GROUPS = 'SET_ADMIN_CHILDDEV_AGE_GROUPS';
export const ADD_ADMIN_CHILDDEV_AGE_GROUP = 'ADD_ADMIN_CHILDDEV_AGE_GROUPS';
export const STORE_ADMIN_CHILDDEV_AGE_GROUP = 'STORE_ADMIN_CHILDDEV_AGE_GROUP';
export const UPDATE_ADMIN_CHILDDEV_AGE_GROUP = 'UPDATE_ADMIN_CHILDDEV_AGE_GROUP';
export const SET_ADMIN_CHILDDEV_AGE_GROUP = 'SET_ADMIN_CHILDDEV_AGE_GROUP';
export const DELETE_ADMIN_CHILDDEV_AGE_GROUP = 'DELETE_ADMIN_CHILDDEV_AGE_GROUP';
export const REMOVE_ADMIN_CHILDDEV_AGE_GROUP = 'REMOVE_ADMIN_CHILDDEV_AGE_GROUP';
export const SELECT_ADMIN_CHILDDEV_COUNTRY = 'SELECT_ADMIN_CHILDDEV_COUNTRY';

export const GET_ADMIN_CHILDDEV_CATEGORIES  = 'GET_ADMIN_CHILDDEV_CATEGORIES';
export const SET_ADMIN_CHILDDEV_CATEGORIES  = 'SET_ADMIN_CHILDDEV_CATEGORIES';
export const STORE_ADMIN_CHILDDEV_CATEGORY  = 'STORE_ADMIN_CHILDDEV_CATEGORY';
export const ADD_ADMIN_CHILDDEV_CATEGORY    = 'ADD_ADMIN_CHILDDEV_CATEGORY';
export const UPDATE_ADMIN_CHILDDEV_CATEGORY = 'UPDATE_ADMIN_CHILDDEV_CATEGORY';
export const SET_ADMIN_CHILDDEV_CATEGORY    = 'SET_ADMIN_CHILDDEV_CATEGORY';
export const DELETE_ADMIN_CHILDDEV_CATEGORY = 'DELETE_ADMIN_CHILDDEV_CATEGORY';
export const REMOVE_ADMIN_CHILDDEV_CATEGORY = 'REMOVE_ADMIN_CHILDDEV_CATEGORY';
export const SET_ADMIN_CHILDDEV_CATEGORIES_NEED_RELOAD = 'SET_ADMIN_CHILDDEV_CATEGORIES_NEED_RELOAD';

export const STORE_ADMIN_CHILDDEV_GOAL  = 'STORE_ADMIN_CHILDDEV_GOAL';
export const UPDATE_ADMIN_CHILDDEV_GOAL = 'UPDATE_ADMIN_CHILDDEV_GOAL';
export const SET_ADMIN_CHILDDEV_GOAL    = 'SET_ADMIN_CHILDDEV_GOAL';
export const ADD_ADMIN_CHILDDEV_GOAL    = 'ADD_ADMIN_CHILDDEV_GOAL';
export const DELETE_ADMIN_CHILDDEV_GOAL = 'DELETE_ADMIN_CHILDDEV_GOAL';
export const REMOVE_ADMIN_CHILDDEV_GOAL = 'REMOVE_ADMIN_CHILDDEV_GOAL';

export const STORE_ADMIN_KINDERGARTEN = 'STORE_ADMIN_KINDERGARTEN';
export const ADD_ADMIN_KINDERGARTEN = 'ADD_ADMIN_KINDERGARTEN';
export const SET_USER_SEARCH = 'SET_USER_SEARCH';
export const SET_EMPLOYEE_SEARCH = 'SET_EMPLOYEE_SEARCH';
export const SET_GUARDIAN_SEARCH = 'SET_GUARDIAN_SEARCH';
export const SET_CLASS_SEARCH = 'SET_CLASS_SEARCH';
export const SET_APPLICATION_SEARCH = 'SET_APPLICATION_SEARCH';
export const SET_TARGET_GROUP_SEARCH = 'SET_TARGET_GROUP_SEARCH';
export const SET_OFFICIAL_SEARCH = 'SET_OFFICIAL_SEARCH';
export const SET_ROLE_SEARCH = 'SET_ROLE_SEARCH';
export const GET_ADMIN_SUPPLIERS = 'GET_ADMIN_SUPPLIERS';
export const SET_ADMIN_SUPPLIERS = 'SET_ADMIN_SUPPLIERS';
export const VALIDATE_ADMIN_SUPPLIER = 'VALIDATE_ADMIN_SUPPLIER';
export const INVALIDATE_ADMIN_SUPPLIER = 'INVALIDATE_ADMIN_SUPPLIER';
export const SET_ADMIN_SUPPLIER = 'SET_ADMIN_SUPPLIER';
export const GET_ADMIN_SURVEYS = 'GET_ADMIN_SURVEYS';
export const SET_ADMIN_SURVEYS = 'SET_ADMIN_SURVEYS';
export const GET_ADMIN_SURVEY = 'GET_ADMIN_SURVEY';
export const UPDATE_ADMIN_SURVEY = 'UPDATE_ADMIN_SURVEY';
export const ADMIN_UPDATE_SURVEY_SETTINGS = 'ADMIN_UPDATE_SURVEY_SETTINGS';
export const ADMIN_STORE_QUESTION = 'ADMIN_STORE_QUESTION_';
export const ADMIN_STORE_QUESTION_OPTION = 'ADMIN_STORE_QUESTION_OPTION';
export const ADMIN_UPDATE_QUESTION = 'ADMIN_UPDATE_QUESTION';
export const ADMIN_UPDATE_QUESTION_OPTION = 'ADMIN_UPDATE_QUESTION_OPTION';
export const ADMIN_DELETE_QUESTION_OPTION = 'ADMIN_DELETE_QUESTION_OPTION';
export const ADMIN_DELETE_QUESTION = 'ADMIN_DELETE_QUESTION';
export const ADMIN_REORDER_QUESTION = 'ADMIN_REORDER_QUESTION';
export const ADMIN_REORDER_QUESTION_OPTION = 'ADMIN_REORDER_QUESTION_OPTION';
export const ADMIN_CREATE_QUESTION = 'ADMIN_CREATE_QUESTION';
export const ADMIN_DELETE_SURVEY = 'ADMIN_DELETE_SURVEY';
export const GET_ADMIN_SURVEY_RESULTS = 'GET_ADMIN_SURVEY_RESULTS';
export const SET_ADMIN_KINDERGARTEN_CHILD = 'SET_ADMIN_KINDERGARTEN_CHILD';
export const SET_ADMIN_KINDERGARTEN_COURSE  = 'SET_ADMIN_KINDERGARTEN_COURSE';
