import {
    MOBILE_MENU_VISIBLE,
    CLOSE_MOBILE_MENU,
    SET_SYSTEM_MESSAGE,
    STORE_SYSTEM_MESSAGE,
    SET_EMAIL_VERIFICATION,
    TOGGLE_SHOP_CATEGORY_MENU,
} from './actions';

const state = {
    messages: [],
    email_verification: false,
    email_verification_status: '',
    email_verification_email: '',
    mobile_menu_visible: false,
    shop_category_menu_visible: false,
};
const mutations = {
    [STORE_SYSTEM_MESSAGE](state, data) {
        state.messages.push(data);
    },
    [MOBILE_MENU_VISIBLE](state) {
        state.mobile_menu_visible = !state.mobile_menu_visible;
    },
    [TOGGLE_SHOP_CATEGORY_MENU](state, data) {
        state.shop_category_menu_visible = data;
    },
    [CLOSE_MOBILE_MENU](state) {
        state.mobile_menu_visible = false;
    },
    [SET_EMAIL_VERIFICATION](state, data) {
        state.email_verification = data.verification;
        state.email_verification_status = data.status;
    }
};
const actions = {
    [SET_SYSTEM_MESSAGE]({ commit }, data) {
        commit('STORE_SYSTEM_MESSAGE', data);
    },
    [MOBILE_MENU_VISIBLE]({ commit }) {
        commit('MOBILE_MENU_VISIBLE');
    },
    [CLOSE_MOBILE_MENU]({ commit }) {
        commit('CLOSE_MOBILE_MENU');
    },
    [SET_EMAIL_VERIFICATION]({ commit }, data) {
        return new Promise((resolve) => {
            commit(SET_EMAIL_VERIFICATION, data);
            resolve();
        });
    }
};
const getters = {
    systemMessages: state => {
        return state.messages;
    },
    mobileMenuVisible: state => {
        return state.mobile_menu_visible;
    },
    shopCategoryMenuVisible: state => {
        return state.shop_category_menu_visible;
    },
    verificationMode: state => {
        return state.email_verification;
    },
    verificationStatus: state => {
        return state.email_verification_status;
    },
    verificationEmail: state => {
        return state.email_verification_email;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
