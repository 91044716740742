import {
    GET_MANAGED_POLLS,
    SET_MANAGED_POLLS,
    STORE_POLL,
    SET_POLL,
    GET_POLLS,
    SET_POLLS,
    FILL_POLL_QUESTION,
    GET_POLL_ANSWERS,
    GET_POLL_RESULTS,
} from './actions';
import PollService from '@services/poll.service';
import SurveyService from '@services/survey.service';

const defaultState = () => {
    return {
        managedPolls: [],
        polls: [],
        poll: null
    };
};

const state = defaultState;

const mutations = {
    [SET_MANAGED_POLLS](state, data) {
        state.managedPolls = data;
    },
    [SET_POLLS](state, data) {
        state.polls = data;
    },
    [SET_POLL](state, data) {
        state.poll = data;
    }
};

const actions = {
    [GET_MANAGED_POLLS]({commit, getters}, queryObj) {
        return PollService.getManagedPolls(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_MANAGED_POLLS, res);
        });
    },
    [STORE_POLL]({getters}, data) {
        return SurveyService.storePoll(getters.activeKindergartenId, data).then(res => {
            return res.survey_id;
        });
    },
    [GET_POLLS]({commit, getters}, queryObj) {
        return PollService.getPolls(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_POLLS, res);
            return res.survey_id;
        });
    },
    [FILL_POLL_QUESTION]({getters}, data) {
        return PollService.fillPollQuestion(getters.activeKindergartenId, data.survey_id, data).then(res => {
            return res;
        });
    },
    [GET_POLL_RESULTS]({getters}, surveyId) {
        return PollService.getPollResults(getters.activeKindergartenId, surveyId).then(res => {
            return res;
        });
    },
    [GET_POLL_ANSWERS]({getters}, surveyId) {
        return PollService.getPollAnswers(getters.activeKindergartenId, surveyId).then(res => {
            return res;
        });
    },
};

const getters = {
    managedPolls: state => state.managedPolls,
    polls: state => state.polls,
    selectedPoll: state => state.poll
};

export default {
    state,
    mutations,
    actions,
    getters
};