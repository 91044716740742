<template>
    <BootstrapModal
        id="cookie-accept-modal"
        hide-header
        hide-footer
        centered
        no-close-on-backdrop>
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-4">
            <h4 class="order-2 order-sm-1 m-0">{{ $t('Cookie settings') }}</h4>
            <div class="order-1 order-sm-2 ml-auto mb-2 mb-sm-0">
                <LanguageSwitcher
                    :landingPage="true"
                    :boxSelect="false"/>
            </div>
        </div>
        <div class="mb-4">
            <p class="text-break mb-0 mr-3">{{ $t('This website uses cookies to enhance your browsing experience and provide a more personalized service.') }}</p>
        </div>
        <div v-if="showOptions">
            <div class="d-flex mb-3">
                <ECheckbox
                    id="necessary-check"
                    v-model="form.essential"
                    class="mr-2"
                    disabled
                    success/>
                <div class="d-flex flex-column flex-grow-1">
                    <span class="font-weight-bold">{{ $t('Necessary') }}</span>
                    <span>{{ $t('Necessary cookie description') }}</span>
                </div>
            </div>
            <div class="d-flex mb-3">
                <ECheckbox
                    id="statistics-check"
                    v-model="form.statistics"
                    class="mr-2"
                    success/>
                <div class="d-flex flex-column flex-grow-1">
                    <span class="font-weight-bold">{{ $t('Statistics') }}</span>
                    <span>{{ $t('Statistical cookie description') }}</span>
                </div>
            </div>
            <div class="d-flex mb-3">
                <ECheckbox
                    id="marketing-check"
                    v-model="form.marketing"
                    class="mr-2"
                    success/>
                <div class="d-flex flex-column flex-grow-1">
                    <span class="font-weight-bold" :class="{'text-danger': markMarketingAsRequired}">{{ $t('Marketing') }}</span>
                    <span>{{ $t('Marketing cookie description') }}</span>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mt-4">
            <div v-if="!showOptions" class="text-primary e3-pointer mr-sm-auto" @click="showOptions = !showOptions">{{ $t('Manage my preferences')}}</div>
            <button class="btn btn-danger ml-sm-auto mr-sm-2 my-3 my-sm-0" @click="reject()">{{ $t('Reject') }}</button>
            <button class="btn btn-success" @click="accept()">{{ $t('Accept') }}</button>
        </div>
    </BootstrapModal>
</template>

<script>
import BootstrapModal from '@components/modals/BootstrapModal.vue';
import LanguageSwitcher from '@components/LanguageSwitcher.vue';
import EventBus from '@/utils/eventBus';
import {saveCookiePreferences, getCookiePreferences, hasCookieAccepted} from '@/utils/cookieConsent';
import {initializeSentry} from '@/utils/sentry';
import {initFirebase} from '@/utils/firebase';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
    name: 'CookieAccept',
    data() {
        return {
            is_accepted: false,
            showOptions: false,
            form: {
                essential: true,
                statistics: true,
                marketing: true
            },
            markMarketingAsRequired: false
        };
    },
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        reject() {
            this.removeCookies();
            if (this.user) {
                this.$store.dispatch('LOGOUT');
            }
            EventBus.emit('hide-modal', 'cookie-accept-modal');
        },
        removeCookies() {
            this.$cookies?.keys().forEach(cookie => {
                if (process.env.NODE_ENV !== 'development') {
                    this.$cookies.remove(cookie, null, `.${window.location.hostname}`);
                } else {
                    this.$cookies.remove(cookie);
                }
            });
        },
        accept() {
            const cookiePreferences = {
                ...this.form,
                consentTime:  new Date()
            };

            saveCookiePreferences(cookiePreferences);

            this.getLanguages();

            if (cookiePreferences.statistics) {
                initFirebase();
                initializeSentry(Vue);
            }

            EventBus.emit('cookie-preferences-saved', cookiePreferences);
            EventBus.emit('hide-modal', 'cookie-accept-modal');
        },
        getLanguages() {
            this.$store.dispatch('GET_LANGUAGES').then(() => {
                const lang = localStorage.getItem('lang') || 'en';
                const html = document.documentElement;
                html.setAttribute('lang', lang);
            });
        },
        showModal(eventData) {
            if (eventData) {
                this.showOptions = eventData.displayPreferences;

                if (eventData.requiredCookie === 'marketing') {
                    this.markMarketingAsRequired = true;
                }
            } else {
                this.markMarketingAsRequired = false;
            }

            EventBus.emit('show-modal', 'cookie-accept-modal');
        }
    },
    components: {
        LanguageSwitcher,
        BootstrapModal
    },
    created() {
        const cookiePreferences = getCookiePreferences();

        if (cookiePreferences) {
            this.form.statistics = cookiePreferences.statistics;
            this.form.marketing = cookiePreferences.marketing;
        }
    },
    mounted() {
        EventBus.on('show-cookie-accept-modal', this.showModal);
        if (hasCookieAccepted('essential')) {
            if (hasCookieAccepted('statistics')) {
                initFirebase();
            }
        } else {
            this.showModal();
        }
    },
    beforeDestroy() {  // Vue 3 Rename beforeUnmount
        EventBus.off('show-cookie-accept-modal', this.showModal);
    }
};
</script>

<style lang="scss" scoped>
.cookie-accept {
    background: #f1f1f1;
    padding: 1.25em;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 999;
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: baseline;
    align-items: baseline;
}
</style>
