const Settings = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/settings');
const General = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/settings/views/General');
const Modules = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/settings/views/Modules');
const Maps = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/settings/views/Maps');
const Approvals = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/settings/views/Approvals');
const Diets = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/settings/views/Diets');

const KindergartenChildren = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/children');
const ActiveChildren = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/children/views/ActiveChildren');
const ArchivedChildren = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/children/views/ArchivedChildren');

const KindergartenCourses = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/courses');
const Courses = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/courses/views/Courses');
const ArchivedCourses = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/courses/views/ArchivedCourses');

const Employees = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/employees/index');
const ActiveEmployees = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/employees/views/Employees');
const ArchivedEmployees = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/employees/views/ArchivedEmployees');

const TargetGroups = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/target_groups/index');
const SystemTargetGroups = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/target_groups/views/SystemTargetGroups');
const KindergartenTargetGroups = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/target_groups/views/KindergartenTargetGroups');
const TargetGroup = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/target_groups/views/TargetGroup');

const Roles = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/roles/Roles');
const Parents = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/parents/Parents');

const Import = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/import');
const FileImport = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/import/views/FileImport');
const FileImportDataMap = () => import(/* webpackChunkName: "kindergarten-module" */ '@/views/kindergarten/import/components/FileImportDataMap');
const FileImportDataOverview = () => import(/* webpackChunkName: "kindergarten-module" */ '@/views/kindergarten/import/components/FileImportDataOverview');
const ArnoImport = () => import( /* webpackChunkName: "kindergarten-module" */'@views/kindergarten/import/views/ArnoImport');
const ThisImport = () => import('@views/kindergarten/import/views/THIS');

// const DoorCards = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/door_cards/index');
const CardChildren = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/door_cards/views/Children');
// const CardEmployees = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/door_cards/views/Employees');
// const CardDoors = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/kindergarten/door_cards/views/Doors');

import store from '@/store';

export default [
    {
        path: 'settings',
        components: { base: Settings },
        children: [
            {
                path: '',
                name: 'kindergarten.settings',
                components: { 'kindergarten-settings': General },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessKindergartenSettings) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'modules',
                name: 'kindergarten.modules',
                components: { 'kindergarten-settings': Modules },
            },
            {
                path: 'maps',
                name: 'kindergarten.maps',
                components: { 'kindergarten-settings': Maps }
            },
            {
                path: 'approvals',
                name: 'kindergarten.approvals',
                components: { 'kindergarten-settings': Approvals },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canManageKindergartenChildApprovals) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                //PHASE 2
                path: 'data',
                name: 'kindergarten.data',
                // components: { 'kindergarten-settings': Maps }
            },
            {
                path: 'diets',
                name: 'kindergarten.diets',
                components: { 'kindergarten-settings': Diets },
            }
        ]
    },
    {
        path: 'children',
        components: { base: KindergartenChildren },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartenChildren) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: '',
                name: 'kindergarten.active-children',
                components: { children: ActiveChildren }
            },
            {
                path: 'archived',
                name: 'kindergarten.archived-children',
                components: { children: ArchivedChildren }
            }
        ]
    },
    {
        path: 'courses',
        components: { base: KindergartenCourses },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartenCourses) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: '',
                name: 'kindergarten.courses',
                components: { course: Courses },
                meta: 'regular'
            },
            {
                path: 'special',
                name: 'kindergarten.special-courses',
                components: { course: Courses },
                meta: 'special'
            },
            {
                path: 'summer',
                name: 'kindergarten.summer-courses',
                components: { course: Courses },
                meta: 'summer'
            },
            {
                path: 'archived',
                name: 'kindergarten.archived-courses',
                components: { course: ArchivedCourses },
                meta: 'archived'
            }
        ]
    },
    {
        path: 'employees',
        components: { base: Employees },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartenEmployees) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: '',
                name: 'kindergarten.employees',
                components: { children: ActiveEmployees }
            },
            {
                path: 'archived',
                name: 'kindergarten.archived-employees',
                components: { children: ArchivedEmployees }
            }
        ]
    },
    {
        path: 'roles',
        name: 'kindergarten.roles',
        components: { base: Roles }
    },
    {
        path: 'parents',
        name: 'kindergarten.parents',
        components: { base: Parents },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartenGuardians) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
    },
    {
        path: 'import',
        components: { base: Import },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartenImport) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: '',
                name: 'kindergarten.import.file',
                components: { import: FileImport },
            },
            {
                path: 'file/data-map',
                name: 'kindergarten.import.file.data.map',
                components: { import: FileImportDataMap },
            },
            {
                path: 'file/data-overview',
                name: 'kindergarten.import.file.data.overview',
                components: { import: FileImportDataOverview },
            },
            {
                path: 'arno',
                name: 'kindergarten.import.arno',
                components: {import: ArnoImport}
            },
            {
                path: 'this',
                name: 'kindergarten.import.this',
                components: {import : ThisImport}
            }
        ]
    },
    {
        path: '/kindergarten/target-groups',
        components: { base: TargetGroups },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartenTargetGroups) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: '',
                name: 'kindergarten.kindergarten-groups',
                components: { kindergartenTargetGroups: KindergartenTargetGroups}
            },
            {
                path: '/kindergarten/system-groups',
                name: 'kindergarten.system-groups',
                components: {
                    kindergartenTargetGroups: SystemTargetGroups
                }
            }
        ]
    },
    {
        path: '/kindergarten/target-groups/:target_group_id',
        name: 'kindergarten.group',
        components: { base: TargetGroup },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartenTargetGroups) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
    },
    {
        path: '/kindergarten/door-cards',
        components: { base: CardChildren },
        name: 'kindergarten.door-cards',
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartenDoorCards) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        // children: [
        //     {
        //         path: '',
        //         name: 'kindergarten.door-cards',
        //         components: { doorCards: CardChildren}
        //     },
        //     {
        //         path: '/kindergarten/door-cards/employees',
        //         name: 'kindergarten.card-employees',
        //         components: {
        //             doorCards: CardEmployees
        //         }
        //     },
        //     {
        //         path: '/kindergarten/door-cards/doors',
        //         name: 'kindergarten.card-doors',
        //         components: {
        //             doorCards: CardDoors
        //         }
        //     }
        // ]
    },
];
