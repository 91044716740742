import { render, staticRenderFns } from "./SupplierProductSearch.vue?vue&type=template&id=01dc1ad9"
import script from "./SupplierProductSearch.vue?vue&type=script&lang=js"
export * from "./SupplierProductSearch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports