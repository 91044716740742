import http from '@http';

class SupplierIntegrationService {
    constructor() {
        if (SupplierIntegrationService.instance) {
            return SupplierIntegrationService.instance;
        }
        SupplierIntegrationService.instance = this;
    }

    availableIntegrationClients(supplierId) {
        return http.get(`/api/suppliers/${supplierId}/integrations/available-clients`).then(res => res.data);
    }

    supplierIntegrationClients(supplierId) {
        return http.get(`/api/suppliers/${supplierId}/integrations`).then(res => res.data);
    }

    supplierIntegrationClient(supplierId, integrationId) {
        return http.get(`/api/suppliers/${supplierId}/integrations/${integrationId}`).then(res => res.data);
    }

    addSupplierIntegrationClient(supplierId, data) {
        return http.post(`/api/suppliers/${supplierId}/integrations`, data).then(res => res.data);
    }

    updateSupplierIntegrationClient(supplierId, data) {
        return http.put(`/api/suppliers/${supplierId}/integrations/${data.id}`, data).then(res => res.data);
    }

    availableCategories(supplierId, integrationId) {
        return http.get(`/api/suppliers/${supplierId}/integrations/${integrationId}/available-categories`).then(res => res.data);
    }

    categories(supplierId, integrationId) {
        return http.get(`/api/suppliers/${supplierId}/integrations/${integrationId}/categories`).then(res => res.data);
    }

    syncCategories(supplierId, integrationId, data) {
        return http.put(`/api/suppliers/${supplierId}/integrations/${integrationId}/categories`, data).then(res => res.data);
    }

    integrationCategories(supplierId, integrationId, queryObj) {
        return http.get(`/api/suppliers/${supplierId}/integrations/${integrationId}/integration-categories`, {params: queryObj}).then(res => res.data);
    }

    products(supplierId, integrationId, queryObj) {
        return http.get(`/api/suppliers/${supplierId}/integrations/${integrationId}/products`, {params: queryObj}).then(res => res.data);
    }

    services(supplierId, integrationId, queryObj) {
        return http.get(`/api/suppliers/${supplierId}/integrations/${integrationId}/services`, {params: queryObj}).then(res => res.data);
    }

    priceLists(supplierId, integrationId) {
        return http.get(`/api/suppliers/${supplierId}/integrations/${integrationId}/price-lists`).then(res => res.data);
    }

    selectPriceList(supplierId, integrationId, data) {
        return http.put(`/api/suppliers/${supplierId}/integrations/${integrationId}/price-lists`, data).then(res => res.data);
    }
}

export default new SupplierIntegrationService();
