<template>
    <div class="row e3-list-item" :class="{'e3-list-align' : align, 'align-items-center': verticalAlign}">
        <div class="e3-bold" :class="labelClass ? labelClass : 'col-sm-6'">
            {{ $t(fieldLabel) }}:
        </div>
        <div class="e3-field" :class="fieldClass ? fieldClass : 'col-sm-6'">
            <slot/>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        fieldLabel: String,
        labelClass: String,
        fieldClass: String,
        align: {
            type: Boolean,
            default: true
        },
        verticalAlign: {
            type: Boolean,
            default: true
        }
    },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 576px){
    .e3-list-align {
        .e3-bold {
            text-align: right;
        }
        .e3-field {
            text-align: left;
        }
    }
}

.e3-list-item {
    margin-bottom: 0.25rem;
}
</style>
