//mutations
export const RESET_DOCUMENT_STATE = 'RESET_DOCUMENT_STATE';
export const SET_RECENT_DOCUMENTS = 'SET_RECENT_DOCUMENTS';
export const SET_RECENT_DOCUMENT_COUNT = 'SET_RECENT_DOCUMENT_COUNT';
export const SET_SHARED_DOCUMENT_FILES = 'SET_SHARED_DOCUMENT_FILES';
export const SET_FILE_LIST_MAX_PAGE = 'SET_FILE_LIST_MAX_PAGE';
export const SET_FOLDER_FILES_TOTAL = 'SET_FOLDER_FILES_TOTAL';

//actions
export const GET_DOCUMENT_FOLDERS = 'GET_DOCUMENT_FOLDERS';
export const GET_RECENT_DOCUMENTS = 'GET_RECENT_DOCUMENTS';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const SET_DOCUMENT_ACTIVITY = 'SET_DOCUMENT_ACTIVITY';
export const UPDATE_DOCUMENT_FILE = 'UPDATE_DOCUMENT_FILE';
export const GET_SHARED_DOCUMENT_FILES = 'GET_SHARED_DOCUMENT_FILES';
