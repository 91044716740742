import http from '@http';
import store from '../../store';
import {download} from '@services/helper.service';

class DiaryService {
    constructor() {
        if (DiaryService.instance) {
            return DiaryService.instance;
        }

        DiaryService.instance = this;
    }

    getDiaryData(data) {
        const params = {params: { course: data.course_id, date: data.date }};
        return http.get(`/api/kindergartens/${store.getters.activeKindergartenId}/diary`, params).then(res => res.data);
    }

    getDiaryWeekData(data) {
        const params = {params: { course: data.course_id, date: data.date }};
        return http.get(`/api/kindergartens/${store.getters.activeKindergartenId}/diary/week`, params).then(res => res.data);
    }

    getAdminDiary(id) {
        return http.get(`/api/diary/show/${id}`).then(res => res.data);
    }

    updateSingleEntry(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/diary/${data.diary.id}/update-entry`, data).then(res => res.data);
    }

    updateAllEntries(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/diary/${data.diary.id}/update-entries`, data).then(res => res.data);
    }

    publish(kindergartenId, diaryId) {
        return http.put(`/api/kindergartens/${kindergartenId}/diary/${diaryId}/publish`).then(res => res.data);
    }

    saveComment(kindergartenId, data) {
        return this.updateComment(kindergartenId, data.diary.id, data.data);
    }

    updateComment(kindergartenId, diaryId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/diary/${diaryId}/save-comment`, {data}).then(res => res.data);
    }

    createDiary(kindergartenId, diary) {
        return http.post(`/api/kindergartens/${kindergartenId}/diary`, diary).then(res => res.data);
    }

    getGuardianDiary(kindergartenId, childId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/diary`).then(res => res.data);
    }

    getGuardianFeed(kindergartenId, childId, params) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/guardian-feed`, {params: params }).then(res => res.data);
    }

    getTodaysAttendance(kindergartenId, courseId) {
        return http.get(`/api/kindergartens/${kindergartenId}/diary/course/${courseId}/attendance`).then(res => res.data);
    }

    getTodaysComments(kindergartenId, courseId) {
        return http.get(`/api/kindergartens/${kindergartenId}/diary/course/${courseId}/comments`).then(res => res.data);
    }

    uploadMedia(kindergartenId, diary, file) {
        return http.post(`/api/kindergartens/${kindergartenId}/diary/${diary.id}/media`, file).then(res => res.data);
    }

    uploadDocument(kindergartenId, diary, file) {
        return http.post(`/api/kindergartens/${kindergartenId}/diary/${diary.id}/document`, file).then(res => res.data);
    }

    getDiaryFile(kindergartenId, file) {
        return http.download(`/api/kindergartens/${kindergartenId}/files/diary/${file.filename}`)
            .then(res => download(res.data, file));
    }

    updateDiaryFile(kindergartenId, filename, form) {
        return http.put(`/api/kindergartens/${kindergartenId}/files/diary/${filename}`, form).then(res => res.data);
    }

    deleteDiaryFile(kindergartenId, filename) {
        return http.delete(`/api/kindergartens/${kindergartenId}/files/diary/${filename}`)
            .then(res => res.data);
    }

    likeDiary(kindergartenId, diaryId) {
        return http.post(`/api/kindergartens/${kindergartenId}/diary/${diaryId}/like`)
            .then(res => res.data);
    }
}

export default new DiaryService();
