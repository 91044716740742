import http from '@http';

class DietaryOptionService {
    constructor() {
        if (DietaryOptionService.instance) {
            return DietaryOptionService.instance;
        }
        DietaryOptionService.instance = this;
    }

    getKindergartenDietaryOptions(kindergartenId) {
        return http
            .get(`/api/kindergartens/${kindergartenId}/dietary-options`)
            .then(res => res.data);
    }

    createKindergartenDietaryOption(kindergartenId, option) {
        return http
            .post(`/api/kindergartens/${kindergartenId}/dietary-options`, {name: option})
            .then(res => res.data);
    }

    deleteKindergartenDietaryOption(kindergartenId, optionId) {
        return http
            .delete(`/api/kindergartens/${kindergartenId}/dietary-options/${optionId}`)
            .then(res => res.data);
    }
}

export default new DietaryOptionService();
