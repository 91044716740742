<template>
    <div>
        <div>
            <span class="font-weight-bold">{{ notification.creator.fname }} {{ notification.creator.lname }}</span>
            <span> ({{ notification.kindergarten.name}}) </span>
            <span>{{$t('Notification - New wishlist item', {item: shopTranslate(notification.data.name)})}}</span>
        </div>
        <div class="text-black-50">
            {{ formatDateTime(notification.created_at) }}
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {shopTranslate} from '@/common/services/helper.service';
import {formatDateTime} from '@/filters';

export default {
    name: 'KindergartenWishlistNotification',
    props: {
        notification: Object
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['userKindergartens']),
        data() {
            return this.notification.data;
        },
    },
    methods: {
        formatDateTime, // TODO: Vue3 remove
        shopTranslate
    }
};
</script>

<style scoped>

</style>
