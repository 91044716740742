<template>
    <div :class="[fgClass,{'form-group': isLabelSlot,'has-feedback has-error': validation && validation.$error}]">
        <label v-if="isLabelSlot" :for="id" :class="labelClass">
            <slot name="label"/>
            <template v-if="optional">
                - <small>{{ $t('Optional') }}</small>
            </template>
        </label>

        <div :class="[inputBlockClass, { 'input-group': isAppendSlot || isPrependSlot }, inputContainerClass]">
            <div v-if="isPrependSlot" class="input-group-prepend">
                <slot name="input-prepend"/>
            </div>
            <input
                :id="id"
                :ref="`${id}_input`"
                v-model="val"
                class="form-control"
                :class="[{'is-invalid': validation && validation.$error,'form-control-lg': isLargeInput}, inputClass, {'border-right-0': isAppendSlot}]"
                :type="inputType"
                :disabled="disabled"
                :placeholder="placeholder"
                step="any"
                lang="en"
                :min="minValue"
                :maxlength="maxlength"
                @keyup="onKeyup"
                @focus="onFocus"
                @blur="onBlur"
                @keypress.enter="onKeypressEnter"
                @change="change"
                @keypress="handleKeypress"/>
            <div v-if="isAppendSlot" class="input-group-append">
                <slot name="input-append"/>
            </div>
            <div :class="{'d-flex justify-content-between': validation && validation.$error && charCounter}">
                <ValitationMessages :validation="validation" :class="valitationClasses"/>
                <div v-if="charCounter" class="text-right e3-char-counter" :class="{'e3-char-limit': usedChars === maxlength }"> {{ usedChars }}/{{ maxlength }} </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EInput',
    props: {
        id: [String, Number],
        minValue: Number,
        value: [String, Number],
        type: String,
        disabled: Boolean,
        placeholder: String,
        validation: Object,
        inline: Boolean,
        optional: Boolean,
        uppercase: Boolean,
        large: Boolean,
        formGroupClass: String,
        inputClass: {
            type: String,
            default: ''
        },
        inputContainerClass: {
            type: String,
            default: ''
        },
        maxlength: {
            type: Number,
            default: null
        },
        charCounter: Boolean,
        valitationClasses: {
            type: String,
            default: ''
        },
        replaceCommaWithPeriod: {
            type: Boolean,
            default: false
        },
        noLetters: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localValue: '',
            usedChars: null
        };
    },
    computed: {
        val: {
            get() {
                if(this.charCounter) {
                    this.updateUsedChars();
                }
                return this.value;
            },
            set(value) {
                // this.updateLastValue(value);
                if (this.replaceCommaWithPeriod) {
                    value = value.replace(/,/g, '.');
                }
                if (this.isNumberType) {
                    this.$emit('input', value.replace(/,/g, '.'));
                } else if (this.uppercase) {
                    this.$emit('input', value.toUpperCase());
                } else {
                    this.$emit('input', value);
                }
            }
        },
        fgClass() {
            return this.formGroupClass;
        },
        labelClass() {
            if (this.inline) {
                return 'col-md-3';
            }

            return '';
        },
        inputBlockClass() {
            if (this.inline) {
                return 'col-md-9';
            }

            return '';
        },
        isLargeInput() {
            return this.large;
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        isAppendSlot() {
            return !!this.$slots['input-append'];
        },
        isPrependSlot() {
            return !!this.$slots['input-prepend'];
        },
        isNumberType() {
            return this.type === 'number';
        },
        inputType() {
            if (this.type && this.type !== 'text') {
                return this.type;
            }
            return 'text';
        },
    },
    methods: {
        onKeypressEnter(e) {
            this.$emit('keypress-enter', e);
        },
        onKeyup(e) {
            this.$emit('keyup', e);
            this.validation && this.validation.$touch();
        },
        onBlur(e) {
            this.$emit('blur', e);
            this.validation && this.validation.$touch();
        },
        onFocus(e) {
            this.$emit('focus', e);
        },
        clear() {
            if (this.$refs.inputField) {
                this.$refs.inputField.value = '';
            }
        },
        blur() {
            if (this.$refs.inputField) {
                this.$refs.inputField.blur();
            }
        },
        focus() {
            if (this.$refs.inputField) {
                this.$refs.inputField.focus();
            }
        },
        change(e) {
            this.$emit('change', e);
        },
        updateUsedChars() {
            this.usedChars = this.value ? this.value.length : 0;
        },
        handleKeypress(event) {
            if (this.noLetters) {
                if (!'1234567890.,'.includes(event.key)) {
                    event.preventDefault();
                }
            }
        }
    },
    created() {
        this.localValue = this.value;
        this.usedChars = this.value ? this.value.length : 0;
    }
};
</script>

<style lang="scss" scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.fs-14 {
    font-size: 14px;
}

.input-group-append:focus {
    border-color:#f79e80;
}
</style>
