import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import channels from './modules/channels';
import loader from './modules/loader';
import localization from './modules/localization';
import diary from './modules/diary';
import event from './modules/event';
import kindergarten from './modules/kindergarten';
import user from './modules/user';
import employee from './modules/employee';
import guardian from './modules/guardian';
import system from './modules/system';
import message from './modules/message';
import announcement from './modules/announcement';
import application from './modules/application';
import document from './modules/document';
import folder from './modules/folder';
import course from './modules/course';
import common from './modules/common';
import foodmenu from './modules/foodmenu';
import child from './modules/child';
import childdev from './modules/childdev';
import template from './modules/template';
import plan from './modules/plan';
import map from './modules/map';
import dashboard from './modules/dashboard';
import importStore from './modules/import';
import workschedule from './modules/workschedule';
import targetgroups from './modules/targetgroup';
import gallery from './modules/gallery';
import employee_meals from './modules/employee-meals';
import role from './modules/role';
import settings from './modules/settings';
import approvals from './modules/approvals';
import integrations from './modules/integration';
import admin from './modules/admin';
import organization from './modules/organization';
import official from './modules/official';
import idea from './modules/idea';
import arno from './modules/arno';
import THIS from './modules/this';
import supplier from './modules/supplier';
import shop from './modules/shop';
import banner from './modules/banner';
import door_card from './modules/door-card';
import childCalendar from '@store/child-calendar';
import notification from '@store/notification';
import survey from '@store/survey';
import questionnaire from '@store/questionnaire';
import poll from '@store/poll';
import dietaryOption from './modules/dietary-option';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth,
        channels,
        loader,
        localization,
        diary,
        event,
        kindergarten,
        user,
        employee,
        guardian,
        system,
        message,
        announcement,
        application,
        document,
        folder,
        course,
        common,
        foodmenu,
        child,
        childdev,
        template,
        plan,
        map,
        dashboard,
        importStore,
        workschedule,
        targetgroups,
        settings,
        gallery,
        role,
        employee_meals,
        approvals,
        integrations,
        admin,
        organization,
        official,
        idea,
        arno,
        THIS,
        supplier,
        shop,
        banner,
        door_card,
        childCalendar,
        notification,
        dietaryOption,
        survey,
        questionnaire,
        poll
    }
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
    store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
