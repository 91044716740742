<template>
    <div :class="[fgClass, { 'form-group': isLabelSlot }]">
        <label v-if="isLabelSlot" :for="id" :class="labelClass">
            <slot name="label"/>
            <template v-if="optional">
                - <small>{{ $t('Optional') }}</small>
            </template>
        </label>
        <div class="input-group phone-input" :class="inputBlockClass">
            <div class="input-group-prepend">
                <ESelect id="code-select"
                         v-model="val.code"
                         :options="phoneCodes"
                         option-type="country_code"
                         :placeholder="$t('Country code')"
                         class="e3-phone-select"
                         :show-labels="false"
                         close-on-select
                         searchable
                         not-empty
                         is-async
                         hide-dropdown
                         sharpCorners
                         :custom-label="country => `+${country.code}`"
                         flags/>
            </div>
            <input :id="id"
                   ref="inputField"
                   v-model.trim="val.number"
                   class="form-control"
                   :class="{ 'is-invalid': validation && validation.$error }"
                   type="number"
                   :disabled="disabled"
                   :placeholder="placeholder"
                   step="any"
                   lang="en"
                   @focus="onFocus"
                   @blur="onBlur"
                   @keypress.enter="onKeypressEnter"/>
            <div v-if="isAppendSlot" class="input-group-append">
                <slot name="input-append"/>
            </div>
            <ValitationMessages :validation="validation"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'EPhone',
    props: {
        id: [String, Number],
        value: Object,
        disabled: Boolean,
        placeholder: String,
        optional: Boolean,
        inline: Boolean,
        validation: Object,
        formGroupClass: String
    },
    computed: {
        ...mapGetters(['phoneCodes']),
        fgClass() {
            return this.formGroupClass;
        },
        val: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        labelClass() {
            if (this.inline) {
                return 'col-5 col-md-3';
            }

            return '';
        },
        inputBlockClass() {
            if (this.inline) {
                return 'col-md-7';
            }

            return '';
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        isErrorSlot() {
            return !!(this.$scopedSlots['error'] || this.$slots['error']);
        },
        isAppendSlot() {
            return !!this.$slots['input-append'];
        }
    },
    methods: {
        onKeypressEnter(e) {
            this.$emit('keypress-enter', e);
        },
        onBlur(e) {
            this.$emit('blur', e);
        },
        onFocus(e) {
            this.$emit('focus', e);
        },
        clear() {
            if (this.$refs.inputField) {
                this.$refs.inputField.value = '';
            }
        },
        blur() {
            if (this.$refs.inputField) {
                this.$refs.inputField.blur();
            }
        },
        focus() {
            if (this.$refs.inputField) {
                this.$refs.inputField.focus();
            }
        }
    },
    created() {
        this.$store.dispatch('FETCH_COUNTRIES');
    }
};
</script>

<style lang="scss" scoped>
.input-group-prepend {
    max-width: 140px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>
