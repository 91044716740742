<template>
    <div>
        <PageTitle :title="$t('Notifications')"/>
        <div v-if="!initialLoading" :key="language">
            <div v-if="generalNotifications.length">
                <div v-for="notification in generalNotifications" :key="notification.id">
                    <!--Handle all kinds of types and usecases -->
                    <DiaryLikeNotification v-if="notification.type === 'diary_like'" :notification="notification"/>
                    <div v-else-if="notification.type === 'calendar'" class="card p-3 mb-2">
                        <ChildCalendarNotification :notification="notification"/>
                    </div>
                    <div v-else-if="notification.type === 'wishlist'" class="card p-3 mb-2">
                        <KindergartenWishlistNotification :notification="notification"/>
                    </div>
                    <div v-else-if="notification.type === 'application'" class="card p-3 mb-2">
                        <ApplicationNotification :notification="notification"/>
                    </div>
                </div>
                <div v-if="hasMoreNotifications" class="d-flex mt-3">
                    <e-btn-loader :isSubmitLoading="isSubmitLoading" color="primary" class="mx-auto" @clicked="loadMore">
                        {{$t('Load more')}}
                    </e-btn-loader>
                </div>
            </div>
            <empty-list-message v-else wrapperClass="border rounded bg-white p-3 mt-3">
                {{$t('There are no notifications')}}
            </empty-list-message>
        </div>
        <e-loader v-else class="overlay-loader"/>
    </div>

</template>

<script>
import {mapGetters} from 'vuex';
import ApplicationNotification from '@/views/notification/components/ApplicationNotification.vue';
import ChildCalendarNotification from '@views/notification/components/ChildCalendarNotification';
import DiaryLikeNotification from '@/views/notification/components/DiaryLikeNotification.vue';
import KindergartenWishlistNotification from '@/views/notification/components/KindergartenWishlistNotification.vue';

export default {
    name: 'Notifications',
    components: {
        ApplicationNotification,
        ChildCalendarNotification,
        DiaryLikeNotification,
        KindergartenWishlistNotification,
    },
    data() {
        return {
            page: 1,
            perPage: 5,
            initialLoading: true,
            isSubmitLoading: false,
            socketChannel: null,
            echo: null
        };
    },
    computed: {
        ...mapGetters(['generalNotifications', 'hasMoreNotifications', 'user', 'language'])
    },
    methods: {
        loadMore() {
            this.page++;
            this.isSubmitLoading = true;
            this.getNotifications();
        },
        getNotifications() {
            this.$store.dispatch('GET_NOTIFICATIONS', {page: this.page, perPage: this.perPage}).then(() => {
                if(this.page === 1) {
                    this.$store.commit('SET_RECENT_NOTIFICATIONS_COUNT', 0);
                }
            }).finally(() => {
                this.initialLoading = false;
                this.isSubmitLoading = false;
            });
        },
    },
    created() {
        this.getNotifications();
    }
};
</script>

<style lang="scss" scoped>
@import "@common/styles/components/_notifications.scss";
</style>
