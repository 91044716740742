<template>
    <div class="align-items-center header-icons">
        <div v-for="icon in headerIcons" :key="icon.id" class="position-relative">
            <EDropdown
                v-if="icon.visible()"
                :id="icon.id"
                :ref="`${icon.id}_dropdown`"
                :key="icon.id"
                :iconClass="icon.iconClass"
                :gray="icon.gray"
                :dropClass="icon.apps ? `` : `e3-header-icons d-flex justify-content-center align-items-center`"
                :iconSize="icon.apps ? `27` : `20`"
                toggle-class="text-decoration-none"
                class="e3-arrow-drop e3-icons-drop"
                size="sm"
                variant="link"
                :offset="18"
                right
                icon
                noCaret
                @toggle="() => dropToggled(icon.id)">
                <template #content>
                    <component :is="icon.id" :id="icon.id" @close="closeDropdown"/>
                </template>
            </EDropdown>
            <label
                v-if="icon.visible() && icon.newCount() !== 0"
                :for="icon.id"
                class="d-flex align-items-center justify-content-center e3-header-label bg-success rounded-circle text-white position-absolute font-weight-bold"
                :class="{'e3-over-99': icon.newCount() > 99}">
                <span>
                    {{ countNew(icon.newCount()) }}
                </span>
            </label>
        </div>
    </div>
</template>

<script>
import { GALogEvent } from '@/utils/firebase';
import { mapGetters } from 'vuex';
import Messages from './Messages.vue';
import Announcements from './Announcements.vue';
import Calendar from './Calendar.vue';
import Cloud from './Cloud.vue';
// import Apps from './Apps';
import Notifications from './Notifications.vue';
import Cart from './Cart.vue';

export default {
    data() {
        return {
            headerIcons: [
                {
                    id: 'Cart',
                    iconClass: 'mdi-cart-variant',
                    gray: 'text-white',
                    newCount: () => {
                        return this.userRecentCart.count;
                    },
                    visible: () => {
                        return this.userRecentCart.count;
                    }
                },
                {
                    id: 'Notifications',
                    iconClass: 'mdi-bell',
                    gray: 'text-white',
                    newCount: () => {
                        return this.unreadNotificationsCount;
                    },
                    visible: () => {
                        return this.currentEmployee; //TODO official and guardian support
                    }
                },
                {
                    id: 'Announcements',
                    iconClass: 'mdi-bullhorn',
                    gray: 'text-white',
                    newCount: () => {
                        return this.unreadAnnouncements;
                    },
                    visible: () => {
                        return this.canAccessAnnouncement;
                    }
                },
                {
                    id: 'Messages',
                    iconClass: 'mdi-message-text',
                    gray: 'text-white',
                    newCount: () => {
                        return this.unreadMessages;
                    },
                    visible: () => {
                        return this.activeKindergartenId || this.activeOrganizationId;
                    }
                },
                {
                    id: 'Calendar',
                    iconClass: 'mdi-calendar-blank',
                    gray: 'text-white',
                    newCount: () => {
                        return this.newEventsCount;
                    },
                    visible: () => {
                        return this.canAccessEventsIndex || this.canAccessOrgEventsIndex;
                    }
                },
                {
                    id: 'Cloud',
                    iconClass: 'mdi-cloud',
                    gray: 'text-white',
                    newCount: () => {
                        return this.unseenDocumentCount;
                    },
                    visible: () => {
                        return this.canAccessDocumentsIndex;
                    }
                },
                // {
                //     id: 'Apps',
                //     iconClass: 'mdi-apps',
                //     apps: true,
                //     newCount: () => {
                //         return 0;
                //     }
                // }
            ]
        };
    },
    computed: {
        ...mapGetters([
            'unreadMessages',
            'unreadAnnouncements',
            'unseenDocumentCount',
            'messageTypes',
            'activeKindergartenId',
            'newEventsCount',
            'canAccessEventsIndex',
            'canAccessDocumentsIndex',
            'canAccessGalleryIndex',
            'activeOrganizationId',
            'canAccessAnnouncement',
            'canAccessOrgEventsIndex',
            'unreadNotificationsCount',
            'currentEmployee',
            'userRecentCart'
        ])
    },
    methods: {
        closeDropdown(dropdownId) {
            this.$refs[`${dropdownId}_dropdown`][0].hide();
        },
        countNew(count) {
            if (count <= 99 || typeof count === 'string') {
                return count;
            } else {
                return '99+';
            }
        },
        dropToggled(id) {
            GALogEvent('header_notif_open', {
                button_name: id
            });
        },
    },
    components: {
        Cart,
        Notifications,
        Announcements,
        Messages,
        Calendar,
        // Apps,
        Cloud,
    }
};
</script>

<style scoped>
.e3-header-label {
    font-size: 80%;
    top: -8px;
    right: -4px;
    height: 1.3rem;
    width: 1.3rem;
    z-index: 10;
}
.e3-over-99 {
    height: 1.6rem !important;
    width: 1.6rem !important;
    top: -12px !important;
    right: -8px !important;
}
</style>
