import VueRouter from 'vue-router';
import authCheck from '@middleware/auth-check';
// Services
import store from '../store';
import AuthService from '@services/auth.service';
import {GALogEvent} from '@/utils/firebase';

// Auth Views
const Wrapper = () => import(/* webpackChunkName: "common-module" */ '@views/Wrapper');
const Dashboard = () => import(/* webpackChunkName: "common-module" */ '@views/dashboard/Dashboard');

const Privacy = () => import(/* webpackChunkName: "privacy-module" */ '@views/auth/privacy/Privacy');
const Error403 = () => import(/* webpackChunkName: "privacy-module" */ '@views/errors/Error403');

const Courses = () => import(/* webpackChunkName: "courses-module" */ '@views/course/Courses');

const UserApplications = () => import(/* webpackChunkName: "applications-module" */ '@views/application/user/Applications');

//Dev views
const DevDashboard = () => import(/* webpackChunkName: "dev-module" */ '@views/dev_dashboard/DevDashboard');
const HelpVideos = () => import(/* webpackChunkName: "help-module" */ '@views/help/HelpVideos');
const HelpSeminars = () => import(/* webpackChunkName: "help-module" */ '@views/help/HelpSeminars');
const HelpUpdates = () => import(/* webpackChunkName: "help-module" */ '@views/help/HelpUpdates');
// const ShopTeaser = () => import(/*shop-module */ '@views/shop/ShopTeaser');
// imported routes
import adminRoutes from '@views/admin/routes';
import childProfileRoutes from '@views/child/profile/routes';
import kindergartenRoutes from '@views/kindergarten/routes';
import messageRoutes from '@views/message/routes';
import announcementRoutes from '@views/announcement/routes';
import kindergartenApplicationRoutes from '@views/application/kindergarten/routes';
import userProfileRoutes from '@views/user/profile/routes';
import foodMenuRoutes from '@views/menu/routes';
import weekPlanRoutes from '@views/plan/week/routes';
import monthPlanRoutes from '@views/plan/month/routes';
import yearPlanRoutes from '@views/plan/year/routes';
import projectPlanRoutes from '@views/plan/project/routes';
import scheduleRoutes from '@views/schedule/routes';
import diaryRoutes from '@views/diary/routes';
import reportRoutes from '@views/report/routes';
import eventRoutes from '@views/event/routes';
import contactRoutes from '@views/contact/routes';
import childDevelopmentRoutes from '@views/child_development/routes';
import galleryRoutes from '@views/gallery/routes';
import documentRoutes from '@views/document/routes';
import ideaRoutes from '@views/idea/routes';
import supplierRoutes from '@views/supplier/routes';
import shopRoutes from '@views/shop/routes';
import surveyRoutes from '@views/survey/routes';

// Unauth views
const AuthWrapper = () => import(/* webpackChunkName: "auth-module" */ '@views/AuthWrapper');
const Login = () => import(/* webpackChunkName: "auth-module" */ '@views/auth/landing/Landing');
const HaridCallback = () => import(/* webpackChunkName: "auth-module" */ '@views/auth/HaridCallback');
const AppleCallback = () => import(/* webpackChunkName: "auth-module" */ '@views/auth/AppleCallback');
const GoogleCallback = () => import(/* webpackChunkName: "auth-module" */ '@views/auth/GoogleCallback');
const FacebookCallback = () => import(/* webpackChunkName: "auth-module" */ '@views/auth/FacebookCallback');
const VerificationLanding = () => import(/* webpackChunkName: "auth-module" */ '@views/auth/verification/Verification');
const SSO_Auth = () => import(/* webpackChunkName: "auth-module" */ '@views/auth/sso/Auth');

const PurchaseTerms = () => import(/*webpackChunkName: "shop-module" */ '@views/shop/components/PurchaseTerms');
const ShopCampaign = () => import(/*webpackChunkName: "shop-module" */ '@views/shop/Campaign.vue');
import registerRoutes from '@views/auth/register/routes';
import Notifications from '@views/notification/Notifications';

// Define the routes
let routes = [
    {
        path: '/auth',
        redirect: '/login',
        component: AuthWrapper,
        children: [
            ...registerRoutes,
            {
                path: 'login',
                name: 'login',
                components: { unauthorized: Login }
            },
            {
                path: 'callback/google/login',
                name: 'google_callback_login',
                meta: { disableScrollBehavior: true },
                components: { unauthorized: GoogleCallback }
            },
            {
                path: 'callback/google/register',
                name: 'google_callback_register',
                meta: { disableScrollBehavior: true },
                components: { unauthorized: GoogleCallback }
            },
            {
                path: 'callback/facebook/login',
                name: 'facebook_callback_login',
                meta: { disableScrollBehavior: true },
                components: { unauthorized: FacebookCallback }
            },
            {
                path: 'callback/facebook/register',
                name: 'facebook_callback_register',
                meta: { disableScrollBehavior: true },
                components: { unauthorized: FacebookCallback }
            },
            {
                path: 'callback/harid/login',
                name: 'harid_callback_login',
                meta: { disableScrollBehavior: true },
                components: { unauthorized: HaridCallback }
            },
            {
                path: 'callback/harid/register',
                name: 'harid_callback_register',
                meta: { disableScrollBehavior: true },
                components: { unauthorized: HaridCallback }
            },
            {
                path: 'callback/apple/login',
                name: 'apple_callback_login',
                meta: { disableScrollBehavior: true },
                components: { unauthorized: AppleCallback }
            },
            {
                path: 'callback/apple/register',
                name: 'apple_callback_register',
                meta: { disableScrollBehavior: true },
                components: { unauthorized: AppleCallback }
            },
            {
                path: 'password/reset',
                name: 'password_reset',
                components: { unauthorized: VerificationLanding }
            },
            {
                path: 'verification/verify/:user',
                name: 'verify_email',
                components: { unauthorized: VerificationLanding }
            },
            {
                path: 'verification/email-change/:user',
                name: 'change_email',
                components: { unauthorized: VerificationLanding }
            },
            {
                path: 'verification/register',
                name: 'employee_user_register',
                components: { unauthorized: VerificationLanding }
            }
        ]
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy,
    },
    {
        path: '/purchase-terms',
        name: 'purchase-terms',
        component: PurchaseTerms,
    },
    {
        path: '/user-name',
        name: 'user_name',
        component: VerificationLanding,
    },
    {
        path: '/sso/auth',
        name: 'sso_auth',
        component: SSO_Auth,
    },
    {
        path: '/campaign',
        name: 'shop.campaign',
        component: ShopCampaign
    },
    {
        path: '/',
        redirect: '/dashboard',
        component: Wrapper,
        meta: {
            middleware: [authCheck]
        },
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                components: { base: Dashboard }
            },
            {
                path: '/help',
                name: 'help.videos',
                components: {base: HelpVideos}
            },
            {
                path: '/help-seminars',
                name: 'help.seminars',
                components: {base: HelpSeminars},
                beforeEnter: (to, from, next) => {
                    if (store.getters.currentEmployee) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: '/help-updates',
                name: 'help.updates',
                components: {base: HelpUpdates},
                beforeEnter: (to, from, next) => {
                    if (store.getters.currentEmployee) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: '/dev_dashboard',
                name: 'dev_dashboard',
                components: { base: DevDashboard },
                beforeEnter: (to, from, next) => {
                    // eslint-disable-next-line no-undef
                    if (process.env.NODE_ENV) {
                        next();
                    } else {
                        next({path: '/dashboard'});
                    }
                }
            },
            ...childProfileRoutes,
            ...kindergartenRoutes,
            ...adminRoutes,

            {
                path: '/courses',
                name: 'courses',
                components: { base: Courses }
            },

            ...diaryRoutes,
            ...reportRoutes,

            {
                path: '/applications',
                name: 'applications',
                components: { base: UserApplications }
            },

            ...kindergartenApplicationRoutes,
            ...scheduleRoutes,
            {
                path: '/kindergarten/settings',
                name: 'schedule.settings'
            },
            //Plans
            ...weekPlanRoutes,
            ...monthPlanRoutes,
            ...yearPlanRoutes,
            ...projectPlanRoutes,
            {
                path: '/statistics/plans',
                name: 'plans.statistics'
            },
            //Settings
            ...userProfileRoutes,
            //Help pages
            // {
            //     path: '/help',
            //     name: 'help',
            //     components: { base: Help }
            // },
            ...foodMenuRoutes,
            //Statistics
            // {
            //     path: '/statistics/organization',
            //     name: 'statistics.organization'
            // },
            // {
            //     path: '/statistics/kindergarten',
            //     name: 'statistics.kindergarten'
            // },
            // {
            //     path: '/statistics/courses',
            //     name: 'statistics.courses'
            // },
            // {
            //     path: '/statistics/activity',
            //     name: 'statistics.activity'
            // },
            // {
            //     path: '/statistics/attendance',
            //     name: 'statistics.attendance'
            // },
            // {
            //     path: '/statistics/menu',
            //     name: 'statistics.foodmenu'
            // },
            // {
            //     path: '/statistics/plans',
            //     name: 'statistics.plans'
            // },
            // {
            //     path: '/statistics/schedule',
            //     name: 'statistics.workschedule'
            // },
            //Polls
            // {
            //     path: '/polls/active',
            //     name: 'polls.active'
            // },
            //Events
            ...eventRoutes,
            ...contactRoutes,
            ...childDevelopmentRoutes,
            {
                path: '/organization/kindergartens',
                name: 'organization.kindergartens'
            },
            {
                path: '/organization/courses',
                name: 'organization.courses'
            },
            ...announcementRoutes,
            ...messageRoutes,
            ...documentRoutes,
            ...galleryRoutes,
            ...ideaRoutes,
            ...supplierRoutes,
            ...shopRoutes,
            ...surveyRoutes,
            {
                path: '/notifications',
                name: 'general.notifications',
                components: {base: Notifications},
                beforeEnter: (to, from, next) => {
                    if (store.getters.activeKindergartenId || store.getters.activeOrganizationId) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: '/access-restricted',
                name: 'access-restricted',
                components: {base:  Error403},
            },
        ]
    },
    { path: '*', redirect: '/' }
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior,
    linkActiveClass: 'active'
});


router.beforeEach(async (to, from, next) => {
    store.dispatch('STOP_ALL_CONTENT_LOADERS');
    store.dispatch('CLEAR_CHANNEL_USERS');
    if (AuthService.isAuthorized && to.path !== `/auth/verification/verify/${to.params.user}` && to.path !== '/campaign') {
        if (!store.getters.user) {
            await store.dispatch('GET_USER');
            await store.dispatch('GET_PERMISSIONS');
            store.dispatch('GET_USER_HEADER_INFO');
        }

        if (!store.getters.user?.privacy_confirmed && to.path !== '/privacy') {
            next({ path: '/privacy' });
        } else if (store.getters.user?.privacy_confirmed && (store.getters.user?.fname === '' || store.getters.user?.lname === '') && to.path !== '/user-name') {
            next({ path: '/user-name' });
        } else if (to.path === '/auth/login') {
            next({path: '/dashboard'});
        } else if (to.path === '/sso/auth') {
            next();
        } else {
            store.dispatch('GET_USER_BANNERS');
            next();
        }
    } else {
        const accessConditions = [
            to.path === '/privacy',
            to.path === '/user-name',
            to.path === '/auth/login',
            to.path === '/auth/register',
            to.path === '/auth/register/country',
            to.path === '/auth/register/options',
            to.path === '/auth/register/agree',
            to.path === '/auth/callback/harid/login',
            to.path === '/auth/callback/harid/register',
            to.path === '/auth/callback/apple/login',
            to.path === '/auth/callback/apple/register',
            to.path === '/auth/callback/google/login',
            to.path === '/auth/callback/google/register',
            to.path === '/auth/callback/facebook/login',
            to.path === '/auth/callback/facebook/register',
            to.path === '/auth/password/reset',
            to.path === `/auth/verification/verify/${to.params.user}`,
            to.path === `/auth/verification/email-change/${to.params.user}`,
            to.path === '/auth/verification/register',
            to.path === '/sso/auth',
            to.path === '/campaign'
        ];

        if (accessConditions.some(item => item)) {
            next();
        } else {
            if (from.path !== '/auth/login') {
                next({
                    path: '/auth/login'
                });
            }
        }
    }
});

router.afterEach((to) => {
    GALogEvent('screen_view', {
        screen_name: to.name
    });
});

/**
 * https://router.vuejs.org/en/advanced/scroll-behavior.html
 */
function scrollBehavior(to, from, savedPosition) {
    if (to.meta.disableScrollBehavior) {
        return null;
    }

    if (from.path === '/privacy' && to.path  === '/auth/login') {
        return { x: 0, y: 0 };
    }

    if (savedPosition) {
        return savedPosition;
    } else {
        if (to.hash) {
            return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });
        }
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

export default router;
