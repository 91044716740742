import http from '@http';

class KindergartenService {
    constructor() {
        if (KindergartenService.instance) {
            return KindergartenService.instance;
        }

        KindergartenService.instance = this;
    }

    getKindergartenContacts(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/contacts`).then(res => res.data);
    }

    searchByName(term) {
        return http.get('/api/common/kindergartens/search', { params: { term } }).then(res => res.data);
    }

    getKindergartenSettings(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/settings`).then(res => res.data);
    }

    updateKindergartenSetting(kindergartenId, settingId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/setting/${settingId}/update`, data).then(res => res.data);
    }

    updateKindergartenSettings(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/settings`, data).then(res => res.data);
    }

    updateKindergartenChildDevSetting(kindergartenId, settingId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/child-dev/setting/${settingId}/update`, data).then(res => res.data);
    }

    updateKindergartenContacts(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/contacts`, data).then(res => res.data);
    }

    getKindergartenEmployees(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/employees`, { params: queryObj }).then(res => res.data);
    }

    getKindergartenEmployeesList(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/employees-list`).then(res => res.data);
    }

    getKindergartenArchivedEmployees(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/employees/archived`, { params: queryObj }).then(res => res.data);
    }

    getKindergartenChildren(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/children`, { params: queryObj }).then(res => res.data);
    }

    getKindergartenChildrenList(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children-list`).then(res => res.data);
    }

    getKindergartenGuardians(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/parents`, { params: queryObj }).then(res => res.data);
    }

    getKindergartenGuardiansList(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/parents-list`).then(res => res.data);
    }

    getTypePermissions(kindergartenId, type) {
        return http.get(`/api/kindergartens/${kindergartenId}/permissions/${type}`).then(res => res.data);
    }

    getPermissions(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/permissions`).then(res => res.data);
    }

    getKindergartenRoles(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/roles`, { params: queryObj }).then(res => res.data);
    }

    addKindergartenRole(kindergartenId, form) {
        return http.post(`/api/kindergartens/${kindergartenId}/roles`, form).then(res => res.data);
    }

    updateKindergartenRole(kindergartenId, form) {
        return http.put(`/api/kindergartens/${kindergartenId}/roles/${form.id}`, form).then(res => res.data);
    }

    togglePermission(kindergartenId, roleId, permissionId) {
        return http.put(`/api/kindergartens/${kindergartenId}/roles/${roleId}/permission/${permissionId}`).then(res => res.data);
    }

    getActiveKindergartenCourses(kindergartenId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses`, { params: options }).then(res => res.data);
    }

    getArchivedKindergartenCourses(kindergartenId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses/archived`, { params: options }).then(res => res.data);
    }

    getKindergartenSpecialCourses(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses/special`).then(res => res.data);
    }

    getKindergartenTargetGroups(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/groups`, {params: queryObj}).then(res => res.data);
    }

    getRecentFiles(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/recent/files`).then(res => res.data);
    }

    getArchivedPeriods(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses/archived`, {params: queryObj}).then(res => res.data);
    }

    getSummerPeriods(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses/summer`).then(res => res.data);
    }

    getDiaryStatuses(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/diary-statuses`).then(res => res.data);
    }

    insertDiaryStatus(kindergartenId, status) {
        return http.post(`/api/kindergartens/${kindergartenId}/diary-statuses`, status).then(res => res.data);
    }

    updateDiaryStatus(kindergartenId, status) {
        return http.put(`/api/kindergartens/${kindergartenId}/diary-statuses/${status.id}`, status).then(res => res.data);
    }

    getKindergartenAllActiveCourses(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/all-active-courses`)
            .then(res => res.data);
    }

    getKindergartenActiveCourses(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/active-courses`)
            .then(res => res.data);
    }

    getEmployeeMeals(kindergartenId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/employee-meals`, { params: options })
            .then(res => res.data);
    }

    getEmployeeMealsSettings(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/employee-meals/settings`)
            .then(res => res.data);
    }

    updateEmployeeMealsSettings(kindergartenId, form) {
        return http.put(`/api/kindergartens/${kindergartenId}/employee-meals/settings`, form)
            .then(res => res.data);
    }

    saveEmployeeMeal(kindergartenId, form)
    {
        return http.post(`/api/kindergartens/${kindergartenId}/employee-meals`, form);
    }

    getEnabledLanguages(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/languages`).then(res => res.data);
    }

    pdfSettings(kindergartenId, query) {
        return http.get(`/api/kindergartens/${kindergartenId}/pdf-settings`, {params: query}).then(res => res.data);
    }

    updatePdfSettings(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/pdf-settings`, data).then(res => res.data);
    }

    pdfSettingValues(kindergartenId, query) {
        return http.get(`/api/kindergartens/${kindergartenId}/pdf-settings/values`, {params: query}).then(res => res.data);
    }
}

export default new KindergartenService();
