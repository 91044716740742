<template>
    <div style="display: contents">
        <tr :id="`collaps_${entity.id}_${indexEntity}`" class="e3-pointer" tabindex="0" @click="$emit('openCloseRow', entity.id)" @keyup.enter="$emit('openCloseRow', entity.id)">
            <td class="align-middle">
                <div class="plus-icon">
                    <SvgIcon :icon="(opened.includes(entity.id) ? 'mdi-minus' : 'mdi-plus')" size="15" class="text-white"/>
                </div>
            </td>
            <td v-if="isNumberable">
                {{ entities.current_page && entities.from ? (entities.from + parseInt(indexEntity)) : parseInt(indexEntity) + 1 }}
            </td>
            <td v-for="field in fields" :key="field.key" :class="field.tdClass">
                <slot v-if="!!slots[field.key]" :name="field.key" :item="entity" :row-index="indexEntity" :table-index="tableIndex"/>
                <span v-else>{{ filterIfNeeded(entityField(entity, field.key), field.filter) }}</span>
            </td>
        </tr>
        <tr v-if="opened.includes(entity.id)" :key="entity.id" :class="{ opened: opened.includes(entity.id) }">
            <td :colspan="colspan">
                <slot name="collapsInfo" :item="entity"/>
            </td>
        </tr>
    </div>
</template>

<script>
import filters from '@/filters';

export default {
    name: 'ETableCollapsibleRow',
    props: {
        entities: [Object, Array],
        entity: Object,
        indexEntity: [Number, String],
        tableIndex: [Number, String],
        opened: Array,
        fields: Array,
        slots: Object,
        isNumberable: Boolean
    },
    computed: {
        colspan() {
            let colspan = 1;
            if(this.isNumberable) {
                colspan++;
            }
            return this.fields.length + colspan;
        }
    },
    methods: {
        filterIfNeeded(entity, filter) {
            if(filter) {
                return filters[filter](entity);
            }
            return entity;
        },
        entityField(entity, key) {
            if (key.includes('.')) {
                const keys = key.split('.');
                if (keys.length > 1) {
                    let obj = entity;
                    for (let i = 0; i < keys.length; i++) {
                        obj = obj[keys[i]];
                    }
                    return obj;
                }
            }
            return entity[key];
        }
    }
};
</script>
<style scoped lang="scss">
    .opened:hover {
        background-color: transparent;
    }

    .plus-icon {
        background-color: #f79e80;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 21px;
        width: 21px;
        border-radius: 0.33rem;

        i {
            &:before {
                font-size: 15px;
            }
        }
    }
</style>
