export const RESET_DOOR_CARD_STATE = 'RESET_DOOR_CARD_STATE';
export const GET_DOOR_CARD_CHILDREN = 'GET_DOOR_CARD_CHILDREN';
export const SET_DOOR_CARD_CHILDREN = 'SET_DOOR_CARD_CHILDREN';
export const GET_DOOR_CARD_DOORS = 'GET_DOOR_CARD_DOORS';
export const SET_DOOR_CARD_DOORS = 'SET_DOOR_CARD_DOORS';
export const SET_DOOR_CARD_DOOR = 'SET_DOOR_CARD_DOOR';
export const UPDATE_DOOR_CARD_DOOR = 'UPDATE_DOOR_CARD_DOOR';
export const STORE_DOOR_CARD = 'STORE_DOOR_CARD';
export const UPDATE_DOOR_CARD = 'UPDATE_DOOR_CARD';
export const DELETE_DOOR_CARD = 'DELETE_DOOR_CARD';
export const ADD_DOOR_CARD = 'ADD_DOOR_CARD';
export const SET_DOOR_CARD = 'SET_DOOR_CARD';
export const REMOVE_DOOR_CARD = 'REMOVE_DOOR_CARD';
export const SET_DOOR_CARD_MESSAGE = 'SET_DOOR_CARD_MESSAGE';