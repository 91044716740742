import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueVideoPlayer from 'vue-video-player';
import VueMeta from 'vue-meta';
import VueCookies from 'vue-cookies';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import Toastr from 'vue-toastr';
import VueMoment from 'vue-moment';
import Timezone from 'moment-timezone';

import Draggable from 'vuedraggable';
import {hasCookieAccepted} from '@/utils/cookieConsent';
import {initializeSentry} from '@/utils/sentry';

import router from './routes/routes';
import store from './store';
import filters from './filters';
import i18n from './i18n';
import './common/styles/app.scss';

import App from './App';
import EliisDropdown from '@components/Dropdown';
import EliisTooltip from '@components/ToolTip';
import EliisTablefilter from '@components/table/Filter';
import EliisTable from '@components/table/Table';
import EliisEditor from '@components/quill/QuillBasic';
import EliisLoader from '@components/loaders/ContentLoader';
import AddNewButton from '@components/buttons/AddNewButton';
import ConfirmButton from '@components/buttons/ConfirmButton';
import EmptyListMessage from '@components/notifs/EmptyListMessage';
import PageTitle from '@components/PageTitle';
import ProfileImage from '@components/ProfileImage';
import EModal from '@components/modals/BaseModal';
import ModalHeader from '@components/ModalHeader';
import HeaderIconPack from '@common/layout/headericons/HeaderIconPack';
import ListRowItem from '@components/ListRowItem';
import ButtonLoader from '@components/ButtonLoader';
import RoundIconButton from '@components/buttons/RoundIconButton.vue';
import SvgIcon from '@components/SvgIcon.vue';
import DatePicker from '@components/datepicker/DatePicker.vue';

const moment = require('moment');
Vue.use(VueMoment, {moment, Timezone});
Vue.use(BootstrapVue);
Vue.use(VueVideoPlayer);
Vue.use(VueCookies);
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(Toastr, {
    defaultTimeout: 8000,
    defaultPosition: 'toast-top-center',
    defaultCloseOnHover: false,
    defaultPreventDuplicates: true,
    defaultProgressBar: false
});
Vue.use(VueMeta);

let locale = Vue.prototype.$cookies.get('lang');
if (!locale) {
    locale = localStorage.getItem('lang') || 'en';
}

i18n.locale = locale;

const requireComponent = require.context('./common/components/input', false, /.*\.(vue)$/);
requireComponent.keys().forEach((fileName) => {
    const Component = requireComponent(fileName).default;
    if (Component.name) {
        Vue.component(Component.name, Component);
    }
});

Vue.component('HeaderIconPack', HeaderIconPack);
Vue.component('EmptyListMessage', EmptyListMessage);
Vue.component('AddNewButton', AddNewButton);
Vue.component('ConfirmButton', ConfirmButton);
Vue.component('ELoader', EliisLoader);
Vue.component('EDropdown', EliisDropdown);
Vue.component('ETooltip', EliisTooltip);
Vue.component('ETable', EliisTable);
Vue.component('ETableFilter', EliisTablefilter);
Vue.component('EEditor', EliisEditor);
Vue.component('PageTitle', PageTitle);
Vue.component('ProfileImage', ProfileImage);
Vue.component('EModal', EModal);
Vue.component('ModalHeader', ModalHeader);
Vue.component('EListItem', ListRowItem);
Vue.component('EBtnLoader', ButtonLoader);
Vue.component('RoundIconButton', RoundIconButton);
Vue.component('SvgIcon', SvgIcon);
Vue.component('EDatepicker', DatePicker);
Vue.component('EDraggable', Draggable);

// eslint-disable-next-line no-undef
Vue.component('pagination', require('laravel-vue-pagination'));

Vue.directive('focus', {
    inserted: (el, binding, vNode) => {
        if (vNode && vNode.componentInstance && !vNode.componentInstance.val) {
            el.getElementsByTagName('input')[0].focus();
        }
    }
});

Vue.directive('click-outside', {
    bind(el, binding) {
        const clickEventHandler = event => {
            if (binding.value && binding.value.item == 'burgerMenu') {
                let exists = false;

                event.composedPath().forEach((path) => {
                    if (path.id == 'sidebar' ||
                        path.id == 'burger-menu' ||
                        path.id == 'banner-popover') {
                        exists = true;
                    }
                });

                if (
                    store.getters.mobileMenuVisible &&
                    !el.contains(event.target) &&
                    el !== event.target && !exists
                ) {
                    binding.value.fn(event);
                }
            } else {
                const exceptionElemId = binding.arg;

                if (!el.contains(event.target) && el !== event.target && !(exceptionElemId && event.target.closest('#' + exceptionElemId))) {
                    binding.value(event);
                }
            }
        };

        el.__vueClickEventHandler__ = clickEventHandler;

        document.addEventListener('click', clickEventHandler);
    },

    unbind(el) {
        document.removeEventListener('click', el.__vueClickEventHandler__);
    },
});

if (hasCookieAccepted('statistics')) {
    initializeSentry(Vue);
}

export const vm = new Vue({
    el: '#app',
    i18n,
    router,
    filters,
    store,
    render: h => h(App)
});
export const eventBus = new Vue();
