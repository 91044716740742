import {
    GET_GALLERY_FOLDERS,
    DELETE_GALLERY_FILE,
    UPDATE_GALLERY_FILE,
} from './actions';


import GalleryService from '@services/gallery.service';

const state = {
    folders: [],
    folder: {},
    shared_folders: [],
    images: [],
    target_groups: null,
    permissions: {
        manage: 'manage',
        view: 'view',
        edit: 'edit'
    }
};


const mutations = {
};


const actions = {
    [GET_GALLERY_FOLDERS]({commit, getters}, queryObj) {
        if(queryObj.page === 1) {
            commit('RESET_SUB_FOLDERS');
            commit('SET_FOLDER_MAX_PAGE', 0);
            commit('SET_FOLDER_TOTAL', 0);
        }
        return GalleryService.getIndexData(getters.activeKindergartenId, queryObj).then(res => {
            commit('ADD_TO_SUB_FOLDERS', res.folders.data);
            commit('SET_FOLDER_SETTINGS', res.settings);
            commit('SET_FOLDER_MAX_PAGE', res.folders.last_page);
            commit('SET_FOLDER_TOTAL', res.folders.total);
        });
    },
    [DELETE_GALLERY_FILE]({commit, getters}, {fileName}) {
        return GalleryService.deleteGalleryFile(getters.activeKindergartenId, fileName).then(() => {
            commit('REMOVE_FOLDER_FILE', fileName);
        });
    },
    [UPDATE_GALLERY_FILE]({commit, getters}, form) {
      return GalleryService.updateGalleryFile(getters.activeKindergartenId, form.filename, form).then(res => {
          commit('UPDATE_FOLDER_FILE', res);
      });
    },
};


const getters = {
};


export default {
    state,
    mutations,
    actions,
    getters
};
