import Echo from 'laravel-echo';
// eslint-disable-next-line no-undef
window.Pusher = require('pusher-js');
import Vue from 'vue';
import axios from 'axios';
import RecentService from '@services/recent.service';

window.Pusher.Runtime.createXHR = function () {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    return xhr;
};

const state = {
    echo: null,
    socketId: null,
    echoStatus: '',
    private_channels: [
        {
            channel: '',
            event: 'NewMessage'
        },
        {
            channel: '',
            event: 'NewAnnouncement'
        },
        {
            channel: '',
            event: 'NewDocument'
        },
        {
            channel: '',
            event: 'NewEvent'
        },
        {
            channel: '',
            event: 'NewNotification'
        }
    ],
    channelUsers: []
};

const mutations = {
    ['SET_LARAVEL_ECHO'](state, pusherData) {
        if (!state.echo) {
            state.echo = new Echo(pusherData);
        }
    },
    ['SET_LARAVEL_ECHO_STATUS'](state, status) {
        state.echoStatus = status;
    },
    ['SET_PRIVATE_CHANNEL'](state, channel) {
        let index = state.private_channels.findIndex(
            ch => ch.event === channel.event
        );
        Vue.set(state.private_channels, index, channel);
    },
    ['SET_SOCKET_ID'](state, socketId) {
        if (!state.socketId && socketId) {
            state.socketId = socketId;
            axios.defaults.headers['X-Socket-ID'] = socketId;
        }
    },
    ['ADD_CHANNEL_USER'](state, user) {
        if(!state.channelUsers.find(u => u.id === user.id)) {
            state.channelUsers.push(user);
        }
    },
    ['ADD_CHANNEL_USERS'](state, users) {
        users.forEach(user => {
            if(!state.channelUsers.find(u => u.id === user.id)) {
                state.channelUsers.push(user);
            }
        });
    },
    ['REMOVE_CHANNEL_USER'](state, user) {
        state.channelUsers = state.channelUsers.filter(u => u.id !== user.id);
    },
    ['CLEAR_CHANNEL_USERS'](state) {
        state.channelUsers = [];
    },
};

const actions = {
    ['SET_LARAVEL_ECHO']({ commit, state }) {
        let pusherData = {
            broadcaster: 'pusher',
            // eslint-disable-next-line no-undef
            key: process.env.VUE_APP_PUSHER_APP_KEY,
            // eslint-disable-next-line no-undef
            wsHost: process.env.VUE_APP_PUSHER_APP_HOSTNAME,
            // During local development, default to port defined in .env. Otherwise, use SSL port.
            // eslint-disable-next-line no-undef
            wsPort: process.env.VUE_APP_PUSHER_APP_PORT,
            disableStats: true,
            forceTLS: false,
            encrypted: true,
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    Accept: 'application/json',
                },
            },
        };

        if (process.env.VUE_APP_API_URL) {
            pusherData.authEndpoint = process.env.VUE_APP_API_URL + '/broadcasting/auth';
        }

        commit('SET_LARAVEL_ECHO', pusherData);

        state.echo.connector.pusher.connection.bind('connecting', () => {
            commit('SET_LARAVEL_ECHO_STATUS', 'connecting');
        });
        state.echo.connector.pusher.connection.bind('connected', () => {
            commit('SET_LARAVEL_ECHO_STATUS', 'connected');
        });
        state.echo.connector.pusher.connection.bind('disconnected', () => {
            commit('SET_LARAVEL_ECHO_STATUS', 'disconnected');
        });
        state.echo.connector.pusher.connection.bind('failed', () => {
            commit('SET_LARAVEL_ECHO_STATUS', 'failed');
        });
        state.echo.connector.pusher.connection.bind('unavailable', () => {
            commit('SET_LARAVEL_ECHO_STATUS', 'unavailable');
        });
    },

    ['PRIVATE_CHANNEL']({ commit, dispatch, getters }, channel) {
        commit('SET_PRIVATE_CHANNEL', channel);
        dispatch('PRIVATE_CHANNEL_LISTENER', channel);
        commit('SET_SOCKET_ID', getters.echo.socketId());
    },

    ['WHISPER']({ getters }, whisper) {
        getters.echo.join('chat').whisper('client-Typing', whisper);
    },

    ['WHISPER_LISTENER']({ getters }) {
        getters.echo.join('chat').listenForWhisper('client-Typing', () => {});
    },

    ['LEAVE_CHANNEL']({ getters }, channel) {
        getters.echo.leaveChannel(channel);
    },

    ['LEAVE_PRIVATE_CHANNELS']({ getters }) {
        getters.privateChannels.forEach(c => {
            if (c.channel !== '') {
                getters.echo.leave(c.channel);
            }
        });
    },

    ['CLEAR_CHANNEL_USERS']({ commit }) {
        commit('CLEAR_CHANNEL_USERS');
    },

    ['ADD_CHANNEL_USERS']({ commit }, users) {
        commit('ADD_CHANNEL_USERS', users);
    },

    ['ADD_CHANNEL_USER']({ commit }, user) {
        commit('ADD_CHANNEL_USER', user);
    },

    ['REMOVE_CHANNEL_USER']({ commit }, user) {
        commit('REMOVE_CHANNEL_USER', user);
    },
    ['PRIVATE_CHANNEL_LISTENER']({ getters, dispatch, commit }, listener) {
        getters.echo.private(listener.channel).listen(listener.event, res => {
            if (listener.event === 'NewMessage') {
                dispatch('GET_RECENT_MESSAGES');
            }
            if(listener.event === 'NewAnnouncement') {
                dispatch('GET_RECENT_KINDERGARTEN_ANNOUNCEMENTS');
                // commit('SET_UNREAD_ANNOUNCEMENT_COUNT', getters.unreadAnnouncements+1);
            }
            if(listener.event === 'NewDocument') {
                // dispatch('GET_RECENT_DOCUMENTS', getters.activeKindergartenId);
                RecentService.getKindergartenActivity(getters.activeKindergartenId).then(res => {
                    commit('SET_RECENT_DOCUMENT_COUNT', res.documents_count);
                    commit('SET_RECENT_EVENTS_COUNT', res.events_count);
                    //TODO other modules
                });
            }
            if(listener.event === 'NewEvent') {
                if(res.data.kindergarten_id === getters.activeKindergartenId && getters.canAccessEventsIndex) {
                    // dispatch('GET_RECENT_EVENTS');
                    RecentService.getKindergartenActivity(getters.activeKindergartenId).then(res => {
                        commit('SET_RECENT_DOCUMENT_COUNT', res.documents_count);
                        commit('SET_RECENT_EVENTS_COUNT', res.events_count);
                        //TODO other modules
                    });
                }
                if(res.data.org_id === getters.activeOrganizationId && getters.canAccessOrgEventsIndex) {
                    // dispatch('GET_RECENT_ORG_EVENTS');
                    RecentService.getOrganizationActivity(getters.activeOrganizationId).then(res => {
                        commit('SET_RECENT_EVENTS_COUNT', res.events_count);
                        //TODO other modules
                    });
                }
            }

            if(listener.event === 'NewNotification') {
                commit('SET_RECENT_NOTIFICATIONS_COUNT', getters.unreadNotificationsCount + 1);
            }
        });
    }
};

const getters = {
    socketId: state => state.socketId,
    echo: state =>  state.echo,
    echoStatus: state =>  state.echoStatus,
    channelUsers: state => state.channelUsers,
    privateChannels: state => state.private_channels
};

export default {
    state,
    mutations,
    actions,
    getters
};
