<template>
    <div>
        <textarea
            ref="textarea"
            v-model="val"
            oninput="this.style.height = &quot;&quot;;this.style.height = this.scrollHeight + &quot;px&quot;"
            onfocus="this.style.height = &quot;&quot;;this.style.height = this.scrollHeight + &quot;px&quot;"
            class="form-control e3-textarea"
            :class="{ 'is-invalid': validation && validation.$error}"
            :disabled="disabled"
            :placeholder="placeholder"
            :maxlength="maxlength"
            @keyup="onKeyup"
            @change="onChange"
            @blur="onBlur"/>
        <div v-if="charCounter" class="text-right e3-char-counter" :class="{'e3-char-limit': usedChars === maxlength }"> {{ usedChars }}/{{ maxlength }} </div>
        <ValitationMessages :validation="validation"/>
    </div>
</template>

<script>
export default {
    name: 'AutoGrowTextarea',
    props: {
        value: [String, Number],
        disabled: Boolean,
        placeholder: String,
        maxlength: {
            type: Number,
            default: null
        },
        charCounter: Boolean,
        validation: Object
    },
    data() {
        return {
            usedChars: null
        };
    },
    computed: {
        val: {
            get() {
                if(this.charCounter) {
                    this.updateUsedChars();
                }
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        updateUsedChars() {
            this.usedChars = this.value ? this.value.length : 0;
        },
        onKeyup(e) {
            this.$emit('keyup', e);
            this.validation && this.validation.$touch();
        },
        onBlur(e) {
            this.$emit('blur', e);
            this.validation && this.validation.$touch();
        },
        onChange(e) {
            this.$emit('change', e);
            this.validation && this.validation.$touch();
        },
    },
    mounted() {
        this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight+'px';
    },
    created() {
        this.usedChars = this.value ? this.value.length : 0;
    }
};
</script>

<style lang="scss" scoped>
.e3-textarea {
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    min-height: 57px;
}
</style>
