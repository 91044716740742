<template>
    <b-modal id="file-edit-modal" lazy static hide-footer @hidden="resetModal">
        <template slot="modal-header">
            <modal-header modalName="file-edit-modal">
                <template>
                    {{ $t('File') }}: <span v-if="modalTitleFileName">{{file.name}}</span>
                </template>
            </modal-header>
        </template>
        <div class="file-edit-modal-container">
            <template v-if="form && !loading">
                <div>
                    <e-input id="name-input" v-model="form.name" class="mb-3" :validation="$v.form.name">
                        <template #label>
                            {{ $t('Name') }}
                        </template>
                    </e-input>
                </div>
                <div>
                    <label for="view-select" class="d-flex align-items-center">
                        <span>
                            {{ $t('Add target groups who can view the file') }}
                            - <small>{{ $t('Optional') }}</small>
                        </span>
                        <e-tooltip id="targetViewTip" class="ml-2" :inline="false">
                            <div class="text-left">{{ $t('All target groups added here can view and download the file.') }}</div>
                        </e-tooltip>
                    </label>
                    <e-select
                        id="view-select"
                        v-model="form.view"
                        :options="userTargetGroups"
                        :placeholder="$t('Choose')"
                        searchable
                        :multiple="true"
                        track-by="unique_id"
                        :close-on-select="false"
                        :custom-label="target => target.name + ' (' + ($t(typeTranslations(target.type))) + ')'"
                        class="mb-3"/>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <button class="btn btn-secondary mr-2" @click="$bvModal.hide('file-edit-modal')">
                        {{ $t('Cancel') }}
                    </button>
                    <e-btn-loader :isSubmitLoading="isSubmitLoading" @clicked="save">
                        <span>{{ $t('Save') }}</span>
                    </e-btn-loader>
                </div>
            </template>
            <e-loader v-else/>
        </div>
    </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import {eventBus} from '@/main';
import {minLength, required} from 'vuelidate/lib/validators';
import DocumentService from '@services/document.service';
import {alphaNumFileCustom} from '@/validators';

export default {
    name: 'FileModal',
    data() {
        return {
            file: null,
            form: {
                name: '',
                view: [],
            },
            modalTitleFileName: '',
            availableTargetGroups: [],
            loading: true,
            files: [],
            isEdit: false,
            isSubmitLoading: false,
            index: false,
            mainFolder: ''
        };
    },
    computed: {
        ...mapGetters([
            'currentEmployee',
            'currentGuardian',
            'userTargetGroups',
            'activeKindergartenId',
            'userTargetIdentities',
            'folder',
            'folderFiles',
            'subFolders'
        ]),
    },
    methods: {
        openModal() {
            this.$bvModal.show('file-edit-modal');
            this.$store.dispatch('GET_USER_TARGETGROUPS').then(() => {
                this.form.name = this.file.name;
                this.removeExtension();
                this.form.filename = this.file.filename;
                this.modalTitleFileName = this.file.filename;
                this.loading = false;
            });
            DocumentService.getDocumentAcl(this.activeKindergartenId, this.file.id).then(res => {
                this.form.view = res;
            });
        },
        typeTranslations(type) {
            if (type === 'group') {
                return 'Target group';
            } else {
                return this.$options.filters.capitalize(type);
            }
        },
        save() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.isSubmitLoading = true;
                this.removeExtension();
                this.$store.dispatch('UPDATE_DOCUMENT_FILE', this.form).then(() => {
                    this.$toastr.s(this.$t('File updated'));
                    this.$bvModal.hide('file-edit-modal');
                }).finally(() => {
                    this.isSubmitLoading = false;
                });
            }
        },
        resetModal() {
            this.form = {
                name: '',
                view: [],
            };
            this.loading = true;
            this.modalTitleFileName = '';
        },
        removeExtension() {
            this.form.name = this.form.name.replace(/\.(jpg|JPG|png|PNG|txt|TXT|xlsx|XLSX|docx|DOCX|doc|DOC|pdf|PDF)$/g, '');
        },
    },
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(1),
                alphaNumFileCustom
            }
        }
    },
    created() {
        eventBus.$on('show-edit-file-modal', file => {
            this.file = file;
            this.openModal();
        });
    },
    destroyed() {
        eventBus.$off('show-edit-file-modal');
    }
};
</script>

<style scoped>
    .file-edit-modal-container {
        min-height: 197px;
    }
</style>
