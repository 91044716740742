import store from '@/store';

const Marketplace = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/marketplace/index.vue');
const Suppliers = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/marketplace/suppliers/Suppliers.vue');
const AdminSupplier = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/marketplace/suppliers/details/index.vue');
const AdminSupplierDetails = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/marketplace/suppliers/details/views/AdminSupplierGeneral.vue');
const AdminSupplierProducts = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/marketplace/suppliers/details/views/AdminSupplierProducts.vue');
const AdminSupplierServices = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/marketplace/suppliers/details/views/AdminSupplierServices.vue');
const AdminSupplierIntegrations = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/marketplace/suppliers/details/views/AdminSupplierIntegrations.vue') ;
const AdminSupplierIntegration = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/marketplace/suppliers/details/views/Integration/AdminSupplierIntegration.vue') ;
const AdminSupplierIntegrationSettings = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/marketplace/suppliers/details/views/Integration/AdminSupplierIntegrationSettings.vue') ;
const AdminSupplierIntegrationCategories = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/marketplace/suppliers/details/views/Integration/AdminSupplierIntegrationCategories.vue') ;
const AdminSupplierIntegrationProducts = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/marketplace/suppliers/details/views/Integration/AdminSupplierIntegrationProducts.vue') ;
const AdminSupplierIntegrationServices = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/marketplace/suppliers/details/views/Integration/AdminSupplierIntegrationServices.vue') ;
const AdminProducts = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/marketplace/products/AdminProducts.vue');
const AdminProduct = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/marketplace/products/AdminProduct.vue');
const AdminServices = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/marketplace/services/AdminServices.vue');
const AdminService = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/marketplace/services/AdminService.vue');
const AdminShopFilters = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/marketplace/filters/views/AdminShopFilters.vue');
const AdminShopFilter = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/marketplace/filters/views/AdminShopFilter.vue');
const AdminShopOrders = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/marketplace/orders/AdminOrders.vue');
const AdminShopOrder = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/marketplace/orders/AdminOrder.vue');
export default [
    {
        path: 'admin/marketplace',
        name: 'admin.marketplace',
        components: { base: Marketplace },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminMarketplaceIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        // redirect: '/admin/marketplace/suppliers',
        children: [
            {
                path: '/admin/marketplace/suppliers',
                name: 'admin.marketplace.suppliers',
                components: { marketplace: Suppliers },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessAdminSuppliersIndex) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: '/admin/marketplace/products',
                name: 'admin.marketplace.products',
                components: { marketplace: AdminProducts },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessAdminProductsIndex) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: '/admin/marketplace/services',
                name: 'admin.marketplace.services',
                components: { marketplace: AdminServices },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessAdminServicesIndex) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: '/admin/marketplace/filters',
                name: 'admin.marketplace.filters',
                components: { marketplace: AdminShopFilters },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessAdminFiltersIndex) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: '/admin/marketplace/orders',
                name: 'admin.marketplace.orders',
                components: { marketplace: AdminShopOrders },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessAdminOrdersIndex) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
        ]
    },
    {
        path: '/admin/marketplace/products/:productId',
        name: 'admin.marketplace.product',
        components: { base: AdminProduct },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminProductsIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/marketplace/services/:serviceId',
        name: 'admin.marketplace.service',
        components: { base: AdminService },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminServicesIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },

    {
        path: '/admin/marketplace/filters/:filterId',
        name: 'admin.marketplace.filter',
        components: { base: AdminShopFilter },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminFiltersIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/marketplace/orders/:orderId',
        name: 'admin.marketplace.order',
        components: { base: AdminShopOrder },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminOrdersIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/suppliers/:supplier_id',
        components: {base: AdminSupplier},
        children: [
            {
                path: '',
                name: 'admin.supplier.details',
                components: { supplier: AdminSupplierDetails },
            },
            {
                path: 'products',
                name: 'admin.supplier.products',
                components: { supplier: AdminSupplierProducts },
            },
            {
                path: 'services',
                name: 'admin.supplier.services',
                components: { supplier: AdminSupplierServices },
            },
            {
                path: 'integrations',
                name: 'admin.supplier.integrations',
                components: { supplier: AdminSupplierIntegrations },
            },
        ]
    },
    {
        path: '/admin/suppliers/:supplier_id/integrations/:integration_id',

        components: { base: AdminSupplierIntegration },
        children: [
            {
                path: '',
                name: 'admin.supplier.integration',
                components: {integration: AdminSupplierIntegrationSettings}
            },
            {
                path: 'categories',
                name: 'admin.supplier.integration.categories',
                components: {integration: AdminSupplierIntegrationCategories}
            },
            {
                path: 'products',
                name: 'admin.supplier.integration.products',
                components: {integration: AdminSupplierIntegrationProducts}
            },
            {
                path: 'services',
                name: 'admin.supplier.integration.services',
                components: {integration: AdminSupplierIntegrationServices}
            }
        ]
    },

];
