const ChildDevelopment = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development');
const Goals = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development/views/Goals');
const Evaluate = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development/views/Evaluate');
const SettingAgeGroups = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development/views/SettingAgeGroups');
const SettingGeneral = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development/views/SettingGeneral');
const SettingImport = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development/views/SettingImport');
const SettingMarkingOptions = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development/views/SettingMarkingOptions');
const SettingPdf = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development/views/SettingPdf');
const EvaluateChildren = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development/views/EvaluateChildren');
const EvaluateGoals = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development/views/EvaluateGoals');
const Goal = () => import(/* webpackChunkName: "child-dev-module" */ '@views/child_development/views/Goal');

import store from '@/store';

export default [
    {
        path: '/child-development/settings/',
        name: 'child-dev.settings',
        redirect: '/child-development/settings/general',
        components: { base: ChildDevelopment },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessChildDevelopment) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: 'general',
                name: 'child-dev.settings.general',
                components: { 'child-development': SettingGeneral },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditChildDevSettings) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'marking-options',
                name: 'child-dev.settings.marking-options',
                components: { 'child-development': SettingMarkingOptions },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditChildDevSettings) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'age-groups',
                name: 'child-dev.settings.age-groups',
                components: { 'child-development': SettingAgeGroups },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditChildDevSettings) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'import',
                name: 'child-dev.settings.import',
                components: { 'child-development': SettingImport },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditChildDevSettings && store.getters.canEditChildDevelopement) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'pdf',
                name: 'child-dev.settings.pdf',
                components: { 'child-development': SettingPdf },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditChildDevSettings && store.getters.canAccessKindergartenPdfSettings) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
        ]
    },
    {
        path: '/child-development/evaluate/',
        name: 'child-dev.evaluate',
        redirect: '/child-development/evaluate/children',
        components: { base: Evaluate },
        children: [
            {
                path: 'children',
                name: 'child-dev.eval.children',
                components: { 'child-dev-evaluate': EvaluateChildren }
            },
            {
                path: 'goals',
                name: 'child-dev.eval.goals',
                components: { 'child-dev-evaluate': EvaluateGoals }
            },
            {
                path: 'goals/:goal_id',
                name: 'child-dev.eval.goal',
                components: { 'child-dev-evaluate': Goal }
            },
        ]
    },
    {
        path: '/child-development/goals/:goal_id',
        redirect: '/child-development/evaluate/goals/:goal_id',
    },
    {
        path: '/child-development/goals',
        name: 'child-dev.goals',
        components: { base: Goals },
        beforeEnter: (to, from, next) => {
            if (store.getters.canManageChildDevelopment) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
    },
];
