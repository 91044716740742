const Supplier = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/Supplier');

const Products = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Products');
const ProductsList = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Products/views/ProductsList');
const AddNewProduct = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Products/views/AddNewProduct');
const EditProduct = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Products/views/EditProduct');
//
const Orders = () => import(/* webpackChunkName: "supplier-module" */ '@/views/supplier/views/Orders/SupplierOrders.vue');
const Order = () => import(/* webpackChunkName: "supplier-module" */ '@/views/supplier/views/Orders/SupplierOrder.vue');
//
const Pricelists = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Pricelists');
const PricelistsList = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Pricelists/views/PricelistsList');
const PricelistsStore = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Pricelists/views/Store');
const PricelistsUpdate = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Pricelists/views/Update');

const Services = () => import(/* webpackChunkName: "supplier-module" */'@views/supplier/views/Services');

const ActiveServiceList = () => import/* webpackChunkName: "supplier-module" */('@views/supplier/views/Services/views/ActiveServiceList.vue');
const InactiveServiceList = () => import/* webpackChunkName: "supplier-module" */('@views/supplier/views/Services/views/InactiveServiceList.vue');
const AddNewService = () => import/* webpackChunkName: "supplier-module" */('@views/supplier/views/Services/views/AddNewService.vue');
const EditService = () => import/* webpackChunkName: "supplier-module" */('@views/supplier/views/Services/views/EditService.vue');

const Settings = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Settings');
const SupplierSettings = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Settings/views/General');
const SupplierCountrySettings = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Settings/views/CountrySettings');

const Integrations = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Integrations/index');
const Integration = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Integrations/views/Integration');
const IntegrationSettings = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Integrations/views/IntegrationSettings');
const IntegrationCategories = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Integrations/views/IntegrationCategories');
const IntegrationProducts = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Integrations/views/IntegrationProducts');
const IntegrationServices = () => import(/* webpackChunkName: "supplier-module" */ '@views/supplier/views/Integrations/views/IntegrationServices');

import store from '@/store';

export default [
    {
        path: '/supplier',
        components: { base: Supplier },
        children: [
            {
                path: 'products',
                component: Products,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierProducts) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
                children: [
                    {
                        path: '',
                        meta: 'products.active',
                        name: 'supplier.products',
                        components: { 'supplier-products': ProductsList }
                    },
                    {
                        path: 'inactive',
                        meta: 'products.inactive',
                        name: 'supplier.products.inactive',
                        components: { 'supplier-products': ProductsList }
                    },
                ]
            },
            {
                path: 'products/store',
                name: 'supplier.products.store',
                component:  AddNewProduct,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierProducts) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'products/:product_id/update',
                name: 'supplier.products.update',
                component:  EditProduct,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierProducts) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'services',
                component: Services,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierServices) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'supplier.services',
                        component: ActiveServiceList,
                    },
                    {
                        path: 'inactive',
                        name: 'supplier.services.inactive',
                        component: InactiveServiceList,
                    },
                ]
            },
            {
                path: 'services/store',
                name: 'supplier.services.store',
                component:  AddNewService,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierServices) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'services/:service_id/update',
                name: 'supplier.services.update',
                component:  EditService,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierServices) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'orders',
                name: 'supplier.orders',
                component:  Orders,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierOrders) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'orders/:order_id',
                name: 'supplier.order',
                component: Order,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierOrders) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'pricelists',
                component: Pricelists ,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierPricelists) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
                children: [
                    {
                        path: '',
                        meta: 'pricelists',
                        name: 'supplier.pricelists',
                        components: { 'supplier-pricelists': PricelistsList }
                    },
                    {
                        path: 'store',
                        meta: 'pricelists.store',
                        name: 'supplier.pricelists.store',
                        components: { 'supplier-pricelists': PricelistsStore }
                    },
                    {
                        path: ':pl_id/update',
                        meta: 'pricelists.update',
                        name: 'supplier.pricelists.update',
                        components: { 'supplier-pricelists': PricelistsUpdate }
                    },
                ]
            },
            {
                path: 'settings',
                component: Settings,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierInfo) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'supplier.settings',
                        components: { 'supplier-settings': SupplierSettings }
                    },
                    {
                        path: 'country-settings',
                        name: 'supplier.settings.country',
                        components: { 'supplier-settings': SupplierCountrySettings }
                    },
                ]
            },
            {
                path: 'integrations',
                name: 'supplier.integrations',
                component: Integrations,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierIntegrations) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
            },
            {
                path: 'integrations/:id',
                component: Integration,
                beforeEnter: (to, from, next) => {
                    if (store.getters.canEditSupplierIntegrations) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'supplier.integration',
                        components: {'supplier-integration': IntegrationSettings}
                    },
                    {
                        path: 'categories',
                        name: 'supplier.integration.categories',
                        components: {'supplier-integration': IntegrationCategories}
                    },
                    {
                        path: 'products',
                        name: 'supplier.integration.products',
                        components: {'supplier-integration': IntegrationProducts}
                    },
                    {
                        path: 'services',
                        name: 'supplier.integration.services',
                        components: {'supplier-integration': IntegrationServices}
                    }
                ]
            }
        ]
    }
];
