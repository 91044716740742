import SettingsService from '@services/settings.service';
import {
    SET_SYSTEM_AUTH_PROVIDERS,
    GET_SYSTEM_AUTH_PROVIDERS,
    SET_ACTION_BUTTON_ACTIVE,
} from './actions';
const defaultState = () => {
    return {
        providers: [],
        actionBtnActive: false,
    };
};
const state = defaultState();

const mutations = {
    [SET_SYSTEM_AUTH_PROVIDERS](state, providers) {
        state.providers = providers;
    },
    [SET_ACTION_BUTTON_ACTIVE](state, value) {
        state.actionBtnActive = value;
    },
};

const actions = {
    [GET_SYSTEM_AUTH_PROVIDERS]({commit}) {
        return SettingsService.getAuthMethods().then(res => {
           commit(SET_SYSTEM_AUTH_PROVIDERS, res);
        });
    }
};

const getters = {
    systemAuthProviders: state => state.providers,
    actionBtnActive: state => state.actionBtnActive
};

export default {
    state,
    mutations,
    actions,
    getters
};
