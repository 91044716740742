import {
    GET_KINDERGARTEN_ACTIVE_COURSES,
    GET_KINDERGARTEN_ALL_ACTIVE_COURSES,
    SET_KINDERGARTEN_ALL_ACTIVE_COURSES,
    SET_KINDERGARTEN_COURSES,
    ADD_KINDERGARTEN_COURSE,
    UPDATE_KINDERGARTEN_COURSE, SET_KINDERGARTEN_COURSE, STORE_KINDERGARTEN_COURSE,
    REMOVE_KINDERGARTEN_COURSE, ARCHIVE_KINDERGARTEN_COURSE, DELETE_KINDERGARTEN_COURSE,
    GET_KINDERGARTEN_ARCHIVED_COURSES,
    SET_KINDERGARTEN_COURSE_TEACHERS,
    SET_KINDERGARTEN_ARCHIVED_COURSES,
    SET_KINDERGARTEN_COURSE_CHILDREN_COUNT,
    RESTORE_KINDERGARTEN_COURSE,
    REMOVE_KINDERGARTEN_ARCHIVED_COURSE
} from './actions';
import KindergartenService from '@/common/services/kindergarten.service';
import CourseService from '@/common/services/course.service';
import Vue from 'vue';

const getDefaultState = () => {
    return {
        courses: [],
        archivedCourses: {
            data: []
        },
    };
};

export const kindergartenCoursesState = getDefaultState();

export const kindergartenCoursesMutations = {
    [SET_KINDERGARTEN_COURSES](state, courses) {
        state.courses = courses;
    },
    [SET_KINDERGARTEN_ARCHIVED_COURSES] (state, data) {
        state.archivedCourses = data;
    },
    [ADD_KINDERGARTEN_COURSE](state, course) {
        state.courses.push(course);
    },
    [SET_KINDERGARTEN_COURSE](state, course) {
        let index = state.courses.findIndex(c => c.id === course.id);
        Vue.set(state.courses, index, course);
    },
    [REMOVE_KINDERGARTEN_COURSE](state, courseId) {
        state.courses = state.courses.filter(c => c.id !== courseId);
    },
    [REMOVE_KINDERGARTEN_ARCHIVED_COURSE](state, courseId) {
        state.archivedCourses.data.filter(c => c.id !== courseId);
    },
    [SET_KINDERGARTEN_COURSE_TEACHERS](state, data) {
        let course = state.courses.find(c => c.id === data.courseId);
        if(course) {
            course.teachers = data.teachers;
            let index = state.courses.findIndex(c => c.id === data.courseId);
            Vue.set(state.courses, index, course);
        }
    },
    [SET_KINDERGARTEN_COURSE_CHILDREN_COUNT](state, data) {
        let course = state.courses.find(c => c.id === data.courseId);
        if(course) {
            course.current_children_count = data.counts.current_children_count;
            course.future_children_count = data.counts.future_children_count;
            let index = state.courses.findIndex(c => c.id === data.courseId);
            Vue.set(state.courses, index, course);
        }
    }
};

export const kindergartenCoursesActions = {
    [GET_KINDERGARTEN_ALL_ACTIVE_COURSES]({commit, getters}) {
        return KindergartenService.getKindergartenAllActiveCourses(getters.activeKindergartenId).then(res => {
            commit(SET_KINDERGARTEN_ALL_ACTIVE_COURSES, res);
        });
    },
    [GET_KINDERGARTEN_ACTIVE_COURSES]({commit, getters}, options) {
        commit(SET_KINDERGARTEN_COURSES, []);
        return KindergartenService.getActiveKindergartenCourses(getters.activeKindergartenId, options).then(res => {
            commit(SET_KINDERGARTEN_COURSES, res);
        });
    },
    [GET_KINDERGARTEN_ARCHIVED_COURSES]({commit, getters}, options) {
        commit(SET_KINDERGARTEN_COURSES, []);
        return KindergartenService.getArchivedKindergartenCourses(getters.activeKindergartenId, options).then(res => {
            commit(SET_KINDERGARTEN_ARCHIVED_COURSES, res);
        });
    },
    [STORE_KINDERGARTEN_COURSE]({ commit, getters }, data) {
        return CourseService.createCourse(getters.activeKindergartenId, data ).then(res => {
            commit(ADD_KINDERGARTEN_COURSE, res);
        });
    },
    [UPDATE_KINDERGARTEN_COURSE]({ commit, getters }, data) {
        return CourseService.updateCourse(getters.activeKindergartenId, data.id, data.form).then(res => {
            commit(SET_KINDERGARTEN_COURSE, res.course);
            return res;
        });
    },
    [ARCHIVE_KINDERGARTEN_COURSE]({ commit, getters }, data) {
        return CourseService.archiveCourse(getters.activeKindergartenId, data).then(() => {
            commit(REMOVE_KINDERGARTEN_COURSE, data.id);
        });
    },
    [DELETE_KINDERGARTEN_COURSE]({commit ,getters}, courseId) {
        return CourseService.deleteCourse(getters.activeKindergartenId, courseId).then(() => {
            commit(REMOVE_KINDERGARTEN_COURSE, courseId);
        });
    },
    [RESTORE_KINDERGARTEN_COURSE]({commit, getters}, data) {
        return CourseService.restoreCourse(getters.activeKindergartenId, data).then(() => {
            commit(REMOVE_KINDERGARTEN_ARCHIVED_COURSE, data.id);
        });
    }
};

export const kindergartenCoursesGetters = {
    kindergartenCourses: state => state.courses,
    kindergartenArchivedCourses: state => state.archivedCourses,
    kindergartenAllActiveCourses: state => state.all_active_courses,
    kindergartenActiveCourses: state => state.active_courses,
};
