export const GET_CHILD_ABSENCE_CALENDAR = 'GET_CHILD_ABSENCE_CALENDAR';
export const SET_CHILD_ABSENCE_CALENDAR = 'SET_CHILD_ABSENCE_CALENDAR';
export const SET_CHILD_ABSENCE_CALENDAR_LOCK = 'SET_CHILD_ABSENCE_CALENDAR_LOCK';
export const SET_CHILD_ABSENCE_DATA = 'SET_CHILD_ABSENCE_DATA';
export const STORE_CHILD_CALENDAR_DAY = 'STORE_CHILD_CALENDAR_DAY';
export const STORE_CHILD_CALENDAR_DAY_COMMENT = 'STORE_CHILD_CALENDAR_DAY_COMMENT';
export const SET_CHILD_CALENDAR_DAY = 'SET_CHILD_CALENDAR_DAY';
export const SET_CHILD_ABSENCE_CALENDAR_HOLIDAYS = 'SET_CHILD_ABSENCE_CALENDAR_HOLIDAYS';
export const STORE_CHILD_CALENDAR_DAYS = 'STORE_CHILD_CALENDAR_DAYS';
export const SET_CHILD_ABSENCE_DEFAULT_STATUS = 'SET_CHILD_ABSENCE_DEFAULT_STATUS';
export const STORE_CHILD_CALENDAR_TIMES = 'STORE_CHILD_CALENDAR_TIMES';
export const SET_CHILD_CALENDAR_ACCESS = 'SET_CHILD_CALENDAR_ACCESS';
export const SET_CHILD_ABSENCE_CALENDAR_COMMENT_LOCK = 'SET_CHILD_ABSENCE_CALENDAR_COMMENT_LOCK';
export const STORE_CHILD_CALENDAR_STATUS = 'STORE_CHILD_CALENDAR_STATUS';
export const SET_CHILD_ABSENCE_CALENDAR_WORKDAY_WEEKEND = 'SET_CHILD_ABSENCE_CALENDAR_WORKDAY_WEEKEND';
export const SET_CHILD_ABSENCE_CALENDAR_WORKDAY_HOLIDAY = 'SET_CHILD_ABSENCE_CALENDAR_WORKDAY_HOLIDAY';