import ChildDevService from '@services/childdev.service';
import Vue from 'vue';
import {DAY, hasPassed, WEEK} from '../../storeHelpers';
import {
    GET_CHILD_DEV_OPTIONS,
    SET_CHILD_DEV_OPTIONS,
    UPDATE_CHILD_DEV_OPTIONS,
    GET_CHILD_DEV_AGE_GROUPS,
    SET_CHILD_DEV_AGE_GROUPS,
    UPDATE_CHILD_DEV_AGE_GROUP,
    STORE_CHILD_DEV_AGE_GROUP,
    SET_CHILD_DEV_AGE_GROUP,
    DELETE_CHILD_DEV_AGE_GROUP,
    GET_CHILD_DEV_CATEGORIES,
    SET_CHILD_DEV_CATEGORIES,
    ADD_KINDERGARTEN_CHILD_DEV_CATEGORY,
    SET_KINDERGARTEN_CHILD_DEV_CATEGORY,
    DELETE_KINDERGARTEN_CHILD_DEV_CATEGORY,
    STORE_KINDERGARTEN_CHILD_DEV_CATEGORY,
    UPDATE_KINDERGARTEN_CHILD_DEV_CATEGORY,
    STORE_KINDERGARTEN_CHILD_DEV_GOAL,
    UPDATE_KINDERGARTEN_CHILD_DEV_GOAL,
    DELETE_KINDERGARTEN_CHILD_DEV_GOAL,
    CHILD_DEV_GOAL,
    GET_EMPLOYEE_ROLES,
    SET_EMPLOYEE_ROLES,
    GET_CHILD_DEV_COURSE_CHILDREN,
    SET_CHILD_DEV_COURSE_CHILDREN,
    EVALUATE_CHILD_DEV,
    SET_CHILD_DEV_EVALUATION,
    COPY_CHILD_DEV_GOALS,
    STORE_CHILD_DEV_COMMENT,
    UPDATE_CHILD_DEV_COMMENT,
    SET_CHILD_DEV_COMMENT,
    DELETE_CHILD_DEV_COMMENT,
    GET_CDM_SETTINGS,
    SET_CDM_SETTINGS,
    SET_CDM_VERBOSE_SETTING,
    SET_CDM_VERBOSE_SETTINGS,
    GET_KINDERGARTEN_CHILD_DEV_CATEGORIES,
    SET_KINDERGARTEN_CHILD_DEV_CATEGORIES,
    ADD_KINDERGARTEN_CHILD_DEV_GOAL,
    SET_KINDERGARTEN_CHILD_DEV_GOAL,

    GET_CDM_CHILD_DATA,
    GET_CDM_GOALS_EVALUATION_DATA,
    GET_KINDERGARTEN_CDM_CONFIGURATION,
    GET_KINDERGARTEN_CDM_SETTINGS,
    RESET_CDM_STORE,
    STORE_CHILD_DEV_OPTION,
    ADD_CHILD_DEV_OPTION,
    DELETE_CHILD_DEV_OPTION,
    REMOVE_CHILD_DEV_OPTION
} from './actions';
import {SET_CHILD_DEV_EVALUATIONS_IN_PROFILE} from '../child/actions';

function removeCategory(category, state) {
    if(category.parent_id) {
        const categoryIndex = state.kindergartenSubcategories.findIndex(sc => sc.id === category.id);
        Vue.delete(state.kindergartenSubcategories, categoryIndex);

        //Find goals and subgoals
        const goals = state.kindergartenGoals.filter(g => g.category_id === category.id);
        goals.map(goal => {
            state.kindergartenSubgoals = state.kindergartenSubgoals.filter(sg => sg.parent_id !== goal.id);
        });
        state.kindergartenGoals = state.kindergartenGoals.filter(g => g.category_id !== category.id);

    } else {
        const categoryIndex = state.kindergartenCategories.findIndex(c => c.id === category.id);
        Vue.delete(state.kindergartenCategories, categoryIndex);
        state.kindergartenSubcategories.filter(sc => sc.parent_id === category.id).forEach(subCategory => {
            removeCategory(subCategory, state);
        });
    }
}

const getDefaultState = () => {
    return {
        eval_options: [
            {value: 1, name: 'Monthly'},
            {value: 3, name: 'Quarterly'},
            {value: 6, name: 'Half a year'},
            {value: 12, name: 'Yearly'},
        ],
        settings: {
            value: {
                child_dev_enabled: false,
                shown_to_parents: false,
                shown_in_profile_diary: false,
                eval_with_numbers: false,
                subgoals_enabled: false,
                goal_type_goal: false,
                goal_type_competence: false,
            },
            date: null

        },
        eval_with_numbers: null,
        verboseSettings: {
            value: {
                child_dev_enabled: null,
                shown_to_parents: null,
                shown_in_profile_diary: null,
                eval_with_numbers: null,
                subgoals_enabled: null,
                goal_type_goal: null,
                goal_type_competence: null,
            },
            date: null,
        },


        //Cdm structure separated by layer
        kindergartenCategories: [],
        kindergartenSubcategories: [],
        kindergartenGoals: [],
        kindergartenSubgoals: [],

        //Contains the tree structured data
        childCategories: [],

        //Goal evaluation options
        options: {
            value: [],
            date: null
        },
        //CDM age group options
        // age_groups: [],
        age_groups: {
            value: [],
            date: null
        },
        //Employee roles used to determine visible categories
        employee_roles: {
            value: [],
            date: null
        },
        //children list for evaluateChildren and goal specific evaluation
        course_children: [],
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_CDM_STORE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_CHILD_DEV_EVALUATION](state, child) {
        const index = state.course_children.findIndex(c => c.id === child.id);
        if(index > -1) {
            Vue.set(state.course_children, index, child);
        } else {
            state.course_children.push(child);
        }
    },
    [SET_CHILD_DEV_COURSE_CHILDREN](state, data) {
        state.course_children = data;
    },
    [SET_EMPLOYEE_ROLES](state, data) {
        state.employee_roles.value = data;
        state.employee_roles.date = Vue.moment();
    },
    [SET_CDM_SETTINGS](state, data) {
        state.settings.value = data;
        state.settings.date = Vue.moment();
    },
    [SET_CDM_VERBOSE_SETTING](state, data) {
        state.verboseSettings.value[data.setting] = data.value;
        state.settings.value[data.setting] = data.value.value == true;
    },
    [ADD_CHILD_DEV_OPTION](state, data) {
        state.options.value.push(data);
        state.options.date = Vue.moment();
    },
    [SET_CHILD_DEV_OPTIONS](state, data) {
        state.options.value = data;
        state.options.date = Vue.moment();
    },
    [REMOVE_CHILD_DEV_OPTION](state, id) {
        state.options.value = state.options.value.filter(o => o.id !== id);
    },
    [SET_CHILD_DEV_AGE_GROUPS](state, data) {
        state.age_groups.value = data;
        state.age_groups.date = Vue.moment();
    },
    [SET_CHILD_DEV_AGE_GROUP](state, group) {
        const index = state.age_groups.value.findIndex(g => g.id === group.id);
        if(index > -1) {
            Vue.set(state.age_groups.value, index, group);
        } else {
            state.age_groups.value.push(group);
        }
    },
    [DELETE_CHILD_DEV_AGE_GROUP](state, groupId) {
        const index = state.age_groups.value.findIndex(g => g.id === groupId);
        Vue.delete(state.age_groups.value, index);
    },
    [SET_KINDERGARTEN_CHILD_DEV_CATEGORIES](state, data) {
        if(data.categories) {
            state.kindergartenCategories = data.categories;
            state.kindergartenSubcategories = data.subcategories;
            state.kindergartenGoals = data.goals;
            state.kindergartenSubgoals = data.subgoals;
        } else {
            state.kindergartenCategories = [];
            state.kindergartenSubcategories = [];
            state.kindergartenGoals = [];
            state.kindergartenSubgoals = [];
        }
    },
    [SET_CHILD_DEV_CATEGORIES](state, data) {
      state.childCategories = data;
    },
    [ADD_KINDERGARTEN_CHILD_DEV_CATEGORY](state, category) {
        if (category.parent_id) {
            state.kindergartenSubcategories.push(category);
        } else {
            state.kindergartenCategories.push(category);
        }
    },
    [SET_KINDERGARTEN_CHILD_DEV_CATEGORY](state, category) {
        if (category.parent_id) {
            const index = state.kindergartenSubcategories.findIndex(c => c.id === category.id);
            if(index > -1) {
                Vue.set(state.kindergartenSubcategories, index, category);
            }
        } else {
            const index = state.kindergartenCategories.findIndex(c => c.id === category.id);
            if(index > -1) {
                Vue.set(state.kindergartenCategories, index, category);
            }
        }
    },
    [DELETE_KINDERGARTEN_CHILD_DEV_CATEGORY](state, category) {
        removeCategory(category, state);
    },
    [ADD_KINDERGARTEN_CHILD_DEV_GOAL](state, goal) {
        const isSubgoal = goal.category_id === null && goal.parent_id !== null;
        if(isSubgoal) {
            state.kindergartenSubgoals.push(goal);
        } else {
            state.kindergartenGoals.push(goal);
        }
    },
    [SET_KINDERGARTEN_CHILD_DEV_GOAL](state, goal) {
        const isSubgoal = goal.category_id === null && goal.parent_id !== null;

        if(isSubgoal) {
            const index = state.kindergartenSubgoals.findIndex(sg => sg.id === goal.id);
            if(index > -1) {
                Vue.set(state.kindergartenSubgoals, index, goal);
            }
        } else {
            const index = state.kindergartenGoals.findIndex(sg => sg.id === goal.id);
            if(index > -1) {
                Vue.set(state.kindergartenGoals, index, goal);
            }
        }
    },
    [DELETE_KINDERGARTEN_CHILD_DEV_GOAL](state, goal) {
        const isSubGoal = goal.category_id === null && goal.parent_id !== null;
        if (isSubGoal) {
            let goalIndex = state.kindergartenSubgoals.findIndex(g => g.id === goal.id);
            Vue.delete(state.kindergartenSubgoals, goalIndex);
        } else {
            let goalIndex = state.kindergartenGoals.findIndex(g => g.id === goal.id);
            Vue.delete(state.kindergartenGoals, goalIndex);
            //Find any subgoals
            state.kindergartenSubgoals = state.kindergartenSubgoals.filter(sg => sg.parent_id !== goal.id);
        }
    },
    [SET_CHILD_DEV_COMMENT](state, {comment}) {
        mainLoop:
        for (let mainCatIndex = 0; mainCatIndex < state.childCategories.length; mainCatIndex++) {
            const subCat = state.childCategories[mainCatIndex].subcategories;
            for (let i = 0; i < subCat.length; i++) {
                const subcat = subCat[i];
                for (const goal of subcat.goals) {
                    if (goal.id === comment.goal_id) {
                        const index = goal.comments.findIndex(c => c.id === comment.id);
                        if(index > -1) {
                            Vue.set(goal.comments, index, comment);
                        } else {
                            goal.comments.push(comment);
                        }
                        break mainLoop;
                    } else if (goal.subgoals) {
                        const subgoal = goal.subgoals?.find(g => g.id === comment.goal_id);
                        if (subgoal) {
                            const index = subgoal.comments.findIndex(c => c.id === comment.id);
                            if(index > -1) {
                                Vue.set(subgoal.comments, index, comment);
                            } else {
                                subgoal.comments.push(comment);
                            }
                            break mainLoop;
                        }
                    }
                }
            }
        }
    },
    [DELETE_CHILD_DEV_COMMENT](state, {comment}) {
        mainLoop:
        for (let mainCatIndex = 0; mainCatIndex < state.childCategories.length; mainCatIndex++) {
            const subCat = state.childCategories[mainCatIndex].subcategories;
            for (let i = 0; i < subCat.length; i++) {
                const subcat =subCat[i];
                for (const goal of subcat.goals) {
                    if (goal.id === comment.goal_id) {
                        const index = goal.comments.findIndex(c => c.id === comment.id);
                        if(index > -1) {
                            Vue.delete(goal.comments, index);
                        }
                        break mainLoop;
                    } else if (goal.subgoals) {
                        const subgoal = goal.subgoals?.find(g => g.id === comment.goal_id);
                        if (subgoal) {
                            const index = subgoal.comments.findIndex(c => c.id === comment.id);
                            if(index > -1) {
                                Vue.delete(subgoal.comments, index);
                            }
                            break mainLoop;
                        }
                    }
                }
            }
        }
    },
    [SET_CDM_VERBOSE_SETTINGS](state, settings) {
        state.verboseSettings.value = settings;
        state.verboseSettings.date = Vue.moment();
    },
};

const actions = {
    [COPY_CHILD_DEV_GOALS]({ commit, dispatch, getters, state }) {
        return ChildDevService.copyGoals(getters.activeKindergartenId).then(res => {
            commit(SET_CDM_VERBOSE_SETTINGS, res.settings);
            //dispatch('GET_CHILD_DEV_CATEGORIES');
            dispatch('GET_CHILD_DEV_AGE_GROUPS', true);
            state.settings.date = null;
            dispatch('GET_KINDERGARTEN_CDM_CONFIGURATION', {
                settings: true,
            });
            return res;
        });
    },
    [EVALUATE_CHILD_DEV]({ commit, getters }, form) {
        return ChildDevService.evaluate(getters.activeKindergartenId, form.child_id, form).then(res => {
            commit(SET_CHILD_DEV_EVALUATION, res.child);
            commit(SET_CHILD_DEV_EVALUATIONS_IN_PROFILE, res.child.evaluations);
            res.goal.old_category_id = res.goal.category_id;

            return res.child.evaluations;
        });
    },
    [GET_CHILD_DEV_COURSE_CHILDREN]({ commit, getters }, courseId) {
        return ChildDevService.courseChildren(getters.activeKindergartenId, courseId).then(res => {
            commit(SET_CHILD_DEV_COURSE_CHILDREN, res);
        });
    },
    [GET_EMPLOYEE_ROLES]({ commit, getters }) {
        // if (getters.childDevRolesList.length) {
        //     return;
        // }
        return ChildDevService.employeeRoles(getters.activeKindergartenId).then(res => {
            commit(SET_EMPLOYEE_ROLES, res);
        });
    },
    [GET_CDM_SETTINGS]({ commit, getters, state }) {
        if(!state.settings.date || hasPassed(state.settings.date, DAY)) {
            return ChildDevService.cdmSettings(getters.activeKindergartenId).then(res => {
                commit(SET_CDM_SETTINGS, res);
            });
        } else {
            return;
        }

    },
    [GET_KINDERGARTEN_CDM_SETTINGS]({ commit, getters }) {
        if (!state.verboseSettings.date || hasPassed(state.verboseSettings.date, DAY)) {
            return ChildDevService.cdmVerboseSettings(getters.activeKindergartenId).then(res => {
                commit(SET_CDM_VERBOSE_SETTINGS, res);
            });
        } else {
            return getters.cdmVerboseSettings;
        }
    },
    [GET_CHILD_DEV_OPTIONS]({ commit, getters }, forced = false) {
        if(!state.options.date || hasPassed(state.options.date, DAY) || forced) {
            return ChildDevService.options(getters.activeKindergartenId).then(res => {
                commit(SET_CHILD_DEV_OPTIONS, res);
                return res;
            });
        } else {
            return getters.childDevOptions;
        }
    },
    [STORE_CHILD_DEV_OPTION]({commit, getters}, form) {
        return ChildDevService.addOption(getters.activeKindergartenId, form).then(res => {
            commit(ADD_CHILD_DEV_OPTION, res);
            return res;
        });
    },
    [UPDATE_CHILD_DEV_OPTIONS]({ commit, getters }, form) {
        return ChildDevService.updateOptions(getters.activeKindergartenId, form).then(res => {
            commit(SET_CHILD_DEV_OPTIONS, res);
            return res;
        });
    },
    [DELETE_CHILD_DEV_OPTION]({commit, getters}, id) {
        return ChildDevService.removeOption(getters.activeKindergartenId, id).then(() => {
            commit(REMOVE_CHILD_DEV_OPTION, id);
        });
    },
    [GET_CHILD_DEV_AGE_GROUPS]({ commit, getters }, forced = false) {
        if(!state.age_groups.date || hasPassed(state.age_groups.date, DAY) || forced) {
            return ChildDevService.ageGroups(getters.activeKindergartenId).then(res => {
                commit(SET_CHILD_DEV_AGE_GROUPS, res);
            });
        } else {
            return;
        }
    },
    [STORE_CHILD_DEV_AGE_GROUP]({ commit, getters }, form) {
        return ChildDevService.storeAgeGroup(getters.activeKindergartenId, form).then(res => {
            commit(SET_CHILD_DEV_AGE_GROUP, res);
        });
    },
    [UPDATE_CHILD_DEV_AGE_GROUP]({ commit, getters }, form) {
        return ChildDevService.updateAgeGroup(getters.activeKindergartenId, form.id, form).then(res => {
            commit(SET_CHILD_DEV_AGE_GROUP, res);
        });
    },
    [DELETE_CHILD_DEV_AGE_GROUP]({ commit, getters }, groupId) {
        return ChildDevService.destroyAgeGroup(getters.activeKindergartenId, groupId).then(res => {
            commit(DELETE_CHILD_DEV_AGE_GROUP, groupId);
            return res;
        });
    },
    [GET_CDM_CHILD_DATA]({commit, getters, state}, childId) {
        let options = {};

        if(!state.age_groups.date || hasPassed(state.age_groups.date, DAY)) {
            options.age_groups = true;
        }

        return ChildDevService.categoriesForChild(getters.activeKindergartenId, childId, options).then(res => {
            if(res.age_groups) {
                commit(SET_CHILD_DEV_AGE_GROUPS, res.age_groups);
            }

            commit(SET_CHILD_DEV_CATEGORIES, res.categories);
        });
    },
    [GET_CDM_GOALS_EVALUATION_DATA]({getters, commit, state}, settings = false) {
        commit(SET_CHILD_DEV_CATEGORIES, []);
        let options = {settings: settings};

        if(!state.age_groups.date || hasPassed(state.age_groups.date, DAY)) {
            options.age_groups = true;
        }

        return ChildDevService.categoriesForGoals(getters.activeKindergartenId, options).then(res => {
            if(res.age_groups) {
                commit(SET_CHILD_DEV_AGE_GROUPS, res.age_groups);
            }
            if(res.settings) {
                commit(SET_CDM_SETTINGS, res.settings);
            }
            commit(SET_CHILD_DEV_CATEGORIES, res.categories);
        });
    },
    [GET_KINDERGARTEN_CDM_CONFIGURATION]({commit, getters, state}, requests = {}) {
        let options = {};
        if(requests.age_groups && (!state.age_groups.date || hasPassed(state.age_groups.date, DAY))) {
            options.age_groups = true;
        }

        if(requests.options && (!state.options.date || hasPassed(state.options.date, DAY))) {
            options.options = true;
        }

        if(requests.settings && (!state.settings.date || hasPassed(state.settings.date, DAY))) {
            options.settings = true;
        }

        if(requests.roles && (!state.employee_roles.date || hasPassed(state.employee_roles.date, WEEK))) {
            options.roles = true;
        }

        if(requests.categories) {
            options.categories = requests.categories;
        }
        //Check if any data needs to be fetched
        if(Object.keys(options).length !== 0) {
            return ChildDevService.generalSettings(getters.activeKindergartenId, options).then(res => {
                if(res.age_groups) {
                    commit(SET_CHILD_DEV_AGE_GROUPS, res.age_groups);
                }

                if(res.options) {
                    commit(SET_CHILD_DEV_OPTIONS, res.options);
                }
                if(res.settings) {
                    commit(SET_CDM_SETTINGS, res.settings);
                }
                if(res.roles) {
                    commit(SET_EMPLOYEE_ROLES, res.roles);
                }
                if(res.categories) {
                    commit(SET_CHILD_DEV_CATEGORIES, res.categories);
                }
            });
        } else {
            return;
        }
    },
    [GET_CHILD_DEV_CATEGORIES]({commit, getters}, childId) {
        commit(SET_CHILD_DEV_CATEGORIES, []);
        return ChildDevService.categoriesForChild(getters.activeKindergartenId, childId).then(res => {
            commit(SET_CHILD_DEV_CATEGORIES, res.categories);
        });
    },
    [GET_KINDERGARTEN_CHILD_DEV_CATEGORIES]({commit, getters}) {
            commit(SET_KINDERGARTEN_CHILD_DEV_CATEGORIES, []);
            return ChildDevService.kindergartenCategories(getters.activeKindergartenId).then(res => {
                commit(SET_KINDERGARTEN_CHILD_DEV_CATEGORIES, res);
            });
    },
    [STORE_KINDERGARTEN_CHILD_DEV_CATEGORY]({ commit, getters }, form) {
        return ChildDevService.storeCategory(getters.activeKindergartenId, form).then(res => {
            commit(ADD_KINDERGARTEN_CHILD_DEV_CATEGORY, res);
        });
    },
    [UPDATE_KINDERGARTEN_CHILD_DEV_CATEGORY]({ commit, getters }, form) {
        return ChildDevService.updateCategory(getters.activeKindergartenId, form.id, form).then(res => {
            commit(SET_KINDERGARTEN_CHILD_DEV_CATEGORY, res);
        });
    },
    [DELETE_KINDERGARTEN_CHILD_DEV_CATEGORY]({ commit, getters }, category) {
        return ChildDevService.destroyCategory(getters.activeKindergartenId, category.id).then(res => {
            commit(DELETE_KINDERGARTEN_CHILD_DEV_CATEGORY, category);
            return res;
        });
    },
    [STORE_KINDERGARTEN_CHILD_DEV_GOAL]({ commit, getters }, form) {
        ChildDevService.storeGoal(getters.activeKindergartenId, form).then(res => {
            commit(ADD_KINDERGARTEN_CHILD_DEV_GOAL, res);
        });
    },
    [UPDATE_KINDERGARTEN_CHILD_DEV_GOAL]({ commit, getters }, form) {
        return ChildDevService.updateGoal(getters.activeKindergartenId, form.id, form).then(res => {
            commit(SET_KINDERGARTEN_CHILD_DEV_GOAL, res);
        });
    },
    [DELETE_KINDERGARTEN_CHILD_DEV_GOAL]({ commit, getters }, goal) {
        return ChildDevService.destroyGoal(getters.activeKindergartenId, goal.id).then(res => {
            commit(DELETE_KINDERGARTEN_CHILD_DEV_GOAL, goal);
            return res;
        });
    },
    [CHILD_DEV_GOAL]({ getters}, goalId) {
        let options = {};

        return ChildDevService.goal(getters.activeKindergartenId, goalId, options).then(res => {
            return res;
        });
    },
    [STORE_CHILD_DEV_COMMENT]({ commit, getters }, {childId, form}) {
        return ChildDevService.storeComment(getters.activeKindergartenId, childId, form).then(res => {
            commit(SET_CHILD_DEV_COMMENT, {comment: res, getters});
        });
    },
    [UPDATE_CHILD_DEV_COMMENT]({ commit, getters }, {commentId, form}) {
        return ChildDevService.updateComment(getters.activeKindergartenId, commentId, form).then(res => {
            commit(SET_CHILD_DEV_COMMENT, {comment: res, getters});
        });
    },
    [DELETE_CHILD_DEV_COMMENT]({ commit, getters }, comment) {
        return ChildDevService.destroyComment(getters.activeKindergartenId, comment.id).then(res => {
            commit(DELETE_CHILD_DEV_COMMENT, {comment, getters});
            return res;
        });
    },
};

const getters = {
    cdmSettings: state => state.settings.value,
    cdmVerboseSettings: state => state.verboseSettings.value,
    childDevCourseChildren: state => state.course_children,
    childDevRolesList: state => state.employee_roles.value,
    childDevOptions: state => state.options.value,
    childDevEvalOptions: state => state.eval_options,
    childDevAgeGroups: state => state.age_groups.value,
    childDevKindergartenCategories: state => state.kindergartenCategories,
    childDevKindergartenSubcategories: state => state.kindergartenSubcategories,
    childDevKindergartenGoals: state => state.kindergartenGoals,
    childDevCategories: state => state.childCategories,
    childDevKindergartenCategorySubcategories: state => id => {
        return state.kindergartenSubcategories.filter(sc => sc.parent_id === id);
    },
    childDevKindergartenCategoryGoals: state => id => {
        return state.kindergartenGoals.filter(g => g.category_id === id);
    },
    childDevKindergartenGoalSubgoals: state => id => {
        return state.kindergartenSubgoals.filter(sg => sg.parent_id === id);
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};
