import http from '@http';

class NotificationService {
    constructor() {
        if (NotificationService.instance) {
            return NotificationService.instance;
        }

        NotificationService.instance = this;
    }

    getRecentNotifications() {
        return http.get('/api/common/notifications/recent').then(res => res.data);
    }

    getNotifications(queryObj) {
        return http.get('/api/common/notifications', {params: queryObj}).then(res => res.data);
    }

    getUnseenCount() {
        return http.get('/api/common/notifications/unseen').then(res => res.data);
    }
}

export default new NotificationService();