import {
    DELETE_SERVICE,
    DELETE_SERVICE_IMG,
    GET_SUPPLIER_SERVICES,
    GET_SUPPLIER_SERVICE,
    REMOVE_SERVICE,
    SET_SERVICES,
    SET_SERVICE,
    SET_SERVICE_FORM,
    STORE_SERVICE,
    UPDATE_SERVICE,
} from './actions';
import SupplierService from '@services/supplier.service';
import Vue from 'vue';

const getDefaultState = () => {
    return {
        services: {
            data: []
        },
        serviceForm: null
    };
};

export const serviceState = getDefaultState();

export const serviceMutations = {
    [SET_SERVICE](state, product) {
        const index = state.services?.data.findIndex(p => p.id === product.id);

        if (index > -1) {
            Vue.set(state.services.data, index, product);
        } else {
            state.services.data.push(product);
        }
    },
    [SET_SERVICES](state, data) {
        state.services = data.items;
    },
    [SET_SERVICE_FORM](state, data) {
        state.serviceForm = data;
    },
    [REMOVE_SERVICE](state, product) {
        const index = state.services.data.findIndex(p => p.id === product.id);
        state.services.data.splice(index, 1);
    }
};

export const serviceActions = {
    [GET_SUPPLIER_SERVICES]({getters, commit}, queryObj) {
        return SupplierService.services(getters.activeSupplierId, queryObj).then(res => {
            commit(SET_SERVICES, {type: queryObj.type, items: res});
        });
    },
    [GET_SUPPLIER_SERVICE]({commit}, {supplierId, serviceId}) {
        return SupplierService.service(supplierId, serviceId).then(res => {
            commit(SET_SERVICE_FORM, res);
        });
    },
    [STORE_SERVICE]({getters, commit}, {form}) {
        return SupplierService.storeService(getters.activeSupplierId, form).then(res => {
            commit(SET_SERVICE, res);
        });
    },
    [UPDATE_SERVICE]({getters, commit}, {form, productId}) {
        return SupplierService.updateService(getters.activeSupplierId, form, productId).then(res => {
            commit(SET_SERVICE_FORM, res);
        });
    },
    [DELETE_SERVICE]({getters, commit}, product) {
        return SupplierService.deleteService(getters.activeSupplierId, product.id).then(() => {
            commit(REMOVE_SERVICE, product);
        });
    },
    [DELETE_SERVICE_IMG]({getters, commit}, imgId) {
        return SupplierService.deleteServiceImg(getters.activeSupplierId, imgId).then(res => {
            commit(SET_SERVICE, res);
        });
    }
};

export const serviceGetters = {
    supplierServices: state => state.services,
    serviceForm: state => state.serviceForm
};
