export const USER_APPLICATIONS = 'USER_APPLICATIONS';
export const KINDERGARTEN_APPLICATIONS = 'KINDERGARTEN_APPLICATIONS';
export const SET_USER_APPLICATIONS = 'SET_USER_APPLICATIONS';
export const SET_PENDING_KINDERGARTEN_APPLICATIONS =
    'SET_PENDING_KINDERGARTEN_APPLICATIONS';
export const SET_CONFIRMED_KINDERGARTEN_APPLICATIONS =
    'SET_CONFIRMED_KINDERGARTEN_APPLICATIONS';
export const SET_REJECTED_KINDERGARTEN_APPLICATIONS =
    'SET_REJECTED_KINDERGARTEN_APPLICATIONS';
export const SET_TERMINATED_KINDERGARTEN_APPLICATIONS =
    'SET_TERMINATED_KINDERGARTEN_APPLICATIONS';
export const SAVE_APPLICATION = 'SAVE_APPLICATION';
export const SET_APPLICATION = 'SET_APPLICATION';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_KINDERGARTEN_APPLICATION =
    'UPDATE_KINDERGARTEN_APPLICATION';
export const GET_APPLICATION = 'GET_APPLICATION';
export const REJECT_KINDERGARTEN_APPLICATION =
    'REJECT_KINDERGARTEN_APPLICATION';
export const COMFIRM_KINDERGARTEN_APPLICATION =
    'COMFIRM_KINDERGARTEN_APPLICATION';
export const ADD_REMOVE_CHILD = 'ADD_REMOVE_CHILD';
