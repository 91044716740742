import {
    GET_PRICE_LISTS,
    SET_PRICE_LISTS,
    SET_PRICE_LIST,
    STORE_PRICE_LIST,
    UPDATE_PRICE_LIST,
    DELETE_PRICE_LIST,
    REMOVE_PRICE_LIST,
    RESET_PRICE_LIST_STATE,
    REORDER_PRICE_LIST
} from './actions';
import SupplierService from '@services/supplier.service';
import Vue from 'vue';

const getDefaultState = () => {
    return {
        price_lists: {
            data: []
        }
    };
};

export const pricelistState = getDefaultState();

export const pricelistMutations = {
    [RESET_PRICE_LIST_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_PRICE_LISTS](state, data) {
        state.price_lists = data;
    },
    [SET_PRICE_LIST](state, priceList) {
        const index = state.price_lists.data.findIndex(p => p.id === priceList.id);
        if (index > -1) {
            Vue.set(state.price_lists.data, index, priceList);
        } else {
            state.price_lists.data.push(priceList);
        }
    },
    [REMOVE_PRICE_LIST](state, priceList) {
        const index = state.price_lists.data.findIndex(p => p.id === priceList.id);
        state.price_lists.data.splice(index, 1);
    }
};

export const pricelistActions = {
    [GET_PRICE_LISTS]({getters, commit}, queryObj) {
        return SupplierService.pricelists(getters.activeSupplierId, queryObj).then(res => {
            commit(SET_PRICE_LISTS, res);
        });
    },
    [STORE_PRICE_LIST]({getters, commit}, form) {
        return SupplierService.storePricelist(getters.activeSupplierId, form).then(res => {
            commit(SET_PRICE_LIST, res);
        });
    },
    [UPDATE_PRICE_LIST]({getters, commit}, {form, priceListId}) {
        return SupplierService.updatePricelist(getters.activeSupplierId, form, priceListId).then(res => {
            commit(SET_PRICE_LIST, res);
        });
    },
    [DELETE_PRICE_LIST]({getters, commit}, priceList) {
        return SupplierService.deletePricelist(getters.activeSupplierId, priceList.id).then(() => {
            commit(REMOVE_PRICE_LIST, priceList);
        });
    },
    [REORDER_PRICE_LIST]({getters, commit}, form) {
        return SupplierService.reorderPricelist(getters.activeSupplierId, form).then(res => {
            commit(SET_PRICE_LISTS, res);
        });
    }
};

export const pricelistGetters = {
    supplierPriceLists: state => state.price_lists
};
