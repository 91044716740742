import Vue from 'vue';
import store from './store';
import moment from 'moment';
import xss from 'xss';
import { basicEditorXSSOptions } from './config';

export default {
    // Get nice readable timestamp from UNIX timestamp
    formatTs: Vue.filter('format_ts', value => {
        return Vue.moment.unix(Number(value)).format('ddd, MMM D, YYYY HH:mm A');
    }),

    // Get human readable "time elapsed" from UNIX timestamp
    formatTsElapsed: Vue.filter('format_ts_elapsed', value => {
        return Vue.moment.unix(Number(value)).from();
    }),

    formatDate: Vue.filter('formatDate', date => {
        if (date !== null) {
            return Vue.moment(date).format('L');
        }
        return null;
    }),

    formatFullYear: Vue.filter('formatFullYear', date => {
        if (date !== null) {
            return Vue.moment(date).format('YYYY');
        }
        return null;
    }),

    formatStartOfWeek: Vue.filter('formatStartOfWeek', date => {
        if (date !== null) {
            return Vue.moment(date).startOf('isoWeek').format('DD.MM.YYYY');
        }
        return null;
    }),

    formatEndOfWeek: Vue.filter('formatEndOfWeek', date => {
        if (date !== null) {
            return Vue.moment(date).endOf('isoWeek').format('DD.MM.YYYY');
        }
        return null;
    }),

    formatDateWithDayMonthName: Vue.filter('formatDateWithDayMonthName', date => {
        if (date !== null) {
            return Vue.moment(date).format('dddd, D MMMM YYYY');
        }
        return null;
    }),

    formatDateWithMonth: Vue.filter('formatDateWithMonth', date => {
            if (date !== null) {
                return Vue.moment(date).format('DD.MM');
            }
        }
    ),

     formatFullMonthDate: Vue.filter('formatFullMonthDate', date => {
        if (date !== null) {
            return Vue.moment(date).format('LL');
        }
        return null;
    }),

    formatUtcDate: Vue.filter('formatUtcDate', date => {
        if (date !== null) {
            let utc = Vue.moment.utc(date);
            let timezone = store.getters.user.timezone;
            return Vue.moment(utc).tz(timezone).format('DD.MM.YYYY');
        }
        return null;
    }),

    convertFromUtc: Vue.filter('convertFromUtc', date => {
        if (date !== null) {
            let utc = Vue.moment.utc(date);
            let timezone = store.getters.user.timezone;
            return Vue.moment(utc).tz(timezone);
        }
        return null;
    }),

    formatDateTime: Vue.filter('formatDateTime', date => {
        if (date !== null) {
            let utc = Vue.moment.utc(date);
            let timezone = store.getters.user.timezone;

            // return Vue.moment(date).format('L LT');
            return Vue.moment(utc).tz(timezone).format('L LT');
        }
        return null;
    }),

    formatTime: Vue.filter('formatTime', date => {
        if (date !== null) {
            return Vue.moment(date).format('LT');
        }
        return null;
    }),

    formatLTTime: Vue.filter('formatLTTime', time => {
        if (time !== null) {
            return Vue.moment(time, 'HH:mm:ss').format('LT');
        }
        return null;
    }),

    formatDayString: Vue.filter('formatDayString', date => {
        if (date !== null) {
            return (
                Vue.moment(date).format('dddd').charAt(0).toUpperCase() +
                Vue.moment(date).format('dddd').slice(1)
            );
        }
        return null;
    }),

    formatDayInt: Vue.filter('formatDayInt', date => {
        if (date !== null) {
            return Vue.moment(date).format('D');
        }
        return null;
    }),

    formatMonth: Vue.filter('formatMonth', date => {
        if (date !== null) {
            return Vue.moment(date).format('MMMM YYYY');
        }
        return null;
    }),

    truncate: Vue.filter('truncate', (value, length) => {
        length = length || 15;
        if (!value || typeof value !== 'string') {
            return '';
        }
        if (value.length <= length) {
            return value;
        }
        return value.substring(0, length) + '...';
    }),

    msgBodyRegex: Vue.filter('msgBodyRegex', value => {
        return value.replace(/<\/?[^>]+>|(&nbsp;)/gi, ' ');
    }),

    round: Vue.filter('round', (value, decimals) => {
        if (value > 0 && value !== null) {
            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
        }
        return 0;
    }),

    toLower: Vue.filter('toLower', value => {
        return value.toLowerCase().trim();
    }),

    toUpper: Vue.filter('toUpper', value => {
        return value.toUpperCase().trim();
    }),

    capitalize: Vue.filter('capitalize', value => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }),

    valueOrDash: Vue.filter('valueOrDash', value => {
        if (!value) {
            return '-';
        }
        return value;
    }),

    searchHighlight: Vue.filter('searchHighlight', (givenWord, searchWord) => {
        if(!givenWord) {
            return;
        }

        let given = givenWord.toLowerCase();
        let searching = searchWord.toLowerCase();
        let beginning = given.indexOf(searching);

        if(beginning > -1) {
            let ending = beginning + searching.length;
            let splitBeginning = givenWord.substring(0, beginning);
            let splitSearch = givenWord.substring(beginning, ending);
            let splitEnding = givenWord.substring(ending, givenWord.length);

            return `${splitBeginning}<span class="e3-search-highlight">${splitSearch}</span>${splitEnding}`;
        } else {
            return givenWord;
        }
    }),
    striphtml: Vue.filter('striphtml', function(value) {
        let div = document.createElement('div');
        div.innerHTML = value;
        let text = div.textContent || div.innerText || '';
        return text;
    }),

    formatCalendarDate: Vue.filter('formatCalendarDate', function(date) {
        const timezone = store.getters.user.timezone;
        if (date !== null) {
            let calendarDate = Vue.moment.utc(date).tz(timezone).calendar({
                lastDay: 'D. MMM',
                sameDay: 'LT',
                nextDay: 'D. MMM',
                lastWeek: 'D. MMM',
                nextWeek: 'D. MMM',
                sameElse: 'D. MMM',
            });
            return calendarDate;
        }
        return null;
    }),

    formatAge: Vue.filter('formatAge', function (date) {
        if (date !== null) {
            return Vue.moment().diff(date, 'years');
        }
        return null;
    })
};

export function formatTs(value) {
    return moment.unix(Number(value)).format('ddd, MMM D, YYYY HH:mm A');
}

// Get human readable "time elapsed" from UNIX timestamp
export function formatTsElapsed(value) {
    return moment.unix(Number(value)).from();
}

export function formatDate(date) {
    if (date !== null) {
        return moment(date).format('L');
    }
    return null;
}

export function formatFullYear(date) {
    if (date !== null) {
        return moment(date).format('YYYY');
    }
    return null;
}

export function formatStartOfWeek(date) {
    if (date !== null) {
        return moment(date).startOf('isoWeek').format('DD.MM.YYYY');
    }
    return null;
}

export function formatEndOfWeek(date) {
    if (date !== null) {
        return moment(date).endOf('isoWeek').format('DD.MM.YYYY');
    }
    return null;
}

export function formatDateWithDayMonthName(date) {
    if (date !== null) {
        return moment(date).format('dddd, D MMMM YYYY');
    }
    return null;
}

export function formatDateWithMonth(date) {
    if (date !== null) {
        return moment(date).format('DD.MM');
    }
}

export function formatFullMonthDate(date) {
    if (date !== null) {
        return moment(date).format('LL');
    }
    return null;
}

export function formatUtcDate(date) {
    if (date !== null) {
        let utc = moment.utc(date);
        let timezone = store.getters.user.timezone;
        return moment(utc).tz(timezone).format('DD.MM.YYYY');
    }
    return null;
}

export function convertFromUtc(date) {
    if (date !== null) {
        let utc = moment.utc(date);
        let timezone = store.getters.user.timezone;
        return moment(utc).tz(timezone);
    }
    return null;
}

export function formatDateTime(date) {
    if (date !== null) {
        let utc = moment.utc(date);
        let timezone = store.getters.user.timezone;

        // return Vue.moment(date).format('L LT');
        return moment(utc).tz(timezone).format('L LT');
    }
    return null;
}

export function formatTime(date) {
    if (date !== null) {
        return moment(date).format('LT');
    }
    return null;
}

export function formatLTTime(time) {
    if (time !== null) {
        return moment(time, 'HH:mm:ss').format('LT');
    }
    return null;
}

export function formatDayString(date) {
    if (date !== null) {
        return (
            moment(date).format('dddd').charAt(0).toUpperCase() +
            moment(date).format('dddd').slice(1)
        );
    }
    return null;
}

export function formatDayInt(date) {
    if (date !== null) {
        return moment(date).format('D');
    }
    return null;
}

export function formatMonth(date) {
    if (date !== null) {
        return moment(date).format('MMMM YYYY');
    }
    return null;
}

export function truncate(value, length) {
    length = length || 15;
    if (!value || typeof value !== 'string') {
        return '';
    }
    if (value.length <= length) {
        return value;
    }
    return value.substring(0, length) + '...';
}

export function stripHtmlTags(value) {
    if (!value) {
        return '';
    }
    return value.replace(/<\/?[^>]+>|(&nbsp;)/gi, ' ');
}

export function round(value, decimals) {
    if (value > 0 && value !== null) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }
    return 0;
}

export function toLower(value) {
    return value.toLowerCase().trim();
}

export function toUpper(value) {
    return value.toUpperCase().trim();
}

export function capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function valueOrDash(value) {
    if (!value) {
        return '-';
    }
    return value;
}

export function searchHighlight(givenWord, searchWord) {
    if(!givenWord) {
        return;
    }

    let given = givenWord.toLowerCase();
    let searching = searchWord.toLowerCase();
    let beginning = given.indexOf(searching);

    if(beginning > -1) {
        let ending = beginning + searching.length;
        let splitBeginning = givenWord.substring(0, beginning);
        let splitSearch = givenWord.substring(beginning, ending);
        let splitEnding = givenWord.substring(ending, givenWord.length);

        return `${splitBeginning}<span class="e3-search-highlight">${splitSearch}</span>${splitEnding}`;
    } else {
        return givenWord;
    }
}

export function striphtml(value) {
    let div = document.createElement('div');
    div.innerHTML = value;
    let text = div.textContent || div.innerText || '';
    return text;
}

export function formatCalendarDate(date) {
    const timezone = store.getters.user.timezone;
    if (date !== null) {
        let calendarDate = moment.utc(date).tz(timezone).calendar({
            lastDay: 'D. MMM',
            sameDay: 'LT',
            nextDay: 'D. MMM',
            lastWeek: 'D. MMM',
            nextWeek: 'D. MMM',
            sameElse: 'D. MMM',
        });
        return calendarDate;
    }
    return null;
}

export function formatAge(date) {
    if (date !== null) {
        return moment().diff(date, 'years');
    }
    return null;
}

export function sanitizeBasicEditorHtml(html) {
    if (!html) {
        return '';
    }

    return xss(html, basicEditorXSSOptions);
}
