import moment from 'moment';

function isConsentTimeExpired(inConsentTime) {
    const consentTime = moment(inConsentTime);
    const pastDate = moment().subtract(6, 'months');

    return consentTime.isBefore(pastDate);
}

export function saveCookiePreferences(preferences) {
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
}

export function getCookiePreferences() {
    const storedPreferences = localStorage.getItem('cookiePreferences');
    return storedPreferences ? JSON.parse(storedPreferences) : null;
}

export function hasCookieAccepted(cookieName) {
    const cookiePreferences = getCookiePreferences();

    if (
        !cookiePreferences ||
        !cookiePreferences.consentTime ||
        isConsentTimeExpired(cookiePreferences.consentTime)
    ) return false;

    if(cookiePreferences[cookieName]) {
        return cookiePreferences[cookieName];
    }

    return false;
}
