<template>
    <div class="position-relative" @mouseleave="checkClosePop()">
        <div class="e3-folder-container d-flex flex-lg-column align-items-center" :title="file.name">
            <ImageThumbnail
                v-if="file.mime_type === 'image/jpeg' && file.thumbnails && file.thumbnails.small"
                :url="buildUrl()"/>
            <template v-else-if="videoMimeTypes.includes(file.mime_type)">
                <ImageThumbnail
                    :class="{'e3-video-player' : hasThumbnail()}"
                    :url="checkThumbnail()"/>
            </template>
            <div v-else class="e3-folder-box d-flex flex-lg-column align-items-center">
                <div @click="download(file)">
                    {{ truncate(file.name, 20) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {truncate} from '@/filters';
import {mapGetters} from 'vuex';
import DocumentService from '@services/document.service';
import FileService from '@services/file.service';

import {downloadS3File} from '@services/helper.service';
import ImageThumbnail from '@components/image-thumbnail/ImageThumbnail.vue';

export default {
    props: ['file', 'type'],
    data() {
        return {
            videoMimeTypes: ['video/mp4', 'video/quicktime']
        };
    },
    computed: {
        ...mapGetters(['activeKindergartenId'])
    },
    components: {
        ImageThumbnail
    },
    methods: {
        truncate, // TODO: Vue3 remove
        checkClosePop() {
            if (!this.keepActive) {
                this.visible = false;
            }
        },
        hidePop() {
            this.keepActive = false;
            this.visible = false;
        },
        isFolderNew(updated_at) {
            let createdNow = this.$moment().diff(this.$moment(updated_at), 'seconds') === 0;
            if (createdNow) {
                this.newCreated = true;
            }
            return createdNow;
        },
        unHighlight() {
            if (this.newCreated) {
                this.newCreated = false;
            }
        },
        download(file) {
            if (file.url) {
                return downloadS3File(file.url, this.file, () => {
                    this.loading = false;
                    this.$emit('loadingStatus', null);
                });
            }

            if (this.type === 'document') {
                DocumentService.downloadDocument(this.activeKindergartenId, file).finally(() => {
                    this.loading = false;
                    this.$emit('loadingStatus', null);
                });
            } else {
                FileService.getFile(file, this.type).finally(() => {
                    this.loading = false;
                    this.$emit('loadingStatus', null);
                });
            }
        },
        buildUrl() {
            if (this.file.thumbnails.small.url) {
                return this.file.thumbnails.small.url;
            }

            let apiBase = process.env.NODE_ENV !== 'development' ?
                `https://api.${window.location.hostname}` :
                `${process.env.VUE_APP_API_URL}`;

            if (this.type === 'child') {
                return apiBase + `/api/kindergartens/${this.activeKindergartenId}/children/${this.$route.params.child_id}/files_thumbs/${this.file.thumbnails.small.filename}`;
            } else {
                return apiBase + `/api/kindergartens/${this.activeKindergartenId}/files/${this.type}_thumbs/${this.file.thumbnails.small.filename}`;
            }
        },
        hasThumbnail() {
            return this.file.thumbnails.small;
        },
        checkThumbnail() {
            if (this.hasThumbnail()) {
                return this.buildUrl();
            }
            return '/images/image_placeholder_video_thumbnail.jpg';
        }
    }
};
</script>
