<template>
    <div :class="{ 'form-group': isLabelSlot }">
        <label v-if="isLabelSlot" :for="id" :class="labelClass">
            <slot name="label"/>
            <template v-if="optional">
                - <small>{{ $t('Optional') }}</small>
            </template>
        </label>
        <div :class="inputBlockClass">
            <textarea :id="id"
                      v-model="val"
                      class="form-control"
                      :class="[{ 'is-invalid': validation && validation.$error }, givenClass]"
                      :type="type || 'text'"
                      :disabled="disabled"
                      :placeholder="placeholder"
                      :maxlength="maxlength"
                      @change="onChange"
                      @blur="onBlur"/>
            <ValitationMessages :validation="validation" :class="valitationClasses"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ETextarea',
    props: {
        id: String,
        value: [String, Number],
        validation: Object,
        type: String,
        disabled: Boolean,
        placeholder: String,
        optional: Boolean,
        inline: Boolean,
        valitationClasses: {
            type: String,
            default: null
        },
        givenClass: String,
        maxlength: {
            type: Number,
            default: null
        }
    },
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        labelClass() {
            if (this.inline) {
                return 'col-md-5 col-form-label text-md-right text-sm-left';
            }

            return '';
        },
        inputBlockClass() {
            if (this.inline) {
                return 'col-md-7';
            }

            return '';
        },
        isLabelSlot() {
            return !!this.$slots.label;
        }
    },
    methods: {
        onBlur(e) {
            this.$emit('blur', e);
        },
        onChange(e) {
            this.$emit('change', e);
        },
    },
};
</script>
