<template>
    <div>
        <template v-if="files.length || searchLoading">
            <h3 v-if="!hideTitle" class="h4 d-flex align-items-center mb-2 mt-4">
                <span class="mr-2">{{ type === 'document' ? $t("Files") : $t("Images and videos") }}</span>
                <template v-if="customFilesCount">
                    <small v-if="filesCount !== null">({{ filesCount }})</small>
                </template>
                <small v-else>({{ files.length }})</small>
            </h3>
            <template>
                <slot/>
            </template>
            <div v-if="!searchLoading" class="row d-flex flex-wrap mx-n1">
                <div v-for="file in visibleFiles" :key="file.id" class="px-1 mb-2 file-area" @click="openModal(file)">
                    <FileContainer :file="file" :type="type"/>
                </div>
                <div v-for="file in invisibleFiles" :key="file.id" class="px-1 mb-2 file-area e3-file-placeholder">
                    <div class="d-flex justify-content-center align-items-center placeholder-image">
                        <SvgIcon icon="mdi-image" size="56"/>
                    </div>
                </div>
            </div>
            <ELoader v-else class="p-0"/>
        </template>
        <EmptyListMessage v-else-if="searching" class="border bg-white rounded p-2 mt-3">
            {{ $t('No documents found') }}
        </EmptyListMessage>
        <ViewGalleryFileModal v-if="showGalleryFileModal"/>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import FileContainer from '@views/folder/components/FileContainer.vue';
import ViewGalleryFileModal from '@views/gallery/modals/ViewFileModal.vue';
import {eventBus} from '@/main';

export default {
    props: {
        files: Array,
        filesCount: Number,
        customFilesCount: Boolean,
        type: String,
        searchLoading: Boolean,
        searchValue: String,
        searching: Boolean,
        hideTitle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newCreated: false,
            offsetTop: 0,
            shownFiles: 25,
        };
    },
    computed: {
        visibleFiles() {
            return this.files.slice(0, this.shownFiles);
        },
        invisibleFiles() {
            return this.files.slice(this.shownFiles, this.files.length);
        },
        fileCount() {
            return this.files.length;
        },
        showGalleryFileModal() {
            return ['gallery', 'diary', 'child', 'gallery_builtin_child', 'gallery_builtin_diary'].includes(this.type);
        },
    },
    watch: {
        offsetTop () {
            if (this.shownFiles < this.fileCount) {
                this.scrollCallBack();
            }
        }
    },
    methods: {
        openModal(file) {
            let args = {
                file: file,
                type: this.type,
                ...(this.type === 'child' && { files: this.files })
            };
            eventBus.$emit('show-view-media-file-modal', args);
        },
        isElementInWindow(element) {
            let item = element.getBoundingClientRect();

            return (
                item.left >= 0 &&
                item.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                item.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        scrollCallBack() {
            let placeholders = document.querySelectorAll('.placeholder-image');

            for (let i = 0; i < placeholders.length; i++) {
                if (this.isElementInWindow(placeholders[i]) && this.shownFiles < this.fileCount) {
                    this.shownFiles++;
                }
            }
        },
        handleScroll: debounce(function() {
            this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
        }, 300)
    },
    components: {
        FileContainer,
        ViewGalleryFileModal
    },
    created() {
        document.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.scrollCallBack();
    },
    beforeDestroy(){
        document.removeEventListener('scroll', this.handleScroll);
    }
};
</script>

<style lang="scss" scoped>
@import "@common/styles/variables/_variables.scss";
@import "@common/styles/mixins/_filters.scss";

.e3-file-placeholder {
    position: relative;
    width: 100%;
    &::before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .placeholder-image {
        .e3-svg-mdi {
            @include text-secondary-filter;
        }
        position: absolute;
        top: 0%;
        bottom: 0%;
        left: 0.25rem;
        right: 0.25rem;
        height: 100%;
        border-radius: 0.33rem;
        background-color: $gray-200;
    }
}
</style>
