import {
    RESET_MESSAGE_STATE,
    ADD_SENT_MESSAGE,
    DELETE_MESSAGE,
    GET_AVAILABLE_MESSAGE_TARGETGROUPS,
    GET_MESSAGES,
    REMOVE_DELETED_MESSAGE,
    RESTORE_MESSAGE,
    SEND_MESSAGE,
    SET_AVAILABLE_MESSAGE_TARGETGROUPS,
    SET_DELETED_MESSAGES,
    SET_MESSAGE_DELETED,
    SET_MESSAGE_SEEN,
    SET_MESSAGES,
    SET_SENT_MESSAGES,
    UPDATE_MESSAGE_SEEN,
    SET_MESSAGE_CURRENT_PAGE,
    SET_MESSAGE_PER_PAGE,
    GET_RECENT_MESSAGES,
    SET_RECENT_MESSAGES,
    SET_UNREAD_MESSAGES_COUNT,
    DELETE_SELECTED_MESSAGES,
    GET_MESSAGE_DRAFTS,
    SET_MESSAGE_DRAFTS,
    DELETE_MESSAGE_DRAFT,
    REMOVE_MESSAGE_DRAFT,
    SET_MESSAGE_DRAFT,
    GET_MESSAGE_DRAFT,
    GET_DRAFT,
    UPLOAD_MESSAGE_DRAFT_ATTACHMENT,
    ADD_MESSAGE_DRAFT_ATTACHMENT,
    DELETE_MESSAGE_DRAFT_ATTACHMENT,
    REMOVE_MESSAGE_DRAFT_ATTACHMENT,
    UPDATE_MESSAGE_DRAFT,
    CREATE_MESSAGE_DRAFT,
    SET_DRAFT_STATUS,
    ADD_MESSAGE_DRAFT,
    DELETE_SELECTED_MESSAGE_DRAFTS,
    SEND_OLD_MESSAGE
} from './actions';
import Vue from 'vue';
import MessageService from '@services/message.service';

const getDefaultState = () => {
    return {
        messages: {data: []},
        sent_messages:  {data: []},
        deleted_messages:  {data: []},
        messageDrafts: {data: []},
        recent_messages: [],
        unread_messages_count: 0,
        target_groups: [],
        message_types: {
            sent: 'sent',
            received: 'received',
            reply: 'reply',
            replyAll: 'reply-all',
            forward: 'forward',
            updated: 'updated',
            deleted: 'deleted',
            newMessage: 'new-message',
            draft: 'draft',
            message: 'message',
            messages: 'messages',
            newAnnouncement: 'new-announcement',
            announcement: 'announcement',
            announcements: 'announcements'
        },
        message_per_page: 50,
        message_current_page: 1,
        draft: {
            id: null,
            subject: '',
            body: '',
            targetGroups: [],
            files: []
        },
        draftStatus: ''
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_MESSAGE_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_MESSAGES](state, messages) {
        state.messages = messages;
    },
    [SET_DELETED_MESSAGES](state, messages) {
        state.deleted_messages = messages;
    },
    [SET_SENT_MESSAGES](state, messages) {
        state.sent_messages = messages;
    },
    [SET_MESSAGE_SEEN](state, message) {
        let messageIndex = state.messages.data.findIndex(msg => msg.id === message.id);
        if(messageIndex !== -1) {
            Vue.set(state.messages.data, messageIndex, message);
        }
        if(messageIndex === -1) {
            let deletedIndex = state.deleted_messages.data?.findIndex(msg => msg.id === message.id);
            if(deletedIndex !== -1) {
                Vue.set(state.deleted_messages.data, deletedIndex, message);
            }
        }

        let recentIndex = state.recent_messages.findIndex(msg => msg.id === message.id);
        if(recentIndex !== -1) {
            Vue.set(state.recent_messages, recentIndex, message);
        }

    },
    [SET_MESSAGE_DELETED](state, id) {
        let index = state.messages.data.findIndex(msg => msg.id === id);
        if(index !== -1) {
            let message = state.messages.data.find(msg => msg.id === id);
            state.messages.data.splice(index, 1);
            state.deleted_messages.data.unshift(message);
        }

        let recentIndex = state.recent_messages.findIndex(msg => msg.id === id);
        if(recentIndex !== -1) {
            const message = state.recent_messages.find(msg => msg.id === id);
            if(!message.receiver.seen) {
                state.unread_messages_count = state.unread_messages_count ? state.unread_messages_count - 1 : 0;
            }

            state.recent_messages.splice(recentIndex, 1);

        }
    },
    [SET_AVAILABLE_MESSAGE_TARGETGROUPS](state, data) {
        state.target_groups = data;
    },
    [ADD_SENT_MESSAGE](state, message) {
        state.sent_messages.data?.unshift(message);
    },
    [REMOVE_DELETED_MESSAGE](state, id) {
        let index = state.deleted_messages.data.findIndex(msg => msg.id === id);
        state.deleted_messages.data.splice(index, 1);
    },
    [SET_MESSAGE_CURRENT_PAGE](state, value) {
        state.message_current_page = value;
    },
    [SET_MESSAGE_PER_PAGE](state, value) {
        state.message_per_page = value;
    },
    [SET_RECENT_MESSAGES](state, messages) {
        state.recent_messages = messages;
    },
    [SET_UNREAD_MESSAGES_COUNT](state, count) {
        state.unread_messages_count = count > 0 ? count : 0;
    },
    [SET_MESSAGE_DRAFTS] (state, drafts) {
        state.messageDrafts = drafts;
    },
    [ADD_MESSAGE_DRAFT](state, draft) {
        state.messageDrafts.data.unshift(draft);
    },
    [REMOVE_MESSAGE_DRAFT](state, draftId) {
        state.messageDrafts.data = state.messageDrafts.data.filter(draft => draft.id !== draftId);
    },
    [SET_MESSAGE_DRAFT] (state, draft) {
        if(draft.id) {
            //Set drafts list data
            let index = state.messageDrafts.data.findIndex(d => d.id === draft.id);
            if(index !== -1) {
                let listDraft = state.messageDrafts.data.find(d => d.id === draft.id);
                listDraft.subject = draft.subject;
                Vue.set(state.messageDrafts.data, index, listDraft);
            }
        }

        //Set modal form data
        state.draft.id = draft.id;
        state.draft.subject = draft.subject;
        state.draft.body = draft.body;
        state.draft.targetGroups = draft.receivers.map(r => {
            return {
                id: r.identifiable_id,
                type: r.identifiable_type,
                name: r.name,
                unique_id: r.identifiable_id+'_'+r.identifiable_type
            };
        });
        state.draft.files = draft.files.map(f => {
            return {
                id: f.id,
                type: f.mime_type,
                name: f.name,
                filename: f.filename,
                size: f.size
            };
        });
    },
    [ADD_MESSAGE_DRAFT_ATTACHMENT] (state, file) {
        state.draft.files.push(file);
        let draft = state.messageDrafts.data.find(draft => draft.id === state.draft.id);
        if(draft) {
            draft.files_count = draft.files_count+1;
            let index = state.messageDrafts.data.findIndex(draft => draft.id === state.draft.id);
            if(index !== -1) {
                Vue.set(state.messageDrafts.data, index, draft);
            }
        }
    },
    [REMOVE_MESSAGE_DRAFT_ATTACHMENT](state, index) {
        state.draft.files.splice(index, 1);
        let draft = state.messageDrafts.data.find(draft => draft.id === state.draft.id);
        if(draft) {
            draft.files_count = draft.files_count-1;
            let index = state.messageDrafts.data.findIndex(draft => draft.id === state.draft.id);
            if(index !== -1) {
                Vue.set(state.messageDrafts.data, index, draft);
            }
        }

    },
    [SET_DRAFT_STATUS](state, status) {
        state.draftStatus = status;
    },
};
const actions = {
    [GET_MESSAGES]({ commit, getters }, {type, queryObj}) {
        return MessageService.messages(type, queryObj).then(res => {
            if (type === getters.messageTypes.received) {
                commit(SET_MESSAGES, res);
            } else if (type === getters.messageTypes.sent) {
                commit(SET_SENT_MESSAGES, res);
            } else if (type === getters.messageTypes.deleted) {
                commit(SET_DELETED_MESSAGES, res);
            }
        });
    },
    [UPDATE_MESSAGE_SEEN]({ commit, getters }, data) {
        return MessageService.update(data).then(res => {
            commit(SET_MESSAGE_SEEN, res);
            if(!data.deleted) {
                commit(SET_UNREAD_MESSAGES_COUNT, getters.unreadMessages - 1);
            }
        });
    },
    [DELETE_MESSAGE]({ commit, getters }, messageId) {
        return MessageService.delete(messageId).then(res => {
            if (res.status === getters.messageTypes.updated) {
                commit(SET_MESSAGE_DELETED, messageId);
            } else if (res.status === getters.messageTypes.deleted) {
                commit(REMOVE_DELETED_MESSAGE, messageId);
            }
        });
    },
    [GET_AVAILABLE_MESSAGE_TARGETGROUPS]({ commit }) {
        return MessageService.getUserTargetGroups().then(res => {
            commit(SET_AVAILABLE_MESSAGE_TARGETGROUPS, res);
        });
    },
    [SEND_MESSAGE]({ commit, getters}, data) {
        let url = undefined;
        if(getters.activeKindergartenId) {
            url = `kindergartens/${getters.activeKindergartenId}`;
        } else if(getters.activeOrganizationId) {
            url = `organizations/${getters.activeOrganizationId}`;
         }
        if(url !== undefined) {
            return MessageService.sendMessage(url, data.draftId, data.draft).then(res => {
                if(res.error) {
                    commit(SET_MESSAGE_DRAFT, res.draft);
                    return res;
                } else {
                    commit(ADD_SENT_MESSAGE, res);
                    commit(REMOVE_MESSAGE_DRAFT, data.draftId);
                }
            });
        }
    },
    [SEND_OLD_MESSAGE]({ commit, getters}, data) {
        let url = undefined;
        if(getters.activeKindergartenId) {
            url = `kindergartens/${getters.activeKindergartenId}`;
        } else if(getters.activeOrganizationId) {
            url = `organizations/${getters.activeOrganizationId}`;
        }
        if(url !== undefined) {
            return MessageService.send(url, data).then(res => {
                commit(ADD_SENT_MESSAGE, res);
            });
        }
    },
    [RESTORE_MESSAGE]({ commit }, data) {
        return MessageService.update(data).then(() => {
            commit(REMOVE_DELETED_MESSAGE, data.id);
        });
    },
    [GET_RECENT_MESSAGES]({commit}) {
        return MessageService.getRecent().then(res => {
            commit(SET_RECENT_MESSAGES, res.messages);
            commit(SET_UNREAD_MESSAGES_COUNT, res.unread_count);
        });
    },
    [DELETE_SELECTED_MESSAGES]({ commit, getters }, {status, selectedMessages}) {
        return MessageService.deleteSelected(selectedMessages).then(res => {
            if (status === getters.messageTypes.updated) {
                res.forEach(id => {
                    commit(SET_MESSAGE_DELETED, id);
                });
            } else if (status == getters.messageTypes.deleted) {
                res.forEach(id => {
                    commit(REMOVE_DELETED_MESSAGE, id);
                });
            }
        });
    },
    [GET_MESSAGE_DRAFTS]({ commit}, queryObj) {
        return MessageService.messageDrafts(queryObj).then(res => {
            commit(SET_MESSAGE_DRAFTS, res);
        });
    },
    [GET_MESSAGE_DRAFT]({commit}, messageId) {
        return MessageService.messageDraft(messageId).then(res => {
            if(res.id) {
                commit(SET_MESSAGE_DRAFT, res);
            }
        });
    },
    [GET_DRAFT]({commit}, draftId) {
        commit(SET_MESSAGE_DRAFT, {
            id: null,
            subject: '',
            body: '<p></p>',
            receivers: [],
            files: []
        });
        return MessageService.getDraft(draftId).then(res => {
            if(res.id) {
                commit(SET_MESSAGE_DRAFT, res);
            }
        });
    },
    [CREATE_MESSAGE_DRAFT]({commit}, data) {
        commit(SET_DRAFT_STATUS, 'saving');
        return MessageService.createDraft(data).then(res => {
            commit(SET_DRAFT_STATUS, 'saved');
            commit(SET_MESSAGE_DRAFT, res);
            return res;
        }).catch(() => {
            commit(SET_DRAFT_STATUS, 'error');
        });
    },
    [DELETE_MESSAGE_DRAFT]({commit}, {draftId, queryObj}) {
        return MessageService.deleteDraft(draftId, queryObj).then(res => {
            commit(SET_MESSAGE_DRAFTS, res);
        });
    },
    [UPDATE_MESSAGE_DRAFT] ({commit}, {id, data}) {
        commit(SET_DRAFT_STATUS, 'saving');
        return MessageService.saveDraft(id, data).then(res => {
            commit(SET_MESSAGE_DRAFT, res);
            commit(SET_DRAFT_STATUS, 'saved');
        }).catch(() => {
            commit(SET_DRAFT_STATUS, 'error');
        });
    },
    [UPLOAD_MESSAGE_DRAFT_ATTACHMENT] ({commit, getters}, {id, data}) {
        let url = undefined;
        if(getters.activeKindergartenId) {
            url = `kindergartens/${getters.activeKindergartenId}`;
        } else if(getters.activeOrganizationId) {
            url = `organizations/${getters.activeOrganizationId}`;
        }
        if(url !== 'undefined' && id) {
            return MessageService.uploadAttachment(url, id, data).then(res => {
                commit(ADD_MESSAGE_DRAFT_ATTACHMENT, res);
                return res;
            });
        }

    },
    [DELETE_MESSAGE_DRAFT_ATTACHMENT]({commit}, {draftId, index, filename}) {
        commit(SET_DRAFT_STATUS, 'saving');
       return MessageService.deleteDraftFile(draftId, filename).then(() => {
           commit(REMOVE_MESSAGE_DRAFT_ATTACHMENT, index);
           commit(SET_DRAFT_STATUS, 'saved');
        }).catch(() => {
           commit(SET_DRAFT_STATUS, 'error');
       });
    },
    [DELETE_SELECTED_MESSAGE_DRAFTS]({commit}, data) {
        return MessageService.deleteSelectedDrafts(data).then(res => {
            commit(SET_MESSAGE_DRAFTS, res);
        });
    }
};
const getters = {
    recentMessages: state => {
        return state.recent_messages;
    },
    messageDrafts: state => {
        return state.messageDrafts;
    },
    messages: state => {
        return state.messages;
    },
    deletedMessages: state => {
        return state.deleted_messages;
    },
    sentMessages: state => {
        return state.sent_messages;
    },
    messageTypes: state => {
        return state.message_types;
    },
    messageTargetGroups: state => {
        return state.target_groups;
    },
    getReceivedMessageById: state => id => {
        return state.messages.data?.find(message => message.id === parseInt(id));
    },
    getDeletedMessageById: state => id => {
        return state.deleted_messages.data?.find(
            message => message.id === parseInt(id)
        );
    },
    unreadMessages: state => {
        return state.unread_messages_count;
    },
    tabMessages: state => tab => {
        if (tab === state.message_types.received) {
            return state.messages;
        } else if (tab === state.message_types.sent) {
            return state.sent_messages;
        } else if (tab === state.message_types.deleted) {
            return state.deleted_messages;
        }
    },
    perPageMessages: state => {
        return state.message_per_page;
    },
    currentMessagePage: state => {
        return state.message_current_page;
    },
    draft: state => state.draft,
    draftStatus: state => state.draftStatus
};

export default {
    state,
    mutations,
    actions,
    getters
};
