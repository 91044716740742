import Vue from 'vue';
import CourseService from '@services/course.service';
import KindergartenService from '@services/kindergarten.service';
import {
    SET_COURSE_CHILDREN,
    SET_COURSE_TEACHERS,
    SET_COURSES_SETTINGS,
    ADD_COURSE_TEACHERS,
    REMOVE_COURSE_TEACHER,
    ADD_COURSE_CHILDREN,
    REMOVE_COURSE_CHILD,
    GET_COURSE_CHILDREN,
    GET_COURSE_TEACHERS,
    GET_COURSE_SETTINGS,
    GET_AVAILABLE_CHILDREN,
    SET_AVAILABLE_CHILDREN,
    UPDATE_COURSE_SETTING,
    UPDATE_ALL_COURSE_SETTINGS,
    SET_COURSE_KINDERGARTEN_SETTINGS,
    UPDATE_COURSE_MEALS,
    UPDATE_ALL_COURSE_MEALS,
    UPDATE_COURSE_ABSENCES,
    UPDATE_ALL_COURSE_ABSENCES,
    UPDATE_COURSE_PRESENCES,
    UPDATE_ALL_COURSE_PRESENCES,
    GET_DIARY_STATUSES,
    SET_DIARY_STATUSES,
    ADD_DIARY_STATUS,
    UPDATE_DIARY_STATUS,
    GET_AVAILABLE_SUMMER_CHILDREN,
    UPDATE_COURSE_CHILD,
    UPDATE_COURSE_TEACHER
} from './actions';
import {
    SET_KINDERGARTEN_COURSE_CHILDREN_COUNT,
    SET_KINDERGARTEN_COURSE_TEACHERS
} from '@/store/modules/kindergarten/actions';

const getDefaultState = () => {
    return {
        courseChildren: [],
        courseTeachers: [],
        //opened course settings
        available_children: [],
        settings: [],
        general_settings: [],
        statuses: [],
        message: null
    };
};

const state = getDefaultState();

const mutations = {
    [SET_COURSE_TEACHERS](state, data) {
      state.courseTeachers = data;
    },
    [SET_COURSE_CHILDREN](state, data) {
        state.courseChildren = data;
    },
    [SET_COURSES_SETTINGS](state, data) {
        data.forEach(course => {
            course.absent_statuses.sort((a, b) => a.pivot.order - b.pivot.order);
            course.present_statuses.sort((a, b) => a.pivot.order - b.pivot.order);
        });
        state.settings = data;
    },
    [UPDATE_COURSE_SETTING](state, data) {
        let index = state.settings.findIndex(course => course.id === data.id);
        if (index !== -1) {
            data.absent_statuses.sort((a, b) => a.pivot.order - b.pivot.order);
            data.present_statuses.sort((a, b) => a.pivot.order - b.pivot.order);
            Vue.set(state.settings, index, data);
        }
    },
    [SET_AVAILABLE_CHILDREN](state, children) {
        state.available_children = children;
    },
    [SET_COURSE_KINDERGARTEN_SETTINGS](state, settings) {
        state.general_settings = settings;
    },
    [SET_DIARY_STATUSES](state, statuses) {
        state.statuses = statuses;
    },
    [ADD_DIARY_STATUS](state, status) {
        state.statuses.push(status);
    },
    [UPDATE_DIARY_STATUS](state, data) {
        let index = state.statuses.findIndex(status => status.id === data.id);
        if (index !== -1) {
            Vue.set(state.statuses, index, data);
        }
    },
};

const actions = {
    [ADD_COURSE_CHILDREN]({ commit, getters }, data) {
        return CourseService.addChildren(getters.activeKindergartenId, data.id, {
            children: data.children
        }).then(res => {
            commit(SET_COURSE_CHILDREN, res.children);
            commit(SET_KINDERGARTEN_COURSE_CHILDREN_COUNT, {courseId: data.id, counts: res});
        });
    },
    [UPDATE_COURSE_CHILD]({commit, getters}, data) {
      return CourseService.updateChild(getters.activeKindergartenId, data.courseId, data).then(res => {
          commit(SET_COURSE_CHILDREN, res.children);
          commit(SET_KINDERGARTEN_COURSE_CHILDREN_COUNT, {courseId: data.courseId, counts: res});
      });
    },
    [REMOVE_COURSE_CHILD]({ commit, getters }, data) {
        return CourseService.removeChild(getters.activeKindergartenId, data.courseId, data.courseChildId).then(res => {
            commit(SET_COURSE_CHILDREN, res.children);
            commit(SET_KINDERGARTEN_COURSE_CHILDREN_COUNT, {courseId: data.courseId, counts: res});
        });
    },
    [ADD_COURSE_TEACHERS]({ commit, getters }, data) {
        return CourseService.addTeachers(getters.activeKindergartenId, data.id, {teachers: data.teachers})
            .then(res => {
                commit(SET_COURSE_TEACHERS, res.teachers);
                commit(SET_KINDERGARTEN_COURSE_TEACHERS, {courseId: data.id, teachers: res.current_teachers});
            });
    },
    [UPDATE_COURSE_TEACHER]({commit, getters}, data) {
        return CourseService.updateTeacher(getters.activeKindergartenId, data.courseId, data).then(res => {
            commit(SET_COURSE_TEACHERS, res.teachers);
            commit(SET_KINDERGARTEN_COURSE_TEACHERS, {courseId: data.courseId, teachers: res.current_teachers});
        });
    },
    [REMOVE_COURSE_TEACHER]({ commit, getters }, data) {
        return CourseService.removeTeacher(getters.activeKindergartenId, data.courseId, data.course_teacher_id).then(res => {
            commit(SET_COURSE_TEACHERS, res.teachers);
            commit(SET_KINDERGARTEN_COURSE_TEACHERS, {courseId: data.courseId, teachers: res.current_teachers});
        });
    },
    [GET_COURSE_CHILDREN]({commit, getters}, courseId) {
        commit(SET_COURSE_CHILDREN, []);
        return CourseService.getCourseChildren(getters.activeKindergartenId, courseId).then(res => {
            commit(SET_COURSE_CHILDREN, res);
            return res;
        });
    },
    [GET_COURSE_TEACHERS]({commit, getters}, courseId) {
        commit(SET_COURSE_TEACHERS, []);
        return CourseService.getCourseTeachers(getters.activeKindergartenId, courseId).then(res => {
            commit(SET_COURSE_TEACHERS, res);
        });
    },
    [GET_COURSE_SETTINGS]({commit, getters}, type) {
        return CourseService.getCourseSettings(getters.activeKindergartenId, type).then(res => {
            commit(SET_COURSES_SETTINGS, res.courses);
            commit(SET_COURSE_KINDERGARTEN_SETTINGS, res.settings);
        });
    },
    [UPDATE_COURSE_SETTING]({commit, getters}, {courseId, form}) {
        return CourseService.updateCourseSetting(getters.activeKindergartenId, courseId, form).then(res => {
            commit(UPDATE_COURSE_SETTING, res.course);
            commit(SET_COURSE_KINDERGARTEN_SETTINGS, res.settings);
        });
    },
    [UPDATE_ALL_COURSE_SETTINGS]({commit, getters}, data) {
        return CourseService.updateAllCourseSettings(getters.activeKindergartenId, data).then(res => {
            commit(SET_COURSES_SETTINGS, res.courses);
            commit(SET_COURSE_KINDERGARTEN_SETTINGS, res.settings);
        });
    },
    [UPDATE_COURSE_MEALS]({commit, getters}, {courseId, meals}) {
        return CourseService.updateCourseMeals(getters.activeKindergartenId, courseId, meals).then(res => {
            commit(UPDATE_COURSE_SETTING, res);
        });
    },
    [UPDATE_ALL_COURSE_MEALS]({commit, getters}, data) {
        return CourseService.updateAllCourseMeals(getters.activeKindergartenId, data).then(res => {
            commit(SET_COURSES_SETTINGS, res);
        });
    },
    [UPDATE_COURSE_ABSENCES]({commit, getters}, {courseId, absences}) {
        return CourseService.updateCourseAbsentStatuses(getters.activeKindergartenId, courseId, absences).then(res => {
            commit(UPDATE_COURSE_SETTING, res.course);
            commit(SET_COURSE_KINDERGARTEN_SETTINGS, res.settings);
        });
    },
    [UPDATE_ALL_COURSE_ABSENCES]({commit, getters}, data) {
        return CourseService.updateAllCoursesAbsentStatuses(getters.activeKindergartenId, data).then(res => {
            commit(SET_COURSES_SETTINGS, res.courses);
            commit(SET_COURSE_KINDERGARTEN_SETTINGS, res.settings);
        });
    },
    [UPDATE_COURSE_PRESENCES]({commit, getters}, {courseId, statuses}) {
        return CourseService.updateCoursePresentStatuses(getters.activeKindergartenId, courseId, statuses).then(res => {
            commit(UPDATE_COURSE_SETTING, res.course);
            commit(SET_COURSE_KINDERGARTEN_SETTINGS, res.settings);
        });
    },
    [UPDATE_ALL_COURSE_PRESENCES]({commit, getters}, data) {
        return CourseService.updateAllCoursesPresentStatuses(getters.activeKindergartenId, data).then(res => {
            commit(SET_COURSES_SETTINGS, res.courses);
            commit(SET_COURSE_KINDERGARTEN_SETTINGS, res.settings);
        });
    },
    [GET_AVAILABLE_CHILDREN]({commit, getters}, request) {
        commit(SET_AVAILABLE_CHILDREN, []);
        return CourseService.getCourselessChildren(getters.activeKindergartenId, request).then(res => {
            commit(SET_AVAILABLE_CHILDREN, res);
        });
    },
    [GET_AVAILABLE_SUMMER_CHILDREN]({commit, getters}, request) {
        commit(SET_AVAILABLE_CHILDREN, []);
        return CourseService.getCourselessSummerChildren(getters.activeKindergartenId, request).then(res => {
            commit(SET_AVAILABLE_CHILDREN, res);
        });
    },
    [GET_DIARY_STATUSES]({ commit, getters}) {
        if (getters.diarySettingsStatuses.length) {
            return getters.diarySettingsStatuses;
        }
        KindergartenService.getDiaryStatuses(getters.activeKindergartenId).then(res => {
            commit(SET_DIARY_STATUSES, res);
        });
    },
    [ADD_DIARY_STATUS]({ commit, getters}, status) {
        KindergartenService.insertDiaryStatus(getters.activeKindergartenId, status).then(res => {
            commit(ADD_DIARY_STATUS, res);
        });
    },
    [UPDATE_DIARY_STATUS]({ commit, getters}, status) {
        KindergartenService.updateDiaryStatus(getters.activeKindergartenId, status).then(res => {
            commit(UPDATE_DIARY_STATUS, res);
        });
    },
};

const getters = {
    availableChildren: state => state.available_children,
    courseSettings: state => state.settings,
    kindergartenDiarySettings: state => state.general_settings,
    diarySettingsStatuses: state => state.statuses,
    coursePeriodUpdateMessage: state => state.message,
    courseChildren: state => state.courseChildren,
    courseTeachers: state => state.courseTeachers
};

export default {
    state,
    mutations,
    actions,
    getters
};
