import {
    GET_AVAILABLE_SHOP_CATEGORIES,
    SET_AVAILABLE_SHOP_CATEGORIES,
    GET_SUPPLIER_INTEGRATION_CATEGORIES,
    SET_SUPPLIER_INTEGRATION_CATEGORIES,
    SYNC_SUPPLIER_INTEGRATION_CATEGORIES,
    SET_INTERGATION_ERROR,
    GET_SUPPLIER_INTEGRATION_PRICE_LISTS,
    SET_SUPPLIER_INTEGRATION_PRICE_LISTS,
    SET_SELECTED_SUPPLIER_INTEGRATION,
    SAVE_SUPPLIER_INTEGRATION_PRICE_LIST

} from './actions';

import SupplierIntegrationService from '@/common/services/supplier_integration.service';

const getDefaultState = () => {
    return {
        availableCategories: [],
        structuredAvailableCategories: [],
        integrationCategories: [],
        integrationError: null,
        price_lists: []
    };
};

export const supplierIntegrationState = getDefaultState();
export const supplierIntegrationMutations = {
    [SET_AVAILABLE_SHOP_CATEGORIES](state, data) {
        state.availableCategories = data.availableCategories;
        state.structuredAvailableCategories = data.structuredAvailableCategories;
    },
    [SET_SUPPLIER_INTEGRATION_CATEGORIES](state ,data) {
        state.integrationCategories = data;
    },
    [SET_INTERGATION_ERROR](state, error) {
      state.integrationError = error;
    },
    [SET_SUPPLIER_INTEGRATION_PRICE_LISTS](state, data) {
        state.price_lists = data;
    }
};
export const supplierIntegrationActions = {
    [GET_AVAILABLE_SHOP_CATEGORIES] ({commit}, {supplierId, integrationId}) {
        return SupplierIntegrationService.availableCategories(supplierId, integrationId).then(res => {
            commit(SET_AVAILABLE_SHOP_CATEGORIES, res);
        });
    },
    [GET_SUPPLIER_INTEGRATION_CATEGORIES]({getters, commit}, integrationId) {
        commit(SET_INTERGATION_ERROR, null);
        commit(SET_SUPPLIER_INTEGRATION_CATEGORIES, []);
        return SupplierIntegrationService.categories(getters.activeSupplierId, integrationId).then(res => {
            if(res.error) {
                commit(SET_INTERGATION_ERROR, res.error);
            } else {
                commit(SET_SUPPLIER_INTEGRATION_CATEGORIES, res.categories);
            }
        });
    },
    [SYNC_SUPPLIER_INTEGRATION_CATEGORIES]({getters, commit}, {integrationId, data}) {
        return SupplierIntegrationService.syncCategories(getters.activeSupplierId, integrationId, data).then(res => {
            if(res.error) {
                commit(SET_INTERGATION_ERROR, res.error);
            } else {
                commit(SET_SUPPLIER_INTEGRATION_CATEGORIES, res.categories);
            }
        });
    },
    [GET_SUPPLIER_INTEGRATION_PRICE_LISTS]({getters, commit}, integrationId) {
        return SupplierIntegrationService.priceLists(getters.activeSupplierId, integrationId).then(res => {
            commit(SET_SUPPLIER_INTEGRATION_PRICE_LISTS, res);
        });
    },
    [SAVE_SUPPLIER_INTEGRATION_PRICE_LIST]({getters, commit}, {integrationId, data}) {
        return SupplierIntegrationService.selectPriceList(getters.activeSupplierId, integrationId, data).then(res => {
            commit(SET_SELECTED_SUPPLIER_INTEGRATION, res);
        });
    }
};

export const supplierIntegrationGetters = {
    availableIntegrationCategories: state => state.availableCategories,
    availableStructuredIntegrationCategories: state => state.structuredAvailableCategories,
    integrationCategories: state => state.integrationCategories,
    supplierIntegrationError: state => state.integrationError,
    supplierIntegrationPriceLists: state => state.price_lists
};