export const GET_INTEGRATIONS = 'GET_INTEGRATIONS';
export const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
export const GET_INTEGRATION = 'GET_INTEGRATION';
export const SET_INTEGRATION = 'SET_INTEGRATION';
export const UPDATE_INTEGRATION_SETTINGS = 'UPDATE_INTEGRATION_SETTINGS';
export const STORE_INTEGRATION = 'STORE_INTEGRATION';
export const ADD_INTEGRATION = 'ADD_INTEGRATION';
export const SET_ORGANIZATION_OPTIONS = 'SET_ORGANIZATION_OPTIONS';
export const SET_KINDERGARTEN_OPTIONS = 'SET_KINDERGARTEN_OPTIONS';
export const STORE_INTEGRATION_CLIENT = 'STORE_INTEGRATION_CLIENT';
export const ADD_INTEGRATION_CLIENT = 'ADD_INTEGRATION_CLIENT';
export const GET_INTEGRATION_CLIENTS = 'GET_INTEGRATION_CLIENTS';
export const SET_INTEGRATION_CLIENTS = 'SET_INTEGRATION_CLIENTS';
export const DELETE_INTEGRATION_CLIENT = 'DELETE_INTEGRATION_CLIENT';
export const REMOVE_INTEGRATION_CLIENT = 'REMOVE_INTEGRATION_CLIENT';
export const UPDATE_INTEGRATION_CLIENT = 'UPDATE_INTEGRATION_CLIENT';
export const SET_INTEGRATION_CLIENT = 'SET_INTEGRATION_CLIENT';