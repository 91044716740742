<template>
    <div v-clickOutside="blur">
        <div class="position-relative e3-date-picker">
            <input
                :value="displayValue"
                :placeholder="hasLabel"
                class="form-control"
                :class="{'is-invalid': validation && validation.$error, 'date-range': showRange}"
                :readonly="inputReadonly"
                :disabled="disabled"
                @blur="inputBlur"
                @focus="focus"
                @input="inputChange"/>
            <button class="btn date-icon">
                <svg class="svg-18 text-primary" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z"/>
                </svg>
            </button>
            <b-calendar
                :id="id"
                class="date-picker border rounded p-2"
                v-bind="labels || {}"
                :class="[{'open': pickerOpen}, calendarClass]"
                :value="dateVal"
                :locale="language"
                :start-weekday="1"
                :hide-header="true"
                :min="minDate"
                :max="maxDate"
                :reset-button="reset"
                :disabled="disabled"
                :placeholder="hasLabel"
                :date-disabled-fn="dateDisabled"
                @input="pickerInput">
                <div v-if="reset" class="d-flex justify-content-center py-2">
                    <button
                        v-if="dateVal"
                        class="btn btn-danger btn-sm"
                        @click="clearDate">
                        {{ $t("Clear date") }}
                    </button>
                </div>
            </b-calendar>
        </div>
        <ValitationMessages :validation="validation" :class="{'position-absolute': positionAbsoluteValidation}"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {academicYear} from '@services/helper.service';

export default {
    name: 'EDatepicker',
    directives: {
        clickOutside: {
            bind: function(el, binding, vNode) {
                el.__vueClickOutside__ = event => {
                    if (!el.contains(event.target)) {
                        vNode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener('click', el.__vueClickOutside__);
            },
            unbind: function(el) {
                document.removeEventListener('click', el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            }
        }
    },
    props: {
        id: [String, Number],
        value: {
            type: String
        },
        minDate: String,
        maxDate: String,
        label: String,
        validation: Object,
        showRange: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        noWeekendsDays: {
            type: Boolean,
            default: false
        },
        reset: {
            type: Boolean,
            default: false
        },
        outputFormat: {
            type: String,
            default: 'YYYY-MM-DD',
        },
        displayFormat: {
            type: String,
            default: 'L',
        },
        displayMonthsFormat: {
            type: String,
            default: 'MMMM YYYY',
        },
        displayYearsFormat: {
            type: String,
            default: 'YYYY',
        },
        disableType: {
            type: Boolean,
            default: false
        },
        displayYearPeriod: {
            type: Boolean,
            default: false
        },
        yearDuration: String,
        positionAbsoluteValidation: {
            type: Boolean,
            default: false
        },
        calendarClass: {
            type: String,
            default: null
        },
        disabledDates: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            pickerOpen: false,
            inputFocused: false,
            displayValue: '',
            dFormat: this.displayFormat,
            labels: {
                labelPrevDecade: this.$t('Previous decade'),
                labelPrevYear: this.$t('Previous year'),
                labelPrevMonth: this.$t('Previous month'),
                labelCurrentMonth: this.$t('Current month'),
                labelNextMonth: this.$t('Next month'),
                labelNextYear: this.$t('Next year'),
                labelNextDecade: this.$t('Next decade'),
                labelToday: this.$t('Today'),
                labelSelected: this.$t('Selected date'),
                labelNoDateSelected: this.$t('No date selected'),
                labelCalendar: this.$t('Calendar'),
                labelNav: this.$t('Calendar navigation'),
                labelHelp: this.$t('Use the arrow keys to navigate through the calendar')
            },
        };
    },
    computed: {
        ...mapGetters(['language']),
        dateVal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        hasLabel() {
            return this.label || '';
        },
        inputReadonly() {
            return this.showRange || this.disableType;
        },
    },
    watch: {
        language: function () {
            this.displayValue = this.setDisplayDate(this.value);
        },
    },
    methods: {
        focus() {
            if (!this.disabled) {
                this.inputFocused = true;
                this.pickerOpen = true;
            }
        },
        inputBlur() {
            this.inputFocused = false;
        },
        blur() {
            this.pickerOpen = false;
            if (this.dateVal && this.inputFocused) {
                this.dateVal = this.checkMinMaxDate(this.dateVal, this.outputFormat);
                this.displayValue = this.checkMinMaxDate(this.dateVal, this.outputFormat, this.displayFormat);
            }
        },
        clearDate() {
            this.$emit('input', '');
        },
        dateDisabled(ymd, date) {
            return !!((this.noWeekendsDays && (this.$moment(date).day() == 6 || this.$moment(date).day() == 0)) || (this.disabledDates.length && this.disabledDates.includes(this.$moment(date).format('YYYY-MM-DD'))));
        },
        setDisplayDate(date, inputFormat=this.outputFormat, outputFormat=this.dFormat) {
            if (date && this.showRange) {
                switch(this.showRange) {
                case 'years':
                    if (this.displayYearPeriod) {
                        return this.$moment(academicYear(date), inputFormat).startOf('isoYear').format(this.displayYearsFormat) +
                            ' - ' + this.$moment(this.yearDuration, inputFormat).format(this.displayYearsFormat);
                    } else {
                        return this.$moment(date, inputFormat).startOf('isoYear').format(this.displayYearsFormat);
                    }
                case 'months':
                    return this.$moment(date, inputFormat).startOf('isoMonth').format(this.displayMonthsFormat);
                default:
                    return this.$moment(date, inputFormat).startOf('isoWeek').format(outputFormat) +
                        ' - ' +
                        this.$moment(date, inputFormat).endOf('isoWeek').format(outputFormat);
                }
            } else if (date) {
                return this.$moment(date, inputFormat).format(outputFormat);
            }
        },
        pickerInput(e) {
            if (!this.inputFocused) {
                this.displayValue = this.setDisplayDate(e);
                this.dateVal = e;
            }
            this.pickerOpen = false;
        },
        checkMinMaxDate(date, inputFormat=this.dFormat, outputFormat=this.outputFormat) {
            if (this.minDate && this.$moment(date, inputFormat).isBefore(this.$moment(this.minDate))) {
                return this.$moment(this.minDate).format(outputFormat);
            }

            if (this.maxDate && this.$moment(date, inputFormat).isAfter(this.$moment(this.maxDate))) {
                return this.$moment(this.maxDate).format(outputFormat);
            }

            return this.$moment(date, inputFormat).format(outputFormat);
        },
        inputChange(e) {
            this.displayValue = e.target.value;
            if (e.target.value === '') {
                this.dateVal = '';
            } else {
                this.dateVal = this.checkMinMaxDate(e.target.value);
                this.setDisplayDate(this.dateVal);
            }
        },
    },
    created() {
        if (this.value) {
            this.displayValue = this.setDisplayDate(this.value);
        }
    }
};
</script>

<style scoped lang="scss">
@import "@common/styles/variables/_variables.scss";
.date-picker {
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    background-color: #fff;
    margin-top: 2px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    &.open {
        height: auto;
        opacity: 1;
        z-index: 2;
    }
}

.align-right {
    right: 0;
}

.align-right-lg {
	@media (min-width: 992px) {
		right: 0;
	}
}

.date-range {
    min-width: 220px;
}


@media screen and (max-width: $grid-br-sm) {
    .date-range {
        min-width: 198px;
    }   
}

.e3-date-picker .form-control {
    padding: 0.375rem 0.55rem;
}

.date-icon {
    position: absolute;
    top: 4px;
    right: 0;
    pointer-events: none;
    .is-invalid {
        color: $danger !important;
    }
}
.form-control.is-invalid {
    background-image: none;
    border: 1px solid $danger !important;
}
</style>
