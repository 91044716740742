<template>
    <div :class="{'small-upload' : small}">
        <form enctype="multipart/form-data" class="mt-2">
            <div
                id="dropbox"
                class="d-flex justify-content-center align-items-center dropbox position-relative p-3"
                :class="{'disabled': disabled}">
                <div class="d-flex flex-column justify-content-center e3-upload-inner">
                    <SvgIcon icon="mdi-cloud-upload" size="36" class="mx-auto"/>
                    <div class="d-flex justify-content-center text-center">
                        {{ $t('Click on icon to choose files or drag and drop inside the box to upload') }}
                    </div>
                    <slot name="additional-info"/>
                </div>
                <input id="files" ref="files" type="file" :title="$t('No file chosen')" :multiple="!singleUpload" class="input-file position-absolute"
                       :class="{'e3-disabled-file-upload': disabled}"
                       :disabled="disabled"
                       :accept="accept"
                       @change="handleFileUploads"
                       @click="resetFileUploads"/>
            </div>
            <div v-if="isModal" class="d-flex justify-content-end align-items-center mt-4">
                <button type="button" class="btn btn-success" @click="$bvModal.hide(modalName)">
                    {{ $t('Done') }}
                </button>
            </div>
        </form>
        <b-modal
            id="invalid-file-modal"
            lazy
            static
            centered
            hide-footer>
            <template v-slot:modal-header>
                <modal-header modalName="invalid-file-modal">{{ $t('Error') }}</modal-header>
            </template>
            <div class="mb-3">
                <span v-if="hasOneInvalidFile()">
                    {{ $t('The file is 0 bytes, so it will not be attached.', { file: invalid_files[0].name}) }}
                </span>
                <span v-else>
                    <span>{{ $tc('The files are 0 bytes and will not be attached. Please re-attach your files again without them.', invalid_files.length, { file: chooseInvalidFiles()}) }}</span>
                </span>
            </div>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary" @click="closeInvalidModal">{{ $t('Close') }}</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {eventBus} from '@/main';
import UploadMixin from '@components/upload/Upload.mixin';

export default {
    props: {
        title: String,
        isModal: Boolean,
        modalName: String,
        disabled: Boolean,
        fileUpload: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        singleUpload: {
            type: Boolean,
            default: false
        },
        useBoxUpload: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: null
        },
    },
    mixins: [UploadMixin],
    data() {
        return {
            files: [],
            fileRow: 3,
            showArea: true,
            invalid_files: [],
        };
    },
    methods: {
        async handleFileUploads() {
            if (this.useBoxUpload) {
                this.$emit('uploadBox', {files: [...this.$refs.files.files]});
                this.$bvModal.hide(this.modalName);
                return;
            }

            let invalid = [...this.$refs.files.files].filter(f => f.size === 0);
            if(!invalid.length || this.isModal) {
                for(let i = 0; i < this.$refs.files.files.length; i++) {
                    if (this.$refs.files.files[i].size <= this.fileSizeMax) {
                        this.files.push(this.createFileObj(this.$refs.files.files[i]));
                        if(this.isModal) {
                            this.$emit('single-file', this.createFileObj(this.$refs.files.files[i]));
                            await this.sleep(200);
                        }
                    }
                    else {
                        this.$toastr.e(this.$t('File size is too big'));
                    }
                }
                if(!this.isModal) {
                    this.$emit('files', this.files);
                }
            } else {
                this.invalid_files = [];
                invalid.map(i => {
                    this.invalid_files.push({
                        name: i.name
                    });
                });
                this.$bvModal.show('invalid-file-modal');
            }
        },
        sleep(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },
        upload() {
            this.$emit('files', this.files);
            this.$emit('submit', true);
            this.$bvModal.hide(this.modalName);
        },
        removeFile(index) {
            this.files.splice(index, 1);
            this.$emit('files', this.files);
        },
        closeInvalidModal() {
            this.$bvModal.hide('invalid-file-modal');
        },
        resetFileUploads() {
            this.invalid_files = [];
            this.$refs.files.value = '';
        },
        hasOneInvalidFile() {
            return this.invalid_files.length == 1 && this.$refs.files.files.length == 1;
        },
        chooseInvalidFiles() {
            return this.invalid_files.map(
                function(file){
                    return file.name;
                }).join(', ');
        },
        createFileObj(file) {
            return new File([file], file.name.slice(0, this.nameMaxLength), {type: file.type, lastModified: file.lastModified});
        }
    },
    mounted() {
        eventBus.$on('remove-file', index => {
            this.removeFile(index);
        });
        eventBus.$on('reset-upload-filelist', () => {
            this.resetFileUploads();
            this.files = [];
        });
    },
    destroyed() {
        eventBus.$off('remove-file');
        eventBus.$off('reset-upload-filelist');
    },
};
</script>

<style scoped lang="scss">
@import "@common/styles/mixins/_filters.scss";

    .dropbox {
        border: 2px dashed #f79e80;
        border-radius: .33em;
        color: dimgray;
        padding: 10px 10px;
        min-height: 5rem; /* minimum height */
        cursor: pointer;
        .e3-svg-mdi {
            @include e3-dropbox-filter;
        }

        &.disabled {
            opacity: 0.75;
        }
    }

    .small-upload .e3-upload-inner {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .e3-upload-inner {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .input-file {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0; /* invisible but it's there! */
        cursor: pointer;
    }
    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
    }
    .e3-upload-icon {
        right: 50%
    }
    .e3-remove {
        width: 20px;
        height: 20px;
        padding: 0.05rem;
    }
    .e3-disabled-file-upload {
       cursor: default;
    }
</style>
