const UserProfile = () => import(/* webpackChunkName: "user-module" */ '@views/user/profile');
const Profile = () => import(/* webpackChunkName: "user-module" */ '@views/user/profile/views/Profile');
const Notifications = () => import(/* webpackChunkName: "user-module" */ '@views/user/profile/views/Notifications');
const Devices = () => import(/* webpackChunkName: "user-module" */ '@views/user/profile/views/UserDevices');
const Connections = () => import(/* webpackChunkName: "user-module" */ '@views/user/profile/views/UserConnections');

export default [
    {
        path: '/user',
        components: { base: UserProfile },
        children: [
            {
                path: 'profile',
                name: 'user.profile',
                components: { user: Profile }
            },
            {
                path: 'notifications',
                name: 'user.notifications',
                components: { user: Notifications }
            },
            {
                path: 'devices',
                name: 'user.devices',
                components: { user: Devices }
            },
            {
                path: 'connections',
                name: 'user.connections',
                components: { user: Connections }
            }
        ]
    }
];
