<template>
    <div class="e3-documents">
        <template>
            <PageTitle :title="$t('Gallery')"/>
            <div v-if="loadMoreFolders" class="d-flex flex-column flex-sm-row align-items-center">
                <h5 class="d-flex align-items-center mr-auto mb-3 mb-sm-0">
                    <a class="nav-link text-primary e3-hover-underline e3-pointer p-0" @click="getGalleryIndex">
                        {{ $t("Gallery") }}
                    </a>
                    <span class="d-flex align-items-center mr-2">
                        <span class="mx-1">
                            <SvgIcon icon="mdi-chevron-right" source="menu-icons" size="24"/>
                        </span>
                        {{ $t(crumbName || loadMoreFolders) }}
                    </span>
                </h5>
                <ESearchInput
                    v-if="hasInitialItems"
                    v-model="searchValue"
                    class="ml-auto"
                    :disabled="anyLoading"
                    :placeholder="$t('Type to search')"
                    @getEntities="search()">
                </ESearchInput>
            </div>
        </template>

        <div v-if="!loadMoreFolders && !initialLoad">
            <h3 class="mt-4 mb-2 ">{{ $t('ELIIS albums') }}</h3>
            <div class="d-flex flex-wrap flex-row mb-2">
                <div v-for="(folder, idx) in builtinFolders" :key="`${idx}-bf`"
                     class="e3-folder-container d-flex flex-lg-column align-items-center mr-3 mb-2 mb-md-0"
                     :title="$t(folder.title)">
                    <router-link
                        :to="folder.route"
                        class="e3-folder-box d-flex align-items-center pb-1 grid flex-column">
                        <FolderIcon isGallery isReadonly isGrid/>
                        <div class="d-flex align-items-center text-center text-dark text-break px-3 h-100 folder-name overflow-hidden pt-1">
                            <span>{{ $t(folder.title) }}</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <transition v-if="loadMoreFolders" name="listing">
            <div class="e3-folder-listing">
                <FolderList
                    :searchValue="searchValue"
                    :folders="subFolders"
                    :searchLoading="folderLoading"
                    type="gallery"
                    :page="page"
                    index
                    @loadMore="loadMore"/>
            </div>
        </transition>
        <transition v-else name="folders">
            <div class="e3-folders">
                <FolderRow
                    notFoundMsg="No albums found"
                    :searchValue="searchValue"
                    folderType="allFolders"
                    emptyMsg="No albums"
                    :title="$t('Albums')"
                    type="gallery"
                    :per-page="foldersPerPage"
                    :hasInitialItems="hasInitialItems"
                    :anyLoading="anyLoading"
                    @loadMore="loadMore"
                    @search="handleSearch"/>
            </div>
        </transition>
        <div v-if="loadMoreFolders !== 'sharedFolders'">
            <FolderModal type="gallery" :noUpload="false" class="mb-4"/>
            <AddNewButton v-if="canAddGalleryFolder" :title="$t('Add folder')" @addNewClicked="openModal"/>
        </div>
    </div>
</template>

<script>
import FolderModal from '@views/folder/modals/FolderModal';
import FolderList from '@views/folder/components/list/FolderList';
import FolderRow from '@views/folder/components/FolderRow';
import FolderIcon from '@/views/folder/components/FolderIcon';
import { mapGetters } from 'vuex';
import { eventBus } from '@/main';

export default {
    data() {
        return {
            showMore: false,
            loadMoreFolders: '',
            crumbName: '',
            searchValue: '',
            foldersPerPage: 20,
            page: 1,
            folderLoading: false,
            hasInitialItems: false,
            initialSearch: false,
            showChildFiles: false,
            initialLoad: true,
        };
    },
    computed : {
        ...mapGetters([
            'canAddGalleryFolder',
            'folderSettings',
            'subFolders',
            'canAccessChildFilesEmployee',
            'canAccessChildFilesGuardian',
        ]),
        anyLoading() {
            return this.folderLoading || this.documentLoading;
        },
        childProfilePicturesVisible() {
            return this.showChildFiles
                && (this.canAccessChildFilesEmployee || this.canAccessChildFilesGuardian);
        },
        builtinFolders() {
            const folders = [
                {title: 'Diary', route: {name: 'gallery.builtin.diary.index'}},
            ];
            if (this.childProfilePicturesVisible) {
                folders.push(
                    {title: 'Children', route: {name: 'gallery.builtin.child-profile'}},
                );
            }
            return folders;
        },
    },
    methods: {
        openModal() {
            eventBus.$emit('show-index-add-folder-modal', {});
        },
        search() {
            if (this.searchValue.length > 0) {
                this.initialSearch = true;
            }

            if (this.searchValue.length > 0 || this.initialSearch) {
                this.page = 1;
                this.getGalleryFolders(true);
            }

            if (!this.searchValue.length > 0 && this.initialSearch) {
                this.initialSearch = false;
            }
        },
        handleSearch(value) {
            this.searchValue = value;
            this.search();
        },
        loadMore() {
            this.loadMoreFolders = 'Albums';
            this.page++;
            this.getGalleryFolders();
        },
        getGalleryFolders(reset = false) {
            this.$store.commit('START_CONTENT_LOADER');
            if (reset) {
                this.folderLoading = true;
                this.$store.commit('RESET_FOLDER_STATE');
            }
            return this.$store.dispatch('GET_GALLERY_FOLDERS', {page: this.page, perPage: this.foldersPerPage, term: this.searchValue}).then(() => {
                if (this.hasInitialItems == false && this.subFolders) {
                    this.hasInitialItems = true;
                }
            }).finally(() => {
                this.folderLoading = false;
                this.showChildFiles = this.folderSettings.child_profile_files_enabled;
                this.$store.commit('STOP_CONTENT_LOADER');
            });
        },
        getGalleryIndex() {
            this.loadMoreFolders = '';
            this.page = 1;
            this.searchValue = '';
            this.getGalleryFolders(true);
        }
    },
    components: {
        FolderIcon,
        FolderModal,
        FolderList,
        FolderRow
    },
    created() {
        this.$store.commit('RESET_FOLDER_STATE');
        this.getGalleryFolders().finally(() => this.initialLoad = false);
    }
};

</script>


<style scoped lang="scss">
.underline {
  padding-bottom: 10px;
  border-bottom: 1px solid #f79e80;
}

.e3-folder-icon {
    height: 65px;
    &::before {
        height: 65px;
    }
}

.e3-folder-container:hover {
    .text-center.text-dark.text-break {
        text-decoration: underline;
        color: #f79e80 !important;
    }
}

.folder-name {
    max-height: 52px;
}
</style>
