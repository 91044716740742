import store from '@/store';

const Messages = () =>
    import(/* webpackChunkName: "messages-module" */ '@views/message');
const MessageTab = () =>
    import(/* webpackChunkName: "messages-module" */ '@views/message/views/MessageTab.vue');

const DraftTab = () =>
    import(/* webpackChunkName: "messages-module" */ '@views/message/views/DraftsTab.vue');

export default [
    {
        path: '/messages',
        components: { base: Messages },
        children: [
            {
                path: '',
                name: 'messages.received',
                components: { message: MessageTab },
                meta: 'received'
            },
            {
                path: 'drafts',
                name: 'messages.drafts',
                components: { message: DraftTab },
                meta: 'sent'
            },
            {
                path: 'sent',
                name: 'messages.sent',
                components: { message: MessageTab },
                meta: 'sent'
            },
            {
                path: 'deleted',
                name: 'messages.deleted',
                components: { message: MessageTab },
                meta: 'deleted'
            }
        ],
        beforeEnter: (to, from, next) => {
            if (store.getters.activeKindergartenId || store.getters.activeOrganizationId) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    }
];
