import {
    GET_KINDERGARTEN_TARGET_GROUPS,
    SET_KINDERGARTEN_TARGET_GROUPS,
    ADD_KINDERGARTEN_TARGET_GROUP,
    SAVE_KINDERGARTEN_TARGET_GROUP,
    RESET_TARGET_GROUP_STORE,
    GET_KINDERGARTEN_TARGET_GROUP,
    UPDATE_KINDERGARTEN_TARGET_GROUP,
    SET_KINDERGARTEN_TARGET_GROUP
} from './actions';
import Vue from 'vue';
import KindergartenService from '@services/kindergarten.service';
import TargetGroupService from '@services/targetgroup.service';
import sortBy from 'lodash/sortBy';
const defaultState = () => {
    return {
        target_groups: [],
        target_group: {}
    };
};
const state = defaultState;

const mutations = {
    [SET_KINDERGARTEN_TARGET_GROUPS](state, groups) {
        state.target_groups = groups;
    },
    [ADD_KINDERGARTEN_TARGET_GROUP](state, group) {
        state.target_groups.push(group);
        state.target_groups = sortBy(state.target_groups, t => t.name);
    },
    [RESET_TARGET_GROUP_STORE](state) {
        Object.assign(state, defaultState());
    },
    [SET_KINDERGARTEN_TARGET_GROUP](state, targetGroup) {
        Vue.set(state, 'target_group', targetGroup);
    }
};

const actions = {
    [GET_KINDERGARTEN_TARGET_GROUPS]({commit, getters}, queryObj) {
        return KindergartenService.getKindergartenTargetGroups(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_KINDERGARTEN_TARGET_GROUPS, res);
        });
    },
    [GET_KINDERGARTEN_TARGET_GROUP]({commit, getters}, id) {
      return TargetGroupService.getTargetGroup(getters.activeKindergartenId, id).then(res => {
          commit(SET_KINDERGARTEN_TARGET_GROUP, res);
      });
    },
    [SAVE_KINDERGARTEN_TARGET_GROUP]({commit, getters}, data) {
        return TargetGroupService.createTargetGroup(getters.activeKindergartenId, data).then(res => {
           commit(ADD_KINDERGARTEN_TARGET_GROUP, res);
           return res;
        });
    },
    [UPDATE_KINDERGARTEN_TARGET_GROUP]({commit, getters}, data) {
        return TargetGroupService.updateTargetGroup(getters.activeKindergartenId, data.id, data).then(res => {
            commit(SET_KINDERGARTEN_TARGET_GROUP, res);
        });
}
};

const getters = {
    kindergartenTargetGroups: state => state.target_groups,
    kindergartenTargetGroup: state => state.target_group
};

export default {
    state,
    mutations,
    actions,
    getters
};