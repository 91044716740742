import http from '@http';
class CourseService {
    constructor() {
        if(CourseService.instance) {
            return CourseService.instance;
        }

        CourseService.instance = this;
    }

    getCourses() {
        return http.get('api/admin/courses').then(res => res.data);
    }

    getEmployeeActiveCourses(kindergartenId, employeeId) {
        return http.get(`/api/kindergartens/${kindergartenId}/employees/${employeeId}/active-courses`)
            .then(res => res.data);
    }

    getEmployeeCourses(kindergartenId, employeeId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/employees/${employeeId}/courses`, {params: options})
            .then(res => res.data);
    }

    getEmployeeCoursesDashboardSync(kindergartenId, courseId) {
        return http.get(`/api/kindergartens/${kindergartenId}/dashboard/course-sync/${courseId}`)
            .then(res => res.data);
    }

    addTeachers(kindergartenId, course_id, teachers) {
        return http.post(`/api/kindergartens/${kindergartenId}/courses/${course_id}/add-teachers`, teachers)
            .then(res => res.data);
    }

    updateTeacher(kindergartenId, courseId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/${courseId}/update-teacher/${data.courseTeacherId}`, data)
            .then(res => res.data);
    }

    removeTeacher(kindergartenId, course_id, course_teacher_id) {
        return http.delete(`/api/kindergartens/${kindergartenId}/courses/${course_id}/remove-teacher/${course_teacher_id}`)
            .then(res => res.data);
    }

    addChildren(kindergartenId, course_id, children) {
        return http.post(`/api/kindergartens/${kindergartenId}/courses/${course_id}/add-children`, children)
            .then(res => res.data);
    }

    updateChild(kindergartenId, courseId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/${courseId}/update-child/${data.courseChildId}`, data)
            .then(res => res.data);
    }

    removeChild(kindergartenId, course_id, course_child_id) {
        return http.delete(`/api/kindergartens/${kindergartenId}/courses/${course_id}/remove-child/${course_child_id}`)
            .then(res => res.data);
    }

    getCoursePeriod(kindergartenId, id) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses/period/${id}`).then(res => res.data);
    }

    updateCourse(kindergartenId, course_id, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/${course_id}`, data).then(res => res.data);
    }

    createCoursePeriod(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/courses/period`, data).then(res => res.data);
    }

    createSummerPeriod(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/courses/summer-period`, data).then(res => res.data);
    }

    createCourse(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/courses`, data).then(res => res.data);
    }

    deleteCourse(kindergartenId, id) {
        return http.delete(`/api/kindergartens/${kindergartenId}/courses/${id}`).then(res => res.data);
    }

    createSpecialCourse(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/courses/special`, data).then(res => res.data);
    }

    updateCoursePeriod(kindergartenId, period_id, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/period/${period_id}`, data)
            .then(res => res.data);
    }

    getCourseChildren(kindergartenId, courseId) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses/${courseId}/children`).then(res => res.data);
    }

    getCourseTeachers(kindergartenId, courseId) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses/${courseId}/teachers`).then(res => res.data);
    }

    getCourseSettings(kindergartenId, type) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses/settings`, {params:{type: type}}).then(res => res.data);
    }

    getCourselessChildren(kindergartenId, request) {
        return http.get(`/api/kindergartens/${kindergartenId}/children-available`, {params: request})
            .then(res => res.data);
    }
    getCourselessSummerChildren(kindergartenId, request) {
        return http.get(`/api/kindergartens/${kindergartenId}/summer-children-available`, {params: request})
            .then(res => res.data);
    }
    getCourse(kindergartenId, courseId) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses/${courseId}`).then(res => res.data);
    }

    archiveCourse(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/${data.id}/archive`, data).then(res => res.data);
    }

    restoreCourse(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/${data.id}/restore`, data).then(res => res.data);
    }

    deletePeriod(kindergartenId, periodId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/courses/period/${periodId}`).then(res => res.data);
    }

    updateCourseSetting(kindergartenId, courseId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/${courseId}/settings`, data).then(res => res.data);
    }

    updateAllCourseSettings(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/settings`, data).then(res => res.data);
    }

    getCourseBirthdays(kindergartenId, courseId) {
        return http.get(`/api/kindergartens/${kindergartenId}/courses/${courseId}/birthdays`).then(res => res.data);
    }

    updateCourseMeals(kindergartenId, courseId, meals) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/${courseId}/meals`, meals).then(res => res.data);
    }

    updateAllCourseMeals(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/meals`, data).then(res => res.data);
    }

    updateCourseAbsentStatuses(kindergartenId, courseId, statuses) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/${courseId}/absent`, statuses).then(res => res.data);
    }

    updateAllCoursesAbsentStatuses(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/absent`, data).then(res => res.data);
    }

    updateCoursePresentStatuses(kindergartenId, courseId, statuses) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/${courseId}/present`, statuses).then(res => res.data);
    }

    updateAllCoursesPresentStatuses(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/present`, data).then(res => res.data);
    }

    syncChildrenDates(kindergartenId, courseId) {
        return http.put(`/api/kindergartens/${kindergartenId}/courses/${courseId}/children/dates-sync`, {}).then(res => res.data);
    }
}

export default new CourseService();
