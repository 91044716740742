import http from '@http';
import {download} from '@/common/services/helper.service';
import i18n from '@/i18n';

class AdminSurveyService {
    constructor() {
        if (AdminSurveyService.instance) {
            return AdminSurveyService.instance;
        }

        AdminSurveyService.instance = this;
    }

    getSurveys(queryObj) {
        return http.get('/api/admin/surveys', {params: queryObj}).then(res => res.data);
    }

    getSurvey(surveyId) {
        return http.get(`/api/admin/surveys/${surveyId}`).then(res => res.data);
    }

    addSurvey(data) {
        return http.post('/api/admin/surveys', data).then(res => res.data);
    }

    updateSurvey(surveyId, data) {
        return http.put(`/api/admin/surveys/${surveyId}`, data).then(res => res.data);
    }
    updateSettings(surveyId, data) {
        return http.put(`/api/admin/surveys/${surveyId}/settings`, data).then(res => res.data);
    }

    addQuestion(surveyId, data) {
        return http.post(`/api/admin/surveys/${surveyId}/question`, data).then(res => res.data);
    }

    updateQuestion(surveyId, questionId, data) {
        return http.put(`/api/admin/surveys/${surveyId}/question/${questionId}`, data).then(res => res.data);
    }

    deleteQuestion(surveyId, questionId) {
        return http.delete(`/api/admin/surveys/${surveyId}/question/${questionId}`).then(res => res);
    }

    reorderQuestion(surveyId, questionId, data) {
        return http.put(`/api/admin/surveys/${surveyId}/question/${questionId}/order`, data).then(res => res);
    }

    addQuestionOption(surveyId, questionId, data) {
        return http.post(`/api/admin/surveys/${surveyId}/question/${questionId}/options`, data).then(res => res.data);
    }

    updateQuestionOption(surveyId, questionId, optionId, data) {
        return http.put(`/api/admin/surveys/${surveyId}/question/${questionId}/options/${optionId}`, data).then(res => res.data);
    }

    deleteQuestionOption(surveyId, questionId, optionId) {
        return http.delete(`/api/admin/surveys/${surveyId}/question/${questionId}/options/${optionId}`).then(res => res);
    }

    reorderQuestionOption(surveyId, questionId, optionId, data) {
        return http.put(`/api/admin/surveys/${surveyId}/question/${questionId}/options/${optionId}/order`, data).then(res => res);
    }

    deleteSurvey(surveyId) {
        return http.delete(`/api/admin/surveys/${surveyId}`).then(res => res.data);
    }
    getAnswers(surveyId) {
        return http.get(`/api/admin/surveys/${surveyId}/results`).then(res => res.data);
    }

    getResultsXLS(surveyId, name) {
        return http.download(`/api/admin/surveys/${surveyId}/xls`).then(res => {
            const file = {
                mime_type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                name: `${i18n.t('Questionnaire')} ${name}.xlsx`
            };
            download(res.data, file);
        });
    }
}

export default new AdminSurveyService();
