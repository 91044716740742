<template>
    <router-link :to="{name: 'diary', params: {course_id: notification.data.course_id, date: notification.data.date}}" class="card p-3 mb-2 diary-likes e3-notification-row">
        <div>
            <span class="font-weight-bold">{{ notification.creator.fname }} {{ notification.creator.lname }} <span v-if="notification.data.additional_likes > 0">+{{ notification.data.additional_likes }}</span></span>
            <span> {{ notificationBody }} </span>
        </div>
        <div class="text-black-50">
            {{ formatDateTime(notification.created_at) }}
        </div>
    </router-link>
</template>

<script>
import {formatDateTime} from '@/filters';

export default {
    name: 'DiaryLikeNotification',
    props: {
        notification: Object
    },
    data() {
        return {
        };
    },
    computed: {
        notificationBody() {
            const data = this.notification.data;
            const date = this.$moment(data.date)
                .locale(this.$store.getters.language).format('D. MMMM');
            return this.$t('Notification - New diary like', {date: date, course: data.course_name});
        }
    },
    methods: {
        formatDateTime, // TODO: Vue3 remove
    }
};
</script>
