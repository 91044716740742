<template>
    <div class="e3-head-icon-round">
        <div class="font-weight-bold d-flex align-items-center px-3 py-2">
            <span class="text-uppercase">{{ $t('My cart')}} </span>
            <span class="ml-auto">{{ userRecentCart.count }}</span>
        </div>
        <div class="border-top">
            <div v-if="userRecentCart.items.length" class="py-2">
                <div v-for="item in userRecentCart.items" :key="item.id" class="d-flex align-items-center px-3 py-2">
                    <div class="e3-image-thumbnail-box sm img-thumbnail p-2 mr-2 mb-auto flex-shrink-0">
                        <div v-if="item.product" class="e3-image-thumbnail e3-border-radius-23" :style="{ 'background-image': `url('${imageUrl(item.product)}')` }"/>
                        <div v-if="item.service" class="e3-image-thumbnail e3-border-radius-23" :style="{ 'background-image': `url('${imageUrl(item.service)}')` }"/>
                    </div>
                    <div class="d-flex flex-column flex-fill">
                        <div class="d-flex">
                            <div class="pr-2 name-container">
                                <router-link
                                    :to="{name: routeName(item), params: routeParams(item)}"
                                    class="e3-pointer text-decoration-none d-block text-truncate">
                                    <template v-if="item.product">{{ shopTranslate(item.product.name )}}</template>
                                    <template v-if="item.service">{{ shopTranslate(item.service.name) }}</template>
                                </router-link>
                            </div>
                            <!-- TODO: ADD BACK IN IF HAVE PROVIDER ID -->
                            <RoundIconButton
                                btnSize="26"
                                type="bg-transparent"
                                class="ml-auto"
                                @click="removeFromCart(item.id)">
                                <SvgIcon icon="mdi-close-circle" class="text-danger" size="26"/>
                            </RoundIconButton>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <span class="mr-2">{{ $t('Quantity') }}: {{ item.qty }}</span>
                            <div class="ml-auto">
                                <PriceTag v-if="item.service" :price="item.service.sales_price_gross" size="display-4"/>
                                <PriceTag v-if="item.product" :price="item.product.sales_price_gross" size="display-4"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end py-2 px-3 mt-3">
                    {{ $t('Total') }}:
                    <PriceTag :price="userRecentCart.sum" size="display-4" class="ml-2"/>
                </div>
            </div>
            <div v-else>
                <EmptyListMessage wrapperClass="p-2">
                    {{ $t('Your cart is empty') }}
                </EmptyListMessage>
            </div>
        </div>
        <div class="bg-light py-2 px-3 border-top rounded-bottom">
            <router-link :to="{name: 'shop.cart'}" @click.native="$emit('close', id)">
                {{ $t('Show all products/services') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {shopTranslate} from '@/common/services/helper.service';
import PriceTag from '@components/PriceTag.vue';
import ShopService from '@/common/services/shop.service';

export default {
    props: {
        id: [String, Number]
    },
    name: 'Cart',
    data() {
        return {

        };
    },
    computed: {
        ...mapGetters(['userRecentCart']),
    },
    components: {
        PriceTag,
    },
    methods: {
        shopTranslate,
        removeFromCart(itemId) {
            ShopService.removeFromCart(itemId).then((res) => {
                this.$toastr.s(this.$t('Item removed from cart'));
                this.$store.commit('SET_RECENT_USER_CART', res);
            });
        },
        routeParams(item) {
            let type = '';
            if (item.service) {
                type = 'service';
            }
            if (item.product) {
                type = 'product';
            }

            return {
                item_slug: item[type].slug,
                category_slug: this.$route.params.category_slug || item[type].category.slug,
                subcategory_slug: this.$route.params.subcategory_slug || item[type].subcategories[0]?.slug
            };
        },
        routeName(item) {
            if (item.service) {
                return `shop.${item.service.type}`;
            }
            if (item.product) {
                return `shop.${item.product.type}`;
            }
        },
        imageUrl(item) {
            return item?.small_thumb ?? '/images/no-photo.jpg';
        },
    },
    created() {
        this.$store.dispatch('GET_RECENT_USER_CART');
    }
};
</script>

<style scoped>
    .e3-image-thumbnail-box.sm {
        width: 60px;
        height: 60px;
        padding: 0 !important; 
    }

    .name-container {
        max-width: 150px;

        @media (min-width: 768px) {
            max-width: 170px;
        }
    }
</style>
