import {COMPOSE_FILE_IMPORT_DATA, GET_FILE_IMPORT_DATA, IMPORT_FILE_DATA, SET_FILE_IMPORT_DATA, SET_FILE_IMPORT_DATA_MODEL} from './actions';
import ImportService from '@services/import.service';
import cloneDeep from 'lodash/cloneDeep';

const defaultState = () => {
    return {
        file_import_data: {
            data: [],
            headings_eliis: [],
            headings_from_data: [],
        },
        composed_file_import_data: {},
        file_import_data_model: 0,
        do_not_use: 'do_not_use'
    };
};
const state = defaultState;

const mutations = {
    [SET_FILE_IMPORT_DATA](state, data) {
        state.file_import_data = data;
    },
    [SET_FILE_IMPORT_DATA_MODEL](state, model) {
        state.file_import_data_model = model;
    },
    [COMPOSE_FILE_IMPORT_DATA](state, data) {
        const newData = cloneDeep(data);
        state.composed_file_import_data = newData;
    }
};

const actions = {
    [GET_FILE_IMPORT_DATA]({ commit, getters }, data) {
        commit('START_CONTENT_LOADER');
        return ImportService.uploadFile(getters.activeKindergartenId, data).then(res => {
                commit(SET_FILE_IMPORT_DATA, res);
                commit(SET_FILE_IMPORT_DATA_MODEL, data.model);
                commit('STOP_CONTENT_LOADER');
            });
    },
    [IMPORT_FILE_DATA]({ commit, getters }, data) {
        commit('START_CONTENT_LOADER');
        data.model = getters.fileImportDataModel;
        return ImportService.importData(getters.activeKindergartenId, data).then(res => {
            commit('STOP_CONTENT_LOADER');
            return res;
        });
    }
};

const getters = {
    fileImportData: state => state.file_import_data,
    composedFileImportData: state => state.composed_file_import_data,
    fileImportDataModel: state => state.file_import_data_model
};

export default {
    state,
    mutations,
    actions,
    getters
};
