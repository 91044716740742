import http from '@http';

class PollService {
    constructor() {
        if(PollService.instance) {
            return PollService.instance;
        }

        PollService.instance = this;
    }

    getManagedPolls(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/surveys/managed-polls`, { params: queryObj }).then(res => res.data);
    }

    getPollAnswers(kindergartenId, surveyId) {
        return http.get(`/api/kindergartens/${kindergartenId}/polls/${surveyId}/answers`).then(res => res.data);
    }

    getPolls(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/polls`, { params: queryObj }).then(res => res.data);
    }

    fillPollQuestion(kindergartenId, surveyId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/polls/${surveyId}`, data).then(res => res.data);
    }

    getDashboardPoll(kindergartenId, skip) {
        return http.get(`/api/kindergartens/${kindergartenId}/polls/dashboard`, { params: {skip} }).then(res => res.data);
    }

    submitDashboardPoll(kindergartenId, surveyId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/polls/${surveyId}/dashboard`, data).then(res => res.data);
    }

    getPollResults(kindergartenId, surveyId) {
        return http.get(`/api/kindergartens/${kindergartenId}/surveys/managed-polls/answers/${surveyId}`).then(res => res.data);
    }
}

export default new PollService();