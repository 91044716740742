<template>
    <div>
        <div class="d-flex flex-column flex-sm-row align-items-center mt-4 mb-2">
            <h3 class="h4 mr-auto mb-3 mb-sm-0">{{ $t(title) }}</h3>
            <div class="d-flex flex-row ml-auto">
                <div class="d-flex mt-2">
                    <div v-if="hasInitialFolders" class="d-flex mr-3">
                        <label for="folders-check" class="mr-2">{{ $t('Folders') }}</label>
                        <div class="ml-auto">
                            <ECheckbox
                                id="folders-check"
                                v-model="searchFoldersLocal"
                                :disabled="anyLoading"
                                success
                                @change="resetFolderSearch"/>
                        </div>
                    </div>
                    <div v-if="hasInitialFiles" class="d-flex mr-3">
                        <label for="document-check" class="mr-2">{{ $t('Files') }}</label>
                        <div class="ml-auto">
                            <ECheckbox
                                id="document-check"
                                v-model="searchDocumentsLocal"
                                :disabled="anyLoading"
                                success
                                @change="resetDocumentSearch"/>
                        </div>
                    </div>
                </div>
                <ESearchInput
                    v-if="hasInitialItems"
                    v-model="searchValueLocal"
                    class="ml-auto"
                    :disabled="anyLoading"
                    :placeholder="$t('Type to search')"
                    @getEntities="search">
                </ESearchInput>
            </div>
        </div>
        <div>
            <div class="mb-2 d-flex flex-wrap flex-column flex-md-row" :class="{'mx - n2': subFolders.length}">
                <template v-if="!isContentLoading">
                    <template v-if="subFolders.length">
                        <div class="d-flex flex-column flex-lg-row flex-wrap w-100 mx-lg-n2">
                            <div v-for="f in subFolders.slice(0, perPage)" :key="f.id" class="e3-folder-group mx-lg-2 my-lg-2">
                                <FolderContainer :id="`folder-${f.id}`" :folder="f" :type="type" tools :searchValue="searchValue"/>
                            </div>
                        </div>
                        <div v-if="folderTotalFolders > perPage" class="d-flex justify-content-center w-100 mt-3 mb-2 mb-lg-0">
                            <button class="btn btn-primary px-4" @click="showMoreFolders()">
                                {{ $t("See more") }}
                            </button>
                        </div>
                    </template>
                    <EmptyListMessage v-else-if="searchValue && searching" class="border rounded w-100 p-2 bg-white">
                        {{ $t(notFoundMsg) }}
                    </EmptyListMessage>
                    <EmptyListMessage v-else class="border rounded w-100 p-2 bg-white">
                        {{ $t(emptyMsg) }}
                    </EmptyListMessage>
                </template>
                <template v-else>
                    <div class="w-100">
                        <ELoader/>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FolderContainer from '@views/folder/components/FolderContainer';

export default {
    props: {
        folderType: String,
        title: String,
        type: String,
        emptyMsg: String,
        perPage: Number,
        searchValue: String,
        notFoundMsg: String,
        searching: Boolean,
        hasInitialItems: Boolean,
        anyLoading: Boolean,
        hasInitialFolders: Boolean,
        hasInitialFiles: Boolean,
        searchFolders: Boolean,
        searchDocuments: Boolean,
    },
    data() {
        return {
            searchValueLocal: this.searchValue,
            searchFoldersLocal: this.searchFolders,
            searchDocumentsLocal: this.searchDocuments,
        };
    },
    computed: {
        ...mapGetters([
            'isContentLoading',
            'subFolders',
            'folderTotalFolders'
        ]),
        isGallery() {
            return this.type === 'gallery';
        }
    },
    methods: {
        showMoreFolders(){
            this.$emit('loadMore');
        },
        search() {
            this.$emit('search', this.searchValueLocal);
        },
        resetFolderSearch() {
            this.$emit('resetFolderSearch');
        },
        resetDocumentSearch() {
            this.$emit('resetDocumentSearch');
        },
    },
    watch: {
        searchValue(newValue) {
            this.searchValueLocal = newValue;
        },
        searchFolders(newValue) {
            this.searchFoldersLocal = newValue;
        },
        searchDocuments(newValue) {
            this.searchDocumentsLocal = newValue;
        },
    },
    components: {
        FolderContainer,
    },
};
</script>
