import http from '@http';
import {download} from '@services/helper.service';

class ChildDevService {
    constructor() {
        if (ChildDevService.instance) {
            return ChildDevService.instance;
        }

        ChildDevService.instance = this;
    }

    //view based requests
    categoriesForChild(kindergartenId, childId, options = {}) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/${childId}/categories`, {params: options})
            .then(res => res.data);
    }

    categoriesForGoals(kindergartenId, options = {}) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/goals-categories`, {params: options})
            .then(res => res.data);
    }

    //Get various additional data for cdm lke age_groups or options and so on
    generalSettings(kindergartenId, options = {}) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/general-data`, {params: options})
            .then(res => res.data);
    }

    // options
    cdmSettings(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/settings`).then(res => res.data);
    }

    cdmVerboseSettings(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/settings-verbose`).then(res => res.data);
    }

    employeeRoles(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/employee-roles`).then(res => res.data);
    }

    courseChildren(kindergartenId, courseId) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/course/${courseId}/children`).then(res => res.data);
    }

    options(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/options`).then(res => res.data);
    }

    addOption(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/child-dev/options/store`, data).then(res => res.data);
    }
    updateOptions(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/child-dev/options/update`, data).then(res => res.data);
    }

    removeOption(kindergartenId, optionId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/child-dev/options/${optionId}`).then(res => res.data);
    }

    // age groups
    ageGroups(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/age-groups`).then(res => res.data);
    }

    storeAgeGroup(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/child-dev/age-groups/store`, data).then(res => res.data);
    }

    updateAgeGroup(kindergartenId, groupId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/child-dev/age-groups/${groupId}/update`, data).then(res => res.data);
    }

    destroyAgeGroup(kindergartenId, groupId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/child-dev/age-groups/${groupId}/destroy`).then(res => res.data);
    }

    // categories

    categories(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/categories`).then(res => res.data);
    }

    kindergartenCategories(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/kindergarten-categories`).then(res => res.data);
    }

    storeCategory(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/child-dev/categories/store`, data).then(res => res.data);
    }

    updateCategory(kindergartenId, categoryId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/child-dev/categories/${categoryId}/update`, data).then(res => res.data);
    }

    destroyCategory(kindergartenId, categoryId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/child-dev/categories/${categoryId}/destroy`).then(res => res.data);
    }

    // goals
    storeGoal(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/child-dev/goals/store`, data).then(res => res.data);
    }

    updateGoal(kindergartenId, goalId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/child-dev/goals/${goalId}/update`, data).then(res => res.data);
    }

    destroyGoal(kindergartenId, goalId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/child-dev/goals/${goalId}/destroy`).then(res => res.data);
    }

    goal(kindergartenId, goalId, options = {}) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/goals/${goalId}/show`, {params: options}).then(res => res.data);
    }

    copyGoals(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/goals/copy`).then(res => res.data);
    }

    // evaluation
    evaluate(kindergartenId, childId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/child-dev/${childId}/evaluate`, data).then(res => res.data);
    }

    printChildGoals(kindergartenId, child, queryObj) {
        return http.download(`/api/kindergartens/${kindergartenId}/child-dev/${child.id}/print`, {params: queryObj})
            .then(res => {
                const file = {
                    mime_type: 'application/pdf',
                    name: `${child.name}.pdf`
                };
                download(res.data, file);
            });
    }

    // comments
    storeComment(kindergartenId, childId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/child-dev/${childId}/comments/store`, data).then(res => res.data);
    }

    updateComment(kindergartenId, commentId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/child-dev/comments/${commentId}/update`, data).then(res => res.data);
    }

    destroyComment(kindergartenId, commentId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/child-dev/comments/${commentId}/destroy`).then(res => res.data);
    }

    getCategoryData(kindergartenId, childId, categoryId) {
        return http.get(`/api/kindergartens/${kindergartenId}/child-dev/${childId}/categories/${categoryId}`).then(res => res.data);
    }


}

export default new ChildDevService();
