export const GET_USER_DASHBOARD = 'GET_USER_DASHBOARD';
export const SET_DASHBOARD_MESSAGES = 'SET_DASHBOARD_MESSAGES';
export const SET_DASHBOARD_ANNOUNCEMENT = 'SET_DASHBOARD_ANNOUNCEMENT';
export const SET_DASHBOARD_APPLICATIONS = 'SET_DASHBOARD_APPLICATIONS';
export const SET_DASHBOARD_BIRTHDAYS = 'SET_DASHBOARD_BIRTHDAYS';
export const GET_DASHBOARD_BIRTHDAYS = 'GET_DASHBOARD_BIRTHDAYS';
export const SET_DASHBOARD_ATTENDANCE_COUNT = 'SET_DASHBOARD_ATTENDANCE_COUNT';
export const GET_DASHBOARD_ATTENDANCE_COUNT = 'GET_DASHBOARD_ATTENDANCE_COUNT';
export const SET_DASHBOARD_DIARY_COMMENTS = 'SET_DASHBOARD_DIARY_COMMENTS';
export const GET_DASHBOARD_DIARY_COMMENTS = 'GET_DASHBOARD_DIARY_COMMENTS';
export const SET_DASHBOARD_ACTIVITY = 'SET_DASHBOARD_ACTIVITY';
export const RESET_DASHBOARD_STORE = 'RESET_DASHBOARD_STORE';
export const SET_DASHBOARD_EVENTS = 'SET_DASHBOARD_EVENTS';
export const SET_DASHBOARD_WORK_SCHEDULES = 'SET_DASHBOARD_WORK_SCHEDULES';
export const SET_DASHBOARD_EMPLOYEE_BIRTHDAYS = 'SET_DASHBOARD_EMPLOYEE_BIRTHDAYS';
export const SET_DASHBOARD_POLL_DATA = 'SET_DASHBOARD_POLL_DATA';
export const SET_DASHBOARD_QUESTIONNAIRES = 'SET_DASHBOARD_QUESTIONNAIRES';
export const SUBMIT_DASHBOARD_POLL = 'SUBMIT_DASHBOARD_POLL';
export const GET_DASHBOARD_POLL = 'GET_DASHBOARD_POLL';
export const GET_EMPLOYEE_DASHBOARD_SYNC = 'GET_EMPLOYEE_DASHBOARD_SYNC';
export const SET_DASHBOARD_COURSES_LOADING = 'SET_DASHBOARD_COURSES_LOADING';
