<template>
    <div :class="{'float-left': left}">
        <label :for="id" class="btn btn-success" :class="labelClass">
            {{ $t(title) }}
        </label>
        <input :id="id" class="file-input" :accept="accept" type="file" @change="loadFile" @click="$emit('click')"/>
    </div>
</template>

<script>
export default {
    name: 'EUploadBtn',
    props: {
        id: [String, Number],
        title: String,
        accept: String,
        labelClass: String,
        left: Boolean
    },
    methods: {
        loadFile(e) {
            this.$emit('change', e.target.files[0]);
        }
    }
};
</script>
<style scoped>
.file-input {
    display: none;
}
</style>
