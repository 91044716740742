import http from '@http';

class BannerService {
    constructor() {
        if (BannerService.instance) {
            return BannerService.instance;
        }
        BannerService.instance = this;
    }

    getAllBanners() {
        return http.get('/api/admin/banners').then(res => res.data);
    }

    getUserBanners(type, id) {
        return http.get(`/api/${type}/${id}/banners`).then(res => res.data);
    }

    store(data) {
        return http.post('/api/admin/banners', data).then(res => res.data);
    }

    update(data) {
        return http.post(`/api/admin/banners/${data.get('id')}?_method=PUT`, data).then(res => res.data);
    }

    deleteBanner(banner_id, data) {
        return http.delete(`/api/admin/banners/${banner_id}`, data).then(res => res.data);
    }

    reprioritize(data) {
        return http.put('/api/admin/banners', data).then(res => res.data);
    }
}

export default new BannerService();
