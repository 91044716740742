import http from '@http';

class SupplierService {
    constructor() {
        if (SupplierService.instance) {
            return SupplierService.instance;
        }
        SupplierService.instance = this;
    }

    deactivateSupplier(supplierId) {
        return http.delete(`/api/suppliers/${supplierId}/deactivate`).then(res => res.data);
    }

    agreeTerms(supplierId) {
        return http.put(`/api/suppliers/${supplierId}/agree`).then(res => res.data);
    }

    update(supplierId, form) {
        return http.put(`/api/suppliers/${supplierId}/update`, form).then(res => res.data);
    }

    getSupplierSettings(supplierId, form) {
        return http.get(`/api/suppliers/${supplierId}/`, form).then(res => res.data);
    }

    updateSettings(supplierId, form) {
        return http.put(`/api/suppliers/${supplierId}/settings/update`, form).then(res => res.data);
    }

    supplierOrgs(supplierId) {
        return http.get(`/api/suppliers/${supplierId}/orgs`).then(res => res.data);
    }

    addCountry(supplierId, form) {
        return http.post(`/api/suppliers/${supplierId}/country`, form).then(res => res.data);
    }

    removeCountry(supplierId, countryId) {
        return http.delete(`/api/suppliers/${supplierId}/country/${countryId}`).then(res => res.data);
    }

    // products
    searchSupplierProducts(supplierId, term) {
        return http.get(`/api/suppliers/${supplierId}/product-search?term=${term}`).then(res => res.data);
    }

    searchSupplierServices(supplierId, term) {
        return http.get(`/api/suppliers/${supplierId}/service-search?term=${term}`).then(res => res.data);
    }
    products(supplierId, queryObj) {
        return http.get(`/api/suppliers/${supplierId}/products`, { params: queryObj }).then(res => res.data);
    }
    product(supplierId, productId) {
        return http.get(`/api/suppliers/${supplierId}/products/${productId}/show`).then(res => res.data);
    }
    storeProduct(supplierId, form) {
        return http.post(`/api/suppliers/${supplierId}/products/store`, form).then(res => res.data);
    }
    updateProduct(supplierId, form, productId) {
        return http.post(`/api/suppliers/${supplierId}/products/${productId}/update`, form).then(res => res.data);
    }
    deleteProduct(supplierId, productId) {
        return http.delete(`/api/suppliers/${supplierId}/products/${productId}/destroy`).then(res => res.data);
    }
    deleteProductImg(supplierId, fileId) {
        return http.delete(`/api/suppliers/${supplierId}/shop_product/${fileId}`).then(res => res.data);
    }

    //Services
    services(supplierId, queryObj) {
        return http.get(`/api/suppliers/${supplierId}/services`, { params: queryObj }).then(res => res.data);
    }
    service(supplierId, productId) {
        return http.get(`/api/suppliers/${supplierId}/services/${productId}/show`).then(res => res.data);
    }
    storeService(supplierId, form) {
        return http.post(`/api/suppliers/${supplierId}/services/store`, form).then(res => res.data);
    }
    updateService(supplierId, form, productId) {
        return http.post(`/api/suppliers/${supplierId}/services/${productId}/update`, form).then(res => res.data);
    }
    deleteService(supplierId, productId) {
        return http.delete(`/api/suppliers/${supplierId}/services/${productId}/destroy`).then(res => res.data);
    }
    deleteServiceImg(supplierId, fileId) {
        return http.delete(`/api/suppliers/${supplierId}/shop_service/${fileId}`).then(res => res.data);
    }

    // price lists
    pricelists(supplierId, queryObj) {
        return http.get(`/api/suppliers/${supplierId}/price-lists`, { params: queryObj }).then(res => res.data);
    }
    pricelist(supplierId, pricelistId) {
        return http.get(`/api/suppliers/${supplierId}/price-lists/${pricelistId}/show`).then(res => res.data);
    }
    priceListProducts(supplierId, pricelistId, queryObj) {
        return http.get(`/api/suppliers/${supplierId}/price-lists/${pricelistId}/products`, { params: queryObj }).then(res => res.data);
    }
    priceListServices(supplierId, pricelistId, queryObj) {
        return http.get(`/api/suppliers/${supplierId}/price-lists/${pricelistId}/services`, { params: queryObj }).then(res => res.data);
    }
    storePricelist(supplierId, form) {
        return http.post(`/api/suppliers/${supplierId}/price-lists/store`, form).then(res => res.data);
    }
    updatePricelist(supplierId, form, pricelistId) {
        return http.put(`/api/suppliers/${supplierId}/price-lists/${pricelistId}/update`, form).then(res => res.data);
    }
    deletePricelist(supplierId, pricelistId) {
        return http.delete(`/api/suppliers/${supplierId}/price-lists/${pricelistId}/destroy`).then(res => res.data);
    }
    reorderPricelist(supplierId, form) {
        return http.put(`/api/suppliers/${supplierId}/price-lists/reorder`, form).then(res => res.data);
    }

    filters(supplierId) {
        return http.get(`/api/suppliers/${supplierId}/filters`).then(res => res.data);
    }

    //Orders
    orders(supplierId, queryObj) {
        return http.get(`/api/suppliers/${supplierId}/orders`, {params: queryObj}).then(res => res.data);
    }

    order(supplierId, orderId) {
        return http.get(`/api/suppliers/${supplierId}/orders/${orderId}`).then(res => res.data);
    }

    rejectOrder(supplierId, orderId, reason) {
        return http.put(`/api/suppliers/${supplierId}/orders/${orderId}/reject`, {reason}).then(res => res.data);
    }

    confirmOrder(supplierId, orderId) {
        return http.put(`/api/suppliers/${supplierId}/orders/${orderId}/confirm`).then(res => res.data);
    }

    updateOrder(supplierId, orderId, data) {
        return http.put(`/api/suppliers/${supplierId}/orders/${orderId}/update`, data).then(res => res.data);
    }

    categories(supplierId) {
        return http.get(`/api/suppliers/${supplierId}/categories`).then(res => res.data);
    }

    productCategories(supplierId) {
        return http.get(`/api/suppliers/${supplierId}/products/categories`).then(res => res.data);
    }

    serviceCategories(supplierId) {
        return http.get(`/api/suppliers/${supplierId}/services/categories`).then(res => res.data);
    }
}

export default new SupplierService();
