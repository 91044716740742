
import MapService from '@services/map.service';
import {
    GET_MAP,
    SET_MAP,
    UPDATE_MAP_FIELD,
    PUBLISH_MAP,
    SET_PUBLISHED,
    RENAME_MAP,
    DELETE_MAP,
    APPROVE_MAP,
    SET_MAP_APPROVALS,
    DISAPPROVE_MAP,
    SET_MAP_APPROVAL
} from './actions';
import {UPDATE_CHILD_MAP} from '../child/actions';
import Vue from 'vue';

const state = {
    map: {}
};

const mutations = {
    [SET_MAP](state, map) {
        state.map = map;
    },
    [SET_PUBLISHED](state, published) {
        state.map.published = published;
    },
    [UPDATE_MAP_FIELD](state, field) {
        let index = state.map.data.findIndex(f => f.field_id === field.field_id && f.employee_id === field.employee_id);
        
        if(index !== -1) {
            Vue.set(state.map.data, index, field);
        } else {
            state.map.data.push(field);
        }
    },
    [DELETE_MAP](state) {
        state.map = {};
    },
    [SET_MAP_APPROVALS](state, approvals) {
        state.map.approvals = approvals;
    },
    [SET_MAP_APPROVAL](state, approval) {
        if(!state.map.approvals) {
            state.map.approvals = [approval];
        } else {
            let index = state.map.approvals.findIndex(a => a.created_by === approval.created_by);
            if(index !== -1) {
                Vue.set(state.map.approvals, index, approval);
            } else {
                state.map.approvals.push(approval);
            }
        }
    }
};
const actions = {
    [GET_MAP]({commit, getters}, {childId, mapId}) {
        return MapService.getMap(getters.activeKindergartenId, childId, mapId).then(res => {
            commit(SET_MAP, res);
        });
    },
    [UPDATE_MAP_FIELD]({commit, getters}, {childId, mapId, data}) {
        return MapService.updateField(getters.activeKindergartenId, childId, mapId, data).then(res => {
            commit(UPDATE_MAP_FIELD, res);
            if(res.approvals) {
                commit(SET_MAP_APPROVALS, res.approvals);
            }
        });
    },
    [PUBLISH_MAP]({commit, getters}, {childId, mapId, form}) {
        return MapService.publishMap(getters.activeKindergartenId, childId, mapId, form).then(res => {
           commit(SET_PUBLISHED, res.published);
        });
    },
    [RENAME_MAP]({commit, getters},  {childId, mapId, form}) {
        return MapService.renameMap(getters.activeKindergartenId, childId, mapId, form).then(res => {
            commit(UPDATE_CHILD_MAP, res);
        });
    },
    [DELETE_MAP]({commit, getters}, {childId, mapId}) {
        return MapService.deleteMap(getters.activeKindergartenId, childId, mapId).then(res => {
            commit(DELETE_MAP, res);
        });
    },
    [APPROVE_MAP]({commit, getters}, {mapId, childId, form}) {
        return MapService.approveMap(getters.activeKindergartenId, childId, mapId, form).then(res => {
            commit(SET_MAP_APPROVALS, res);
        });
    },
    [DISAPPROVE_MAP]({commit, getters}, {mapId, childId, form}) {
        return MapService.disapproveMap(getters.activeKindergartenId, childId, mapId, form).then(res => {
            commit(SET_MAP_APPROVALS, res);
        });
    },
};
const getters = {
    currentMap: state => {
        return state.map;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
