import {
    GET_THIS_CLASSES,
    SET_THIS_CLASSES,
    SET_THIS_ERROR,
    SET_THIS_CHILDREN,
    RESET_THIS_STATE,
    STORE_THIS_PERIOD,
    SET_THIS_CLASS,
    STORE_THIS_COURSE,
    UPDATE_THIS_CLASS_CHILDREN,
    SET_THIS_CLASS_CHILDREN,
    GET_THIS_CLASS_CHILDREN,
    CHECK_THIS_CLASS_CHILDREN,
    UPDATE_ELIIS_CLASS_CHILDREN_COUNT,
} from './actions';
import Vue from 'vue';
import ThisService from '@services/this.service';
const getDefaultState = () => {
    return {
        classes: [],
        error_message: null,
        children: []
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_THIS_STATE](){
        Object.assign(state, getDefaultState());
    },
    [SET_THIS_CLASSES](state, classes) {
        state.classes = classes;
    },
    [SET_THIS_CHILDREN](state, children) {
        state.children = children;
    },
    [SET_THIS_ERROR](state, error) {
        state.error_message = error;
    },
    [SET_THIS_CLASS](state, course) {
        let index = state.classes.findIndex(c => c.ehiscode === course.ehiscode);
        if(index !== -1) {
            Vue.set(state.classes, index, course);
        }
    },
    [CHECK_THIS_CLASS_CHILDREN](state, data) {
        state.children = data;
        // let class_children = state.children[ehisCode];
        // response.forEach(child => {
        //     let index = class_children.findIndex(c => c.idcode === child.idcode);
        //     if(index !== -1) {
        //         Vue.set(class_children, index, child);
        //     } else {
        //         class_children.push(child);
        //     }
        // });
        // Vue.set(state.children, ehisCode, class_children);
    },
    [SET_THIS_CLASS_CHILDREN](state, {ehisCode, form, response}) {
        let class_children = state.children[ehisCode];
        if(class_children) {
            form.forEach(child => {
                let index = class_children.findIndex(c => c.idcode === child.idcode);
                if(index !== -1) {
                    //find children meant to be removed, they are not in the response
                    if(child.status === 'remove_child') {
                        class_children.splice(index, 1);
                    } else {
                        let response_child = response.find(c => c.idcode === child.idcode);
                        if(response_child) {
                            Vue.set(class_children, index, response_child);
                        }
                    }
                }
            });
        }
        Vue.set(state.children, ehisCode, class_children);
    },
    [UPDATE_ELIIS_CLASS_CHILDREN_COUNT](state, payload) {
        const index = state.classes.findIndex(c => c.ehiscode === payload.course.ehisCode);
        if (index !== -1) {
            const course = state.classes[index];
            course.children_count = payload.children.length;
            Vue.set(state.classes, index, course);
        }
    }
};

const actions = {
    [GET_THIS_CLASSES]({commit, getters}, options) {
        commit(RESET_THIS_STATE);
        return ThisService.getClasses(getters.activeKindergartenId, options).then(res => {
            if(res.error) {
                commit(SET_THIS_ERROR, res.message);
            } else {
                commit(SET_THIS_CLASSES, res);
                // commit(SET_THIS_CHILDREN, res.children);
            }
        });
    },
    [STORE_THIS_PERIOD]({commit, getters}, data) {
        return ThisService.storePeriod(getters.activeKindergartenId, data).then(res => {
            commit(SET_THIS_CLASS, res);
        });
    },
    [STORE_THIS_COURSE]({commit, getters}, courseId) {
        return ThisService.storeCourse(getters.activeKindergartenId, courseId).then(res => {
            commit(SET_THIS_CLASS, res);
            return res;
        });
    },
    [GET_THIS_CLASS_CHILDREN]({commit, getters}, data) {
        return ThisService.getThisChildrenStatus(getters.activeKindergartenId, data).then(res => {
            commit(CHECK_THIS_CLASS_CHILDREN, res);
        });
    },
    [UPDATE_THIS_CLASS_CHILDREN]({commit, getters}, {course, form}) {
        return ThisService.updateChildren(getters.activeKindergartenId, course.id, form).then(res => {
            commit(UPDATE_ELIIS_CLASS_CHILDREN_COUNT, { course, children: res });
            commit(SET_THIS_CLASS_CHILDREN, {
                ehisCode: course.ehisCode,
                form: form.children,
                response: res
            });
        });
    }
};

const getters = {
    thisClasses: state => state.classes,
    thisError: state => state.error_message,
    thisClassChildren: state => state.children
};

export default {
    state,
    mutations,
    actions,
    getters
};
