const FoodMenu = () =>
    import(/* webpackChunkName: "foodmenu-module" */ '@views/menu/FoodMenu');
const Day = () =>
    import(/* webpackChunkName: "foodmenu-module" */ '@views/menu/views/Day');
const Week = () =>
    import(/* webpackChunkName: "foodmenu-module" */ '@views/menu/views/Week');
const EmployeeMeals = () =>
    import(/* webpackChunkName: "foodmenu-module" */'@views/menu/views/EmployeeMeals');
const Settings = () =>
    import(/* webpackChunkName: "foodmenu-module" */'@views/menu/views/Settings');
import store from '@/store';

export default [
    {
        path: '/menu',
        components: { base: FoodMenu },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessFoodmenuIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: 'day',
                name: 'foodmenu.day',
                components: { foodmenu: Day }
            },
            {
                path: 'week',
                name: 'foodmenu.weekly',
                components: { foodmenu: Week }
            },
            {
                path: '/statistics/menu',
                name: 'foodmenu.statistics'
            },
        ]
    },
    {
        path: '/menu/employees',
        name: 'foodmenu.employee.meals',
        components: {base: EmployeeMeals}
    },
    {
        path: '/menu/settings',
        name: 'foodmenu.settings',
        components: {base: Settings}
    }
];
