<template>
    <div class="e3-action-btn-outer">
        <div v-if="subBtnsVisible" class="backdrop"/>
        <div v-click-outside="close" class="e3-action-btn-container e3-action-btn" :class="{'z-index' : subBtnsVisible}">
            <transition
                name="fade"
                tag="div" mode="out-in">
                <div v-if="hasSubButtons && subBtnsVisible" class="e3-action-sub-container">
                    <button
                        v-for="(button, index) in subButtons"
                        :key="`${button.title} - ${index}`"
                        class="btn btn-primary shadow d-flex align-items-center ml-auto mb-3 text-nowrap"
                        @click="button.action ? button.action() : null; toggleSubBtns()">
                        <SvgIcon :icon="button.icon" class="text-white mr-2" size="28"/>
                        {{ button.title }}
                    </button>
                </div>
            </transition>
            <button class="e3-action-btn" @click="hasSubButtons ? toggleSubBtns() : add()">
                <SvgIcon
                    icon="mdi-plus-thick"
                    size="36"
                    class="text-white"
                    :class="{'rotate-45' : subBtnsVisible}"/>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        subButtons: Array,
    },
    data() {
        return {
            subBtnsVisible: false,
            // EXAMPLE PROP
            subButtonsProp: [
                { title: 'My button', icon: 'mdi-paperclip' },
                { title: 'Other button', icon: 'mdi-chevron-right', action: () => {this.test();} },
            ]
        };
    },
    computed: {
        hasSubButtons() {
            return this.subButtons?.length > 0;
        }
    },
    methods: {
        add() {
            this.$emit('addNewClicked');
        },
        toggleSubBtns() {
            this.subBtnsVisible = !this.subBtnsVisible;
        },
        close() {
            if (this.hasSubButtons && this.subBtnsVisible) {
                this.subBtnsVisible = false;
            }
        }
    },
    created() {
        this.$store.commit('SET_ACTION_BUTTON_ACTIVE', true);
    },
    destroyed() {
        this.$store.commit('SET_ACTION_BUTTON_ACTIVE', false);
    },
};
</script>

<style scoped lang="scss">
@import "@common/styles/variables/_variables.scss";

.e3-action-btn-outer {
    display: inline-flex;
    width: 62px;
}


@media screen and (max-width: $grid-br-md) {
    .backdrop {
        background-color: $light-gray;
        opacity: 40%;
        position: absolute;
        bottom: -16px;
        right: -16px;
        z-index: 10000;
        width: 100vw;
        height: 100vh;
    }
}

div:not(.fixed-buttons) > .e3-action-btn-container {
    position: fixed;
    right: 16px;
    bottom: 16px;
    &.z-index {
        z-index: 10001;
    }
}

.e3-action-sub-container {
    position: absolute;
    bottom: 62px;
    right: 0;
}

.e3-action-btn {
    z-index: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    background-color: #f79e80;
    border: none;
    border-radius: 50%;
    transition: box-shadow .2s ease-in;
    animation: pulse 2s 5;

    &:hover, &:focus {
        outline: none;
        animation: none;
    }

    i {
        transition: all .2s ease-in;
    }
}

.rotate-45 {
    transform: rotate(45deg);
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(#f79e80, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(#f79e80, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(#f79e80, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(#f79e80, 0.4);
        box-shadow: 0 0 0 0 rgba(#f79e80, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(#f79e80, 0);
        box-shadow: 0 0 0 10px rgba(#f79e80, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(#f79e80, 0);
        box-shadow: 0 0 0 0 rgba(#f79e80, 0);
    }
}
</style>
