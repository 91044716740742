import store from '@/store';

const ProjectPlans = () =>
    import(
        /* webpackChunkName: "plan-module" */ '@views/plan/project/Projectplans'
    );
const Plan = () => import('@views/plan/project/views/Plan');
const Overview = () => import('@views/plan/project/views/Overview');
const Templates = () => import('@views/plan/project/views/Templates');
export default [
    {
        path: '/plans/project',
        components: { base: ProjectPlans },
        children: [
            {
                path: '',
                name: 'plans.prjplan',
                components: { projectplan: Plan },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessProjectPlan) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'overview',
                name: 'plans.prjplan.overview',
                components: { projectplan: Overview },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessOverView) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'templates',
                name: 'plans.prjplan.templates',
                components: { projectplan: Templates },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessTemplates) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            }
        ]
    }
];
