import {
    GET_PERMISSIONS,
    GET_USER,
    GET_USER_MENU,
    GET_USER_PROFILE,
    GET_USER_TARGETGROUPS,
    RESET_USER_STATE,
    RESET_USER_LOGIN,
    SET_LOGGED_IN_FLAG,
    SET_PERMISSIONS,
    SET_USER,
    SET_USER_KINDERGARTENS,
    SET_USER_MENU,
    SET_USER_PIC,
    SET_USER_TARGETGROUPS,
    UPDATE_USER,
    UPDATE_USER_PRIVACY,
    UPDATE_USER_PERMISSIONS,
    SET_USER_LOGIN,
    GET_USER_EMPLOYEES, SET_USER_CHILDREN,
    SET_USER_ORGANIZATIONS,
    SET_USER_EMPLOYEES,
    UPDATE_EMPLOYEE_DATA,
    UPDATE_EMPLOYEE_SETTING,
    SET_EMPLOYEE_SETTINGS,
    SET_USER_OFFICIALS,
    UPDATE_GUARDIAN_SETTINGS,
    UPDATE_OFFICIAL_SETTINGS,
    SET_USER_GUARDIANS,
    GET_USER_HEADER_INFO,
    SET_USER_SUPPLIERS,
    SET_USER_SUPPLIER_REPS,
    RESET_USER_BANNER_STATE,
    GET_USER_BANNERS,
    SET_USER_BANNERS,
    GET_USER_OAUTH_CLIENTS,
    SET_USER_OAUTH_CLIENTS,
    REVOKE_USER_OAUTH_CLIENT,
    REMOVE_USER_OAUTH_CLIENT,
    SET_USER_LANGUAGE,
    GET_RECENT_USER_CART,
    SET_RECENT_USER_CART,
    REMOVE_FROM_RECENT_USER_CART,
    UPDATE_USER_NAME
} from './actions';
import Vue from 'vue';
import {START_FULL_PAGE_LOADER, STOP_FULL_PAGE_LOADER} from '@store/loader/actions';
import {permissions} from './permissions';
import UserService from '@services/user.service';
import FileService from '@services/file.service';
import EmployeeService from '@services/employee.service';
import RecentService from '@services/recent.service';
import BannerService from '@services/banner.service';
import { SET_LANGUAGE } from '@store/localization/constants';
import orderBy from 'lodash/orderBy';
import {DAY, hasPassed} from '../../storeHelpers';
import NotificationService from '@services/notification.service';

const getDefaultState = () => {
    return {
        user: false,
        logged_in_as: false,
        user_menu: [],
        user_kindergartens: [],
        user_organizations: [],
        user_suppliers: [],
        permissions: {},
        picture: '',
        employees: [],
        guardians: [],
        officials: [],
        supplierReps: [],
        children: [],
        targetGroups: [],
        login: '',
        banners: {value: [], date: null},
        oauth_clients: [],
        user_cart: {
            count: 0,
            sum: 0,
            items: []
        }
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_USER_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_USER](state, data) {
        state.user = data;
        state.targetGroups = [];
    },
    [SET_USER_PIC](state, data) {
        if (data) {
            try {
                // If URL object is successfully created, the data is a valid URL and can be accessed directly.
                new URL(data);
                state.picture = data;
            } catch (_) {
                FileService.getUserProfilePicture(data).then(res => {
                    state.picture = window.URL.createObjectURL(res);
                    //window.URL.revokeObjectURL(state.picture);
                });
            }
        } else {
            state.picture = '';
        }
    },
    [SET_USER_MENU](state, data) {
        state.user_menu = data;
    },
    [SET_USER_KINDERGARTENS](state, data) {
        state.user_kindergartens = data;
    },
    [SET_PERMISSIONS](state, data) {
        state.permissions = data;
    },
    [SET_LOGGED_IN_FLAG](state, status) {
        state.logged_in_as = status;
    },
    [SET_USER_TARGETGROUPS](state, data) {
        state.targetGroups = data;
    },
    [UPDATE_USER_PRIVACY](state, data) {
        state.user.privacy_confirmed = data;
    },
    [UPDATE_USER_PERMISSIONS](state, {permission, remove}) {
        if(!state.permissions[permission.node] && !remove) {
            Vue.set(state.permissions, permission.node, permission.id);
        } else {
            Vue.delete(state.permissions, permission.node);
        }
    },
    [SET_USER_LOGIN](state, login) {
        state.login = login;
    },
    [SET_USER_CHILDREN](state, data) {
        state.children = data;
    },
    [SET_USER_ORGANIZATIONS](state, organizations) {
        state.user_organizations = organizations;
    },
    [SET_USER_EMPLOYEES](state, employees) {
        state.employees = employees;
    },
    [SET_EMPLOYEE_SETTINGS](state, data) {
        let index = state.employees.findIndex(employee => employee.id === data.employee_id);
        Vue.set(state.employees[index], 'settings', data.settings);
    },
    [UPDATE_EMPLOYEE_DATA](state, data) {
        let index = state.employees.findIndex(employee => employee.id === data.employee_id);
        Vue.set(state.employees[index], 'data', data.data);
    },
    [UPDATE_GUARDIAN_SETTINGS](state, data) {
        let index = state.guardians.findIndex(guardian => guardian.id === data.guardian_id);
        Vue.set(state.guardians[index], 'settings', data.settings);
    },
    [UPDATE_OFFICIAL_SETTINGS](state, data) {
        let index = state.officials.findIndex(o => o.id === data.official_id);
        Vue.set(state.officials[index], 'settings', data.settings);
    },
    [SET_USER_OFFICIALS](state, officials) {
        state.officials = officials;
    },
    [SET_USER_GUARDIANS](state, data) {
        state.guardians = data;
    },
    [SET_USER_SUPPLIERS](state, data) {
        state.user_suppliers = data;
    },
    [SET_USER_SUPPLIER_REPS](state, data) {
        state.supplierReps = data;
    },
    [RESET_USER_BANNER_STATE](state) {
        state.banners = getDefaultState().banners;
    },
    [SET_USER_BANNERS](state, banners) {
        state.banners.value = orderBy([...banners], ['priority'], ['asc']);
    },
    [SET_USER_OAUTH_CLIENTS](state, clients) {
        state.oauth_clients = clients;
    },
    [REMOVE_USER_OAUTH_CLIENT](state, client_id) {
        const index = state.oauth_clients.findIndex(c => c.client_id === client_id);
        Vue.delete(state.oauth_clients, index);
    },
    [SET_USER_LANGUAGE](state, lang) {
        state.user.language = lang;
        state.user.language_id = lang.id;
    },
    [SET_RECENT_USER_CART](state, cart) {
        state.user_cart = cart;
    },
    [REMOVE_FROM_RECENT_USER_CART](state, id) {
        let itemIndex = state.user_cart.items.findIndex(i => i.id == id);

        state.user_cart.items.splice(itemIndex, 1);
        Vue.set(state.user_cart, 'count', state.user_cart.count - 1);
    },
};
const actions = {
    [GET_USER]({commit, getters, dispatch}, platform = null) {
        commit(START_FULL_PAGE_LOADER);
        return UserService.getUserData(platform).then(res => {
            dispatch('SET_LARAVEL_ECHO');
            if (localStorage.getItem('login_as')) {
                commit(SET_LOGGED_IN_FLAG, true);
            }
            commit(SET_USER_LOGIN, res.login);
            let organization_id = localStorage.getItem('organization_id');
            let kindergarten_id = localStorage.getItem('kindergarten_id');
            let supplier_id = localStorage.getItem('supplier_id');
            if(res.organizations.length) {
                commit(SET_USER_ORGANIZATIONS, res.organizations);
                if(!kindergarten_id && !supplier_id) {
                    if(organization_id && getters.userOrganizations.find(o => o.id == organization_id)) {
                        commit('SET_ORGANIZATION', getters.userOrganizations.find(o => o.id == organization_id));
                    } else {
                        commit('SET_ORGANIZATION', res.organizations[0]);
                    }
                }
            }

            if(res.suppliers.length) {
                commit(SET_USER_SUPPLIERS, res.suppliers);
                if(!getters.activeOrganizationId) {
                    if(!kindergarten_id && !getters.activeOrganizationId) {
                        if(supplier_id && getters.userSuppliers.find(supplier => supplier.id === supplier_id)) {
                            commit('SET_SUPPLIER', getters.userSuppliers.find(supplier => supplier.id === supplier_id));
                        } else {
                            commit('SET_SUPPLIER', res.suppliers[0]);
                        }
                    }
                }
            }

            if (res.kindergartens.length) {
                commit(SET_USER_KINDERGARTENS, res.kindergartens);
                if(!getters.activeOrganizationId && !getters.activeSupplierId) {
                    if (kindergarten_id && getters.userKindergartens.find(kindergarten => kindergarten.id == kindergarten_id)) {
                        commit('SET_KINDERGARTEN', getters.userKindergartens.find(kindergarten => kindergarten.id == kindergarten_id));
                    } else {
                        commit('SET_KINDERGARTEN', res.kindergartens[0]);
                    }
                    dispatch('GET_KINDERGARTEN_LANGUAGES');
                }
            }

            if (res.employees) {
                commit(SET_USER_EMPLOYEES, res.employees);
            }
            if (res.guardians) {
                commit('SET_USER_GUARDIANS', res.guardians);
            }
            if (res.children.length) {
                commit('SET_USER_CHILDREN', res.children);
            }
            if(res.officials.length) {
                commit(SET_USER_OFFICIALS, res.officials);
            }

            if(res.supplier_reps) {
                commit(SET_USER_SUPPLIER_REPS, res.supplier_reps);
            }
            //commit(*SET_OFFICIAL, res.official);
            /*            if (res.permissions !== undefined) {
                            commit(SET_PERMISSIONS, res.permissions);
                        }*/
            if (res.user !== undefined) {
                commit(SET_USER, res.user);
                // if(getters.activeKindergartenId) {
                //     dispatch(GET_USER_TARGETGROUPS);
                // }
                dispatch(SET_LANGUAGE, res.user.language.code);

                if (res.user.profile_picture !== null) {
                    commit(SET_USER_PIC, res.user.profile_picture);
                }
            }
            if(res.cartData) {
                commit(SET_RECENT_USER_CART, res.cartData);
            }
            dispatch('GET_USER_BANNERS');
            commit(STOP_FULL_PAGE_LOADER);
        });
    },
    [GET_PERMISSIONS]({commit, getters}) {
        commit(SET_PERMISSIONS, {});
        let permissions = {};
        let promises = [];

        commit(START_FULL_PAGE_LOADER);
        //Get active kindergarten permissions
        if(getters.activeKindergartenId) {
            promises.push(UserService.getUserKindergartenPermissions(getters.activeKindergartenId).then(res => {
                permissions = {...permissions, ...res};
            }));
        }
        //Get active organization permissions
        if(getters.activeOrganizationId) {
            promises.push(UserService.getUserOrganizationPermissions(getters.activeOrganizationId).then(res => {
                permissions = {...permissions, ...res};
            }));
        }

        //Get supplier permissions
        if(getters.user.is_supplier && getters.activeSupplierId) {
            promises.push(UserService.getSupplierRepPermissions(getters.activeSupplierId).then(supplierPermissions => {
                permissions = {...permissions, ...supplierPermissions};
            }));
        }

        //Get admin permissions
        if(getters.user.is_admin) {
            promises.push(UserService.getAdminPermissions().then(adminPermissions => {
                permissions = {...permissions, ...adminPermissions};
            }));
        }

        return Promise.allSettled(promises).then(() => {
            commit(SET_PERMISSIONS, permissions);
        }).finally(() => {
            commit(STOP_FULL_PAGE_LOADER);
        });
    },
    [RESET_USER_LOGIN]({commit}) {
        commit(SET_USER_LOGIN, getDefaultState().login);
    },
    //Not used
    [GET_USER_PROFILE]({commit}) {
        UserService.getUserProfile().then(res => {
            commit(SET_USER, res.user);
            commit('SET_USER_GUARDIANS', res.guardians);
            commit('SET_USER_EMPLOYEES', res.employees);
        });
    },
    [GET_USER_MENU]({commit, getters}) {
        commit(START_FULL_PAGE_LOADER);
        let menus = {};
        let promises = [];

        //Get kindergarten based menu
        if (getters.activeKindergartenId) {
            promises.push(UserService.getUserKindergartenMenu(getters.activeKindergartenId).then(menu => {
                menus = {...menus, ... menu};
            }));
        }
        //Get organization based menu
        if(getters.activeOrganizationId) {
            promises.push(UserService.getUserOrganizationMenu(getters.activeOrganizationId).then(menu => {
                menus = {...menus, ... menu};
            }));
        }

        if(getters.activeSupplierId) {
            promises.push(UserService.getUserSupplierMenu(getters.activeSupplierId).then(menu => {
                menus = {...menus, ... menu};
            }));
        }
        //Get user menu
        if((!getters.activeSupplierId && !getters.activeKindergartenId && !getters.activeOrganizationId)) {
            promises.push(UserService.getUserMenu().then(menu => {
                menus = {...menus, ... menu};
            }));
        }

        Promise.allSettled(promises).then(() => {
            commit(SET_USER_MENU, menus);
        }).finally(() => {
            commit(STOP_FULL_PAGE_LOADER);
        });
    },
    [UPDATE_USER]({commit}, data) {
        return UserService.updateUser(data).then(res => {
            commit(SET_USER, res);
        });
    },
    [UPDATE_USER_NAME]({commit}, data) {
        return UserService.updateUserName(data).then(res => {
            commit(SET_USER, res);
        });
    },
    [UPDATE_USER_PRIVACY]({commit}, data) {
        commit(UPDATE_USER_PRIVACY, data.confirmed);
    },
    [GET_USER_TARGETGROUPS]({commit, getters}) {
        // if (getters.userTargetGroups.length) {
        //     return getters.userTargetGroups;
        // }
        return new Promise(resolve => {
            return UserService.getUserTargetGroups(getters.activeKindergartenId).then(res => {
                commit(SET_USER_TARGETGROUPS, res);
                resolve();
            });
        });
    },
    [GET_USER_EMPLOYEES]({commit}) {
        return UserService.getUserEmployees().then(res => {
            commit('SET_USER_EMPLOYEES', res);
        });
    },
    [UPDATE_EMPLOYEE_DATA]({commit}, data) {
        return EmployeeService.updateData(getters.activeKindergartenId, data.employee_id, data.form).then(res => {
            commit(UPDATE_EMPLOYEE_DATA, {employee_id: data.employee_id, data: res});
        });
    },
    [UPDATE_EMPLOYEE_SETTING]({commit}, data) {
        return EmployeeService.updateSettings(data.employee_id, data.form).then(res => {
            commit(SET_EMPLOYEE_SETTINGS, {employee_id: data.employee_id, settings: res});
        });
    },
    [GET_USER_HEADER_INFO]({getters, commit, dispatch}) {
        if (Object.keys(getters.userPermissions).length) {
            dispatch('PRIVATE_CHANNEL', {channel: `user.${getters.userId}`, event: 'NewMessage'});
            dispatch('GET_RECENT_MESSAGES');

            if(getters.activeOrganizationId) {
                RecentService.getOrganizationActivity(getters.activeOrganizationId).then(res => {
                    if(getters.canAccessOrgEventsIndex) {
                        commit('SET_RECENT_EVENTS_COUNT', res.events_count);
                        dispatch('PRIVATE_CHANNEL', {channel: `user.${getters.userId}`, event: 'NewEvent'});
                    }

                    dispatch('PRIVATE_CHANNEL', {channel: `user.${getters.userId}`, event: 'NewNotification'});
                    commit('SET_RECENT_NOTIFICATIONS_COUNT', res.notifications_count);
                    //TODO other modules
                });
            }
            if(getters.activeKindergartenId) {
                RecentService.getKindergartenActivity(getters.activeKindergartenId).then(res => {
                    commit('SET_RECENT_DOCUMENT_COUNT', res.documents_count);
                    commit('SET_RECENT_EVENTS_COUNT', res.events_count);

                    //TODO other modules
                });
                if(getters.currentEmployee) {
                    NotificationService.getUnseenCount().then(res => {
                        dispatch('PRIVATE_CHANNEL', {channel: `user.${getters.userId}`, event: 'NewNotification'});
                        if(res.notifications_count) {
                            commit('SET_RECENT_NOTIFICATIONS_COUNT', res.notifications_count);
                        }

                    });
                }
                dispatch('PRIVATE_CHANNEL', {channel: `user.${getters.userId}`, event: 'NewAnnouncement'});
                dispatch('GET_RECENT_KINDERGARTEN_ANNOUNCEMENTS');
                if(getters.canAccessEventsIndex) {
                    dispatch('PRIVATE_CHANNEL', {channel: `user.${getters.userId}`, event: 'NewEvent'});
                    // dispatch('GET_RECENT_EVENTS');
                }
                if(getters.canAccessDocumentsIndex) {
                    //dispatch('GET_RECENT_DOCUMENTS', getters.activeKindergartenId);
                    dispatch('PRIVATE_CHANNEL', {channel: `user.${getters.userId}`, event: 'NewDocument'});
                }
            }
        }
    },
    [GET_USER_BANNERS]({ getters, commit, state }) {
        if(!state.banners.date || hasPassed(state.banners.date, DAY)) {
            state.banners.date = Vue.moment();
            let type = 'organizations';
            let id;
            if(getters.activeOrganizationId) {
                id = getters.activeOrganizationId;
            } else if (getters.activeKindergartenId) {
                type = 'kindergartens';
                id = getters.activeKindergartenId;
            } else if (getters.activeSupplierId) {
                type = 'suppliers';
                id = getters.activeSupplierId;
            }
            if(id) {
                return BannerService.getUserBanners(type, id).then(banners => {
                    commit('SET_USER_BANNERS', banners);
                });
            }
        }
    },
    [GET_USER_OAUTH_CLIENTS]({commit}) {
        UserService.userOauthClients().then(res => {
            commit('SET_USER_OAUTH_CLIENTS', res);
        });
    },
    [REVOKE_USER_OAUTH_CLIENT]({ commit }, clientId) {
        UserService.revokeOauthClient(clientId).then(() => {
            commit('REMOVE_USER_OAUTH_CLIENT', clientId);
        });
    },
    [GET_RECENT_USER_CART]({commit}) {
        return UserService.getUserCart().then(res => {
           commit(SET_RECENT_USER_CART, res);
        });
    }
};
const getters = {
    ...permissions,
    userId: state => {
        return state.user.id;
    },
    user: state => {
        return state.user;
    },
    userLeftMenu: state => {
        return state.user_menu.left;
    },
    userTopMenu: state => {
        return state.user_menu.top;
    },
    userKindergartens: state => {
        return state.user_kindergartens;
    },
    userPermissions: state => {
        return state.permissions;
    },
    userProfilePic: state => {
        return state.picture;
    },
    loggedInAs: state => {
        return state.logged_in_as;
    },
    userTargetGroups: state => {
        return state.targetGroups;
    },
    userTargetIdentities: state => {
        return state.targetGroups.filter(group => group.type !== 'group');
    },
    loginType: state => state.login,
    userChildren: state => state.children,
    userKindergartenChildren: (state) => (kindergartenId) => {
        return state.children.filter(child => child.kindergarten_id === kindergartenId);
    },
    userOrganizations: state => state.user_organizations,
    userEmployees: state => state.employees,
    currentEmployee: (state, getters) => {
        return state.employees.find(employee => employee.kindergarten_id === getters.activeKindergartenId);
    },
    currentGuardian: (state, getters) => {
        return state.guardians.find(guardian => guardian.kindergarten_id === getters.activeKindergartenId);
    },
    currentEmployeeNotifSettings: state => id => {
        return state.employees.find(employee => employee.kindergarten_id === id);
    },
    currentOfficial: (state, getters) => {
        return state.officials.find(official => official.org_id === getters.activeOrganizationId);
    },
    currentSupplierRep: (state, getters) => state.supplierReps.find(rep => rep.supplier_id === getters.activeSupplierId),
    userOfficials: state => state.officials,
    userGuardians: state => state.guardians,
    userSupplierReps: state => state.supplierReps,
    userSuppliers: state => state.user_suppliers,
    userBanners: state => state.banners.value,
    userOauthClients: state => state.oauth_clients,
    userRecentCart: state => state.user_cart
};

export default {
    state,
    mutations,
    actions,
    getters
};
