import store from '@/store';

const Organizations = () => import(/* webpackChunkName: "organization-module" */ '@views/admin/organization/Organizations');
const AdminOrganization = () => import(/* webpackChunkName: "organization-module" */ '@views/admin/organization/details/index');
const AdminOrganizationGeneral = () => import(/* webpackChunkName: "organization-module" */ '@views/admin/organization/details/views/AdminOrganizationGeneral');
const AdminOrganizationOfficials = () => import(/* webpackChunkName: "organization-module" */ '@views/admin/organization/details/views/AdminOrganizationOfficials');
const AdminOrganizationKindergartens = () => import(/* webpackChunkName: "organization-module" */ '@views/admin/organization/details/views/AdminOrganizationKindergartens');
const AdminOrganizationRoles = () => import(/* webpackChunkName: "organization-module" */ '@views/admin/organization/details/views/AdminOrganizationRoles');
const AdminOrganizationTargetGroups = () => import(/* webpackChunkName: "organization-module" */ '@views/admin/organization/details/views/AdminOrganizationTargetGroups');

export default [
    {
        path: '/admin/organizations',
        name: 'admin.organizations',
        components: { base: Organizations },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessOrganizationsIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/organizations/:org_id',
        components: {base: AdminOrganization},
        children: [
            {
                path: '',
                name: 'admin.organization.details',
                components: { organization: AdminOrganizationGeneral },
            },
            {
                path: 'officials',
                name: 'admin.organization.officials',
                components: { organization: AdminOrganizationOfficials },
            },
            {
                path: 'roles',
                name: 'admin.organization.roles',
                components: { organization: AdminOrganizationRoles },
            },
            {
                path: 'kindergartens',
                name: 'admin.organization.kindergartens',
                components: { organization: AdminOrganizationKindergartens },
            },
            {
                path: 'target-groups',
                name: 'admin.organization.targetgroups',
                components: { organization: AdminOrganizationTargetGroups },
            },
        ]
    }
];
