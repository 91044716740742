<template>
    <b-modal id="gallery-diary-dates-modal" lazy static>
        <template v-slot:modal-header>
            <modal-header modalName="gallery-diary-dates-modal">{{ $t('Change date range') }}</modal-header>
        </template>
        <div class="py-2 align-items-center font-weight-bold row d-none d-sm-flex">
            <div class="col text-left">{{ $t('Start date') }}</div>
            <div class="col text-left">{{ $t('End date') }}</div>
        </div>
        <div class="row">
            <div class="row col-sm mb-2 mb-sm-0 d-flex align-items-center mx-0">
                <div class="col-5 px-0 d-sm-none">{{ $t('Start date') }}</div>
                <div class="col-7 px-0 px-sm-1 col-sm-12">
                    <EDatepicker
                        v-model="startDate"
                        calendarClass="align-right"
                        :validation="$v.startDate"/>
                </div>
            </div>
            <div class="row col-sm mb-2 mb-sm-0 d-flex align-items-center mx-0">
                <div class="col-5 px-0 d-sm-none">{{ $t('End date') }}</div>
                <div class="col-7 px-0 px-sm-1 col-sm-12">
                    <EDatepicker
                        v-model="endDate"
                        calendarClass="align-right"
                        :validation="$v.endDate"/>
                </div>
            </div>
        </div>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-secondary" @click="cancel">{{$t('Cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="apply">{{$t('Apply')}}</button>
        </template>
    </b-modal>
</template>

<script>
import {eventBus} from '@/main';
import {required} from 'vuelidate/lib/validators';

export default {
    name: 'GalleryDiaryDatesModal',
    data() {
        return {
            startDate: null,
            endDate: null,
        };
    },
    methods: {
        cancel() {
            this.$bvModal.hide('gallery-diary-dates-modal');
        },
        apply() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$emit('apply-dates', { startDate: this.startDate, endDate: this.endDate });
            this.$bvModal.hide('gallery-diary-dates-modal');
        }
    },
    created() {
        eventBus.$on('open-gallery-diary-dates-modal', (dates) => {
            this.startDate = dates.startDate;
            this.endDate = dates.endDate;
            this.$bvModal.show('gallery-diary-dates-modal');
        });
    },
    destroyed() {
        eventBus.$off('open-gallery-diary-dates-modal');
    },
    validations: {
        startDate: {
            required,
            startDateEarlier: function() {
                return this.$moment(this.endDate).isSameOrAfter(this.$moment(this.startDate));
            }
        },
        endDate: {
            required,
            endDateLater: function() {
                return this.$moment(this.startDate).isSameOrBefore(this.$moment(this.endDate));
            },
        }
    }
};
</script>
