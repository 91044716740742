import DietaryOptionService from '@services/dietary_option.service';
import {
    GET_KINDERGARTEN_DIETARY_OPTIONS,
    SET_KINDERGARTEN_DIETARY_OPTIONS,
    CREATE_KINDERGARTEN_DIETARY_OPTION,
    ADD_KINDERGARTEN_DIETARY_OPTION,
    DELETE_KINDERGARTEN_DIETARY_OPTION,
    REMOVE_KINDERGARTEN_DIETARY_OPTION,
} from './actions';

const state = {
    kindergartenDietaryOptions: []
};

const mutations = {
    [SET_KINDERGARTEN_DIETARY_OPTIONS](state, data) {
        state.kindergartenDietaryOptions = data;
    },
    [ADD_KINDERGARTEN_DIETARY_OPTION](state, option) {
        state.kindergartenDietaryOptions.push(option);
    },
    [REMOVE_KINDERGARTEN_DIETARY_OPTION](state, optionId) {
        const filteredOptions = state.kindergartenDietaryOptions.filter(o => o.id !== optionId);
        state.kindergartenDietaryOptions = [...filteredOptions];
    }
};

const actions = {
    [GET_KINDERGARTEN_DIETARY_OPTIONS]({ commit, getters }) {
        return DietaryOptionService.getKindergartenDietaryOptions(getters.activeKindergartenId).then(res => {
            commit(SET_KINDERGARTEN_DIETARY_OPTIONS, res);
        });
    },
    [CREATE_KINDERGARTEN_DIETARY_OPTION]({ commit, getters }, option) {
        return DietaryOptionService.createKindergartenDietaryOption(getters.activeKindergartenId, option).then(res => {
            commit(ADD_KINDERGARTEN_DIETARY_OPTION, res);
        });
    },
    [DELETE_KINDERGARTEN_DIETARY_OPTION]({ commit, getters }, optionId) {
        return DietaryOptionService.deleteKindergartenDietaryOption(getters.activeKindergartenId, optionId).then(res => {
            if (res.deleted) {
                commit(REMOVE_KINDERGARTEN_DIETARY_OPTION, optionId);
            }
            return res;
        });
    }
};

const getters = {
    kindergartenDietaryOptions: state => state.kindergartenDietaryOptions
};

export default {
    state,
    getters,
    mutations,
    actions
};