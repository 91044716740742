import store from '../../store';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import Big from 'big.js';
import Vue from 'vue';
import i18n from '@/i18n';
import axios from 'axios';

export function current(date, period) {
    return {
        start: date.startOf(period).format('Y-MM-DD'),
        end: date.endOf(period).format('Y-MM-DD')
    };
}

export function translate(string) {
    if(string) {
        const translation = parseJson(string);
        if (!translation[store.getters.language]) {
            if(store.getters.activeKindergartenId && store.getters.kindergartenLanguages.length) {
                let primaryLang = store.getters.kindergartenLanguages.find(lang => lang.primary === true);
                if(primaryLang) {
                    return translation[primaryLang.key];
                } else {
                    return `Missing [${store.getters.language}] translation`;
                }
            } else {
                return `Missing [${store.getters.language}] translation`;
            }
        }
        return translation[store.getters.language];
    }
}

export function shopTranslate(translations) {
    if (!translations) {
        return '';
    }

    const selectedLang = store.getters.language;

    const userLangTranslation = translations[selectedLang];

    if (!userLangTranslation) {
        return translations.en || '';
    }

    return userLangTranslation;
}

function parseJson(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
}

export function difference(object, base) {
    function changes(object, base) {
        return transform(object, function(result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    }

    return changes(object, base);
}

export function search(query, str) {
    return str.toLowerCase().trim().includes(query.toLowerCase().trim());
}

export function cleanQueryObj(obj) {
    for (let propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
        if (obj.trashed === false) {
            delete obj.trashed;
        }
    }

    return obj;
}

export function firstSort(fields) {
    return { sortBy: fields.filter(f => f.sortable === true)[0].key, sortDir: 'asc'};
}

export function download(data, file) {
    const fileURL = window.URL.createObjectURL(new Blob([data], { type: file.mime_type }));
    const a = document.createElement('a');
    a.href = fileURL;
    let fileNameWithType = file.name + (file.filename ? file.filename.substr(file.filename.indexOf('.')) : '');
    a.setAttribute('download', fileNameWithType);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function downloadS3File(url, file, fn = null) {
    return axios.get(url, {
        'responseType': 'blob',
        withCredentials: false,
    }).then(res => {
        download(res.data, file);
    }).finally(() => {
        if (fn) {
            fn();
        }
    });
}

export function objectNotEmpty(obj) {
    return Object.keys(obj).length;
}

export function scrollToTop() {
    return window.scrollTo(0, 0);
}

export function academicYear(dateString = false) {
    let date = dateString ? Vue.moment(dateString) : Vue.moment();
    if(date.month()+1 < 9) {
        return date.subtract(1, 'years');
    } else {
        return date;
    }
}

export function detectBrowser() {
    if (navigator.userAgent.includes('Chrome')) {
        return 'chrome';
    } else if (navigator.userAgent.includes('Safari')) {
        return 'safari';
    } else if (navigator.userAgent.includes('SamsungBrowser')) {
        return 'samsung internet';
    } else if (navigator.userAgent.includes('Edg')) {
        return 'edge-chromium';
    } else if (navigator.userAgent.includes('Edge')) {
        return 'edge';
    } else if (navigator.userAgent.includes('Firefox')) {
        return 'firefox';
    } else if (navigator.userAgent.includes('Opera') || navigator.userAgent.includes('OPR')) {
        return 'opera';
    } else if (navigator.userAgent.includes('Trident')) {
        return 'ie';
    } else {
        return 'unknown';
    }
}

export function detectPlatform() {
    const platforms = [
        navigator.userAgent.match(/Android/i) ? 'android' : false,
        navigator.userAgent.match(/BlackBerry/i) ? 'blackberry' : false,
        navigator.userAgent.match(/iPhone/i) ? 'ios-iphone' : false,
        navigator.userAgent.match(/iPad/i) ? 'ios-ipad' : false,
        navigator.userAgent.match(/iPod/i) ? 'ios-ipod' : false,
        navigator.userAgent.match(/Macintosh/i) ? 'ios-mac' : false,
        navigator.userAgent.match(/Opera Mini/i) ? 'opera' : false,
        navigator.userAgent.match(/IEMobile/i) ? 'win-mobile' : false,
        navigator.userAgent.match(/Linux/i) ? 'linux' : false,
        navigator.userAgent.match(/X11/i) ? 'unix' : false,
        navigator.userAgent.match(/Win64|Win32/i) ? 'win-pc' : false
    ];

    return platforms.filter(Boolean)[0] || 'unknown';
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 * @returns {boolean}
 */
export function hasTouchScreen() {
    let hasTouchScreen = false;
    if ('maxTouchPoints' in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
        const mQ = matchMedia?.('(pointer:coarse)');
        if (mQ?.media === '(pointer:coarse)') {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            const UA = navigator.userAgent;
            hasTouchScreen =
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
    }
    return hasTouchScreen;
}

export function isMobile() {
    const SCREEN_SIZE = 992;

    return hasTouchScreen() && window.innerWidth < SCREEN_SIZE;
}

export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function calculateGross(netprice, vat) {
    const gross = new Big(netprice).times(1 + (vat/100));
    return gross.toString();
}

export function calculateDiscount(price, discount) {
    const newPrice = new Big(price).times((100 - discount) / 100);
    return newPrice.toString();
}

export function displayAddress(address) {
    if(!address) {
        return '';
    }

    const addressValues = [address.street, address.city, address.county, address.zip, address.country];

    const addressStr = addressValues.filter(val => val && val !== '').join(', ');

    if (!addressStr && address.address) {
        return address.address;
    }

    return addressStr;
}

export function displayPhone(phone) {
    return `+${phone.code} ${phone.number || phone.phone || ''}`;
}

export function translateStatus(status) {
    return  i18n.t(status.charAt(0).toUpperCase() + status.slice(1));
}

export function calculateSum(price, qty) {
    const sum = new Big(price).times(qty);
    return sum;
}
export  function copy(text) {
    navigator.clipboard.writeText(JSON.stringify(text)).then(function() {
        console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
        console.error('Async: Could not copy text: ', err);
    });
}

export function copyRaw(text) {
    navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
        console.error('Async: Could not copy text: ', err);
    });
}
