<template>
    <b-modal
        id="view-media-file-modal"
        class="e3-modal-fullscreen"
        size="xl"
        lazy
        centered
        hide-footer
        static>
        <template v-slot:modal-header>
            <modal-header modalName="view-media-file-modal">
                <div class="d-flex w-100">
                    <EInput
                        v-if="canEdit"
                        id="file-id"
                        v-model="form.name"
                        charCounter
                        :maxlength="191"
                        :validation="$v.form.name"
                        :title="form.name"
                        class="view-file-input"/>
                    <span v-else>{{ form.name }}</span>
                </div>
            </modal-header>
        </template>
        <div v-if="file">
            <div v-show="loading">
                <e-loader class="overlay-loader"/>
            </div>
            <transition name="fade" mode="out-in">
                <div class="position-relative">
                    <button v-if="position > 0" class="d-none d-md-block e3-image-view-prev-btn" @click="loadPrev">
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"/>
                        </svg>
                    </button>
                    <button v-if="position + 1 < sortedFiles.length" class="d-none d-md-block e3-image-view-next-btn" @click="loadNext">
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
                        </svg>
                    </button>
                    <div class="e3-image-view-container">
                        <img v-if="!isVideo" :src="fileUrl" class="e3-img-full img-fluid" @load="onFileLoad"/>
                        <div v-else style="height: 70vh; min-width: 45vw">
                            <EVideoPlayer :src="fileUrl" @ready="onFileLoad"/>
                        </div>
                    </div>
                    <div class="d-block d-md-none">
                        <button
                            v-if="position > 0"
                            class="btn btn-secondary rounded mr-2 e3-date-btn position-relative"
                            @click="loadPrev">
                            <SvgIcon icon="mdi-chevron-left" size="36"
                                     class="e3-date-btn-icon position-absolute text-primary"/>
                        </button>
                        <button
                            v-if="position + 1 < sortedFiles.length"
                            class="btn btn-secondary rounded mr-2 e3-date-btn position-relative"
                            @click="loadNext">
                            <SvgIcon icon="mdi-chevron-right" size="36"
                                     class="e3-date-btn-icon position-absolute text-primary"/>
                        </button>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-6 text-left">
                            <auto-grow-textarea
                                v-if="canEdit"
                                v-model="form.description"
                                :maxlength="191"
                                charCounter
                                :disabled="actionLoading"
                                class="mb-2"/>
                            <p v-else class="ml-1 mb-0 text-left mb-2 align-items-baseline">{{ form.description }}</p>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-between pl-sm-0 align-items-baseline">
                            <span class="mr-2">{{ $t('Uploaded at:') }} {{ formatDateTime(fileUploadedAt) }}</span>
                            <div class="d-flex justify-content-flex-end d-sm-block">
                                <div class="w-auto d-flex align-items-center justify-content-center mb-sm-2 mr-2 mr-sm-0">
                                    <RoundIconButton
                                        v-if="!actionLoading"
                                        type="btn-success"
                                        :disabled="saveLoading"
                                        :title="$t('Download')"
                                        class="mr-2"
                                        @click="download">
                                        <SvgIcon icon="mdi-download" class="text-white"/>
                                    </RoundIconButton>
                                    <ELoader v-else  class="p-0 mr-2"/>
                                    <ConfirmButton
                                        v-if="canEdit"
                                        :disable="actionLoading || saveLoading"
                                        :msg="$t('Are you sure you want to delete the file', {name: file.name})"
                                        icon
                                        confirmText="Delete"
                                        :title="$t('Delete file')"
                                        :confirmHeader="$t('Delete file')"
                                        @confirmed="removeFile"/>
                                </div>
                                <EBtnLoader
                                    v-if="canEdit"
                                    color="success"
                                    :disabled="actionLoading"
                                    :isSubmitLoading="saveLoading"
                                    @clicked="save">
                                    {{ $t('Save') }}
                                </EBtnLoader>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </b-modal>
</template>

<script>
import {eventBus} from '@/main';
import {mapGetters} from 'vuex';
import {sortBy} from 'lodash';
import {minLength, required} from 'vuelidate/lib/validators';
import {alphaNumFileCustom} from '@/validators';
import {formatDateTime} from '@/filters';
import GalleryService from '@services/gallery.service';
import DiaryService from '@services/diary.service';
import ChildService from '@services/child.service';
import EVideoPlayer from '@components/video/VideoPlayer.vue';
import EInput from '@/common/components/input/Input.vue';
import {downloadS3File} from '@services/helper.service';

export default {
    name: 'ViewFileModal',
    props: {
        editable: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            file: null,
            files: [],
            type: null,
            subType: null,
            position: null,
            loading: true,
            form: {
                name: '',
                description: ''
            },
            actionLoading: false,
            saveLoading: false,
            videoMimeTypes: ['video/mp4', 'video/quicktime'],
            childId: null,
        };
    },
    components: {
        EInput,
        EVideoPlayer
    },
    computed: {
        ...mapGetters([
            'activeKindergartenId',
            'userId',
            'canUploadDiaryImages',
            'folderFiles',
            'diaryMedia',
            'canEditChildFilesEmployee',
            'canManageChildFilesEmployee',
            'canEditChildFilesGuardian',
            'canManageChildFilesGuardian',
        ]),
        canEdit() {
            if(!this.editable) {
                return false;
            }
            if(this.type === 'gallery') {
                return this.$store.getters.folder.access === 'manage' ||
                    (this.$store.getters.folder.access === 'edit' && this.file.created_by === this.userId);
            } else if(this.type === 'diary') {
                return this.canUploadDiaryImages;
            } else if(this.type === 'child') {
                if (this.$store.getters.childProfile.view === 'guardian') {
                    return this.canManageChildFilesGuardian || (this.canEditChildFilesGuardian && this.file.created_by === this.userId);
                } else {
                    return this.canManageChildFilesEmployee || (this.canManageChildFilesEmployee && this.file.created_by === this.userId);
                }
            } else {
                return false;
            }
        },
        fileUploadedAt() {
            return this.file.uploaded_at;
        },
        isVideo() {
            return this.videoMimeTypes.includes(this.file.mime_type);
        },
        fileUrl() {
            return this.getFile(this.file);
        },
        sortedFiles() {
            if(this.type === 'gallery') { // or documents one day
                return sortBy(this.folderFiles, f => f.uploaded_at);
            } else if(this.type === 'diary' && this.subType) {
                if(this.subType === 'static') {
                    //get static images that are not in store because of multiple days in one view
                    return sortBy(this.files, f => f.uploaded_at);
                } else {
                    return sortBy(this.diaryMedia.filter(i => i.type === this.subType), f => f.uploaded_at);
                }
            } else if(this.type === 'child') {
                return this.files; // Already sorted before sent in
            }
            else {
                return [];
            }
        }
    },
    methods: {
        formatDateTime, // TODO: Vue3 remove
        loadNext() {
            this.loading = true;
            this.file = this.sortedFiles[this.position+1];
            this.position = this.position+1;
            if (this.position < this.sortedFiles.length - 1) {
                this.preloadImage(this.sortedFiles[this.position+1]);
            }
        },
        loadPrev() {
            this.loading = true;
            this.file = this.sortedFiles[this.position-1];
            this.position = this.position-1;
            if (this.position > 0) {
                this.preloadImage(this.sortedFiles[this.position-1]);
            }
        },
        moveBetweenGalleryImages(e) {
            switch(e.keyCode) {
            case 37:
                if (this.position > 0) {
                    this.loadPrev();
                }
                break;
            case 39:
                if (this.position + 1 < this.sortedFiles.length) {
                    this.loadNext();
                }
                break;
            default:
                return;
            }
        },
        updateFormData() {
            this.form.name = this.file.name;
            this.form.description = this.file.description;
        },
        preloadImage(file) {
            const img = new Image();
            img.src = this.getFile(file);
        },
        getFile(file) {
            if (this.isVideo) {
                this.loading = false;
                this.updateFormData();
            }
            if (file.url) {
                return file.url;
            }

            let apiBase = process.env.NODE_ENV !== 'development' ?
                `https://api.${window.location.hostname}` :
                `${process.env.VUE_APP_API_URL}`;

            if (this.type === 'child') {
                return apiBase + `/api/kindergartens/${this.activeKindergartenId}/children/${this.childId}/files/${file.filename}`;
            } else {
                return apiBase + `/api/kindergartens/${this.activeKindergartenId}/files/${this.type}/${file.filename}`;
            }
        },
        save() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.saveLoading = true;
                this.form.filename = this.file.filename;
                if (this.type === 'child') {
                    this.form.childId = this.childId;
                }
                this.$store.dispatch(`UPDATE_${this.type.toUpperCase()}_FILE`, this.form).then(() => {
                    this.$toastr.s(this.$t('Saved'));
                }).finally(() => {
                    this.saveLoading = false;
                });
            }
        },
        download() {
            this.actionLoading = true;

            if (this.file.url) {
                return downloadS3File(this.file.url, this.file, () => {
                    this.actionLoading = false;
                });
            }

            if(this.type === 'gallery') {
                GalleryService.downloadGalleryFile(this.activeKindergartenId, this.file).finally(() => {
                    this.actionLoading = false;
                });
            } else if(this.type === 'diary' || this.type === 'gallery_builtin_diary') {
                DiaryService.getDiaryFile(this.activeKindergartenId, this.file).finally(() => {
                    this.actionLoading = false;
                });
            } else if (this.type === 'child' || this.type === 'gallery_builtin_child') {
                ChildService.getChildFile(this.activeKindergartenId, this.childId, this.file).finally(() => {
                    this.actionLoading = false;
                });
            }
        },
        removeFile() {
            this.actionLoading = true;
            this.$store.dispatch(`DELETE_${this.type.toUpperCase()}_FILE`, {fileName: this.file.filename, childId: this.childId}).then(() => {
                this.$bvModal.hide('view-media-file-modal');
            }).finally(() => {
                this.actionLoading = false;
            });
        },
        onFileLoad(){
            this.loading = false;
            this.updateFormData();
        }
    },
    mounted() {
        eventBus.$on('show-view-media-file-modal', data => {
            this.file = data.file;
            if(data.files) {
                this.files = data.files;
            }
            this.type = data.type;
            if(data.subType) {
                this.subType = data.subType;
            }
            if(this.type === 'child' || this.type === 'gallery_builtin_child') {
                this.childId = this.$route.params.child_id;
            }
            this.form.name = this.file.name;
            this.form.description = this.file.description;
            this.position = this.sortedFiles.findIndex(f => f.id === this.file.id);
            this.$bvModal.show('view-media-file-modal');
            this.position < this.sortedFiles.length - 1 && this.preloadImage(this.sortedFiles[this.position+1]);
            this.position > 1 && this.preloadImage(this.sortedFiles[this.position-1]);
            this.loading = true;
            document.addEventListener('keyup', this.moveBetweenGalleryImages);
        });
    },
    destroyed() {
        eventBus.$off('show-view-media-file-modal');
        document.removeEventListener('keyup', this.moveBetweenGalleryImages);
    },
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(1),
                alphaNumFileCustom
            }
        }
    },
};
</script>
<style scoped>
.view-file-input {
    max-width: 430px;
}
</style>
