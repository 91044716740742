export const GET_SURVEY = 'GET_SURVEY';
export const SET_SURVEY = 'SET_SURVEY';
export const STORE_QUESTION = 'STORE_QUESTION';
export const ADD_QUESTION = 'ADD_QUESTION';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const DELETE_SURVEY = 'DELETE_SURVEY';
export const UPDATE_SURVEY_SETTINGS = 'UPDATE_SURVEY_SETTINGS';
export const STORE_QUESTION_OPTION = 'STORE_QUESTION_OPTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const UPDATE_QUESTION_OPTION = 'UPDATE_QUESTION_OPTION';
export const SET_QUESTION = 'SET_QUESTION';
export const ADD_QUESTION_OPTION = 'ADD_QUESTION_OPTION';
export const SET_QUESTION_OPTION = 'SET_QUESTION_OPTION';
export const DELETE_QUESTION_OPTION = 'DELETE_QUESTION_OPTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const REMOVE_QUESTION_OPTION = 'REMOVE_QUESTION_OPTION';
export const REORDER_QUESTION = 'REORDER_QUESTION';
export const REORDER_QUESTION_OPTION = 'REORDER_QUESTION_OPTION';
export const SET_SELECTED_QUESTION = 'SET_SELECTED_QUESTION';
export const SET_QUESTION_OPTIONS = 'SET_QUESTION_OPTIONS';
export const ADD_SURVEY_ACL = 'ADD_SURVEY_ACL';
export const REMOVE_SURVEY_ACL = 'REMOVE_SURVEY_ACL';
export const GET_AVAILABLE_SURVEYS = 'GET_AVAILABLE_SURVEYS';
export const SET_AVAILABLE_SURVEYS = 'SET_AVAILABLE_SURVEYS';
export const STOP_ALL_SURVEY_LOADING = 'STOP_ALL_SURVEY_LOADING';
export const START_SURVEY_LOADING = 'START_SURVEY_LOADING';
export const STOP_SURVEY_LOADING = 'STOP_SURVEY_LOADING';
export const CREATE_QUESTION = 'CREATE_QUESTION';
export const SET_SURVEY_LOCK_DATA = 'SET_SURVEY_LOCK_DATA';
export const SET_SURVEY_LOCK_DATA_EMPTY = 'SET_SURVEY_LOCK_DATA_EMPTY';
export const SET_QUESTIONS = 'SET_QUESTIONS';
