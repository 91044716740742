<template>
    <div class="ml-lg-auto d-flex">
        <div class="d-flex flex-column flex-md-row align-items-md-center">
            <div v-if="label" class="mr-1">
                <span>{{ $t(label) }}</span>
            </div>
            <div class="d-flex align-items-center">
                <div class="mr-1">
                    <EDatepicker
                        id="start_date"
                        v-model="formStart"
                        calendarClass="align-right-lg"
                        :maxDate="currentPeriod.endDate"
                        :minDate="findMin"
                        :validation="v.$each[index].startDate"
                        positionAbsoluteValidation/>
                </div>
                <div>
                    —
                </div>
                <div class="ml-1">
                    <EDatepicker
                        id="end_date"
                        v-model="formEnd"
                        calendarClass="align-right"
                        :minDate="currentPeriod.startDate"
                        :maxDate="findMax"
                        :validation="v.$each[index].endDate"
                        positionAbsoluteValidation/>
                </div>
                <div class="d-flex">
                    <slot/>
                    <template v-if="!hasDefaultSlot">
                        <button class="btn btn-danger d-flex align-items-center ml-2" @click="remove">
                            <SvgIcon icon="mdi-trash-can-outline" size="20" class="text-white"/>
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PeriodPicker',
    props: {
        index: Number,
        minDate: [String, Date],
        maxDate: [String, Date],
        label: {
            type: String,
            required: false
        },
        periods: Array,
        v: Object,
        currentPeriod: Object,
    },
    data() {
        return {
            form: {
                start_date: null,
                end_date: null
            }
        };
    },
    computed: {
        findMin() {
            if(this.index > 0) {
                let endDate = this.periods[this.index-1].endDate;
                return this.$moment(endDate).add(1, 'days').format('YYYY-MM-DD');
            } else return null;
        },
        findMax() {
            if(this.index < this.periods.length-1) {
                let startDate = this.periods[this.index+1].startDate;
                return this.$moment(startDate).subtract(1, 'days').format('YYYY-MM-DD');
            } else return null;
        },
        hasDefaultSlot () {
            return this.$slots.default;
        },
        formStart: {
            get() {
                return this.currentPeriod.startDate;
            },
            set(value) {
                this.$emit('start', value);
            }
        },
        formEnd: {
            get() {
                return this.currentPeriod.endDate;
            },
            set(value) {
                this.$emit('end', value);
            }
        },
    },
    methods: {
        remove() {
            this.$emit('remove');
        },
    },
};
</script>
