import store from '@/store';

const WorkScheduleIndex = () => import(/* webpackChunkName: "work-schedule-module" */ '@views/schedule/index');
const WorkSchedule = () => import(/* webpackChunkName: "work-schedule-module" */ '@views/schedule/views/WorkScheduleDay');
const WorkScheduleWeekly = () => import(/* webpackChunkName: "work-schedule-module" */ '@views/schedule/views/WorkScheduleWeek');
const WorkScheduleMonthly = () => import(/* webpackChunkName: "work-schedule-module" */ '@views/schedule/views/WorkScheduleMonth');
const WorkScheduleSettingsIndex = () => import(/* webpackChunkName: "work-schedule-module" */ '@views/schedule/settings/index');
const WorkScheduleSettings = () => import(/* webpackChunkName: "work-schedule-module" */ '@views/schedule/settings/views/WorkScheduleSettings');
const WorkScheduleTypes = () => import(/* webpackChunkName: "work-schedule-module" */ '@views/schedule/settings/views/WorkScheduleTypes');

export default [
    {
        path: '/work-schedule',
        components: { base: WorkScheduleIndex },
        beforeEnter: (to, from, next) => {
            if(store.getters.canAccessWorkScheduleIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        },
        children: [
            {
                path: 'day',
                name: 'workschedule.day',
                components: { workschedule: WorkSchedule }
            },
            {
                path: 'week',
                name: 'workschedule.week',
                components: { workschedule: WorkScheduleWeekly }
            },
            {
                path: 'month',
                name: 'workschedule.month',
                components: { workschedule: WorkScheduleMonthly }
            },
        ]
    },
    {
        path: '/work-schedule/settings',
        components: { base: WorkScheduleSettingsIndex },
        beforeEnter: (to, from, next) => {
            if(store.getters.canAccessWorkScheduleSettings) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        },
        children: [
            {
                path: '',
                name: 'workschedule.settings',
                components: { 'work-schedule-settings': WorkScheduleSettings },
            },
            {
                path: 'types',
                name: 'workschedule.types',
                components: { 'work-schedule-settings': WorkScheduleTypes },
            }
        ]
    },

];
