import { helpers } from 'vuelidate/lib/validators';

export const passwordRegex = helpers.regex(
    'passwordRegex',
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
);
export const alphaNumCustom = helpers.regex('alphaNumCustom', /^[a-zA-Z0-9À-žЁёА-я ]*$/);

export const alphaNumFileCustom = helpers.regex('alphaNumFileCustom', /^[a-zA-Z0-9À-žЁёА-я!#¤%&=`;,'[\]£$@~()._ -]*$/);

export const timeString = helpers.regex('timeString', /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/);

export const urlNegatedChars = helpers.regex('urlNegatedChars', /^[^/@:;,=+!*|]*$/);

//validates dates with leap year check between years 1900-2199
export const dateString = helpers.regex(
	'dateString',
	/^(((19|20|21)([2468][048]|[13579][26]|0[48])|2000)-02-29|((19|20|21)[0-9]{2}-(0[469]|11)-(0[1-9]|[12][0-9]|30)|(19|20|21)[0-9]{2}-(0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(19|20|21)[0-9]{2}-02-(0[1-9]|1[0-9]|2[0-8])))$/
);

export const allNumeric = helpers.regex('allNumeric', /^\d+\.?\d{0,2}$/);
