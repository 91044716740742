<template>
    <div>
        <ELoader v-if="isContentLoading"/>
        <template v-else>
            <PageTitle>
                {{ pageTitle }}
            </PageTitle>

            <!-- Section header -->
            <div class="d-flex flex-column flex-sm-row flex-wrap align-items-center">
                <!-- Breadcrumbs -->
                <h5 class="d-flex flex-wrap mb-0 mr-auto">
                    <router-link :to="{name: 'gallery.index' }">
                        {{ $t('Gallery') }}
                    </router-link>
                    <span v-for="crumb in breadcrumbs" :key="`c-${crumb.id}`" class="d-flex">
                        <SvgIcon icon="mdi-chevron-right" source="menu-icons" size="24"/>
                        <span :title="crumbName(crumb)">{{ truncate(crumbName(crumb), 20) }}</span>
                    </span>
                </h5>

                <!-- Section header.search -->
                <ESearchInput
                    v-if="folders.length"
                    v-model="searchValue"
                    :disabled="isContentLoading"
                    :placeholder="$t('Type to search')"
                    class="ml-auto mt-2 mt-sm-0"
                    @getEntities="filterContent"/>
            </div>

            <!-- Section content -->
            <div v-if="shownFolders.length" class="container-fluid mt-2">
                <div class="row font-weight-bold mb-2">
                    <div class="col-3 p-0">{{ $t('Name') }}</div>
                </div>
                <div>
                    <div v-for="folder in shownFolders"
                         :key="`folder-${folder.id}`"
                         class="e3-last-border e3-hover-show row border-top py-2">
                        <div class="col d-flex align-items-center p-0 pr-2">
                            <router-link
                                :to="{name: 'gallery.builtin.diary', params: {'course_id': folder.id}}"
                                class="d-flex align-items-center nav-link e3-hover-underline p-0">
                                <FolderIcon
                                    class="small mr-2"
                                    isGallery
                                    isReadonly/>
                                <span>
                                    {{ folder.name }}
                                    <span v-if="folder.archived !== null" class="text-muted">
                                        <span class="text-lowercase">{{ $t('Class archived') }}</span> {{ formatDate(folder.archived) }}
                                    </span>
                                </span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div v-if="showLoadMore" class="d-flex mt-3">
                    <button type="button" class="btn btn-primary mx-auto" @click="loadMore">
                        {{ $t("Load more") }}
                    </button>
                </div>
            </div>

            <EmptyListMessage v-else class="border rounded p-2 bg-white mt-3">
                {{ $t('No pictures found') }}
            </EmptyListMessage>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {formatDate, truncate} from '@/filters';
import FolderService from '@/common/services/folder.service';
import FolderIcon from '@/views/folder/components/FolderIcon.vue';

export default {
    name: 'GalleryDiaryIndex',
    data() {
        return {
            breadcrumbs: [],
            folders: [],
            shownFolders: [],
            foldersPerPage: 20,
            page: 1,
            searchValue: '',
        };
    },
    computed: {
        ...mapGetters([
            'activeKindergartenId',
            'isContentLoading',
            'language',
        ]),
        pageTitle() {
            const title = this.$t('Album');
            const folder = this.crumbName(this.breadcrumbs.slice(-1)[0]);
            return title.concat(': ', folder);
        },
        showLoadMore() {
            return this.searchValue === '' && this.shownFolders.length < this.folders.length;
        },
    },
    methods: {
        formatDate, // TODO: Vue3 remove
        truncate, // TODO: Vue3 remove
        /** @param {{id: number|string, name: string, name_string: string }} crumb */
        crumbName(crumb) {
            if (crumb.name_string) {
                return this.$t(crumb.name_string);
            } else {
                return crumb.name;
            }
        },
        getFolders() {
            this.$store.dispatch('START_CONTENT_LOADER');
            const params = {
                module: 'gallery',
            };
            FolderService.getBuiltinDiaryFolders(this.activeKindergartenId, params).then(res => {
                this.breadcrumbs = res.breadcrumbs;
                this.folders = res.folders;
                this.sortContent();
                this.filterContent();
            }).finally(() => {
                this.$store.dispatch('STOP_CONTENT_LOADER');
            });
        },
        sortContent() {
            const language = this.language;
            this.folders.sort(function(a, b) {
                return a.name.localeCompare(b.name, language);
            });
        },
        filterContent() {
            this.shownFolders = [];
            if (this.searchValue === '') {
                this.page = 1;
                this.shownFolders = this.folders.slice(0, this.foldersPerPage);
            } else {
                const searchValue = this.searchValue.toLocaleLowerCase(this.language);
                this.shownFolders = this.folders.filter(folder => {
                    const name = folder.name.toLocaleLowerCase(this.language);
                    return name.includes(searchValue);
                });
            }
        },
        loadMore() {
            if (!this.showLoadMore) {
                return;
            }
            const max = this.folders.length;
            this.page++;
            let sliceSize = this.page * this.foldersPerPage;
            sliceSize = Math.min(sliceSize, max);
            this.shownFolders = this.folders.slice(0, sliceSize);
        },
    },
    components: {
        FolderIcon,
    },
    created() {
        this.getFolders();
    },
};
</script>
