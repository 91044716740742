import * as Sentry from '@sentry/vue';
//import router from '@/routes/routes';

let sentryInstance = null;

export function initializeSentry(app) {
    if (process.env.VUE_APP_SENTRY_DNS && !sentryInstance) {
        sentryInstance = Sentry.init({
            app,
            dsn: process.env.VUE_APP_SENTRY_DNS,
            integrations: [
                //Sentry.browserTracingIntegration({router}),
                Sentry.replayIntegration({
                    unmask: ['.toast-container'],
                    maxReplayDuration: 120000,
                }),
            ],
            tracesSampleRate: 0,
            environment: process.env.VUE_APP_ENV,
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: process.env.VUE_APP_ENV !== 'production' ? 1.0 : 0.1,
            autoSessionTracking: false,
            ignoreErrors: [
                'Can\'t find variable: gmo',
                'Request failed with status code 401'
            ],
        });
    }
}


