import http from '@http';
import {download} from '@services/helper.service';

class PlanService {
    constructor() {
        if (PlanService.instance) {
            return PlanService.instance;
        }

        PlanService.instance = this;
    }

    store(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/plans/store`, data)
            .then(res => res.data);
    }

    storePlanSetting(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/plans/settings/store`, data)
            .then(res => res.data);
    }

    updateField(kindergartenId, planId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/plans/${planId}/update-field`, data)
            .then(res => res.data);
    }

    updatePlanSetting(kindergartenId, settingId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/plans/settings/${settingId}`, data)
            .then(res => res.data);
    }

    getPlanSettings(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/settings`, {
                params: queryObj
            })
            .then(res => res.data);
    }

    getPlan(kindergartenId, data) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/show`, {
            params: data
        }).then(res => res.data);
    }

    downloadPlanPdf(kindergartenId, planId, options, name) {
        return http.download(
            `/api/kindergartens/${kindergartenId}/plans/${planId}/pdf`, {params: options}
        ).then(res => {
            const file = {
                mime_type: 'application/pdf',
                name: `${name}.pdf`
            };
            download(res.data, file);
        });
    }

    downloadChildPlanPdf(kindergartenId, childId, planId, options, name) {
        return http.download(
            `/api/kindergartens/${kindergartenId}/children/${childId}/plans/${planId}/pdf`, {params: options}
        ).then(res => {
            const file = {
                mime_type: 'application/pdf',
                name: `${name}.pdf`
            };
            download(res.data, file);
        });
    }

    getCoursePlanTypes(kindergartenId, courseId, type) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/types/${courseId}`,{
            params: { type: type }
        }).then(res => res.data);
    }

    getCoursePlans(kindergartenId, courseId, type, year) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/course/${courseId}`,{
            params: { type: type, year: year }
        }).then(res => res.data);
    }

    getCoursePlansOverView(kindergartenId, courseId, type) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/course/${courseId}/overview`,{
            params: { type: type}
        }).then(res => res.data);
    }

    getPlanSubtypes(kindergartenId, type) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/types`, {
                params: { type: type }
            }).then(res => res.data);
    }

    deletePlanSetting(kindergartenId, settingId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/plans/settings/${settingId}/destroy`)
            .then(res => res.data);
    }

    getProjectPlans(kindergartenId, courseId) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/project/${courseId}`)
            .then(res => res.data);
    }

    getProjectPlan(kindergartenId, planId) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/project/${planId}/show`)
            .then(res => res.data);
    }

    storeProjectPlan(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/plans/project/store`, data)
            .then(res => res.data);
    }

    updateProjectPlan(kindergartenId, planId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/plans/project/${planId}`, data)
            .then(res => res.data);
    }

    confirmPlan(kindergartenId, planId) {
        return http.put(`/api/kindergartens/${kindergartenId}/plans/${planId}/confirm`)
            .then(res => res.data);
    }

    rejectPlan(kindergartenId, planId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/plans/${planId}/reject`, data)
            .then(res => res.data);
    }

    publishPlan(kindergartenId, planId, status) {
        return http.put(`/api/kindergartens/${kindergartenId}/plans/${planId}/publish`, {published: status}).then(res => res.data);
    }

    deletePlan(kindergartenId, planId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/plans/${planId}/delete`, {published: status}).then(res => res.data);
    }

    checkTypeStatus(kindergartenId, type) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/type-status`, {params: { type: type }})
            .then(res => res.data);
    }

    getPlanTemplate(kindergartenId, templateId) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/template/${templateId}`)
            .then(res => res.data);
    }

    getCdmCategories(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/child-dev-groups`)
            .then(res => res.data);
    }

    getSummaryData(kindergartenId, planId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/${planId}/summary`, {params: options })
            .then(res => res.data);
    }

    uploadDocument(kindergartenId, planId, file) {
        return http.post(`/api/kindergartens/${kindergartenId}/plans/${planId}/document`, file).then(res => res.data);
    }

    getDocument(kindergartenId, file) {
        return http.download(`/api/kindergartens/${kindergartenId}/files/plans/${file.filename}`)
            .then(res => download(res.data, file));
    }

    deleteDocument(kindergartenId, filename) {
        return http.delete(`/api/kindergartens/${kindergartenId}/files/plans/${filename}`)
            .then(res => res.data);
    }

    getYearPlans(kindergartenId, courseId, typeId) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/year/${courseId}`, {params: {type: typeId}})
            .then(res => res.data);
    }

    getYearPlan(kindergartenId, planId) {
        return http.get(`/api/kindergartens/${kindergartenId}/plans/year/${planId}/show`)
            .then(res => res.data);
    }

    storeYearPlan(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/plans/year/store`, data)
            .then(res => res.data);
    }

    updateYearPlan(kindergartenId, planId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/plans/year/${planId}`, data)
            .then(res => res.data);
    }
}

export default new PlanService();
