export const GET_ARNO_COURSES = 'GET_ARNO_COURSES';
export const SET_ARNO_COURSES = 'SET_ARNO_COURSES';
export const GET_ARNO_CHILDREN = 'GET_ARNO_CHILDREN';
export const SET_ARNO_CHILDREN = 'SET_ARNO_CHILDREN';
export const SET_ARNO_ERROR = 'SET_ARNO_ERROR';
export const STORE_ARNO_COURSE = 'STORE_ARNO_COURSE';
export const UPDATE_ARNO_COURSE = 'UPDATE_ARNO_COURSE';
export const UPDATE_ARNO_COURSE_CHILDREN = 'UPDATE_ARNO_COURSE_CHILDREN';
export const RESET_ARNO_STATE = 'RESET_ARNO_STATE';
export const STORE_ARNO_PERIOD = 'STORE_ARNO_PERIOD';
export const SET_ARNO_COURSE = 'SET_ARNO_COURSE';