export const GET_DIARY = 'GET_DIARY';
export const SET_DIARY = 'SET_DIARY';
export const SET_DIARY_ENTRIES = 'SET_DIARY_ENTRIES';
export const GET_DESCRIPTION = 'GET_DESCRIPTION';
export const UPDATE_DIARY_TEXT = 'UPDATE_DIARY_TEXT';
export const GET_DIARY_WEEK = 'GET_DIARY_WEEK';
export const SET_DIARY_WEEK = 'SET_DIARY_WEEK';
export const SET_MY_DAILY_COMMENT = 'SET_MY_DAILY_COMMENT';
export const SET_DAILY_COMMENTS = 'SET_DAILY_COMMENTS';
export const UPDATE_DAILY_COMMENTS = 'UPDATE_DAILY_COMMENTS';
export const SET_MY_ANALYSIS_COMMENT = 'SET_MY_ANALYSIS_COMMENT';
export const SET_ANALYSIS_COMMENTS = 'SET_ANALYSIS_COMMENTS';
export const UPDATE_ANALYSIS_COMMENTS = 'UPDATE_ANALYSIS_COMMENTS';
export const UPDATE_REQUEST_STATUS = 'UPDATE_REQUEST_STATUS';
export const PUBLISH_DIARY = 'PUBLISH_DIARY';
export const STORE_COMMENT = 'STORE_COMMENT';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_ENTRY = 'SET_ENTRY';
export const SET_ENTRIES = 'SET_ENTRIES';
export const STORE_ENTRY = 'STORE_ENTRY';
export const STORE_ENTRIES = 'STORE_ENTRIES';
export const SET_COURSE_SETTINGS = 'SET_COURSE_SETTINGS';
export const SET_COURSE_STATUSES = 'SET_COURSE_STATUSES';
export const SET_COURSE_MEALS = 'SET_COURSE_MEALS';
export const ADD_ABSENCE = 'ADD_ABSENCE';
export const SET_ROW_LOADING = 'SET_ROW_LOADING';
export const UNSET_ROW_LOADING = 'UNSET_ROW_LOADING';
export const SET_DIARY_MEDIA = 'SET_DIARY_MEDIA';
export const SET_DIARY_DOCUMENTS = 'SET_DIARY_DOCUMENTS';
export const ADD_DIARY_MEDIA = 'ADD_DIARY_MEDIA';
export const ADD_DIARY_DOCUMENT = 'ADD_DIARY_DOCUMENT';
export const UPLOAD_DIARY_MEDIA = 'UPLOAD_DIARY_MEDIA';
export const UPLOAD_DIARY_DOCUMENT = 'UPLOAD_DIARY_DOCUMENT';
export const UPDATE_DIARY_FILE = 'UPDATE_DIARY_FILE';
export const DELETE_DIARY_MEDIA = 'DELETE_DIARY_MEDIA';
export const DELETE_DIARY_FILE = 'DELETE_DIARY_FILE';
export const DELETE_DIARY_DOCUMENT = 'DELETE_DIARY_DOCUMENT';
export const CREATE_DIARY = 'CREATE_DIARY';
export const CREATE_AND_PUBLISH_DIARY = 'CREATE_AND_PUBLISH_DIARY';
export const SET_DIARY_ERROR = 'SET_DIARY_ERROR';
export const SET_DIARY_HOLIDAYS = 'SET_DIARY_HOLIDAYS';
export const LIKE_DIARY = 'LIKE_DIARY';
export const SET_DIARY_LIKES = 'SET_DIARY_LIKES';
