import {UPDATE_GUARDIAN_SETTING} from './actions';
import GuardianService from '@services/guardian.service';
import {UPDATE_GUARDIAN_SETTINGS} from '@store/user/actions';

const state = {
    guardians: [],
    guardian_relations: [
        {value: 1, text: 'Mother'},
        {value: 2, text: 'Father'},
        {value: 3, text: 'Grandmother'},
        {value: 4, text: 'Grandfather'},
        {value: 5, text: 'Legal guardian'}
    ]
};
const mutations = {


};
const actions = {
    [UPDATE_GUARDIAN_SETTING]({commit}, data) {
        GuardianService.updateSettings(data.kindergarten_id, data.guardian_id, data.form).then(res => {
            commit(UPDATE_GUARDIAN_SETTINGS, {guardian_id: data.guardian_id, settings: res});
        });
    }
};
const getters = {
    // currentGuardian: (state, getters) => {
    //     return state.guardians.find(guardian => guardian.kindergarten_id === getters.activeKindergartenId);
    // },
    // getGuardians: state => {
    //     return state.guardians;
    // },
    guardianRelations: state => {
        return state.guardian_relations;
    },

};

export default {
    state,
    mutations,
    actions,
    getters
};
