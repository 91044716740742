import store from '@/store';

//TODO: REMOVE COMMENTED LINES IF THE NEW LAYOUT WORKS AND ALL IS GOOD
const Diary = () => import(/* webpackChunkName: "diary-module" */ '@views/diary/Diary');
// const DiaryWeek = () => import(/* webpackChunkName: "diary-module" */ '@views/diary/DiaryWeek');
const DiarySettings = () => import(/* webpackChunkName: "diary-module" */ '@views/diary/settings/views/DiarySettings');
const DiaryStatuses = () => import(/* webpackChunkName: "diary-module" */ '@views/diary/settings/views/DiaryStatuses');
const DiarySettingsIndex = () => import(/* webpackChunkName: "diary-module" */ '@views/diary/settings/index');
const Day = () => import(/* webpackChunkName: "diary-module" */ '@views/diary/views/Day');
const Week = () => import(/* webpackChunkName: "diary-module" */ '@views/diary/views/Week');

export default [
    {
        path: '/diary',
        components: {base: Diary},
        beforeEnter: (to, from, next) => {
            if(store.getters.canAccessDiaryCourse || store.getters.canAccessDiaryManager) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        },
        children: [
            {
                path: 'day',
                name: 'diary',
                components: {diary: Day},
            },
            {
                path: 'week',
                name: 'diary.weekly',
                components: {diary: Week},
            }
        ]
    },
    {
        path: '/diary/settings',
        components: {base: DiarySettingsIndex},
        children: [
            {
                path: '',
                name: 'diary.settings.regular',
                components: {'diary-settings': DiarySettings},
                meta: { type: 1}
            },
            {
                path: '/diary/settings/special',
                name: 'diary.settings.special',
                components: {'diary-settings': DiarySettings},
                meta: { type: 2 }
            },
            {
                path: '/diary/settings/summer',
                name: 'diary.settings.summer',
                components: {'diary-settings': DiarySettings},
                meta: { type: 3 }
            },
            {
                path: '/diary/settings/statuses',
                name: 'diary.settings.statuses',
                components: {'diary-settings': DiaryStatuses}
            },
        ]
    },

];
