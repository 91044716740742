<template>
    <EDropdown
        v-if="selectedCourse && (dropdownItems.courses.length || dropdownItems.archivedCourses.length)"
        id="courses-dropdown"
        :text="`${$t('Class')}: ${selectedCourse && selectedCourse.name}`"
        variant="primary"
        :left="rtl == false"
        :right="rtl == true"
        :items="dropdownItems.courses"
        :extraItems="dropdownItems.archivedCourses"
        :extraItemsButtonText="$t('Archived')"
        :active="active"/>
</template>

<script>
import { mapGetters } from 'vuex';
import HelperMixin from '@common/mixins/Helper.mixin';

export default {
    name: 'ECourseSelect',
    mixins: [HelperMixin],
    data() {
        return {
            active: null
        };
    },
    computed: {
        ...mapGetters(['employeeCourses', 'selectedCourse', 'currentEmployee', 'rtl']),
        dropdownCourses() {
            let courses = [];
            this.employeeCourses.map(c => {
                const course = {
                    id: c.id,
                    key: c.id,
                    name: c.name,
                    action: () => {
                        this.selectCourse(c); // whole object, pluck out whats needed
                    }
                };
                courses.push(course);
            });

            return courses;
        },
        dropdownItems() {
            let courses = [];
            let archivedCourses = [];
            const returnObj = {
                courses: [],
                archivedCourses: []
            };

            this.employeeCourses.map(c => {
                const course = {
                    id: c.id,
                    name: c.name,
                    action: () => {
                        this.selectCourse(c); // whole object, pluck out whats needed
                    }
                };
                if (!c.archived) {
                    courses.push(course);
                } else {
                    archivedCourses.push(course);
                }
            });

            if (courses.length === 0 && archivedCourses.length > 0) {
                returnObj.courses = [...archivedCourses];
                archivedCourses = [];
            } else {
                returnObj.courses = courses;
                returnObj.archivedCourses = archivedCourses;
            }

            this.alphabetSort(returnObj.courses);
            this.alphabetSort(returnObj.archivedCourses);

            return returnObj;
        },
    },
    methods: {
        selectCourse(course) {
            this.$store.commit('SET_EMPLOYEE_COURSE', course);
            this.active = course.id;
            this.$emit('course', course);
        },
    },
};
</script>
