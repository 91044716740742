export const permissions = {
    // Documents
    canAccessDocumentsIndex: state => {
        return 'documents_access_index' in state.permissions;
    },
    canAddFolders: state => {
        return 'documents_add_folders' in state.permissions;
    },

    // Foodmenu
    canEditFoodmenu: state => {
        return 'foodmenu_can_edit' in state.permissions;
    },
    canPublishFoodmenu: state => {
        return 'foodmenu_can_publish' in state.permissions;
    },

    // Child
    canAccessChildIndex: state => {
        return 'child_access_index' in state.permissions;
    },
    managerChildProfile: state => {
        return 'child_access_managerfields' in state.permissions;
    },
    canAccessChildGuardians: state => {
        return 'child_access_parents' in state.permissions;
    },
    canEditChildGuardians: state => {
        return 'child_edit_parents' in state.permissions;
    },
    canRemoveChildGuardians: state => {
        return 'child_remove_parents' in state.permissions;
    },
    canAccessChildMaps: state => {
        return 'child_access_maps' in state.permissions;
    },
    canAddChildMaps: state => {
        return 'child_add_maps' in state.permissions;
    },
    canViewChildMaps: state => {
      return 'child_view_maps' in state.permissions;
    },
    canParentAccessChildMaps: state => {
        return 'child_parent_access_maps' in state.permissions;
    },
    canParentViewChildMaps: state => {
        return 'child_parent_view_map' in state.permissions;
    },
    canAccessChildPlans: state => {
        return 'child_access_plans' in state.permissions;
    },
    canParentViewChild: state => {
      return 'child_access_view' in state.permissions;
    },
    canParentEditChild: state => {
        return 'child_parent_edit' in state.permissions;
    },
    canArchiveChild: state => {
        return 'child_can_archive' in state.permissions;
    },
    canRestoreChild: state => {
        return 'child_can_activate' in state.permissions;
    },
    canAccessAnnouncement: state => {
        return 'announcement_access_index' in state.permissions;
    },
    canCreateAnnouncement: state => {
        return 'announcement_can_create' in state.permissions;
    },
    canSeeMessageReceivers: state => {
        return 'message_can_see_receivers' in state.permissions;
    },
    canSeeAnnouncementReceivers: state => {
        return 'announcement_can_see_receivers' in state.permissions;
    },
    canAccessChildFilesEmployee: state => {
        return 'child_access_files_employee' in state.permissions;
    },
    canEditChildFilesEmployee: state => {
        return 'child_edit_files_employee' in state.permissions;
    },
    canManageChildFilesEmployee: state => {
        return 'child_manage_files_employee' in state.permissions;
    },
    canAccessChildFilesGuardian: state => {
        return 'child_access_files_guardian' in state.permissions;
    },
    canEditChildFilesGuardian: state => {
        return 'child_edit_files_guardian' in state.permissions;
    },
    canManageChildFilesGuardian: state => {
        return 'child_manage_files_guardian' in state.permissions;
    },

    // Courses
    canEditCourseChildren: state => {
        return 'courses_edit_children' in state.permissions;
    },
    canEditCourseTeachers: state => {
        return 'courses_edit_teachers' in state.permissions;
    },


    // Maps
    canPublishMaps: state => {
        return 'maps_can_publish' in state.permissions;
    },
    canEditMaps: state => {
        return 'maps_can_edit' in state.permissions;
    },
    canEditMapFields: state => {
        return 'maps_can_editfields' in state.permissions;
    },
    canDeleteMap: state => {
        return 'maps_can_delete' in state.permissions;
    },
    canRenameMaps: state => {
        return 'maps_can_rename' in state.permissions;
    },

    // Diary
    canAccessDiaryCourse: state => {
        return 'diary_access_course' in state.permissions;
    },
    canAccessDiaryManager: state => {
        return 'diary_access_manager' in state.permissions;
    },
    canEditDiaryTexts: state => {
        return 'diary_edit_texts' in state.permissions;
    },
    canEditDiaryAttendance: state => {
        return 'diary_edit_attendance' in state.permissions;
    },
    canAddDiaryTexts: state => {
        return 'diary_add_texts' in state.permissions;
    },
    canPublishDiaryTexts: state => {
        return 'diary_publish_texts' in state.permissions;
    },
    canEditAttendance: state => {
        return 'diary_edit_attendance' in state.permissions;
    },
    canUploadDiaryImages: state => {
        return 'diary_add_images' in state.permissions;
    },
    canUploadDiaryDocuments: state => {
        return 'diary_add_documents' in state.permissions;
    },

    //Applications
    canManageApplications: state => {
        return 'applications_access_manage' in state.permissions;
    },

    // Kindergarten
    canAccessKindergartenSettings: state => {
        return 'kindergarten_access_settings' in state.permissions;
    },
    canAccessKindergartenDiarySettings: state => {
        return 'diary_access_settings' in state.permissions;
    },
    canAccessKindergartenChildren: state => {
        return 'kindergarten_access_children' in state.permissions;
    },
    canAccessKindergartenCourses: state => {
        return 'kindergarten_access_courses' in state.permissions;
    },
    canAccessKindergartenEmployees: state => {
        return 'kindergarten_access_employees' in state.permissions;
    },
    canAccessKindergartenGuardians: state => {
        return 'kindergarten_access_guardians' in state.permissions;
    },
    canAccessKindergartenImport: state => {
        return 'kindergarten_access_import' in state.permissions;
    },
    canAddChildren: state => {
        return 'kindergarten_add_children' in state.permissions;
    },
    canAccessKindergartenTargetGroups: state => {
        return 'kindergarten_access_targetgroups' in state.permissions;
    },
    canAccessKindergartenDoorCards: state => {
        return 'kindergarten_access_doorcards' in state.permissions;
    },
    canAccessKindergartenPdfSettings: state => {
        return 'kindergarten_access_pdf_settings' in state.permissions;
    },

    // Admin
    canAccessAdminIndex: state => {
        return 'admin_access_index' in state.permissions;
    },
    canAccessUsersIndex: state => {
        return 'admin_users_index' in state.permissions;
    },
    canAccessUsersAdmins: state => {
        return 'admin_users_admins' in state.permissions;
    },
    canEditUsersAdmins: state => {
        return 'admin_users_admins_edit' in state.permissions;
    },
    canAccessUsersRoles: state => {
        return 'admin_users_roles' in state.permissions;
    },
    canAccessAdminHelp: state => {
        return 'admin_help_index' in state.permissions;
    },
    canAccessKindergartensIndex: state => {
        return 'admin_kindergartens_index' in state.permissions;
    },
    canAccessChildrenIndex: state => {
        return 'admin_children_index' in state.permissions;
    },
    canAccessOrganizationsIndex: state => {
        return 'admin_organizations_index' in state.permissions;
    },
    canAccessTemplatesIndex: state => {
        return 'admin_templates_index' in state.permissions;
    },
    canAccessPublicHolidaysIndex: state => {
        return 'admin_public_holidays_index' in state.permissions;
    },
    canAdminLoginAs: state => {
        return 'admin_can_loginas' in state.permissions;
    },
    canAccessAdminIntegrationIndex: state => {
        return 'admin_integrations_index' in state.permissions;
    },
    canAccessChildDevTemplatesIndex: state => {
        return 'admin_child_dev_templates_index' in state.permissions;
    },
    canAccessAdminMessages: state => {
        return 'admin_message_index' in state.permissions;
    },
    canAccessAdminBanners: state => {
        return 'admin_banners_index' in state.permissions;
    },
    canAccessAdminMarketplaceIndex: state => {
        return 'admin_marketplace_index' in state.permissions;
    },
    canAccessAdminSuppliersIndex: state => {
        return 'admin_suppliers_index' in state.permissions;
    },
    canAccessAdminProductsIndex: state => {
        return 'admin_products_index' in state.permissions;
    },
    canAccessAdminServicesIndex: state => {
        return 'admin_services_index' in state.permissions;
    },
    canAccessAdminFiltersIndex: state => {
        return 'admin_filters_index' in state.permissions;
    },
    canAccessAdminOrdersIndex: state => {
        return 'admin_orders_index' in state.permissions;
    },
    canAccessAdminSsoClientsIndex: state => {
        return 'admin_sso_clients_index' in state.permissions;
    },
    canEditAdminSsoClients: state => {
        return 'admin_sso_clients_edit' in state.permissions;
    },
    canAccessAdminSurveysIndex: state => {
        return 'admin_survey_index' in state.permissions;
    },

    // Reports
    canAccessReportsOccupancy: state => {
        return 'reports_access_occupancy' in state.permissions;
    },
    canAccessReportsAttendance: state => {
        return 'reports_access_attendance' in state.permissions;
    },
    canViewAttendanceReportWithoutCourses: state => {
        return 'reports_view_attendance_all' in state.permissions;
    },
    canViewAttendanceSummaryData: state => {
        return 'reports_view_attendance_summary_data' in state.permissions;
    },
    canViewAttendanceSummaryDiscount: state => {
        return 'reports_view_attendance_summary_discount' in state.permissions;
    },
    canAccessReportsMeals: state => {
        return 'reports_access_todaysmeals' in state.permissions;
    },
    canAccessReportsChildFeedback: state => {
        return 'reports_access_childfeedback' in state.permissions;
    },
    canAccessReportsCdmChildWithNumbers: state => {
        return 'reports_access_cdm_child_with_numbers' in state.permissions;
    },
    canAccessReportsCdmGroupWithNumbers: state => {
        return 'reports_access_cdm_group_with_numbers' in state.permissions;
    },
    canAccessReportsEmployeeMeals: state => {
        return 'reports_access_employeemeals' in state.permissions;
    },
    canAccessReportsGuardianStatistics: state => {
        return 'reports_access_guardiansstatistics' in state.permissions;
    },
    canAccessReportsDiary: state => {
        return 'reports_access_diary' in state.permissions;
    },
    canAccessOrgReportsOccupancy: state => {
        return 'organizations_reports_access_occupancy' in state.permissions;
    },
    canAccessOrgReportsAttendance: state => {
        return 'organizations_reports_access_attendance' in state.permissions;
    },
    canViewOccupancyDiscount: state => {
        return 'reports_view_occupancy_discount' in state.permissions;
    },
    canViewOccupancyData: state => {
        return 'reports_view_occupancy_data' in state.permissions;
    },
    canViewOccupancyWithoutCourses: state => {
        return 'reports_view_occupancy_all' in state.permissions;
    },
    canAccessReportsWorkschedule: state => {
        return 'reports_access_workschedule' in state.permissions;
    },

    // Foodmenu
    canAccessFoodmenuIndex: state => {
        return 'foodmenu_access_index' in state.permissions;
    },
    canAccessFoodmenuStatistics: state => {
        return 'statistics_access_foodmenu' in state.permissions;
    },

    // Events
    canAccessEventsIndex: state => {
        return 'events_access_index' in state.permissions;
    },
    canAccessOrgEventsIndex: state => {
        return 'organizations_events_access_index' in state.permissions;
    },
    canAddEvent: state => {
        return 'events_can_add' in state.permissions;
    },

    // Contacts
    canAccessContactsKindergarten: state => {
        return 'contacts_access_kindergarten' in state.permissions;
    },
    canAccessContactsEmployees: state => {
        return 'contacts_access_employees' in state.permissions;
    },
    canAccessContactsParents: state => {
        return 'contacts_access_parents' in state.permissions;
    },
    canAccessContactsOrganization: state => {
        return 'contacts_access_organization' in state.permissions;
    },
    canAccessContactsKindergartens: state => {
        return 'organizations_access_contacts' in state.permissions;
    },

    // Plans plans_access_index
    canAccessPlansIndex: state => {
        return 'plans_access_index' in state.permissions;
    },
    canAccessPlansSettings: state => {
        return 'plans_access_settings' in state.permissions;
    },
    canAccessWeekPlan: state => {
        return 'plans_access_week' in state.permissions;
    },
    canAccessMonthPlan: state => {
        return 'plans_access_month' in state.permissions;
    },
    canAccessYearPlan: state => {
        return 'plans_access_year' in state.permissions;
    },
    canAccessProjectPlan: state => {
        return 'plans_access_project' in state.permissions;
    },
    canAccessPlans: state => {
        return 'plans_access_plan' in state.permissions;
    },
    canAccessOverView: state => {
        return 'plans_access_overview' in state.permissions;
    },
    canAccessTemplates: state => {
        return 'plans_access_templates' in state.permissions;
    },
    canPublishPlan: state => {
        return 'plans_can_publish' in state.permissions;
    },
    canConfirmPlan: state => {
        return 'plans_can_confirm' in state.permissions;
    },
    canEditPlans: state => {
        return 'plans_can_edit' in state.permissions;
    },
    canEditPlanFields: state => {
        return 'plans_can_editfields' in state.permissions;
    },
    canManagerAccessPlans: state => {
        return 'plans_access_manager' in state.permissions;
    },
    canUploadPlanDocuments: state => {
      return 'plans_add_documents' in state.permissions;
    },

    // Child Development
    canAccessChildDevelopment: state => {
        return 'childdev_access_index' in state.permissions;
    },
    canManageChildDevelopment: state => {
        return 'childdev_access_management' in state.permissions;
    },
    canEditChildDevCategories: state => {
        return 'childdev_categories_edit' in state.permissions;
    },
    canEditChildDevGoals: state => {
        return 'childdev_goals_edit' in state.permissions;
    },
    canEditChildDevSettings: state => {
        return 'childdev_access_settings' in state.permissions;
    },

    canAccessEvaluations: state => {
        return 'childdev_access_evaluation' in state.permissions;
    },

    canViewChildDevelopement: state => {
        return 'childdev_goals_view' in state.permissions;
    },
    canEditChildDevelopement: state => {
        return 'childdev_goals_evaluate' in state.permissions;
    },
    canViewChildDevelopementParent: state => {
        return 'child_dev_goal_view_parent' in state.permissions;
    },

    //Gallery
    canAccessGalleryIndex: state => {
        return 'gallery_access_index' in state.permissions;
    },
    canAddGalleryFolder: state => {
        return 'gallery_add_folders' in state.permissions;
    },

    //Workschedule
    canAccessWorkScheduleIndex : state => {
        return 'workschedule_access_index' in state.permissions;
    },
    canAccessWorkScheduleSettings : state => {
        return 'workschedule_access_settings' in state.permissions;
    },
    canViewOwnWorkSchedule : state => {
        return 'workschedule_view_own' in state.permissions;
    },
    canViewCourseWorkSchedule : state => {
        return 'workschedule_view_course' in state.permissions;
    },
    canViewEveryonesWorkSchedule : state => {
        return 'workschedule_view_all' in state.permissions;
    },
    canAddOwnWorkSchedule : state => {
        return 'workschedule_add_own' in state.permissions;
    },
    canAddCourseWorkSchedule : state => {
        return 'workschedule_add_course' in state.permissions;
    },
    canAddEveryonesWorkSchedule : state => {
        return 'workchedule_add_all' in state.permissions;
    },
    canEditOwnWorkSchedule : state => {
        return 'workschedule_edit_own' in state.permissions;
    },
    canEditCourseWorkSchedule : state => {
        return 'workschedule_edit_course' in state.permissions;
    },
    canEditEveryonesWorkSchedule : state => {
        return 'workchedule_edit_all' in state.permissions;
    },
    canDeleteOwnWorkSchedule : state => {
        return 'workschedule_delete_own' in state.permissions;
    },
    canDeleteEveryonesWorkSchedule : state => {
        return 'workchedule_delete_all' in state.permissions;
    },
    canAddLockedWorkSchedule: state => {
        return 'workschedule_add_locked' in state.permissions;
    },
    canEditLockedWorkSchedule: state => {
        return 'workschedule_edit_locked' in state.permissions;
    },
    canDeleteLockedWorkSchedule: state => {
        return 'workschedule_delete_locked' in state.permissions;
    },

    //Employee meals
    canAccessEmployeeMealsIndex : state => {
        return 'employeemeals_access_index' in state.permissions;
    },
    canAccessEmployeeMealsSettings : state => {
        return 'employeemeals_access_settings' in state.permissions;
    },
    canViewOwnEmployeeMeals : state => {
        return 'employeemeals_view_own' in state.permissions;
    },
    canViewCourseEmployeeMeals : state => {
        return 'employeemeals_view_course' in state.permissions;
    },
    canViewEveryonesEmployeeMeals : state => {
        return 'employeemeals_view_all' in state.permissions;
    },
    canEditOwnEmployeeMeals : state => {
        return 'employeemeals_edit_own' in state.permissions;
    },
    canEditCourseEmployeeMeals : state => {
        return 'employeemeals_edit_course' in state.permissions;
    },
    canEditEveryonesEmployeeMeals : state => {
        return 'employeemeals_edit_all' in state.permissions;
    },

    //Approvals
    canManageKindergartenChildApprovals: state => {
        return 'kindergarten_approvals_manage' in state.permissions;
    },
    canViewChildApprovals: state => {
        return 'child_approvals_view' in state.permissions;
    },
    canEditChildApprovals: state => {
        return 'child_approvals_edit' in state.permissions;
    },
    canAccessReportsApprovals: state => {
        return 'reports_access_approvals' in state.permissions;
    },
    canAccessReportsApprovalsAll: state => {
        return 'reports_access_approvals_all' in state.permissions;
    },

    // Ideabank
    canAccessIdeaIndex: state => {
        return 'ideabank_access_index' in state.permissions;
    },

    // Supplier
    canEditSupplierOrders: state => {
        return 'supplier_can_edit_orders' in state.permissions;
    },
    canEditSupplierPricelists: state => {
        return 'supplier_can_edit_pricelists' in state.permissions;
    },
    canEditSupplierProducts: state => {
        return 'supplier_can_edit_products' in state.permissions;
    },
    canEditSupplierServices: state => {
        return 'supplier_can_edit_services' in state.permissions;
    },
    canEditSupplierReps: state => {
        return 'supplier_can_edit_reps' in state.permissions;
    },
    canEditSupplierInfo: state => {
        return 'supplier_can_edit_supplier_info' in state.permissions;
    },
    canEditSupplierIntegrations: state => {
        return 'supplier_can_edit_integrations' in state.permissions;
    },

    // Shop
    canAddToShopOrder: state => {
        return 'shop_access_kindergarten_orders' in state.permissions;
    },
    canAddToWishlist: state => {
        return 'shop_access_kindergarten_wishlist' in state.permissions;
    },
    canSendOrders: state => {
        return 'shop_can_order' in state.permissions;
    },

    //Surveys
    canAccessSurveyIndex: state => {
        return 'surveys_access_index' in state.permissions;
    },
    canAccessQuestionnaires: state => {
        return 'surveys_access_questionnaires' in state.permissions;
    },
    canAccessPolls: state => {
        return 'surveys_access_polls' in state.permissions;
    },
    canManageQuestionnaires: state => {
        return 'surveys_access_questionnaires_manage' in state.permissions;
    },
    canManagePolls: state => {
        return 'surveys_access_polls_manage' in state.permissions;
    }
};
