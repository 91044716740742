import http from '@http';

class DoorCardService {
    getDoorCardChildren(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/door-cards`, {params: queryObj}).then(res => res.data);
    }

    getDoorCardDoors(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/door-cards/doors`).then(res => res.data);
    }

    updateDoor(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/door-cards/doors`, data).then(res => res.data);
    }

    addGuardianCard(kindergartenId, childId, guardianId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/door-cards/cards/${childId}/${guardianId}`, data).then(res => res.data);
    }

    updateGuardianCard(kindergartenId, childId, guardianId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/door-cards/cards/${childId}/${guardianId}`, data).then(res => res.data);
    }

    deleteGuardianCard(kindergartenId, childId, guardianId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/door-cards/cards/${childId}/${guardianId}`).then(res => res.data);
    }
}

export default new DoorCardService();