<template>
    <div class="notranslate" :class="headerMessage ? 'e3-quill-header' : ''">
        <div v-if="headerMessage" class="main-gradient border border-bottom-0 rounded-top p-2">{{ headerMessage }}</div>
        <div ref="editor"/>
        <ValitationMessages :validation="validation"/>
    </div>
</template>

<script>
import Quill from 'quill';
import ValitationMessages from '../input/ValitationMessages';
import QuillRegisterMixin from '@common/components/quill/abstracts/QuillRegister.mixin';

export default {
    mixins: [QuillRegisterMixin],
    props: {
        id: String,
        placeholder: {
            type: String,
            default: 'Write something …'
        },
        content: {
            type: String,
            default: ''
        },
        validation: Object,
        headerMessage: String,
        hasColour: {
            type: Boolean,
            default: true
        },
        shouldFocus: {
            type: Boolean,
            default: false
        },
        allowImage : {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editor: null,
        };
    },
    watch: {
        content: function(newVal) {
            if (newVal && newVal !== this.content) {
                this.setEditorContent(newVal);
            }
        },
        id: function() {
            const delta = this.editor.clipboard.convert(this.content);
            this.editor.setContents(delta, 'silent');
        },
    },
    methods: {
        setEditorContent(val) {
            const delta = this.editor.clipboard.convert(val);
            this.editor.setContents(delta, 'silent');
        },
        update() {
            this.$emit(
                'input',
                this.editor.root.innerHTML !== '<p><br></p>'
                    ? this.editor.root.innerHTML
                    : ''
            );

            const range = this.editor.getSelection();

            if (range) {
                this.$emit('setLastIndex', range.index);
            }
        },
        blurEvent() {
            this.$emit(
                'blur',
                this.editor.root.innerHTML !== '<p><br></p>'
                    ? this.editor.root.innerHTML
                    : ''
            );
        },
        setFocus(index = 0) {
            this.editor.setSelection(index, 0);
        },
    },
    components: {
        ValitationMessages
    },
    mounted() {
        const toolbarOptions = ['bold', 'italic', 'underline', 'strike',
                                'link',
                                { list: 'ordered' }, { list: 'bullet' },
                                'clean'
        ];

        const formatOptions = ['link', 'bold', 'underline', 'italic', 'strike', 'list'];

        if (this.hasColour) {
            toolbarOptions.push({ color: [] });
            toolbarOptions.push({ background: [] });
            formatOptions.push('color');
            formatOptions.push('background');
        }

        if (this.allowImage) {
            formatOptions.push('image');
        }

        this.editor = new Quill(this.$refs.editor, {
            modules: {
                toolbar: toolbarOptions
            },
            placeholder: this.placeholder,
            theme: 'snow',
            formats: formatOptions
        });

        this.setEditorContent(this.content);
        this.editor.on('text-change', () => this.update());
        this.editor.on('selection-change', (range) => {
            if (this.shouldFocus && range) {
                this.$emit('setLastIndex', range.index);
            }

            if (range) {
                if (range.length == 0) {
                    //console.log('User cursor is on', range.index);
                } else {
                    //var text = this.editor.getText(range.index, range.length);
                    //console.log('User has highlighted');
                }
            } else {
                //console.log('Cursor not in the editor');
                this.blurEvent();
            }
        });

        this.editor.clipboard.addMatcher(Node.TEXT_NODE, this.quillLinking);

        if (this.shouldFocus) {
            this.setFocus();
        }
    },
};
</script>

<style></style>
