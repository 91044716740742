<template>
    <b-dropdown
        v-if="hasContent || hasButtonContent"
        :id="id"
        :ref="`${id}_dropdown`"
        :disabled="disable"
        :size="hasSize"
        :text="text"
        :variant="hasVariant"
        :offset="hasOffset"
        :toggle-class="hasToggleClass"
        :no-caret="hasNoCaret"
        :right="isRight"
        :left="isLeft"
        :class="hasClass"
        :boundary="boundary"
        @show="toggleDropOpen"
        @hide="hideDropdown">
        <template>
            <slot v-if="dropOpen" name="content"/>
            <template slot="button-content">
                <SvgIcon v-if="isIcon" :icon="icClass" :size="hasIconSize" :class="isGray"/>
                <slot v-if="hasButtonContent" name="button-content"/>
            </template>
        </template>
    </b-dropdown>
    <b-dropdown
        v-else
        :id="id"
        :ref="`${id}_dropdown`"
        :disabled="disable"
        :size="hasSize"
        :text="text"
        :variant="hasVariant"
        :offset="hasOffset"
        :toggle-class="hasToggleClass"
        :no-caret="hasNoCaret"
        :right="isRight"
        :left="isLeft"
        :class="[{'e3-single-item': !showRegularDropdown()}, dropClass]"
        split
        :boundary="boundary"
        @show="toggleDropOpen"
        @hide="hideDropdown"
        @click="showRegularDropdown() ? openDropdown() : null">
        <template>
            <b-dropdown-item
                v-for="item in items"
                :id="item.id"
                :key="item.key || item[keyName]"
                :disabled="item.disabled"
                :active="item[keyName] == active"
                @click="item.action()">
                <div class="d-flex align-items-center">
                    <SvgIcon v-if="item.type === 'edit'" icon="mdi-account-edit-outline" size="20" class="mb-1"/>
                    <SvgIcon v-if="item.type === 'delete'" icon="mdi-trash-can-outline" size="20" class="mb-1"/>
                    <span>{{ item.name }}</span>
                    <template v-if="item.type === 'survey-radio'">
                        <div class="d-flex align-items-center ml-2">
                            <ERadio id="dropdown-radio" class="mr-1" value="true"/>
                            <ERadio id="dropdown-radio-value"/>
                        </div>
                    </template>
                    <template v-if="item.type === 'survey-check'">
                        <div class="d-flex align-items-center ml-2">
                            <ECheckbox id="dropdown-check" class="mr-2" success/>
                            <ECheckbox id="dropdown-check-value" value="true" success/>
                        </div>
                    </template>
                </div>
            </b-dropdown-item>
            <template v-if="extraItems && extraItems.length">
                <li class="pt-1">
                    <div class="dropdown-item">
                        <button class="btn btn-link text-muted p-0 d-flex align-items-center text-decoration-none" @click="toggleExtraItems">
                            <SvgIcon :icon="extraItemsVisible ? 'mdi-minus' : 'mdi-plus'" iconSize="mdi-16px" colour="text-muted" class="mr-1"/>
                            <span>{{extraItemsButtonText}}</span>
                        </button>
                    </div>
                </li>
                <template v-if="extraItemsVisible">
                    <b-dropdown-item
                        v-for="item in extraItems"
                        :id="item.id"
                        :key="item.key || item[keyName]"
                        :disabled="item.disabled"
                        :active="item[keyName] == active"
                        @click="item.action()">
                        <div class="d-flex align-items-center">
                            <SvgIcon v-if="item.type === 'edit'" icon="mdi-account-edit-outline" iconSize="mdi-20px" colour="" class="mb-1"/>
                            <SvgIcon v-if="item.type === 'delete'" icon="mdi-trash-can-outline" iconSize="mdi-20px" colour="" class="mb-1"/>
                            <span>{{ item.name }}</span>
                        </div>
                    </b-dropdown-item>
                </template>
            </template>
        </template>
    </b-dropdown>
</template>

<script>
export default {
    name: 'Dropdown',
    props: {
        id: [String, Number],
        size: String,
        dropClass: String,
        text: String,
        variant: String,
        offset: [String, Number],
        toggleClass: String,
        noCaret: Boolean,
        icon: Boolean,
        iconSize: String,
        iconClass: String,
        items: Array,
        extraItems: Array,
        right: Boolean,
        left: Boolean,
        gray: String,
        active: [String, Number],
        keyName: {
            type: String,
            default: 'id'
        },
        extraItemsButtonText: String,
        disable: {
            type: Boolean,
            default: false

        },
        boundary: {
            type: String,
            default: 'scrollParent'
        }
    },
    data() {
        return {
            dropOpen: false,
            extraItemsVisible: false,
        };
    },
    computed: {
        hasButtonContent() {
            return !!this.$slots['button-content'];
        },
        hasContent() {
            return !!this.$slots['content'];
        },
        hasClass() {
            return this.dropClass;
        },
        isLeft() {
            return this.left;
        },
        isRight() {
            return this.right;
        },
        isGray() {
            return this.gray;
        },
        isIcon() {
            return this.icon;
        },
        icClass() {
            return this.iconClass;
        },
        hasIconSize() {
            return this.iconSize;
        },
        hasSize() {
            return this.size;
        },
        hasVariant() {
            return this.variant;
        },
        hasOffset() {
            return this.offset;
        },
        hasToggleClass() {
            return this.toggleClass;
        },
        hasNoCaret() {
            return this.noCaret;
        },
    },
    methods: {
        hide() {
            this.$refs[`${this.id}_dropdown`].hide();
        },
        showRegularDropdown() {
            const extraItemsCount = this.extraItems ? this.extraItems.length : 0;

            return (this.items.length + extraItemsCount) > 1;
        },
        toggleDropOpen() {
            this.dropOpen = true;
            this.$emit('toggle', true);
        },
        hideDropdown() {
            this.dropOpen = false;
            this.$emit('toggle', false);
        },
        openDropdown() {
            this.$refs[`${this.id}_dropdown`].show();
        },
        toggleExtraItems() {
            this.extraItemsVisible = !this.extraItemsVisible;
        },
        findValueObject() {
            let item = this.items.find(item => item.id == this.text);
            return item.name;
        },
    }
};
</script>
