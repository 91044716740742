import http from '@http';

class AdminOrganizationService {
    constructor() {
        if (AdminOrganizationService.instance) {
            return AdminOrganizationService.instance;
        }

        AdminOrganizationService.instance = this;
    }

    getOrganizations(queryObj) {
        return http.get('/api/admin/organizations', {params: queryObj}).then(res => res.data);
    }

    getOrganizationsList() {
        return http.get('/api/admin/organizations/list').then(res => res.data);
    }

    getOfficials(organizationId, queryObj) {
        return http.get(`/api/admin/organizations/${organizationId}/officials`, {params: queryObj}).then(res => res.data);
    }

    storeOrganization(data) {
        return http.post('/api/admin/organizations', data).then(res => res.data);
    }

    storeOfficial(organizationId, data) {
        return http.post(`/api/admin/organizations/${organizationId}/officials`, data).then(res => res.data);
    }

    deleteOfficial(organizationId, officialId) {
        return http.delete(`/api/admin/organizations/${organizationId}/officials/${officialId}`).then(res => res.data);
    }

    updateOrganizationOfficial(organizationId, officialId, data) {
        return http.put(`/api/admin/organizations/${organizationId}/officials/${officialId}`, data).then(res => res.data);
    }

    getRoles(organizationId, queryObj) {
        return http.get(`/api/admin/organizations/${organizationId}/roles`, {params: queryObj}).then(res => res.data);
    }

    getTargetGroups(organizationId, queryObj) {
        return http.get(`/api/admin/organizations/${organizationId}/target-groups`, {params: queryObj}).then(res => res.data);
    }

    getKindergartens(organizationId, queryObj) {
        return http.get(`/api/admin/organizations/${organizationId}/kindergartens`, {params: queryObj}).then(res => res.data);
    }

}
export default new AdminOrganizationService();