import ApprovalService from '@services/approval.service';
import {
    GET_KINDERGARTEN_APPROVALS,
    ADD_KINDERGARTEN_APPROVAL,
    SET_KINDERGARTEN_APPROVALS,
    SET_KINDERGARTEN_APPROVAL,
    REMOVE_KINDERGARTEN_APPROVAL,
    CREATE_KINDERGARTEN_APPROVAL,
    UPDATE_KINDERGARTEN_APPROVAL_STATUS,
    DELETE_KINDERGARTEN_APPROVAL
} from './actions';
import Vue from 'vue';

const state = {
    kindergartenApprovals: []
};

const getters = {
    kindergartenApprovals: state => state.kindergartenApprovals
};

const mutations = {
    [SET_KINDERGARTEN_APPROVALS](state, data) {
        state.kindergartenApprovals = data;
    },
    [SET_KINDERGARTEN_APPROVAL](state, data) {
        let index = state.kindergartenApprovals.findIndex(approval => approval.id === data.id);
        Vue.set(state.kindergartenApprovals, index, data);
    },
    [ADD_KINDERGARTEN_APPROVAL](state, approval) {
        state.kindergartenApprovals.push(approval);
    },
    [REMOVE_KINDERGARTEN_APPROVAL](state, approvalId) {
        const filteredApprovals = state.kindergartenApprovals.filter(p => p.id !== approvalId);
        Vue.set(state, 'kindergartenApprovals', [...filteredApprovals]);
    }
};

const actions = {
    [GET_KINDERGARTEN_APPROVALS]({ commit, getters }) {
        return ApprovalService.getKindergartenApprovals(getters.activeKindergartenId).then(res => {
            commit(SET_KINDERGARTEN_APPROVALS, res);
        });
    },
    [UPDATE_KINDERGARTEN_APPROVAL_STATUS]({ commit, getters }, payload) {
        return ApprovalService.updateKindergartenApprovalStatus(getters.activeKindergartenId, payload).then(res => {
            commit(SET_KINDERGARTEN_APPROVAL, res);
        });
    },
    [CREATE_KINDERGARTEN_APPROVAL]({ commit, getters }, question) {
        return ApprovalService.createKindergartenApproval(getters.activeKindergartenId, question).then(res => {
            commit(ADD_KINDERGARTEN_APPROVAL, res);
        });
    },
    [DELETE_KINDERGARTEN_APPROVAL]({ commit, getters }, approvalId) {
        return ApprovalService.deleteKindergartenApproval(getters.activeKindergartenId, approvalId).then(res => {
            if (res.deleted) {
                commit(REMOVE_KINDERGARTEN_APPROVAL, approvalId);
            }
        });
    },
};


export default {
    state,
    getters,
    mutations,
    actions
};
