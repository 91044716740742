<template>
    <div class="e3-head-icon-round">
        <div class="d-flex align-items-center px-3 py-2">
            <span class="font-weight-bold text-uppercase">{{ $t('Events') }}</span>
        </div>
        <div class="border-top">
            <div v-if="processedEvents.length">
                <div v-for="event in processedEvents" :key="event.id" class="e3-pointer d-flex flex-row px-3 py-2" @click="showEvent(event.id)">
                    <div class="profile-image-container">
                        <ProfileImage
                            :imageUrl="event.creator.profile_picture"
                            :name="event.creator.name"
                            size="sm"/>
                    </div>
                    <div class="d-flex flex-column ml-2 w-100">
                        <div class="font-weight-bold">
                            {{ event.creator.name }}
                        </div>
                        <div class="text-primary text-break">{{ event.title }}</div>
                        <div v-if="event.description" class="text-break">{{ event.description }}</div>
                        <small class="text-muted">
                            <span class="mr-2">{{ event.start_date }}</span>
                            <span v-if="event.start_time" class="mr-2">{{ event.start_time }}</span>
                            <span v-if="event.is_recurring">({{ $t("Recurring event") }})</span>
                        </small>
                    </div>
                </div>
            </div>
            <empty-list-message v-else wrapperClass="p-2">
                {{ $t("No new events") }}
            </empty-list-message>
        </div>
        <div class="bg-light px-3 py-2 border-top rounded-bottom">
            <router-link :to="{ name: showRoute }" @click.native="$emit('close', id)">
                {{ $t('Show all events') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {eventBus} from '@/main';
import {formatDate, truncate, formatLTTime, stripHtmlTags} from '@/filters';

export default {
    props: {
        id: [String, Number]
    },
    data() {
        return {
            calendarCount: 0
        };
    },
    computed: {
        ...mapGetters([
            'newEvents',
            'loginType',
            'activeOrganizationId',
            'activeKindergartenId',
            'canAccessEventsIndex',
            'canAccessOrgEventsIndex'
        ]),
        showRoute() {
            return this.activeKindergartenId ? 'events.calendar' : 'events.organization.calendar';
        },
        processedEvents() {
            return this.newEvents.map(event => {
                let description = null;

                if (event.description) {
                    description = stripHtmlTags(event.description);
                    description = truncate(description, 64);
                }

                return {
                    ...event,
                    title: truncate(event.title, 64),
                    description,
                    start_date: formatDate(event.start_date),
                    start_time: event.start_time ? formatLTTime(event.start_time) : null
                };
            });
        }
    },
    methods: {
        showEvent(eventId) {
            const originalEventData = this.newEvents.find(event => event.id === eventId);
            if (originalEventData) {
                eventBus.$emit('event-wrapper-modal', originalEventData);
            }
        },
        getRecentEvents() {
            if(this.activeKindergartenId && this.canAccessEventsIndex) {
                this.$store.dispatch('GET_RECENT_EVENTS', this.activeKindergartenId);
            }
            if(this.activeOrganizationId && this.canAccessOrgEventsIndex) {
                this.$store.dispatch('GET_RECENT_ORG_EVENTS', this.activeKindergartenId);
            }
        }
    },
    created() {
        this.getRecentEvents();
    }
};
</script>
