import {
    SET_RECENT_NOTIFICATIONS_COUNT,
    GET_RECENT_NOTIFICATIONS,
    SET_RECENT_NOTIFICATIONS,
    GET_NOTIFICATIONS,
    ADD_NOTIFICATIONS,
    SET_NOTIFICATIONS_HAS_MORE
} from './actions';
import NotificationService from '@services/notification.service';
const defaultState = () => {
    return {
        unread_notifications_count: 0,
        recent_notifications: [],
        notifications: [],
        has_more: false,
    };
};

const state = defaultState;

const mutations = {
    [SET_RECENT_NOTIFICATIONS_COUNT](state, count) {
        state.unread_notifications_count = count;
    },
    [SET_RECENT_NOTIFICATIONS](state, notifications) {
        state.recent_notifications = notifications;
        state.unread_notifications_count = 0;
    },
    [ADD_NOTIFICATIONS](state, notifications) {
      notifications.forEach(notification => {
          state.notifications.push(notification);
      });
    },
    [SET_NOTIFICATIONS_HAS_MORE]( state, has_more) {
        state.has_more = has_more;
    }
};

const actions = {
    [GET_RECENT_NOTIFICATIONS]({commit, getters}) {
        return NotificationService.getRecentNotifications(getters.activeKindergartenId).then(res => {
            commit(SET_RECENT_NOTIFICATIONS, res.notifications);
        });
    },
    [GET_NOTIFICATIONS]({commit, state}, queryObj) {
        if(queryObj.page === 1) {
            state.notifications = [];
        }
        return NotificationService.getNotifications(queryObj).then(res => {
            commit(ADD_NOTIFICATIONS, res.notifications);
            commit(SET_NOTIFICATIONS_HAS_MORE, res.has_more);
        });
    }
};

const getters = {
    unreadNotificationsCount: state => state.unread_notifications_count,
    recentNotifications: state => state.recent_notifications,
    generalNotifications: state => state.notifications,
    hasMoreNotifications: state => state.has_more
};

export default {
    state,
    mutations,
    actions,
    getters
};