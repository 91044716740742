import {
    GET_ADMIN_ORGANIZATIONS,
    SET_ADMIN_ORGANIZATIONS,
    STORE_ORGANIZATION,
    ADD_ORGANIZATION,
    GET_USERS,
    SET_USERS,
    GET_ADMIN_USERS,
    SET_ADMIN_USERS,
    ADD_ADMIN_USER,
    STORE_ADMIN_USER,
    EDIT_ADMIN_USER,
    SET_ADMIN_USER,
    DELETE_ADMIN_USER,
    REMOVE_ADMIN_USER,
    GET_ADMIN_ROLES,
    SET_ADMIN_ROLES,
    GET_ADMIN_PERMISSIONS,
    SET_ADMIN_PERMISSIONS,
    UPDATE_ADMIN_ROLE,
    SET_ADMIN_ROLE,
    STORE_ADMIN_ROLE,
    ADD_ADMIN_ROLE,
    DELETE_ADMIN_ROLE,
    REMOVE_ADMIN_ROLE,
    GET_ADMIN_KINDERGARTENS,
    SET_ADMIN_KINDERGARTENS,
    GET_ADMIN_COUNTRIES,
    SET_ADMIN_COUNTRIES,
    STORE_ADMIN_KINDERGARTEN,
    ADD_ADMIN_KINDERGARTEN,
    SET_USER_SEARCH,
    SET_EMPLOYEE_SEARCH,
    SET_GUARDIAN_SEARCH,
    SET_CLASS_SEARCH,
    SET_APPLICATION_SEARCH,
    SET_TARGET_GROUP_SEARCH,
    SET_OFFICIAL_SEARCH,
    SET_ROLE_SEARCH,
    GET_ADMIN_SUPPLIERS,
    SET_ADMIN_SUPPLIERS,
    VALIDATE_ADMIN_SUPPLIER,
    INVALIDATE_ADMIN_SUPPLIER,
    SET_ADMIN_SUPPLIER,
    SET_ADMIN_KINDERGARTEN_CHILD,
    SET_ADMIN_KINDERGARTEN_COURSE
} from './actions';
import AdminUserService from '@services/admin_user.service';
import AdminOrganizationService from '@services/admin_organization.service';
import AdminKindergartenService from '@services/admin_kindergarten.service';
import AdminSupplierService from '@services/admin_supplier.service';
import Vue from 'vue';
import {surveyState, surveyMutations, surveyActions, surveyGetters} from './surveys';
import {childdevState, childdevMutations, childdevActions, childdevGetters} from './childdev';
const defaultState = () => {
    return {
        ...childdevState,
        ...surveyState,
        users: {},
        adminUsers: {},
        adminPermissions: [],
        adminRoles: {
            data: []
        },
        kindergartens: {},

        countries: [],
        children: [],
        organizations: {},
        suppliers: {},

        userSearchValue: null,
        employeeSearchValue: null,
        guardianSearchValue: null,
        courseSearchValue: null,
        applicationSearchValue: null,
        childSearchValue: null,
        targetGroupSearchValue: null,
        officialSearchValue: null,
        roleSearchValue: null,
        selectedAdminKindergartenChild: {},
        selectedAdminKindergartenCourse: {},
    };
};

const state = defaultState;

const mutations = {
    ...childdevMutations,
    ...surveyMutations,
    //Organizations
    [SET_ADMIN_ORGANIZATIONS] (state, organizations) {
        state.organizations = organizations;
    },
    [ADD_ORGANIZATION] (state, organization) {
        state.organizations.data?.push(organization);
    },
    [SET_ADMIN_KINDERGARTENS] (state, kindergartens) {
        state.kindergartens = kindergartens;
    },
    [ADD_ADMIN_KINDERGARTEN] ( state, kindergarten) {
        state.kindergartens.data?.push(kindergarten);
    },
    [SET_ADMIN_COUNTRIES] (state, countries) {
        state.countries = countries;
    },
    [SET_USERS] (state, users) {
        state.users = users;
    },
    [SET_ADMIN_USERS] (state, admins) {
        state.adminUsers = admins;
    },
    [ADD_ADMIN_USER] (state, admin) {
        state.adminUsers.data.push(admin);
    },
    [SET_ADMIN_USER] (state, admin) {
        const index = state.adminUsers.data.findIndex(u => u.id === admin.id);
        Vue.set(state.adminUsers.data, index, admin);
    },
    [REMOVE_ADMIN_USER] (state, adminId) {
        const index = state.adminUsers.data.findIndex(u => u.id === adminId);
        state.adminUsers.data.splice(index, 1);
    },
    [SET_ADMIN_PERMISSIONS] (state, permissions) {
        state.adminPermissions = permissions;
    },
    [SET_ADMIN_ROLES] (state, roles) {
        state.adminRoles = roles;
    },
    [SET_ADMIN_ROLE] (state, role) {
        const index = state.adminRoles.data.findIndex(r => r.id === role.id);
        Vue.set(state.adminRoles.data, index, role);
    },
    [ADD_ADMIN_ROLE] (state, role) {
        state.adminRoles.data.push(role);
    },
    [REMOVE_ADMIN_ROLE] (state, roleId) {
        const index = state.adminRoles.data.findIndex(r => r.id === roleId);
        state.adminRoles.data.splice(index, 1);
    },
    [SET_ADMIN_SUPPLIERS] (state, data) {
        state.suppliers = data;
    },
    [SET_ADMIN_SUPPLIER] (state, data) {
        const index = state.suppliers.data.findIndex(s => s.id === data.id);
        Vue.set(state.suppliers.data, index, data);
    },
    [SET_USER_SEARCH] (state, search) {
        state.userSearchValue = String(search);
    },
    [SET_EMPLOYEE_SEARCH] (state, search) {
        state.employeeSearchValue = String(search);
    },
    [SET_GUARDIAN_SEARCH] (state, search) {
        state.guardianSearchValue = String(search);
    },
    [SET_CLASS_SEARCH] (state, search) {
      state.courseSearchValue = String(search);
    },
    [SET_APPLICATION_SEARCH] (state, search) {
        state.applicationSearchValue = String(search);
    },
    [SET_TARGET_GROUP_SEARCH] (state, search) {
        state.targetGroupSearchValue = String(search);
    },
    [SET_OFFICIAL_SEARCH] (state, search) {
        state.officialSearchValue = String(search);
    },
    [SET_ROLE_SEARCH] (state, search) {
        state.roleSearchValue = String(search);
    },
    [SET_ADMIN_KINDERGARTEN_CHILD](state, child) {
        state.selectedAdminKindergartenChild = child;
    },
    [SET_ADMIN_KINDERGARTEN_COURSE](state, course) {
        state.selectedAdminKindergartenCourse = course;
    }
};

const actions = {
    ...childdevActions,
    ...surveyActions,
    //Organizations
    [GET_ADMIN_ORGANIZATIONS]({commit}, queryObj) {
        return AdminOrganizationService.getOrganizations(queryObj).then(res => {
            commit(SET_ADMIN_ORGANIZATIONS, res);
        });
    },
    [STORE_ORGANIZATION]({commit}, data) {
        return AdminOrganizationService.storeOrganization(data).then(res => {
            commit(ADD_ORGANIZATION, res);
        });
    },
    //Kindergartens
    [GET_ADMIN_KINDERGARTENS]({commit}, queryObj) {
        return AdminKindergartenService.getKindergartens(queryObj).then(res => {
            commit(SET_ADMIN_KINDERGARTENS, res);
        });
    },
    [STORE_ADMIN_KINDERGARTEN]({commit}, data) {
        return AdminKindergartenService.addKindergarten(data).then(res => {
            commit(ADD_ADMIN_KINDERGARTEN, res);
        });
    },
    //Countries
    [GET_ADMIN_COUNTRIES]({commit}) {
        return AdminUserService.getAdminCountry().then(res => {
            commit(SET_ADMIN_COUNTRIES, res);
        });
    },
    //Users
    [GET_USERS]({commit}, queryObj) {
        return AdminUserService.getUsers(queryObj).then(res => {
            commit(SET_USERS, res);
        });
    },
    [GET_ADMIN_USERS]({commit}, queryObj) {
        return AdminUserService.getAdmins(queryObj).then(users => {
            commit(SET_ADMIN_USERS, users);
        });
    },
    [STORE_ADMIN_USER]({commit}, data) {
        return AdminUserService.addAdminUser(data).then(res => {
            commit(ADD_ADMIN_USER, res);
        });
    },
    [EDIT_ADMIN_USER]({commit}, data) {
        return AdminUserService.updateAdminUser(data).then(res => {
            commit(SET_ADMIN_USER, res);
        });
    },
    [DELETE_ADMIN_USER]({commit}, adminId) {
        return AdminUserService.deleteAdminUser(adminId).then(() => {
            commit(REMOVE_ADMIN_USER, adminId);
        });
    },
    [GET_ADMIN_ROLES]({commit}, queryObj) {
        return AdminUserService.getAdminRoles(queryObj).then(roles => {
            commit(SET_ADMIN_ROLES, roles);
            this.roles = roles;
        });
    },
    [GET_ADMIN_PERMISSIONS]({commit}) {
        return AdminUserService.getAdminPermissions().then(permissions => {
            commit(SET_ADMIN_PERMISSIONS, permissions);
        });
    },
    [UPDATE_ADMIN_ROLE]({commit}, role) {
        return AdminUserService.updateAdminRole(role).then(role => {
            commit(SET_ADMIN_ROLE, role);
        });
    },
    [STORE_ADMIN_ROLE]({commit}, role) {
        return AdminUserService.addAdminRole(role).then(res => {
            commit(ADD_ADMIN_ROLE, res);
        });
    },
    [DELETE_ADMIN_ROLE]({commit}, roleId) {
        return AdminUserService.deleteAdminRole(roleId).then(() => {
            commit(REMOVE_ADMIN_ROLE, roleId);
        });
    },
    [GET_ADMIN_SUPPLIERS]({commit}, queryObj) {
        return AdminSupplierService.getSuppliers(queryObj).then(res => {
            commit(SET_ADMIN_SUPPLIERS, res);
        });
    },
    [VALIDATE_ADMIN_SUPPLIER]({commit}, supplierId) {
        return AdminSupplierService.validateSupplier(supplierId).then(res => {
            commit(SET_ADMIN_SUPPLIER, res);
        });
    },
    [INVALIDATE_ADMIN_SUPPLIER]({commit}, supplierId) {
        return AdminSupplierService.invalidateSupplier(supplierId).then(res => {
            commit(SET_ADMIN_SUPPLIER, res);
        });
    }
};

const getters = {
    ...childdevGetters,
    ...surveyGetters,
    AdminOrganizations: state => state.organizations,
    AdminKindergartens: state => state.kindergartens,
    adminCountries: state => state.countries,
    adminSuppliers: state => state.suppliers,
    users: state => state.users,
    adminUsers: state => state.adminUsers,
    adminPermissions: state => state.adminPermissions,
    adminRoles: state => state.adminRoles,
    adminUserSearchValue: state => state.userSearchValue,
    adminEmployeeSearchValue: state => state.employeeSearchValue,
    adminGuardianSearchValue: state => state.guardianSearchValue,
    adminCourseSearchValue: state => state.courseSearchValue,
    adminApplicationSearchValue: state => state.applicationSearchValue,
    adminTargetGroupSearchValue: state => state.targetGroupSearchValue,
    adminOfficialSearchValue: state => state.officialSearchValue,
    adminRoleSearchValue: state => state.roleSearchValue,
    selectedAdminKindergartenChild: state => state.selectedAdminKindergartenChild,
    selectedAdminKindergartenCourse: state => state.selectedAdminKindergartenCourse
};

export default {
    state,
    mutations,
    actions,
    getters
};
