import http from '@http';

class CommonService {
    constructor() {
        if (CommonService.instance) {
            return CommonService.instance;
        }

        CommonService.instance = this;
    }

    getCountries() {
        return http.get('/api/auth/countries').then(res => res.data);
    }

    getLanguages() {
        return http.get('/api/common/languages').then(res => res.data);
    }

    saveProviderSuggestion(provider) {
        return http.post('/api/common/shop/provider', provider).then(res => res.data);
    }
}
export default new CommonService();
