import Vue from 'vue';

export function convertTimesToUtc(date, time, tz) {
  const utcDate = {
    tz
  };
  const dateString = (date ? date : Vue.moment().format('YYYY-MM-DD')) + 'T' + (time ? time : '00:00:00');
  let moment = Vue.moment.tz(dateString, tz).utc();
  utcDate.date = moment.format('YYYY-MM-DD');
  utcDate.time = moment.format('HH:mm:ss');
  return utcDate;
}

/**
 * This beast is mostly meant for recurring objects but retains the original single date logic also
 * @param date
 * @param time
 * @param tz
 * @param originalDate
 * @returns {{tz: *}}
 */
export function convertTimesFromUtc(date, time, tz, originalDate) {
    const utcDate = {
        tz
    };
    //if dealing with a recurring date and there is danger of daylight saving
    if(originalDate) {
        const originalString = originalDate + 'T' + (time ? time : '00:00:00');
        let original = Vue.moment.utc(originalString).tz(tz);
        const dateString = (date ? date : Vue.moment().format('YYYY-MM-DD')) + 'T' + (time ? time : '00:00:00');
        let moment = Vue.moment.utc(dateString).tz(tz);
        //keep a editable copy of original date conversion
        let differ =  Vue.moment.utc(dateString).tz(tz);

        //set date same as repeat start but keep time from converted event date
        differ.set({'date': original.date(), 'month': original.month(), 'year': original.year()});

        //find hour diff within the same date
        let diff = original.diff(differ, 'hours');
        if(diff !== 0) {
            moment.add(diff, 'hours');
        }
        utcDate.date = moment.format('YYYY-MM-DD');
        utcDate.time = moment.format('HH:mm:ss');
    } else {
        const dateString = (date ? date : Vue.moment().format('YYYY-MM-DD')) + 'T' + (time ? time : '00:00:00');
        let moment = Vue.moment.utc(dateString).tz(tz);
        utcDate.date = moment.format('YYYY-MM-DD');
        utcDate.time = moment.format('HH:mm:ss');
    }

    return utcDate;
}
//constant values for seconds
export const MINUTE = 60;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

/**
 * Validate if date is older than defined time in seconds
 */
export function hasPassed(date, seconds) {
    date = Vue.moment(date);
    let now = Vue.moment();

    return now.diff(date, 'seconds') > seconds;
}

export function adjustLocaleNumbers(locale) {
    const symbolMap = {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '0': '0'
    };
    const numberMap = {
        '١': '1',
        '٢': '2',
        '٣': '3',
        '٤': '4',
        '٥': '5',
        '٦': '6',
        '٧': '7',
        '٨': '8',
        '٩': '9',
        '٠': '0'
    };
    Vue.moment.updateLocale(locale, {
        preparse: function (string) {
            return string.replace(/\u200f/g, '').replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (match) {
                return numberMap[match];
            }).replace(/،/g, ',');
        },
        postformat: function(string) {
            return string.replace(/\d/g, function(match) {
                return symbolMap[match];
            }).replace(/,/g, '،');
        },
    });
}
