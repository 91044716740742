import store from '@/store';

const Yearplans = () => import(/* webpackChunkName: "plan-module" */ '@views/plan/year/Yearplans');
const Plan = () => import(/* webpackChunkName: "plan-module" */ '@views/plan/year/components/Plan.vue');
const Overview = () => import(/* webpackChunkName: "plan-module" */ '@views/plan/year/components/Overview');
const Settings = () => import(/* webpackChunkName: "plan-module" */ '@views/plan/components/Settings');
const Templates = () => import(/* webpackChunkName: "plan-module" */ '@views/plan/components/Templates');

export default [
    {
        path: '/plans/year',
        components: { base: Yearplans },
        children: [
            {
                path: 'templates',
                name: 'plans.yplan.templates',
                components: { yearplan: Templates },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessTemplates) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: '',
                name: 'plans.yplan.overview',
                components: { yearplan: Overview },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessOverView) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: ':plan_id',
                name: 'plans.yplan',
                components: { yearplan: Plan },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessYearPlan) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'settings',
                name: 'plans.yplan.settings',
                components: { yearplan: Settings },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessPlansSettings) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
        ]
    }
];
