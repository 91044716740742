<template>
    <video-player
        class="vjs-custom-skin"
        :options="{
            muted: false,
            language: language,
            playbackRates: [1.0, 1.5, 2.0],
            sources: [{
                type: 'video/mp4',
                src: src
            }]
        }"
        :playsinline="true"
        @ready="onFileLoad"/>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    props: {
        src: String
    },
    computed: {
        ...mapGetters(['language']),
    },
    methods: {
        onFileLoad() {
            this.$emit('ready');
        }
    },
};
</script>
<style scoped>
@media screen and (min-width: 576px){
    .e3-img-full {
        max-height: 70vh;
        max-width: 70vw;
    }
}
.view-file-input {
    width: 430px;
}
.player {
    position: absolute !important;
    width: 100%;
    height: 100%;
}
.vjs-custom-skin {
    height: 100% !important;
}
.vjs-custom-skin /deep/ .video-js {
    height: 100%;
}
.vjs-custom-skin /deep/ .video-js .vjs-big-play-button {
    top: calc(50% - 0.75em);
    left: calc(50% - 1.5em);
}
</style>
