<template>
    <b-modal id="move-modal" hide-footer size="md" lazy static no-close-on-backdrop>
        <template v-slot:modal-header>
            <ModalHeader modalName="move-modal">
                <span v-if="contentType === 'folders'">{{ $t('Moving folders', {count: folders.length}) }}</span>
                <span v-else>{{ $t('Moving files', {count: files.length}) }}</span>
            </ModalHeader>
        </template>
        <template v-if="!askConfirmation">
            <div v-if="breadcrumbs">
                <h5 class="d-flex flex-wrap">
                    <div
                        :class="{'text-primary e3-pointer' : breadcrumbs.length}"
                        @click="breadcrumbs.length ? loadFolder({id: 'index', name: 'index'}) : null">
                        {{ type === 'document' ? $t("Documents") : $t("Gallery") }}
                    </div>
                    <span v-for="crumb in breadcrumbs" :key="crumb.id" class="d-flex">
                        <SvgIcon icon="mdi-chevron-right" class="text-black" size="24"/>
                        <div
                            class="text-primary e3-pointer"
                            :title="crumb.name"
                            @click="loadFolder(crumb)">
                            {{ truncate(crumb.name, 20) }}
                        </div>
                    </span>
                </h5>
            </div>
            <div class="mt-4">
                <template v-if="!loading">
                    <template v-if="availableSubFolders.length || showCurrentFolder">
                        <div
                            v-if="showCurrentFolder"
                            class="d-flex align-items-center border-top border-bottom py-2"
                            @click="selectMoveFolder(currentFolder)">
                            <FolderIcon :isGallery="type === 'gallery'" :isReadonly="false" class="small mr-2"/>
                            <span class="font-weight-bold mr-1">{{ $t('Current folder') }}:</span>
                            <span class="text-break mr-2">{{ currentFolder?.name }}</span>
                            <ERadio
                                v-if="currentFolder?.access !== 'view'"
                                :id="`folder-${currentFolder.id}`"
                                class="ml-auto"
                                type="radio"
                                :value="selectedFolder?.id !== currentFolder.id"/>
                        </div>
                        <div
                            v-for="(ffolder, index) in availableSubFolders"
                            :key="ffolder.id">
                            <div
                                class="d-flex align-items-center border-bottom hover-select py-2"
                                :class="{
                                    'selected' : ffolder.id === selectedFolder?.id,
                                    'border-top' : index === 0 && !showCurrentFolder,
                                }"
                                @click="canSelectFolder(ffolder) ? selectMoveFolder(ffolder) : null">
                                <FolderIcon :isGallery="type === 'gallery'" :isReadonly="false" class="small mr-2"/>
                                <div
                                    v-if="ffolder.subfolders_count && folderAvailable(ffolder.id)"
                                    class="d-flex align-items-center mr-2"
                                    @click.stop="ffolder.subfolders_count ? loadFolder(ffolder) : null">
                                    <span class="text-break text-primary e3-pointer mr-1">{{ ffolder.name }}</span>
                                    <SvgIcon  icon="mdi-chevron-right" class="text-primary" size="18"/>
                                </div>
                                <span v-else class="text-break mr-2">{{ ffolder.name }}</span>
                                <ERadio
                                    v-if="canSelectFolder(ffolder)"
                                    :id="`folder-${ffolder.id}`"
                                    class="ml-auto"
                                    type="radio"
                                    :value="selectedFolder?.id !== ffolder.id"/>
                                <ETooltip v-else :id="`folder-${ffolder.id}-tp`" class="ml-auto mr-2">
                                    <span v-if="ffolder.id === folder.id">{{ $t('Folder you want to move from') }}</span>
                                    <span v-else-if="!folderAvailable(ffolder.id)">{{ $t('Trying to move given folder') }}</span>
                                    <span v-else-if="!canSelectFolder(ffolder)">{{ $t('You lack sufficient permissions to manage this folder') }}</span>

                                </ETooltip>
                            </div>
                        </div>
                        <div v-if="page < folderMaxPage" class="d-flex mt-3">
                            <EBtnLoader :isSubmitLoading="loadingMore" color="primary" class="mx-auto btn-sm" @clicked="loadMore">
                                {{ $t('Load more') }}
                            </EBtnLoader>
                        </div>
                    </template>
                    <EmptyListMessage v-else>
                        {{ $t('No available folders to choose from') }}
                    </EmptyListMessage>
                </template>
                <ELoader v-else/>
            </div>
        </template>
        <div v-else>
            <div class="row align-items-center mx-0">
                <div class="d-flex flex-column col px-1">
                    <div class="mx-auto mt-3">
                        <SvgIcon v-if="this.contentType === 'files'" icon="mdi-file-multiple" class="text-success" size="56"/>
                        <SvgIcon v-if="this.contentType === 'folders'" icon="mdi-folder-multiple" class="text-success" size="56"/>
                    </div>
                    <span class="text-center text-break mt-2">{{ folder.name }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-center col-2 px-1">
                    <SvgIcon icon="mdi-chevron-right" size="36"/>
                </div>
                <div class="d-flex flex-column col px-1">
                    <FolderIcon :isGallery="true" :isReadonly="false" class="mx-auto"/>
                    <span class="text-break text-center mt-2">{{ selectedFolder.name }}</span>
                </div>
            </div>
        </div>
        <div class="d-flex mt-4">
            <div v-if="selectedFolder && !askConfirmation" class="mr-2">
                <span class="font-weight-bold mr-1">{{ $t('Selected folder') }}:</span>
                <span class="text-break">{{ selectedFolder.name }}</span>
            </div>
            <button class="btn btn-secondary ml-auto mr-2" @click="!askConfirmation ? $bvModal.hide('move-modal') : askConfirmation = false">
                {{ !askConfirmation ? $t('Cancel') : $t('Back') }}
            </button>
            <EBtnLoader :disabled="!selectedFolder" :isSubmitLoading="submitLoading" @clicked="!askConfirmation ? askConfirmation = true : move()">
                {{ $t('Move') }}
            </EBtnLoader>
        </div>
    </b-modal>
</template>

<script>
import { eventBus } from '@/main';
import { mapGetters } from 'vuex';
import {truncate} from '@/filters';
import FolderService from '@services/folder.service';
import GalleryService from '@services/gallery.service';
import DocumentService from '@services/document.service';
import FolderIcon from '@views/folder/components/FolderIcon.vue';

export default {
    name: 'MoveModal',
    props: {
        type: String,
    },
    data() {
        return {
            selectedFolder: null,
            submitLoading: false,
            loading: false,
            loadingMore: false,
            contentType: null,
            files: [],
            folders: [],
            availableSubFolders: [],
            breadcrumbs: [],
            askConfirmation: false,
            currentFolder: null,
            done: null,
            page: 1,
            folderMaxPage: 1,
        };
    },
    components: {
        FolderIcon
    },
    computed: {
        ...mapGetters(['activeKindergartenId', 'subFolders', 'folder']),
        showCurrentFolder() {
            return this.currentFolder && this.currentFolder.id !== this.folder.id && this.currentFolder.id !== 'index';
        },
    },
    methods: {
        truncate, // TODO: Vue3 remove
        canSelectFolder(folder) {
            return folder.id !== this.folder.id && this.folderAvailable(folder.id) && folder.access !== 'view';
        },
        folderAvailable(id) {
            if (this.folders && this.folders.some(s => s === id)) {
                return false;
            }

            return true;
        },
        openModal() {
            this.$bvModal.show('move-modal');
        },
        closeModal() {
            this.done && this.done();
            this.$bvModal.hide('move-modal');
        },
        setCurrentFolder(folder) {
            this.currentFolder = folder;
            this.selectMoveFolder(this.currentFolder);
        },
        selectMoveFolder(folder) {
            if (folder.access === 'view' || folder.id === 'index' || folder.id === this.folder.id) {
                this.selectedFolder = null;
                return;
            }

            this.selectedFolder = folder;
        },
        move() {
            let folderId = this.selectedFolder.id;

            this.submitLoading = true;
            if (this.contentType === 'folders' && this.folders.length !== 0) {
                let form = {
                    folder_ids: this.folders
                };
                this.$store.dispatch('MOVE_FOLDER_SUBFOLDERS', {folder_id: folderId, form: form}).then(() => {
                    this.$toastr.s(this.$t('Folders moved'));
                    this.closeModal();
                }).catch(() => {
                    this.closeModal();
                }).finally(() =>  {
                    this.submitLoading = false;
                });
            } else if (this.contentType === 'files' && this.files.length !== 0) {
                let form = {
                    file_ids: this.files
                };
                this.$store.dispatch('MOVE_FOLDER_FILES', {folder_id: folderId, form: form}).then(() => {
                    this.$toastr.s(this.$t('Files moved'));
                    this.closeModal();
                }).catch(() => {
                    this.closeModal();
                }).finally(() =>  {
                    this.submitLoading = false;
                });
            }
        },
        loadMore() {
            this.loadingMore = true;
            this.getSubFolders({...this.currentFolder, page: this.page + 1}).then(() => {
                ++this.page;
            }).finally(() => {
                this.loadingMore = false;
            });
        },
        loadFolder(folder) {
            this.page = 1;
            this.loading = true;
            this.availableSubFolders = [];
            this.getSubFolders(folder).finally(() => {
                this.loading = false;
            });
            this.adjustBreadcrumbs(folder);
        },
        getSubFolders(folder) {
            let queryObj = {
                subcount: true,
                perPage: 20,
                term: '',
                page: folder.page || this.page,
            };

            if (folder.id === 'index') {
                if (this.type === 'document') {
                    return DocumentService.getIndexData(this.activeKindergartenId, queryObj)
                        .then(res => this.setResponseData(folder, res));
                }
                else if (this.type === 'gallery') {
                    return GalleryService.getIndexData(this.activeKindergartenId, queryObj)
                        .then(res => this.setResponseData(folder, res));
                }
            } else {
                queryObj.folder_id = folder.id;

                return FolderService.getSubFolders(this.activeKindergartenId, folder.id, queryObj)
                    .then(res => this.setResponseData(folder, res));
            }
        },
        setResponseData(folder, res) {
            this.setCurrentFolder({id: folder.id, name: folder.name});
            this.availableSubFolders = this.availableSubFolders.concat(res.folders?.data || res.data);
            this.folderMaxPage = res.folders?.last_page || res.last_page;
        },
        adjustBreadcrumbs(folder) {
            if (folder.id === 'index') {
                this.breadcrumbs = [];
                return;
            }

            if (this.breadcrumbs.some(b => b.id === folder.id)) {
                let index = this.breadcrumbs.findIndex(i => i.id === folder.id);
                this.breadcrumbs.length = index+1;
            } else {
                this.breadcrumbs.push(
                    { id: folder.id, name: folder.name}
                );
            }
        },
    },
    created() {
        eventBus.$on('show-move-modal', (data) => {
            this.contentType = data.contentType;
            this.files = data.files;
            this.folders = data.folders;
            this.done = data.done;
            this.openModal();
            this.askConfirmation = false;
            this.loadFolder({id: this.folder.id, name: this.folder.name});
            this.setCurrentFolder({id: this.folder.id, name: this.folder.name});
            let crumbs = [];
            this.folder.breadcrumbs.map(b => {
                const crumb = {
                    id: b.id,
                    name: b.name,
                };
                crumbs.push(crumb);
            });
            this.breadcrumbs = crumbs;
        });
    },
    destroyed() {
        eventBus.$off('show-move-modal');
    }
};
</script>

<style lang="scss" scoped>
@import "@common/styles/variables/_variables.scss";
    .hover-select:hover {
        background-color: #{$primary + '30'};
    }
    .selected {
        background-color: #{$primary + '30'};
    }
</style>
