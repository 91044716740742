<template>
    <div class="e3-head-icon-round">
        <div class="d-flex align-items-center px-3 py-2">
            <span class="font-weight-bold">{{ $t('Messages') |toUpper }}</span>
            <!--            <span class="ml-auto text-success">{{ unreadMessages }}</span>-->
        </div>
        <div class="d-flex flex-column border-top">
            <MessagesContent :tab="tab" :data="recentMessages"/>
        </div>
        <div class="bg-light px-3 py-2 border-top rounded-bottom">
            <router-link :to="{ name: 'messages.received' }" @click.native="routerLinkClick">
                {{ $t('Show all messages') }}
            </router-link>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MessagesContent from './components/MessagesContent.vue';

export default {
    props: {
        id: [String, Number]
    },
    data() {
        return {
            tab: 'messages'
        };
    },
    computed: {
        ...mapGetters([
            'recentMessages',
            'unreadMessages',
            'messageTypes'
        ])
    },
    methods: {
        getMessages() {
            if (!this.recentMessages.length) {
                this.$store.dispatch('GET_RECENT_MESSAGES');
            }
        },
        routerLinkClick() {
            this.$emit('close', this.id);
        }
    },
    components: {
        MessagesContent
    },
    created() {
        this.getMessages();
    }
};
</script>
