<template>
    <div class="d-flex mb-4">
        <h2 class="mb-0 d-flex flex-wrap align-items-center text-break">
            {{ title }}
            <slot/>
        </h2>
    </div>
</template>

<script>

export default {
    name: 'PageTitle',
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    computed: {
        headTitleText() {
            return this.title !== '' ? this.title + ' - ' : '';
        }
    },
    metaInfo() {
        return {
            title: `${this.headTitleText}ELIIS - ${this.$t('eKindergarten')}`,
        };
    }
};
</script>
