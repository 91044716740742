import http from '@http';
import { download } from '@services/helper.service';

class DocumentService {
    constructor() {
        if (DocumentService.instance) {
            return DocumentService.instance;
        }
        DocumentService.instance = this;
    }

    getIndexData(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/documents`, {params: queryObj}).then(res => res.data);
    }

    getDocument(kindergartenId, file) {
        return http.download(`/api/kindergartens/${kindergartenId}/files/documents/${file.filename}`).then(res => res.data);
    }
    downloadDocument(kindergartenId, file) {
        return this.getDocument(kindergartenId, file).then(document => download(document, file));
    }

    deleteDocument(kindergartenId, filename) {
        return http.delete(`/api/kindergartens/${kindergartenId}/files/documents/${filename}`)
            .then(res => res.data);
    }

    updateActivity(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/documents/activity`).then(res => res.data);
    }

    updateDocumentFile(kindergartenId, filename, form) {
        return http.put(`/api/kindergartens/${kindergartenId}/files/documents/${filename}`, form).then(res => res.data);
    }

    getDocumentAcl(kindergartenId, documentId) {
        return http.get(`/api/kindergartens/${kindergartenId}/documents/${documentId}/acl`).then(res => res.data);
    }

    getSharedDocuments(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/documents/shared`, {params: queryObj}).then(res => res.data);
    }
}

export default new DocumentService();
