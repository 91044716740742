export const GET_CHILD_DEV_OPTIONS = 'GET_CHILD_DEV_OPTIONS';
export const GET_CHILD_DEV_AGE_GROUPS = 'GET_CHILD_DEV_AGE_GROUPS';
export const SET_CHILD_DEV_OPTIONS = 'SET_CHILD_DEV_OPTIONS';
export const SET_CHILD_DEV_AGE_GROUPS = 'SET_CHILD_DEV_AGE_GROUPS';
export const SET_CHILD_DEV_AGE_GROUP = 'SET_CHILD_DEV_AGE_GROUP';
export const STORE_CHILD_DEV_AGE_GROUP = 'STORE_CHILD_DEV_AGE_GROUP';
export const UPDATE_CHILD_DEV_AGE_GROUP = 'UPDATE_CHILD_DEV_AGE_GROUP';
export const DELETE_CHILD_DEV_AGE_GROUP = 'DELETE_CHILD_DEV_AGE_GROUP';
export const STORE_CHILD_DEV_OPTION = 'STORE_CHILD_DEV_OPTION';
export const ADD_CHILD_DEV_OPTION = 'ADD_CHILD_DEV_OPTION';
export const UPDATE_CHILD_DEV_OPTIONS = 'UPDATE_CHILD_DEV_OPTIONS';
export const DELETE_CHILD_DEV_OPTION = 'DELETE_CHILD_DEV_OPTION';
export const REMOVE_CHILD_DEV_OPTION = 'REMOVE_CHILD_DEV_OPTION';
export const GET_CHILD_DEV_CATEGORIES = 'GET_CHILD_DEV_CATEGORIES';
export const STORE_KINDERGARTEN_CHILD_DEV_CATEGORY = 'STORE_KINDERGARTEN_CHILD_DEV_CATEGORY';
export const UPDATE_KINDERGARTEN_CHILD_DEV_CATEGORY = 'UPDATE_KINDERGARTEN_CHILD_DEV_CATEGORY';
export const DELETE_KINDERGARTEN_CHILD_DEV_CATEGORY = 'DELETE_KINDERGARTEN_CHILD_DEV_CATEGORY';
export const ADD_KINDERGARTEN_CHILD_DEV_CATEGORY = 'ADD_KINDERGARTEN_CHILD_DEV_CATEGORY';
export const SET_KINDERGARTEN_CHILD_DEV_CATEGORY = 'SET_KINDERGARTEN_CHILD_DEV_CATEGORY';
export const SET_CHILD_DEV_CATEGORIES = 'SET_CHILD_DEV_CATEGORIES';
export const SET_KINDERGARTEN_CHILD_DEV_CATEGORIES = 'SET_KINDERGARTEN_CHILD_DEV_CATEGORIES';
export const STORE_KINDERGARTEN_CHILD_DEV_GOAL = 'STORE_KINDERGARTEN_CHILD_DEV_GOAL';
export const UPDATE_KINDERGARTEN_CHILD_DEV_GOAL = 'UPDATE_KINDERGARTEN_CHILD_DEV_GOAL';
export const DELETE_KINDERGARTEN_CHILD_DEV_GOAL = 'DELETE_KINDERGARTEN_CHILD_DEV_GOAL';
export const CHILD_DEV_GOAL = 'CHILD_DEV_GOAL';
export const GET_EMPLOYEE_ROLES = 'GET_EMPLOYEE_ROLES';
export const SET_EMPLOYEE_ROLES = 'SET_EMPLOYEE_ROLES';
export const GET_CHILD_DEV_COURSE_CHILDREN = 'GET_CHILD_DEV_COURSE_CHILDREN';
export const SET_CHILD_DEV_COURSE_CHILDREN = 'SET_CHILD_DEV_COURSE_CHILDREN';
export const EVALUATE_CHILD_DEV = 'EVALUATE_CHILD_DEV';
export const SET_CHILD_DEV_EVALUATION = 'SET_CHILD_DEV_EVALUATION';
export const COPY_CHILD_DEV_GOALS = 'COPY_CHILD_DEV_GOALS';
export const STORE_CHILD_DEV_COMMENT = 'STORE_CHILD_DEV_COMMENT';
export const SET_CHILD_DEV_COMMENT = 'SET_CHILD_DEV_COMMENT';
export const UPDATE_CHILD_DEV_COMMENT = 'UPDATE_CHILD_DEV_COMMENT';
export const DELETE_CHILD_DEV_COMMENT = 'DELETE_CHILD_DEV_COMMENT';
export const GET_CDM_SETTINGS = 'GET_CDM_SETTINGS';
export const SET_CDM_SETTINGS = 'SET_CDM_SETTINGS';
export const SET_CDM_VERBOSE_SETTING = 'SET_CDM_VERBOSE_SETTING';
export const SET_CDM_VERBOSE_SETTINGS = 'SET_CDM_VERBOSE_SETTINGS';
export const GET_KINDERGARTEN_CHILD_DEV_CATEGORIES = 'GET_KINDERGARTEN_CHILD_DEV_CATEGORIES';
export const ADD_KINDERGARTEN_CHILD_DEV_GOAL = 'ADD_KINDERGARTEN_CHILD_DEV_GOAL';
export const SET_KINDERGARTEN_CHILD_DEV_GOAL = 'SET_KINDERGARTEN_CHILD_DEV_GOAL';

export const GET_CDM_CHILD_DATA = 'GET_CDM_CHILD_DATA';
export const GET_CDM_GOALS_EVALUATION_DATA = 'GET_CDM_GOALS_EVALUATION_DATA';
export const GET_KINDERGARTEN_CDM_CONFIGURATION = 'GET_KINDERGARTEN_CDM_CONFIGURATION';
export const GET_KINDERGARTEN_CDM_SETTINGS = 'GET_KINDERGARTEN_CDM_SETTINGS';
export const RESET_CDM_STORE = 'RESET_CDM_STORE';
