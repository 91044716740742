import http from '@http';

class OrganizationService {
    constructor() {
        if (OrganizationService.instance) {
            return OrganizationService.instance;
        }

        OrganizationService.instance = this;
    }

    getKindergartens(organizationId, queryObj) {
        return http.get(`/api/organizations/${organizationId}/kindergartens`, {params: queryObj}).then(res => res.data);
    }

    getKindergartensList(organizationId) {
        return http.get(`/api/organizations/${organizationId}/kindergartens-list`).then(res => res.data);
    }

    getKindergartenCourses(organizationId, kindergartenId, options) {
        return http.get(`/api/organizations/${organizationId}/kindergartens/${kindergartenId}/courses`, {params: options}).then(res => res.data);
    }
}
export default new OrganizationService();
