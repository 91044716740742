<template>
    <div v-if="validation && validation.$error" class="invalid-feedback d-block">
        <div v-if="validation.$params.required && !validation.required">
            {{ $t("Required") }}
        </div>
        <div v-if="validation.$params.sameAs && !validation.sameAs">
            {{ $t("Required") }}
        </div>
        <div v-if="validation.$params.minLength && !validation.minLength">
            {{ $t("Min length") }} {{ validation.$params.minLength.min }}
        </div>
        <div v-if="validation.$params.maxLength && !validation.maxLength">
            {{ $t("Max character length reached") }}
        </div>
        <div v-if="validation.$params.minValue && !validation.minValue">
            {{ $t("Min value") }} {{ validation.$params.minValue.min }}
        </div>
        <div v-if="validation.$params.maxValue && !validation.maxValue">
            {{ $t("Max value") }} {{ validation.$params.maxValue.max }}
        </div>
        <div v-if="validation.$params.email && !validation.email">
            {{ $t("Not valid email") }}
        </div>
        <div v-if="validation.$params.alphaNum && !validation.alphaNum">
            {{ $t("Only letters and numbers") }}
        </div>
        <div v-if="validation.$params.alphaNumCustom && !validation.alphaNumCustom">
            {{ $t("Only letters, numbers, whitespaces and the symbols") }} ( ) _ - .
        </div>
        <div v-if="validation.$params.alphaNumFileCustom && !validation.alphaNumFileCustom">
            {{ $t("A file name can't contain any of the following characters:") }}
            {{ alphaNumFileCharacters }}
        </div>
        <div v-if="validation.$params.urlNegatedChars && !validation.urlNegatedChars">
            {{ $t("Name can't contain any of the following characters:") }}
            {{ urlNegatedChars }}
        </div>
        <div v-if="validation.$params.sameAsPassword && !validation.sameAsPassword">
            {{ $t("Passwords don't match") }}
        </div>
        <div v-if="validation.$params.passwordRegex && !validation.passwordRegex">
            {{ $t("The password must be 8 letters long and contain at least: 1 uppercase letter, 1 lowercase letter and 1 number.")}}
        </div>
        <div v-if="validation.$params.numeric && !validation.numeric">
            {{ $t('Only numbers') }}
        </div>
        <div v-if="validation.$params.integer && !validation.integer">
            {{ $t('Only integers') }}
        </div>
        <div v-if="validation.$params.timeString && !validation.timeString">
            {{ $t('Not a valid time') }}
        </div>
        <div v-if="validation.$params.dateString && !validation.dateString">
            {{ $t('Not a valid date') }}
        </div>
        <div v-if="'endTimeGreater' in validation.$params && !validation.endTimeGreater">
            {{ $t('Start time must be earlier than the end time') }}
        </div>
        <div v-if="'endTimeComparedToNow' in validation.$params && !validation.endTimeComparedToNow">
            {{ $t('End time must be later than current time') }}
        </div>
        <div v-if="'repeatEndGreater' in validation.$params && !validation.repeatEndGreater">
            {{ $t('Repeat end must be later than the start date') }}
        </div>
        <div v-if="'courseMinDate' in validation.$params && !validation.courseMinDate">
            {{$t('Date out of class range')}}
        </div>
        <div v-if="'courseMaxDate' in validation.$params && !validation.courseMaxDate">
            {{$t('Date out of class range')}}
        </div>
        <div v-if="validation.$params.allNumeric && !validation.allNumeric">
            {{$t('Only numbers and decimal numbers with 2 decimal places')}}
        </div>
        <div v-if="validation.$params.url && !validation.url">
            {{ $t('Only URLs') }}
        </div>
        <div v-if="validation.$params.comment_day_valid && !validation.comment_day_valid">
            {{ $t('Chosen day can not be before notification day') }}
        </div>
        <div v-if="validation.$params.comment_time_valid && !validation.comment_time_valid">
            {{ $t('Time must be later than notification time') }}
        </div>
        <div v-if="'startDateEarlier' in validation.$params && !validation.startDateEarlier">
            {{ $t('Start date must be earlier than the end date') }}
        </div>
        <div v-if="'endDateLater' in validation.$params && !validation.endDateLater">
            {{ $t('End date must be later than the start date') }}
        </div>
        <div v-if="'endDateMinimum' in validation.$params && !validation.endDateMinimum">
            {{ $t('End date cannot be in the past')}}
        </div>
        <template v-if="validation.code || validation.number">
            <div v-if="validation?.number?.$invalid">
                {{ $t("Required") }}
            </div>
            <div v-if="validation?.code?.$invalid">
                {{ $t('Country code needs to be selected') }}
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ValitationMessages',
    props: {
        validation: Object
    },
    data() {
        return {
            alphaNumFileCharacters: '\\ / : * ? " < > |',
            urlNegatedChars: '/ @ : ; , = + ! * |',
        };
    },
};
</script>
