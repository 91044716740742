<template>
    <div class="custom-control" :class="{ disabled: disabled, rounded: rounded, inactive: inactive, success: success, lg: lg }">
        <input :id="id" v-model="valModel" :disabled="disabled" class="custom-control-input" type="checkbox" :value="val"/>
        <label :for="id" class="custom-control-label" :class="{ 'has-error': validation && validation.$error }">
            <span v-if="label" class="d-flex ml-2">{{ label }}</span>
            <slot name="label"/>
            <template v-if="optional">
                - <small>{{ $t('Optional') }}</small>
            </template>
        </label>
        <a v-if="labelLink && labelLink.href" :id="labelLink.id" :href="labelLink.href" target="_blank" class="font-size-sm ml-1">
            {{ labelLink.content }}
        </a>
        <ValitationMessages :validation="validation"/>
    </div>
</template>

<script>
export default {
    name: 'ECheckbox',
    props: {
        value: [Object, Boolean, Array, Number, String],
        val: [Boolean, Number, String, Object],
        id: {
            type: String,
            required: true
        },
        label: String,
        disabled: Boolean,
        labelLink: Object,
        rounded: Boolean,
        optional: Boolean,
        inactive: Boolean,
        validation: Object,
        success: Boolean,
        lg: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        valModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@common/styles/variables/_variables.scss";
@import "@common/styles/mixins/_filters.scss";
 
    .custom-control-input:checked ~ .custom-control-label::before {
        background: $orange;
    }

    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
        background-color: #fff;
    }

    .custom-control {
        display: inline-block;
        padding-left: 20px;
    }

    .custom-control-label {
        cursor: pointer;
        font-size: 0.9rem;
        &.has-error {
            &::before {
                background: lighten($danger, 50%) !important;
                border-color: $danger !important;
            }
        }

        &:before {
            border-radius: 4px;
            left: -20px;
        }

        &:after {
            background: url('~@/assets/svg/css-svg-icons.svg#mdi-check-stroke') no-repeat;
            @include text-white-filter;
            background-size: 24px auto;
            width: 20px;
            height: 20px;
            content: "";
            top: 4px;
            left: -18px;
        }
    }

    .disabled {
        .custom-control-input:not(:checked) ~ .custom-control-label::after {
            background: none;
        }
        .custom-control-label {
            cursor: default;
            opacity: 0.25;
        }
    }

    .lg {
        height: 28px;
        padding-left: 28px !important;

        .custom-control-input {
            height: 28px;
            width: 28px;
        }
        .custom-control-label {
            padding-top: 2px;

            &:before {
                left: -28px;
                top: 0;
                height: 28px;
                width: 28px;
            }
            &:after {
                top: 6px;
                left: -22px;
            }
        }
    }

    .rounded {
        .custom-control-label {
            &:before,
            &:after {
                border-radius: 50%;
            }
        }
    }

    .success{
        .custom-control-label::before{
            border-color: $green;
        }
        .custom-control-input:checked ~ .custom-control-label::before {
            background: $green;
            border-color:transparent !important;
            outline:none !important;
        }
        .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
            border-color: $green;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            border-color: $green;
        }

        .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            border-color: $green;
        }
    }
    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: $orange;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: $orange;
    }

    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        border-color: $orange;
    }

</style>
