<template>
    <b-modal
        id="maintenance-error-modal"
        title=""
        size="lg"
        :hide-footer="true"
        :hide-header="true"
        centered
        no-close-on-backdrop>
        <ErrorContent
            title="Maintenance"
            message="ELIIS will be back soon. We are currently upgrading ELIIS to make it even better. We apologize for the inconvenience and appreciate your patience."
            back-link-text=""
            backLinkUrl="/"
            class="shadow-none error-404-image-lg"/>
        <div class="border-top d-flex justify-content-end">
            <button class="btn btn-secondary mt-3" @click="hideModal">{{$t('Close')}}</button>
        </div>
    </b-modal>
</template>

<script>
import ErrorContent from '@components/ErrorContent.vue';

export default {
    components: {
        ErrorContent
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('maintenance-error-modal');
        },
    }
};
</script>
