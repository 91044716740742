<template>
    <div class="price-tag">
        <div class="price" :class="size">
            <span>{{priceStrings[0]}}</span>
            <span class="price-cents">.{{priceStrings[1]}}&nbsp;<span class="currency">&euro;</span></span>
        </div>
        <small v-if="label" class="price-label text-body font-weight-bold">{{label}}</small>
    </div>
</template>

<script>
export default {
    props: {
        price: {
            type: [Number, Object],
            default: 0
        },
        label: {
            type: String
        },
        size: {
            type: String,
            default: 'display-2'
        }
    },
    computed: {
        priceStrings() {
            return String(this.price.toFixed(2)).split('.');
        }
    },
};
</script>

<style scoped>
    .price {
        font-weight: bold;
        line-height: .8;
    }
    .price-cents {
        font-size: .6em;
    }
    .currency {
        font-size: .7em;
    }
    .price-label {
        line-height: .8;
    }
</style>
