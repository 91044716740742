import {
    GET_EMPLOYEE_MEALS,
    SET_EMPLOYEE_MEALS,
    GET_EMPLOYEE_MEALS_SETTINGS,
    SET_EMPLOYEE_MEALS_SETTINGS,
    UPDATE_EMPLOYEE_MEALS_SETTINGS,
    SET_EMPLOYEE_MEALS_LOCK_DATE,
    SAVE_EMPLOYEE_MEAL
} from './actions';
// import Vue from 'vue';
import KindergartenService from '@services/kindergarten.service';
const defaultState = () => {
    return {
        employee_meals: [],
        settings: [],
        lock_date: ''
    };
};
const state = defaultState;

const mutations = {
    [SET_EMPLOYEE_MEALS](state, employees) {
        state.employee_meals = employees;
    },
    [SET_EMPLOYEE_MEALS_SETTINGS](state, settings) {
        state.settings = settings;
    },
    [SET_EMPLOYEE_MEALS_LOCK_DATE](state, date) {
        state.lock_date = date;
    }
};

const actions = {
    [GET_EMPLOYEE_MEALS]({commit, getters}, data) {
        return KindergartenService.getEmployeeMeals(getters.activeKindergartenId, data).then(res => {
            commit(SET_EMPLOYEE_MEALS, res.employees);
            commit(SET_EMPLOYEE_MEALS_SETTINGS, res.settings);
            commit(SET_EMPLOYEE_MEALS_LOCK_DATE, res.lock_date);
        });
    },
    [GET_EMPLOYEE_MEALS_SETTINGS]({commit, getters}) {
        return KindergartenService.getEmployeeMealsSettings(getters.activeKindergartenId).then(res => {
            commit(SET_EMPLOYEE_MEALS_SETTINGS, res);
        });
    },
    [UPDATE_EMPLOYEE_MEALS_SETTINGS]({commit, getters}, form) {
        return KindergartenService.updateEmployeeMealsSettings(getters.activeKindergartenId, form).then(res => {
           commit(SET_EMPLOYEE_MEALS_SETTINGS, res);
        });
    },
    [SAVE_EMPLOYEE_MEAL]({getters}, form) {
        return KindergartenService.saveEmployeeMeal(getters.activeKindergartenId, form);
    }
};

const getters = {
    employeeMeals: state => state.employee_meals,
    employeeMealsSettings: state => state.settings,
    employeeMealsLockDate: state => state.lock_date
};

export default {
    state,
    mutations,
    actions,
    getters
};
