import http from '@http';

class AdminSupplierService {
    constructor() {
        if (AdminSupplierService.instance) {
            return AdminSupplierService.instance;
        }

        AdminSupplierService.instance = this;
    }

    getSuppliers(queryObj) {
        return http.get('/api/admin/suppliers', {params: queryObj}).then(res => res.data);
    }

    getSupplier(supplierId) {
        return http.get(`/api/admin/suppliers/${supplierId}`).then(res => res.data);
    }

    getSupplierProducts(supplierId, queryObj) {
        return http.get(`/api/admin/suppliers/${supplierId}/products`, {params: queryObj}).then(res => res.data);
    }

    getSupplierServices(supplierId, queryObj) {
        return http.get(`/api/admin/suppliers/${supplierId}/services`, {params: queryObj}).then(res => res.data);
    }

    invalidateProduct(supplierId, productId) {
        return http.put(`/api/admin/suppliers/${supplierId}/products/${productId}/invalidate`).then(res => res.data);
    }

    invalidateService(supplierId, serviceId) {
        return http.put(`/api/admin/suppliers/${supplierId}/services/${serviceId}/invalidate`).then(res => res.data);
    }

    validateProduct(supplierId, productId) {
        return http.put(`/api/admin/suppliers/${supplierId}/products/${productId}/validate`).then(res => res.data);
    }
    validateService(supplierId, serviceId) {
        return http.put(`/api/admin/suppliers/${supplierId}/services/${serviceId}/validate`).then(res => res.data);
    }

    validateSupplier(supplierId) {
        return http.put(`/api/admin/suppliers/${supplierId}/validate`).then(res => res.data);
    }

    invalidateSupplier(supplierId) {
        return http.put(`/api/admin/suppliers/${supplierId}/invalidate`).then(res => res.data);
    }

    integrations(supplierId) {
        return http.get(`/api/admin/suppliers/${supplierId}/integrations`).then(res => res.data);
    }

    integration(supplierId, integrationId) {
        return http.get(`/api/admin/suppliers/${supplierId}/integrations/${integrationId}`).then(res => res.data);
    }

    categories(supplierId, integrationId) {
        return http.get(`/api/admin/suppliers/${supplierId}/integrations/${integrationId}/categories`).then(res => res.data);
    }

    integrationCategories(supplierId, integrationId, options) {
        return http.get(`/api/admin/suppliers/${supplierId}/integrations/${integrationId}/integration-categories`, {params: options}).then(res => res.data);
    }

    integrationProducts(supplierId, integrationId, queryObj) {
        return http.get(`/api/admin/suppliers/${supplierId}/integrations/${integrationId}/products`, {params: queryObj}).then(res => res.data);
    }

    integrationServices(supplierId, integrationId, queryObj) {
        return http.get(`/api/admin/suppliers/${supplierId}/integrations/${integrationId}/services`, {params: queryObj}).then(res => res.data);
    }

    availableCategories() {
        return http.get('/api/admin/suppliers/available-categories').then(res => res.data);
    }
}

export default new AdminSupplierService();