export const GET_THIS_CLASSES = 'GET_THIS_CLASSES';
export const SET_THIS_CLASSES = 'SET_THIS_CLASSES';
export const SET_THIS_CLASS = 'SET_THIS_CLASS';
export const SET_THIS_ERROR = 'SET_THIS_ERROR';
export const SET_THIS_CHILDREN = 'SET_THIS_CHILDREN';
export const RESET_THIS_STATE = 'RESET_THIS_STATE';
export const STORE_THIS_PERIOD = 'STORE_THIS_PERIOD';
export const STORE_THIS_COURSE = 'STORE_THIS_COURSE';
export const UPDATE_THIS_CLASS_CHILDREN = 'UPDATE_THIS_CLASS_CHILDREN';
export const SET_THIS_CLASS_CHILDREN = 'SET_THIS_CLASS_CHILDREN';
export const GET_THIS_CLASS_CHILDREN = 'GET_THIS_CLASS_CHILDREN';
export const CHECK_THIS_CLASS_CHILDREN = 'CHECK_THIS_CLASS_CHILDREN';
export const UPDATE_ELIIS_CLASS_CHILDREN_COUNT = 'UPDATE_ELIIS_CLASS_CHILDREN_COUNT';
