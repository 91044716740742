<template>
    <div class="e3-search-input">
        <e-input
            :id="id"
            v-model="val"
            :placeholder="placeholder"
            @keyup.enter="search">
            <template #input-append>
                <button v-show="val" class="e3-clear-search" :disabled="disabled" @click="clearSearch">
                    <SvgIcon icon="mdi-close" class="text-danger" :size="18"/>
                </button>
                <button class="btn btn-primary rounded-right" :disabled="!minValueReached || disabled" @click="search">
                    {{ $t('Search') }}
                </button>
            </template>
        </e-input>
    </div>
</template>

<script>
export default {
    name: 'ESearchInput',
    props: {
        id: String,
        value: [String, Number],
        placeholder: String,
        minValue: {
            type: Number,
            default: 2
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            minValueReached: false,
        };
    },
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
                if (!val.length) {
                    this.$emit('getEntities');
                }
            }
        },
    },

    watch: {
        'val': function() {
            if (this.val.length >= this.minValue) {
                this.minValueReached = true;
            } else {
                this.minValueReached = false;
            }
        },
    },

    methods: {
        search() {
            if(this.minValueReached) {
                this.$emit('getEntities');
            }
        },
        clearSearch() {
            this.$emit('input', '');
            if(this.minValueReached) {
                this.$emit('getEntities');
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    .e3-clear-search {
        border-bottom: 1px solid #c4c4c4;
        border-top: 1px solid #c4c4c4;
        border-right: none;
        border-left: none;
        background-color: #fff;
    }
    div.e3-search-input:focus-within .e3-clear-search{
        border-color: #f79e80;
    }
</style>