<template>
    <div>
        <!-- <e-table-filter
            v-if="isSearchable" id="filter" :value="term" @input="val => {searchDebounce(val)}"
            @clear="resetFilter"/> -->
        <div>
            <div class="e3-table">
                <div v-if="!hideHeader" class="e3-table-header e3-light-text-shadow main-gradient justify-content-between font-weight-bold"
                     :class="[isSearchable || tableHeaderDate ? 'd-sm-flex align-items-sm-center' : 'd-flex align-items-center', 
                              {'border-bottom rounded' : headerCollapsible && isCollapsed}, {'e3-pointer' : headerCollapsible}]"
                     @click="headerCollapsible ? collapse() : null">
                    <div class="d-flex align-items-center w-100">
                        <div v-if="headerCollapsible" class="d-flex e3-plus-minus-icon mr-2"
                             :aria-expanded="!isCollapsed">
                            <span class="plus-icon mr-2 border-0">
                                <i class="d-flex"/>
                            </span>
                        </div>
                        <div v-html="tableHeader"/>
                    </div>
                    <div v-if="isSearchable" class="mt-2 mt-sm-0 d-print-none" @click.stop="">
                        <ETableFilter
                            id="filter"
                            :value="term"
                            @input="val => {searchDebounce(val), this.$emit('searching', val)}"
                            @clear="resetFilter"/>
                    </div>
                    <div v-if="tableHeaderDate" v-html="tableHeaderDate"/>
                </div>
                <transition name="slide">
                    <div v-if="tableVisible" class="mb-3 e3-table-border" :class="{'table-responsive': !allowOverflow}">
                        <table class="table m-0 table-striped table-hover" :class="noTopAlign ? 'e3-table-align' : ''">
                            <thead>
                                <tr>
                                    <th v-if="isCollapsible" colspan="1"></th>
                                    <th v-if="isNumberable" colspan="1" :class="filterAlignNumber ? 'pb-3' : ''">Nr.</th>
                                    <th
                                        v-for="field in fields"
                                        :key="field.key"
                                        :class="[{'e3-pointer' : field.sortable}, field.thClass]"
                                        @click="field.sortable ? sort(field.key) : null">
                                        <slot v-if="hasSlot('title_' + field.key)" :name="'title_' + field.key"/>
                                        <div v-else class="d-inline-flex align-items-center">
                                            {{ field.label }}
                                            <SortDirectionButton v-if="field.sortable" :sortDir="sortDir" :orderColumn="sortBy" :column="field.key"/>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <template
                                v-if="this.computedEntities && this.computedEntities.length && (isLoaderDisabled ? true : !this.chooseContentLoader())">
                                <EDraggable
                                    v-if="draggable && !collapsible"
                                    v-model="computedEntities"
                                    tag="tbody"
                                    v-bind="{ animation: 250 }"
                                    class="e3-cursor-move">
                                    <component :is="'TableRow'"
                                               v-for="(entity, indexEntity) in computedEntities"
                                               :key="`nocollaps_${entity.id}_${indexEntity}`"
                                               :ref="`table_${tableIndex}_row_${indexEntity}`"
                                               :entities="entities"
                                               :entity="entity"
                                               :indexEntity="indexEntity"
                                               :tableIndex="tableIndex"
                                               :opened="opened"
                                               :fields="fields"
                                               :slots="$scopedSlots"
                                               :isNumberable="isNumberable"
                                               @openCloseRow="openCloseRow">
                                        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                                            <slot :name="name" v-bind="slotData" :item="entity" :row-index="indexEntity" :table-index="tableIndex"/>
                                        </template>
                                    </component>
                                </EDraggable>
                                <tbody v-else>
                                    <component :is="isCollapsible ? 'TableCollapsibleRow' : 'TableRow'"
                                               v-for="(entity, indexEntity) in computedEntities"
                                               :key="isCollapsible ? `collaps_${entity.id}_${indexEntity}` : `nocollaps_${entity.id}_${indexEntity}`"
                                               :ref="`table_${tableIndex}_row_${indexEntity}`"
                                               :entities="entities"
                                               :entity="entity"
                                               :indexEntity="indexEntity"
                                               :tableIndex="tableIndex"
                                               :opened="opened"
                                               :fields="fields"
                                               :slots="$scopedSlots"
                                               :isNumberable="isNumberable"
                                               @openCloseRow="openCloseRow">
                                        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                                            <slot :name="name" v-bind="slotData" :item="entity" :row-index="indexEntity" :table-index="tableIndex"/>
                                        </template>
                                    </component>
                                </tbody>
                            </template>
                            <tbody v-else-if="this.computedEntities && !this.computedEntities.length && !this.chooseContentLoader()">
                                <tr>
                                    <td :colspan="headerCols" class="p-0">
                                        <EmptyListMessage class="bg-white">
                                            <span v-if="!term">{{ $t(nothingFound) }}</span>
                                            <span v-else>{{ $t('No search results') }}</span>
                                        </EmptyListMessage>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="isLoaderDisabled ? false : this.chooseContentLoader()" class="justify-content-center">
                                <tr>
                                    <td :colspan="headerCols" class="bg-white">
                                        <ELoader class="py-0"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </transition>
            </div>
            <pagination v-if="isPaginatable" :data="entities" :limit="3" class="d-print-none" @pagination-change-page="getEntities">
                <span slot="prev-nav">
                    <SvgIcon icon="mdi-chevron-left"/>
                </span>
                <span slot="next-nav">
                    <SvgIcon icon="mdi-chevron-right"/>
                </span>
            </pagination>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import {cleanQueryObj} from '@services/helper.service';
import {mapGetters} from 'vuex';
import SortDirectionButton from '@common/components/buttons/SortDirectionButton';
import TableCollapsibleRow from './TableCollapsibleRow';
import TableRow from './TableRow';

export default {
    name: 'ETable',
    props: {
        tableIndex: {
            type: [String, Number],
            default: 'table'
        },
        tableHeader: String,
        tableHeaderDate: String,
        nothingFound: {
            type: String,
            default: 'The list is empty'
        },
        entities: [Array, Object],
        fields: Array,
        paginatable: Boolean,
        collapsible: Boolean,
        disableLoader: Boolean,
        singleRowOpen: {
            type: Boolean,
            default: false
        },
        row_heights_set: Boolean,
        numberable: Boolean,
        trashed: Boolean,
        searchable: Boolean,
        noTopAlign: Boolean,
        filterAlignNumber: Boolean,
        allowOverflow: {
            type: Boolean,
            default: false
        },
        customLoading: Boolean,
        useCustomLoader: {
            type: Boolean,
            default: false
        },
        predefinedSearch: {
            types: [String, Number],
            default: null
        },
        startingSort: {
            type: Object
        },
        headerCollapsible: {
            type: Boolean,
            default: false
        },
        isCollapsed: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        focus: {
            type: Boolean,
            default: true
        },
        hideHeader: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            opened: [],
            searchDebounce: null,
            term: '',
            sortBy: (this.fields.filter(f => f.sortable === true)[0] ? this.fields.filter(f => f.sortable === true)[0].key : ''),
            sortDir: 'asc',
            perPage: 15,
            lastVisitedPage: 1
        };
    },
    computed: {
        ...mapGetters(['isContentLoading', 'language']),
        computedEntities: {
            get() {
                if (this.isPaginatable) {
                    return this.entities.data;
                }
                return this.entities;
            },
            set(entities) {
                this.$emit('draggedEntities', entities);
            }
        },
        isSearchable() {
            return this.searchable;
        },
        isLoaderDisabled() {
            return this.disableLoader;
        },
        isPaginatable() {
            return this.paginatable;
        },
        isCollapsible() {
            return this.collapsible;
        },
        isNumberable() {
            return this.numberable;
        },
        headerCols() {
            let cols = this.fields.length;
            if (this.isCollapsible) {
                cols = cols + 1;
            }
            if (this.isNumberable) {
                cols = cols + 1;
            }
            return cols;
        },
        isCustomLoading() {
            return this.customLoading;
        },
        tableVisible() {
            return !this.headerCollapsible || (this.headerCollapsible && !this.isCollapsed);
        }
    },
    watch: {
        activeKindergartenId(newVal) {
            if (newVal == null || typeof newVal == undefined) {
                return;
            }

            this.getEntities();
        },
        row_heights_set(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.sendRowHeights();
            }
        }
    },
    methods: {
        hasSlot(slot) {
            return !!this.$scopedSlots[slot];
        },
        openCloseRow(id) {
            if (this.singleRowOpen) {
                if (this.opened.length) {
                    this.opened[0] === id ? this.opened = [] : this.opened = [id];
                    this.$emit('openRow', id);
                } else {
                    this.opened.push(id);
                    this.$emit('openRow', id);
                }
            } else {
                const index = this.opened.indexOf(id);
                if (index > -1) {
                    this.opened.splice(index, 1);
                    this.$emit('closeRow', id);
                } else {
                    this.opened.push(id);
                    this.$emit('openRow', id);
                }
            }
        },
        resetFilter() {
            this.term = '';
            this.getEntities(this.lastVisitedPage);
            this.$emit('searching', '');
        },
        sort(field) {
            this.sortBy = field;
            if (this.sortDir == 'desc') {
                this.sortDir = 'asc';
            } else {
                this.sortDir = 'desc';
            }
            this.getEntities(this.lastVisitedPage);
        },
        getEntities(page = 1) {
            if (this.isPaginatable) {
                this.lastVisitedPage = page;
            } else {
                page = null;
                this.perPage = null;
            }

            const queryObj = {
                page: page,
                perPage: this.perPage,
                sortBy: this.sortBy,
                sortDir: this.sortDir,
                term: this.term,
                trashed: this.trashed,
                lang: this.language
            };
            this.$emit('getEntities', cleanQueryObj(queryObj));
        },
        sendRowHeights() {
            this.$emit('sendRowHeights', this.$refs);
        },
        chooseContentLoader() {
            if (this.useCustomLoader) return this.isCustomLoading;
            
            return this.isContentLoading;
        },
        collapse() {
            this.$emit('collapseClick');
        }
    },
    components: {
        SortDirectionButton,
        TableCollapsibleRow,
        TableRow
    },
    created() {
        this.searchDebounce = debounce(value => {
            this.term = value;
            this.getEntities(1);
        }, 500);
        this.sendRowHeights();
        if(this.predefinedSearch) {
            this.term = this.predefinedSearch;
        }
        if(this.startingSort?.sortBy) {
            this.sortBy = this.startingSort.sortBy;
            this.sortDir = this.startingSort.sortDir;
        }
    }
};
</script>
<style scoped lang="scss">
    .opened:hover {
        background-color: transparent;
    }

    .plus-icon {
        background-color: #f79e80;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 21px;
        width: 21px;
        border-radius: 0.33rem;

        i {
            &:before {
                font-size: 15px;
            }
        }
    }
    .table {
        .e3-loader {
            color: red;
        }
    }
</style>
