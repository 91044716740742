<template>
    <div class="e3-head-icon-round">
        <div class="d-flex align-items-center px-3 py-2">
            <span class="text-uppercase font-weight-bold">{{ $t('Files') }}</span>
        </div>
        <div class="border-top">
            <div v-if="recentDocuments.length">
                <div v-for="file in recentDocuments" :key="file.id" class="d-flex flex-row px-3 py-2 e3-header-files">
                    <div class="profile-image-container">
                        <ProfileImage
                            :imageUrl="file.creator.profile_picture"
                            :name="file.creator.fname + ' ' + file.creator.lname"
                            size="sm"/>
                    </div>
                    <div class="d-flex flex-column ml-2 w-100">
                        <div class="d-flex flex-row">
                            <div v-if="file.creator" class="font-weight-bold">{{ file.creator.fname }} {{ file.creator.lname }}</div>
                            <div v-if="file.status && file.status === 'new'" class="ml-auto">
                                <span class="badge badge-primary text-white ml-auto p-2 text-uppercase">
                                    {{ $t('New') }}
                                </span>
                            </div>
                        </div>
                        <div v-if="file.folder" class="text-wrap">
                            <router-link :to="{name:'document.folder', params: {folder: file.folder.id}}" :title="file.folder.name">
                                {{ $t('Folder') }}: {{ truncate(file.folder.name, 16) }}
                            </router-link>
                        </div>
                        <div class="text-wrap">
                            <a
                                class="text-primary font-weight-bold e3-pointer e3-hover-underline d-inline-flex align-items-center"
                                :title="file.name"
                                @click="download(file)">
                                <div class="mr-2">
                                    {{ truncate(file.name, 18) }}
                                </div>
                                <div v-if="loading === file.id" class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </a>
                        </div>
                        <small class="text-muted">{{ formatDateTime(file.created_at) }}</small>
                    </div>
                </div>
            </div>
            <div v-else>
                <empty-list-message wrapperClass="p-2">
                    {{ $t('No new files have been shared with you') }}
                </empty-list-message>
            </div>
        </div>
        <div class="bg-light py-2 px-3 border-top rounded-bottom">
            <router-link :to="{name: 'document.index'}" @click.native="$emit('close', id)">
                {{ $t('Show all files') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {formatDateTime, truncate} from '@/filters';
import DocumentService from '@services/document.service';

export default {
    props: {
        id: [String, Number]
    },
    data() {
        return {
            cloudCount: 0,
            files: [],
            loading: null
        };
    },
    computed: {
        ...mapGetters(['activeKindergartenId', 'recentDocuments', 'unseenDocumentCount', 'loginType'])
    },
    methods: {
        formatDateTime, // TODO: Vue3 remove
        truncate, // TODO: Vue3 remove
        getRecentDocuments() {
            this.$store.dispatch('GET_RECENT_DOCUMENTS', this.activeKindergartenId);
        },
        download(file) {
            if (!this.loading) {
                this.loading = file.id;
                DocumentService.downloadDocument(this.activeKindergartenId, file).finally(()=> {
                    this.loading = null;
                });
            }
        }
    },
    created() {
        this.getRecentDocuments();
    },
};
</script>

<style scoped>
    .e3-header-files:not(:last-child) {
        border-bottom: 1px solid #c2c2c2;
    }
</style>
