import Vue from 'vue';
import {
    ADD_EVENT,
    REMOVE_EVENT,
    REMOVE_EVENTS_BY_ID,
    REMOVE_EVENTS_STARTING_FROM,
    DELETE_EVENT,
    SET_EVENT,
    SET_EVENTS,
    EDIT_EVENT,
    UPDATE_EVENT,
    GET_EVENTS,
    SET_SELECTED_EVENT,
    SET_SELECTED_EVENT_OBJ,
    SET_SELECTED_DATE,
    GET_MY_EVENTS,
    GET_RECENT_EVENTS,
    SET_RECENT_EVENTS,
    SET_RECENT_EVENTS_COUNT,
    UPDATE_EVENTS_ACTIVITY,
    GET_ORG_EVENTS,
    GET_RECENT_ORG_EVENTS,
    UPDATE_ORG_EVENTS_ACTIVITY
} from './actions';
import EventService from '@services/event.service';
import {convertTimesFromUtc} from '../../storeHelpers';
import store from '../../../store';
import orderBy from 'lodash/orderBy';

function convertTimesToUtc(data, selectedEventObj) {
    const dataCopy = {...data};
    let start;
    if(dataCopy.change_mode === 'all') {
        start = dataCopy.repeat_start ?? selectedEventObj.repeat_start ?? dataCopy.start_date ?? selectedEventObj.start_date;
    } else if ( dataCopy.change_mode === 'from') {
        start = (dataCopy.is_recurring ? dataCopy.repeat_start ?? selectedEventObj.repeat_start : null) ?? dataCopy.start_date ?? selectedEventObj?.start_date;
    } else {
        start = dataCopy.start_date;
    }
    if (dataCopy.start_time) {
        const dateString = (start) + 'T' + dataCopy.start_time;
        let moment = Vue.moment.tz(dateString, store.getters.user.timezone).utc();
        // dataCopy.start_date = moment.format('YYYY-MM-DD');
        dataCopy.start_time = moment.format('HH:mm:ss');
    }

    if (dataCopy.end_time) {
        const dateString = (start) + 'T' + dataCopy.end_time;
        let moment = Vue.moment.tz(dateString, store.getters.user.timezone).utc();
        dataCopy.end_time = moment.format('HH:mm:ss');
    }

    return dataCopy;
}

function convertFromToUtc(data) {
    const dataCopy = {...data};
    if (dataCopy.start_time) {
        let moment = convertTimesFromUtc(dataCopy.start_date, dataCopy.start_time, store.getters.user.timezone, dataCopy.repeat_start);
            dataCopy.start_date = moment.date;
            dataCopy.start_time = moment.time;
    }

    if (dataCopy.end_time) {
        let moment = convertTimesFromUtc(dataCopy.end_date, dataCopy.end_time, store.getters.user.timezone, dataCopy.repeat_start);
        dataCopy.end_time = moment.time;
    }

    return dataCopy;
}

function formatEventData(data) {
    const event = convertFromToUtc(data);
    const eventColor = state.eventTypes.find(t => t.value === event.event_type)
        .color;
    const eventBorderColor = state.eventTypes.find(t => t.value === event.event_type)
        .borderColor;
    const eventTextColor = state.eventTypes.find(t => t.value === event.event_type)
        .textColor;
    event.start = event.start_date;
    if (event.end_date !== event.start_date) {
        event.end = event.end_date;
        if (!event.end_time) {
            /*  FullCalendar Note:
                Enddate value is exclusive.
                For example, an event with the end of 2018-09-03 will appear to span
                through 2018-09-02 but end before the start of 2018-09-03.
            */
            //event.end = event.end +" 23:59:59"; // This solution adds 00:00 for event start time
            event.end = Vue.moment(event.end, 'Y-MM-DD')
                .add(1, 'days')
                .format('Y-MM-DD');
        }
    }
    event.backgroundColor = eventColor;
    event.borderColor = eventBorderColor ? eventBorderColor : eventColor;
    event.textColor = eventTextColor ? eventTextColor : '#fff';
    if (event.start_time) {
        event.start = event.start + 'T' + event.start_time;
        event.allDay = false;
    }
    if (event.end_time) {
        event.end = (event.end || event.start_date) + 'T' + event.end_time;
    }
    return event;
}

const state = {
    events: [],
    newEventsCount: 0,
    newEvents: [],
    eventTypes: [
        { value: 1, color: '#f79e80', text: 'Study visit/Education project' },
        { value: 2, color: '#a5c365', text: 'Theatre/Concert' },
        { value: 5, color: '#e082b1', text: 'Meeting' },
        { value: 8, color: '#eb5e5d', text: 'Training' },
        { value: 3, color: '#cc7979', text: 'Joint event'},
        { value: 4, color: '#f0ad4e', text: 'Class event' },
        { value: 7, color: '#73cac2', text: 'Learning activitiy'},
        { value: 6, color: '#5bc0de', text: 'Other event' },
        { value: 0, color: '#fff', borderColor: '#eb5e5d', textColor: '#444444', text: 'Public holidays' }
    ],
    selectedEventObj: null,
    selectedDate: null,
};

const mutations = {
    [SET_EVENT](state, data) {
        Vue.set(state, 'events', orderBy([...state.events, ...data], ['start_date'], ['desc']));
    },
    [SET_EVENTS](state, data) {
        Vue.set(state, 'events', [...data]);
    },
    [UPDATE_EVENT](state, data) {
        const eventIndex = state.events.findIndex(e => {
            return data.id === e.id;
        });
        if (eventIndex !== -1) {
            Vue.set(state.events, eventIndex, data);
        }
    },
    [REMOVE_EVENT](state, unique_id) {
        const filteredEventList = state.events.filter(e => {
            return e.unique_id !== unique_id;
        });
        Vue.set(state, 'events', [...filteredEventList]);
    },
    [REMOVE_EVENTS_BY_ID](state, id) {
        const filteredEventList = state.events.filter(e => {
            return e.id !== id;
        });
        Vue.set(state, 'events', [...filteredEventList]);
    },
    [REMOVE_EVENTS_STARTING_FROM](state) {
        const startDate = new Date(state.selectedEventObj.start_date);

        const filteredEventList = state.events.filter(e => {
            let eventStartDate = new Date(e.start_date);
            let isSameGroup =
                e.id === state.selectedEventObj.id;
            let isAfterStartDate =
                eventStartDate.getTime() >= startDate.getTime();
            return !isSameGroup || !isAfterStartDate;
        });

        Vue.set(state, 'events', [...filteredEventList]);
    },
    [SET_SELECTED_EVENT](state, data) {
        state.selectedEventObj = data;
    },
    [SET_SELECTED_DATE](state, data) {
        state.selectedDate = data;
    },
    [SET_RECENT_EVENTS](state, events) {
        state.newEvents = events;
    },
    [SET_RECENT_EVENTS_COUNT](state, count) {
        if(count > 5) {
            count = '5+';
        }
        state.newEventsCount = count;
    }
};

const actions = {
    [ADD_EVENT]({ commit }, data) {
        return EventService.createEvent(convertTimesToUtc(data)).then(res => {
            const events = [];
            res.forEach(event => {
                events.push(formatEventData(event));
            });
            commit(SET_EVENT, events);
        });
    },
    [EDIT_EVENT]({ commit, getters }, data) {
        return EventService.updateEvent(convertTimesToUtc(data, getters.getSelectedEventObj)).then(res => {
            commit(REMOVE_EVENTS_BY_ID, data.id);
            //commit(SET_EVENT, res);
            const addEvents = [];
            //const updateEvents = [];
            res.forEach(event => {
                addEvents.push(formatEventData(event));
            });
            commit(SET_EVENT, addEvents);
        });
    },

    [GET_EVENTS]({ commit, getters }, params) {
        return EventService.getEvents(getters.activeKindergartenId, params).then(res => {
            const events = [];
            res.forEach(event => {
                events.push(formatEventData(event));
            });
            commit(SET_EVENTS, events);
        });
    },
    [GET_ORG_EVENTS]({commit, getters}, params) {
        return EventService.getOrgEvents(getters.activeOrganizationId, params).then(res => {
            const events = [];
            res.forEach(event => {
                events.push(formatEventData(event));
            });
            commit(SET_EVENTS, events);
        });
    },
    [SET_SELECTED_EVENT_OBJ]({ commit }, data) {
        commit(SET_SELECTED_EVENT, data);
    },
    [SET_SELECTED_DATE]({ commit }, data) {
        commit(SET_SELECTED_DATE, data);
    },
    [DELETE_EVENT]({ commit }, data) {
        return EventService.deleteEvent(data).then(() => {
            switch (data.change_mode) {
                case 'single':
                    commit(REMOVE_EVENT, data.unique_id);
                    break;
                case 'all':
                    commit(REMOVE_EVENTS_BY_ID, data.id);
                    break;
                case 'from':
                    commit(REMOVE_EVENTS_STARTING_FROM);
                    break;
            }
        });
    },
    [GET_MY_EVENTS]({commit, getters}) {
        return EventService.getMyEvents(getters.activeKindergartenId).then(res => {
            const events = [];
            res.forEach(event => {
                events.push(formatEventData(event));
            });
            commit(SET_EVENTS, events);
            commit(SET_RECENT_EVENTS, []);
            commit(SET_RECENT_EVENTS_COUNT, 0);
        });
    },
    [GET_RECENT_EVENTS]({commit, getters}) {
        commit(SET_RECENT_EVENTS_COUNT, 0);
        return EventService.getRecentEvents(getters.activeKindergartenId).then(res => {
        const events = [];
        res.events.forEach(event => {
            const e = convertFromToUtc(event);
            if (e.is_recurring) {
                e.end_date = Vue.moment(e.start_date).add(e.duration, 'days').format('YYYY-MM-DD');
            }
            events.push(e);
        });
          commit(SET_RECENT_EVENTS, events);
          commit(SET_RECENT_EVENTS_COUNT, res.count);
        });
    },
    [GET_RECENT_ORG_EVENTS]({commit, getters}) {
        commit(SET_RECENT_EVENTS_COUNT, 0);
        return EventService.getRecentOrgEvents(getters.activeOrganizationId).then(res => {
            const events = [];
            res.events.forEach(event => {
                const e = convertFromToUtc(event);
                if (e.is_recurring) {
                    e.end_date = Vue.moment(e.start_date).add(e.duration, 'days').format('YYYY-MM-DD');
                }
                events.push(e);
            });
            commit(SET_RECENT_EVENTS, events);
            commit(SET_RECENT_EVENTS_COUNT, res.count);
        });
    },
    [UPDATE_EVENTS_ACTIVITY]({commit, getters}) {
        return EventService.updateActivity(getters.activeKindergartenId).then(() => {
           commit(SET_RECENT_EVENTS_COUNT, 0);
        });
    },
    [UPDATE_ORG_EVENTS_ACTIVITY]({commit, getters}) {
        return EventService.updateOrgActivity(getters.activeOrganizationId).then(() => {
            commit(SET_RECENT_EVENTS_COUNT, 0);
        });
    }
};

const getters = {
    eventList: state => {
        return state.events;
    },
    eventTypes: state => {
        return state.eventTypes;
    },
    getSelectedEventObj: state => {
        return state.selectedEventObj;
    },
    getEventById: state => id => {
        return state.events.find(event => event.unique_id === id);
    },
    getSelectedDate: state => state.selectedDate,
    newEvents : state => state.newEvents,
    newEventsCount: state => state.newEventsCount
};

export default {
    state,
    mutations,
    actions,
    getters
};
