export const RESET_MESSAGE_STATE = 'RESET_MESSAGE_STATE';
export const GET_MESSAGES = 'GET_MESSAGES';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_DELETED_MESSAGES = 'SET_DELETED_MESSAGES';
export const SET_SENT_MESSAGES = 'SET_SENT_MESSAGES';
export const SET_MESSAGE_SEEN = 'SET_MESSAGE_SEEN';
export const SET_MESSAGE_DELETED = 'SET_MESSAGE_DELETED';
export const GET_AVAILABLE_MESSAGE_TARGETGROUPS = 'GET_AVAILABLE_MESSAGE_TARGETGROUPS';
export const SET_AVAILABLE_MESSAGE_TARGETGROUPS = 'SET_AVAILABLE_MESSAGE_TARGETGROUPS';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_OLD_MESSAGE = 'SEND_OLD_MESSAGE';
export const ADD_SENT_MESSAGE = 'ADD_SENT_MESSAGE';
export const REMOVE_DELETED_MESSAGE = 'REMOVE_DELETED_MESSAGE';
export const RESTORE_MESSAGE = 'RESTORE_MESSAGE';
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const UPDATE_MESSAGE_SEEN = 'UPDATE_MESSAGE_SEEN';
export const SET_MESSAGE_CURRENT_PAGE = 'SET_MESSAGE_CURRENT_PAGE';
export const SET_MESSAGE_PER_PAGE = 'SET_MESSAGE_PER_PAGE';
export const GET_RECENT_MESSAGES = 'GET_RECENT_MESSAGES';
export const SET_RECENT_MESSAGES = 'SET_RECENT_MESSAGES';
export const SET_UNREAD_MESSAGES_COUNT = 'SET_UNREAD_MESSAGES_COUNT';
export const DELETE_SELECTED_MESSAGES = 'DELETE_SELECTED_MESSAGES';
export const GET_MESSAGE_DRAFTS = 'GET_MESSAGE_DRAFTS';
export const SET_MESSAGE_DRAFTS = 'SET_MESSAGE_DRAFTS';
export const DELETE_MESSAGE_DRAFT = 'DELETE_MESSAGE_DRAFT';
export const REMOVE_MESSAGE_DRAFT = 'REMOVE_MESSAGE_DRAFT';
export const GET_MESSAGE_DRAFT = 'GET_MESSAGE_DRAFT';
export const GET_DRAFT = 'GET_DRAFT';
export const CREATE_MESSAGE_DRAFT = 'CREATE_MESSAGE_DRAFT';
export const UPDATE_MESSAGE_DRAFT = 'UPDATE_MESSAGE_DRAFT';
export const SET_MESSAGE_DRAFT = 'SET_MESSAGE_DRAFT';
export const UPLOAD_MESSAGE_DRAFT_ATTACHMENT = 'UPLOAD_MESSAGE_DRAFT_ATTACHMENT';
export const ADD_MESSAGE_DRAFT_ATTACHMENT = 'ADD_MESSAGE_DRAFT_ATTACHMENT';
export const DELETE_MESSAGE_DRAFT_ATTACHMENT = 'DELETE_MESSAGE_DRAFT_ATTACHMENT';
export const REMOVE_MESSAGE_DRAFT_ATTACHMENT = 'REMOVE_MESSAGE_DRAFT_ATTACHMENT';
export const SET_DRAFT_STATUS = 'SET_DRAFT_STATUS';
export const ADD_MESSAGE_DRAFT = 'ADD_MESSAGE_DRAFT';
export const DELETE_SELECTED_MESSAGE_DRAFTS = 'DELETE_SELECTED_MESSAGE_DRAFTS';
