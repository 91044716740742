export const RESET_KINDERGARTEN_STATE = 'RESET_KINDERGARTEN_STATE';
export const SET_KINDERGARTEN = 'SET_KINDERGARTEN';
export const SET_KINDERGARTEN_SETTINGS = 'SET_KINDERGARTEN_SETTINGS';
export const GET_KINDERGARTEN_SETTINGS = 'GET_KINDERGARTEN_SETTINGS';
export const SET_KINDERGARTEN_CONTACTS = 'SET_KINDERGARTEN_CONTACTS';
export const GET_KINDERGARTEN_EMPLOYEES = 'GET_KINDERGARTEN_EMPLOYEES';
export const SET_KINDERGARTEN_EMPLOYEES = 'SET_KINDERGARTEN_EMPLOYEES';
export const GET_KINDERGARTEN_CHILDREN = 'GET_KINDERGARTEN_CHILDREN';
export const SET_KINDERGARTEN_CHILDREN = 'SET_KINDERGARTEN_CHILDREN';
export const GET_KINDERGARTEN_GUARDIANS = 'GET_KINDERGARTEN_GUARDIANS';
export const SET_KINDERGARTEN_GUARDIANS = 'SET_KINDERGARTEN_GUARDIANS';
export const GET_TYPE_PERMISSIONS = 'GET_TYPE_PERMISSIONS';
export const SET_KINDERGARTEN_COURSES = 'SET_KINDERGARTEN_COURSES';
export const UPDATE_ACTIVE_KINDERGARTEN = 'UPDATE_ACTIVE_KINDERGARTEN';
export const ADD_CHILD = 'ADD_CHILD';
export const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE';
export const STORE_CHILD = 'STORE_CHILD';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';
export const GET_KINDERGARTEN_GUARDIANS_LIST = 'GET_KINDERGARTEN_GUARDIANS_LIST';
export const SET_KINDERGARTEN_GUARDIANS_LIST = 'SET_KINDERGARTEN_GUARDIANS_LIST';
export const GET_KINDERGARTEN_CHILDREN_LIST = 'GET_KINDERGARTEN_CHILDREN_LIST';
export const SET_KINDERGARTEN_CHILDREN_LIST = 'SET_KINDERGARTEN_CHILDREN_LIST';
export const GET_KINDERGARTEN_CONTACTS = 'GET_KINDERGARTEN_CONTACTS';
export const UPDATE_KINDERGARTEN_SETTINGS = 'UPDATE_KINDERGARTEN_SETTINGS';
export const SET_TYPE_PERMISSIONS = 'SET_TYPE_PERMISSIONS';
export const UPDATE_KINDERGARTEN_CONTACTS = 'UPDATE_KINDERGARTEN_CONTACTS';
export const SET_KINDERGARTEN_LOGO = 'SET_KINDERGARTEN_LOGO';
export const GET_KINDERGARTEN_LOGO = 'GET_KINDERGARTEN_LOGO';
export const SET_KINDERGARTEN_NAME = 'SET_KINDERGARTEN_NAME';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE';
export const GET_KINDERGARTEN_ARCHIVED_EMPLOYEES = 'GET_KINDERGARTEN_ARCHIVED_EMPLOYEES';
export const GET_KINDERGARTEN_EMPLOYEES_LIST = 'GET_KINDERGARTEN_EMPLOYEES_LIST';
export const REMOVE_CHILD = 'REMOVE_CHILD';
export const GET_KINDERGARTEN_ACTIVE_COURSES = 'GET_KINDERGARTEN_ACTIVE_COURSES';
export const GET_KINDERGARTEN_ALL_ACTIVE_COURSES = 'GET_KINDERGARTEN_ALL_ACTIVE_COURSES';
export const SET_KINDERGARTEN_ALL_ACTIVE_COURSES = 'SET_KINDERGARTEN_ALL_ACTIVE_COURSES';
export const SET_MONTH_WORK_DAY_COUNT = 'SET_MONTH_WORK_DAY_COUNT';
export const SET_KINDERGARTEN_SETTING = 'SET_KINDERGARTEN_SETTING';
export const GET_SYSTEM_PERMISSIONS = 'GET_SYSTEM_PERMISSIONS';
export const SET_SYSTEM_PERMISSIONS = 'SET_SYSTEM_PERMISSIONS';
export const GET_KINDERGARTEN_LANGUAGES = 'GET_KINDERGARTEN_LANGUAGES';
export const SET_KINDERGARTEN_LANGUAGES = 'SET_KINDERGARTEN_LANGUAGES';
export const ADD_KINDERGARTEN_COURSE = 'ADD_KINDERGARTEN_COURSE';
export const STORE_KINDERGARTEN_COURSE = 'STORE_KINDERGARTEN_COURSE';
export const UPDATE_KINDERGARTEN_COURSE = 'UPDATE_KINDERGARTEN_COURSE';
export const SET_KINDERGARTEN_COURSE = 'SET_KINDERGARTEN_COURSE';
export const REMOVE_KINDERGARTEN_COURSE = 'REMOVE_KINDERGARTEN_COURSE';
export const ARCHIVE_KINDERGARTEN_COURSE = 'ARCHIVE_KINDERGARTEN_COURSE';
export const DELETE_KINDERGARTEN_COURSE = 'DELETE_KINDERGARTEN_COURSE';
export const GET_KINDERGARTEN_ARCHIVED_COURSES = 'GET_KINDERGARTEN_ARCHIVED_COURSES';
export const SET_KINDERGARTEN_COURSE_TEACHERS = 'SET_KINDERGARTEN_COURSE_TEACHERS';
export const SET_KINDERGARTEN_ARCHIVED_COURSES = 'SET_KINDERGARTEN_ARCHIVED_COURSES';
export const SET_KINDERGARTEN_COURSE_CHILDREN_COUNT = 'SET_KINDERGARTEN_COURSE_CHILDREN_COUNT';
export const RESTORE_KINDERGARTEN_COURSE = 'RESTORE_KINDERGARTEN_COURSE';
export const REMOVE_KINDERGARTEN_ARCHIVED_COURSE = 'REMOVE_KINDERGARTEN_ARCHIVED_COURSE';

