export const DEACTIVATE_SUPPLIER = 'DEACTIVATE_SUPPLIER';
export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const SET_SUPPLIER_AGREED_AT = 'SET_SUPPLIER_AGREED_AT';
export const SET_SUPPLIER = 'SET_SUPPLIER';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const RESET_SUPPLIER_STATE = 'RESET_SUPPLIER_STATE';
export const ACTIVATE_SUPPLIER = 'ACTIVATE_SUPPLIER';
export const AGREE_SUPPLIER_TERMS = 'AGREE_SUPPLIER_TERMS';
export const SAVE_SUPPLIER_SETTING = 'SAVE_SUPPLIER_SETTING';
export const ADD_SUPPLIER_SETTING = 'ADD_SUPPLIER_SETTING';
export const SET_SUPPLIER_LOGO = 'SET_SUPPLIER_LOGO';
// products
export const STORE_PRODUCT = 'STORE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const SET_PRODUCT = 'SET_PRODUCT';
export const GET_SUPPLIER_PRODUCTS = 'GET_SUPPLIER_PRODUCTS';
export const GET_SUPPLIER_PRODUCT = 'GET_SUPPLIER_PRODUCT';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT_FORM = 'SET_PRODUCT_FORM';
export const DELETE_PRODUCT_IMG = 'DELETE_PRODUCT_IMG';
// price lists
export const GET_PRICE_LISTS = 'GET_PRICE_LISTS';
export const SET_PRICE_LISTS = 'SET_PRICE_LISTS';
export const SET_PRICE_LIST = 'SET_PRICE_LIST';
export const STORE_PRICE_LIST = 'STORE_PRICE_LIST';
export const UPDATE_PRICE_LIST = 'UPDATE_PRICE_LIST';
export const DELETE_PRICE_LIST = 'DELETE_PRICE_LIST';
export const REMOVE_PRICE_LIST = 'REMOVE_PRICE_LIST';
export const REORDER_PRICE_LIST = 'REORDER_PRICE_LIST';
export const RESET_PRICE_LIST_STATE = 'RESET_PRICE_LIST_STATE';

//Services

export const STORE_SERVICE = 'STORE_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';
export const SET_SERVICE = 'SET_SERVICE';
export const SET_SERVICE_FORM = 'SET_SERVICE_FORM';
export const GET_SUPPLIER_SERVICES = 'GET_SUPPLIER_SERVICES';
export const GET_SUPPLIER_SERVICE = 'GET_SUPPLIER_SERVICE';
export const SET_SERVICES = 'SET_SERVICES';
export const DELETE_SERVICE_IMG = 'DELETE_SERVICE_IMG';

export const GET_SHOP_ITEM_FILTERS = 'GET_SHOP_ITEM_FILTERS';
export const SET_SHOP_ITEM_FILTERS = 'SET_SHOP_ITEM_FILTERS';
export const GET_SUPPLIER_ORDERS = 'GET_SUPPLIER_ORDERS';
export const SET_SUPPLIER_ORDERS = 'SET_SUPPLIER_ORDERS';
export const GET_SUPPLIER_CATEGORIES = 'GET_SUPPLIER_CATEGORIES';
export const SET_SUPPLIER_CATEGORIES = 'SET_SUPPLIER_CATEGORIES';
export const GET_SUPPLIER_PRODUCT_CATEGORIES = 'GET_SUPPLIER_PRODUCT_CATEGORIES';
export const SET_SUPPLIER_PRODUCT_CATEGORIES = 'SET_SUPPLIER_PRODUCT_CATEGORIES';
export const GET_SUPPLIER_SERVICE_CATEGORIES = 'GET_SUPPLIER_SERVICE_CATEGORIES';
export const SET_SUPPLIER_SERVICE_CATEGORIES = 'SET_SUPPLIER_SERVICE_CATEGORIES';

//Settings
export const DELETE_SUPPLIER_COUNTRY = 'DELETE_SUPPLIER_COUNTRY';
export const SET_SUPPLIER_COUNTRIES = 'SET_SUPPLIER_COUNTRIES';
export const GET_SUPPLIER_SETTINGS = 'GET_SUPPLIER_SETTINGS';
export const SET_SUPPLIER_SETTINGS = 'SET_SUPPLIER_SETTINGS';
export const GET_AVAILABLE_SUPPLIER_INTEGRATION_CLIENTS = 'GET_AVAILABLE_SUPPLIER_INTEGRATION_CLIENTS';
export const SET_AVAILABLE_SUPPLIER_INTEGRATION_CLIENTS = 'SET_AVAILABLE_SUPPLIER_INTEGRATION_CLIENTS';
export const GET_SUPPLIER_INTEGRATION_CLIENTS = 'GET_SUPPLIER_INTEGRATION_CLIENTS';
export const SET_SUPPLIER_INTEGRATION_CLIENTS = 'SET_SUPPLIER_INTEGRATION_CLIENTS';
export const STORE_SUPPLIER_INTEGRATION_CLIENT = 'STORE_SUPPLIER_INTEGRATION_CLIENT';
export const ADD_SUPPLIER_INTEGRATION_CLIENT = 'ADD_SUPPLIER_INTEGRATION_CLIENT';
export const UPDATE_SUPPLIER_INTEGRATION_CLIENT = 'UPDATE_SUPPLIER_INTEGRATION_CLIENT';
export const SET_SUPPLIER_INTEGRATION_CLIENT = 'SET_SUPPLIER_INTEGRATION_CLIENT';
export const GET_SUPPLIER_INTEGRATION = 'GET_SUPPLIER_INTEGRATION';
export const SET_SELECTED_SUPPLIER_INTEGRATION = 'SET_SELECTED_SUPPLIER_INTEGRATION';

//Integrations
export const GET_SUPPLIER_INTEGRATIONS = 'GET_SUPPLIER_INTEGRATIONS';
export const SET_SUPPLIER_INTEGRATIONS = 'SET_SUPPLIER_INTEGRATIONS';
export const GET_AVAILABLE_SHOP_CATEGORIES = 'GET_AVAILABLE_SHOP_CATEGORIES';
export const SET_AVAILABLE_SHOP_CATEGORIES = 'SET_AVAILABLE_SHOP_CATEGORIES';
export const GET_SUPPLIER_INTEGRATION_CATEGORIES = 'GET_SUPPLIER_INTEGRATION_CATEGORIES';
export const SET_SUPPLIER_INTEGRATION_CATEGORIES = 'SET_SUPPLIER_INTEGRATION_CATEGORIES';
export const SET_INTERGATION_ERROR = 'SET_INTERGATION_ERROR';
export const SYNC_SUPPLIER_INTEGRATION_CATEGORIES = 'SYNC_SUPPLIER_INTEGRATION_CATEGORIES';
export const GET_SUPPLIER_INTEGRATION_PRICE_LISTS = 'GET_SUPPLIER_INTEGRATION_PRICE_LISTS';
export const SET_SUPPLIER_INTEGRATION_PRICE_LISTS = 'SET_SUPPLIER_INTEGRATION_PRICE_LISTS';
export const SAVE_SUPPLIER_INTEGRATION_PRICE_LIST = 'SAVE_SUPPLIER_INTEGRATION_PRICE_LIST';
