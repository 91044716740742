<template>
    <div class="py-5 px-4 bg-white shadow-sm rounded">
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-center">
            <div class="pb-3 pb-md-0 d-flex justify-content-center mr-md-5">
                <slot name="image">
                    <img class="img-fluid error-404-image" src="~@/assets/images/eliis_404.png" alt="404">
                </slot>
            </div>
            <div class="text-center text-md-left error-404-text-container">
                <h3>{{$t(title)}}</h3>
                <p>{{$t(message)}}</p>
                <div>
                    <router-link :to="backLinkUrl">{{$t(backLinkText)}}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorContent',
    props: {
        title: {
            type: String,
            default: 'Oops... Something went wrong.'
        },
        message: {
            type: String,
            default: 'The page you are looking for may have been moved, deleted or possibly never existed.'
        },
        backLinkText: {
            type: String,
            default: 'Back to dashboard'
        },
        backLinkUrl: {
            type: String,
            default: '/dashboard'
        }
    }
};
</script>

<style>

</style>
