import http from '@http';
import {download} from '@services/helper.service';
import i18n from '@/i18n';

class QuestionnaireService {
    constructor() {
        if(QuestionnaireService.instance) {
            return QuestionnaireService.instance;
        }

        QuestionnaireService.instance = this;
    }

    getQuestionnaires(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/questionnaires`, { params: queryObj }).then(res => res.data);
    }

    getQuestionnaire(kindergartenId, id) {
        return http.get(`/api/kindergartens/${kindergartenId}/questionnaires/${id}`).then(res => res.data);
    }

    getQuestionnaireQuestions(kindergartenId, survey_id, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/questionnaires/${survey_id}/questions`, { params: queryObj }).then(res => res.data);
    }

    fillQuestionnaireQuestion(kindergartenId, survey_id, question_id, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/questionnaires/${survey_id}/answer/${question_id}`, data).then(res => res.data);
    }

    submitQuestionnaire(kindergartenId, id) {
        return http.put(`/api/kindergartens/${kindergartenId}/questionnaires/${id}/submit`).then(res => res.data);
    }

    unSubmitQuestionnaire(kindergartenId, id) {
        return http.put(`/api/kindergartens/${kindergartenId}/questionnaires/${id}/unsubmit`).then(res => res.data);
    }

    getQuestionnaireResults(kindergartenId, surveyId) {
        return http.get(`/api/kindergartens/${kindergartenId}/surveys/managed-questionnaires/answers/${surveyId}`).then(res => res.data);
    }

    getQuestionnaireAnswers(kindergartenId, surveyId) {
        return http.get(`/api/kindergartens/${kindergartenId}/questionnaires/${surveyId}/answers`).then(res => res.data);
    }

    getResultsXLS(kindergartenId, surveyId, name) {
        return http.download(`/api/kindergartens/${kindergartenId}/questionnaires/${surveyId}/xls`).then(res => {
            const file = {
                mime_type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                name: `${i18n.t('Questionnaire')} ${name}.xlsx`
            };
            download(res.data, file);
        });
    }

}

export default new QuestionnaireService();
