export const SET_COURSE_TEACHERS = 'SET_COURSE_TEACHERS';
export const SET_COURSE_CHILDREN = 'SET_COURSE_CHILDREN';
export const SET_COURSES_SETTINGS = 'SET_COURSES_SETTINGS';
export const ADD_COURSE_TEACHERS = 'ADD_COURSE_TEACHERS';
export const REMOVE_COURSE_TEACHER = 'REMOVE_COURSE_TEACHER';
export const ADD_COURSE_CHILDREN = 'ADD_COURSE_CHILDREN';
export const REMOVE_COURSE_CHILD = 'REMOVE_COURSE_CHILD';
export const GET_COURSE_CHILDREN = 'GET_COURSE_CHILDREN';
export const GET_COURSE_TEACHERS = 'GET_COURSE_TEACHERS';
export const GET_COURSE_SETTINGS = 'GET_COURSE_SETTINGS';
export const GET_AVAILABLE_CHILDREN = 'GET_AVAILABLE_CHILDREN';
export const SET_AVAILABLE_CHILDREN = 'SET_AVAILABLE_CHILDREN';
export const UPDATE_COURSE_SETTING = 'UPDATE_COURSE_SETTING';
export const UPDATE_ALL_COURSE_SETTINGS = 'UPDATE_ALL_COURSE_SETTINGS';
export const SET_COURSE_KINDERGARTEN_SETTINGS = 'SET_COURSE_KINDERGARTEN_SETTINGS';
export const UPDATE_COURSE_MEALS = 'UPDATE_COURSE_MEALS';
export const UPDATE_ALL_COURSE_MEALS = 'UPDATE_ALL_COURSE_MEALS';
export const UPDATE_COURSE_ABSENCES = 'UPDATE_COURSE_ABSENCES';
export const UPDATE_ALL_COURSE_ABSENCES = 'UPDATE_ALL_COURSE_ABSENCES';
export const UPDATE_COURSE_PRESENCES = 'UPDATE_COURSE_PRESENCES';
export const UPDATE_ALL_COURSE_PRESENCES = 'UPDATE_ALL_COURSE_PRESENCES';
export const GET_DIARY_STATUSES = 'GET_DIARY_STATUSES';
export const SET_DIARY_STATUSES = 'SET_DIARY_STATUSES';
export const ADD_DIARY_STATUS = 'ADD_DIARY_STATUS';
export const UPDATE_DIARY_STATUS = 'UPDATE_DIARY_STATUS';
export const GET_AVAILABLE_SUMMER_CHILDREN = 'GET_AVAILABLE_SUMMER_CHILDREN';
export const UPDATE_COURSE_CHILD = 'UPDATE_COURSE_CHILD';
export const UPDATE_COURSE_TEACHER = 'UPDATE_COURSE_TEACHER';
