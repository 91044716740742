import {
    ADD_CHILD,
    ADD_EMPLOYEE,
    GET_KINDERGARTEN_ARCHIVED_EMPLOYEES,
    GET_KINDERGARTEN_CHILDREN,
    GET_KINDERGARTEN_CHILDREN_LIST,
    GET_KINDERGARTEN_CONTACTS,
    GET_KINDERGARTEN_EMPLOYEES,
    GET_KINDERGARTEN_EMPLOYEES_LIST,
    GET_KINDERGARTEN_GUARDIANS,
    GET_KINDERGARTEN_GUARDIANS_LIST,
    GET_KINDERGARTEN_LANGUAGES,
    GET_KINDERGARTEN_LOGO,
    GET_KINDERGARTEN_SETTINGS,
    GET_SYSTEM_PERMISSIONS,
    GET_TYPE_PERMISSIONS,
    REMOVE_CHILD,
    REMOVE_EMPLOYEE,
    SAVE_EMPLOYEE,
    SET_EMPLOYEE,
    SET_KINDERGARTEN,
    SET_KINDERGARTEN_CHILDREN,
    SET_KINDERGARTEN_CHILDREN_LIST,
    SET_KINDERGARTEN_CONTACTS,
    SET_KINDERGARTEN_EMPLOYEES,
    SET_KINDERGARTEN_GUARDIANS,
    SET_KINDERGARTEN_GUARDIANS_LIST,
    SET_KINDERGARTEN_LANGUAGES,
    SET_KINDERGARTEN_LOGO,
    SET_KINDERGARTEN_NAME,
    SET_KINDERGARTEN_SETTING,
    SET_KINDERGARTEN_SETTINGS,
    SET_MONTH_WORK_DAY_COUNT,
    SET_SYSTEM_PERMISSIONS,
    SET_TYPE_PERMISSIONS,
    STORE_CHILD,
    UPDATE_ACTIVE_KINDERGARTEN,
    UPDATE_KINDERGARTEN_CONTACTS,
    RESET_KINDERGARTEN_STATE,
    UPDATE_KINDERGARTEN_SETTINGS
} from './actions';
import Vue from 'vue';
import KindergartenService from '@services/kindergarten.service';
import EmployeeService from '@services/employee.service';
import ChildService from '@services/child.service';
import {
    kindergartenCoursesActions, kindergartenCoursesGetters,
    kindergartenCoursesMutations,
    kindergartenCoursesState
} from '@/store/modules/kindergarten/courses';

const getDefaultState = () => {
    return {
        ...kindergartenCoursesState,
        contacts: {
            address: {}
        },
        settings: [],
        employees: [],
        children: {},
        children_list: [],
        type_permissions: [],
        systemPermissions: {},
        //contains paginated data
        guardians: {},
        //contains all guardians with minimal data
        guardians_list: [],
        name: '',
        selected_kindergarten_id: null,
        logo: '',
        work_day_count: 0,
        languages: [],
        activeKindergarten: null,
    };
};
const state = getDefaultState();

const mutations = {
    ...kindergartenCoursesMutations,
    [RESET_KINDERGARTEN_STATE](state) {
        Object.assign(state, getDefaultState());
        localStorage.removeItem('kindergarten_id');
    },
    [SET_KINDERGARTEN](state, data) {
        state.name = data.name;
        state.selected_kindergarten_id = data.id;
        state.activeKindergarten = data;
        localStorage.setItem('kindergarten_id', data.id);
        localStorage.removeItem('organization_id');
        localStorage.removeItem('supplier_id');
    },
    [SET_KINDERGARTEN_CONTACTS](state, data) {
        state.contacts = data;
    },
    [SET_KINDERGARTEN_EMPLOYEES](state, data) {
        state.employees = data;
    },
    [SET_KINDERGARTEN_SETTINGS](state, data) {
        state.settings = data;
    },
    [SET_KINDERGARTEN_SETTING](state, setting) {
        const index = state.settings.findIndex(s => s.id === setting.id);
        if(index !== -1) {
            Vue.set(state.settings, index, setting);
        } else {
            state.settings.push(setting);
        }
    },
    [SET_KINDERGARTEN_CHILDREN](state, data) {
        state.children = data;
    },
    [SET_KINDERGARTEN_GUARDIANS](state, data) {
        state.guardians = data;
    },
    [SET_KINDERGARTEN_GUARDIANS_LIST](state, data) {
        state.guardians_list = data;
    },
    [SET_KINDERGARTEN_CHILDREN_LIST](state, data) {
        state.children_list = data;
    },

    [SET_TYPE_PERMISSIONS](state, data) {
        state.type_permissions = data;
    },
    [ADD_CHILD](state, child) {
        if(state.children.data) {
            state.children.data.push(child);
            state.children.total = state.children.total+1;
        }
        state.children_list.push(child);
    },
    [REMOVE_CHILD](state, childId) {
        const filtered = state.children.data.filter(c => c.id !== childId);
        Vue.set(state.children, 'data', [...filtered]);
        state.children.total = state.children.total-1;
    },
    [ADD_EMPLOYEE](state, employee) {
        state.employees.push(employee);
    },
    [SET_KINDERGARTEN_LOGO](state, data){
        state.activeKindergarten = {...state.activeKindergarten, logo: data};
    },
    [SET_KINDERGARTEN_NAME](state, name) {
        state.name = name;
    },
    [SET_EMPLOYEE](state, employee) {
        let index = state.employees.findIndex(e => e.id === employee.id);
        if(index !== -1) {
            Vue.set(state.employees, index, employee);
        }
    },
    [REMOVE_EMPLOYEE](state, employeeId) {
        const filtered = state.employees.filter(e => e.id !== employeeId);
        Vue.set(state, 'employees', [...filtered]);
    },
    [SET_MONTH_WORK_DAY_COUNT](state, count) {
        state.work_day_count = count;
    },
    [SET_SYSTEM_PERMISSIONS](state, permissions) {
        // state.permissions = permissions;
        Vue.set(state.systemPermissions, 'employee', permissions.employee);
        Vue.set(state.systemPermissions, 'guardian', permissions.guardian);
    },
    [SET_KINDERGARTEN_LANGUAGES](state, languages) {
        state.languages = languages;
    }
};

const actions = {
    ...kindergartenCoursesActions,
    [RESET_KINDERGARTEN_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [GET_KINDERGARTEN_LOGO]({commit}, data) {
        commit(SET_KINDERGARTEN_LOGO, data);
    },
    [GET_KINDERGARTEN_CONTACTS]({ commit, getters }) {
        commit('START_CONTENT_LOADER');
        return KindergartenService.getKindergartenContacts(getters.activeKindergartenId)
            .then(res => {
                commit(SET_KINDERGARTEN_CONTACTS, res);
                commit('STOP_CONTENT_LOADER');
            });
    },
    [GET_KINDERGARTEN_SETTINGS]({commit, getters}, data) {
        if (getters.kindergartenSettings.length > 2) {
            return getters.kindergartenSettings;
        }

        commit('START_CONTENT_LOADER');
        return KindergartenService.getKindergartenSettings(data).then(res => {
            commit(SET_KINDERGARTEN_SETTINGS, res);
            commit('STOP_CONTENT_LOADER');
        });
    },
    [UPDATE_KINDERGARTEN_SETTINGS]({commit, getters}, form) {
        return KindergartenService.updateKindergartenSettings(getters.activeKindergartenId, form).then(res => {
            commit(SET_KINDERGARTEN_SETTINGS, res);
        });
    },
    [UPDATE_KINDERGARTEN_CONTACTS]({commit, getters}, form) {
        return KindergartenService.updateKindergartenContacts(getters.activeKindergartenId, form).then(res => {
            if(res.name !== undefined) {
                commit(SET_KINDERGARTEN_NAME, res.name);
                delete(res.name);
            }
            commit(SET_KINDERGARTEN_CONTACTS, res);
        });
    },
    [GET_KINDERGARTEN_EMPLOYEES]({commit, getters}, queryObj) {
        return KindergartenService.getKindergartenEmployees(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_KINDERGARTEN_EMPLOYEES, res.employees);
            commit(SET_MONTH_WORK_DAY_COUNT, res.work_day_count);
            commit('SET_KINDERGARTEN_ROLES', res.roles);
        });
    },
    [GET_KINDERGARTEN_EMPLOYEES_LIST]({commit, getters}) {
        return KindergartenService.getKindergartenEmployeesList(getters.activeKindergartenId).then(res => {
            commit(SET_KINDERGARTEN_EMPLOYEES, res);
        });
    },
    [GET_KINDERGARTEN_ARCHIVED_EMPLOYEES]({commit, getters}, queryObj) {
        return KindergartenService.getKindergartenArchivedEmployees(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_KINDERGARTEN_EMPLOYEES, res.employees);
            commit('SET_KINDERGARTEN_ROLES', res.roles);
        });
    },
    [GET_KINDERGARTEN_CHILDREN]({commit, getters}, queryObj) {
        commit('START_CONTENT_LOADER');
        return KindergartenService.getKindergartenChildren(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_KINDERGARTEN_CHILDREN, res);
            commit('STOP_CONTENT_LOADER');
        });
    },
    [GET_KINDERGARTEN_CHILDREN_LIST]({commit, getters}) {
        return KindergartenService.getKindergartenChildrenList(getters.activeKindergartenId).then(res => {
            commit(SET_KINDERGARTEN_CHILDREN_LIST, res);
        });
    },
    [GET_KINDERGARTEN_GUARDIANS]({commit, getters}, queryObj) {
        commit('START_CONTENT_LOADER');
        return KindergartenService.getKindergartenGuardians(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_KINDERGARTEN_GUARDIANS, res);
            commit('STOP_CONTENT_LOADER');
        });
    },
    [GET_KINDERGARTEN_GUARDIANS_LIST]({commit, getters}) {
        return KindergartenService.getKindergartenGuardiansList(getters.activeKindergartenId).then(res => {
            commit(SET_KINDERGARTEN_GUARDIANS_LIST, res);
        });
    },
    [GET_TYPE_PERMISSIONS]({commit, getters}, type) {
        return KindergartenService.getTypePermissions(getters.activeKindergartenId, type).then(res => {
            commit(SET_TYPE_PERMISSIONS, res);
        });
    },
    [GET_SYSTEM_PERMISSIONS]({commit, getters}) {
        return KindergartenService.getPermissions(getters.activeKindergartenId).then(res => {
            commit(SET_SYSTEM_PERMISSIONS, res);
        });
    },
    [UPDATE_ACTIVE_KINDERGARTEN]({commit}, data) {
        commit(SET_KINDERGARTEN, data);
    },
    [SAVE_EMPLOYEE]({commit, getters}, data) {
        return EmployeeService.store(getters.activeKindergartenId, data).then(res => {
            commit(ADD_EMPLOYEE, res);
        });
    },
    [STORE_CHILD]({commit, getters}, data) {
        return ChildService.store(getters.activeKindergartenId, data).then(res => {
            if(!res.status) {
                commit(ADD_CHILD, res);
            }

            return res;
        });
    },
    [GET_KINDERGARTEN_LANGUAGES]({commit, getters}) {
        return KindergartenService.getEnabledLanguages(getters.activeKindergartenId).then(res => {
            commit(SET_KINDERGARTEN_LANGUAGES, res);
        });
    }
};

const getters = {
    ...kindergartenCoursesGetters,
    kindergartenContacts: state => {
        return state.contacts;
    },
    kindergartenSettings: state => {
        return state.settings || [];
    },
    kindergartenSetting: state => setting => state.settings.find(s => s.setting === setting),
    kindergartenName: state => {
        return state.name;
    },
    kindergartenEmployees: state => {
        return state.employees;
    },
    kindergartenChildren: state => {
        return state.children || [];
    },
    kindergartenGuardians: state => {
        return state.guardians;
    },
    kindergartenGuardiansList: state => {
        return state.guardians_list;
    },
    kindergartenChildrenList: state => {
        return state.children_list;
    },
    typePermissions: state => {
        return state.type_permissions;
    },
    activeKindergartenId: state => {
        return state.selected_kindergarten_id;
    },
    kindergartenLogoPic: state => {
        return state.logo;
    },

    monthWorkingDays: state => state.work_day_count,
    systemPermissions: state => state.systemPermissions,
    kindergartenLanguages: state => state.languages,
    activeKindergarten: state => state.activeKindergarten
};

export default {
    state,
    mutations,
    actions,
    getters
};
