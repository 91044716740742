import ChildService from '@services/child.service';
import Vue from 'vue';
import {
    GET_CHILD_ABSENCE_CALENDAR,
    SET_CHILD_ABSENCE_CALENDAR,
    SET_CHILD_ABSENCE_CALENDAR_LOCK,
    SET_CHILD_ABSENCE_DATA,
    STORE_CHILD_CALENDAR_DAY,
    STORE_CHILD_CALENDAR_DAY_COMMENT,
    SET_CHILD_CALENDAR_DAY,
    SET_CHILD_ABSENCE_CALENDAR_HOLIDAYS,
    STORE_CHILD_CALENDAR_DAYS,
    SET_CHILD_ABSENCE_DEFAULT_STATUS,
    STORE_CHILD_CALENDAR_TIMES,
    SET_CHILD_CALENDAR_ACCESS,
    SET_CHILD_ABSENCE_CALENDAR_COMMENT_LOCK,
    STORE_CHILD_CALENDAR_STATUS,
    SET_CHILD_ABSENCE_CALENDAR_WORKDAY_WEEKEND,
    SET_CHILD_ABSENCE_CALENDAR_WORKDAY_HOLIDAY,
} from './actions';


const getDefaultState = () => {
    return {
        absenceCalendarData: [],
        absenceChildData: {},
        absenceLockDate: null,
        absenceCommentLockDate: null,
        absenceCalendarHolidays: [],
        defaultStatus: null,
        access: null,
        workday_weekend: null,
        workday_holiday: null,
    };
};

const state = getDefaultState();

const mutations = {
    [SET_CHILD_ABSENCE_CALENDAR](state, data) {
        state.absenceCalendarData = data;
    },
    [SET_CHILD_ABSENCE_CALENDAR_LOCK](state, date) {
        state.absenceLockDate = date;
    },
    [SET_CHILD_ABSENCE_CALENDAR_COMMENT_LOCK](state, date) {
        state.absenceCommentLockDate = date;
    },
    [SET_CHILD_ABSENCE_DATA](state, data) {
        state.absenceChildData = data;
    },
    [SET_CHILD_CALENDAR_DAY](state, day) {
        let index = state.absenceCalendarData.findIndex(c => c.date === day.date);
        if(index !== -1) {
            Vue.set(state.absenceCalendarData, index, day);
        } else {
            state.absenceCalendarData.push(day);
        }
    },
    [SET_CHILD_ABSENCE_CALENDAR_HOLIDAYS] (state, data) {
        state.absenceCalendarHolidays = data;
    },
    [SET_CHILD_ABSENCE_DEFAULT_STATUS] (state, data) {
        state.defaultStatus = data;
    },
    [SET_CHILD_CALENDAR_ACCESS](state, access) {
        state.access = access;
    },
    [SET_CHILD_ABSENCE_CALENDAR_WORKDAY_WEEKEND](state, workday_weekend) {
        state.workday_weekend = workday_weekend;
    },
    [SET_CHILD_ABSENCE_CALENDAR_WORKDAY_HOLIDAY](state, workday_holiday) {
        state.workday_holiday = workday_holiday;
    },
};

const actions = {
    [GET_CHILD_ABSENCE_CALENDAR]({commit, getters}, data) {
        commit(SET_CHILD_ABSENCE_CALENDAR, []);
        commit(SET_CHILD_ABSENCE_CALENDAR_LOCK, null);
        commit(SET_CHILD_ABSENCE_CALENDAR_COMMENT_LOCK, null);
        commit(SET_CHILD_ABSENCE_CALENDAR_HOLIDAYS, []);
        commit(SET_CHILD_ABSENCE_DEFAULT_STATUS, null);
        commit(SET_CHILD_CALENDAR_ACCESS, null);
        commit(SET_CHILD_ABSENCE_CALENDAR_WORKDAY_WEEKEND, null);
        commit(SET_CHILD_ABSENCE_CALENDAR_WORKDAY_HOLIDAY, null);
        return ChildService.getChildCalendar(getters.activeKindergartenId, data.childId, data).then(res => {
            commit(SET_CHILD_ABSENCE_CALENDAR, res.days);
            commit(SET_CHILD_ABSENCE_DATA, res.child_data);
            commit(SET_CHILD_ABSENCE_CALENDAR_LOCK, res.lock_date);
            commit(SET_CHILD_ABSENCE_CALENDAR_COMMENT_LOCK, res.comment_date);
            commit(SET_CHILD_ABSENCE_CALENDAR_HOLIDAYS, res.holidays);
            commit(SET_CHILD_ABSENCE_DEFAULT_STATUS, res.default_status);
            commit(SET_CHILD_CALENDAR_ACCESS, res.access);
            commit(SET_CHILD_ABSENCE_CALENDAR_WORKDAY_WEEKEND, res.workday_weekend);
            commit(SET_CHILD_ABSENCE_CALENDAR_WORKDAY_HOLIDAY, res.workday_holiday);
        });
    },
    [STORE_CHILD_CALENDAR_STATUS]({commit, getters}, data) {
        return ChildService.saveChildCalendarStatus(getters.activeKindergartenId, data.childId, data).then(res => {
            commit(SET_CHILD_CALENDAR_DAY, res);
        });
    },
    [STORE_CHILD_CALENDAR_DAY_COMMENT]({commit, getters}, data) {
        return ChildService.saveChildCalendarComment(getters.activeKindergartenId, data.childId, data).then(res => {
            commit(SET_CHILD_CALENDAR_DAY, res);
        });
    },
    [STORE_CHILD_CALENDAR_DAYS]({commit, getters}, data) {
        return ChildService.saveChildCalendarDays(getters.activeKindergartenId, data.childId, data.form).then(res => {
            res.forEach(day => {
                commit(SET_CHILD_CALENDAR_DAY, day);
            });
        });
    },
    [STORE_CHILD_CALENDAR_DAY]({commit, getters}, data) {
        return ChildService.saveChildCalendarDay(getters.activeKindergartenId, data.childId, data.form).then(res => {
            commit(SET_CHILD_CALENDAR_DAY, res);
        });
    },
    [STORE_CHILD_CALENDAR_TIMES]({commit, getters}, data) {
        return ChildService.saveChildCalendarTimes(getters.activeKindergartenId, data.childId, data.form).then(res => {
            commit(SET_CHILD_CALENDAR_DAY, res);
        });
    }
};

const getters = {
    absenceCalendar: state => state.absenceCalendarData,
    absenceChildData: state => state.absenceChildData,
    absenceLockDate: state => state.absenceLockDate,
    absenceCommentLockDate: state => state.absenceCommentLockDate,
    absenceCalendarHolidays: state => state.absenceCalendarHolidays,
    absenceCalendarDefaultStatus: state => state.defaultStatus,
    absenceCalendarAccess: state => state.access,
    absenceCalendarWorkdayWeekend: state => state.workday_weekend,
    absenceCalendarWorkdayHoliday: state => state.workday_holiday,
};

export default {
    state,
    mutations,
    actions,
    getters
};