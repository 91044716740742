<template>
    <button
        :id="id ? id : ''"
        :disabled="isSubmitLoading || disabled"
        :class="[{'e3-spinner' : isSpinLoading}, `position-relative btn btn-${color} ${btnClass}`]"
        :type="buttonType"
        @click="emit">
        <span :class="{'e3-btn-opacity' : isSpinLoading}">
            <slot/>
        </span>
        <div v-if="isSpinLoading" class="d-flex position-absolute e3-btn-loading">
            <div class="spinner-border spinner-border-sm text-white" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </button>
</template>

<script>
export default {
    props: {
        isSubmitLoading: {
            type: Boolean,
            default: false
        },
        buttonType: {
            type: String,
            default: 'button'
        },
        color: {
            type: String,
            default: 'success'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        id: String,
        btnClass: {
            type: String,
            default: ''
        },
        showSpinner: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        isSpinLoading() {
            return this.isSubmitLoading && this.showSpinner;
        }
    },
    methods: {
        emit() {
            this.$emit('clicked');
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@common/styles/variables/_variables.scss";
.e3-btn-opacity {
    opacity: 0;
}
.e3-btn-loading {
    height: 16px;
    width: 16px;
    top: 50%;
    margin-top: -8px;
    left: 50%;
    margin-left: -8px
}
.btn-white {
    .spinner-border.text-white {
        color: $green !important;
    }
}
.e3-spinner.btn:disabled {
    opacity: 1;
}
</style>
