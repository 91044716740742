export const RESET_USER_STATE = 'RESET_USER_STATE';
export const RESET_USER_LOGIN = 'RESET_USER_LOGIN';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const SET_USER = 'SET_USER';
export const GET_USER_MENU = 'GET_USER_MENU';
export const SET_USER_MENU = 'SET_USER_MENU';
export const SET_USER_KINDERGARTENS = 'SET_USER_KINDERGARTENS';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_USER_PIC = 'SET_USER_PIC';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const SET_LOGGED_IN_FLAG = 'SET_LOGGED_IN_FLAG';
export const GET_USER_TARGETGROUPS = 'GET_USER_TARGETGROUPS';
export const SET_USER_TARGETGROUPS = 'SET_USER_TARGETGROUPS';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS';
export const UPDATE_USER_PRIVACY = 'UPDATE_USER_PRIVACY';
export const SET_USER_LOGIN = 'SET_USER_LOGIN';
export const GET_USER_EMPLOYEES = 'GET_USER_EMPLOYEES';
export const SET_USER_CHILDREN = 'SET_USER_CHILDREN';
export const SET_USER_ORGANIZATIONS = 'SET_USER_ORGANIZATIONS';
export const SET_USER_EMPLOYEES = 'SET_USER_EMPLOYEES';
export const UPDATE_EMPLOYEE_DATA = 'UPDATE_EMPLOYEE_DATA';
export const UPDATE_EMPLOYEE_SETTING = 'UPDATE_EMPLOYEE_SETTING';
export const SET_EMPLOYEE_SETTINGS = 'SET_EMPLOYEE_SETTINGS';
export const SET_USER_OFFICIALS = 'SET_USER_OFFICIALS';
export const UPDATE_GUARDIAN_SETTINGS = 'UPDATE_GUARDIAN_SETTINGS';
export const UPDATE_OFFICIAL_SETTINGS = 'UPDATE_OFFICIAL_SETTINGS';
export const SET_USER_GUARDIANS = 'SET_USER_GUARDIANS';
export const GET_USER_HEADER_INFO = 'GET_USER_HEADER_INFO';
export const SET_USER_SUPPLIERS = 'SET_USER_SUPPLIERS';
export const SET_USER_SUPPLIER_REPS = 'SET_USER_SUPPLIER_REPS';
export const RESET_USER_BANNER_STATE = 'RESET_USER_BANNER_STATE';
export const GET_USER_BANNERS = 'GET_USER_BANNERS';
export const SET_USER_BANNERS = 'SET_USER_BANNERS';
export const GET_USER_OAUTH_CLIENTS = 'GET_USER_OAUTH_CLIENTS';
export const SET_USER_OAUTH_CLIENTS = 'SET_USER_OAUTH_CLIENTS';
export const REVOKE_USER_OAUTH_CLIENT = 'REVOKE_USER_OAUTH_CLIENT';
export const REMOVE_USER_OAUTH_CLIENT = 'REMOVE_USER_OAUTH_CLIENT';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const GET_RECENT_USER_CART = 'GET_RECENT_USER_CART';
export const SET_RECENT_USER_CART = 'SET_RECENT_USER_CART';
export const REMOVE_FROM_RECENT_USER_CART = 'REMOVE_FROM_RECENT_USER_CART';
