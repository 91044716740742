const Register = () =>
    import(/* webpackChunkName: "auth-module" */ './Register');
const SelectRole = () =>
    import(/* webpackChunkName: "auth-module" */ './components/Role');
const RegisterOptions = () =>
    import(/* webpackChunkName: "auth-module" */ './components/Options');
const Form = () =>
    import(/* webpackChunkName: "auth-module" */ './components/Form');
const Location = () =>
    import(/* webpackChunkName: "auth-module" */ './components/Location');

export default [
    {
        path: '/auth',
        components: { unauthorized: Register },
        children: [
            {
                path: 'register',
                name: 'register.role',
                components: { register: SelectRole }
            },
            {
                path: 'register/options',
                name: 'register.options',
                components: { register: RegisterOptions }
            },
            {
                path: 'register/agree',
                name: 'register.form',
                components: { register: Form }
            },
            {
                path: 'register/country',
                name: 'register.country',
                components: { register: Location }
            },
        ]
    }
];
