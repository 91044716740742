import {
    GET_ADMIN_SURVEYS,
    SET_ADMIN_SURVEYS,
    GET_ADMIN_SURVEY,
    UPDATE_ADMIN_SURVEY,
    ADMIN_UPDATE_SURVEY_SETTINGS,
    ADMIN_STORE_QUESTION_OPTION,
    ADMIN_UPDATE_QUESTION,
    ADMIN_DELETE_QUESTION,
    ADMIN_REORDER_QUESTION,
    ADMIN_UPDATE_QUESTION_OPTION,
    ADMIN_DELETE_QUESTION_OPTION,
    ADMIN_REORDER_QUESTION_OPTION,
    ADMIN_CREATE_QUESTION, ADMIN_STORE_QUESTION,
    ADMIN_DELETE_SURVEY,
    GET_ADMIN_SURVEY_RESULTS
} from './actions';
import AdminSurveyService from '@/common/services/admin_survey.service';
import {
    ADD_QUESTION, ADD_QUESTION_OPTION, REMOVE_QUESTION, REMOVE_QUESTION_OPTION,
    SET_QUESTION, SET_QUESTION_OPTION, SET_QUESTION_OPTIONS,
    SET_QUESTIONS,
    SET_SELECTED_QUESTION,
    SET_SURVEY,
    SET_SURVEY_LOCK_DATA,
    START_SURVEY_LOADING,
    STOP_SURVEY_LOADING,
} from '@/store/modules/survey/actions';

function checkForLock(res) {
    return res.locked;
}
const defaultState = () => {
    return {
        surveys: {},
    };
};

export const surveyState = defaultState();

export const surveyMutations = {
    [SET_ADMIN_SURVEYS](state, data) {
      state.surveys = data;
    },
};

export const surveyActions = {
    [GET_ADMIN_SURVEYS]({commit}, queryObj) {
       return AdminSurveyService.getSurveys(queryObj).then(res => {
            commit(SET_ADMIN_SURVEYS, res);
        });
    },
    [GET_ADMIN_SURVEY]({commit}, surveyId) {
        commit(SET_SURVEY, null);
        return AdminSurveyService.getSurvey(surveyId).then(res => {
            commit(SET_SURVEY, res.survey);
            return res;
        });
    },
    [UPDATE_ADMIN_SURVEY]({commit}, data) {
        return AdminSurveyService.updateSurvey(data.survey_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_SURVEY, res);
            }
        });
    },
    [ADMIN_UPDATE_SURVEY_SETTINGS]({commit}, data) {
        return AdminSurveyService.updateSettings(data.survey_id, data).then(res => {
            commit(SET_SURVEY, res.survey);
            return res;
        });
    },
    async [ADMIN_CREATE_QUESTION]({dispatch}, data) {
        let questionRes = await dispatch(ADMIN_STORE_QUESTION, data.question);
        if(questionRes && questionRes !== 'locked') {
            if (data.isDuplicate) {
                await data.question.options.forEach(option => {
                    dispatch(ADMIN_STORE_QUESTION_OPTION, {
                        survey_id: data.question.survey_id,
                        question_id: questionRes.id,
                        label: option.label,
                        order: option.order
                    });
                });
            } else {
                await dispatch(ADMIN_STORE_QUESTION_OPTION, {
                    survey_id: data.question.survey_id,
                    question_id: questionRes.id,
                    label: data.option,
                    order: 1
                });
            }
        }

        return questionRes;
    },
    [ADMIN_STORE_QUESTION]({commit}, data) {
        commit(START_SURVEY_LOADING);
        return AdminSurveyService.addQuestion(data.survey_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(ADD_QUESTION, res);
                return res;
            }
        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [ADMIN_UPDATE_QUESTION]({commit}, data) {
        commit(START_SURVEY_LOADING);
        return AdminSurveyService.updateQuestion(data.survey_id, data.question_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_QUESTION, res);
            }
        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [ADMIN_DELETE_QUESTION]({commit}, data) {
        commit(START_SURVEY_LOADING);
        return AdminSurveyService.deleteQuestion(data.survey_id, data.question_id).then((res) => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_SELECTED_QUESTION, null);
                commit(REMOVE_QUESTION, data.question_id);
            }
        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [ADMIN_REORDER_QUESTION]({commit}, data) {
        return AdminSurveyService.reorderQuestion(data.data.survey_id, data.data.question_id, data.data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_QUESTIONS, data.questions);
            }
        });
    },
    [ADMIN_STORE_QUESTION_OPTION]({commit}, data) {
        commit(START_SURVEY_LOADING);
        return AdminSurveyService.addQuestionOption(data.survey_id, data.question_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(ADD_QUESTION_OPTION, res);
                return res;
            }

        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [ADMIN_UPDATE_QUESTION_OPTION]({commit}, data) {
        commit(START_SURVEY_LOADING);
        return AdminSurveyService.updateQuestionOption(data.survey_id, data.question_id, data.option_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_QUESTION_OPTION, res);
            }
        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [ADMIN_DELETE_QUESTION_OPTION]({commit}, data) {
        commit(START_SURVEY_LOADING);
        return AdminSurveyService.deleteQuestionOption(data.survey_id, data.question_id, data.option_id).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(REMOVE_QUESTION_OPTION, data);
            }
        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [ADMIN_REORDER_QUESTION_OPTION]({commit}, data) {
        return AdminSurveyService.reorderQuestionOption(data.survey_id, data.question_id, data.option_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_QUESTION_OPTIONS, data);
            }
        });
    },
    [ADMIN_DELETE_SURVEY]( {commit}, id) {
        return AdminSurveyService.deleteSurvey(id).then(() => {
            commit(SET_SURVEY, null);
        });
    },
    [GET_ADMIN_SURVEY_RESULTS]({commit}, id) {
        return AdminSurveyService.getAnswers(id).then(res => {
            commit(SET_SURVEY, res);
        });
    }
};

export const surveyGetters = {
    adminSurveys: state => state.surveys
};