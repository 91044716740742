import {
    GET_ADMIN_KINDERGARTEN_SETTING_TEMPLATES,
    SET_ADMIN_KINDERGARTEN_SETTING_TEMPLATES,
    SET_ADMIN_KINDERGARTEN_SETTING_TEMPLATE,
    UPDATE_ADMIN_KINDERGARTEN_SETTING_TEMPLATE,

    GET_ADMIN_CHILDDEV_AGE_GROUPS,
    SET_ADMIN_CHILDDEV_AGE_GROUPS,
    STORE_ADMIN_CHILDDEV_AGE_GROUP,
    ADD_ADMIN_CHILDDEV_AGE_GROUP,
    UPDATE_ADMIN_CHILDDEV_AGE_GROUP,
    SET_ADMIN_CHILDDEV_AGE_GROUP,
    DELETE_ADMIN_CHILDDEV_AGE_GROUP,
    REMOVE_ADMIN_CHILDDEV_AGE_GROUP,

    GET_ADMIN_CHILDDEV_CATEGORIES,
    SET_ADMIN_CHILDDEV_CATEGORIES,
    STORE_ADMIN_CHILDDEV_CATEGORY,
    ADD_ADMIN_CHILDDEV_CATEGORY,
    UPDATE_ADMIN_CHILDDEV_CATEGORY,
    SET_ADMIN_CHILDDEV_CATEGORY,
    DELETE_ADMIN_CHILDDEV_CATEGORY,
    REMOVE_ADMIN_CHILDDEV_CATEGORY,
    SET_ADMIN_CHILDDEV_CATEGORIES_NEED_RELOAD,

    STORE_ADMIN_CHILDDEV_GOAL,
    UPDATE_ADMIN_CHILDDEV_GOAL,
    SET_ADMIN_CHILDDEV_GOAL,
    ADD_ADMIN_CHILDDEV_GOAL,
    DELETE_ADMIN_CHILDDEV_GOAL,
    REMOVE_ADMIN_CHILDDEV_GOAL,

    SELECT_ADMIN_CHILDDEV_COUNTRY,
} from './actions';

import Vue from 'vue';
import AdminChildDevService from '@common/services/admin_childdev.service';

function removeCategory(category, state) {
    if(category.parent_id) {
        const categoryIndex = state.childdevSubcategories.findIndex(sc => sc.id === category.id);
        Vue.delete(state.childdevSubcategories, categoryIndex);

        //Find goals and subgoals
        const goals = state.childdevGoals.filter(g => g.category_id === category.id);
        goals.map(goal => {
            state.childdevSubgoals = state.childdevSubgoals.filter(sg => sg.parent_id !== goal.id);
        });
        state.childdevGoals = state.childdevGoals.filter(g => g.category_id !== category.id);

    } else {
        const categoryIndex = state.childdevCategories.findIndex(c => c.id === category.id);
        Vue.delete(state.childdevCategories, categoryIndex);
        state.childdevSubcategories.filter(sc => sc.parent_id === category.id).forEach(subCategory => {
           removeCategory(subCategory, state);
        });
    }
}
const defaultState = () => {
    return {
        childdevAgeGroups: [],
        childdevCategories: [],
        childdevSubcategories: [],
        childdevGoals: [],
        childdevSubgoals: [],
        childdevCategoriesNeedReload: false,
        childdevSelectedCountry: {id: null, name: '...'},

        childdevSettingsTemplates: {},
    };
};

export const childdevState = defaultState();

export const childdevMutations = {
    [SELECT_ADMIN_CHILDDEV_COUNTRY](state, country) {
        if (country.id !== state.childdevSelectedCountry.id) {
            state.childdevSelectedCountry = country;
            const params = {
                country_id: country.id,
                settings: [
                    'subgoals_enabled',
                    'goal_type_goal',
                    'goal_type_competence',
                ],
            };
            this.dispatch('GET_ADMIN_KINDERGARTEN_SETTING_TEMPLATES', params);
            this.dispatch('GET_ADMIN_CHILDDEV_CATEGORIES', {country_id: country.id});
            this.dispatch('GET_ADMIN_CHILDDEV_AGE_GROUPS', {country_id: country.id});
        }
    },

    [SET_ADMIN_KINDERGARTEN_SETTING_TEMPLATES] (state, settings) {
        state.childdevSettingsTemplates = settings;
    },
    [SET_ADMIN_KINDERGARTEN_SETTING_TEMPLATE] (state, setting) {
        if (state.childdevSettingsTemplates[setting.country_id] === undefined) {
            state.childdevSettingsTemplates[setting.country_id] = {};
        }
        Vue.set(state.childdevSettingsTemplates[setting.country_id], setting.setting, setting.value);
    },

    [SET_ADMIN_CHILDDEV_CATEGORIES_NEED_RELOAD](state, newValue) {
        state.childdevCategoriesNeedReload = newValue;
    },

    [SET_ADMIN_CHILDDEV_AGE_GROUPS](state, ageGroups) {
        state.childdevAgeGroups = ageGroups;
    },
    [ADD_ADMIN_CHILDDEV_AGE_GROUP](state, ageGroup) {
        state.childdevAgeGroups.push(ageGroup);
    },
    [SET_ADMIN_CHILDDEV_AGE_GROUP](state, ageGroup) {
        const index = state.childdevAgeGroups.findIndex(g => g.id === ageGroup.id);
        Vue.set(state.childdevAgeGroups, index, ageGroup);
    },
    [REMOVE_ADMIN_CHILDDEV_AGE_GROUP](state, ageGroup) {
        const index = state.childdevAgeGroups.findIndex(g => g.id === ageGroup.id);
        state.childdevAgeGroups.splice(index, 1);
    },

    [SET_ADMIN_CHILDDEV_CATEGORIES](state, data) {
        state.childdevCategories = data.categories;
        state.childdevSubcategories = data.subcategories;
        state.childdevGoals = data.goals;
        state.childdevSubgoals = data.subgoals;
    },
    [ADD_ADMIN_CHILDDEV_CATEGORY](state, category) {
        if (category.parent_id) {
            state.childdevSubcategories.push(category);
        } else {
            state.childdevCategories.push(category);
        }
    },
    [SET_ADMIN_CHILDDEV_CATEGORY](state, category) {
        if (category.parent_id) {

            const categoryIndex = state.childdevSubcategories.findIndex(sc => sc.id === category.id);
            Vue.set(state.childdevSubcategories, categoryIndex, category);
        } else {
            const categoryIndex = state.childdevCategories.findIndex(c => c.id === category.id);
            Vue.set(state.childdevCategories, categoryIndex, category);
        }
    },
    [REMOVE_ADMIN_CHILDDEV_CATEGORY](state, category) {
        removeCategory(category, state);
    },

    [SET_ADMIN_CHILDDEV_GOAL](state, goal) {
        const isSubGoal = goal.category_id === null && goal.parent_id !== null;
        if(isSubGoal) {
            let goalIndex = state.childdevSubgoals.findIndex(g => g.id === goal.id);
            Vue.set(state.childdevSubgoals, goalIndex, goal);
        } else {
            let goalIndex = state.childdevGoals.findIndex(g => g.id === goal.id);
            Vue.set(state.childdevGoals, goalIndex, goal);
        }
    },
    [ADD_ADMIN_CHILDDEV_GOAL](state, goal) {
        const isSubGoal = goal.category_id === null && goal.parent_id !== null;
        if(isSubGoal) {
            state.childdevSubgoals.push(goal);
        } else {
            state.childdevGoals.push(goal);
        }
    },
    [REMOVE_ADMIN_CHILDDEV_GOAL](state, goal) {
        const isSubGoal = goal.category_id === null && goal.parent_id !== null;
        if (isSubGoal) {
            let goalIndex = state.childdevSubgoals.findIndex(g => g.id === goal.id);
            Vue.delete(state.childdevSubgoals, goalIndex);
        } else {
            let goalIndex = state.childdevGoals.findIndex(g => g.id === goal.id);
            Vue.delete(state.childdevGoals, goalIndex);
            //Find any subgoals
            state.childdevSubgoals = state.childdevSubgoals.filter(sg => sg.parent_id !== goal.id);
        }
    },
};

export const childdevActions = {
    //Kindergarten setting templates
    [GET_ADMIN_KINDERGARTEN_SETTING_TEMPLATES]({commit}, queryObj) {
        return AdminChildDevService.getSettingsTemplate(queryObj).then(res => {
            commit(SET_ADMIN_KINDERGARTEN_SETTING_TEMPLATES, res);
        });
    },
    [UPDATE_ADMIN_KINDERGARTEN_SETTING_TEMPLATE]({commit}, data) {
        return AdminChildDevService.updateSettingsTemplate(data).then(() => {
            commit(SET_ADMIN_KINDERGARTEN_SETTING_TEMPLATE, data);
        });
    },

    [GET_ADMIN_CHILDDEV_AGE_GROUPS]({commit}, queryObj) {
        return AdminChildDevService.ageGroups(queryObj).then(res => {
            commit(SET_ADMIN_CHILDDEV_AGE_GROUPS, res);
        });
    },
    [STORE_ADMIN_CHILDDEV_AGE_GROUP]({commit}, data) {
        return AdminChildDevService.storeAgeGroup(data).then(res => {
            commit(ADD_ADMIN_CHILDDEV_AGE_GROUP, res);
        });
    },
    [UPDATE_ADMIN_CHILDDEV_AGE_GROUP]({commit}, ageGroup) {
        return AdminChildDevService.updateAgeGroup(ageGroup.id, ageGroup).then(res => {
            commit(SET_ADMIN_CHILDDEV_AGE_GROUP, res);
        });
    },
    [DELETE_ADMIN_CHILDDEV_AGE_GROUP]({commit}, groupId) {
        return AdminChildDevService.destroyAgeGroup(groupId).then(res => {
            commit(REMOVE_ADMIN_CHILDDEV_AGE_GROUP, groupId);
            return res;
        });
    },

    [GET_ADMIN_CHILDDEV_CATEGORIES]({commit}, queryObj) {
        return AdminChildDevService.categories(queryObj).then(res => {
            commit(SET_ADMIN_CHILDDEV_CATEGORIES, res);
            this.commit('SET_ADMIN_CHILDDEV_CATEGORIES_NEED_RELOAD', false);
        });
    },
    [STORE_ADMIN_CHILDDEV_CATEGORY]({commit}, data) {
        return AdminChildDevService.storeCategory(data).then(res => {
            commit(ADD_ADMIN_CHILDDEV_CATEGORY, res);
        });
    },
    [UPDATE_ADMIN_CHILDDEV_CATEGORY]({commit}, category) {
        return AdminChildDevService.updateCategory(category.id, category).then(res => {
            commit(SET_ADMIN_CHILDDEV_CATEGORY, res);
        });
    },
    [DELETE_ADMIN_CHILDDEV_CATEGORY]({commit}, category) {
        return AdminChildDevService.destroyCategory(category.id).then(res => {
            commit(REMOVE_ADMIN_CHILDDEV_CATEGORY, category);
            return res;
        });
    },

    [STORE_ADMIN_CHILDDEV_GOAL]({commit}, data) {
        return AdminChildDevService.storeGoal(data).then(res => {
            commit(ADD_ADMIN_CHILDDEV_GOAL, res);
        });
    },
    [UPDATE_ADMIN_CHILDDEV_GOAL]({commit}, data) {
        return AdminChildDevService.updateGoal(data.id, data).then(res => {
            commit(SET_ADMIN_CHILDDEV_GOAL, res);
        });
    },
    [DELETE_ADMIN_CHILDDEV_GOAL]({commit}, goal) {
        return AdminChildDevService.destroyGoal(goal.id).then(res => {
            commit(REMOVE_ADMIN_CHILDDEV_GOAL, goal);
            return res;
        });
    },
};

export const childdevGetters = {
    adminChilddevAgeGroups: state => state.childdevAgeGroups,
    adminChilddevCategories: state => state.childdevCategories,
    adminChildDevSubcategories: state => state.childdevSubcategories,
    adminChildDevCategorySubcategories: state => id => {
        return state.childdevSubcategories.filter(sc => sc.parent_id === id);
    },
    adminChildDevCategoryGoals: state => id => {
      return state.childdevGoals.filter(g => g.category_id === id);
    },
    adminChildDevGoalSubgoals: state => id => {
        return state.childdevSubgoals.filter(sg => sg.parent_id === id);
    },
    adminChilddevCategoriesNeedReload: state => state.childdevCategoriesNeedReload,
    adminChilddevSelectedCountry: state => state.childdevSelectedCountry,
    adminChilddevSettingTemplates: state => state.childdevSettingsTemplates,
};
