import http from '@http';

class TargetGroup {
    constructor() {
        if (TargetGroup.instance) {
            return TargetGroup.instance;
        }

        TargetGroup.instance = this;
    }

    getTargetGroup(kindergartenId, group) {
        return http.get(`/api/kindergartens/${kindergartenId}/target-groups/${group}`)
            .then(res => res.data);
    }

    createTargetGroup(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/target-groups`, data).then(res => res.data);
    }

    updateTargetGroup(kindergartenId, id , data) {
        return http.put(`/api/kindergartens/${kindergartenId}/target-groups/${id}`, data)
            .then(res => res.data);
    }

    updateMembers(kindergartenId, id, members) {
        return http.put(`/api/kindergartens/${kindergartenId}/target-groups/${id}/update-member`, members)
            .then(res => res.data);
    }

    updateConsumer(kindergartenId, id, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/target-groups/${id}/update-consumer`, data)
            .then(res => res.data);
    }
}

export default new TargetGroup();
