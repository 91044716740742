<template>
    <router-link :to="{name: 'diary', params: {course_id: notification.data.course_id, date: notification.data.date}}" class="d-flex flex-row px-3 py-2 diary-likes e3-notification-row" @click.native="navigateToDiary">
        <div class="profile-image-container">
            <ProfileImage :imageUrl="notification.creator.profile_picture" :name="notification.creator.name" size="sm"/>
        </div>
        <div class="d-flex flex-column ml-2 w-100">
            <div class="font-weight-bold">
                {{ notification.creator.name }}
                <span v-if="notification.data.additional_likes > 0">+{{ notification.data.additional_likes }}</span>
            </div>
            <div class="text-break">
                {{ truncate(notificationText, 50) }}
            </div>
        </div>
    </router-link>
</template>

<script>
import {truncate} from '@/filters';
import {eventBus} from '@/main';
export default {
    name: 'DiaryLikesNotification',
    props: {
        notification: Object,
    },
    methods: {
        truncate, // TODO: Vue3 remove
        navigateToDiary() {
            this.$router.push({
                name: 'diary',
                params: {
                    course_id: this.notification.data.course_id,
                    date: this.notification.data.date
                }
            }).catch(err => {
                if (err.name === 'NavigationDuplicated') {
                    eventBus.$emit('updateDiary', {
                        course_id: this.notification.data.course_id,
                        date: this.notification.data.date
                    });
                }
            });
        }
    },
    computed: {
        notificationText() {
            const date = this.$moment(this.notification.data.date)
                .locale(this.$store.getters.language).format('D. MMMM');
            return this.$t('Notification - New diary like', {date: date, course: this.notification.data.course_name});
        }
    }
};
</script>
