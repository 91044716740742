<template>
    <div class="e3-image-thumbnail-box">
        <div class="e3-image-thumbnail" :class="{'e3-pointer' : hasPointer}" :style="{ 'background-image': 'url(' + url + '), url(' + image + ')' }"></div>
    </div>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default: ''
        },
        hasPointer: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            image:'/images/image_placeholder_download.png',
        };
    }
};
</script>
