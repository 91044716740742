import {
    GET_KINDERGARTEN_ORDERS,
    SET_KINDERGARTEN_ORDERS,
    STORE_KINDERGARTEN_ORDER,
    GET_KINDERGARTEN_WISHLIST,
    SET_KINDERGARTEN_WISHLIST,
    SET_KINDERGARTEN_CONTACTS,
    REMOVE_FROM_WISHLIST,
    UPDATE_WISHLIST_ITEM,
    SET_WISHLIST_ITEM,
    UPDATE_SUPPLIER_WISHLIST,
    UPDATE_WISHLIST_PRICE,
    GET_SHOP_CATEGORIES,
    SET_SHOP_CATEGORIES,
    GET_USER_ORDERS,
    SET_USER_ORDERS,
    GET_USER_CART,
    SET_USER_CART,
    MY_CART_ORDER,
    REMOVE_ITEM_FROM_USER_CART,
    EDIT_USER_CART_ITEM,
    GET_SHOP_PRODUCT,
    GET_SHOP_SERVICE,
    SET_SELECTED_SHOP_ITEM,
    GET_SHOP_ITEM_COMMENTS,
    SET_SELECTED_ITEM_COMMENTS,
    STORE_SHOP_ITEM_COMMENT,
    ADD_SELECTED_ITEM_COMMENT,
    STORE_SHOP_ITEM_RATING,
    SET_SHOP_ITEM_RATING,
    GET_SUPPLIER_INFO,
    SET_SUPPLIER_INFO,
    RESET_SHOP_STATE,
    GET_SHOP_FILTERS,
    SET_SHOP_FILTERS,
    SEARCH_PRODUCTS,
    SEARCH_SERVICES,
    GET_SHOP_SUPPLIERS,
    SET_SHOP_SUPPLIERS,
    GET_POPULAR_ITEMS,
    SET_POPULAR_ITEMS,
    SET_SYSTEM_FILTERS,
    UPDATE_MY_CART_PRICE,
    UPDATE_USER_CART,
} from './actions';
import ShopService from '@services/shop.service';
import Vue from 'vue';

// function getItemCount(category, isEmployee) {
//     let counts = category.country_counts;
//     if(!counts)
//         return 0;
//     return isEmployee ? counts.items_for_all : counts.items_for_individuals;
// }
// function evaluateCategoryVisibility(category, isEmployee) {
//     category.item_count = getItemCount(category, isEmployee);
//     if(category.subcategories.length) {
//         const subCats = [];
//         category.subcategories.forEach(subCat => {
//             let count = getItemCount(subCat, isEmployee);
//             subCat.item_count = count;
//             if(count) {
//                 subCats.push(subCat);
//             }
//         });
//         category.subcategories = subCats;
//     }
//     category.visible = category.item_count || (category.subcategories.length && category.subcategories.filter(s => s.item_count > 0).length);
//
//     return category;
// }
const getDefaultState = () => {
    return {
        filters: [],
        productCategories: [],
        serviceCategories: [],
        kindergartenOrders: {},
        userOrders: {},
        wishlists: [],
        userCart: [],
        kindergartenContacts: null,
        selectedShopItem: {},
        supplierInfo: {},
        shopOrderValues: [
            {code: 'price_asc', name: 'Price ascending'},
            {code: 'price_desc', name: 'Price descending'},
        ],
        selectedFilters: [],
        suppliers: [],
        popularProducts: [],
        popularServices: [],
        systemFilters: null,
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_SHOP_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_SHOP_FILTERS](state, data) {
        state.filters = data;
    },
    [SET_SYSTEM_FILTERS](state, data) {
        state.systemFilters = data;
    },
    [SET_SHOP_CATEGORIES](state, data) {
        state.productCategories = data.filter(c => c.type === 'product');
        state.serviceCategories = data.filter(c => c.type === 'service');
    },
    [SET_KINDERGARTEN_WISHLIST](state, data) {
        const whislists = data.map(supplier => {
            supplier.items = supplier.items.map(item => {
                item.userSelected = true;
                return item;
            });
            return supplier;
        });
        state.wishlists = whislists;
    },
    [SET_KINDERGARTEN_CONTACTS](state, data) {
        state.kindergartenContacts = data;
    },
    [SET_WISHLIST_ITEM](state, data) {
        let supplier = state.wishlists[data.supplier_index];
        const index = supplier.items.findIndex(p => p.id === data.item.id);

        Vue.set(supplier.items, index, {...supplier.items[index], ...data.item});
        Vue.set(state.wishlists, data.supplier_index, supplier);
    },
    [UPDATE_SUPPLIER_WISHLIST](state, data) {
        if(data.wishlist.items.length) {
            Vue.set(state.wishlists, data.supplier_index, data.wishlist);
        } else {
            state.wishlists.splice(data.supplier_index, 1);
        }
    },
    [REMOVE_FROM_WISHLIST](state, data) {
        let supplier = state.wishlists[data.supplier_index];

        const index = supplier.items.findIndex(p => p.id === data.item_id);
        supplier.items.splice(index, 1);
        if(supplier.items.length) {
            Vue.set(state.wishlists, data.supplier_index, supplier);
        } else {
            state.wishlists.splice(data.supplier_index, 1);
        }

    },
    [SET_KINDERGARTEN_ORDERS](state, data) {
        state.kindergartenOrders = data;
    },
    [SET_USER_ORDERS](state, data) {
        state.userOrders = data;
    },
    [SET_USER_CART](state, data) {
        state.userCart = data;
    },
    [UPDATE_USER_CART](state, data) {
        if(data.cart.items.length) {
            Vue.set(state.userCart, data.supplier_index, data.cart);
        } else {
            state.userCart.splice(data.supplier_index, 1);
        }
    },
    [REMOVE_ITEM_FROM_USER_CART](state, data) {
        let providerIndex = null;
        let itemIndex = null;

        providerIndex = state.userCart.findIndex(p => p.id == data.providerId);
        itemIndex = state.userCart[providerIndex].items.findIndex(i => i.id == data.itemId);

        state.userCart[providerIndex].items.splice(itemIndex, 1);

        if (state.userCart[providerIndex].items.length == 0) {
            state.userCart.splice(providerIndex, 1);
        }
    },
    [EDIT_USER_CART_ITEM](state, data) {
        let providerIndex = null;
        let itemIndex = null;

        providerIndex = state.userCart.findIndex(p => p.id == data.providerId);
        itemIndex = state.userCart[providerIndex].items.findIndex(i => i.id == data.itemId);

        state.userCart[providerIndex].items.splice(itemIndex, 1, data.item);
    },
    [SET_SELECTED_SHOP_ITEM](state, item) {
        state.selectedShopItem = item;
    },
    [SET_SELECTED_ITEM_COMMENTS](state, comments) {
        state.selectedShopItem.comments = comments;
    },
    [ADD_SELECTED_ITEM_COMMENT](state, comment) {
        state.selectedShopItem.comments.data.unshift(comment);
        if (state.selectedShopItem.comments.data.length > 5) {
            state.selectedShopItem.comments.data.pop();
        }
        state.selectedShopItem.comments.total += 1;
        state.selectedShopItem.comments.last_page = Math.ceil(state.selectedShopItem.comments.total / state.selectedShopItem.comments.per_page);

        // Vue.set(state.selectedShopItem.comments, 'comments_count', state.selectedShopItem.comments.comments_count + 1);
    },
    [SET_SHOP_ITEM_RATING](state, rating) {
        state.selectedShopItem.rating = rating;
    },
    [SET_SUPPLIER_INFO](state, supplier) {
        state.supplierInfo = supplier;
    },
    [SET_SHOP_SUPPLIERS](state, data) {
        state.suppliers = data;
    },
    [SET_POPULAR_ITEMS](state, data) {
        state.popularProducts = data.products;
        state.popularServices = data.services;
    }
};
const actions = {
    [GET_SHOP_CATEGORIES] ({commit}) {
        return ShopService.categories().then(res => {
            commit(SET_SHOP_CATEGORIES, res);
            return res;
        });
    },
    [GET_KINDERGARTEN_WISHLIST]({commit, getters}, status) {
        return ShopService.wishlist(getters.activeKindergartenId, {status}).then(res => {
            commit(SET_KINDERGARTEN_WISHLIST, res.wishlists);
            commit(SET_KINDERGARTEN_CONTACTS, res.contacts);
        });
    },
    [UPDATE_WISHLIST_ITEM]({commit, getters}, data) {
        return ShopService.editWishlistItem(getters.activeKindergartenId, data.wishlistId, data.form).then(res => {
            commit(SET_WISHLIST_ITEM, {supplier_index: data.supplierIndex, item: res});
        });
    },
    [UPDATE_WISHLIST_PRICE]({commit, state}, data) {
        const supplier = state.wishlists[data.supplier_index];
        if (supplier) {
            let total = 0;
            supplier.items.map((item) => {
                if (item.userSelected) {
                    if (item.product) {
                        total += parseFloat(item.qty) * parseFloat(item.product.kindergarten_sales_price_gross);
                    }
                    if (item.service) {
                        total += parseFloat(item.qty) * item.service.kindergarten_sales_price_gross;
                    }
                }
            });
            commit(UPDATE_SUPPLIER_WISHLIST, {
                supplier_index: data.supplier_index,
                wishlist: {...supplier, sum: total.toFixed(2)}
            });
        }
    },
    [REMOVE_FROM_WISHLIST]({commit, getters}, data) {
        return ShopService.removeFromWishlist(getters.activeKindergartenId, data.item_id).then(() => {
            commit(REMOVE_FROM_WISHLIST, data);
        });
    },
    [GET_KINDERGARTEN_ORDERS]({commit, getters}, queryObj) {
        return ShopService.kindergartenOrders(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_KINDERGARTEN_ORDERS, res);
        });
    },
    [STORE_KINDERGARTEN_ORDER]({getters, commit}, form) {
        return ShopService.storeKindergartenOrder(getters.activeKindergartenId, form).then(res => {
            commit(SET_KINDERGARTEN_WISHLIST, res);
        });
    },
    [UPDATE_MY_CART_PRICE]({commit}, data) {
        const provider = state.userCart[data.supplier_index];
        if (provider) {
            let total = 0;
            provider.items.map((item) => {
                if (item.product) {
                    total += parseFloat(item.qty) * parseFloat(item.product.sales_price_gross);
                }
                if (item.service) {
                    total += parseFloat(item.qty) * item.service.sales_price_gross;
                }
            });
            commit(UPDATE_USER_CART, {
                supplier_index: data.supplier_index,
                cart: {...provider, sum: total.toFixed(2)}
            });
        }
    },
    [MY_CART_ORDER]({commit}, form) {
        return ShopService.storeUserOrder(form).then(res => {
            commit(SET_USER_CART, res.cart);
            commit('SET_RECENT_USER_CART', res.recent);
        });
    },
    [GET_USER_ORDERS]({commit}, queryObj) {
        return ShopService.userOrders(queryObj).then(res => {
            commit(SET_USER_ORDERS, res);
        });
    },
    [GET_USER_CART]({commit}) {
        return ShopService.userCart().then(res => {
            commit(SET_USER_CART, res);
        });
    },
    [GET_SHOP_PRODUCT]({commit, getters}, slug) {
        commit(SET_SELECTED_SHOP_ITEM, {});
        if(getters.activeKindergartenId) {
            return ShopService.kindergartenProduct(getters.activeKindergartenId, slug).then(res => {
                commit(SET_SELECTED_SHOP_ITEM, res);
            }).catch(err => {
                throw err;
            });
        } else {
            return ShopService.product(slug).then(res => {
                commit(SET_SELECTED_SHOP_ITEM, res);
            }).catch(err => {
                throw err;
            });
        }

    },
    [GET_SHOP_SERVICE]({commit, getters}, slug) {
        commit(SET_SELECTED_SHOP_ITEM, {});
        if(getters.activeKindergartenId) {
            return ShopService.kindergartenService(getters.activeKindergartenId, slug).then(res => {
                commit(SET_SELECTED_SHOP_ITEM, res);
            });
        } else {
            return ShopService.service(slug).then(res => {
                commit(SET_SELECTED_SHOP_ITEM, res);
            });
        }

    },
    [GET_SHOP_ITEM_COMMENTS]({commit, getters}, data) {
        if(data.type === 'product') {
            if(getters.activeKindergartenId) {
                return ShopService.getKindergartenProductComments(getters.activeKindergartenId, data.slug, data.queryObj).then(res => {
                    commit(SET_SELECTED_ITEM_COMMENTS, res);
                });
            } else {
                return ShopService.getProductComments(data.slug, data.queryObj).then(res => {
                    commit(SET_SELECTED_ITEM_COMMENTS, res);
                });
            }

        }
        if(data.type === 'service') {
            if(getters.activeKindergartenId) {
                return ShopService.getKindergartenServiceComments(data.slug, data.queryObj).then(res => {
                    commit(SET_SELECTED_ITEM_COMMENTS, res);
                });
            } else {
                return ShopService.getServiceComments(data.slug, data.queryObj).then(res => {
                    commit(SET_SELECTED_ITEM_COMMENTS, res);
                });
            }
        }
    },
    [STORE_SHOP_ITEM_COMMENT]({commit, getters}, data) {
        if(data.type === 'product') {
            if(getters.activeKindergartenId) {
                return ShopService.addKindergartenProductComment(getters.activeKindergartenId, data.slug, data.comment).then(res => {
                    commit(ADD_SELECTED_ITEM_COMMENT, res);
                });
            } else {
                return ShopService.addProductComment(data.slug, data.comment).then(res => {
                    commit(ADD_SELECTED_ITEM_COMMENT, res);
                });
            }

        }
        if(data.type === 'service') {
            if(getters.activeKindergartenId) {
                return ShopService.addKindergartenServiceComment(getters.activeKindergartenId, data.slug, data.comment).then(res => {
                    commit(ADD_SELECTED_ITEM_COMMENT, res);
                });
            } else {
                return ShopService.addServiceComment(data.slug, data.comment).then(res => {
                    commit(ADD_SELECTED_ITEM_COMMENT, res);
                });
            }

        }
    },
    [STORE_SHOP_ITEM_RATING]({commit}, data) {
        if(data.type === 'product') {
            return ShopService.rateProduct(data.slug, data.rating).then(res => {
                commit(SET_SHOP_ITEM_RATING, res);
            });
        }
        if(data.type === 'service') {
            return ShopService.rateService(data.slug, data.rating).then(res => {
                commit(SET_SHOP_ITEM_RATING, res);
            });
        }
    },
    [GET_SHOP_SUPPLIERS]({commit}) {
        return ShopService.getSuppliers().then(res => {
            commit(SET_SHOP_SUPPLIERS, res);
        });
    },
    [GET_SUPPLIER_INFO]({commit}, name) {
        commit(SET_SUPPLIER_INFO, {});
        return ShopService.getSupplierInfo(name).then(res => {
            commit(SET_SUPPLIER_INFO, res);
        }).catch(err => {
            throw err;
        });
    },
    [GET_SHOP_FILTERS]({commit}) {
        return ShopService.filters().then(res => {
            commit(SET_SHOP_FILTERS, res.shop_filters);
            commit(SET_SYSTEM_FILTERS, res.system_filters);
        });
    },
    [SEARCH_PRODUCTS]({getters}, searchQuery) {
        if(getters.activeKindergartenId) {
            return ShopService.kindergartenProductSearch(getters.activeKindergartenId, searchQuery);
        } else {
            return ShopService.productSearch(searchQuery);
        }
    },
    [SEARCH_SERVICES]({getters}, searchQuery) {
        if(getters.activeKindergartenId) {
            return ShopService.kindergartenServiceSearch(getters.activeKindergartenId, searchQuery);
        } else {
            return ShopService.serviceSearch(searchQuery);
        }

    },
    [GET_POPULAR_ITEMS]({getters, commit}) {
        if(getters.activeKindergartenId) {
            return ShopService.popularKindergartenShopItems(getters.activeKindergartenId).then(res => {
                commit(SET_POPULAR_ITEMS, res);
            });
        } else {
            return ShopService.popularShopItems().then(res => {
                commit(SET_POPULAR_ITEMS, res);
            });
        }
    }
};
const getters = {
    allShopFilters: state => state.filters,
    shopProductFilters: state => state.filters.filter(f => f.type === 'product' || f.type === 'all'),
    shopServiceFilters: state => state.filters.filter(f => f.type === 'service' || f.type === 'all'),
    shopFilterById: state => id => state.filters.find(f => f.id == id),
    productCategoryBySlug: state => slug => state.productCategories.find(c => c.slug === slug),
    serviceCategoryBySlug: state => slug => state.serviceCategories.find(c => c.slug === slug),
    productCategories: state => state.productCategories,
    serviceCategories: state => state.serviceCategories,
    kindergartenOrders : state => state.kindergartenOrders,
    userOrders : state => state.userOrders,
    userCart : state => state.userCart,
    kindergartenWishlists: state => state.wishlists,
    shopKindergartenContacts: state => state.kindergartenContacts,
    selectedShopItem: state => state.selectedShopItem,
    supplierInfo: state => state.supplierInfo,
    shopOrderingValues: state => state.shopOrderValues,
    eliisSuppliers: state => state.suppliers,
    popularProducts: state => state.popularProducts,
    popularServices: state => state.popularServices,
    shopSystemFilters: state => state.systemFilters
};

export default {
    state,
    mutations,
    actions,
    getters
};
