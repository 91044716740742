export default {
    methods: {
        alphabetSort(data, value = 'name') {
            return data.sort(function(a, b) {
                var courseA = a[value].toUpperCase();
                var courseB = b[value].toUpperCase();

                return (courseA < courseB) ? -1 : (courseA > courseB) ? 1 : 0;
            });
        },
    },
};
