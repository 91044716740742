import FolderService from '@services/folder.service';
import Vue from 'vue';

import {
    SET_SUB_FOLDERS,
    GET_FOLDER,
    SET_FOLDER,
    SET_FOLDER_FILES,
    GET_FOLDER_ACL,
    ADD_ACL_TO_FOLDER,
    UPDATE_FOLDER,
    EDIT_FOLDER,
    EDIT_SUB_FOLDER,
    UPDATE_SUB_FOLDER,
    CREATE_FOLDER,
    ADD_FOLDER,
    ADD_FILE,
    UPLOAD_FILE,
    DELETE_FOLDER,
    REMOVE_FOLDER,
    RESET_FOLDER_STATE,
    REMOVE_FOLDER_FILE,
    UPDATE_FOLDER_FILE,
    RESET_SUB_FOLDERS,
    GET_SUB_FOLDERS,
    SET_FOLDER_MAX_PAGE,
    SET_FOLDER_TOTAL,
    GET_FOLDER_FILES,
    ADD_TO_SUB_FOLDERS,
    SET_ACTIVE_FOLDER_ID,
    MOVE_FOLDER_FILES,
    MOVE_FOLDER_SUBFOLDERS,
    REMOVE_FOLDER_FILES_BY_ID,
    REMOVE_FOLDER_SUBFOLDERS_BY_ID,
    BULK_DELETE_FOLDER_FILES,
    SET_FOLDER_SETTINGS,
} from './actions';

const getDefaultState = () => {
    return {
        subFolders: [],
        folder: {},
        files: [],
        permissions: {
            manage: 'manage',
            view: 'view',
            edit: 'edit'
        },
        settings: {},
        folderMaxPage: 0,
        folderTotal: 0,
        activeFolderId: null,
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_FOLDER_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_SUB_FOLDERS](state, folders) {
        state.subFolders = folders;
    },
    [ADD_TO_SUB_FOLDERS](state, folders) {
        folders.forEach(folder => {
            state.subFolders.push(folder);
        });
    },
    [RESET_SUB_FOLDERS](state) {
        state.subFolders = [];
    },
    [SET_FOLDER](state, folder) {
        if(folder.subfolders !== undefined) {
            delete folder.subfolders;
        }

        if(folder.files !== undefined) {
            delete folder.files;
        }

        state.folder = folder;
    },
    [SET_FOLDER_FILES](state, files) {
        state.files = files;
    },
    [ADD_ACL_TO_FOLDER](state, data) {
        state.subFolders[ state.subFolders.findIndex(f => f.id === data.id)]['acl_groups'] = data.acl;
    },
    [UPDATE_FOLDER](state, folder) {
        Vue.set(state, 'folder', folder);
    },
    [UPDATE_SUB_FOLDER](state, folder) {
        if (state.subFolders.some(f => f.id === folder.id)) {
            let index = state.subFolders.findIndex(f => f.id === folder.id);
            Vue.set(state.subFolders, index, folder);
        }
    },
    [ADD_FOLDER](state, folder) {
        state.subFolders.unshift(folder);
    },
    [ADD_FILE](state, {file, type}) {
        if(type === 'document') {
            state.files.unshift(file);
        } else {
            state.files.push(file);
        }
    },
    [REMOVE_FOLDER](state, folderId) {
        if (state.subFolders.some(f => f.id === folderId)) {
            state.subFolders.splice(state.subFolders.findIndex(f => f.id === folderId), 1);
        }
    },
    [REMOVE_FOLDER_FILE](state, fileName) {
        if (state.files.some(f => f.filename === fileName)) {
            state.files.splice(
                state.files.indexOf(state.files.find(f => f.filename === fileName)), 1
            );
        }
    },
    [REMOVE_FOLDER_FILES_BY_ID](state, files) {
        files.forEach(id => {
            if (state.files.some(f => f.id == id)) {
                state.files.splice(
                    state.files.indexOf(state.files.find(f => f.id == id)), 1
                );
            }
        });
    },
    [REMOVE_FOLDER_SUBFOLDERS_BY_ID](state, folders) {
        folders.forEach(id => {
            if (state.subFolders.some(f => f.id == id)) {
                state.subFolders.splice(
                    state.subFolders.indexOf(state.subFolders.find(f => f.id == id)), 1
                );
            }
        });
    },
    [UPDATE_FOLDER_FILE](state, data) {
        let index = state.files.findIndex(f => f.filename === data.filename);
        if(index !== -1) {
            Vue.set(state.files, index, data);
        }
    },
    [SET_FOLDER_MAX_PAGE](state, max) {
        state.folderMaxPage = max;
    },
    [SET_FOLDER_TOTAL](state, total) {
        state.folderTotal = total;
    },
    [SET_ACTIVE_FOLDER_ID](state, data) {
        state.activeFolderId = data;
    },
    [SET_FOLDER_SETTINGS](state, settings) {
        state.settings = settings;
    },
};

const actions = {
    [GET_FOLDER]({commit, getters}, {id, queryObj}) {
        commit(SET_ACTIVE_FOLDER_ID, id);

        commit(RESET_SUB_FOLDERS);
        commit(SET_FOLDER_MAX_PAGE, 0);
        commit(SET_FOLDER_TOTAL, 0);
        return FolderService.getFolderData(getters.activeKindergartenId, id, queryObj).then(res => {
            if (res.id == getters.activeFolderId) {
                if(res.subfolders !== undefined){
                    commit(SET_SUB_FOLDERS, res.subfolders.data);
                    commit(SET_FOLDER_MAX_PAGE, res.subfolders.last_page);
                    commit(SET_FOLDER_TOTAL, res.subfolders.total);
                }
    
                if(res.files !== undefined) {
                    commit(SET_FOLDER_FILES, res.files);
                }
    
                commit(SET_FOLDER, res);
            }
        });
    },
    [GET_SUB_FOLDERS]({commit, getters}, {reset, queryObj}) {
        if(reset) {
            commit(RESET_SUB_FOLDERS);
        }
        return FolderService.getSubFolders(getters.activeKindergartenId, queryObj.folder_id, queryObj).then(res => {
            commit(ADD_TO_SUB_FOLDERS, res.data);
            commit(SET_FOLDER_MAX_PAGE, res.last_page);
            commit(SET_FOLDER_TOTAL, res.total);
        });
    },
    [GET_FOLDER_ACL]({ commit, getters }, folderId) {
        return FolderService.getFolderAcl(getters.activeKindergartenId, folderId).then(res => {
            commit(ADD_ACL_TO_FOLDER, { id: folderId, acl: res }); //not working
            return res;
        });
    },
    [CREATE_FOLDER]({commit, getters}, data) {
        return FolderService.addFolder(getters.activeKindergartenId, data).then(res => {
            if(res.data) {
                commit(ADD_FOLDER, res.data);
                return res.data;
            }
        });
    },
    [EDIT_FOLDER]({commit, getters}, data) {
        return FolderService.updateFolder(getters.activeKindergartenId, data).then(res => {
            commit(UPDATE_FOLDER, res.data);
        });
    },
    [EDIT_SUB_FOLDER]({commit, getters}, data) {
        return FolderService.updateFolder(getters.activeKindergartenId, data).then(res => {
            commit(UPDATE_SUB_FOLDER, res.data);
        });
    },
    [UPLOAD_FILE]({commit, getters}, data) {
        return FolderService.uploadFiles( getters.activeKindergartenId, data.form, data.id).then(res => {
            //add files to store
            if (res.file) {
                commit(ADD_FILE, {file: res.file, type: data.type});
            }
        });
    },
    [DELETE_FOLDER]({commit, getters}, folderId) {
        return FolderService.deleteFolder(getters.activeKindergartenId, folderId).then(() => {
            commit(REMOVE_FOLDER, folderId);
        });
    },
    [GET_FOLDER_FILES]({commit, getters}, { folder_id, queryObj }) {
        return FolderService.getFolderFiles(getters.activeKindergartenId, folder_id, queryObj).then(res => {
            commit(SET_FOLDER_FILES, res.data);
        });
    },
    [MOVE_FOLDER_FILES]({commit, getters, dispatch}, { folder_id, form }) {
        return FolderService.moveFiles(getters.activeKindergartenId, folder_id, form).then(res => {
            commit(REMOVE_FOLDER_FILES_BY_ID, form.file_ids);
            return res;
        }).catch(() => {
            dispatch(GET_FOLDER, {id: getters.folder.id});
            return;
        });
    },
    [MOVE_FOLDER_SUBFOLDERS]({commit, getters, dispatch}, { folder_id, form }) {
        return FolderService.moveFolders(getters.activeKindergartenId, folder_id, form).then(res => {
            commit(REMOVE_FOLDER_SUBFOLDERS_BY_ID, form.folder_ids);
            return res;
        }).catch(() => {
            dispatch(GET_FOLDER, {id: getters.folder.id});
            return;
        });
    },
    [BULK_DELETE_FOLDER_FILES]({commit, getters, dispatch}, { folder_id, form }) {
        return FolderService.deleteFiles(getters.activeKindergartenId, folder_id, form).then(res => {
            commit(REMOVE_FOLDER_FILES_BY_ID, form.file_ids);
            return res;
        }).catch(() => {
            dispatch(GET_FOLDER, {id: getters.folder.id});
            return;
        });
    },
};

const getters = {
    folder: state => state.folder,
    subFolders: state => state.subFolders,
    folderFiles: state => state.files,
    fileCount: state => state.files.length,
    folderMaxPage: state => state.folderMaxPage,
    folderTotalFolders: state => state.folderTotal,
    activeFolderId: state => state.activeFolderId,
    folderSettings: state => state.settings,
};

export default {
    state,
    mutations,
    actions,
    getters
};
