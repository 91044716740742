import {
    GET_TEMPLATES,
    SET_TEMPLATES,
    STORE_TEMPLATE,
    SET_TEMPLATE,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_ARCHIVE_STATUS,
    GET_USABLE_MAP_TEMPLATES,
    UPDATE_MAP_TEMPLATE_CONFIRM_STATUS,
    UPDATE_MAP_TEMPLATE_INTEGRATIONS,
    SET_TEMPLATE_INTEGRATIONS,
    DELETE_TEMPLATE
} from './actions';

import TemplateService from '@services/template.service';
import Vue from 'vue';

const state = {
    templates: []
};

const mutations = {
    [SET_TEMPLATES](state, data) {
        state.templates = data;
    },
    [SET_TEMPLATE](state, template) {
        state.templates.push(template);
    },
    [UPDATE_TEMPLATE](state, template) {
        let index = state.templates.findIndex(temp => temp.id === template.id);
        if (index !== -1) {
            Vue.set(state.templates, index, template);
        }
    },
    [SET_TEMPLATE_INTEGRATIONS](state, { id, integrations}) {
        let template = state.templates.find(t => t.id === id);
        if(template) {
            template.integrations = integrations;
            Vue.set(state.templates, state.templates.findIndex(t => t.id === id), template);
        }
    },
    [DELETE_TEMPLATE](state, id) {
        let index = state.templates.findIndex(temp => temp.id === id);
        if (index !== -1) {
            state.templates.splice(index, 1);
        }
    }
};

const actions = {
    [GET_TEMPLATES]({ commit, getters }, type) {
        return TemplateService.getKindergartenTemplates(getters.activeKindergartenId, type).then(res => {
            commit(SET_TEMPLATES, res);
        });
    },
    [GET_USABLE_MAP_TEMPLATES]({commit, getters}) {
        TemplateService.getUsableMapTemplates(getters.activeKindergartenId).then(res => {
            commit(SET_TEMPLATES, res);
        });
    },
    [STORE_TEMPLATE]({ commit, getters }, name) {
        return TemplateService.addKindergartenTemplate(getters.activeKindergartenId, name).then(res => {
            commit(SET_TEMPLATE, res);
            return res;
        });
    },
    [UPDATE_TEMPLATE]({ commit, getters }, data) {
        return TemplateService.updateKindergartenTemplate(getters.activeKindergartenId, data.id, data).then(res => {
            commit(UPDATE_TEMPLATE, res);
        });
    },
    [UPDATE_TEMPLATE_ARCHIVE_STATUS]({ commit, getters }, {templateId, status}) {
        return TemplateService.updateTemplateArchiveStatus(getters.activeKindergartenId, templateId, {status: status}).then(res => {
            commit(UPDATE_TEMPLATE, res);
        });
    },
    [UPDATE_MAP_TEMPLATE_CONFIRM_STATUS]({commit, getters}, {templateId, confirm}) {
        return TemplateService.updateMapTemplateConfirmability(getters.activeKindergartenId, templateId, confirm).then(res => {
            commit(UPDATE_TEMPLATE, res);
        });
    },
    [UPDATE_MAP_TEMPLATE_INTEGRATIONS]({commit, getters}, {templateId, data}) {
        TemplateService.updateTemplateIntegrations(getters.activeKindergartenId, templateId, data).then(res => {
            commit(SET_TEMPLATE_INTEGRATIONS, {id: templateId, integrations: res});
        });
    },
    [DELETE_TEMPLATE]({commit, getters}, id) {
        return TemplateService.deleteTemplate(getters.activeKindergartenId, id).then(() => {
            commit(DELETE_TEMPLATE, id);
        });
    }
};

const getters = {
    kindergartenTemplates: state => {
        return state.templates;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
