<template>
    <div class="mb-5">
        <div v-if="!searchLoading && visibleFiles.length" class="container-fluid">
            <div class="d-flex row align-items-center mb-2 mt-4">
                <h3 class="h4 d-flex align-items-center mb-0">
                    <span class="mr-2">{{ $t(title) }}</span>
                    <small>
                        <template v-if="paginated">({{ folderTotalFiles }})</template>
                        <template v-else>({{ files.length }})</template>
                    </small>
                </h3>
                <div class="d-flex align-items-center ml-3">
                    <button type="button" class="btn btn-primary btn-sm" @click="selectAllButton">
                        <span class="d-flex align-items-center">
                            <SvgIcon icon="mdi-check" size="14" class="text-white mr-1"/>
                            {{ $t('Select all') }}
                        </span>
                    </button>
                </div>
                <div v-if="checkedFiles.length && (folder.access === 'manage' || folder.access === 'edit')" class="d-flex align-items-center ml-2">
                    <button type="button" class="btn btn-primary btn-sm" @click="checkChosenFiles('move')">
                        <span class="d-flex align-items-center">
                            <SvgIcon icon="mdi-rhombus-split" size="14" class="text-white mr-1"/>
                            {{ $t('Move') }}
                        </span>
                    </button>
                </div>
                <div v-if="checkedFiles.length" class="d-flex align-items-center ml-2">
                    <EBtnLoader :isSubmitLoading="downloadLoading" btn-class="btn-sm" @clicked="downloadSelected">
                        <span class="d-flex align-items-center">
                            <SvgIcon icon="mdi-download" size="14" class="text-white mr-1"/>
                            {{ $t('Download selected') }}
                        </span>
                    </EBtnLoader>
                </div>
                <div v-if="checkedFiles.length && (folder.access === 'manage' || folder.access === 'edit')" class="d-flex align-items-center ml-2">
                    <button type="button" class="btn btn-danger btn-sm" @click="checkChosenFiles('delete')">
                        <span class="d-flex align-items-center">
                            <SvgIcon icon="mdi-trash-can" size="14" class="text-white mr-1"/>
                            {{ $t('Delete selected') }}
                        </span>
                    </button>
                </div>
            </div>
            <template>
                <slot/>
            </template>
            <div class="row font-weight-bold e3-doc-head-hide mb-2">
                <div class="check-reserve p-0">
                    <ECheckbox
                        :id="`check-allfiles`"
                        v-model="allSelected"
                        success
                        :title="$t('Select all')"
                        @input="selectAll"/>
                </div>
                <div class="col-5 p-0"></div>
                <div class="col-4 p-0">{{ $t("Uploaded at") }}</div>
                <div class="col pl-0 pr-2 text-right">{{ $t("File size") }}</div>
                <div class="col p-0 text-right">{{ $t('Actions') }}</div>
            </div>
            <template>
                <div
                    v-for="file in visibleFiles"
                    :key="file.id"
                    v-click-outside="unHighlight"
                    class="e3-last-border e3-hover-show row border-top py-2"
                    :class="{'e3-created-new': isFileNew(file.created_at) && newCreated}">
                    <div class="d-flex align-items-center check-reserve p-0">
                        <ECheckbox
                            :id="`file-${file.id}`"
                            v-model="checkedFiles"
                            success
                            class="mr-1"
                            :class="{'e3-hidden-item show' : checkedFiles.length === 0}"
                            :val="file.id"/>
                    </div>
                    <div class="col col-md-5 d-flex flex-md-row align-items-center overflow-hidden p-0 pr-2">
                        <File
                            :files="files"
                            :file="file"
                            class="mdi-36px mr-2"
                            :type="type"
                            :searchValue="searchValue"
                            @loadingStatus="(id) => changeLoadingStatus(id)"/>
                    </div>
                    <div class="col-12 order-3 order-md-2 col-md-4 d-flex align-items-center p-0 pe-2">
                        <span class="e3-doc-hide mr-2">{{ $t("Uploaded at") }}:</span>
                        <span>{{ formatDateTime(file.created_at) }}</span>
                    </div>
                    <div class="col-12 order-4 order-md-3 col-md d-flex align-items-center p-0 pr-2 justify-content-md-end">
                        <span class="e3-doc-hide mr-2">{{ $t("File size") }}:</span>
                        <span>{{ returnFileSize(file.size) }}</span>
                    </div>
                    <div class="order-2 col-4 col-sm order-md-4 d-flex align-items-center justify-content-end p-0 pl-2">
                        <template v-if="file.id !== loadingFile">
                            <div class="e3-folder-popover e3-download-icon-button d-flex">
                                <div v-if="loading === file.id" class="spinner-border text-success" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <RoundIconButton
                                    v-else
                                    type="btn-success"
                                    :title="$t('Download')"
                                    @click="download(file)">
                                    <span class="e3-edit-btn d-inline-flex rounded-circle">
                                        <SvgIcon icon="mdi-download" class="text-white"/>
                                    </span>
                                </RoundIconButton>
                            </div>
                            <RoundIconButton
                                v-if="folder.access === 'manage' && folder.type === 'document'"
                                class="ml-2"
                                :disabled="loading === file.id"
                                :title="$t('Edit')"
                                @click="openEditFileModal(file)"/>
                            <ConfirmButton
                                v-if="canDeleteFile(file)"
                                class="ml-2"
                                :msg="$t('Are you sure you want to delete the file', {name: file.name})"
                                icon
                                confirmText="Delete"
                                :title="$t('Delete')"
                                :confirmHeader="$t('Delete file')"
                                :disable="loading === file.id"
                                @confirmed="removeFile(file)"/>
                        </template>
                        <ELoader v-else class="p-0"/>
                    </div>
                </div>
                <template v-if="!paginated">
                    <div
                        v-for="file in invisibleFiles"
                        :key="file.id"
                        class="e3-last-border row border-top py-2 placeholder-file">
                        <div class="col col-md-5 d-flex flex-md-row align-items-center mr-2 pl-0">
                            <SvgIcon icon="mdi-file" size="36" class="placeholder-icon"/>
                            <div class="e3-confidential-strip file-name"/>
                        </div>
                        <div class="col-12 order-3 order-md-2 col-md-4 d-flex align-items-center p-0 pe-2">
                            <div class="e3-confidential-strip"/>
                        </div>
                        <div class="col-12 order-4 order-md-3 col-md d-flex align-items-center p-0 pr-2 justify-content-md-end">
                            <div class="e3-confidential-strip file-size"/>
                        </div>
                        <div class="order-2 col order-md-4 d-flex align-items-center justify-content-end p-0 pl-2"/>
                    </div>
                </template>
            </template>
        </div>
        <ELoader v-else-if="searchLoading"/>
        <EmptyListMessage v-else-if="searching" class="border bg-white rounded p-2 mt-3">
            {{ $t('No documents found') }}
        </EmptyListMessage>
        <ViewGalleryFileModal v-if="type === 'gallery'"/>
        <FileModal/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {eventBus} from '@/main';
import {formatDateTime} from '@/filters';
import debounce from 'lodash/debounce';
import DocumentService from '@services/document.service';
import GalleryService from '@services/gallery.service';
import File from '@components/File.vue';
import ViewGalleryFileModal from '@views/gallery/modals/ViewFileModal.vue';
import FileModal from '@views/folder/modals/FileModal.vue';
import FileMixin from '@common/mixins/File.mixin';
import {download, downloadS3File} from '@services/helper.service';
import JSZip from 'jszip';
import httpService from '@/common/services/http.service';

export default {
    name: 'FileList',
    mixins: [FileMixin],
    props: {
        files: Array,
        type: String,
        paginated: {
            default: false,
            type: Boolean
        },
        title: String,
        perPage: {
            type: Number,
            default: 10
        },
        searchable: {
            default: false
        },
        searchLoading: Boolean,
        searchValue: String,
        searching: Boolean,
    },
    data() {
        return {
            page: 1,
            newCreated: false,
            loading: null,
            loadingFile: null,
            loadingFiles: [],
            shownFiles: 25,
            offsetTop: 0,
            checkedFiles: [],
            deleteLoading: false,
            allSelected: false,
            downloadLoading: false
        };
    },
    watch: {
        searchValue() {
            this.page = 1;
        },
        offsetTop () {
            if (!this.paginated && this.shownFiles < this.fileCount) {
                this.scrollCallBack();
            }
        },
    },
    computed: {
        ...mapGetters([
            'folder',
            'activeKindergartenId',
            'fileListMaxPage',
            'userId',
            'folderTotalFiles',
            'folder'
        ]),
        visibleFiles() {
            if(this.paginated) {
                return this.files;
            } else {
                return this.files.slice(0, this.shownFiles);
            }
        },
        invisibleFiles() {
            if(this.paginated) {
                return [];
            } else {
                return this.files.slice(this.shownFiles, this.files.length);
            }
        },
        maxPage() {
            if(this.paginated && this.fileListMaxPage) {
                return this.fileListMaxPage;
            } else {
                return Math.ceil(this.files.length / this.perPage);
            }
        },
        fileCount() {
            return this.files.length;
        },
    },
    methods: {
        formatDateTime, // TODO: Vue3 remove
        selectAllButton() {
            this.allSelected = !this.allSelected;
            this.selectAll(this.allSelected);
        },
        selectAll(value) {
            if (value) {
                this.checkedFiles = [];
                this.files.forEach(f => {
                    this.checkedFiles.push(f.id);
                });
            } else {
                this.checkedFiles = [];
            }
        },
        canAddToFolder() {
            if (
                this.folder.access === 'edit' ||
                this.folder.acl_groups !== undefined
            ) {
                return true;
            }
        },
        canDeleteFile(file) {
            return this.folder.access === 'manage' ||
                (this.folder.access === 'edit' && file.created_by === this.userId);
        },
        download(file) {
            this.loading = file.id;

            if (file.url) {
                return downloadS3File(file.url, file, () => {
                    this.loading = null;
                });
            }

            switch (this.type) {
            case 'document':
                DocumentService.downloadDocument(this.activeKindergartenId, file).finally(() => {
                    this.loading = null;
                });
                break;
            case 'gallery':
                GalleryService.downloadGalleryFile(this.activeKindergartenId, file).finally(() => {
                    this.loading = null;
                });
                break;
            }
        },
        removeFile(file) {
            let fileName = file.filename;

            this.loadingFiles.push(file.id);
            switch (this.type) {
            case 'document':
                this.$store.dispatch('DELETE_DOCUMENT', fileName).finally(() =>  {
                    this.spliceFromAnArray(file.id);
                });
                break;
            case 'gallery':
                this.$store.dispatch('DELETE_GALLERY_FILE', {fileName: fileName}).finally(() =>  {
                    this.spliceFromAnArray(file.id);
                });
                break;
            }
        },
        isElementInWindow(element) {
            let item = element.getBoundingClientRect();

            return (
                item.left >= 0 &&
                item.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                item.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        isInAnArray(fileId) {
            return this.loadingFiles?.includes(fileId);
        },
        spliceFromAnArray(fileId) {
            for (let i = this.loadingFiles.length; i--;) {
                if (this.loadingFiles[i] === fileId) {
                    this.loadingFiles.splice(i, 1);
                    break;
                }
            }
        },
        scrollCallBack() {
            let placeholders = document.querySelectorAll('.placeholder-file');

            for (let i = 0; i < placeholders.length; i++) {
                if (this.isElementInWindow(placeholders[i]) && this.shownFiles < this.fileCount) {
                    this.shownFiles++;
                }
            }
        },
        handleScroll: debounce(function() {
            this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;

            if(this.determinePaginatedScroll()) {
                this.page++;
                this.$store.dispatch('GET_SHARED_DOCUMENT_FILES', {page: this.page, perPage: this.perPage});
            }
        }, 300),
        isFileNew(created_at) {
            let createdNow = this.$moment().diff(this.$moment(created_at), 'seconds') <= 2;
            if (createdNow) {
                this.newCreated = true;
            }
            return createdNow;
        },
        unHighlight() {
            if (this.newCreated) {
                this.newCreated = false;
            }
        },
        changeLoadingStatus(id) {
            this.loading = id;
        },
        openEditFileModal(file) {
            eventBus.$emit('show-edit-file-modal', file);
        },
        askConfirmation() {
            eventBus.$emit(
                'show-action-confirmation',
                {
                    uid: this._uid,
                    msg: this.$t('Are you sure you want to delete the selected files'),
                    color: 'danger',
                    confirmText: this.$t('Delete selected files'),
                    confirmHeader: this.$t('Delete files', {count: this.checkedFiles.length}),
                }
            );
        },
        checkChosenFiles(type) {
            if (this.folder.access === 'edit') {
                let notAccessibleFiles = this.visibleFiles.filter(f => {
                    return this.checkedFiles.some(file => file === f.id) && f.created_by !== this.userId;
                });

                if (notAccessibleFiles.length) {
                    eventBus.$emit('show-accessible-modal', {
                        items: notAccessibleFiles,
                        checkedItems: this.checkedFiles.length,
                        pageType: this.type,
                        itemsType: 'file',
                        type: type,
                        done: () => {
                            this.checkedFiles = this.checkedFiles.filter(id => !notAccessibleFiles.some(file => file.id === id));
                            if (type === 'delete') {
                                this.askConfirmation();
                            }
                            if (type === 'move') {
                                this.openMoveModal();
                            }
                        }
                    });
                    return;
                }
            }

            if (type === 'delete') {
                this.askConfirmation();
            }
            if (type === 'move') {
                this.openMoveModal();
            }
        },
        openMoveModal() {
            eventBus.$emit('show-move-modal', {
                contentType: 'files',
                files: this.checkedFiles,
                done: () => {
                    this.checkedFiles = [];
                    this.allSelected = false;
                }
            });
        },
        deleteFiles() {
            let form = {
                file_ids: this.checkedFiles
            };
            this.deleteLoading = true;
            this.$store.dispatch('BULK_DELETE_FOLDER_FILES', {folder_id: this.folder.id, form: form}).catch(() => {
                this.checkedFiles = [];
            }).finally(() =>  {
                this.checkedFiles = [];
                this.deleteLoading = false;
            });
        },
        determinePaginatedScroll() {
            return this.paginated &&
                (window.innerHeight + window.pageYOffset) >= document.body.scrollHeight - 700 &&
                this.page < this.maxPage;
        },
        async downloadSelected() {
            this.downloadLoading = true;
            const zip = new JSZip();

            let files = [];
            let zipName = this.folder?.name ?? this.$t(this.title);
            if (this.checkedFiles.length) {
                files = this.visibleFiles.filter(f => this.checkedFiles.includes(f.id));
            }

            for (let i = 0; i < files.length; i++) {

                let file = files[i];
                let name = file.name + (file.filename ? file.filename.substr(file.filename.indexOf('.')) : '');
                if(file.url) {
                    await httpService.download(file.url).then(res => {
                        zip.file(name, res.data);
                    });
                } else {
                    switch (this.type) {
                    case 'document':
                        await DocumentService.getDocument(this.activeKindergartenId, file).then(res => {
                            zip.file(name, res);
                        });
                        break;
                    case 'gallery':
                        await GalleryService.getGalleryFile(this.activeKindergartenId, file).then(res => {
                            zip.file(name, res);
                        });
                        break;
                    }
                }
            }

            zip.generateAsync({type: 'blob'}).then(function (content) {
                download(content, {name: zipName, filename: zipName+'.zip'});
            }).finally(() => {
                this.downloadLoading = false;
            });
        }
    },
    components: {
        FileModal,
        File,
        ViewGalleryFileModal,
    },
    created() {
        document.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.scrollCallBack();
        eventBus.$on('action-confirmed', ({uid}) => {
            if (uid === this._uid) {
                this.deleteFiles();
            }
        });
    },
    beforeDestroy(){
        document.removeEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        eventBus.$off('action-confirmed');
    },
};
</script>

<style lang="scss" scoped>
@import "@common/styles/variables/_variables.scss";
@import "@common/styles/mixins/_filters.scss";

.e3-confidential-strip {
    background: $border-color;
    border-radius: 0.33rem;
    height: 30%;
    width: 8rem;
    &.file-name {
        width: 10rem;
    }
    &.file-size {
        width: 5rem;
    }
}

.placeholder-icon {
    @include e3-placeholder-filter;
}

.check-reserve {
    min-width: 28px;
    max-width: 28px;
}

@media screen and (max-width: 991px) {
    .show {
        display: block !important;
    }
}
</style>
