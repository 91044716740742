<template>
    <div :class="[fgClass,{'form-group': isLabelSlot,'has-feedback has-error': validation && validation.$error}]">
        <label v-if="isLabelSlot" :for="id" :class="labelClass">
            <slot name="label"/>
        </label>

        <div :class="[inputBlockClass, { 'input-group': isAppendSlot || isPrependSlot }]">
            <div v-if="isPrependSlot" class="input-group-prepend">
                <slot name="input-prepend"/>
            </div>
            <input
                :id="id"
                :ref="`${id}_input`"
                v-model="val.address"
                class="form-control"
                :class="{'is-invalid': validation && validation.$error,'form-control-lg': isLargeInput}"
                :type="inputType"
                :disabled="disabled"
                :placeholder="placeholder"
                step="any"
                lang="en"
                :min="minValue"
                @focus="onFocus"
                @blur="onBlur"
                @keypress.enter="onKeypressEnter"/>

            <div v-if="isAppendSlot" class="input-group-append">
                <slot name="input-append"/>
            </div>
            <ValitationMessages :validation="validation"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AddressInput',
    props: {
        id: [String, Number],
        disableNegativeValues: {
            type: Boolean,
            default: false
        },
        value: Object,
        type: String,
        disabled: Boolean,
        placeholder: String,
        validation: Object,
        inline: Boolean,
        uppercase: Boolean,
        large: Boolean,
        formGroupClass: String
    },
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        fgClass() {
            return this.formGroupClass;
        },
        minValue() {
            return this.disableNegativeValues ? 0 : '';
        },
        labelClass() {
            if (this.inline) {
                return 'col-5 col-md-3';
            }

            return '';
        },
        inputBlockClass() {
            if (this.inline) {
                return 'col-md-7';
            }

            return '';
        },
        isLargeInput() {
            return this.large;
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        isAppendSlot() {
            return !!this.$slots['input-append'];
        },
        isPrependSlot() {
            return !!this.$slots['input-prepend'];
        },
        isNumberType() {
            return this.type === 'number';
        },
        inputType() {
            if (this.type && this.type !== 'text') {
                return this.type;
            }
            return 'text';
        },
    },
    methods: {
        onKeypressEnter(e) {
            this.$emit('keypress-enter', e);
        },
        onBlur(e) {
            this.$emit('blur', e);

            this.validation && this.validation.$touch();
        },
        onFocus(e) {
            this.$emit('focus', e);
        },
        clear() {
            if (this.$refs.inputField) {
                this.$refs.inputField.value = '';
            }
        },
        blur() {
            if (this.$refs.inputField) {
                this.$refs.inputField.blur();
            }
        },
        focus() {
            if (this.$refs.inputField) {
                this.$refs.inputField.focus();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>
