import store from '@/store';
const AdminSurveys = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/surveys/index.vue');
const Surveys = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/surveys/views/AdminSurveyList.vue');
const Survey = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/surveys/views/AdminSurvey.vue');
const AdminSurveyResults = () => import(/* webpackChunkName: "admin-module" */'@/views/admin/surveys/components/AdminSurveyResults.vue');
export default [
    {
        path: 'admin/surveys',
        components: {base: AdminSurveys},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminSurveysIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        },
        children: [
            {
                path: '',
                name: 'admin.surveys.ongoing',
                components: { 'admin-surveys': Surveys },
                meta: 'ongoing',
            },
            {
                path: 'drafts',
                name: 'admin.surveys.drafts',
                components: { 'admin-surveys': Surveys },
                meta: 'draft',
            },
            {
                path: 'upcoming',
                name: 'admin.surveys.upcoming',
                components: { 'admin-surveys': Surveys },
                meta: 'upcoming',
            },
            {
                path: 'finished',
                name: 'admin.surveys.finished',
                components: { 'admin-surveys': Surveys },
                meta: 'finished',
            },
        ]
    },
    {
        path: 'admin/surveys/:survey_id',
        name: 'admin.survey',
        components: {base: Survey},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminSurveysIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        },
    },
    {
        path: 'admin/surveys/:survey_id/answers',
        name: 'admin.survey.results',
        components: {base: AdminSurveyResults},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminSurveysIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        },
    }
];
