<template>
    <div class="d-flex align-items-center e3-pointer">
        <div class="mr-2">
            <img
                v-if="type === 'gallery' && file.mime_type === 'image/jpeg'"
                :src="buildUrl()"
                class="tiny-thumb"/>
            <!-- <ImageThumbnail
                v-if="type === 'gallery' && file.mime_type === 'image/jpeg' && file.thumbnails && file.thumbnails.small"
                :url="buildUrl()"
                class="tiny-thumb mr-2"/> -->
            <span v-else :title="file.name">
                <SvgIcon :icon="returnFileIcon('file').img" size="36" :class="returnFileIcon('file').color"/>
            </span>
        </div>
        <div v-if="showName">
            <a class="btn-link nav-link text-primary e3-hover-underline p-0" :title="file.name"  @click.prevent="getFile(file)">
                <span v-if="searchValue" :inner-html.prop="searchHighlight(file.name, searchValue)"/>
                <span v-else>{{ truncate(file.name, 28)}}</span>
            </a>
            <template v-if="file.folder && file.folder.id != folder.id">
                <div>
                    <router-link
                        :to="{name: type+'.folder', params: {'folder': file.folder.id}}"
                        class="d-flex align-items-center nav-link e3-hover-underline text-muted p-0" :title="file.folder.name">
                        <small class="mr-1">{{ $t('In folder') }}:</small>
                        <small :title="file.folder.name">{{ file.folder.name }}</small>
                    </router-link>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {truncate, searchHighlight} from '@/filters';
import DocumentService from '@services/document.service';
import FileService from '@services/file.service';
import PlanService from '@services/plan.service';
import DiaryService from '@services/diary.service';
import ChildService from '@services/child.service';
import { mapGetters } from 'vuex';
import {eventBus} from '@/main';
// import ImageThumbnail from '@components/image-thumbnail/ImageThumbnail';
import FileMixin from '@common/mixins//File.mixin';

export default {
    mixins: [FileMixin],
    props: {
        file: Object,
        type: String,
        searchValue: String,
        showName: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            loading: null
        };
    },
    computed: {
        ...mapGetters(['activeKindergartenId', 'folder'])
    },
    methods: {
        truncate, // TODO: Vue3 remove
        searchHighlight, // TODO: Vue3 remove
        getFile(file) {
            if (!this.loading) {
                if (this.type === 'document') {
                    this.loading = file.id;
                    this.$emit('loadingStatus', file.id);
                    DocumentService.downloadDocument(this.activeKindergartenId, file).finally(() => {
                        this.loading = false;
                        this.$emit('loadingStatus', null);
                    });
                } else if (this.type === 'gallery') {
                    eventBus.$emit('show-view-media-file-modal', {file: file, type: this.type});
                } else if (this.type === 'plan') {
                    PlanService.getDocument(this.activeKindergartenId, file).finally(() => {
                        this.loading = false;
                        this.$emit('loadingStatus', null);
                    });
                } else if (this.type === 'diary') {
                    DiaryService.getDiaryFile(this.activeKindergartenId, file).finally(() => {
                        this.loading = false;
                        this.$emit('loadingStatus', null);
                    });
                } else if (this.type === 'child') {
                    ChildService.getChildFile(this.activeKindergartenId, this.$route.params.child_id, file).finally(() => {
                        this.loading = false;
                        this.$emit('loadingStatus', null);
                    });
                } else {
                    this.loading = file.id;
                    this.$emit('loadingStatus', file.id);
                    FileService.getFile(file, this.type).finally(() => {
                        this.loading = false;
                        this.$emit('loadingStatus', null);
                    });
                }
            }
        },
        buildUrl() {
            if (this.file.thumbnails.small.url) {
                return this.file.thumbnails.small.url;
            }
            if(process.env.NODE_ENV !== 'development') {
                return `https://api.${window.location.hostname}/api/kindergartens/${this.activeKindergartenId}/files/gallery_thumbs/${this.file.thumbnails.small.filename}`;
            } else {
                return `${process.env.VUE_APP_API_URL}/api/kindergartens/${this.activeKindergartenId}/files/gallery_thumbs/${this.file.thumbnails.small.filename}`;
            }
        }
    },
    components: {
        // ImageThumbnail
    },
};
</script>
<style scoped>
.tiny-thumb {
  width: 36px;
  height: 36px;
}
</style>
