import http from '@http';

class ThisService {
    constructor() {
        if (ThisService.instance) {
            return ThisService.instance;
        }

        ThisService.instance = this;
    }

    getClasses(kindergartenId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/this/courses`, { params: options }).then(res => res.data);
    }

    getThisChildrenStatus(kindergartenId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/this/children`, { params: options}).then(res => res.data);
    }

    importData(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/this/import`, data).then(res => res.data);
    }

    getStatus(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/this/status`).then(res => res.data);
    }

}
export default new ThisService();
