import http from '@http';
import Store from '../../store';
class EventService {
    constructor() {
        if (EventService.instance) return EventService.instance;
    }

    getEvents(kindergartenId, params = {}) {
        return http.get(`/api/kindergartens/${kindergartenId}/events`, params)
            .then(res => res.data);
    }

    getOrgEvents(organizationId, params) {
        return http.get(`/api/organizations/${organizationId}/events`, params)
            .then(res => res.data);
    }

    createEvent(data) {
        return http.post(`/api/kindergartens/${Store.getters.activeKindergartenId}/events`, data)
            .then(res => res.data );
    }

    updateEvent(data) {
        return http.put(`/api/kindergartens/${data.kindergarten_id}/events/${data.id}`, data )
            .then(res => res.data);
    }

    deleteEvent(data) {
        return http.delete(`/api/kindergartens/${data.kindergarten_id}/events/${data.id}`,
            { params: { change_mode: data.change_mode, date: data.date } }).then(res => res.data);
    }

    getMyEvents(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/events/my`).then(res => res.data);
    }

    getRecentEvents(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/events/recent`).then(res => res.data);
    }

    getRecentOrgEvents(organizationId) {
        return http.get(`/api/organizations/${organizationId}/events/recent`).then(res => res.data);
    }

    updateActivity(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/events/activity`).then(res => res.data);
    }

    updateOrgActivity(organizationId) {
        return http.get(`/api/organizations/${organizationId}/events/activity`).then(res => res.data);
    }
}
export default new EventService();
