<template>
    <e-input :id="id" v-model="val" v-focus="focus" :placeholder="$t('Type to search')" :disabled="isDisabled" @keypress.enter.prevent="onSearch">
        <template #input-append>
            <button class="btn btn-primary rounded-right" @click="clearFilter">
                {{ $t('Clear') }}
            </button>
        </template>
    </e-input>
</template>

<script>
export default {
    name: 'TableFilter',
    props: {
        id: String,
        disabled: Boolean,
        focus: Boolean,
        value: [String, Number]
    },
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        isDisabled() {
            return this.disabled;
        }
    },
    methods: {
        onSearch() {
            this.$emit('input', this.value);
        },
        clearFilter() {
            if (this.value.trim() !== '') {
                this.$emit('clear');
            }
        }
    }
};
</script>
