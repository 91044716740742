import {
    GET_ARNO_COURSES,
    SET_ARNO_COURSES,
    GET_ARNO_CHILDREN,
    SET_ARNO_CHILDREN,
    SET_ARNO_ERROR,
    STORE_ARNO_COURSE,
    UPDATE_ARNO_COURSE,
    UPDATE_ARNO_COURSE_CHILDREN,
    RESET_ARNO_STATE,
    STORE_ARNO_PERIOD,
    SET_ARNO_COURSE,
} from './actions';
import ArnoService from '@services/arno.service';
import Vue from 'vue';
const getDefaultState = () => {
    return {
        courses: [],
        error_message: null,
        children: []
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_ARNO_STATE](){
        Object.assign(state, getDefaultState());
    },
    [SET_ARNO_COURSES](state, courses) {
        state.courses = courses;
    },
    [SET_ARNO_CHILDREN](state, children) {
        state.children = children;

    },
    [SET_ARNO_ERROR](state, message) {
        state.error_message = message;
    },
    [UPDATE_ARNO_COURSE](state, course) {
        let index = state.courses.findIndex(c => c.id === course.id);
        if(index !== -1) {
            Vue.set(state.courses, index, course);
        }
    },
    [SET_ARNO_COURSE](state, course) {
        let index = state.courses.findIndex(c => c.ehiscode === course.ehiscode);
        if(index !== -1) {
            let arno_course = state.courses.find(c => c.ehiscode === course.ehiscode);
            course.arno_name = arno_course.arno_name;
            Vue.set(state.courses, index, course);
        }
    }
};

const actions = {
    [GET_ARNO_COURSES]({commit, getters}, options) {
        commit(RESET_ARNO_STATE);
        return ArnoService.getClasses(getters.activeKindergartenId, options).then(res => {
            if(res.error) {
                commit(SET_ARNO_ERROR, res.message);
            } else {
                commit(SET_ARNO_COURSES, res.data);
            }
        });
    },
    [GET_ARNO_CHILDREN]({commit, getters}, options) {
        commit(SET_ARNO_ERROR, null);
        return ArnoService.getChildren(getters.activeKindergartenId, options).then(res => {
            if(res.error) {
                commit(SET_ARNO_ERROR, res.message);
            } else {
                commit(SET_ARNO_CHILDREN, res.data);
            }

        });
    },
    [UPDATE_ARNO_COURSE_CHILDREN]({commit, getters}, data) {
        return ArnoService.updateChildren(getters.activeKindergartenId, data.courseId, data.children).then(res => {
            commit(UPDATE_ARNO_COURSE, res);
        });
    },
    [STORE_ARNO_PERIOD]({commit, getters}, data) {
        return ArnoService.storePeriod(getters.activeKindergartenId, data).then(res => {
            commit(SET_ARNO_COURSE, res);
        });
    },
    [STORE_ARNO_COURSE]({commit, getters}, courseId) {
        return ArnoService.storeCourse(getters.activeKindergartenId, courseId).then(res => {
            commit(SET_ARNO_COURSE, res);
        });
    },
};

const getters = {
    arnoClasses: state => state.courses,
    arnoError: state => state.error_message,
    arnoClassChildren: state => state.children
};

export default {
    state,
    mutations,
    actions,
    getters
};
