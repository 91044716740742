import Vue from 'vue';
import {
    GET_DIARY,
    GET_DIARY_WEEK,
    SET_ANALYSIS_COMMENTS,
    SET_DAILY_COMMENTS,
    UPDATE_DAILY_COMMENTS,
    SET_DIARY,
    SET_DIARY_ENTRIES,
    SET_DIARY_WEEK,
    SET_MY_ANALYSIS_COMMENT,
    UPDATE_ANALYSIS_COMMENTS,
    SET_MY_DAILY_COMMENT,
    SET_COMMENT,
    PUBLISH_DIARY,
    UPDATE_REQUEST_STATUS,
    STORE_COMMENT,
    STORE_ENTRY,
    STORE_ENTRIES,
    SET_ENTRY,
    SET_ENTRIES,
    SET_COURSE_MEALS,
    SET_COURSE_SETTINGS,
    SET_COURSE_STATUSES,
    ADD_ABSENCE,
    SET_ROW_LOADING,
    UNSET_ROW_LOADING,
    SET_DIARY_MEDIA,
    ADD_DIARY_MEDIA,
    UPLOAD_DIARY_MEDIA,
    UPLOAD_DIARY_DOCUMENT,
    UPDATE_DIARY_FILE,
    DELETE_DIARY_MEDIA,
    DELETE_DIARY_FILE,
    CREATE_DIARY,
    CREATE_AND_PUBLISH_DIARY,
    SET_DIARY_ERROR,
    SET_DIARY_DOCUMENTS,
    ADD_DIARY_DOCUMENT, DELETE_DIARY_DOCUMENT,
    SET_DIARY_HOLIDAYS,
    LIKE_DIARY,
    SET_DIARY_LIKES,
} from './actions';
import DiaryService from '@services/diary.service';

const getDefaultState = () => {
    return {
        diary: {},
        texts: [],
        daily_comments: [],
        my_daily_comment: null,
        analysis_comments: [],
        my_analysis_comment: null,
        entries: {
            required: [],
            not_required: []
        },
        media: [],
        documents: [],
        diary_week: [],
        attendance_types: {
            present: 1,
            absent: 0,
            uninformed: 2,
            ill: 3
        },
        meal_types: [
            'breakfast',
            'lunch',
            'dinner',
            'supper'
        ],
        course_settings: {},
        course_meals: {},
        course_statuses: [] ,
        row_loading: 0,
        request_status: '',
        error: null,
        holidays: [],
        diary_likes: {
            liked: false,
            users: [],
        },
    };
};

const state = getDefaultState();

const mutations = {
    [SET_DIARY](state, data) {
        state.diary = data;
    },
    [SET_DIARY_ENTRIES](state, data) {
        Vue.set(state.entries, 'required', [...data.required]);
        Vue.set(state.entries, 'not_required', [...data.not_required]);
    },
    [SET_DAILY_COMMENTS](state, data) {
        Vue.set(state, 'daily_comments', [...data]);
    },
    [UPDATE_DAILY_COMMENTS](state, data) {
        let index = state.daily_comments.findIndex(comment => comment.id === data.id);
        if(index !== -1) {
            Vue.set(state.daily_comments, index, data);
        } else {
            state.daily_comments.push(data);
        }
    },
    [SET_MY_DAILY_COMMENT](state, content) {
        Vue.set(state, 'my_daily_comment', {...content});
    },
    [SET_ANALYSIS_COMMENTS](state, data) {
        Vue.set(state, 'analysis_comments', [...data]);
    },
    [UPDATE_ANALYSIS_COMMENTS](state, data) {
        let index = state.analysis_comments.findIndex(comment => comment.id === data.id);
        if(index !== -1) {
            Vue.set(state.analysis_comments, index, data);
        } else {
            state.analysis_comments.push(data);
        }
    },
    [SET_MY_ANALYSIS_COMMENT](state, content) {
        Vue.set(state, 'my_analysis_comment', {...content});
    },
    [SET_ENTRY](state, data) {
        let index = state.entries[data.type].findIndex(
            entry => entry.child.id === data.entry.child.id
        );
        Vue.set(state.entries[data.type], index, data.entry);

        state.diary = data.diary;
    },
    [SET_ENTRIES](state, data) {
        Vue.set(state.entries, 'required', data.entries.required);
        state.diary = data.diary;
    },
    [SET_COMMENT](state, data) {
        let index = state.texts.findIndex(text => text.id === data.id);
        if(index !== -1) {
            Vue.set(state.texts, index, data);
        } else {
            state.texts.push(data);
        }
    },
    [SET_DIARY_WEEK](state, data) {
        state.diary_week = data;
    },
    [UPDATE_REQUEST_STATUS](state, status) {
        state.request_status = status;
    },
    [SET_COURSE_SETTINGS](state, settings) {
        state.course_settings = settings;
    },
    [SET_COURSE_STATUSES](state, statuses) {
        state.course_statuses = statuses;
    },
    [SET_COURSE_MEALS](state, meals) {
        state.course_meals = meals;
    },
    [ADD_ABSENCE](state, attendance) {
        let index = state.entries.required.findIndex(
            entry => entry.child.id === attendance.child_id
        );
        if(index !== -1) {
            let entry = state.entries.required.find(
                entry => entry.child.id === attendance.child_id
            );
            entry.child.attendance = attendance;
            Vue.set(state.entries.required, index, entry);
        } else {
            let index = state.entries.not_required.findIndex(
                entry => entry.child.id === attendance.child_id
            );

            if(index !== -1) {
                let entry = state.entries.not_required.find(
                    entry => entry.child.id === attendance.child_id
                );
                entry.child.attendance = attendance;
                Vue.set(state.entries.not_required, index, entry);
            }
        }
    },
    [SET_ROW_LOADING](state) {
        state.row_loading++;
    },
    [UNSET_ROW_LOADING](state) {
        state.row_loading--;
        if (state.row_loading < 0) {
            state.row_loading = 0;
        }
    },
    [SET_DIARY_MEDIA](state, media) {
      state.media = media;
    },
    [ADD_DIARY_MEDIA](state, media) {
        state.media.push(media);
    },
    [UPDATE_DIARY_FILE](state, media) {
        let index = state.media.findIndex(f => f.filename === media.filename);
        if(index !== -1) {
            Vue.set(state.media, index, media);
        }
    },
    [DELETE_DIARY_MEDIA](state, fileName) {
        if (state.media.some(f => f.filename === fileName)) {
            state.media.splice(
                state.media.indexOf(state.media.find(f => f.filename === fileName)), 1
            );
        }
    },
    [DELETE_DIARY_DOCUMENT](state, fileName) {
        if (state.documents.some(f => f.filename === fileName)) {
            state.documents.splice(
                state.documents.indexOf(state.documents.find(f => f.filename === fileName)), 1
            );
        }
    },
    [SET_DIARY_ERROR](state, error) {
        state.error = error;
    },
    [SET_DIARY_DOCUMENTS](state, documents) {
      state.documents = documents;
    },
    [ADD_DIARY_DOCUMENT](state, document) {
        state.documents.push(document);
    },
    [SET_DIARY_HOLIDAYS](state, holidays) {
        state.holidays = holidays;
    },
    [SET_DIARY_LIKES](state, likes) {
        state.diary_likes = likes;
    },
};

const actions = {
    [GET_DIARY]({commit, getters}, data) {
        commit('START_CONTENT_LOADER');
        commit(SET_DIARY_ERROR, null);
        return DiaryService.getDiaryData(data).then(day => {
            let foreignDailyComments = [];
            let myDailyComment = {};
            let foreignAnalysisComments = [];
            let myAnalysisComment = {};
            let media = [];
            let documents = [];

            if(typeof day.daily_comments !== 'undefined') {
                day.daily_comments.map(c => {
                    if(c.created_by !== getters.currentEmployee.id) {
                        foreignDailyComments.push(c);
                    } else {
                        myDailyComment = c;
                    }
                });
            }

            if(typeof day.analysis_comments !== 'undefined') {
                day.analysis_comments.map(c => {
                    if(c.created_by !== getters.currentEmployee.id) {
                        foreignAnalysisComments.push(c);
                    } else {
                        myAnalysisComment = c;
                    }
                });
            }

            if(typeof day.entries !== 'undefined') {
                commit(SET_DIARY_ENTRIES, day.entries);
            }

            if(typeof day.settings !== 'undefined') {
                commit(SET_COURSE_SETTINGS, day.settings);
            }

            if(typeof day.statuses !== 'undefined') {
                commit(SET_COURSE_STATUSES, day.statuses);
            }

            if(typeof day.meals !== 'undefined') {
                commit(SET_COURSE_MEALS, day.meals);
            }

            if(typeof day.images !== 'undefined') {
                media = day.images;
            }

            if(day.documents) {
                documents = day.documents;
            }

            if (typeof day.likes !== 'undefined') {
                commit(SET_DIARY_LIKES, day.likes);
            } else {
                commit(SET_DIARY_LIKES, getDefaultState()['diary_likes']);
            }

            commit(SET_DIARY, day.diary);
            commit(SET_DAILY_COMMENTS, foreignDailyComments);
            commit(SET_MY_DAILY_COMMENT, myDailyComment);
            commit(SET_ANALYSIS_COMMENTS, foreignAnalysisComments);
            commit(SET_MY_ANALYSIS_COMMENT, myAnalysisComment);
            commit(SET_DIARY_MEDIA, media);
            commit(SET_DIARY_DOCUMENTS, documents);
            commit(SET_DIARY_HOLIDAYS, day.holidays);

        }).catch(error => {
            commit(SET_DIARY_ERROR, error.response.data.message);
        }).finally(() => {
            commit('STOP_CONTENT_LOADER');
        });
    },
    [CREATE_DIARY]({commit, getters}) {
        if (!getters.diary.id) {
            return DiaryService.createDiary(getters.activeKindergartenId, getters.diary).then(res => {
                commit('SET_DIARY', res.diary);
                return res;
            });
        } else {
            return new Promise((resolve) => {
                resolve(getters.diary);
            });
        }
    },
    async [STORE_COMMENT]({commit, dispatch, getters}, data) {
        await dispatch(CREATE_DIARY);
        data.diary = getters.diary;
        return DiaryService.saveComment(getters.activeKindergartenId, data).then((res) => {
            commit(SET_COMMENT, res);
            let myComment = {
                comment: res.comment,
                creator: res.creator,
                id: res.id,
            };
            if (res.type === 1) {
                commit(SET_MY_DAILY_COMMENT, myComment);
            }
            if (res.type === 2) {
                commit(SET_MY_ANALYSIS_COMMENT, myComment);
            }
            return res;
        });
    },
    async [STORE_ENTRY]({dispatch, getters}, data) {
        await dispatch(CREATE_DIARY);
        data.diary = getters.diary;
        return DiaryService.updateSingleEntry(getters.activeKindergartenId, data).then((res) => {
            return res;
        });
    },
    async [STORE_ENTRIES]({commit, dispatch, getters}, data) {
        await dispatch(CREATE_DIARY);
        data.diary = getters.diary;
        return DiaryService.updateAllEntries(getters.activeKindergartenId, data).then(res => {
            commit(SET_ENTRIES, res);
            return res;
        });
    },
    [CREATE_AND_PUBLISH_DIARY]({commit, getters}) {
        const diaryClone = {...getters.diary};
        diaryClone.published = 1;

        return DiaryService.createDiary(getters.activeKindergartenId, diaryClone).then(res => {
            commit('SET_DIARY', res.diary);
            return res;
        });
    },
    [PUBLISH_DIARY]({commit, getters}) {
        return DiaryService.publish(getters.activeKindergartenId, getters.diary.id).then(res => {
            commit(SET_DIARY, res);
            return res;
        });
    },
    [GET_DIARY_WEEK]({commit}, data) {
        commit('START_CONTENT_LOADER');
        commit(SET_DIARY_ERROR, null);
        DiaryService.getDiaryWeekData(data).then(res => {
            commit(SET_DIARY_WEEK, res);
        }).catch(error => {
            commit(SET_DIARY_ERROR, error.response.data.message);
        }).finally(() => {
            commit('STOP_CONTENT_LOADER');
        });
    },
    [SET_ROW_LOADING]({ commit }) {
        commit(SET_ROW_LOADING);
    },
    [UNSET_ROW_LOADING]({ commit }) {
        commit(UNSET_ROW_LOADING);
    },
    [UPLOAD_DIARY_MEDIA]({commit, getters}, data) {
        return DiaryService.uploadMedia(getters.activeKindergartenId, data.diary, data.form).then(res => {
            commit(ADD_DIARY_MEDIA, res.file);
            return res;
        });
    },
    [UPLOAD_DIARY_DOCUMENT]({commit, getters}, data) {
        return DiaryService.uploadDocument(getters.activeKindergartenId, data.diary, data.form).then(res => {
            commit(ADD_DIARY_DOCUMENT, res.file);
            return res;
        });
    },
    [UPDATE_DIARY_FILE]({commit, getters}, form) {
        return DiaryService.updateDiaryFile(getters.activeKindergartenId, form.filename, form).then(res => {
            commit(UPDATE_DIARY_FILE, res);
        });
    },
    [DELETE_DIARY_FILE]({commit, getters}, {fileName}) {
        return DiaryService.deleteDiaryFile(getters.activeKindergartenId, fileName).then(() => {
            commit(DELETE_DIARY_MEDIA, fileName);
        });
    },
    [DELETE_DIARY_DOCUMENT]({commit, getters}, filename) {
        return DiaryService.deleteDiaryFile(getters.activeKindergartenId, filename).then(() => {
            commit(DELETE_DIARY_DOCUMENT, filename);
        });
    },
    [LIKE_DIARY]({getters}, diaryId) {
        return DiaryService.likeDiary(getters.activeKindergartenId, diaryId).then(res => {
            return res;
        });
    }
};

const getters = {
    mealTypes: state => state.meal_types,
    attendanceTypes: state => state.attendance_types,
    diaryTexts: state =>  state.texts,
    dailyComments: state =>state.daily_comments,
    myDailyComment: state => state.my_daily_comment,
    analysisComments: state => state.analysis_comments,
    myAnalysisComment: state => state.my_analysis_comment,
    diaryEntries: state => state.entries || {},
    diaryMedia: state => state.media,
    diary: state => state.diary || {},
    getRequestStatus: state => state.request_status,
    diaryWeek: state => state.diary_week,
    courseDiarySettings: state => state.course_settings,
    courseDiaryStatuses: state => state.course_statuses,
    courseDiaryMeals: state => state.course_meals,
    getEntryById: state => id => {
        let entry = state.entries.required.find(entry => entry.id === id);
        if(entry === undefined && state.entries.not_required !== undefined) {
            entry = state.entries.not_required.find(entry => entry.id === id);
        }
        return entry;
    },
    row_loading: state => {
        return state.row_loading;
    },
    diaryErrorMessage: state => state.error,
    diaryDocuments: state => state.documents,
    diaryHolidays: state => state.holidays,
    diaryLikes: state => state.diary_likes,
};

export default {
    state,
    mutations,
    actions,
    getters
};
