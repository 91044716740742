<template>
    <b-modal id="folder-modal" hide-footer size="lg" lazy static no-close-on-backdrop
             @hide="resetForm">
        <template slot="modal-header">
            <modal-header modalName="folder-modal">
                <template v-if="tab === 'folder'">
                    <span v-if="mode === 'index-edit' || mode === 'edit'">{{ $t('Edit '+folderType) }}: {{ form.name }}</span>
                    <template v-else-if="mode === 'new'">
                        <span v-if="index">{{$t('Create new '+folderType) }}</span>
                        <span v-else>{{ $t('Create new '+folderType+' inside') }}: {{ mainFolder }}</span>
                    </template>
                </template>
                <template v-else>
                    <span>{{ $t('Upload files to') }}: {{ mainFolder }}</span>
                </template>
            </modal-header>
        </template>
        <div v-if="!index && mode !== 'edit' && mode !== 'index-edit'" class="d-flex flex-column flex-sm-row mb-4">
            <button class="btn mr-sm-2" :class="tab === 'folder' ? 'btn-primary' : 'btn-secondary'" @click="tab = 'folder'">{{ $t('Create new '+folderType) }}</button>
            <button class="btn mt-2 mt-sm-0" :class="tab === 'folder' ? 'btn-secondary' : 'btn-primary'" @click="tab = 'document'">{{ $t('Upload files') }}</button>
        </div>
        <div class="tab-content">
            <div v-if="loading">
                <e-loader class="overlay-loader"/>
            </div>
            <div v-else>
                <div v-if="tab === 'folder'" class="tab-pane fade show active">
                    <form @submit.prevent="saveFolder">
                        <e-input id="name-input" v-model="form.name" class="mb-3" :validation="$v.form.name">
                            <template #label>
                                {{ $t('Name') }}
                            </template>
                        </e-input>
                        <div>
                            <label for="view-select" class="d-flex align-items-center">
                                <span>
                                    {{ $t('Add target groups who can view the '+folderType) }}
                                    - <small>{{ $t('Optional') }}</small>
                                </span>
                                <e-tooltip id="targetViewTip" class="ml-2" :inline="false">
                                    <div class="text-left">{{ $t('All target groups added here can view and download the files in this '+folderType+'.') }}</div>
                                </e-tooltip>
                            </label>
                            <e-select 
                                id="view-select"
                                v-model="form.view"
                                :options="userTargetGroups"
                                :placeholder="$t('Choose')"
                                searchable
                                :multiple="true"
                                track-by="unique_id"
                                :close-on-select="false"
                                :custom-label="target => target.name + ' (' + ($t(typeTranslations(target.type))) + ')'"
                                class="mb-3"/>

                            <label for="edit-select" class="d-flex align-items-center">
                                <span>
                                    {{ $t('Add target groups who can edit the '+folderType) }}
                                    - <small>{{ $t('Optional') }}</small>
                                </span>
                                <e-tooltip id="targetEditTip" class="ml-2" :inline="false">
                                    <div class="text-left">{{ $t('All target groups added here can view and download the files in this '+folderType+'.') }}</div>
                                    <div class="text-left mt-2">{{ $t('They can also add new '+folderType+'s and files into this '+folderType+'.') }}</div>
                                </e-tooltip>
                            </label>
                            <e-select 
                                id="edit-select"
                                v-model="form.edit"
                                :options="userTargetGroups"
                                :placeholder="$t('Choose')"
                                searchable
                                :multiple="true"
                                track-by="unique_id"
                                :close-on-select="false"
                                :custom-label="target => target.name + ' (' + ($t(typeTranslations(target.type))) + ')'"
                                class="mb-3"/>
                            <div v-if="showParentManagers">
                                <label for="parent-managers" class="d-flex align-items-center">{{$t('Parent folder managers')}}:</label>
                                <div v-for="manager in parentManagers" :key="manager.unique_id">
                                    {{manager.name}} ({{$t(typeTranslations(manager.type))}})
                                </div>
                            </div>
                            <label for="manage-select" class="d-flex align-items-center">
                                <span>
                                    {{ $t('Add target groups who can manage the '+folderType) }}
                                </span>
                                <e-tooltip id="targetManageTip" class="ml-2" :inline="false">
                                    <div class="text-left">{{ $t('All target groups added here can view and download the files in this '+folderType+'.') }}</div>
                                    <div class="text-left my-2">{{ $t('They can also add new '+folderType+'s and documents into this '+folderType+'.') }}</div>
                                    <div class="text-left">{{ $t('They can also delete the '+folderType+' and change the target groups who can view, edit and manage the '+folderType+'.') }}</div>
                                </e-tooltip>
                            </label>
                            <e-select 
                                id="manage-select"
                                v-model="form.manage"
                                :options="managerOptions"
                                :placeholder="$t('Choose')"
                                searchable
                                :not-empty="!folder.id"
                                :multiple="true"
                                track-by="unique_id"
                                :close-on-select="false"
                                :custom-label="target => target.name + ' (' + ($t(typeTranslations(target.type))) + ')'"
                                class="mb-3"/>
                        </div>
                        <div class="d-flex justify-content-end mt-3">
                            <button class="btn btn-secondary mr-2" @click="$bvModal.hide('folder-modal')">
                                {{ $t('Cancel') }}
                            </button>
                            <e-btn-loader :isSubmitLoading="isSubmitLoading" @clicked="saveFolder">
                                <span v-if="mode === 'new'">{{ $t('Create') }}</span>
                                <span v-else>{{ $t('Save') }}</span>
                            </e-btn-loader>
                        </div>
                    </form>
                </div>
                <div v-if="tab === 'document'" class="tab-pane fade show active">
                    <folder-upload :type="type"/>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import { eventBus } from '@/main';
import FolderUpload from '@views/folder/components/FolderUpload';

export default {
    props: {
        type: String,
        noUpload: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            tab: 'folder',
            form: {
                name: '',
                public: 0,
                shared_edit: 0,
                view: [],
                edit: [],
                manage: [],
                parent_id: null
            },
            indexFolder: {}, //index-edit needs a local copy of the folder to assess the manager status
            mode: null,
            availableTargetGroups: [],
            loading: false,
            files: [],
            isEdit: false,
            isSubmitLoading: false,
            index: false,
            mainFolder: ''
        };
    },
    computed: {
        ...mapGetters([
            'currentEmployee',
            'currentGuardian',
            'userTargetGroups',
            'activeKindergartenId',
            'userTargetIdentities',
            'folderFiles',
            'subFolders'
        ]),
        folder() {
            if(this.mode ==='index-edit') {
                return this.indexFolder;
            } else {
                return this.$store.getters.folder;
            }
        },
        folderType() {
            if(this.type === 'gallery') {
                return 'album';
            } else {
                return 'folder';
            }
        },
        showParentManagers() {
            return !!(this.folder.id && (this.folder.parent_id || this.mode === 'new'));
        },
        parentManagers() {
            if(this.showParentManagers) {
                if(!this.folder.parent_id) {
                    return this.folder.acl_groups.manage;
                } else {
                    if(this.mode === 'edit' || this.mode === 'index-edit') {
                        return this.folder.parent_managers;
                    } else {
                        return this.folder.acl_groups.manage;
                    }
                }
            }
            return [];
        },
        managerOptions() {
            if(this.showParentManagers) {
                return this.userTargetIdentities.filter(user => {
                    return !this.parentManagers.find(m => m.unique_id === user.unique_id);
                });
            } else {
                return this.userTargetIdentities;
            }
        },
    },
    methods: {
        saveFolder() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.isSubmitLoading = true;
                this.form.type = this.type;
                if(this.mode === 'new') {
                    this.$store.dispatch('CREATE_FOLDER', this.form).then((data) => {
                        this.$toastr.s(this.$t('Saved'));
                        this.$nextTick(() => {
                            this.$bvModal.hide('folder-modal');
                            this.redirect(data);
                        });
                    }).finally(() => {
                        this.isSubmitLoading = false;
                    });
                }
                else if(this.mode === 'edit') {
                    this.$store.dispatch('EDIT_FOLDER', this.form).then(() => {
                        this.$toastr.s(this.$t('Updated'));
                        this.$nextTick(() => {
                            this.$bvModal.hide('folder-modal');
                        });
                    }).finally(() => {
                        this.isSubmitLoading = false;
                    });
                } else if(this.mode === 'index-edit') {
                    this.$store.dispatch('EDIT_SUB_FOLDER', this.form).then(() => {
                        this.$toastr.s(this.$t('Updated'));
                        this.$nextTick(() => {
                            this.$bvModal.hide('folder-modal');
                        });
                    }).finally(() => {
                        this.isSubmitLoading = false;
                    });
                }
            }
        },
        openAddModal() {
            this.openModal();
            this.$store.dispatch('GET_USER_TARGETGROUPS').then(() => {
                this.form.manage = this.setManager() || [];
                if (this.folder.id) {
                    this.form.parent_id = this.folder.id;
                }
            });
        },
        setManager() {
            if(this.showParentManagers) {
                let parentEmployee = false;
                if(this.currentEmployee) {
                    if(!this.parentManagers.find(m => m.unique_id === this.currentEmployee.unique_id)) {
                        return [this.currentEmployee];
                    } else {
                        parentEmployee = true;
                    }
                }

                if(this.currentGuardian && !this.parentManagers.find(m => m.unique_id === this.currentGuardian.unique_id) && !parentEmployee) {
                    return [this.currentGuardian];
                }
            } else {
                return [this.currentEmployee || this.currentGuardian];
            }
        },
        filterManagers(managers) {
            if(!this.showParentManagers) {
                return managers;
            } else {
                return managers.filter(user => {
                    return !this.parentManagers.find(m => m.unique_id === user.unique_id);
                });
            }
        },
        openEditModal(folder) {
            this.loading = true;
            this.openModal();
            this.$store.dispatch('GET_USER_TARGETGROUPS').then(() => {
                this.form.name = folder.name;
                this.form.public = folder.public;
                this.form.shared_edit = folder.shared_edit;
                this.form.folder_id = folder.id;
                this.form.parent_id = folder.parent_id;
                if(folder.acl_groups) {
                    this.form.view = folder.acl_groups.view;
                    this.form.edit = folder.acl_groups.edit;
                    this.form.manage = this.filterManagers(folder.acl_groups.manage);
                } else {
                    this.$store.dispatch('GET_FOLDER_ACL', folder.id).then(() => {
                        let f = this.subFolders.find(f => f.id === folder.id);
                        if(f.acl_groups) {
                            this.form.view = f.acl_groups.view;
                            this.form.edit = f.acl_groups.edit;
                            this.form.manage = this.filterManagers(f.acl_groups.manage);
                        }
                    });
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        resetForm() {
            this.$v.$reset();
            this.isSubmitLoading = false;
            this.form = {
                name: '',
                public: 0,
                shared_edit: 0,
                view: [],
                edit: [],
                manage: [],
                parent_id: null
            };
        },
        openModal() {
            this.$bvModal.show('folder-modal');
        },
        canDelete() {
            return this.folder.subFolders.length === 0 && this.folderFiles.length === 0 && this.folder.access === 'manage';
        },
        addFiles(files) {
            for (let i = 0; i < files.length; i++) {
                this.files.push(files[i]);
            }
        },
        canAddToFolder() {
            if (
                this.currentFolder.access === 'edit' ||
                this.currentFolder.acl_groups !== undefined
            ) {
                return true;
            }
        },
        typeTranslations(type) {
            if (type === 'group') {
                return 'Target group';
            } else {
                return this.$options.filters.capitalize(type);
            }
        },
        redirect(data) {
            if (data.type === 'gallery') {
                this.$router.push(`/gallery/${data.id}`);
            } else if (data.type === 'document') {
                this.$router.push(`/documents/${data.id}`);
            }
        }
    },
    components: {
        FolderUpload
    },
    created() {
        eventBus.$on('show-edit-folder-modal',  folder => {
            this.mode = 'edit';
            this.tab = 'folder';
            this.openEditModal(folder);
        });
        eventBus.$on('show-index-add-folder-modal',  () => {
            this.mode = 'new';
            this.index = true;
            this.openAddModal();
        });
        eventBus.$on('show-add-folder-modal',  folder => {
            this.mainFolder = folder.name;
            this.mode = 'new';
            this.openAddModal();
        });
        eventBus.$on('show-index-edit-folder-modal',  folder => {
            this.mode = 'index-edit';
            this.indexFolder = folder;
            this.tab = 'folder';
            this.openEditModal(folder);
        });
    },
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(1)
            },
            manage: {
                required: requiredIf(function() { return !this.folder.id || (!this.folder.parent_id && this.mode !== 'new'); }),
            }
        }
    },
    destroyed() {
        eventBus.$off('show-edit-folder-modal');
        eventBus.$off('show-index-edit-folder-modal');
        eventBus.$off('show-add-folder-modal');
        eventBus.$off('show-index-add-folder-modal');
    }
};
</script>
