import http from '@http';

class ShopService {
    constructor() {
        if (ShopService.instance) {
            return ShopService.instance;
        }
        ShopService.instance = this;
    }

    filters() {
        return http.get('/api/shop/filters').then(res => res.data);
    }
    categories() {
        return http.get('/api/shop/categories').then(res => res.data);
    }

    categoryProducts(slug, queryObj) {
        return http.get(`/api/shop/categories/${slug}/products`, { params: queryObj })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
    }

    categoryServices( slug, queryObj) {
        return http.get(`/api/shop/categories/${slug}/services`, { params: queryObj })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
    }

    kindergartenCategoryProducts(kindergartenId, slug, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/categories/${slug}/products`, { params: queryObj })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
    }
    
    kindergartenCategoryServices(kindergartenId, slug, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/categories/${slug}/services`, { params: queryObj })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
    }

    product(productSlug) {
        return http.get(`/api/shop/products/${productSlug}/show`).then(res => res.data);
    }

    kindergartenProduct(kindergartenId, productSlug) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/products/${productSlug}/show`).then(res => res.data);
    }

    service(serviceSlug) {
        return http.get(`/api/shop/services/${serviceSlug}/show`).then(res => res.data);
    }

    kindergartenService(kindergartenId, serviceSlug) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/services/${serviceSlug}/show`).then(res => res.data);
    }

    productSearch(queryObj) {
        return http.get('/api/shop/products/search', { params: queryObj }).then(res => res.data);
    }

    kindergartenProductSearch(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/products/search`, { params: queryObj }).then(res => res.data);
    }

    serviceSearch(queryObj) {
        return http.get('/api/shop/services/search', { params: queryObj }).then(res => res.data);
    }

    kindergartenServiceSearch(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/services/search`, { params: queryObj }).then(res => res.data);
    }

    popularShopItems() {
        return http.get('/api/shop/popular').then(res => res.data);
    }

    popularKindergartenShopItems(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/popular`).then(res => res.data);
    }

    addProductComment(slug, comment) {
        return http.post(`/api/shop/products/${slug}/comment`, {comment}).then(res => res.data);
    }

    addKindergartenProductComment(kindergartenId, slug, comment) {
        return http.post(`/api/kindergartens/${kindergartenId}/shop/products/${slug}/comment`, {comment}).then(res => res.data);
    }

    getProductComments(slug, queryObj) {
        return http.get(`/api/shop/products/${slug}/comments`, { params: queryObj }).then(res => res.data);
    }

    getKindergartenProductComments(kindergartenId, slug, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/products/${slug}/comments`, { params: queryObj }).then(res => res.data);
    }

    addServiceComment(slug, comment) {
        return http.post(`/api/shop/services/${slug}/comment`, {comment}).then(res => res.data);
    }

    addKindergartenServiceComment(kindergartenId, slug, comment) {
        return http.post(`/api/kindergartens/${kindergartenId}/shop/services/${slug}/comment`, {comment}).then(res => res.data);
    }

    getServiceComments(slug, queryObj) {
        return http.get(`/api/shop/services/${slug}/comments`, { params: queryObj }).then(res => res.data);
    }

    getKindergartenServiceComments(kindergartenId, slug, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/services/${slug}/comments`, { params: queryObj }).then(res => res.data);
    }

    rateProduct(slug, rating) {
        return http.post(`/api/shop/products/${slug}/rate`, {rating}).then(res => res.data);
    }

    rateService(slug, rating) {
        return http.post(`/api/shop/services/${slug}/rate`, {rating}).then(res => res.data);
    }

    getSupplierInfo(name) {
        return http.get(`/api/shop/suppliers/${name}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
    }

    getSupplierProducts(name, queryObj) {
        return http.get(`/api/shop/suppliers/${name}/products`, {params: queryObj}).then(res => res.data);
    }

    kindergartenGetSupplierProducts(kindergartenId, name, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/suppliers/${name}/products`, {params: queryObj}).then(res => res.data);
    }

    getSupplierServices(name, queryObj) {
        return http.get(`/api/shop/suppliers/${name}/services`, {params: queryObj}).then(res => res.data);
    }

    kindergartenGetSupplierServices(kindergartenId, name, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/suppliers/${name}/services`, {params: queryObj}).then(res => res.data);
    }

    getSuppliers() {
        return http.get('/api/shop/suppliers').then(res => res.data);
    }


    // wishlist
    addToKindergartenWishlist(kindergartenId, form) {
        return http.post(`/api/kindergartens/${kindergartenId}/shop/kindergarten-wishlist`, form).then(res => res.data);
    }
    wishlist(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/wishlist`, { params: queryObj }).then(res => res.data);
    }
    removeFromWishlist(kindergartenId, wishlistId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/shop/wishlist/${wishlistId}/delete`).then(res => res.data);
    }
    editWishlistItem(kindergartenId, wishlistId, form) {
        return http.put(`/api/kindergartens/${kindergartenId}/shop/wishlist/${wishlistId}/update`, form).then(res => res.data);
    }

    //Order
    kindergartenOrders(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/orders`, { params : queryObj }).then(res => res.data);
    }

    kindergartenOrder(kindergartenId, orderId) {
        return http.get(`/api/kindergartens/${kindergartenId}/shop/orders/${orderId}`).then(res => res.data);
    }

    cancelKindergartenOrder(kindergartenId, orderId, reason) {
        return http.put(`/api/kindergartens/${kindergartenId}/shop/orders/${orderId}`, {reason: reason}).then(res => res.data);
    }

    storeKindergartenOrder(kindergartenId, form) {
        return http.post(`/api/kindergartens/${kindergartenId}/shop/orders`, form).then(res => res.data);
    }

    //Cart
    getUserOrder(orderId) {
        return http.get(`/api/shop/orders/${orderId}`).then(res => res.data);
    }

    userOrders(queryObj) {
        return http.get('/api/shop/orders', { params : queryObj }).then(res => res.data);
    }

    storeUserOrder(form) {
        return http.post('/api/shop/orders', form).then(res => res.data);
    }

    cancelUserOrder(orderId, form) {
        return http.put(`/api/shop/orders/${orderId}`, form).then(res => res.data);
    }

    userCart() {
        return http.get('/api/shop/cart').then(res => res.data);
    }

    addToCart(form) {
        return http.post('/api/shop/cart', form).then(res => res.data);
    }

    editCartItem(itemId, data) {
        return http.put(`/api/shop/cart/${itemId}/update`, data).then(res => res.data);
    }

    removeFromCart(itemId) {
        return http.delete(`/api/shop/cart/${itemId}/delete`).then(res => res.data);
    }

    shopCampaign(id) {
        return http.get(`/api/campaign/${id}`).then(res => res.data);
    }

}

export default new ShopService();
