import store from '@/store';

const AdminTemplates = () => import(/* webpackChunkName: "user-module" */ './index');
const Templates = () => import(/* webpackChunkName: "user-module" */ './views/ChildDevTemplates');
const SettingAgeGroups = () => import(/* webpackChunkName: "user-module" */ './views/ChildDevSettingsAgeGroups');
const SettingGeneral = () => import(/* webpackChunkName: "user-module" */ './views/ChildDevSettingsGeneral');

export default [
    {
        path: '/child-dev',
        components: { base: AdminTemplates },
        children: [
            {
                path: 'templates',
                name: 'admin.child.dev.templates',
                components: { 'admin-child-dev': Templates },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessChildDevTemplatesIndex) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'settings',
                name: 'admin.child.dev.settings',
                redirect: 'settings/general'
            },
            {
                path: 'settings/general',
                name: 'admin.child.dev.settings.general',
                components: { 'admin-child-dev': SettingGeneral },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessChildDevTemplatesIndex) {
                        next();
                    } else {
                        next({ path: '/dashboard' });
                    }
                }
            },
            {
                path: 'settings/age-groups',
                name: 'admin.child.dev.settings.age-groups',
                components: { 'admin-child-dev': SettingAgeGroups },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessChildDevTemplatesIndex) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            }
        ]
    }
];
