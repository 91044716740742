import http from '@http';

class MessageService {
    constructor() {
        if (MessageService.instance) {
            return MessageService.instance;
        }

        MessageService.instance = this;
    }

    messages(type, queryObj) {
        return http.get(`/api/common/messages/${type}`, {params: queryObj}).then(res => res.data);
    }

    send(url, data) {
        return http.post(`/api/${url}/messages`, data).then(res => res.data);
    }

    update(data) {
        return http.put(`/api/common/messages/${data.id}`, data).then(res => res.data);
    }

    delete(id) {
        return http.delete(`/api/common/messages/${id}`).then(res => res.data);
    }

    getUserTargetGroups() {
        return http.get('/api/common/messages/groups').then(res => res.data);
    }

    getMessageFile(filename) {
        return http.download(`/api/files/messages/${filename}`).then(res => res);
    }

    getRecent() {
        return http.get('/api/common/messages/recent').then(res => res.data);
    }

    sendSystemMessage(data) {
        return http.post('/api/admin/message', data).then(res => res.data);
    }

    deleteSelected(selectedMessages) {
        return http.put('/api/common/messages/delete-selected', {messages: selectedMessages}).then(res => res.data);
    }

    messageDrafts(queryObj) {
        return http.get('/api/common/messages/drafts', {params: queryObj}).then(res => res.data);
    }

    messageDraft(messageId) {
        return http.get(`/api/common/messages/draft/${messageId}`).then(res => res.data);
    }

    getDraft(id) {
        return http.get(`/api/common/messages/drafts/${id}`).then(res => res.data);
    }

    createDraft(data) {
        return http.post('/api/common/messages/drafts', data).then(res => res.data);
    }

    saveDraft(draftId, data) {
        return http.put(`/api/common/messages/drafts/${draftId}`, data).then(res => res.data);
    }

    deleteDraft(draftId, queryObj) {
        return http.delete(`/api/common/messages/drafts/${draftId}`, {data: queryObj}).then(res => res.data);
    }

    deleteSelectedDrafts(data) {
        return http.delete('/api/common/messages/drafts/delete-selected', {data : data }).then(res => res.data);
    }

    deleteDraftFile(draftId, fileName) {
        return http.delete(`/api/common/messages/drafts/${draftId}/file`, {data : {filename: fileName}}).then(res => res.data);
    }

    sendMessage(url, draftId, draft) {
        return http.post(`/api/${url}/messages/${draftId}`, {draft: draft}).then(res => res.data);
    }

    uploadAttachment(url, draftId, data) {
        return http
            .post(
                `/api/${url}/messages/${draftId}/upload`,
                data
            )
            .then(res => res.data);
    }
}

export default new MessageService();
