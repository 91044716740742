<template>
    <i class="e3-svg-mdi" :class="icon"
       :style="hasInlineStyles ? {'width': `${size}px`, 'min-width': `${size}px`, 
                                  'height': `${size}px`, 'min-height': `${size}px`, 
                                  'background-size': `${size * 1.5}px auto !important`} : null"/>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: 'mdi-check'
        },
        size: {
            type: [String, Number],
            default: '18'
        },
        hasInlineStyles: {
            type: Boolean,
            default: true
        }
    },
};
</script>
