import http from '@http';
import {download} from '@services/helper.service';

class GalleryService {
    constructor() {
        if(GalleryService.instance) {
            return GalleryService.instance;
        }
        GalleryService.instance = this;
    }

    getIndexData(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/gallery`, {params: queryObj}).then(res => res.data);
    }

    deleteGalleryFile(kindergartenId, filename) {
        return http.delete(`/api/kindergartens/${kindergartenId}/files/gallery/${filename}`)
            .then(res => res.data);
    }

    getGalleryFile(kindergartenId, file) {
        return http.download(`/api/kindergartens/${kindergartenId}/files/gallery/${file.filename}`)
            .then(res => res.data);
    }

    downloadGalleryFile(kindergartenId, file) {
        return this.getGalleryFile(kindergartenId, file).then(image => download(image, file));
    }

    updateGalleryFile(kindergartenId, filename, form) {
        return http.put(`/api/kindergartens/${kindergartenId}/files/gallery/${filename}`, form).then(res => res.data);
    }
}
export default new GalleryService();
