<template>
    <div>
        <router-view/>
        <CookieConsentModal/>
        <ErrorMaintenance/>
    </div>
</template>

<script>
import { SET_LANGUAGE } from '@store/localization/constants';
import CookieConsentModal from '@/common/components/cookie/CookieConsentModal.vue';
import ErrorMaintenance from '@components/ErrorMaintenance.vue';
import { addScrollbarWidthToBody } from '@/utils/scrollbarUtils';
import axios from 'axios';

export default {
    data() {
        return {
            eventListenersAdded: false,
        };
    },

    methods: {
        setLanguage(lang) {
            this.$store.dispatch(SET_LANGUAGE, lang).catch(() => {
                if (lang !== 'en') {
                    this.setLanguage('en'); // infinite loop when EN not enabled for country?
                }
            });
        },
        unhandledRejection(e) {
            if (/loading chunk \d* failed./i.test(e.reason)) {
                const targetPath = this.$router.history.pending.fullPath;
                if (targetPath) {
                    window.location.pathname = targetPath;
                }
            }
        },
        checkRefreshCooldown() {
            const time = Date.now();
            const lastRefreshTime = localStorage.getItem('lastAutoRefresh');

            if (lastRefreshTime && (time - lastRefreshTime > 1000 * 60 * 60)) {
                return true;
            }

            return false;
        },
        getCachedAppVersion() {
            return localStorage.getItem('buildVersion');
        },
        reloadHandler() {
            const cachedVersion = this.getCachedAppVersion();

            if (cachedVersion && !document.hidden && navigator.onLine){
                if (this.checkRefreshCooldown()) {
                    localStorage.setItem('lastAutoRefresh', Date.now());
                    axios.get(`${window.location.origin}/version.json`)
                        .then((data) => {
                            if (data?.data?.version && data.data.version !== cachedVersion) {
                                window.location.reload();
                            }
                        })
                        .catch(() => {
                            console.warn('Failed to check for new version');
                        });
                }
            }
        }
    },
    components: {
        CookieConsentModal,
        ErrorMaintenance
    },
    created() {
        this.$store.dispatch('GET_LANGUAGES').then(() => {
            const lang = localStorage.getItem('lang') || 'en';
            const html = document.documentElement;
            html.setAttribute('lang', lang);
            this.setLanguage(lang);
            this.$store.dispatch('GET_SYSTEM_AUTH_PROVIDERS');
        });

        const lastRefreshTime = localStorage.getItem('lastAutoRefresh');

        if (!lastRefreshTime) {
            localStorage.setItem('lastAutoRefresh', Date.now());
        }

        // Set build version on initial load, otherwise there is nothing to compare against
        axios.get(`${window.location.origin}/version.json`)
            .then((data) => {
                if (data?.data?.version) {
                    localStorage.setItem('buildVersion', data.data.version);
                }
            })
            .catch(() => {
                console.warn('Failed to get version on app load');
            });
    },
    mounted() {
        addScrollbarWidthToBody(); // calculate scrollbar width for modal padding to prevent content shift

        // Prevent multiple event listeners from develompent hot reload
        if (!this.eventListenersAdded) {
            window.addEventListener('unhandledrejection', this.unhandledRejection); // catch unhandledRejection from promise
            document.addEventListener('visibilitychange', this.reloadHandler);
            window.addEventListener('focus', this.reloadHandler);
            this.eventListenersAdded = true;
        }
    },
    beforeDestroy() {
        // remove on unmount
        window.removeEventListener('unhandledrejection', this.unhandledRejection);
        document.addEventListener('visibilitychange', this.reloadHandler);
        window.addEventListener('focus', this.reloadHandler);
    },
};
</script>

<style lang="scss"></style>
