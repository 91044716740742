import AuthService from '@services/auth.service';
import {START_FULL_PAGE_LOADER, STOP_ALL_LOADERS} from '@store/loader/actions';
import {detectPlatform, detectBrowser} from '@services/helper.service';
import router from '../../routes/routes';

const state = {
    register_form: {
        role: '',
        fname: '',
        lname: '',
        email: '',
        password: '',
        method: '',
        lang: '',
        tz: new Date().getTimezoneOffset()
    }
};

const mutations = {
    ['UPDATE_REGISTER_FORM'](state, { role, country, method, data }) {
        if(role) {
            state.register_form.role = role;
            localStorage.setItem('register_role', role);
        }
        if(country) {
            state.register_form.country = country;
            localStorage.setItem('register_country', country);
        }
        if(method) {
            state.register_form.method = method;
        }
        if(data) {
            state.register_form.fname = data.fname;
            state.register_form.lname = data.lname;
            state.register_form.email = data.email;
            state.register_form.password = data.password;
        }
    },
    ['RESET_REGISTER_FORM'](state) {
        state.register_form = {
            role: '',
            fname: '',
            lname: '',
            email: '',
            password: '',
            method: '',
            lang: '',
            tz: new Date().getTimezoneOffset()
        };
    },
};

const actions = {
    ['SOCIAL_LOGIN']({ getters, commit }, data) {
        const role = localStorage.getItem('register_role');
        const country = localStorage.getItem('register_country');

        commit(START_FULL_PAGE_LOADER);
        if (role && role !== '') {
            data.role = role;
        }
        if (country && country !== '') {
            data.country = country;
        }
        data.lang = getters.language;
        data.tz = new Date().getTimezoneOffset();
        AuthService.socialLogin(data).then(() => {
            commit(STOP_ALL_LOADERS);
        });
    },
    ['LOGIN']({ commit }, data) {
        commit(START_FULL_PAGE_LOADER);
        return AuthService.login(data).then(message => {
            if(message) {
                return message;
            }

            commit(STOP_ALL_LOADERS);
        });
    },
    ['LOGIN_AS'](context, id) {
        AuthService.loginAs(id);
    },
    ['LOGOUT']({ commit }, redirectPath) {
        commit(START_FULL_PAGE_LOADER);
        AuthService.logout(redirectPath);
    },
    ['LOGOUT_AS']({ commit }, id) {
        commit(START_FULL_PAGE_LOADER);
        AuthService.logoutAs(id).then(() => {
            router.replace('/dashboard', () => {
                    location.reload();
                },
                () => {
                    location.reload();
                });
            commit(STOP_ALL_LOADERS);
        });
    },
    ['REGISTER_USER']({ getters, commit }) {
        commit(START_FULL_PAGE_LOADER);
        let data = getters.registerForm;
        data.lang = getters.language;
        return AuthService.registerUser(data).then(res => {
            commit(STOP_ALL_LOADERS);
            commit('RESET_REGISTER_FORM');
            return res;
        });
    },
    ['REGISTER_EMPLOYEE_USER']({ dispatch, commit }, data) {
        data.lang = localStorage.getItem('lang') || 'en';
        data.tz = new Date().getTimezoneOffset();
        return AuthService.registerEmployeeUser(data).then(() => {
            const form = {
                username: data.email,
                password: data.password,
                platform: {
                    platform: detectPlatform(),
                    browser: detectBrowser(),
                    login: 'password'
                },
                // platform: [detectPlatform(), detectBrowser(), 'password']
            };
            commit('RESET_REGISTER_FORM');
            dispatch('LOGIN', form).then(res => {
                return res;
            });
        });
    }
};

const getters = {
    registerForm: state => {
        return state.register_form;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
