<template>
    <div :class="[isGallery ? 'e3-folder green text-success': 'e3-folder orange', {'grid' : isGrid}]">
        <div>
            <SvgIcon  v-if="isReadonly" icon="mdi-eye" class="text-white-50 e3-svg-mdi" :hasInlineStyles="false"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isGrid: {
            type: Boolean,
            default: false,
        },
        isGallery: {
            type: Boolean,
            default: false,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
@import "@common/styles/variables/_variables.scss";

    .e3-folder {
        position: relative;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        align-items: center;

        margin-top: 2rem;
        width: 65px;
        height: 42px;
        border-radius: 0px 6px 6px 6px;

        &::before {
            content: '';
            display: block;
            position: absolute;

            top:-8px;
            left: 0;
            width: 32px;
            height: 8px;
            border-radius: 6px 0px 0px 0px;
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            height: 0;

            top:-16px;
            left: 24px;
            border: 8px solid transparent;
        }
    }

    .e3-folder.small {
        margin-top:0.2rem;
        min-height: 23px;
        min-width: 38px;

        &::before { 
            top: -6px;
            width: 17px;
            height: 6px;
            border-radius: 4px 0px 0px 0px;
        }
        &::after {
            top:-12px;
            left: 11px;
            border: 6px solid transparent;
        }
    }

    .e3-folder:not(.small) .e3-svg-mdi {
        width: 38px;
        height: 38px;
        background-size: 57px auto !important;
    }

    .e3-folder.small .e3-svg-mdi {
        width: 19px;
        height: 19px;
        background-size: 28.5px auto !important;
    }

    @media (max-width: 991.98px) {
        .e3-folder:not(.grid):not(.small) {
            margin-top: 8px;
            min-width: 34px;
            width: 34px;
            height: 20px;
            border-radius: 0px 4px 4px 4px;
            &::before {
                top:-6px;
                left: 0;
                width: 16px;
                height: 6px;
                border-radius: 4px 0px 0px 0px;
            }
            &::after {
                top: -12px;
                left: 10px;
                border: 6px solid transparent;
            }
        }

        .e3-folder:not(.small) .e3-svg-mdi {
            position: relative;
            height: 18px;
            width: 18px;
            background-size: 27px auto !important;
        }
    }
    
    .small {
        margin-top: 8px;
        width: 34px;
        height: 20px;
        border-radius: 0px 4px 4px 4px;
        &::before {
            top:-6px;
            left: 0;
            width: 16px;
            height: 6px;
            border-radius: 6px 0px 0px 0px;
        }
        &::after {
            top: -12px;
            left: 10px;
            border: 6px solid transparent;
        }
    }
    
    $folder-orange: #f79e80;
    $folder-cyan: #23CBF4;
    $folder-green: #a5c365;
    $folder-pink: #e082b1;

    .e3-folder.orange{ background-color: $folder-orange;}
    .e3-folder.orange:before, .e3-folder.orange span{ background-color: darken($folder-orange, 7%);}
    .e3-folder.orange:after{ border-bottom-color: darken($folder-orange, 7%) !important;}
    .e3-folder.orange:hover {
        background-color: lighten($folder-orange, 10%);
        &::before {
            background-color: lighten(darken($folder-orange, 7%), 10%);
        }
        &::after {
            border-bottom-color: lighten(darken($folder-orange, 7%), 10%) !important;
        }
    }
    // .e3-folder.orange span{color: #FFFFFF;}
    // .e3-folder.orange i{color: darken(#f79e80, 7%);}

    .e3-folder.cyan{ background-color: $folder-cyan;}
    .e3-folder.cyan:before, .e3-folder.cyan span{ background-color: darken($folder-cyan, 7%);}
    .e3-folder.cyan:after{ border-bottom-color: darken($folder-cyan, 7%) !important;}

    .e3-folder.green{ background-color: $folder-green;}
    .e3-folder.green:before, .e3-folder.green span{ background-color: darken($folder-green, 7%);}
    .e3-folder.green:after{ border-bottom-color: darken($folder-green, 7%) !important;}

    .e3-folder.pink{ background-color: $folder-pink;}
    .e3-folder.pink:before, .e3-folder.pink span{ background-color: darken($folder-pink, 7%);}
    .e3-folder.pink:after{ border-bottom-color: darken($folder-pink, 7%) !important;}
</style>
