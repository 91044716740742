import http from '@http';
import {download} from '@/common/services/helper.service';

class ChildService {
    constructor() {
        if (ChildService.instance) {
            return ChildService.instance;
        }

        ChildService.instance = this;
    }

    getChildren(queryObj) {
        return http.get('/api/admin/children', { params: queryObj }).then(res => res.data);
    }

    getChild(kindergartenId, childId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}`).then(res => res.data);
    }

    getChildProfile(kindergartenId, childId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/profile`).then(res => res.data);
    }

    updateChildProfile(kindergartenId, childId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/children/${childId}`, data).then(res => res.data);
    }

    store(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/store`, data).then(res => res.data);
    }

    guardians(kindergartenId, childId) {
        return http .get(`/api/kindergartens/${kindergartenId}/children/${childId}/guardians`).then(res => res.data);
    }

    maps(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${queryObj.child_id}/maps`,
            {params: queryObj}).then(res => res.data);
    }

    courses(kindergartenId, childId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/courses`).then(res => res.data);
    }

    childCoursesWithFeedback(kindergartenId, childId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/courses-with-feedback`).then(res => res.data);
    }

    getChildPlansList(kindergartenId, childId, courseId, type) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/courses/${courseId}/plan-list`, {params: {type}})
            .then(res => res.data);
    }

    getChildPlan(kindergartenId, childId, planId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/plans/${planId}`).then(res => res.data);
    }

    getChildProjectPlans(kindergartenId, data) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${data.childId}/courses/${data.courseId}/prplan-list`)
            .then(res => res.data);
    }

    getChildProjectPlan(kindergartenId, childId, planId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/prplan/${planId}`)
            .then(res => res.data);
    }

    getChildWeekPlans(kindergartenId, data) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${data.childId}/courses/${data.courseId}/wplan-list`)
            .then(res => res.data);
    }
    getChildYearPlans(kindergartenId, data) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${data.childId}/courses/${data.courseId}/yplan-list`)
            .then(res => res.data);
    }

    getChildYearPlan(kindergartenId, childId, planId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/yplan/${planId}`)
            .then(res => res.data);
    }

    getChildCoursePlanTypes(kindergartenId, childId, courseId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/planTypes`, {
            params: {course_id: courseId}
        }).then(res => res.data);
    }

    addChildDates(kindergartenId, childId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/add-course`, data).then(res => res.data);
    }

    removeChildDates(kindergartenId, childId, courseChildId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/children/${childId}/delete-course/${courseChildId}`)
            .then(res => res.data);
    }

    updateChildDates(kindergartenId, childId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/children/${childId}/update-course/${data.courseChildId}`, data).then(res => res.data);
    }

    addGuardian(kindergartenId, childId, guardianId, form) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/guardians/${guardianId}`, form).then(res => res.data);
    }

    getAbsences(kindergartenId, queryObj) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${queryObj.child_id}/absences`, {params : queryObj}).then(res => res.data);
    }

    addAbsence(kindergartenId, childId, form) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/absences`, form).then(res => res.data);
    }

    getAbsenceModalData(kindergartenId, childId, form) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/absences/modal`, {params: form}).then(res => res.data);
    }

    archiveChild(kindergartenId, childId, leaveDate) {
        return http.put(`/api/kindergartens/${kindergartenId}/children/${childId}/archive`, {leaveDate: leaveDate}).then(res => res.data);
    }

    restoreChild(kindergartenId, childId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/children/${childId}/activate`, data).then(res => res.data);

    }

    getChildApprovals(kindergartenId, childId) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/approvals`).then(res => res.data);
    }

    updateApprovalAnswers(kindergartenId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/children/${data.childId}/approvals`, { approvals: data.approvals })
          .then(res => res.data);
    }

    getChildCalendar(kindergartenId, childId, data) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/calendar`, {params: data})
            .then(res => res.data);
    }

    saveChildCalendarStatus(kindergartenId, childId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/calendar/status`, data)
            .then(res => res.data);
    }

    saveChildCalendarDay(kindergartenId, childId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/calendar/day`, data)
            .then(res => res.data);
    }

    saveChildCalendarComment(kindergartenId, childId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/calendar/comment`, data)
            .then(res => res.data);
    }

    saveChildCalendarDays(kindergartenId, childId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/calendar/days`, data)
            .then(res => res.data);
    }

    saveChildCalendarTimes(kindergartenId, childId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/calendar/times`, data)
            .then(res => res.data);
    }

    getStatusLogs(kindergartenId, childId, date) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/status-log`, {params: {date}})
            .then(res => res.data);
    }

    getFiles(kindergartenId, childId, page, perPage) {
        return http.get(`/api/kindergartens/${kindergartenId}/children/${childId}/files`, {params: {page, perPage}});
    }

    uploadFile(kindergartenId, childId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/files`, data)
            .then(res => res.data);
    }

    deleteChildFile(kindergartenId, childId, filename) {
        return http.delete(`/api/kindergartens/${kindergartenId}/children/${childId}/files/${filename}`)
            .then(res => res.data);
    }

    getChildFile(kindergartenId, childId, file) {
        return http.download(`/api/kindergartens/${kindergartenId}/children/${childId}/files/${file.filename}`)
            .then(res => download(res.data, file));
    }

    updateChildFile(kindergartenId, childId, filename, form) {
        return http.put(`/api/kindergartens/${kindergartenId}/children/${childId}/files/${filename}`, form).then(res => res.data);
    }
}

export default new ChildService();
