<template>
    <div :class="border ? 'bg-secondary rounded p-3' : ''" :title="displayTitle && !!name ? name : false">
        <div
            class="uname-avatar"
            :class="['size-' + size, {'square': square}]"
            :style="{backgroundColor: color.hex}">
            <span
                class="uname-avatar-initials"
                :style="{color: color.text || '#fff'}">
                {{nameInitials}}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'md'
        },
        square: {
            type: Boolean,
            default: false
        },
        border: {
            type: Boolean,
            default: false
        },
        displayTitle: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            alphabetColors: [
                {char: 'a', hex: '#ef5350', text: '#fff'},
                {char: 'b', hex: '#ec407a', text: '#fff'},
                {char: 'c', hex: '#ab47bc', text: '#fff'},
                {char: 'd', hex: '#7e57c2', text: '#fff'},
                {char: 'e', hex: '#a5c365', text: '#fff'},
                {char: 'f', hex: '#42a5f5', text: '#fff'},
                {char: 'g', hex: '#29b6f6', text: '#fff'},
                {char: 'h', hex: '#26c6da', text: '#fff'},
                {char: 'i', hex: '#26a69a', text: '#fff'},
                {char: 'j', hex: '#66bb6a', text: '#fff'},
                {char: 'k', hex: '#5c6bc0', text: '#fff'},
                {char: 'l', hex: '#d4e157', text: '#fff'},
                {char: 'm', hex: '#ffee58', text: '#fff'},
                {char: 'n', hex: '#ffca28', text: '#fff'},
                {char: 'o', hex: '#ffa726', text: '#fff'},
                {char: 'p', hex: '#ff7043', text: '#fff'},
                {char: 'q', hex: '#8d6e63', text: '#fff'},
                {char: 'r', hex: '#01579b', text: '#fff'},
                {char: 's', hex: '#ff6f00', text: '#fff'},
            ]
        };
    },
    computed: {
        color() {
            const firstInitial = this.$slots.default
                ? this.$slots.default[0].text.trim().charAt(0).toLowerCase()
                : 'e';
            const firstLetterCode = firstInitial.charCodeAt(0);
            const unicodeFirstAlpha = firstLetterCode > 97 ? (firstLetterCode - 97) : firstLetterCode;
            return this.alphabetColors[unicodeFirstAlpha % this.alphabetColors.length];
        },
        nameInitials() {
            let initials = 'E';
            if (!!this.$slots.default && !!this.$slots.default[0].text) {
                const names = this.$slots.default[0].text.trim().split(' ');

                initials = names[0].charAt(0);
                if (names.length > 1) {
                    initials += names[names.length - 1].charAt(0);
                } else if (names[0].length === 2) {
                    initials = names[0].toUpperCase();
                }
            }
            return initials;
        },
        name() {
            return this.$slots.default[0].text ? this.$slots.default[0].text : '';
        }
    }
};
</script>

<style scoped lang="scss">
    .uname-avatar {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #a5c365;
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);

        .uname-avatar-initials {
            color: #fff;
            font-size: 22px;
            line-height: 1;
        }

        &.square {
            border-radius: .33rem;
        }
        &.size-xxs {
            width: 17px;
            height: 17px;
            .uname-avatar-initials {
                font-size: 9px;
            }
        }
        &.size-xs {
            width: 23px;
            height: 23px;
            .uname-avatar-initials {
                font-size: 10px;
            }
        }
        &.size-sm {
            width: 36px;
            height: 36px;
            .uname-avatar-initials {
                font-size: 14px;
            }
        }
        &.size-lg {
            width: 104px;
            height: 104px;
            .uname-avatar-initials {
                font-size: 46px;
            }
            &.square {
                border-radius: .5rem;
            }
        }
        &.size-xl {
            width: 160px;
            height: 160px;
            .uname-avatar-initials {
                font-size: 80px;
            }
            &.square {
                border-radius: .5rem;
            }
        }
    }
</style>
