import {
    RESET_PLAN_STATE,
    ADD_COURSE_PLAN,
    ADD_PLAN_SETTING,
    CONFIRM_PLAN,
    DELETE_PLAN_SETTING,
    GET_COURSE_PLAN_TYPES,
    GET_COURSE_PLANS,
    GET_PLAN,
    GET_PLAN_SETTINGS,
    GET_PLAN_TYPES,
    GET_PROJECT_PLAN,
    GET_PROJECT_PLANS,
    PUBLISH_PLAN,
    REJECT_PLAN,
    REMOVE_PLAN_SETTING,
    SELECT_PROJECT_PLAN,
    SET_COMMENT,
    SET_COURSE_PLAN_TYPES,
    SET_COURSE_PLANS,
    SET_PLAN,
    SET_PLAN_DATE,
    SET_PLAN_SETTINGS,
    SET_PLAN_STATUS,
    SET_PLAN_TYPES,
    SET_PROJECT_PLAN,
    SET_PROJECT_PLANS,
    STORE_PLAN,
    STORE_PLAN_SETTING,
    STORE_PROJECT_PLAN,
    UPDATE_PROJECT_PLAN,
    UPDATE_PLAN_FIELD,
    UPDATE_PLAN_SETTING,
    DELETE_PLAN,
    REMOVE_COURSE_PLAN,
    SET_PLAN_ERROR,
    SET_SELECTED_PLAN_TYPE,
    SET_SUMMARY_OPTIONS,
    UPLOAD_PLAN_DOCUMENT,
    ADD_PLAN_DOCUMENT,
    SET_PLAN_DOCUMENTS,
    DELETE_PLAN_DOCUMENT,
    REMOVE_PLAN_DOCUMENT,
    GET_YEAR_PLANS,
    GET_YEAR_PLAN,
    SET_YEAR_PLANS,
    SELECT_YEAR_PLAN,
    STORE_YEAR_PLAN,
    GET_COURSE_PLAN_OVERVIEW,
    UPDATE_YEAR_PLAN
} from './actions';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import PlanService from '@services/plan.service';
import Vue from 'vue';
import orderBy from 'lodash/orderBy';

const getDefaultState = () => {
    return {
        plan : {},
        course_plan_types: [],
        course_plans: [],
        course_templates: [],
        plan_types: [],
        projectPlanList: [],
        yearPlansList: [],
        //temporary date to switch from overview to specific plan
        selected_date: null,
        //temporary project plan id to switch from overview to specific plan
        selected_project: null,
        selected_year_plan: null,
        statuses: {
            1: 'approved by manager',
            3: 'rejected by manager'
        },
        error: null,
        selected_type: {},
        summary_options: [],
        documents: []
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_PLAN_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_PLAN](state, plan) {
        state.plan = plan;
    },
    [SET_PROJECT_PLAN](state, plan) {
        state.plan = plan;
        state.projectPlanList.push(plan);
    },
    [SET_COURSE_PLAN_TYPES](state , types) {
        state.course_plan_types = types;
    },
    [SET_COURSE_PLANS](state, plans) {
        state.course_plans = plans;
    },
    [ADD_COURSE_PLAN](state, plan) {
        state.course_plans.push(plan);
        state.course_plans = orderBy(state.course_plans, 'start_date', 'desc');
    },
    [SET_PLAN_TYPES](state, types) {
        state.plan_types = types;
    },
    [SET_PLAN_SETTINGS](state, settings) {
        state.course_templates = settings;
    },
    [UPDATE_PLAN_SETTING](state, setting) {
        const plan = state.course_templates.find((template) => template.id === setting.id);
        if (plan) {
            plan.plan_teacher_fields = setting.plan_teacher_fields;
        }
    },
    [ADD_PLAN_SETTING](state, setting) {
        state.course_templates.push(setting);
        state.course_templates = sortBy(state.course_templates, t => t.name);
    },
    [REMOVE_PLAN_SETTING](state, settingId) {
        const filteredList = state.course_templates.filter(t => t.id !== settingId);
        Vue.set(state, 'course_templates', [...filteredList]);
    },
    [SET_PROJECT_PLANS](state, plans) {
        state.projectPlanList = plans;
    },
    [UPDATE_PROJECT_PLAN](state, plan) {
        let index = state.course_plans.findIndex(t => t.id === plan.id);
        if(index !== -1) {
            Vue.set(state.course_plans, index, plan);
        } else {
            state.course_plans.push(plan);
        }
    },
    [SET_PLAN_STATUS](state, data) {
        state.plan.status = data.status;
        state.plan.status_at = data.status_at;
        state.plan.status_by = data.status_by;
    },
    [PUBLISH_PLAN](state, status) {
        state.plan.published = status;
    },
    [SET_COMMENT](state, comment) {
        state.plan.comment = comment;
    },
    [SET_PLAN_DATE](state, date) {
        state.selected_date = date;
    },
    [SELECT_PROJECT_PLAN](state, id) {
        state.selected_project = id;
    },
    [UPDATE_PLAN_FIELD](state, field) {
        let index = state.plan.data.findIndex(f => f.field_id === field.field_id && f.employee_id === field.employee_id);
        if(index !== -1) {
            const fieldData = state.plan.data.find(f => f.field_id === field.field_id && f.employee_id === field.employee_id);
            Vue.set(state.plan.data, index, {...fieldData, ...field});
        } else {
            state.plan.data.push(field);
        }
    },
    [REMOVE_COURSE_PLAN](state, planId) {
        const filteredList = state.course_plans.filter(p => p.id !== planId);
        Vue.set(state, 'course_plans', [...filteredList]);
    },
    [SET_PLAN_ERROR](state, message) {
        state.error = message;
    },
    [SET_SELECTED_PLAN_TYPE](state, type) {
        state.selected_type = type;
    },
    [SET_SUMMARY_OPTIONS](state, options) {
        state.summary_options = options;
    },
    [SET_PLAN_DOCUMENTS](state, files) {
        state.documents = files;
    },
    [ADD_PLAN_DOCUMENT](state, file) {
        state.documents.push(file);
    },
    [REMOVE_PLAN_DOCUMENT](state, filename) {
        let index = state.documents.findIndex(f => f.filename === filename);
        if(index !== -1) {
            state.documents.splice(index, 1);
        }
    },
    [SET_YEAR_PLANS](state, data) {
        state.yearPlansList = data;
    },
    [SELECT_YEAR_PLAN](state, id) {
        state.selected_year_plan = id;
    },
    [UPDATE_YEAR_PLAN](state, plan) {
        let index = state.course_plans.findIndex(t => t.id === plan.id);
        if(index !== -1) {
            Vue.set(state.course_plans, index, plan);
        } else {
            state.course_plans.push(plan);
        }
    },
};

const actions = {
    [GET_PLAN]({commit, getters}, data) {
        commit('START_CONTENT_LOADER');
        commit(SET_PLAN_ERROR, null);
        commit(SET_PLAN, {});
        commit(SET_SUMMARY_OPTIONS, []);
        commit(SET_PLAN_DOCUMENTS, []);
        return PlanService.getPlan(getters.activeKindergartenId, data).then(res => {
            if(res.summary_options) {
                commit(SET_SUMMARY_OPTIONS, res.summary_options);
                delete(res.summary_options);
            }
            if(res.documents) {
                commit(SET_PLAN_DOCUMENTS, res.documents);
                delete(res.documents);
            }

            commit(SET_PLAN, res);
        }).catch(error => {
            commit(SET_PLAN_ERROR, error.response.data.message);
        }).finally(() => {
            commit('STOP_CONTENT_LOADER');
        });
    },
    [STORE_PLAN]({commit, getters}, data) {
        return PlanService.store(getters.activeKindergartenId, data).then(res => {
            if(res.summary_options) {
                commit(SET_SUMMARY_OPTIONS, res.summary_options);
                delete(res.summary_options);
            }
            commit(SET_PLAN, res);
        });
    },
    [UPDATE_PLAN_FIELD]({commit, getters}, {planId, data}) {
        return PlanService.updateField(getters.activeKindergartenId, planId, data).then(res => {
            let resCopy =  cloneDeep(res);
            if(res.status !== undefined) {
                commit(SET_PLAN_STATUS, res.status);
                delete(res.status);
            }

            commit(UPDATE_PLAN_FIELD, res);

            return resCopy;

        });
    },
    [GET_COURSE_PLAN_TYPES]({commit, getters}, {courseId, type}) {
        commit(SET_COURSE_PLANS, []);
        return PlanService.getCoursePlanTypes(getters.activeKindergartenId, courseId, type).then(res => {
            commit(SET_COURSE_PLAN_TYPES, res);
            return res[0] ?? {};
        });
    },
    [GET_COURSE_PLANS]({commit, getters}, {courseId, type, year}) {
        commit(SET_COURSE_PLANS, []);
        commit(SET_PLAN_ERROR, null);
        return PlanService.getCoursePlans(getters.activeKindergartenId, courseId, type, year).then(res => {
            commit(SET_COURSE_PLANS, res);
        }).catch(error => {
            commit(SET_PLAN_ERROR, error.response.data.message);
        });
    },
    [GET_COURSE_PLAN_OVERVIEW]({commit, getters}, {courseId, type}) {
        commit(SET_COURSE_PLANS, []);
        commit(SET_PLAN_ERROR, null);
        return PlanService.getCoursePlansOverView(getters.activeKindergartenId, courseId, type).then(res => {
            commit(SET_COURSE_PLANS, res);
        }).catch(error => {
            commit(SET_PLAN_ERROR, error.response.data.message);
        });
    },
    [GET_PLAN_TYPES]({commit, getters}, type) {
        commit(SET_COURSE_PLANS, []);
        if(getters.planTypes.length) {
            return getters.planTypes[0];
        }
        return PlanService.getPlanSubtypes(getters.activeKindergartenId, type).then(res => {
            commit(SET_PLAN_TYPES, res);
            return res[0] ?? {};
        });
    },
    [STORE_PLAN_SETTING]({commit, getters}, data) {
        return PlanService.storePlanSetting(getters.activeKindergartenId, data).then(res => {
            commit(ADD_PLAN_SETTING, res);
        });
    },
    [GET_PLAN_SETTINGS]({commit, getters}, queryObj) {
        return PlanService.getPlanSettings(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_PLAN_SETTINGS, res);
        });
    },
    [UPDATE_PLAN_SETTING]({commit, getters}, {settingId, data}) {
        return PlanService.updatePlanSetting(getters.activeKindergartenId, settingId, data).then(res => {
            commit(UPDATE_PLAN_SETTING, res);
        });
    },
    [DELETE_PLAN_SETTING]({commit, getters}, id) {
        return PlanService.deletePlanSetting(getters.activeKindergartenId, id).then(() => {
            commit(REMOVE_PLAN_SETTING, id);
        });
    },
    [GET_YEAR_PLANS]({commit, getters}, data) {
        commit(SET_PLAN_ERROR, null);
        commit(SET_PLAN, {});
        return PlanService.getYearPlans(getters.activeKindergartenId, data.courseId, data.type_id).then(res => {
            commit(SET_YEAR_PLANS, res);
            return res;
        });
    },
    [GET_YEAR_PLAN]({commit, getters}, planId) {
        commit(SET_PLAN_ERROR, null);
        commit(SET_PLAN, {});
        commit(SET_SUMMARY_OPTIONS, []);
        commit(SET_PLAN_DOCUMENTS, []);

        return PlanService.getYearPlan(getters.activeKindergartenId, planId).then(res => {
            if(res.summary_options) {
                commit(SET_SUMMARY_OPTIONS, res.summary_options);
                delete(res.summary_options);
            }
            if(res.documents) {
                commit(SET_PLAN_DOCUMENTS, res.documents);
                delete(res.documents);
            }
            commit(SET_PLAN, res);
        });
    },
    [STORE_YEAR_PLAN]({commit, getters}, data) {
        commit(SET_PLAN_ERROR, null);
        commit(SET_PLAN, {});
        commit(SET_SUMMARY_OPTIONS, []);
        commit(SET_PLAN_DOCUMENTS, []);
        return PlanService.storeYearPlan(getters.activeKindergartenId, data).then(res => {
            if(res.summary_options) {
                commit(SET_SUMMARY_OPTIONS, res.summary_options);
                delete(res.summary_options);
            }
            commit(SET_PLAN, res);
            return res;
        });
    },
    [UPDATE_YEAR_PLAN]({commit, getters}, {id, data}) {
        return PlanService.updateYearPlan(getters.activeKindergartenId, id, data).then(res => {
            commit(UPDATE_YEAR_PLAN, res);
        });
    },
    [GET_PROJECT_PLANS]({commit, getters}, courseId) {
        commit(SET_PLAN_ERROR, null);
        commit(SET_PLAN, {});
        return PlanService.getProjectPlans(getters.activeKindergartenId, courseId).then(res => {
            commit(SET_PROJECT_PLANS, res);
            return res;
        });
    },
    [GET_PROJECT_PLAN]({commit, getters}, planId) {
        commit(SET_PLAN_ERROR, null);
        commit(SET_PLAN, {});
        commit(SET_SUMMARY_OPTIONS, []);
        commit(SET_PLAN_DOCUMENTS, []);
        return PlanService.getProjectPlan(getters.activeKindergartenId, planId).then(res => {
            if(res.summary_options) {
                commit(SET_SUMMARY_OPTIONS, res.summary_options);
                delete(res.summary_options);
            }
            if(res.documents) {
                commit(SET_PLAN_DOCUMENTS, res.documents);
                delete(res.documents);
            }

            commit(SET_PLAN, res);
        });
    },
    [UPDATE_PROJECT_PLAN]({commit, getters}, {id, data}) {
        return PlanService.updateProjectPlan(getters.activeKindergartenId, id, data).then(res => {
            commit(UPDATE_PROJECT_PLAN, res);
        });
    },
    [STORE_PROJECT_PLAN]({commit, getters}, data) {
        commit(SET_PLAN_ERROR, null);
        commit(SET_PLAN, {});
        commit(SET_SUMMARY_OPTIONS, []);
        commit(SET_PLAN_DOCUMENTS, []);
        return PlanService.storeProjectPlan(getters.activeKindergartenId, data).then(res => {
            if(res.summary_options) {
                commit(SET_SUMMARY_OPTIONS, res.summary_options);
                delete(res.summary_options);
            }
            commit(SET_PROJECT_PLAN, res);
            return res;
        });
    },
    [CONFIRM_PLAN]({commit, getters}, planId) {
        return PlanService.confirmPlan(getters.activeKindergartenId, planId).then(res => {
            commit(SET_PLAN_STATUS, res);
            return res;
        });
    },
    [REJECT_PLAN]({commit, getters}, {planId, comment}) {
        return PlanService.rejectPlan(getters.activeKindergartenId, planId, {comment: comment}).then(res => {
            commit(SET_PLAN_STATUS, res);
            commit(SET_COMMENT, comment);
            return res;
        });
    },
    [PUBLISH_PLAN]({commit, getters}, {planId, status}) {
        return PlanService.publishPlan(getters.activeKindergartenId, planId, status).then(res => {
            commit(PUBLISH_PLAN, res.published);
        });
    },
    [DELETE_PLAN]({commit, getters}, planId) {
        return PlanService.deletePlan(getters.activeKindergartenId, planId).then(res => {
            if(res.deleted) {
                commit(REMOVE_COURSE_PLAN, planId);
            }
        });
    },
    [UPLOAD_PLAN_DOCUMENT]({commit, getters}, data) {
        return PlanService.uploadDocument(getters.activeKindergartenId, data.planId, data.form).then(res => {
            commit(ADD_PLAN_DOCUMENT, res.file);
            return res;
        });
    },
    [DELETE_PLAN_DOCUMENT]({commit, getters}, filename) {
        return PlanService.deleteDocument(getters.activeKindergartenId, filename).then(() => {
            commit(REMOVE_PLAN_DOCUMENT, filename);
        });
    }
};

const getters = {
    currentPlan: state => state.plan,
    planTypes: state => state.plan_types,
    planErrorMessage: state => state.error,
    coursePlans: state => state.course_plans,
    planStatusMessages: state => state.statuses,
    planSettings: state => state.course_templates,
    projectPlans: state => state.projectPlanList,
    selectedPlanDate: state => state.selected_date,
    selectedPlanType: state => state.selected_type,
    coursePlanTypes: state => state.course_plan_types,
    selectedProjectPlanId: state => state.selected_project,
    currentPlanSummaryOptions: state => state.summary_options,
    currentPlanDocuments: state => state.documents,
    selectedYearPlanId: state => state.selected_year_plan,
    yearPlans: state => state.yearPlansList
};

export default {
    state,
    mutations,
    actions,
    getters
};
