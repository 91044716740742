import axios from 'axios';
import Vue from 'vue';
import {vm} from '@/main';
import store from '../../store';
import router from '../../routes/routes';
import authService from '@services/auth.service';
import i18n from '@/i18n';

class HttpService {
    constructor() {
        this.generateCancelToken();

        if (HttpService.instance) {
            return HttpService.instance;
        }

        if (process.env.NODE_ENV === 'development') {
            axios.defaults.baseURL = process.env.VUE_APP_API_URL;
        } else {
            axios.defaults.baseURL = `https://api.${window.location.hostname}`;
        }

        axios.defaults.withCredentials = true;

        axios.interceptors.request.use(
            config => {
                if (!(config.method === 'get' && config.url.includes('api/lang'))) {
                    config.cancelToken = this.cancelToken;
                }

                return config;
            },
            error => {
                store.commit('STOP_ALL_LOADERS');
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(response => {
            if (response && [201, 200].includes(response.status)) {
                return response;
            }
            return Promise.reject(response);
        }, error => {
            store.commit('STOP_ALL_LOADERS');

            if (error.code === 'ERR_NETWORK') {
                Vue.prototype.$toastr.e(i18n.t('Network Error'));
                return Promise.reject(error);
            } else if (error.code === 'ECONNABORTED') {
                /* Can occur on browser redirect */
                return Promise.reject(error);
            } else if (error.code === 'ERR_CANCELED') {
                /* Can occur when another request returns Unauthorized before this one resolves */
                return Promise.reject(error);
            }

            if (error.response) {
                switch (error.response.status) {
                    case 401: {
                        this.cancelRequests(error.response);
                        // authService.logout();
                        authService.reloadEnvironment();
                        break;
                    }
                    case 429: {
                        this.cancelRequests(error.response);
                        router.replace({'query': null});
                        authService.logout();
                        break;
                    }
                    case 404:
                    case 403: {
                        if(error.response.data.reload) {
                            location.replace('/dashboard');
                        }
                        break;
                    }
                    case 500:
                    case 422: {
                        break;
                    }
                    case 503: {
                        vm.$bvModal.show('maintenance-error-modal');
                        break;
                    }
                    case 417: {
                        // SocialAccount abort - email missing or wrong idcode from HarID
                        location.replace('/auth/login');
                        break;
                    }
                    default: {
                        break;
                    }
                }

                if (error.response.data) {
                    if (error.response.data.errors) {
                        Object.keys(error.response.data.errors).forEach(key => {
                            Vue.prototype.$toastr.e(error.response.data.errors[key]);
                        });
                    } else if (error.response.data.message) {
                        Vue.prototype.$toastr.e(i18n.t(error.response.data.message));
                    } else {
                        Vue.prototype.$toastr.e(i18n.t('error'));
                    }
                } else if (error.response.message) {
                    Vue.prototype.$toastr.e(i18n.t(error.message));
                } else {
                    Vue.prototype.$toastr.e(error);
                }

            } else if (!error.status) {
                Vue.prototype.$toastr.e(i18n.t('Network Error'));
            }

            return Promise.reject(error);
        });

        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        HttpService.instance = this;
    }

    get cancelSource() {
        return this._cancelSource;
    }

    get cancelToken() {
        return this.cancelSource.token;
    }

    generateCancelToken() {
        const CancelToken = axios.CancelToken;
        this._cancelSource = CancelToken.source();
    }

    cancelRequests(message) {
        this.cancelSource.cancel(message);
        this.generateCancelToken();
    }

    get(url, options) {
        return axios.get(url, options);
    }

    post(url, body, options) {
        return axios.post(url, body, options);
    }

    put(url, body, options) {
        return axios.put(url, body, options);
    }

    delete(url, params) {
        return axios.delete(url, params);
    }

    download(url, options = {}) {
        options.responseType = 'blob';
        return axios.get(url, options);
    }
}

export default new HttpService();

export function preventMultiple(request) {
    return (...data) => {
        if (!request.target || request.target !== data.toString()) {
            request.target = data.toString();
            request.request = request(...data);
            request.request.finally(() => {
                delete request.target;
                delete request.request;
            });

            return request.request;
        } else if (request.request) {
            return request.request;
        }
    };
}
