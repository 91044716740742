export const RESET_ANNOUNCEMENT_STATE = 'RESET_ANNOUNCEMENT_STATE';
export const ADD_SENT_ANNOUNCEMENT = 'ADD_SENT_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
export const GET_KINDERGARTEN_ANNOUNCEMENTS = 'GET_KINDERGARTEN_ANNOUNCEMENTS';
export const GET_SENT_KINDERGARTEN_ANNOUNCEMENTS = 'GET_SENT_KINDERGARTEN_ANNOUNCEMENTS';
export const SET_ANNOUNCEMENTS = 'SET_ANNOUNCEMENTS';
export const SET_SENT_ANNOUNCEMENTS = 'SET_SENT_ANNOUNCEMENTS';
export const SET_UNREAD_ANNOUNCEMENT_COUNT = 'SET_UNREAD_ANNOUNCEMENT_COUNT';
export const SEND_ANNOUNCEMENT = 'SEND_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const READ_ANNOUNCEMENTS = 'READ_ANNOUNCEMENTS';
export const GET_AVAILABLE_ANNOUNCEMENT_TARGETGROUPS = 'GET_AVAILABLE_ANNOUNCEMENT_TARGETGROUPS';
export const SET_AVAILABLE_ANNOUNCEMENT_TARGETGROUPS = 'SET_AVAILABLE_ANNOUNCEMENT_TARGETGROUPS';
export const SET_ANNOUNCEMENT_LOAD_MORE = 'SET_ANNOUNCEMENT_LOAD_MORE';
export const GET_RECENT_KINDERGARTEN_ANNOUNCEMENTS = 'GET_RECENT_KINDERGARTEN_ANNOUNCEMENTS';
export const SET_RECENT_KINDERGARTEN_ANNOUNCEMENTS = 'SET_RECENT_KINDERGARTEN_ANNOUNCEMENTS';
