<template>
    <div :class="[fgClass,{'form-group': isLabelSlot,'has-feedback has-error': validation && validation.$error}]">
        <label v-if="isLabelSlot" :for="id" :class="labelClass">
            <slot name="label"/>
            <template v-if="optional">
                - <small>{{ $t('Optional') }}</small>
            </template>
        </label>
        <div class="mb-1">
            <button v-for="(item, i) of langs"
                    :key="i"
                    type="button"
                    class="btn btn-sm text-uppercase"
                    :class="{ 'btn-primary': item.key === lang }"
                    @click="setLang(item.key)">
                {{ item.key }}
            </button>
        </div>

        <div :class="[inputBlockClass, { 'input-group': isAppendSlot || isPrependSlot }]">
            <div v-if="isPrependSlot" class="input-group-prepend">
                <slot name="input-prepend"/>
            </div>
            <textarea :id="id"
                      v-model="data[lang]"
                      class="form-control"
                      :class="[{'is-invalid': validation && validation.$error, 'form-control-lg': isLargeInput}, inputClass]"
                      :disabled="disabled"
                      :rows="rows"
                      :placeholder="placeholder"
                      @input="update"/>
            <div v-if="isAppendSlot" class="input-group-append">
                <slot name="input-append"/>
            </div>
            <ValitationMessages :validation="validation"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EMultiLangTextarea',
    props: {
        id: [String, Number],
        value: Object,
        type: String,
        langs: Array,
        rows: Number,
        optional: Boolean,
        disabled: Boolean,
        inline: Boolean,
        placeholder: String,
        formGroupClass: String,
        large: Boolean,
        inputClass: {
            type: String,
            default: ''
        },
        name: String,
        validation: Object,
        selectedLang: {
            type: String
        }
    },
    data() {
        return {
            lang: this.selectedLang || this.$i18n.locale || 'en'
        };
    },
    computed: {
        data: {
            get() {
                if (this.value && typeof this.value === 'object' && this.value.constructor === Object) {
                    return this.value;
                }

                return {};
            }
        },
        fgClass() {
            return this.formGroupClass;
        },
        isLargeInput() {
            return this.large;
        },
        inputType() {
            if (this.type) {
                return this.type;
            }
            return 'text';
        },
        inputBlockClass() {
            if (this.inline) {
                return 'col-md-7';
            }

            return '';
        },
        labelClass() {
            if (this.inline) {
                return 'col-5 col-md-3';
            }

            return '';
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        isAppendSlot() {
            return !!this.$slots['input-append'];
        },
        isPrependSlot() {
            return !!this.$slots['input-prepend'];
        },
    },
    methods: {
        update() {
            this.$emit('input', this.data);
        },
        setLang(lang) {
            this.lang = lang;
        }
    }
};
</script>

<style lang="scss" scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

textarea {
    min-height: 100px;
}
</style>
