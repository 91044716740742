import http from '@http';

class AnnouncementService {
    constructor() {
        if (AnnouncementService.instance) {
            return AnnouncementService.instance;
        }

        AnnouncementService.instance = this;
    }

    getKindergartenAnnouncements(kindergartenId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/announcements`, {params: options}).then(res => res.data);
    }

    getSentKindergartenAnnouncements(kindergartenId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/announcements/sent`, {params: options}).then(res => res.data);
    }
    announcements(url, type) {
        let request = `/api/${url}/announcements`;
        if (type === 'sent') {
            request = `${request}/${type}`;
        }
        return http.get(request).then(res => res.data);
    }

    send(url, data) {
        return http.post(`/api/${url}/announcements`, data).then(res => res.data);
    }

    update(id, data) {
        return http.put(`/api/common/announcements/${id}`, data).then(res => res.data);
    }

    delete(id) {
        return http.delete(`/api/common/announcements/${id}`).then(res => res.data);
    }

    readAnnouncements(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/announcements/read`).then(res => res.data);
    }

    //For mobile app route testing
    readAnnouncement(kindergartenId, announcementId) {
        return http.put(`/api/kindergartens/${kindergartenId}/announcements/${announcementId}/read`).then(res => res.data);
    }

    getUserTargetGroups(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/announcements/groups`).then(res => res.data);
    }

    getRecentKindergartenAnnouncements(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/announcements/recent`).then(res => res.data);
    }
}

export default new AnnouncementService();
