<template>
    <div>
        <div class="d-flex align-items-center mt-4 mb-2">
            <h3 class="h4 d-flex align-items-center">
                <span class="mr-2">{{ type === 'gallery' ? $t("Albums") : $t("Folders") }}</span>
                <small>({{ total }})</small>
            </h3>
            <div v-if="checkedFolders.length && (folderAccess === 'manage' || folder.access === 'edit')" class="ml-3">
                <button type="button" class="btn btn-primary btn-sm" @click="checkChosenFolders('move')">
                    <span class="d-flex align-items-center">
                        <SvgIcon icon="mdi-rhombus-split" size="14" class="text-white mr-1"/>
                        {{ $t('Move') }}
                    </span>
                </button>
            </div>
        </div>
        <div v-if="folders.length || searchLoading" class="container-fluid">
            <template v-if="!searchLoading">
                <div class="row font-weight-bold e3-doc-head-hide mb-2">
                    <div v-if="folder.access === 'manage' || folder.access === 'edit'" class="check-reserve p-0">
                        <ECheckbox
                            :id="`check-all-folders`"
                            v-model="allSelected"
                            success
                            :title="$t('Select all')"
                            @input="selectAll"/>
                    </div>
                    <div class="col-3 p-0">{{ $t("Name") }}</div>
                    <div class="col-2 p-0">{{ $t("Last modified") }}</div>
                    <div class="col p-0 text-right">{{ $t("Shared with identities") }}</div>
                    <div class="col p-0 text-right">{{ $t("Shared with target groups") }}</div>
                    <div class="col-2 p-0 text-right">{{ $t('Actions') }}</div>
                </div>
                <div
                    v-for="folder in folders"
                    :key="folder.id"
                    v-click-outside="unHighlight"
                    class="e3-last-border e3-hover-show row border-top py-2"
                    :class="{'e3-created-new': isFolderNew(folder.updated_at) && newCreated}">
                    <div v-if="folderAccess === 'manage' || folderAccess === 'edit'" class="d-flex align-items-center check-reserve p-0">
                        <ECheckbox
                            :id="`folder-${folder.id}`"
                            v-model="checkedFolders"
                            success
                            :class="{'e3-hidden-item show' : checkedFolders.length === 0}"
                            :val="folder.id"/>
                    </div>
                    <div class="col-7 col-sm col-md-3 d-flex align-items-center p-0 pr-2">
                        <div class="d-flex overflow-hidden w-100 h-100">
                            <router-link
                                :to="{ name: type=== 'gallery' ? 'gallery.folder': 'document.folder', params: { folder: folder.id }}"
                                class="d-flex align-items-center nav-link e3-hover-underline p-0" :title="folder.name">
                                <FolderIcon class="small mr-2" :isGallery="isGallery" :isReadonly="folder.access === 'view'"/>
                                <span v-if="!searchValue" :title="folder.name">{{ folder.name }}</span>
                                <span v-else :inner-html.prop="searchHighlight(folder.name, searchValue)" :title="folder.name"/>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-12 order-3 order-md-2 col-md-2 d-flex align-items-center p-0 pr-2">
                        <span class="e3-doc-hide mr-2">{{ $t("Last modified") }}:</span>
                        <span>{{ formatDateTime(folder.updated_at) }}</span>
                    </div>
                    <div class="col-12 order-4 order-md-3 col-md d-flex align-items-center p-0 pr-2 justify-content-md-end">
                        <span class="e3-doc-hide mr-2">{{ $t("Shared with identities") }}:</span>
                        <span>{{ folder.acl_identities_count }}</span>
                    </div>
                    <div class="col-12 order-4 order-md-3 col-md d-flex align-items-center justify-content-md-end p-0 pr-2">
                        <span class="e3-doc-hide mr-2">{{ $t("Shared with target groups") }}:</span>
                        <span>{{ folder.acl_target_groups_count }}</span>
                    </div>
                    <div class="order-2 col col-sm-2 order-md-4 d-flex align-items-center justify-content-end p-0 pl-2">
                        <template v-if="folder.id !== folderLoading">
                            <RoundIconButton
                                v-if="folder.access === 'manage'"
                                class="mr-2"
                                :title="$t('Edit folder')"
                                @click="openEditModal(folder)"/>
                            <ConfirmButton
                                v-if="folder.access === 'manage'"
                                :msg="$t('Are you sure you want to delete the '+entity, {name: folder.name})"
                                icon
                                confirmText="Delete"
                                :title="$t('Delete '+entity)"
                                :confirmHeader="$t('Delete '+entity)"
                                @confirmed="deleteFolder(folder.id)"/>
                        </template>
                        <ELoader v-else class="p-0"/>
                    </div>
                </div>
            </template>
            <ELoader v-else class="p-0"/>
        </div>
        <EmptyListMessage v-else-if="searching" class="border rounded p-2 bg-white mt-3">
            {{ $t("No folders found") }}
        </EmptyListMessage>
        <div v-if="page < folderMaxPage && !searchLoading" class="d-flex mt-3">
            <button type="button" class="btn btn-primary mx-auto" @click="loadMore">
                {{ $t("Load more") }}
            </button>
        </div>
    </div>
</template>

<script>
import {eventBus} from '@/main';
import {mapGetters} from 'vuex';
import {formatDateTime, searchHighlight} from '@/filters';
import FolderIcon from '@views/folder/components/FolderIcon.vue';

export default {
    name: 'FolderList',
    props: {
        folders: Array,
        type: String,
        index: {
            type: Boolean,
            default: false
        },
        page: Number,
        searchLoading: Boolean,
        searchValue: String,
        searching: Boolean,
        showEmpty: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newCreated: false,
            folderLoading: null,
            checkedFolders: [],
            allSelected: false,
        };
    },
    computed: {
        ...mapGetters(['folderTotalFolders', 'folder', 'folderMaxPage', 'userId']),
        total() {
            return this.folderTotalFolders;
        },
        isGallery() {
            return this.type === 'gallery';
        },
        entity() {
            return this.isGallery ? 'album' : 'folder';
        },
        folderAccess() {
            return this.folder.access;
        },
    },
    methods: {
        formatDateTime, // TODO: Vue3 remove
        searchHighlight, // TODO: Vue3 remove
        selectAll(value) {
            if (value) {
                this.checkedFolders = [];
                this.folders.forEach(f => {
                    this.checkedFolders.push(f.id);
                });
            } else {
                this.checkedFolders = [];
            }
        },
        openEditModal(folder) {
            if (folder.access === 'manage') {
                eventBus.$emit('show-index-edit-folder-modal', folder);
            }
        },
        loadMore() {
            this.$emit('loadMore');
        },
        isFolderNew(updated_at) {
            let createdNow = this.$moment().diff(this.$moment(updated_at), 'seconds') === 0;
            if (createdNow) {
                this.newCreated = true;
            }
            return createdNow;
        },
        unHighlight() {
            if (this.newCreated) {
                this.newCreated = false;
            }
        },
        deleteFolder(id) {
            this.folderLoading = id;
            this.$store.dispatch('DELETE_FOLDER', id).then(() => {
                this.$toastr.s(this.type === 'document' ? this.$t('Folder deleted') : this.$t('Album deleted'));
            }).finally(() => {
                this.checkedFolders = [];
                this.folderLoading = null;
            });
        },
        checkChosenFolders(type) {
            if (this.folder.access === 'edit') {
                let notAccessibleFolders = this.folders.filter(f => {
                    return this.checkedFolders.some(folder => folder === f.id) && f.access === 'view';
                });

                if (notAccessibleFolders.length) {
                    eventBus.$emit('show-accessible-modal', {
                        items: notAccessibleFolders,
                        checkedItems: this.checkedFolders.length,
                        pageType: this.type,
                        itemsType: 'folder',
                        type: type,
                        done: () => {
                            this.checkedFolders = this.checkedFolders.filter(id => !notAccessibleFolders.some(folder => folder.id === id));
                            if (type === 'move') {
                                this.openMoveModal();
                            }
                        }
                    });
                    return;
                }
            }

            if (type === 'move') {
                this.openMoveModal();
            }
        },
        openMoveModal() {
            eventBus.$emit('show-move-modal', {
                contentType: 'folders',
                folders: this.checkedFolders,
                done: () => {
                    this.checkedFolders = [];
                    this.allSelected = false;
                }
            });
        },
    },
    components: {
        FolderIcon
    },
};
</script>
<style lang="scss" scoped>
    .mdi.mdi-folder-image{
        height: 0;
        width: auto;
        margin-top: 11px;
    }
    .mdi.mdi-folder-image::before{
        position: relative;
        left: -2px;
    }

    .check-reserve {
        min-width: 28px;
        max-width: 28px;
    }

    @media screen and (max-width: 991px) {
        .show {
            display: block !important;
        }
    }
</style>
