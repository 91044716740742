import store from '@/store';

const Kindergartens = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/admin/kindergartens/Kindergartens.vue');
const AdminKindergarten = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/index.vue');
const AdminKindergartenDetails = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenGeneral.vue');
const AdminKindergartenEmployees = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenEmployees.vue');
const AdminKindergartenRoles = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenRoles.vue');
const AdminKindergartenGuardians = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenGuardians.vue');
const AdminKindergartenChildren = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenChildren.vue');
const AdminKindergartenChild = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/child/AdminKindergartenChild.vue');
const AdminKindergartenClasses = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenClasses.vue');
const AdminKindergartenCourse = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/course/AdminKindergartenCourse.vue');
const AdminKindergartenApplications = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenApplications.vue');
const AdminKindergartenTemplates = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenTemplates.vue');
const AdminKindergartenAnnouncements = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenAnnouncements.vue');
const AdminKindergartenTargetGroups = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenTargetGroups.vue');
const AdminKindergartenFolders = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenFolders.vue');
const AdminKindergartenFolder = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenFolder.vue');
const AdminKindergartenEvents = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenEvents.vue');
const AdminKindergartenWorkSchedules = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/AdminKindergartenWorkSchedules.vue');
const WorkSchedule = () => import(/* webpackChunkName: "work-schedule-module" */ '@views/schedule/views/WorkScheduleDay.vue');
const WorkScheduleWeekly = () => import(/* webpackChunkName: "work-schedule-module" */ '@views/schedule/views/WorkScheduleWeek.vue');
const WorkScheduleMonthly = () => import(/* webpackChunkName: "work-schedule-module" */ '@views/schedule/views/WorkScheduleMonth.vue');

const AdminChildInfo = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/child/AdminKindergartenChildInfo.vue');
const AdminKindergartenChildClasses = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/child/AdminKindergartenChildClasses.vue');
const AdminKindergartenChildDiaries = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/child/AdminKindergartenChildDiaries.vue');
const AdminKindergartenChildGuardians = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/child/AdminKindergartenChildGuardians.vue');
const AdminKindergartenChildAttendance = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/child/AdminKindergartenChildAttendance.vue');
const AdminKindergartenChildCards = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/child/AdminKindergartenChildCards.vue');
const AdminKindergartenChildCard = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/child/AdminKindergartenChildCard.vue');
const AdminKindergartenChildApplications = () => import('@views/admin/kindergartens/details/views/child/AdminKindergartenChildApplications.vue');

const AdminKindergartenCourseInfo = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/course/AdminKindergartenCourseInfo.vue');
const AdminKindergartenCourseChildren = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/course/AdminKindergartenCourseChildren.vue');
const AdminKindergartenCourseTeachers = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/course/AdminKindergartenCourseTeachers.vue');
const AdminKindergartenCourseDiary = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/course/AdminKindergartenCourseDiary.vue');
const AdminKindergartenCoursePlans = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/course/AdminKindergartenCoursePlans.vue');
const AdminKindergartenCourseGuardians = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/course/AdminKindergartenCourseGuardians.vue');
const AdminKindergartenCourseTargetGroups = () => import(/* webpackChunkName: "admin-module" */ '@/views/admin/kindergartens/details/views/course/AdminKindergartenCourseTargetGroups.vue');
export default [
    {
        path: '/admin/kindergartens',
        name: 'admin.kindergartens',
        components: { base: Kindergartens },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartensIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/kindergartens/:kindergarten_id',
        components: { base: AdminKindergarten },
        children: [
            {
                path: '',
                name: 'admin.kindergarten.details',
                components: { kindergarten: AdminKindergartenDetails },
            },
            {
                path: 'employees',
                name: 'admin.kindergarten.employees',
                components: { kindergarten: AdminKindergartenEmployees }
            },
            {
                path: 'roles',
                name: 'admin.kindergarten.roles',
                components: { kindergarten: AdminKindergartenRoles }
            },
            {
                path: 'guardians',
                name: 'admin.kindergarten.guardians',
                components: { kindergarten: AdminKindergartenGuardians }
            },
            {
                path: 'children',
                name: 'admin.kindergarten.children',
                components: { kindergarten: AdminKindergartenChildren },
            },
            {
                path: 'classes',
                name: 'admin.kindergarten.classes',
                components: { kindergarten: AdminKindergartenClasses },
            },
            {
                path: 'applications',
                name: 'admin.kindergarten.applications',
                components: { kindergarten: AdminKindergartenApplications },
            },
            {
                path: 'templates',
                name: 'admin.kindergarten.templates',
                components: { kindergarten: AdminKindergartenTemplates },
            },
            {
                path: 'announcements',
                name: 'admin.kindergarten.announcements',
                components: { kindergarten: AdminKindergartenAnnouncements },
            },
            {
                path: 'target-groups',
                name: 'admin.kindergarten.targetgroups',
                components: { kindergarten: AdminKindergartenTargetGroups },
            },
            {
                path: 'folders',
                name: 'admin.kindergarten.folders',
                components: { kindergarten: AdminKindergartenFolders },
            },
            {
                path: 'folders/:folder_id',
                name: 'admin.kindergarten.folder',
                components: { kindergarten: AdminKindergartenFolder },
            },
            {
                path: 'events',
                name: 'admin.kindergarten.events',
                components: { kindergarten: AdminKindergartenEvents },
            },
            {
                path: 'work-schedules',
                // name: 'admin.kindergarten.work-schedules',
                components: { kindergarten: AdminKindergartenWorkSchedules },
                children: [
                    {
                        path: 'day',
                        name: 'admin.kindergarten.work-schedules.day',
                        components: { adminWorkSchedule: WorkSchedule }
                    },
                    {
                        path: 'week',
                        name: 'admin.kindergarten.work-schedules.week',
                        components: { adminWorkSchedule: WorkScheduleWeekly }
                    },
                    {
                        path: 'month',
                        name: 'admin.kindergarten.work-schedules.month',
                        components: { adminWorkSchedule: WorkScheduleMonthly }
                    },
                ]
            },
        ],
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartensIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/kindergartens/:kindergarten_id/children/:child_id',
        components: { base: AdminKindergartenChild },
        children: [
            {
                path: '',
                name: 'admin.kindergarten.child',
                components: { adminChild: AdminChildInfo },
            },
            {
                path: 'classes',
                name: 'admin.kindergarten.child.classes',
                components: { adminChild: AdminKindergartenChildClasses },
            },
            {
                path: 'diary',
                name: 'admin.kindergarten.child.diary',
                components: { adminChild: AdminKindergartenChildDiaries },
            },
            {
                path: 'guardians',
                name: 'admin.kindergarten.child.guardians',
                components: { adminChild: AdminKindergartenChildGuardians },
            },
            {
                path: 'attendance',
                name: 'admin.kindergarten.child.attendance',
                components: { adminChild: AdminKindergartenChildAttendance },
            },
            {
                path: 'cards',
                name: 'admin.kindergarten.child.cards',
                components: { adminChild: AdminKindergartenChildCards },
            },
            {
                path: 'cards/:map_id',
                name: 'admin.kindergarten.child.card',
                components: { adminChild: AdminKindergartenChildCard },
            },
            {
                path: 'applications',
                name: 'admin.kindergarten.child.applications',
                components: { adminChild: AdminKindergartenChildApplications },
            },
        ],
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartensIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/kindergartens/:kindergarten_id/courses/:course_id',
        components: { base: AdminKindergartenCourse },
        children: [
            {
                path: '',
                name: 'admin.kindergarten.course',
                components: { adminCourse: AdminKindergartenCourseInfo },
            },
            {
                path: 'children',
                name: 'admin.kindergarten.course.children',
                components: { adminCourse: AdminKindergartenCourseChildren },
            },
            {
                path: 'teachers',
                name: 'admin.kindergarten.course.teachers',
                components: { adminCourse: AdminKindergartenCourseTeachers },
            },
            {
                path: 'diaries',
                name: 'admin.kindergarten.course.diaries',
                components: { adminCourse: AdminKindergartenCourseDiary},
            },
            {
                path: 'plans',
                name: 'admin.kindergarten.course.plans',
                components: { adminCourse: AdminKindergartenCoursePlans },
            },
            {
                path: 'guardians',
                name: 'admin.kindergarten.course.guardians',
                components: { adminCourse: AdminKindergartenCourseGuardians },
            },
            {
                path: 'targetGroups',
                name: 'admin.kindergarten.course.targetGroups',
                components: { adminCourse: AdminKindergartenCourseTargetGroups },
            },
        ],
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessKindergartensIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },

    //AdminKindergartenCourse
];
