import {
    GET_INTEGRATIONS,
    SET_INTEGRATIONS,
    GET_INTEGRATION,
    SET_INTEGRATION,
    UPDATE_INTEGRATION_SETTINGS,
    STORE_INTEGRATION,
    ADD_INTEGRATION,
    SET_ORGANIZATION_OPTIONS,
    SET_KINDERGARTEN_OPTIONS,
    STORE_INTEGRATION_CLIENT,
    ADD_INTEGRATION_CLIENT,
    GET_INTEGRATION_CLIENTS,
    SET_INTEGRATION_CLIENTS,
    DELETE_INTEGRATION_CLIENT,
    REMOVE_INTEGRATION_CLIENT,
    UPDATE_INTEGRATION_CLIENT,
    SET_INTEGRATION_CLIENT
} from './actions';
import IntegrationsService from '@services/integration.service';
import Vue from 'vue';


const defaultState = () => {
    return {
        integrations: [],
        integration: {},
        clients: { data: [] },
        kindergartens: [],
        organizations: []
    };
};
const state = defaultState;
const mutations = {
    [SET_INTEGRATIONS](state, data) {
        state.integrations = data;
    },
    [SET_INTEGRATION](state, integration) {
        state.integration = integration;
    },
    [ADD_INTEGRATION](state, integration) {
        state.integrations.push(integration);
    },
    [SET_ORGANIZATION_OPTIONS](state, options) {
        state.organizations = options;
    },
    [SET_KINDERGARTEN_OPTIONS](state, options) {
        state.kindergartens = options;
    },
    [ADD_INTEGRATION_CLIENT](state, client) {
        state.clients.data.push(client);
    },
    [SET_INTEGRATION_CLIENT](state, client) {
        let index = state.clients.data.findIndex(d => d.id === client.id);
        if(index > -1) {
            Vue.set(state.clients.data, index, client);
        }
    },
    [SET_INTEGRATION_CLIENTS](state, clients) {
        state.clients = clients;
    },
    [REMOVE_INTEGRATION_CLIENT](state, id) {
        let index = state.clients.data.findIndex(client => client.id === id);
        if(index !== -1) {
            state.clients.data.splice(index, 1);
        }
    }
};
const actions = {
    [GET_INTEGRATIONS]({commit}) {
        return IntegrationsService.getIntegrations().then(res => {
            commit(SET_INTEGRATIONS, res);
        });
    },
    [GET_INTEGRATION]({commit}, id) {
        return IntegrationsService.getIntegration(id).then(res => {
            commit(SET_INTEGRATION, res.integration);
            commit(SET_ORGANIZATION_OPTIONS, res.organizations);
            commit(SET_KINDERGARTEN_OPTIONS, res.kindergartens);
        });
    },
    [UPDATE_INTEGRATION_SETTINGS]({commit}, {id, form}) {
        return IntegrationsService.updateIntegrationSettings(id, form).then(res => {
            commit(SET_INTEGRATION, res);
        });
    },
    [STORE_INTEGRATION]({commit}, data) {
        return IntegrationsService.addIntegration(data).then(res => {
            commit(ADD_INTEGRATION, res);
        });
    },
    [GET_INTEGRATION_CLIENTS]({commit, getters}, queryObj) {
      return IntegrationsService.getIntegrationClients(getters.integration.id, queryObj).then(res => {
          commit(SET_INTEGRATION_CLIENTS, res);
      });
    },
    [STORE_INTEGRATION_CLIENT]({commit, getters}, data) {
        return IntegrationsService.addIntegrationClient(getters.integration.id, data).then(res => {
            commit(ADD_INTEGRATION_CLIENT, res);
        });
    },
    [UPDATE_INTEGRATION_CLIENT]({commit}, {id, form}) {
        return IntegrationsService.updateIntegrationClient(id, form).then(res => {
            commit(SET_INTEGRATION_CLIENT, res);
        });
    },
    [DELETE_INTEGRATION_CLIENT]({commit}, id) {
        return IntegrationsService.deleteIntegrationClient(id).then(() => {
            commit(REMOVE_INTEGRATION_CLIENT, id);
        });
    }
};
const getters = {
    integrations: state => state.integrations,
    integration: state => state.integration,
    orgClientOptions: state => state.organizations,
    kindergartenClientOptions: state => state.kindergartens,
    integrationClients: state => state.clients

};

export default {
    state,
    mutations,
    actions,
    getters
};