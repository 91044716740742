import {
    ADD_KINDERGARTEN_ROLE,
    GET_KINDERGARTEN_ROLES,
    SET_KINDERGARTEN_ROLES,
    STORE_KINDERGARTEN_ROLE, UPDATE_KINDERGARTEN_ROLE,
    UPDATE_TYPE_DEFAULT_ROLE
} from './actions';
import KindergartenService from '@services/kindergarten.service';
import Vue from 'vue';
const defaultState = () => {
    return {
        roles: [],
    };
};
const state = defaultState;

const mutations = {
    [SET_KINDERGARTEN_ROLES](state, data) {
        state.roles = data;
    },
    [ADD_KINDERGARTEN_ROLE](state, role) {
        state.roles.push(role);
    },
    [UPDATE_KINDERGARTEN_ROLE](state, role) {
        const index = state.roles.findIndex(r => r.id === role.id);
        Vue.set(state.roles, index, role);
    },
    [UPDATE_TYPE_DEFAULT_ROLE](state, role) {
        state.roles.map(r => {
            if(r.type_default === true && r.type === role.type) {
                return r.type_default = 0;
            }
        });
    }
};

const actions = {
    [GET_KINDERGARTEN_ROLES]({commit, getters}, queryObj) {
        return KindergartenService.getKindergartenRoles(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_KINDERGARTEN_ROLES, res);
        });
    },
    [STORE_KINDERGARTEN_ROLE]({commit, getters}, data) {
        return KindergartenService.addKindergartenRole(getters.activeKindergartenId, data).then(res => {
            if(res.type_default) {
                commit(UPDATE_TYPE_DEFAULT_ROLE, res);
            }
            commit(ADD_KINDERGARTEN_ROLE, res);
        });
    },
    [UPDATE_KINDERGARTEN_ROLE]({commit, getters}, role) {
        return KindergartenService.updateKindergartenRole(getters.activeKindergartenId, role).then(res => {
            if(res.type_default) {
                commit(UPDATE_TYPE_DEFAULT_ROLE, res);
            }
            commit(UPDATE_KINDERGARTEN_ROLE, res);
        });
    }
};

const getters = {
    kindergartenRoles: state => {
        return state.roles;
    },
    kindergartenEmployeeRoles: state => {
        return state.roles.filter(role => {
            return role.type === 'employee';
        });
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};