<template>
    <span class="d-inline-flex d-print-none ml-1">
        <template v-if="orderColumn == column">
            <SvgIcon :icon="isSortDesc ? 'mdi-chevron-down' : 'mdi-chevron-up'" class="text-black"/>
        </template>
        <template v-else>
            <SvgIcon icon="mdi-sort" size="15" class="text-black"/>
        </template>
    </span>
</template>

<script>
export default {
    name: 'SortDirectionButton',
    props: {
        sortDir: String,
        orderColumn: String,
        column: String
    },
    data() {
        return {};
    },
    computed: {
        isSortDesc() {
            return this.sortDir == 'desc';
        },
    },
};
</script>
