import {
    START_CONTENT_LOADER,
    START_FULL_PAGE_LOADER,
    STOP_ALL_LOADERS,
    STOP_CONTENT_LOADER,
    STOP_FULL_PAGE_LOADER,
    STOP_ALL_CONTENT_LOADERS,
    START_LOGIN_LOADER,
    STOP_LOGIN_LOADER
} from '@store/loader/actions';

const state = {
    fullPageLoading: 0,
    contentLoading: 0,
    loginLoading: 0,
};

const mutations = {
    [START_FULL_PAGE_LOADER](state) {
        state.fullPageLoading++;
    },

    [STOP_FULL_PAGE_LOADER](state) {
        state.fullPageLoading--;
        if (state.fullPageLoading < 0) {
            state.fullPageLoading = 0;
        }
    },

    [START_CONTENT_LOADER](state) {
        state.contentLoading++;
    },

    [STOP_CONTENT_LOADER](state) {
        state.contentLoading--;
        if (state.contentLoading < 0) {
            state.contentLoading = 0;
        }
    },

    [STOP_ALL_CONTENT_LOADERS](state) {
        state.contentLoading = 0;
    },

    [STOP_ALL_LOADERS](state) {
        state.contentLoading = 0;
        state.fullPageLoading = 0;
    },

    [START_LOGIN_LOADER](state) {
        state.loginLoading++;
    },

    [STOP_LOGIN_LOADER](state) {
        state.loginLoading--;
        if (state.loginLoading < 0) {
            state.loginLoading = 0;
        }
    },
};

const actions = {
    [START_FULL_PAGE_LOADER]({ commit }) {
        commit(START_FULL_PAGE_LOADER);
    },
    [STOP_FULL_PAGE_LOADER]({ commit }) {
        commit(STOP_FULL_PAGE_LOADER);
    },
    [START_CONTENT_LOADER]({ commit }) {
        commit(START_CONTENT_LOADER);
    },
    [STOP_CONTENT_LOADER]({ commit }) {
        commit(STOP_CONTENT_LOADER);
    },
    [STOP_ALL_CONTENT_LOADERS]({ commit }) {
        commit(STOP_ALL_CONTENT_LOADERS);
    },
    [STOP_ALL_LOADERS]({ commit }) {
        commit(STOP_ALL_LOADERS);
    },
    [START_LOGIN_LOADER]({ commit }) {
        commit(START_LOGIN_LOADER);
    },
    [STOP_LOGIN_LOADER]({ commit }) {
        commit(STOP_LOGIN_LOADER);
    }
};

const getters = {
    isFullPageLoading: state => {
        return state.fullPageLoading > 0;
    },
    isContentLoading: state => {
        return state.contentLoading > 0;
    },
    isLoginLoading: state => {
        return state.loginLoading > 0;
    }
};

export default {
    state,
    mutations,
    getters,
    actions
};
