<template>
    <div :class="{'form-group': isLabelSlot,'is-invalid': validation && validation.$error}">
        <label v-if="isLabelSlot" :for="id" :class="labelClass">
            <slot name="label"/>
            <template v-if="optional">
                - <small>{{ $t('Optional') }}</small>
            </template>
        </label>
        <multiselect :id="id"
                     v-model="val"
                     :openDirection="openDir"
                     :class="{ 'is-invalid': validation && validation.$error, 'notEmpty' : notEmpty, 'sm-select' : smSelect, 'e3-searchable': isSearchable, 'e3-sharp': sharpCorners, 'values': value ? value.length : false, 'min-width' : hasMinWidth }"
                     :placeholder="placeholder"
                     :options="isAsync ? localOptions : options"
                     :label="label"
                     :custom-label="customLabel"
                     :options-limit="optionsLimit"
                     :multiple="multiple"
                     :max="max"
                     :close-on-select="closeOnSelect"
                     :track-by="trackBy"
                     :searchable="isSearchable"
                     :disabled="disabled"
                     :loading="isLoading"
                     :select-label="$t('Choose')"
                     :selected-label="$t('Selected')"
                     :deselect-label="$t('Remove')"
                     :block-keys="['Delete']"
                     :show-labels="showLabels"
                     :allow-empty="!notEmpty"
                     :preselectFirst="preselectFirst"
                     :internal-search="!isAsync"
                     @select="onSelect"
                     @search-change="search"
                     @remove="onRemove"
                     @open="onOpen"
                     @close="onClose">
            <span slot="maxElements">{{$t('Maximum options selected')}}</span>
            <span slot="noResult">{{$t('Nothing found')}}</span>
            <span slot="noOptions">{{$t('Type to search')}}</span>
            <template slot="tag"/>
            <template v-if="flags" slot="singleLabel" slot-scope="props">
                <div class="d-flex flex-row">
                    <img v-if="getImgNameWithCode(props.option)"
                         class="max-w-20px mr-2"
                         :src=" `/images/flags/${getImgNameWithCode(props.option)}.svg`"
                         :alt="props.option"/>
                    <div class="d-inline-block mr-2">+{{ props.option }}</div>
                </div>
            </template>
            <template v-if="flags" slot="option" slot-scope="props">
                <div class="d-flex flex-row pr-2">
                    <template v-if="props.option.code">
                        <img v-if="getImgNameWithAbbr(props.option.abbr)"
                             class="max-w-20px mr-2"
                             :src="`/images/flags/${getImgNameWithAbbr(props.option.abbr)}.svg`"
                             :alt="props.option.abbr"/>
                        <div class="d-inline-block mr-2">
                            +{{ props.option.code }}
                        </div>
                    </template>
                    <template v-else>
                        {{ props.option.abbr }}
                    </template>
                </div>
            </template>

            <!-- Custom look for select options. See child_development Goal.vue goal selection for example. -->
            <template v-if="customOptions" slot="option" slot-scope="props">
                <template v-if="typeof props.option === 'object' && props.option !== null">
                    <div :class="props.option.classes">
                        {{ props.option.label }}
                    </div>
                </template>
            </template>

        </multiselect>

        <ValitationMessages :validation="validation"/>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'ESelect',
    props: {
        id: [String, Number],
        value: [Object, String, Number, Array],
        options: [Array, Object],
        notEmpty: Boolean,
        label: String,
        searchable: Boolean,
        flags: Boolean,
        customLabel: Function,
        optional: Boolean,
        placeholder: {
            type: String,
            default: ''
        },
        isAsync: {
            type: Boolean,
            default: false
        },
        customOptions: {
            type: Boolean,
            default: false,
        },
        optionsLimit: Number,
        optionType: String,
        closeOnSelect: Boolean,
        multiple: Boolean,
        max: {
            type: Number,
            default: null
        },
        trackBy: String,
        disabled: Boolean,
        isLoading: Boolean,
        validation: Object,
        preselectFirst: Boolean,
        showLabels: {
            type: Boolean,
            default: true
        },
        smSelect: Boolean,
        openDir: {
            type: String,
            default: 'bottom'
        },
        sharpCorners: {
            type: Boolean,
            default: false
        },
        hasMinWidth: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            localOptions: []
        };
    },
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.isAsync) {
                    this.localOptions = [];
                }
                this.$emit('input', this.withFlags ? value.code : value);
            }
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        labelClass() {
            if(this.inline) {
                return 'col-md-5 col-form-label text-md-right text-sm-left';
            }

            return '';
        },
        isSearchable() {
            return this.searchable;
        },
        withFlags() {
            return this.flags;
        }
    },
    methods: {
        search(query) {
            if (this.isAsync) {
                if(this.withFlags) {
                    this.localOptions = this.options.filter(opt => {
                        return opt.code.includes(query);
                    });
                }
            }
        },
        getImgNameWithCode(code) {
            const country = this.options.find(op => op.code === code);
            if(country) {
                return country.abbr.toLowerCase();
            }
        },
        getImgNameWithAbbr(abbr) {
            return abbr.toLowerCase();
        },
        onSelect(value) {
            if (this.isAsync) {
                this.localOptions = [];
            }
            this.$emit('select', this.withFlags ? value.code : value);
        },
        onRemove(value) {
            this.$emit('remove', this.withFlags ? value.code : value);
        },
        onOpen(value) {
            this.$emit('open', this.withFlags ? value.code : value);
        },
        onClose(value) {
            this.$emit('close', this.withFlags ? value?.code : value);
        }
    },
    components: {
        Multiselect
    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@common/styles/variables/_variables.scss";
.multiselect {
    &.is-invalid {
        .multiselect__tags {
            border-color: $danger !important;
        }
    }
}

.multiselect.min-width {
    min-width: 160px;
}

.max-w-20px {
    max-width: 20px;
}

.multiselect {
    min-height: 37px;
}

.multiselect__tags {
    min-height: 37px;
    padding: 6px 40px 0 12px;
    border: 1px solid #c4c4c4;
}

.multiselect__tag {
    margin-bottom: 1px;
}

.multiselect__select {
    height: 35px;
}

.multiselect__placeholder {
    margin-bottom: 0px;
    padding-top: 0px;
    line-height: 23px;
    color: #c4c4c4;
}

.multiselect__content-wrapper {
    border-color: #c4c4c4;
    font-size: 14px;
    line-height: 23px;
}

.multiselect__input,
.multiselect__single {
    padding: 0;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 0;
    background-color: transparent;
}

.multiselect--above .multiselect__content-wrapper {
    border-top: 1px solid #c4c4c4;
}

.multiselect__option {
    min-height: 37px;
    padding: .375rem .75rem;
    line-height: 23px;
}

.multiselect__option:after {
    min-height: 37px;
    padding: .375rem .75rem;
    line-height: 23px;
    white-space: normal;
}

.notEmpty {
    .multiselect__content-wrapper {
        li {
            .multiselect__option--selected.multiselect__option--highlight:after {
                content: none;
            }
        }
    }
}
</style>
