import store from '@/store';

const ChildProfile = () => import(/* webpackChunkName: "child-module" */ '@views/child/profile');
const Info = () => import( /* webpackChunkName: "child-module" */ '@views/child/profile/views/Info');
const Approvals = () => import(/* webpackChunkName: "child-module" */ '@views/child/profile/views/Approvals');
const Maps = () => import( /* webpackChunkName: "child-module" */ '@views/child/profile/views/Maps');
const Development = () => import( /* webpackChunkName: "child-module" */ '@views/child/profile/views/Development');
const Map = () => import( /* webpackChunkName: "child-module" */ '@views/child/profile/views/Map');
const Plans = () =>  import( /* webpackChunkName: "child-module" */ '@views/child/profile/views/ChildPlans');
const Guardians = () => import( /* webpackChunkName: "child-module" */ '@views/child/profile/views/Guardians');
const GuardianDiary = () => import( /* webpackChunkName: "child-module" */ '@views/child/profile/views/GuardianDiary');
const AbsenceCalendar = () => import('@views/child/profile/views/AbsenceCalendar');
const Files = () => import('@views/child/profile/views/Files');
export default [
    {
        path: '/child/:child_id',
        components: { base: ChildProfile },
        children: [
            {
                path: '',
                name: 'child.profile',
                components: { child: Info }
            },
            {
                path: 'calendar',
                name: 'child.calendar',
                components: { child: AbsenceCalendar }
            },
            {
                path: 'approvals',
                name: 'child.approvals',
                components: { child: Approvals },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canViewChildApprovals || store.getters.canEditChildApprovals) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'maps',
                name: 'child.maps',
                components: { child: Maps },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessChildMaps || store.getters.canParentAccessChildMaps) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'plans',
                name: 'child.plans',
                components: { child: Plans },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessChildPlans) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'parents',
                name: 'child.parents',
                components: { child: Guardians },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessChildGuardians) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'diary',
                name: 'child.diary',
                components: { child: GuardianDiary }
            },
            {
                path: 'development',
                name: 'child.development',
                components: { child: Development },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canViewChildDevelopement || store.getters.canEditChildDevelopement || store.getters.canViewChildDevelopementParent) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'files',
                name: 'child.files',
                components: { child: Files },
                beforeEnter: (to, from, next) => {
                    if (store.getters.childProfileSettings.child_profile_files_enabled && (store.getters.canAccessChildFilesEmployee || store.getters.canAccessChildFilesGuardian)) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
        ]
    },
    {
        path: '/child/:child_id/maps/:map_id',
        name: 'child.map',
        components: { base: Map },
        beforeEnter: (to, from, next) => {
            if (store.getters.canViewChildMaps || store.getters.canParentViewChildMaps) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
];
