import store from '@/store';

const IdeasIndex = () => import(/* webpackChunkName: "child-module" */ '@views/idea/ideas/Index');
const Ideas = () => import(/* webpackChunkName: "child-module" */ '@views/idea/ideas/views/Ideas');

const IdeaIndex = () => import(/* webpackChunkName: "child-module" */ '@views/idea/idea/Index');
const IdeaShow = () => import(/* webpackChunkName: "child-module" */ '@views/idea/idea/views/ShowIdea');
const IdeaEdit = () => import(/* webpackChunkName: "child-module" */ '@views/idea/idea/views/EditIdea');

export default [
    {
        path: '/ideas',
        components: {base: IdeasIndex},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessIdeaIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        },
        children: [
            {
                path: '',
                name: 'ideas',
                components: {ideas: Ideas},
                beforeEnter: (to, from, next) => {
                    store.dispatch('UPDATE_CURRENT_IDEAS_TYPE', to.name);
                    next();
                },
            },
            {
                path: 'my-ideas',
                name: 'ideas.my-ideas',
                components: {ideas: Ideas},
                beforeEnter: (to, from, next) => {
                    store.dispatch('UPDATE_CURRENT_IDEAS_TYPE', to.name);
                    next();
                },
            },
            {
                path: 'my-favorites',
                name: 'ideas.my-favorites',
                components: {ideas: Ideas},
                beforeEnter: (to, from, next) => {
                    store.dispatch('UPDATE_CURRENT_IDEAS_TYPE', to.name);
                    next();
                },
            },
        ],
    },
    {
        path: '/idea',
        components: {base: IdeaIndex},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessIdeaIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        },
        children: [
            {
                path: 'add',
                name: 'ideas.add',
                components: {idea: IdeaEdit},
            },
            {
                path: ':idea_id/edit',
                name: 'ideas.edit',
                components: {idea: IdeaEdit},
            },
            {
                path: ':idea_id',
                name: 'ideas.show',
                components: {idea: IdeaShow},
            },
        ],
    },
];
