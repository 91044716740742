<template>
    <div class="e3-head-icon-round">
        <div class="d-flex align-items-center px-3 py-2">
            <span class="font-weight-bold">{{ $t('Announcements') |toUpper }}</span>
            <!--            <span class="ml-auto text-success">{{ unreadAnnouncements }}</span>-->
        </div>
        <div class="d-flex flex-column border-top">
            <MessagesContent :tab="tab" :data="recentAnnouncements"/>
        </div>
        <div class="bg-light px-3 py-2 border-top rounded-bottom">
            <router-link :to="{ name: 'announcements' }" @click.native="$emit('close', id)">
                {{ $t('Show all announcements') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import MessagesContent from './components/MessagesContent';
import {mapGetters} from 'vuex';
export default {
    props: {
        id: [String, Number]
    },
    data() {
        return {
            tab: 'announcements'
        };
    },
    computed: {
        ...mapGetters([
            'recentAnnouncements',
            'unreadAnnouncements',
            'messageTypes'
        ])
    },
    components: {
        MessagesContent
    },
    created() {
        this.tab = this.messageTypes.announcements;
    },
    destroyed() {
        if(this.unreadAnnouncements) {
            this.$store.dispatch('READ_ANNOUNCEMENTS').then(() => {
                this.$store.dispatch('GET_RECENT_KINDERGARTEN_ANNOUNCEMENTS');
            });
        }
    }
};
</script>
