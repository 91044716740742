<template>
    <div class="d-flex justify-content-center align-items-center" :class="[wrapperClass]">
        <div class="d-flex flex-column align-items-center text-muted">
            <div class="d-inline-flex align-items-center">
                <div class="d-flex justify-content-center mr-1">
                    <SvgIcon icon="mdi-alert-circle-outline" class="text-muted"/>
                </div>
                <slot/>
            </div>
            <slot name="second-line"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        wrapperClass: {
            type: String,
            default: 'p-3',
        },
    },
};
</script>
