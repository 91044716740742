import http from '@services/http.service';

class EmployeeService {
    constructor() {
        if (EmployeeService.instance) return EmployeeService.instance;
    }

    updateData(kindergartenId, employeeId, data) {
        return http.put(`/api/common/user/employees/${employeeId}/data`, data).then(res => res.data);
    }

    store(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/employees`, data).then(res => res.data);
    }

    update(kindergartenId, employeeId, form) {
        return http.put(`/api/kindergartens/${kindergartenId}/employees/${employeeId}`, form).then(res => res.data);
    }

    updateSettings(employeeId, data) {
        return http.post(`/api/common/user/employees/${employeeId}/settings`, data)
            .then(res => res.data);
    }

    archiveEmployee(kindergartenId, employeeId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/employees/${employeeId}`).then(res => res.data);
    }

    restoreEmployee(kindergartenId, employeeId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/employees/${employeeId}/restore`, data).then(res => res.data);
    }

    employeeChildren(kindergartenId, employeeId) {
        return http.get(`/api/kindergartens/${kindergartenId}/employees/${employeeId}/children`).then(res => res.data);
    }
}
export default new EmployeeService();
