const Help = () => import(/* webpackChunkName: "help-module" */ '@views/admin/help/Help');


const Templates = () => import(/* webpackChunkName: "template-module" */ '@views/template/Templates');
const Template = () => import(/* webpackChunkName: "template-module" */ '@views/template/Template');
const Children = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/children/Children');
const PublicHolidays = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/public_holidays/PublicHolidays');
const Integrations = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/integrations/Integrations');
const Integration = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/integrations/components/Integration');
const Messages = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/message/Message');
const Banners = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/banners/Banners');
const SSOClients = () => import(/* webpackChunkName: "admin-module" */ '@views/admin/sso/Clients');

import adminChildDevTemplateRoutes from '@views/admin/child_development/routes';
import adminUserRoutes from '@views/admin/user/routes';
import adminKindergartenRoutes from '@views/admin/kindergartens/routes';
import adminOrganizationRoutes from '@views/admin/organization/routes';
import adminMarketplaceRoutes from '@views/admin/marketplace/routes';
import adminSurveyRoutes from '@views/admin/surveys/routes';

import store from '@/store';

export default [
    {
        path: '/admin/helpadmin',
        name: 'admin.helpadmin',
        components: { base: Help },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminHelp) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    ...adminUserRoutes,
    ...adminKindergartenRoutes,
    ...adminOrganizationRoutes,
    ...adminMarketplaceRoutes,
    ...adminSurveyRoutes,
    {
        path: '/admin/children',
        name: 'admin.children',
        components: { base: Children },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessChildrenIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/templates',
        name: 'admin.templates',
        components: { base: Templates },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessTemplatesIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/templates/:temp_id',
        name: 'admin.template',
        components: { base: Template }
    },
    ...adminChildDevTemplateRoutes,
    {
        path: '/admin/public-holidays',
        name: 'admin.public.holidays',
        components: { base: PublicHolidays },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessPublicHolidaysIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/system-message',
        name: 'admin.message',
        components: {base: Messages},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminMessages) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        }
    },
    {
        path: '/admin/integrations',
        name: 'admin.integrations',
        components: { base: Integrations },
        // beforeEnter: (to, from, next) => {
        //     if (store.getters.canAccessAdminIntegrationIndex) {
        //         next();
        //     } else {
        //         next({ path: '/access-restricted' });
        //     }
        // }
    },
    {
        path: '/admin/integrations/:int_id',
        name: 'admin.integration',
        components: { base: Integration },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminIntegrationIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/banners',
        name: 'admin.banners',
        components: { base: Banners },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessUsersIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/admin/sso',
        name: 'admin.sso',
        components: { base: SSOClients },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessAdminSsoClientsIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    }
];
