<template>
    <EDropdown
        v-if="selectedKindergarten && dropdownCourses.length"
        id="courses-dropdown"
        :text="`${$t('Kindergarten')}: ${selectedKindergarten && selectedKindergarten.name}`"
        variant="primary"
        left
        :items="dropdownCourses"
        :active="active"/>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'EKindergartenSelect',
    data() {
        return {
            active: ''
        };
    },
    computed: {
        ...mapGetters(['officialKindergartens', 'selectedKindergarten', 'currentOfficial']),
        dropdownCourses() {
            let kindergartens = [];
            this.officialKindergartens.map(k => {
                const kindergarten = {
                    id: k.id,
                    key: k.id,
                    name: k.name,
                    action: () => {
                        this.selectKindergarten(k); // whole object, pluck out whats needed
                    }
                };
                kindergartens.push(kindergarten);
            });

            return kindergartens;
        }
    },
    methods: {
        selectKindergarten(kindergarten) {
            this.$store.commit('SET_OFFICIAL_KINDERGARTEN', kindergarten);
            this.active = kindergarten.id;
            this.$emit('kindergarten', kindergarten.id);
        }
    },
};
</script>

<style scoped>

</style>
