import Quill from 'quill';

export default {
    methods: {
        registerQuillBlot() {
            Quill.debug('error');
            let InlineEmbed = Quill.import('blots/embed');

            class ToolTipInlineEmbed extends InlineEmbed {
                static create(text) {
                    const node = super.create(text);
                    node.setAttribute('data-value', text);
                    node.innerHTML += '';
                    node.addEventListener('click', function() {
                        
                    }, false);

                    const infoText = document.createElement('span');
                    infoText.className = 'e3-tooltip-text';
                    infoText.innerHTML = text;
                    node.appendChild(infoText);

                    return node;
                }

                static value(domNode) {
                    return domNode.getAttribute('data-value');
                }
   
                static formats() {
                    return ToolTipInlineEmbed.tagName;
                }
            }
            ToolTipInlineEmbed.blotName = 'tooltipblot';
            ToolTipInlineEmbed.tagName = 'span';
            ToolTipInlineEmbed.className = 'e3-tooltip';

            Quill.register(ToolTipInlineEmbed);
        },
        quillLinking(node, delta) {
            if(typeof(node.data) !== 'string') return;
            var regex = /https?:\/\/[^\s]+/g;
            var isLink = node.data.match(regex);

            if(isLink && isLink.length > 0) {
                var ops = [];
                var givenString = node.data;
                
                isLink.forEach(function(link) {
                    var split = givenString.split(link);
                    var beforeLink = split.shift();

                    ops.push({ insert: beforeLink });
                    ops.push({ insert: link, attributes: { link: link } });
                    givenString = split.join(link);
                });

                ops.push({ insert: givenString });
                delta.ops = ops;
            }

            return delta;
        }
    },
};
