import {
    GET_CHILD_PLAN, GET_CHILD_PLAN_TYPES,
    SET_CHILD_PROJECT_PLANS, SET_CHILD_YEAR_PLANS,
    GET_CHILD_PLANS_LIST,
    SET_CHILD_PLANS_LIST,
    RESET_SELECTED_CHILD_PLAN
} from './actions';
import {SET_COURSE_PLAN_TYPES, SET_PLAN, SET_PLAN_DOCUMENTS} from '@/store/modules/plan/actions';
import ChildService from '@/common/services/child.service';

const getDefaultState = () => {
    return {
        plans: []
    };
};

export const planState = getDefaultState();

export const planMutations = {
    [SET_CHILD_YEAR_PLANS](state, data) {
        state.yearPlans = data;
    },
    [SET_CHILD_PROJECT_PLANS](state, plans) {
        state.project_plans = plans;
    },
    [SET_CHILD_PLANS_LIST] (state, plans) {
        state.plans = plans;
    },
};

export const planActions = {
    [GET_CHILD_PLAN_TYPES]({commit, getters}, {childId, courseId}) {
        return ChildService.getChildCoursePlanTypes(getters.activeKindergartenId, childId, courseId).then(res => {
            commit(SET_COURSE_PLAN_TYPES, res);
            return res[0] ?? {};
        });
    },
    [GET_CHILD_PLANS_LIST]({commit, getters}, data) {
        commit(SET_PLAN_DOCUMENTS, []);
        commit(SET_PLAN, {});
        commit(SET_CHILD_PLANS_LIST, []);
        return ChildService.getChildPlansList(getters.activeKindergartenId, data.childId, data.courseId, data.type).then(res => {
            commit(SET_CHILD_PLANS_LIST, res);
        });
    },

    [GET_CHILD_PLAN]({commit, getters}, data) {
        commit(SET_PLAN_DOCUMENTS, []);
        return ChildService.getChildPlan(getters.activeKindergartenId, data.childId, data.planId).then(res => {
            commit(SET_PLAN_DOCUMENTS, res.documents);
            delete(res.documents);
            commit(SET_PLAN, res);
        });
    },
    [RESET_SELECTED_CHILD_PLAN]({commit}) {
        commit(SET_PLAN_DOCUMENTS, []);
        commit(SET_PLAN, {});
    }

};

export const planGetters = {
    childPlanList: state => state.plans
};

export default {
    planState,
    planMutations,
    planActions,
    planGetters
};
