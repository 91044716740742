export const GET_SHOP_FILTERS = 'GET_SHOP_FILTERS';
export const SET_SHOP_FILTERS = 'SET_SHOP_FILTERS';
export const GET_KINDERGARTEN_ORDERS = 'GET_KINDERGARTEN_ORDERS';
export const SET_KINDERGARTEN_ORDERS = 'SET_KINDERGARTEN_ORDERS';
export const STORE_KINDERGARTEN_ORDER = 'STORE_KINDERGARTEN_ORDER';
export const GET_KINDERGARTEN_WISHLIST = 'GET_KINDERGARTEN_WISHLIST';
export const SET_KINDERGARTEN_WISHLIST = 'SET_KINDERGARTEN_WISHLIST';
export const SET_KINDERGARTEN_CONTACTS = 'SET_KINDERGARTEN_CONTACTS';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const UPDATE_WISHLIST_ITEM = 'UPDATE_WISHLIST_ITEM';
export const SET_WISHLIST_ITEM = 'SET_WISHLIST_ITEM';
export const UPDATE_SUPPLIER_WISHLIST = 'UPDATE_SUPPLIER_WISHLIST';
export const UPDATE_WISHLIST_PRICE = 'UPDATE_WISHLIST_PRICE';
export const RESET_SHOP_STATE = 'RESET_SHOP_STATE';
export const GET_SHOP_CATEGORIES = 'GET_SHOP_CATEGORIES';
export const SET_SHOP_CATEGORIES = 'SET_SHOP_CATEGORIES';
export const GET_USER_ORDERS = 'GET_USER_ORDERS';
export const SET_USER_ORDERS = 'SET_USER_ORDERS';
export const GET_USER_CART = 'GET_USER_CART';
export const SET_USER_CART = 'SET_USER_CART';
export const MY_CART_ORDER = 'MY_CART_ORDER';
export const REMOVE_ITEM_FROM_USER_CART = 'REMOVE_ITEM_FROM_USER_CART';
export const GET_SHOP_PRODUCT = 'GET_SHOP_PRODUCT';
export const GET_SHOP_SERVICE = 'GET_SHOP_SERVICE';
export const SET_SELECTED_SHOP_ITEM = 'SET_SELECTED_SHOP_ITEM';
export const GET_SHOP_ITEM_COMMENTS = 'GET_SHOP_ITEM_COMMENTS';
export const SET_SELECTED_ITEM_COMMENTS = 'SET_SELECTED_ITEM_COMMENTS';
export const STORE_SHOP_ITEM_COMMENT = 'STORE_SHOP_ITEM_COMMENT';
export const ADD_SELECTED_ITEM_COMMENT = 'ADD_SELECTED_ITEM_COMMENT';
export const STORE_SHOP_ITEM_RATING = 'STORE_SHOP_ITEM_RATING';
export const SET_SHOP_ITEM_RATING = 'SET_SHOP_ITEM_RATING';
export const GET_SUPPLIER_INFO = 'GET_SUPPLIER_INFO';
export const SET_SUPPLIER_INFO = 'SET_SUPPLIER_INFO';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SEARCH_SERVICES = 'SEARCH_SERVICES';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const GET_SHOP_SUPPLIERS = 'GET_SHOP_SUPPLIERS';
export const SET_SHOP_SUPPLIERS = 'SET_SHOP_SUPPLIERS';
export const EDIT_USER_CART_ITEM = 'EDIT_USER_CART_ITEM';
export const GET_POPULAR_ITEMS = 'GET_POPULAR_ITEMS';
export const SET_POPULAR_ITEMS = 'SET_POPULAR_ITEMS';
export const SET_SYSTEM_FILTERS = 'SET_SYSTEM_FILTERS';
export const UPDATE_MY_CART_PRICE = 'UPDATE_MY_CART_PRICE';
export const UPDATE_USER_CART = 'UPDATE_USER_CART';
