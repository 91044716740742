import {
    GET_DOCUMENT_FOLDERS,
    DELETE_DOCUMENT,
    GET_RECENT_DOCUMENTS,
    SET_RECENT_DOCUMENT_COUNT,
    SET_RECENT_DOCUMENTS,
    SET_DOCUMENT_ACTIVITY,
    UPDATE_DOCUMENT_FILE,
    SET_SHARED_DOCUMENT_FILES,
    GET_SHARED_DOCUMENT_FILES,
    SET_FILE_LIST_MAX_PAGE,
    RESET_DOCUMENT_STATE,
    SET_FOLDER_FILES_TOTAL,
} from './actions';

import DocumentService from '@services/document.service';
import KindergartenService from '@services/kindergarten.service';

const getDefaultState = () => {
    return {
        last_activity: null,
        recent_documents: [],
        shared_document_files: [],
        unseen_count: 0,
        fileListMaxPage: 0,
        permissions: {
            manage: 'manage',
            view: 'view',
            edit: 'edit'
        },
        folderTotalFiles: 0,

    };
};

const state = getDefaultState();

const mutations = {
    //don't use until recent stuff is moved away from this store
    [RESET_DOCUMENT_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_DOCUMENT_ACTIVITY](state, date) {
        state.last_activity = date;
    },
    [SET_RECENT_DOCUMENTS](state, data) {
        state.recent_documents = data;
    },
    [SET_RECENT_DOCUMENT_COUNT](state, count) {
        if(count > 5) {
            count = '5+';
        }
        state.unseen_count = count;
    },
    [SET_SHARED_DOCUMENT_FILES](state, data) {
        //paginated data
        data.forEach(doc => {
            state.shared_document_files.push(doc);
        });
    },
    [SET_FILE_LIST_MAX_PAGE](state, pages) {
        state.fileListMaxPage = pages;
    },
    [SET_FOLDER_FILES_TOTAL](state, total) {
        state.folderTotalFiles = total;
    },

};

const actions = {
    [GET_DOCUMENT_FOLDERS]({ commit, getters}, queryObj) {
        if(queryObj.page === 1) {
            commit('RESET_SUB_FOLDERS');
            commit('SET_FOLDER_MAX_PAGE', 0);
            commit('SET_FOLDER_TOTAL', 0);
        }
        return DocumentService.getIndexData(getters.activeKindergartenId, queryObj).then(res => {
            commit('ADD_TO_SUB_FOLDERS', res.folders.data);
            commit('SET_FOLDER_SETTINGS', res.settings);
            commit('SET_FOLDER_MAX_PAGE', res.folders.last_page);
            commit('SET_FOLDER_TOTAL', res.folders.total);
        });
    },
    [DELETE_DOCUMENT]({ commit, getters }, fileName) {
        return DocumentService.deleteDocument(getters.activeKindergartenId, fileName).then(() => {
            commit('REMOVE_FOLDER_FILE', fileName);
        });
    },
    [GET_RECENT_DOCUMENTS]({ commit }, kindergarten_id) {
        return KindergartenService.getRecentFiles(kindergarten_id).then(res => {
            commit(SET_RECENT_DOCUMENT_COUNT, res.count);
            commit(SET_RECENT_DOCUMENTS, res.documents);
        });
    },
    [SET_DOCUMENT_ACTIVITY]({commit, getters}) {
        return DocumentService.updateActivity(getters.activeKindergartenId).then(res => {
            commit(SET_DOCUMENT_ACTIVITY, res.date);
            commit(SET_RECENT_DOCUMENT_COUNT, 0);
        });
    },
    [UPDATE_DOCUMENT_FILE]({commit, getters}, form) {
        return DocumentService.updateDocumentFile(getters.activeKindergartenId, form.filename, form).then(res => {
            commit('UPDATE_FOLDER_FILE', res);
        });
    },
    [GET_SHARED_DOCUMENT_FILES]({ commit, getters, state }, queryObj) {
        //reset data on index reentry or it will loop old data as new data to existing data
        if(queryObj.page === 1) {
            state.shared_document_files = [];
            commit(SET_FILE_LIST_MAX_PAGE, 0);
        }
        return DocumentService.getSharedDocuments(getters.activeKindergartenId, queryObj).then(res => {
            if (queryObj.term === '') commit(SET_FOLDER_FILES_TOTAL, res.total);
            commit(SET_SHARED_DOCUMENT_FILES, res.data);
            commit(SET_FILE_LIST_MAX_PAGE, res.last_page);
            commit(SET_FOLDER_FILES_TOTAL, res.total);
        });
    }

};

const getters = {
    recentDocuments: state => {
        return state.recent_documents.slice(0, 3) || [];
    },
    unseenDocumentCount: state => state.unseen_count,
    sharedDocumentFiles: state => state.shared_document_files,
    fileListMaxPage: state => state.fileListMaxPage,
    folderTotalFiles: state => state.folderTotalFiles
};

export default {
    state,
    mutations,
    actions,
    getters
};
