<template>
    <td class="contenteditable notranslate text-break" :contenteditable="!disabled" @input="update" @blur="blur"/>
</template>

<script>
export default {
    name: 'ContentEditableTd',
    props: {
        content: String,
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        content(val) {
            if (this.$el.innerText  !== val) {
                this.$el.innerText  = val;
            }
        }
    },
    methods: {
        update(event) {
            this.$emit('input', event.target.innerText );
        },
        blur(event) {
            this.$emit('blur', event.target.innerText );
        },
        setInnerText() {
            if (this.content == null) {
                this.$el.innerText  = '\n';
            } else {
                this.$el.innerText  = this.content;
            }
        }
    },
    mounted() {
        this.setInnerText();
        document.execCommand('defaultParagraphSeparator', false, 'br');
    }
};
</script>

<style scoped lang="scss">
    .contenteditable {
        background-color: #fff;
        white-space: pre-wrap;
        
    }

    .contenteditable:focus {
        outline: none;
    }
</style>
