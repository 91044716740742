export const basicEditorXSSOptions = {
    whiteList: {
        b: ['style'],
        i: ['style'],
        u: ['style'],
        a: ['href', 'title', 'target', 'rel'],
        p: ['class'],
        strong: ['style'],
        em: ['style'],
        br: [],
        s: ['style'],
        span: ['style'],
        ul: [], ol: [], li: [],
    },
    css: {
        whiteList: {
            'color': /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\);?$/,
            'background-color': /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\);?$/,
            'font-size': /^(\d+(\.\d+)?(px|em|%)|small|medium|large|x-large|xx-large)$/i,
        }
    }
};
