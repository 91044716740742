import {
    RESET_WORK_SCHEDULE_STORE,
    GET_WORK_SCHEDULE_TYPES,
    GET_WORK_SCHEDULES,
    SET_WORK_SCHEDULE_TYPES,
    SET_WORK_SCHEDULES,
    SAVE_WORK_SCHEDULE_TYPE,
    ADD_WORK_SCHEDULE_TYPE,
    UPDATE_WORK_SCHEDULE_TYPE,
    GET_WORK_SCHEDULE_SETTINGS,
    SET_WORK_SCHEDULE_SETTINGS,
    UPDATE_WORK_SCHEDULE_SETTINGS,
    INSERT_WORK_SCHEDULE,
    ADD_WORK_SCHEDULE,
    SET_WORK_SCHEDULE_ROLES,
    SELECT_WORK_SCHEDULE_EVENT,
    SET_WORK_SCHEDULE_LOCK_DATE,
    UPDATE_WORK_SCHEDULE,
    REMOVE_SCHEDULES_BY_ID,
    DELETE_WORK_SCHEDULE,
    REMOVE_SCHEDULE,
    REMOVE_SCHEDULES_STARTING_FROM,
    SET_WORK_SCHEDULE_HOLIDAYS,
} from './actions';

import WorkScheduleService from '@services/workschedule.service';
import store from '../../../store';
import {convertTimesToUtc, convertTimesFromUtc} from '../../storeHelpers';
import Vue from 'vue';

const convertFromUtc = (data) => {
    const utcStartDate = convertTimesFromUtc(data.start_date, data.start_time, store.getters.user.timezone, data.repeat_start);
    const utcEndDate = convertTimesFromUtc(data.start_date, data.end_time, store.getters.user.timezone, data.repeat_start);
    data.start_date = utcStartDate.date;
    data.start_time = utcStartDate.time;
    data.end_time = utcEndDate.time;
    data.updated_at = Vue.moment.utc(data.updated_at).tz(store.getters.user.timezone).format('YYYY-MM-DDTHH:mm:ss');
};

const defaultState = () => {
    return {
        types: [],
        settings: {},
        employees: [],
        roles: [],
        selectedWorkSchedule: {},
        lock_date: null,
        holidays: [],
    };
};

const state = defaultState;

const mutations = {
    [RESET_WORK_SCHEDULE_STORE](state) {
        Object.assign(state, defaultState());
    },
    [SET_WORK_SCHEDULE_TYPES](state, types) {
        state.types = types;
    },
    [SET_WORK_SCHEDULES](state, schedules) {
        state.employees = schedules;
    },
    [ADD_WORK_SCHEDULE_TYPE](state, type) {
        state.types.push(type);
    },
    [UPDATE_WORK_SCHEDULE_TYPE](state, data) {
        let index = state.types.findIndex(type => type.id === data.id);
        Vue.set(state.types, index, data);
    },
    [SET_WORK_SCHEDULE_SETTINGS](state, settings) {
        state.settings = settings;
    },
    [ADD_WORK_SCHEDULE](state, data) {
        let employee = state.employees.find(s => s.id === data.employee.id);
        if(employee) {
            employee.work_schedules = [...employee.work_schedules, ...data.schedule_events];
            Vue.set(state.employees, state.employees.indexOf(employee), employee);
        }
    },
    [SET_WORK_SCHEDULE_ROLES](state, roles) {
        state.roles = roles;
    },
    [SELECT_WORK_SCHEDULE_EVENT](state, event) {
        state.selectedWorkSchedule = event;
    },
    [SET_WORK_SCHEDULE_LOCK_DATE](state, date) {
        state.lock_date = date;
    },
    [UPDATE_WORK_SCHEDULE](state, event) {
        const employee = state.employees.find(e => {
            return event.employee_id === e.id;
        });
        if (employee) {
            employee.work_schedules.push(event);
            Vue.set(state.employees, state.employees.indexOf(employee), employee);
        }
    },
    [REMOVE_SCHEDULES_BY_ID](state, data) {
        const employee = state.employees.find(e => {
            return e.id === data.employee_id;
        });

        if(employee) {
            let filteredList = employee.work_schedules.filter(e => {
                return e.id !== data.id;
            });
            Vue.set(employee, 'work_schedules', filteredList);
            Vue.set(state.employees, state.employees.indexOf(employee), employee);
        }
    },
    [REMOVE_SCHEDULE](state, data) {
        const employee = state.employees.find(e => {
            return e.id === data.employee_id;
        });
        let filteredList = employee.work_schedules.filter(e => {
            return e.unique_id !== data.unique_id;
        });
        Vue.set(employee, 'work_schedules', filteredList);
        Vue.set(state.employees, state.employees.indexOf(employee), employee);
    },
    [REMOVE_SCHEDULES_STARTING_FROM](state, data) {
        const employee = state.employees.find(e => {
            return e.id === data.employee_id;
        });
        const startDate = new Date(data.date);
        if(employee) {
            let filteredList = employee.work_schedules.filter(e => {
                let eventStartDate = new Date(e.start_date);
                return !(e.id === data.id && eventStartDate.getDate() >= startDate.getDate()) || e.id !== data.id;
            });
            Vue.set(employee, 'work_schedules', filteredList);
            Vue.set(state.employees, state.employees.indexOf(employee), employee);
        }
    },
    [SET_WORK_SCHEDULE_HOLIDAYS](state, holidays) {
        state.holidays = holidays;
    }
};
const actions = {
    [GET_WORK_SCHEDULE_TYPES]({commit, getters}) {
        return WorkScheduleService.getWorkScheduleTypes(getters.activeKindergartenId).then(res => {
            commit(SET_WORK_SCHEDULE_TYPES, res);
        });
    },
    [SAVE_WORK_SCHEDULE_TYPE]({commit, getters}, type) {
        return WorkScheduleService.addWorkScheduleType(getters.activeKindergartenId, type).then(res => {
            commit(ADD_WORK_SCHEDULE_TYPE, res);
        });
    },
    [UPDATE_WORK_SCHEDULE_TYPE]({commit, getters}, data) {
        return WorkScheduleService.updateWorkScheduleType(getters.activeKindergartenId, data.id, data).then(res => {
           commit(UPDATE_WORK_SCHEDULE_TYPE, res);
        });
    },
    [GET_WORK_SCHEDULE_SETTINGS]({commit, getters}) {
        return WorkScheduleService.getWorkScheduleSettings(getters.activeKindergartenId).then(res => {
           commit(SET_WORK_SCHEDULE_SETTINGS, res);
        });
    },
    [UPDATE_WORK_SCHEDULE_SETTINGS]({commit, getters}, data) {
        return WorkScheduleService.updateWorkScheduleSettings(getters.activeKindergartenId, data).then(res => {
            commit(SET_WORK_SCHEDULE_SETTINGS, res);
        });
    },
    [GET_WORK_SCHEDULES]({commit, getters}, options) {
        return WorkScheduleService.getWorkSchedule(getters.activeKindergartenId, options).then(res => {
            res.employees.forEach(e => {
                e.work_schedules.forEach(s => {
                    if (!s.full_day) {
                        convertFromUtc(s);
                    }
                });
            });

            commit(SET_WORK_SCHEDULES, res.employees);
            commit(SET_WORK_SCHEDULE_ROLES, res.roles);
            commit(SET_WORK_SCHEDULE_LOCK_DATE, res.lock_date);
            commit(SET_WORK_SCHEDULE_TYPES, res.types);
            commit(SET_WORK_SCHEDULE_HOLIDAYS, res.holidays);
        });
    },
    [INSERT_WORK_SCHEDULE]({commit, getters}, data) {
        const dataCopy = {...data};
        if (!dataCopy.full_day) {
            const utcStartDate = convertTimesToUtc(dataCopy.start_date, dataCopy.start_time ? dataCopy.start_time : false, store.getters.user.timezone);
            const utcEndDate = convertTimesToUtc(dataCopy.start_date, dataCopy.end_time ? dataCopy.end_time : false, store.getters.user.timezone);
            dataCopy.start_date = utcStartDate.date;
            dataCopy.start_time = utcStartDate.time;
            dataCopy.end_time = utcEndDate.time;
        }

        return WorkScheduleService.insertWorkSchedule(getters.activeKindergartenId, dataCopy).then(res => {
            res.schedule_events.forEach(s => {
                if (!s.full_day) {
                    convertFromUtc(s);
                }
            });
            commit(ADD_WORK_SCHEDULE, res);
        });
    },
    [UPDATE_WORK_SCHEDULE]({commit, getters}, data) {
        const dataCopy = {...data};
        if (!dataCopy.full_day) {
            let start;
            if(dataCopy.change_mode === 'all') {
                start = dataCopy.repeat_start ?? getters.selectedWorkSchedule.repeat_start ?? dataCopy.start_date ?? getters.selectedWorkSchedule.start_date;
            } else if ( dataCopy.change_mode === 'from') {
                start = (dataCopy.is_recurring ? dataCopy.repeat_start ?? getters.selectedWorkSchedule.repeat_start : null) ?? dataCopy.start_date ?? getters.selectedWorkSchedule.start_date;
            } else {
                start = dataCopy.start_date;
            }

            if (dataCopy.start_time) {
                const utcStartTime = convertTimesToUtc(start, dataCopy.start_time, store.getters.user.timezone);
                dataCopy.start_date = utcStartTime.date;
                dataCopy.start_time = utcStartTime.time;
            }

            if (dataCopy.end_time) {
                const utcEndTime = convertTimesToUtc(start, dataCopy.end_time, store.getters.user.timezone);
                dataCopy.end_time = utcEndTime.time;
            }
        }

        return WorkScheduleService.updateWorkSchedule(getters.activeKindergartenId, dataCopy).then(res => {
            commit(REMOVE_SCHEDULES_BY_ID, data);
            res.schedule_events.forEach(event => {
                if (!event.full_day) {
                    convertFromUtc(event);
                }
                commit(UPDATE_WORK_SCHEDULE, event);
            });
        });
    },
    [DELETE_WORK_SCHEDULE]({commit, getters}, data) {
        return WorkScheduleService.deleteWorkSchedule(getters.activeKindergartenId, data).then(() => {
            switch (data.change_mode) {
                case 'single':
                    commit(REMOVE_SCHEDULE, data);
                    break;
                case 'all':
                    commit(REMOVE_SCHEDULES_BY_ID, data);
                    break;
                case 'from':
                    commit(REMOVE_SCHEDULES_STARTING_FROM, data);
                    break;
            }
        });
    }
};
const getters = {
    workScheduleTypes : state => state.types,
    workScheduleSettings : state => state.settings,
    workSchedulesEmployees: state => state.employees,
    workScheduleRoles : state => state.roles,
    selectedWorkSchedule: state => state.selectedWorkSchedule,
    workScheduleLockDate: state => state.lock_date,
    workScheduleHolidays: state => state.holidays,
};

export default {
    state,
    mutations,
    actions,
    getters
};
