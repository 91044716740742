export const GET_MANAGED_QUESTIONNAIRES = 'GET_MANAGED_QUESTIONNAIRES';
export const SET_MANAGED_QUESTIONNAIRES = 'SET_MANAGED_QUESTIONNAIRES';
export const STORE_QUESTIONNAIRE = 'STORE_QUESTIONNAIRE';
export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const SET_QUESTIONNAIRE = 'SET_QUESTIONNAIRE';
export const GET_QUESTIONNAIRES = 'GET_QUESTIONNAIRES';
export const SET_QUESTIONNAIRES = 'SET_QUESTIONNAIRES';
export const SUBMIT_QUESTIONNAIRE = 'SUBMIT_QUESTIONNAIRE';
export const GET_QUESTIONNAIRE_QUESTIONS = 'GET_QUESTIONNAIRE_QUESTIONS';
export const SET_QUESTIONNAIRE_QUESTIONS = 'SET_QUESTIONNAIRE_QUESTIONS';
export const FILL_QUESTIONNAIRE_QUESTION = 'FILL_QUESTIONNAIRE_QUESTION';
export const SET_QUESTION_PAGE = 'SET_QUESTION_PAGE';
export const SET_SUBMITTED_AT = 'SET_SUBMITTED_AT';
export const UNSUBMIT_QUESTIONNAIRE = 'UNSUBMIT_QUESTIONNAIRE';
export const GET_QUESTIONNAIRE_ANSWERS = 'GET_QUESTIONNAIRE_ANSWERS';
export const GET_QUESTIONNAIRE_RESULTS = 'GET_QUESTIONNAIRE_RESULTS';