export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENT = 'GET_EVENT';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_EVENT = 'SET_EVENT';
export const ADD_EVENT = 'ADD_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const REMOVE_EVENTS_BY_ID = 'REMOVE_EVENTS_BY_ID';
export const REMOVE_EVENTS_STARTING_FROM = 'REMOVE_EVENTS_STARTING_FROM';
export const DELETE_EVENT = 'DELETE_EVENT';
export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';
export const SET_SELECTED_EVENT_OBJ = 'SET_SELECTED_EVENT_OBJ';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const GET_MY_EVENTS = 'GET_MY_EVENTS';
export const GET_RECENT_EVENTS = 'GET_RECENT_EVENTS';
export const SET_RECENT_EVENTS = 'SET_RECENT_EVENTS';
export const SET_RECENT_EVENTS_COUNT = 'SET_RECENT_EVENTS_COUNT';
export const UPDATE_EVENTS_ACTIVITY = 'UPDATE_EVENTS_ACTIVITY';
export const GET_ORG_EVENTS = 'GET_ORG_EVENTS';
export const GET_RECENT_ORG_EVENTS = 'GET_RECENT_ORG_EVENTS';
export const UPDATE_ORG_EVENTS_ACTIVITY = 'UPDATE_ORG_EVENTS_ACTIVITY';
