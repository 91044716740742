import http from '@http';

class OfficialService {
    constructor() {
        if (OfficialService.instance) {
            return OfficialService.instance;
        }

        OfficialService.instance = this;
    }

    getKindergartens(organizationId, officialId) {
        return http.get(`/api/organizations/${organizationId}/officials/${officialId}/kindergartens`).then(res => res.data);
    }

    updateSettings(organizationId, officialId, data) {
        return http.put(`/api/organizations/${organizationId}/officials/${officialId}/settings`, data)
            .then(res => res.data);
    }

}
export default new OfficialService();
