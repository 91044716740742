export const RESET_EMPLOYEE_STATE = 'RESET_EMPLOYEE_STATE';
export const GET_EMPLOYEE_COURSES = 'GET_EMPLOYEE_COURSES';
export const SET_EMPLOYEE_COURSES = 'SET_EMPLOYEE_COURSES';
export const SET_EMPLOYEE_COURSE = 'SET_EMPLOYEE_COURSE';
export const SAVE_EMPLOYEE_COURSE = 'SAVE_EMPLOYEE_COURSE';
export const SET_EMPLOYEE_SETTINGS = 'SET_EMPLOYEE_SETTINGS';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const ARCHIVE_EMPLOYEE = 'ARCHIVE_EMPLOYEE';
export const RESTORE_EMPLOYEE = 'RESTORE_EMPLOYEE';
export const SET_EMPLOYEE_CHILDREN = 'SET_EMPLOYEE_CHILDREN';
export const GET_EMPLOYEE_CHILDREN = 'GET_EMPLOYEE_CHILDREN';
export const SET_EMPLOYEE_COURSES_DATE = 'SET_EMPLOYEE_COURSES_DATE';
// export const GET_EMPLOYEE_ACTIVE_COURSES = 'GET_EMPLOYEE_ACTIVE_COURSES';
// export const GET_EMPLOYEE_ALL_COURSES = 'GET_EMPLOYEE_ALL_COURSES';
