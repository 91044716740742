<template>
    <div :class="[{'has-feedback has-error': validation && validation.$error}]">
        <label :for="id">
            <slot name="label"/>
            <template v-if="optional">
                - <small>{{ $t('Optional') }}</small>
            </template>
        </label>
        <div class="mb-1">
            <button v-for="(item, i) of langs"
                    :key="i"
                    type="button"
                    class="btn btn-sm text-uppercase"
                    :class="{ 'btn-primary': item.key === lang }"
                    @click="setLang(item.key)">
                {{ item.key }}
            </button>
        </div>
        <QuillTextEditor
            :id="id"
            :disabled="disabled"
            :content="editorContent"
            :placeholder="placeholder"
            class="e3-no-paragraph text-break text-content e3-editor bg-white"
            @input="update"
            @blur="update"/>
        <ValitationMessages :validation="validation"/>
    </div>
</template>

<script>
import QuillTextEditor from '../QuillTextEditor.vue';

export default {
    name: 'EMultiLangEditor',
    props: {
        id: [String, Number],
        value: Object,
        langs: Array,
        optional: Boolean,
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        validation: Object,
        selectedLang: {
            type: String
        }
    },
    data() {
        return {
            lang: this.selectedLang || this.$i18n.locale || 'en'
        };
    },
    computed: {
        data: {
            get() {
                if (this.value && typeof this.value === 'object' && this.value.constructor === Object) {
                    return Object.assign({}, this.value);
                }

                return {};
            }
        },
        editorContent() {
            const text = this.data[this.lang] || '';
            return text;
        },
    },
    components: {
        QuillTextEditor
    },
    methods: {
        update(text) {
            const data = Object.assign({}, this.data);
            data[this.lang] = text;
            this.$emit('input', data);
        },
        setLang(lang) {
            this.lang = lang;
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
