<template>
    <div v-clickOutside="blur" class="position-relative e3-time-picker">
        <input
            v-model="timeVal"
            :placeholder="label"
            class="form-control"
            :class="{'is-invalid': validation && validation.$error}"
            :disabled="disabled"
            @blur="inputBlur"
            @focus="focus"/>
        <button class="btn time-icon">
            <svg class="svg-18 text-primary" :class="{'is-invalid': validation && validation.$error}" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z"/>
            </svg>
        </button>
        <b-time
            :id="id"
            :value="timeVal"
            button-only
            class="time-picker border rounded p-2"
            :class="{'open': pickerOpen}"
            button-variant="link"
            :right="!isLeft"
            :locale="language"
            :minutes-step="minutesStep"
            v-bind="labels || {}"
            :disabled="disabled"
            :hide-header="true"
            @input="pickerInput">
            <div v-if="reset" class="d-flex justify-content-center">
                <button
                    v-if="timeVal"
                    class="btn btn-danger btn-sm"
                    @click="clearTime">
                    {{ $t("Clear time") }}
                </button>
            </div>
        </b-time>
        <ValitationMessages :validation="validation"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'ETimepicker',
    directives: {
        clickOutside: {
            bind: function(el, binding, vNode) {
                el.__vueClickOutside__ = event => {
                    if (!el.contains(event.target)) {
                        vNode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener('click', el.__vueClickOutside__);
            },
            unbind: function(el) {
                document.removeEventListener('click', el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            }
        }
    },
    props: {
        id: [String, Number],
        value: {
            type: String,
            default: ''
        },
        reset: {
            type: Boolean,
            default: false
        },
        minutesStep: {
            type: Number,
            default: 1
        },
        label: {
            type: String,
            default: 'HH:MM'
        },
        validation: Object,
        disabled: Boolean,
        isLeft: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            pickerOpen: false,
            inputFocused: false,
            labels: {
                labelHours: this.$t('Hours'),
                labelMinutes: this.$t('Minutes'),
                labelSeconds: this.$t('Seconds'),
                labelIncrement: this.$t('Increase'),
                labelDecrement: this.$t('Decrease'),
                labelSelected: this.$t('Selected time'),
                labelNoTimeSelected: this.$t('No time selected')
            }
        };
    },
    computed: {
        ...mapGetters(['language']),
        timeVal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        focus() {
            this.inputFocused = true;
            if (!this.timeVal) {
                this.timeVal = '12:00';
            }
            this.pickerOpen = true;
        },
        blur() {
            this.pickerOpen = false;
            if (this.timeVal && this.timeVal.slice(1,2) == ':') {
                this.timeVal = '0' + this.timeVal;
            }
        },
        clearTime() {
            this.timeVal = '';
        },
        inputBlur() {
            this.inputFocused = false;
        },
        pickerInput(e) {
            if (!this.inputFocused) {
                this.timeVal = e.slice(0, 5);
            }
        }
    },
    created() {
        if (this.value) {
            this.timeVal = this.value.slice(0, 5);
        }
    }
};
</script>

<style scoped lang="scss">
@import "@common/styles/variables/_variables.scss";

.time-picker {
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    background-color: #fff;
    right: 0;
    margin-top: 2px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    &.open {
        height: auto;
        opacity: 1;
        z-index: 2;
    }
}

.time-icon {
    position: absolute;
    top: 2px;
    right: 0;
    pointer-events: none;
    .is-invalid {
        color: $danger !important;
    }
}
.form-control.is-invalid {
    background-image: none;
    border: 1px solid $danger !important;
}

</style>
