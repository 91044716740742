import {
    GET_MANAGED_QUESTIONNAIRES,
    SET_MANAGED_QUESTIONNAIRES,
    STORE_QUESTIONNAIRE,
    GET_QUESTIONNAIRE,
    SET_QUESTIONNAIRE,
    GET_QUESTIONNAIRES,
    SET_QUESTIONNAIRES,
    SUBMIT_QUESTIONNAIRE,
    GET_QUESTIONNAIRE_QUESTIONS,
    SET_QUESTIONNAIRE_QUESTIONS,
    FILL_QUESTIONNAIRE_QUESTION,
    SET_QUESTION_PAGE,
    SET_SUBMITTED_AT,
    UNSUBMIT_QUESTIONNAIRE,
    GET_QUESTIONNAIRE_ANSWERS,
    GET_QUESTIONNAIRE_RESULTS,
} from './actions';
import SurveyService from '@services/survey.service';
import QuestionnaireService from '@services/questionnaire.service';
const defaultState = () => {
    return {
        managedQuestionnaires: [],
        questionnaires: [],
        questionnaire: null,
        page: null,
    };
};

const state = defaultState;

const mutations = {
    [SET_MANAGED_QUESTIONNAIRES] (state, data) {
        state.managedQuestionnaires = data;
    },
    [SET_QUESTIONNAIRE](state, data) {
        state.questionnaire = data;
    },
    [SET_QUESTIONNAIRES](state, data) {
        state.questionnaires = data;
    },
    [SET_QUESTIONNAIRE_QUESTIONS](state, data) {
        state.questionnaire.questions = data;
    },
    [SET_QUESTION_PAGE](state ,data) {
        state.page = data;
    },
    [SET_SUBMITTED_AT](state, date) {
        state.questionnaire.submitted_at = date;
    }
};

const actions = {
    [GET_MANAGED_QUESTIONNAIRES]({commit, getters}, queryObj) {
        return SurveyService.getManagedQuestionnaires(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_MANAGED_QUESTIONNAIRES, res);
        });
    },
    [STORE_QUESTIONNAIRE]({getters}, data) {
        return SurveyService.storeQuestionnaire(getters.activeKindergartenId, data).then(res => {
            return res.survey_id;
        });
    },
    [GET_QUESTIONNAIRES]({getters, commit}, queryObj) {
        return QuestionnaireService.getQuestionnaires(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_QUESTIONNAIRES, res);
        });
    },
    [GET_QUESTIONNAIRE]({getters, commit}, id) {
        return QuestionnaireService.getQuestionnaire(getters.activeKindergartenId, id).then(res => {
            commit(SET_QUESTIONNAIRE, res.survey);
            commit(SET_QUESTION_PAGE, res.page);
            return res;
        });
    },
    [GET_QUESTIONNAIRE_QUESTIONS]({getters, commit}, data) {
        return QuestionnaireService.getQuestionnaireQuestions(getters.activeKindergartenId, data.survey_id, data.queryObj).then(res => {
            commit(SET_QUESTIONNAIRE_QUESTIONS, res);
            return res;
        });
    },
    [FILL_QUESTIONNAIRE_QUESTION]({getters}, data) {
        return QuestionnaireService.fillQuestionnaireQuestion(getters.activeKindergartenId, data.survey_id, data.question_id, data);
    },
    [SUBMIT_QUESTIONNAIRE]({getters, commit}, id) {
        return QuestionnaireService.submitQuestionnaire(getters.activeKindergartenId, id).then(res => {
            commit(SET_SUBMITTED_AT, res);
        });
    },
    [UNSUBMIT_QUESTIONNAIRE]({getters, commit}, id) {
        return QuestionnaireService.unSubmitQuestionnaire(getters.activeKindergartenId, id).then(() => {
            commit(SET_SUBMITTED_AT, null);
        });
    },
    [GET_QUESTIONNAIRE_ANSWERS]({getters, commit}, id) {
        commit(SET_QUESTIONNAIRE, null);
        return QuestionnaireService.getQuestionnaireAnswers(getters.activeKindergartenId, id).then(res => {
            commit(SET_QUESTIONNAIRE, res);
        });
    },
    [GET_QUESTIONNAIRE_RESULTS]({getters, commit}, id) {
        commit(SET_QUESTIONNAIRE, null);
        return QuestionnaireService.getQuestionnaireResults(getters.activeKindergartenId, id).then(res => {
            commit(SET_QUESTIONNAIRE, res);
        });
    },
};

const getters = {
    managedQuestionnaires: state => state.managedQuestionnaires,
    questionnaires: state => state.questionnaires,
    questionnaire: state => state.questionnaire,
    questionnairePage: state => state.page
};

export default {
    state,
    mutations,
    actions,
    getters
};