<template>
    <div>
        <ELoader v-if="isContentLoading"/>
        <template v-else>
            <PageTitle>
                {{ pageTitle }}
            </PageTitle>

            <!-- Section header -->
            <div class="d-flex flex-column flex-sm-row flex-wrap align-items-center">
                <h5 class="d-flex flex-wrap mb-0 mr-auto">
                    <router-link :to="{ name: 'gallery.index' }">
                        {{ $t('Gallery') }}
                    </router-link>
                    <span v-for="crumb in breadcrumbs" :key="`c-${crumb.id}`" class="d-flex">
                        <SvgIcon icon="mdi-chevron-right" source="menu-icons" size="24"/>
                        <router-link
                            v-if="crumb.id !== activeFolder"
                            :to="{
                                name: 'gallery.builtin.child-profile',
                                params: { child_id: typeof crumb.id === 'string' ? null : crumb.id }
                            }"
                            class="nav-link p-0"
                            :title="crumbName(crumb)">
                            {{ truncate(crumbName(crumb), 20) }}
                        </router-link>
                        <span v-else :title="crumbName(crumb)">{{ truncate(crumbName(crumb), 20) }}</span>
                    </span>
                </h5>

                <!-- Section header.settings & search -->
                <div v-if="folders.length || files.length" class="ml-auto my-2 my-sm-0">
                    <ESearchInput
                        v-model="searchValue"
                        :placeholder="$t('Type to search')"
                        @getEntities="filterContent"></ESearchInput>
                </div>
            </div>

            <!-- Section content -->
            <div v-if="shownFolders.length" class="container-fluid mt-2">
                <div class="row font-weight-bold e3-doc-head-hide mb-2">
                    <div class="col-3 p-0">{{ $t('Name') }}</div>
                </div>
                <div>
                    <div
                        v-for="folder in shownFolders"
                        :key="`folder-${folder.id}`"
                        class="e3-last-border e3-hover-show row border-top py-2">
                        <div class="col-3 col-sm col-md-3 d-flex align-items-center p-0 pr-2"
                             :title="folder.name">
                            <div class="d-flex text-nowrap w-100 h-100">
                                <router-link
                                    :to="{name: 'gallery.builtin.child-profile', params: {'child_id': folder.id}}"
                                    class="d-flex align-items-center nav-link e3-hover-underline p-0">
                                    <FolderIcon
                                        class="small mr-2"
                                        isGallery
                                        isReadonly/>
                                    {{ folder.name }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showLoadMore" class="d-flex mt-3">
                    <button type="button" class="btn btn-primary mx-auto" @click="loadMore">
                        {{ $t("Load more") }}
                    </button>
                </div>
            </div>

            <div v-else-if="shownFiles.length">
                <div
                    class="row d-flex flex-wrap justify-content-center justify-content-sm-start">
                    <FileGrid
                        :files="shownFiles"
                        type="gallery_builtin_child"
                        class="container-fluid"/>
                </div>
                <div class="row d-flex flex-wrap mx-n2 justify-content-center">
                    <div v-if="showLoadMore" class="d-flex mt-3">
                        <button type="button" class="btn btn-primary mx-auto" @click="loadMore">
                            {{ $t("Load more") }}
                        </button>
                    </div>
                </div>
            </div>

            <EmptyListMessage v-else class="border rounded p-2 bg-white mt-3">
                <template v-if="activeFolder === routeName">
                    {{ $t('No folders found') }}
                </template>
                <template v-else>
                    {{ $t('No files found') }}
                </template>
            </EmptyListMessage>
        </template>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {truncate} from '@/filters';
import ESearchInput from '@/common/components/input/SearchInput.vue';
import FileGrid from '@/views/folder/components/grid/FileGrid.vue';
import FolderIcon from '@/views/folder/components/FolderIcon.vue';
import FolderService from '@/common/services/folder.service';

export default {
    name: 'GalleryChildProfileFolder',
    data() {
        return {
            routeName: 'child-profile',
            breadcrumbs: [],
            activeFolder: '',
            files: [],
            folders: [],
            searchValue: '',
            shownFiles: [],
            filesPerPage: 18,
            shownFolders: [],
            foldersPerPage: 20,
            page: 1,
        };
    },
    computed: {
        ...mapGetters([
            'activeKindergartenId',
            'isContentLoading',
            'language',
        ]),
        pageTitle() {
            const title = this.$t('Album');
            const folder = this.crumbName(this.breadcrumbs.slice(-1)[0]);
            return title.concat(': ', folder);
        },
        showLoadMore() {
            if (this.activeFolder !== this.routeName) {
                return this.searchValue === '' &&
                    this.page * this.filesPerPage < this.files.length;
            } else {
                return this.searchValue === '' &&
                    this.page * this.foldersPerPage < this.folders.length;
            }
        },
    },
    watch: {
        '$route.params.child_id': function(newValue) {
            newValue = parseInt(newValue);
            if (isNaN(newValue)) {
                newValue = '';
                this.activeFolder = this.routeName;
            } else {
                this.activeFolder = newValue;
            }
            this.page = 1;
            this.getContent(newValue);
        },
    },
    methods: {
        truncate, // TODO: Vue3 remove
        /** @param {{id: number|string, name: string, name_string: string }} crumb */
        crumbName(crumb) {
            if (crumb.name_string) {
                return this.$t(crumb.name_string);
            } else {
                return crumb.name;
            }
        },
        /** @param {number|string} child */
        getContent(child = '') {
            this.$store.dispatch('START_CONTENT_LOADER');
            const params = {
                module: 'gallery',
            };
            FolderService.getBuiltinChildProfile(this.activeKindergartenId, child, params).then(res => {
                this.breadcrumbs = res.breadcrumbs;
                this.folders = res.folders;
                this.files = res.files;
                this.searchValue = '';
                this.sortContent();
                this.filterContent();
            }).finally(() => {
                this.$store.dispatch('STOP_CONTENT_LOADER');
            });
        },
        sortContent() {
            const language = this.language;
            this.files.sort(function(a, b) {
                return a.name.localeCompare(b.name, language);
            });
            this.folders.sort(function(a, b) {
                return a.name.localeCompare(b.name, language);
            });
        },
        setFiles() {
            if (this.searchValue === '') {
                this.shownFiles = this.files.slice(0, this.filesPerPage);
            } else {
                this.page = 1;
                const searchValue = this.searchValue.toLocaleLowerCase(this.language);
                this.shownFiles = this.files.filter(file => {
                    const name = file.name.toLocaleLowerCase(this.language);
                    return name.includes(searchValue);
                });
            }
        },
        setFolders() {
            if (this.searchValue === '') {
                this.shownFolders = this.folders.slice(0, this.foldersPerPage);
            } else {
                this.page = 1;
                const searchValue = this.searchValue.toLocaleLowerCase(this.language);
                this.shownFolders = this.folders.filter(folder => {
                    const name = folder.name.toLocaleLowerCase(this.language);
                    return name.includes(searchValue);
                });
            }
        },
        filterContent() {
            this.shownFolders = [];
            this.shownFiles = [];
            if (this.files.length > 0) {
                this.setFiles();
            } else if (this.folders.length > 0) {
                this.setFolders();
            }
        },
        loadMore() {
            if (!this.showLoadMore) {
                return;
            }
            if (this.activeFolder !== this.routeName) {
                const max = this.files.length;
                this.page++;
                let sliceSize = this.page * this.filesPerPage;
                sliceSize = Math.min(sliceSize, max);
                this.shownFiles = this.files.slice(0, sliceSize);
            } else {
                const max = this.folders.length;
                this.page++;
                let sliceSize = this.page * this.foldersPerPage;
                sliceSize = Math.min(sliceSize, max);
                this.shownFolders = this.folders.slice(0, sliceSize);
            }
        },
    },
    components: {
        ESearchInput,
        FileGrid,
        FolderIcon,
    },
    created() {
        const child = parseInt(this.$route.params.child_id);
        if (child) {
            this.activeFolder = child;
            this.getContent(child);
        } else {
            this.activeFolder = this.routeName;
            this.getContent();
        }
    }
};
</script>

<style scoped>
</style>
