import http from '@http';

class AdminKindergartenService {
    constructor() {
        if (AdminKindergartenService.instance) {
            return AdminKindergartenService.instance;
        }

        AdminKindergartenService.instance = this;
    }

    getKindergartens(queryObj) {
        return http.get('/api/admin/kindergartens', {params: queryObj}).then(res => res.data);
    }

    getKindergarten(kindergartenId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}`).then(res => res.data);
    }

    addKindergarten(kindergarten) {
        return http.post('/api/admin/kindergartens', kindergarten).then(res => res.data);
    }

    getKindergartenCountries() {
        return http.get('/api/admin/kindergartens/countries').then(res => res.data);
    }

    getKindergartenManagers(kindergartenId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/managers`).then(res => res.data);
    }

    updateKindergartenManager(kindergartenId, data) {
        return http.put(`/api/admin/kindergartens/${kindergartenId}/managers`, data).then(res => res.data);
    }

    updateKindergarten(kindergartenId, data) {
        return http.put(`/api/admin/kindergartens/${kindergartenId}`, data).then(res => res.data);
    }

    updateOrganization(kindergartenId, data) {
        return http.put(`/api/admin/kindergartens/${kindergartenId}/organization`, data).then(res => res.data);
    }

    getKindergartenEmployees(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/employees`, {params: queryObj}).then(res => res.data);
    }

    detachKindergartenEmployeeUser(kindergartenId, employeeId) {
        return http.put(`/api/admin/kindergartens/${kindergartenId}/employees/${employeeId}/detach`).then(res => res.data);
    }

    getKindergartenGuardians(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/guardians`, {params: queryObj}).then(res => res.data);
    }

    getKindergartenRoles(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/roles`, {params: queryObj}).then(res => res.data);
    }

    getKindergartenChildren(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/children`, {params: queryObj}).then(res => res.data);
    }

    getKindergartenApplications(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/applications`, {params: queryObj}).then(res => res.data);
    }

    getKindergartenTemplates(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/templates`, {params: queryObj}).then(res => res.data);
    }

    getKindergartenAnnouncements(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/announcements`, {params: queryObj}).then(res => res.data);
    }

    getKindergartenTargetGroups(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/target-groups`, {params: queryObj}).then(res => res.data);
    }

    validateKindergartenTargetGroupMembers(kindergartenId, targetGroupId, options) {
        return http.post(`/api/admin/kindergartens/${kindergartenId}/target-groups/${targetGroupId}/validate`, options).then(res => res.data);
    }

    getKindergartenFolders(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/folders`, {params: queryObj}).then(res => res.data);
    }

    getKindergartenFolder(kindergartenId, folderId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/folders/${folderId}`).then(res => res.data);
    }

    getKindergartenEvents(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/events`, {params: queryObj}).then(res => res.data);
    }


    getKindergartenChild(kindergartenId, childId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/children/${childId}`).then(res => res.data);
    }

    getKindergartenCourse(kindergartenId, courseId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/courses/${courseId}`).then(res => res.data);
    }

    getKindergartenCourseChildren(kindergartenId, courseId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/courses/${courseId}/children`).then(res => res.data);
    }

    getKindergartenCourseTeachers(kindergartenId, courseId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/courses/${courseId}/teachers`).then(res => res.data);
    }

    getKindergartenCourseDiary(kindergartenId, courseId, date) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/courses/${courseId}/diary`, {params: {date}}).then(res => res.data);
    }

    getKindergartenCoursePlanTypes(kindergartenId, courseId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/courses/${courseId}/plan-types`).then(res => res.data);
    }
    getKindergartenCoursePlans(kindergartenId, courseId, type) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/courses/${courseId}/plans`, {params: {type}}).then(res => res.data);
    }

    getKindergartenCoursePlan(kindergartenId, courseId, planId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/courses/${courseId}/plans/${planId}`).then(res => res.data);
    }

    getKindergartenCourseGuardians(kindergartenId, courseId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/courses/${courseId}/guardians`).then(res => res.data);
    }

    getKindergartenCourseTargetGroups(kindergartenId, courseId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/courses/${courseId}/target-groups`).then(res => res.data);
    }

    getKindergartenChildGuardians(kindergartenId, childId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/children/${childId}/guardians`).then(res => res.data);
    }

    getKindergartenChildCourses(kindergartenId, childId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/children/${childId}/courses`).then(res => res.data);
    }

    getKindergartenClasses(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/classes`, {params: queryObj}).then(res => res.data);
    }

    getKindergartenChildDiaries(kindergartenId, childId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/children/${childId}/diaries`).then(res => res.data);
    }

    getKindergartenChildAttendance(kindergartenId, childId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/children/${childId}/attendance`).then(res => res.data);
    }

    getKindergartenChildCards(kindergartenId, childId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/children/${childId}/cards`).then(res => res.data);
    }

    getKindergartenChildCard(kindergartenId, childId, cardId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/children/${childId}/cards/${cardId}`).then(res => res.data);
    }

    restoreKindergartenChildCard(kindergartenId, childId, cardId) {
        return http.put(`/api/admin/kindergartens/${kindergartenId}/children/${childId}/cards/${cardId}`, {card_id: cardId}).then(res => res.data);
    }
    getKindergartenChildApplications(kindergartenId, childId) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/children/${childId}/applications`).then(res => res.data);
    }

    getKindergartenWorkSchedules(kindergartenId, queryObj) {
        return http.get(`/api/admin/kindergartens/${kindergartenId}/work-schedules`, {params: queryObj}).then(res => res.data);
    }
}

export default new AdminKindergartenService();
