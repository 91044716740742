import {vm} from '@/main';
import Vue from 'vue';
import { hasCookieAccepted } from '@/utils/cookieConsent';

import {
    SET_LANGUAGE,
    SET_TRANSLATES,
    GET_LANGUAGES,
    SET_LANGUAGES,
    SET_COUNTRY,
    SET_COUNTRY_LANGUAGES,
    LANGUAGE_LOADED,
    SET_RTL,
} from '@store/localization/constants';
import {START_CONTENT_LOADER, STOP_CONTENT_LOADER} from '@store/loader/actions';
import settingsService from '@services/settings.service';
import userService from '@/common/services/user.service';
import {adjustLocaleNumbers} from '../../storeHelpers';

const state = {
    languages: {},
    country: 'ee',
    language: localStorage.getItem('lang'),
    countryLanguages: { // getLanguages is cached these static values can be removed later
        ee: ['et'],
        lv: ['lv'],
        lt: ['lt'],
        pl: ['pl']
    },
    translates: {},
    languageLoaded: false,
    isRtl: false,
};

const mutations = {
    [SET_LANGUAGE](state, key) {
        if(typeof key === 'string') {
            if (key == 'ar') {
                adjustLocaleNumbers(key);
            }

            state.language = key;
            vm.$i18n.locale = state.language;
            Vue.moment.locale(state.language);
            localStorage.setItem('lang', state.language);
            if (hasCookieAccepted('essential')) {
                if (process.env.NODE_ENV !== 'development') {
                    vm.$cookies.set('lang', state.language, '10y', null, `.${window.location.hostname}`, true);
                } else {
                    vm.$cookies.set('lang', state.language, '10y');
                }
            }
        } else {
            throw new TypeError('Key should be lang key (string)');
        }
    },

    [SET_TRANSLATES](state, {key, data}) {
        Vue.set(state.translates, key, data);
        vm.$i18n.setLocaleMessage(key, data);
    },

    [SET_LANGUAGES](state, data) {
        state.languages = data;
    },

    [SET_COUNTRY_LANGUAGES](state, data) {
        state.countryLanguages = data;
    },

    [SET_COUNTRY](state, data) {
        state.country = data;
        if (localStorage.getItem('cookie:accepted')) {
            if (process.env.NODE_ENV !== 'development') {
                vm.$cookies.set('country', state.country, '2y', null, `.${window.location.hostname}`, true);
            } else {
                vm.$cookies.set('country', state.country, '2y');
            }
        }
    },

    [LANGUAGE_LOADED](state, data) {
        state.languageLoaded = data;
    },

    [SET_RTL](state, data) {
        state.isRtl = data;
    },
};

const actions = {
    async [SET_LANGUAGE]({commit, getters}, key) {
        if(!key) {
            throw new TypeError('Can\'t find lang parameter');
        }

        if(getters.language !== key || !getters.hasTranslate(key)) {
            commit(LANGUAGE_LOADED, true);
            commit(SET_TRANSLATES, {key, data: 'in-progress'});
            const locale = `${getters.country}_${key}`;
            settingsService.getTranslates(locale).then(data => {
                if(getters.user && !getters.loggedInAs) {
                    userService.setUserLanguage(locale).then(res => {
                        commit('SET_USER_LANGUAGE', res);
                    });
                }
                commit(SET_TRANSLATES, {key, data});
            }).finally(() => {
                commit(LANGUAGE_LOADED, false);
            });

            commit(SET_LANGUAGE, key);
        }
    },

    async [GET_LANGUAGES]({commit}) {
        commit(START_CONTENT_LOADER);
        await settingsService.getLanguages().then(res => {
            const langs = {};
            for(let [key, lang] of Object.entries(res.langs)) {
                langs[key] = {key: key, text: lang};
            }
            commit(SET_LANGUAGES, langs);
            commit(SET_COUNTRY, res.country);
            if (res.countryLanguages) { // getLanguages is cached this can be removed later
                commit(SET_COUNTRY_LANGUAGES, res.countryLanguages);
            }
            commit(SET_LANGUAGE, res.lang); // needed for App.vue created().then
        }).finally(() => {
            commit(STOP_CONTENT_LOADER);
        });
    },
};

const getters = {
    languages: state => Object.keys(state.languages).map(key => state.languages[key]),
    language: state => state.language,
    country: state => state.country,
    countryLanguages: state => state.countryLanguages,
    isLanguageLoaded: state => state.languageLoaded,
    hasTranslate: state => key => !!state.translates[key],
    rtl: state => state.isRtl,
};

export default {
    state,
    mutations,
    actions,
    getters
};
