import store from '@/store';

const AdminUsers = () => import(/* webpackChunkName: "user-module" */ './index');
const Users = () => import(/* webpackChunkName: "user-module" */ './views/Users');
const Admins = () => import(/* webpackChunkName: "user-module" */ './views/Admins');
const Roles = () => import(/* webpackChunkName: "user-module" */ './views/Roles');

export default [
    {
        path: '/admin/users',
        components: { base: AdminUsers },
        children: [
            {
                path: '',
                name: 'admin.users',
                components: { 'admin-users': Users },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessUsersIndex) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: '/admin/users/admins',
                name: 'admin.users.admins',
                components: { 'admin-users': Admins },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessUsersAdmins) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: '/admin/users/roles',
                name: 'admin.users.roles',
                components: { 'admin-users': Roles },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessUsersRoles) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            }
        ]
    }
];
