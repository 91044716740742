const KindergartenApplications = () => import(/* webpackChunkName: "applications-module" */ '@views/application/kindergarten/Applications');
const Pending = () => import(/* webpackChunkName: "applications-module" */ '@views/application/kindergarten/views/Pending');
const Confirmed = () => import(/* webpackChunkName: "applications-module" */ '@views/application/kindergarten/views/Confirmed');
const Rejected = () => import(/* webpackChunkName: "applications-module" */ '@views/application/kindergarten/views/Rejected');
const Terminated = () => import(/* webpackChunkName: "applications-module" */ '@views/application/kindergarten/views/Terminated');
const ViewApplication = () => import(/* webpackChunkName: "applications-module" */ '@views/application/kindergarten/views/ViewApplication');

import store from '@/store';

export default [
   {
        path: '/kindergarten/applications',
        components: {base: KindergartenApplications},
        beforeEnter: (to, from, next) => {
            if (store.getters.canManageApplications) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: 'pending',
                name: 'applications.pending',
                components: {applications: Pending}
            },
            {
                path: 'accepted',
                name: 'applications.confirmed',
                components: {applications: Confirmed}
             },
            {
                path: 'rejected',
                name: 'applications.rejected',
                components: {applications: Rejected}
             },
            {
                path: 'terminated',
                name: 'applications.terminated',
                components: {applications: Terminated}
             },
            ]
    },
    {
        path: '/kindergarten/applications/:applicationId',
        name: 'applications.view-application',
        components: {base: ViewApplication}
    }
];
