import http from '@http';

class ArnoService {
    constructor() {
        if (ArnoService.instance) {
            return ArnoService.instance;
        }

        ArnoService.instance = this;
    }

    getClasses(kindergartenId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/arno/courses`, {params: options}).then(res => res.data);
    }

    getChildren(kindergartenId, options) {
        return http.get(`/api/kindergartens/${kindergartenId}/arno/children`, {params: options})
            .then(res => res.data);
    }

    importData(kindergartenId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/arno/import`, data).then(res => res.data);
    }

    getStatus(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/arno/status`).then(res => res.data);
    }
}
export default new ArnoService();