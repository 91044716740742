<template>
    <label class="custom-radio">
        <input type="checkbox" class="custom-control-input" :checked="value === realValue" :disabled="disabled" @change="change"/>
        <span
            class="d-flex custom-control-indicator align-items-center justify-content-center"
            :class="[{disabled: disabled}, color ? `e3-${color.slice(1)}` : '']">
            <span v-if="checkIcon" class="custom-radio-text e3-no-select" :class="{'e3-hide': value !== realValue}">
                {{ icon }}
            </span>
            <i v-else class="e3-svg-mdi text-white" 
               :class="[icon ? icon : 'mdi-check', {'d-none' : checkIcon || value !== realValue}]"
               :style="{'width': `${iconSize}px`, 'height': `${iconSize}px`, 'background-size': `${iconSize * 1.5}px auto !important`}"/>
        </span>
    </label>
</template>

<script>
export default {
    name: 'CustomRadio',
    props: ['value', 'realValue', 'disabled', 'icon', 'color'],
    data() {
        return {
            iconSize: 18
        };
    },
    computed: {
        checkIcon() {
            return this.icon && this.icon.length <= 2;
        },
    },
    methods: {
        change($event) {
            this.$emit('input', $event.target.checked ? this.realValue : null);
        }
    }
};
</script>

<style scoped lang="scss">
@import "@common/styles/variables/_variables.scss";

.e3-hide {
    display: none;
}

.custom-radio-text {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.custom-radio {
    margin-bottom: 0;
    position: relative;
    display: block;
    .custom-control-input {
        display: none;
    }
    > input[type="checkbox"] {
        & + .custom-control-indicator {
            display: block;
            position: relative;
            width: 28px;
            height: 28px;
            margin-left: auto;
            margin-right: auto;
            border: 2px solid #a9a9a9;
            background-color: white;
            border-radius: 50%;
            transition: all 0.1s ease-in;
        }
        &:disabled {
            + .custom-control-indicator {
                opacity: 0.15;
                cursor: default;
            }
        }
        &:checked {
            + .custom-control-indicator {
                background: $success;
                border-color: $success;
                transition: all 0.2s ease-in;
            }
        }
    }
}
</style>
