const Employees = () => import(/* webpackChunkName: "contact-module" */ '@views/contact/Employees');
const Guardians = () => import(/* webpackChunkName: "contact-module" */ '@views/contact/Guardians');
const Contacts = () => import(/* webpackChunkName: "contact-module" */ '@views/contact/Contacts');
const ContactOrganizations = () => import(/* webpackChunkName: "contact-module" */ '@views/contact/ContactOrganizations');

import store from '@/store';

export default [
    {
        path: '/contacts/kindergarten',
        name: 'contacts.kindergarten',
        components: { base: Contacts },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessContactsKindergarten) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/contacts/employees',
        name: 'contacts.employees',
        components: { base: Employees },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessContactsEmployees) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/contacts/parents',
        name: 'contacts.parents',
        components: { base: Guardians },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessContactsParents) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/contacts/organizations',
        name: 'contacts.organizations',
        components: { base: ContactOrganizations }
    },
];
