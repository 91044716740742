<template>
    <b-modal :id="id" :lazy="lazy" :static="isStatic" :hide-footer="hideFooter" :no-close-on-backdrop="noCloseOnBackdrop">
        <template slot="modal-header" slot-scope="{ close }">
            <div class="d-flex align-items-center w-100">
                <h5 class="m-0 mr-auto">{{ $t(title) }}</h5>
                <RoundIconButton type="bg-white" @click="close()">
                    <SvgIcon icon="mdi-close-circle" size="27" class="text-danger"/>
                </RoundIconButton>
            </div>
        </template>
        <slot name="content"/>
    </b-modal>
</template>

<script>
export default {
    name: 'EModal',
    props: {
        id: String,
        title: String,
        static: {
            type: Boolean,
            default: false
        },
        lazy: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        noCloseOnBackdrop: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isStatic() {
            return this.static;
        }
    }
};
</script>

<style scoped>

</style>
