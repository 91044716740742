var render = function render(){var _vm=this,_c=_vm._self._c;return _c('video-player',{staticClass:"vjs-custom-skin",attrs:{"options":{
        muted: false,
        language: _vm.language,
        playbackRates: [1.0, 1.5, 2.0],
        sources: [{
            type: 'video/mp4',
            src: _vm.src
        }]
    },"playsinline":true},on:{"ready":_vm.onFileLoad}})
}
var staticRenderFns = []

export { render, staticRenderFns }