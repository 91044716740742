<template>
    <div class="e3-head-icon-round">
        <div class="d-flex align-items-center px-3 py-2">
            <span class="font-weight-bold text-uppercase">{{ $t('Notifications') }}</span>
        </div>
        <div class="d-flex flex-column border-top">
            <div v-if="!loading">
                <div v-if="recentNotifications.length">
                    <div v-for="notification in recentNotifications" :key="notification.id">
                        <DiaryLikesNotification v-if="notification.type === 'diary_like'" :notification="notification" @click.native="$emit('close', id)"/>
                        <div v-else class="d-flex flex-row px-3 py-2">
                            <div class="profile-image-container">
                                <ProfileImage :imageUrl="notification.creator.profile_picture" :name="notification.creator.name" size="sm"/>
                            </div>
                            <div class="d-flex flex-column ml-2 w-100">
                                <div class="font-weight-bold">{{ notification.creator.name }}</div>
                                <div class="text-break">
                                    <span v-if="notification.type === 'application'">
                                        {{$t('New application')}}:
                                        <router-link class="font-weight-bold" :to="{name: 'applications.view-application', params: {applicationId: notification.data.id}}" @click.native="$emit('close', id)">
                                            {{notification.data.child_name}}
                                        </router-link>
                                    </span>
                                    <template v-else>
                                        {{truncate(text(notification), 30)}}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <empty-list-message v-else wrapperClass="p-2">
                    {{ $t("No notifications") }}
                </empty-list-message>
            </div>
            <e-loader v-else/>
        </div>
        <div class="bg-light px-3 py-2 border-top rounded-bottom">
            <router-link :to="{ name: 'general.notifications' }" @click.native="$emit('close', id)">
                {{ $t('Show all notifications') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import {formatDate, formatTime, truncate} from '@/filters';
import {mapGetters} from 'vuex';
import {shopTranslate} from '@/common/services/helper.service';
import DiaryLikesNotification from '@/common/layout/headericons/components/DiaryLikesNotification.vue';
export default {
    name: 'Notifications',
    props: {
        id: [String, Number]
    },
    components: {
        DiaryLikesNotification,
    },
    data() {
        return {
            loading: null
        };
    },
    computed: {
        ...mapGetters(['recentNotifications', 'activeKindergartenId', 'activeOrganizationId']),
    },
    methods: {
        truncate, // TODO: Vue3 remove
        text(notification) {
            if(notification.type === 'calendar') {
                let data = notification.data;
                if(data.type === 'status') {
                    return this.$t('Notification Child will be '+ (data.status ? 'present' : 'absent'), {child: data.child, date: formatDate(data.date)});
                }
                if(data.type === 'comment') {
                    return this.$t('Notification - Child comment', {child: data.child, date: formatDate(data.date), comment: data.comment});
                }
                if(data.type === 'times') {
                    return this.$t('Notification - Child times', {child: data.child, date: formatDate(data.date), time: formatTime(data.start_time) +' - '+ formatTime(data.end_time)});
                }
                if(data.type === 'multi') {
                    return this.$t('Notification - Child will be ' + (data.status ? 'present' : 'absent')+' for a longer period', {child: data.child, start_date: formatDate(data.date), end_date: formatDate(data.end_date), comment: data.comment});
                }
            }
            else if(notification.type === 'wishlist') {
                let data = notification.data;
                let name = shopTranslate(data.name);
                return this.$t('Notification - New wishlist item', {item: name});
            }
            else if(notification.type === 'application') {
                let data = notification.data;
                return this.$t('Notification - New application', {child_name: data.child_name});
            }
        },
    },
    created() {
        if(this.activeKindergartenId) {
            this.loading = true;
            this.$store.dispatch('GET_RECENT_NOTIFICATIONS').finally(() => {
                this.loading = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@common/styles/components/_notifications.scss";
</style>
