import http from '@http';

class SettingsService {
    getLanguages() {
        return http.get('/api/lang/languages').then(res => res.data);
    }

    getTranslates(key) {
        return http.get(`/api/lang/${key}.json`).then(msgs => {
            if (msgs && msgs.data) {
                return msgs.data;
            }

            return {};
        });
    }

    getDefaultTranslations() {
        return http.get('api/lang/default').then(msgs => {
            if (msgs && msgs.data) {
                return msgs.data;
            }
        });
    }

    getAuthMethods() {
        return http.get('/api/auth/providers').then(res => res.data);
    }
}

export default new SettingsService();
