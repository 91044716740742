<template>
    <div class="custom-control custom-radio" :class="{ 'custom-control-inline': inline, disabled: disabled}">
        <input :id="id"
               v-model="valModel"
               class="custom-control-input"
               type="radio"
               :name="name"
               :value="val"
               :disabled="disabled"/>
        <label class="custom-control-label" :for="id">
            <slot name="label-content">
                {{ $t(label) }}
                <template v-if="optional">
                    - <small>{{ $t('Optional') }}</small>
                </template>
            </slot>
        </label>
    </div>
</template>

<script>
export default {
    name: 'ERadio',
    props: {
        value: [Boolean, String, Number],
        val: [Boolean, String, Number],
        id: [String, Number],
        name: String,
        label: String,
        disabled: Boolean,
        optional: Boolean,
        inline: Boolean
    },
    computed: {
        valModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@common/styles/variables/_variables.scss";
.custom-control {
    &.is-invalid {
        .custom-control-label::before { 
            border-color: $danger;
        }
    }
    .custom-control-label::before {
        border-color: #cdcdcd;
    }
}

.disabled {
    .custom-control-label {
        cursor: default;
        opacity: 0.25;
    }
}
</style>
