import GuardianService from '@services/guardian.service';
import ChildService from '@services/child.service';
import DiaryService from '@services/diary.service';
import MapService from '@services/map.service';

import Vue from 'vue';
import {
    ADD_CHILD_ABSENCE,
    ADD_CHILD_GUARDIAN,
    ADD_CHILD_MAP,
    ADD_GUARDIAN,
    ARCHIVE_CHILD,
    DELETE_GUARDIAN,
    DETACH_GUARDIAN,
    GET_CHILD,
    GET_CHILD_ABSENCES,
    GET_CHILD_COURSES_WITH_FEEDBACK,
    GET_CHILD_COURSES,
    GET_CHILD_GUARDIANS,
    GET_CHILD_MAPS,
    GET_CHILD_PROFILE,
    GET_GUARDIAN_DIARY,
    REMOVE_CHILD_COURSE_DATES,
    RESTORE_CHILD,
    SAVE_CHILD_PROFILE,
    SET_CHILD,
    SET_CHILD_ABSENCES,
    SET_CHILD_COURSES_WITH_FEEDBACK,
    SET_CHILD_COURSES,
    SET_CHILD_MAPS,
    SET_GUARDIAN_DIARY,
    SET_GUARDIANS,
    STORE_CHILD_ABSENCE,
    STORE_CHILD_MAP,
    STORE_GUARDIAN,
    UPDATE_CHILD_MAP,
    UPDATE_CHILD_PROFILE,
    UPDATE_GUARDIAN,
    GET_CHILD_APPROVALS,
    SET_CHILD_APPROVALS,
    UPDATE_CHILD_APPROVAL_ANSWERS,
    SET_CHILD_APPROVAL_ANSWERS,
    SET_CHILD_DEV_EVALUATIONS_IN_PROFILE,
    SET_GUARDIAN_DIARY_LOAD_MORE,
    SET_DOOR_CARD_ENABLED,
    SET_CHILD_PIC,
    GET_CHILD_FILES,
    SET_CHILD_FILES,
    DELETE_CHILD_FILE,
    REMOVE_CHILD_FILE,
    UPDATE_CHILD_FILE,
    SAVE_CHILD_FILE,
    UPLOAD_CHILD_FILE,
    ADD_CHILD_FILE,
    SET_CHILD_PROFILE_SETTINGS,
    UPDATE_CHILD_COURSE_DATES,
    SET_CHILD_COURSE_DATES,
    ADD_CHILD_COURSE_DATES,
} from './actions';

import FileService from '@/common/services/file.service';

const state = {
    ...planState,
    child: {
        evaluations: []
    },
    guardians: [],
    maps: [],
    plans: [],

    courses: [],
    child_courses_with_feedback: [],
    absences: [],
    approvals: [],

    //Guardian diary entries
    diary: [],
    hasMore: false,

    //Absence calendar
    absenceCalendarData: [],
    absenceChildData: {},
    absenceLockDate: null,
    absenceCalendarHolidays: [],
    //Inception
    doorCardEnabled: false,

    //Child files
    documents: [],
    media: [],

    childProfileSettings: {},
};
import {planState, planActions, planMutations, planGetters} from '@/store/modules/child/plans';
const mutations = {
    ...planMutations,
    [SET_GUARDIANS](state, data) {
        state.guardians = data;
    },
    [UPDATE_GUARDIAN](state, guardian) {
        let index = state.guardians.findIndex(g => g.id === guardian.id);
        if(index !== -1) {
            Vue.set(state.guardians, index, guardian);
        }
        if(guardian.pivot.main_parent) {
            state.guardians.map(g => {
                if(g.id !== guardian.id) {
                    return g.pivot.main_parent = 0;
                }
            });
        }
    },
    [ADD_GUARDIAN](state, guardian) {
        state.guardians.push(guardian);
    },
    [SET_GUARDIAN_DIARY](state, days) {
        // state.diary = diary;
        days.forEach(day => {
            state.diary.push(day);
        });
    },
    [SET_GUARDIAN_DIARY_LOAD_MORE](state, hasMore) {
      state.hasMore = hasMore;
    },
    [SET_CHILD](state, child) {
        state.child = child;
    },
    [SET_CHILD_DEV_EVALUATIONS_IN_PROFILE](state, evals) {
        Vue.set(state.child, 'evaluations', evals);
    },
    [SET_CHILD_MAPS](state, maps) {
        state.maps = maps;
    },
    [ADD_CHILD_MAP](state, map) {
        state.maps.push(map);
    },
    [UPDATE_CHILD_PROFILE](state, child) {
        Vue.set(state, 'child', child);
    },
    [UPDATE_CHILD_MAP](state, map) {
        let index = state.maps.findIndex(m => m.id === map.id);
        if(index !== -1) {
            Vue.set(state.maps, index, map);
        }
    },
    [SET_CHILD_COURSES](state, courses) {
        state.courses = courses;
    },
    [SET_CHILD_COURSES_WITH_FEEDBACK](state, courses) {
        state.child_courses_with_feedback = courses;
    },
    [SET_CHILD_ABSENCES](state, absences) {
        state.absences = absences;
    },
    [ADD_CHILD_ABSENCE](state, absence) {
        state.absences.unshift(absence);
    },
    [SET_CHILD_APPROVALS](state, approvals) {
        state.approvals = approvals;
    },
    [SET_CHILD_APPROVAL_ANSWERS](state, data) {
        data.response.map((answer) => {
            let index = state.approvals.findIndex(approval => approval.id === answer.approval_id);
            state.approvals[index].latest_data = answer;
        });
    },
    [SET_DOOR_CARD_ENABLED](state, data) {
        state.doorCardEnabled = data;
    },
    [SET_CHILD_PIC](state, pictureUrl) {
        if (pictureUrl) {
            try {
                // If URL object is successfully created, the data is a valid URL and can be accessed directly.
                new URL(pictureUrl);
                state.child.profile_picture = pictureUrl;
            } catch (_) {
                let child = state.child;
                FileService.getChildProfilePicture(child.kindergarten_id, child.id, pictureUrl).then(res => {
                    child.profile_picture = window.URL.createObjectURL(res);
                });
            }
        } else {
            state.child.profile_picture = '';
        }
    },
    [SET_CHILD_FILES](state, {data}) {
        state.documents = data.filter(f => f.file_type === 'document');
        state.media = data.filter(f => f.file_type === 'media');
    },
    [SAVE_CHILD_FILE](state, data) {
        // Currently only media files' changes implemented
        let index = state.media.findIndex(f => f.filename === data.filename);
        if (index !== -1) {
            Vue.set(state.media, index, data);
        }
    },
    [ADD_CHILD_FILE](state, file) {
        if (file.file_type === 'document') {
            state.documents.unshift(file);
        } else if (file.file_type === 'media') {
            state.media.unshift(file);
        }
    },
    [REMOVE_CHILD_FILE](state, fileName) {
        let index = state.media.findIndex(f => f.filename === fileName);
        if (index !== -1) {
            state.media.splice(index, 1);
        } else {
            index = state.documents.findIndex(f => f.filename === fileName);
            if (index !== -1) {
                state.documents.splice(index, 1);
            }
        }
    },
    [SET_CHILD_PROFILE_SETTINGS](state, settings) {
        state.childProfileSettings = settings;
    },
    [SET_CHILD_COURSE_DATES](state, data) {
        state.child.course_dates = data;
    },

};

const actions = {
    ...planActions,
    [STORE_GUARDIAN]({ commit, getters }, form) {
        return GuardianService.store(getters.activeKindergartenId, form.child_id, form).then(res => {
            commit(SET_GUARDIANS, res);
        });
    },
    [ADD_CHILD_GUARDIAN]({commit, getters}, {childId, guardianId, form}) {
        return ChildService.addGuardian(getters.activeKindergartenId, childId, guardianId, form).then(res => {
            commit(SET_GUARDIANS, res);
        });
    },
    [UPDATE_GUARDIAN]({commit, getters}, {childId, guardianId, form}) {
        return GuardianService.update(getters.activeKindergartenId, childId, guardianId, form).then(res => {
            commit(UPDATE_GUARDIAN, res);
        });
    },
    [DETACH_GUARDIAN]({commit, getters}, {childId, guardianId}) {
      return GuardianService.detach(getters.activeKindergartenId, childId, guardianId).then(res => {
            commit(UPDATE_GUARDIAN, res);
      });
    },
    [DELETE_GUARDIAN]({commit, getters}, {childId, guardianId}) {
        return GuardianService.delete(getters.activeKindergartenId, childId, guardianId).then(res => {
            commit(SET_GUARDIANS, res);
        });
    },
    [GET_CHILD_GUARDIANS]({ commit, getters }, child_id) {
        commit('START_CONTENT_LOADER');
        ChildService.guardians(getters.activeKindergartenId, child_id).then(res => {
            commit(SET_DOOR_CARD_ENABLED, res.door_card_enabled);
            commit(SET_GUARDIANS, res.guardians);
            commit('STOP_CONTENT_LOADER');
        });
    },
    [GET_GUARDIAN_DIARY]({commit, getters, state}, {childId, queryObj}) {
        if(queryObj.page === 1) {
            state.diary = [];
        }
        return DiaryService.getGuardianFeed(getters.activeKindergartenId, childId, queryObj).then(res => {
           commit(SET_GUARDIAN_DIARY, res.data);
           commit(SET_GUARDIAN_DIARY_LOAD_MORE, res.next_date);
        });
    },
    [GET_CHILD]({commit, getters}, childId) {
        return ChildService.getChild(getters.activeKindergartenId, childId).then(res => {
            commit(SET_CHILD_PROFILE_SETTINGS, res.settings);
            delete res.settings;
            commit(SET_CHILD, res);
        });
    },
    [GET_CHILD_PROFILE]({commit, getters}, childId) {
        return ChildService.getChildProfile(getters.activeKindergartenId, childId).then(res => {
            res.dietary_options = res.dietary_options.map(o => o.dietary_option_id);
            commit(SET_CHILD_COURSES, res.active_courses);
            delete(res.active_courses);
            commit(SET_CHILD, res);
            if(res.profile_picture) {
                commit(SET_CHILD_PIC, res.profile_picture);
            }
        });
    },
    [GET_CHILD_MAPS]({commit, getters}, queryObj) {
        commit(SET_CHILD_MAPS, []);
        return ChildService.maps(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_CHILD_MAPS, res);
        });
    },
    [STORE_CHILD_MAP]({commit, getters}, form) {
        return MapService.add(getters.activeKindergartenId, form).then(res => {
           commit(ADD_CHILD_MAP, res);
           return res;
        });
    },
    [UPDATE_CHILD_MAP]({commit, getters}, form) {
        return MapService.renameMap(getters.activeKindergartenId, form.childId, form.id, form).then(res => {
           commit(UPDATE_CHILD_MAP, res);
           return res;
        });
    },
    [SAVE_CHILD_PROFILE]({commit, getters}, {childId, form}) {
        return ChildService.updateChildProfile(getters.activeKindergartenId, childId, form).then(res => {
            if(!res.status) {
                commit(SET_CHILD_COURSES, res.active_courses);
                delete(res.active_courses);
                commit(UPDATE_CHILD_PROFILE, res);
            }
            return res;
        });
    },
    [GET_CHILD_COURSES]({commit, getters}, childId) {
        return ChildService.courses(getters.activeKindergartenId, childId).then(res => {
            commit(SET_CHILD_COURSES, res);
            return res[0] ?? {};
        });
    },
    [GET_CHILD_COURSES_WITH_FEEDBACK]({commit, getters}, childId) {
        return ChildService.childCoursesWithFeedback(getters.activeKindergartenId, childId).then(res => {
            commit(SET_CHILD_COURSES_WITH_FEEDBACK, res);
        });
    },
    [ADD_CHILD_COURSE_DATES]({commit, getters}, data) {
        return ChildService.addChildDates(getters.activeKindergartenId, data.childId, data).then(res => {
            commit(SET_CHILD_COURSE_DATES, res.course_dates);
            return res;
        });
    },
    [UPDATE_CHILD_COURSE_DATES]({commit, getters}, data) {
        return ChildService.updateChildDates(getters.activeKindergartenId, data.child_id, data).then(res => {
            commit(SET_CHILD_COURSE_DATES, res.course_dates);
            return res;
        });
    },
    [REMOVE_CHILD_COURSE_DATES]({commit, getters}, {childId, courseChildId}) {
        return ChildService.removeChildDates(getters.activeKindergartenId, childId, courseChildId).then(res => {
            commit(SET_CHILD_COURSE_DATES, res);
        });
    },
    [GET_CHILD_ABSENCES]({commit, getters}, queryObj) {
        return ChildService.getAbsences(getters.activeKindergartenId, queryObj).then(res => {
            commit(SET_CHILD_ABSENCES, res);
        });
    },
    [STORE_CHILD_ABSENCE]({commit}, {childId, kindergartenId, form, store}) {
        return ChildService.addAbsence(kindergartenId, childId, form).then(res => {
            //store only if same child view is open
            if(store) {
                commit(ADD_CHILD_ABSENCE, res);
            }
        });
    },
    [ARCHIVE_CHILD]({commit, getters}, {childId, leaveDate}) {
        return ChildService.archiveChild(getters.activeKindergartenId, childId, leaveDate).then(res => {
            if(res.status > 1) {
                commit('REMOVE_CHILD', childId);
            }
        });
    },
    [RESTORE_CHILD]({commit, getters}, {childId, form}) {
        return ChildService.restoreChild(getters.activeKindergartenId, childId, form).then(() => {
            commit('REMOVE_CHILD', childId);
        });
    },
    [GET_CHILD_APPROVALS]({commit, getters}, childId) {
        return ChildService.getChildApprovals(getters.activeKindergartenId, childId).then((res) => {
            commit(SET_CHILD_APPROVALS, res);
        });
    },
    [UPDATE_CHILD_APPROVAL_ANSWERS]({commit, getters}, data) {
        return ChildService.updateApprovalAnswers(getters.activeKindergartenId, data).then((res) => {
            commit(SET_CHILD_APPROVAL_ANSWERS, {
                response: res,
            });
        });
    },
    [UPLOAD_CHILD_FILE]({commit, getters}, {childId, formData}) {
        return ChildService.uploadFile(getters.activeKindergartenId, childId, formData).then(res => {
            if (res.file) {
                commit(ADD_CHILD_FILE, res.file);
            }
        });
    },
    [GET_CHILD_FILES]({commit, getters}, {childId}) {
        commit(SET_CHILD_FILES, {data: []});
        return ChildService.getFiles(getters.activeKindergartenId, childId).then(res => {
            commit(SET_CHILD_FILES, {data: res.data});
            return res;
        });
    },
    [UPDATE_CHILD_FILE]({commit, getters}, form) {
        return ChildService.updateChildFile(getters.activeKindergartenId, form.childId, form.filename, form).then(res => {
            commit('SAVE_CHILD_FILE', res);
        });
    },
    [DELETE_CHILD_FILE]({commit, getters}, {fileName, childId}) {
        return ChildService.deleteChildFile(getters.activeKindergartenId, childId, fileName).then(() => {
            commit('REMOVE_CHILD_FILE', fileName);
        });
    },
};

const getters = {
    ...planGetters,
    childGuardians: state => state.guardians,
    guardianDiary: state => state.diary,
    guardianDiaryHasMore: state => state.hasMore,
    childProfile: state => state.child,
    childProfileSettings: state => state.childProfileSettings,
    childMaps: state => state.maps,
    childCourses: state => state.courses,
    childCoursesWithFeedback: state => state.child_courses_with_feedback,
    childAbsences: state => state.absences,
    childApprovals: state => state.approvals,
    doorCardEnabled: state => state.doorCardEnabled,
    childDocuments: state => state.documents,
    childMedia: state => state.media,
};

export default {
    state,
    mutations,
    actions,
    getters
};
