const Events = () => import(/* webpackChunkName: "events-module" */ '@views/event/views/Events');
const MyEvents = () => import(/* webpackChunkName: "events-module" */'@views/event/views/MyEvents');
const OrgEvents = () => import(/* webpackChunkName: "events-module" */ '@views/event/views/OrgEvents');
const EventsListView = () => import(/* webpackChunkName: "events-module" */ '@views/event/views/EventsListView');

import store from '@/store';

export default [
    {
        path: '/events',
        name: 'events.calendar',
        components: { base: Events },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessEventsIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/events/my',
        name: 'events.my',
        components: { base: MyEvents }
    },
    {
        path: '/org/events',
        name: 'events.organization.calendar',
        components: { base: OrgEvents },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessOrgEventsIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/events-list',
        name: 'events.list',
        components: { base: EventsListView },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessEventsIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    //organization.events.calendar
    // {
    //     path: '/events/list',
    //     name: 'events.list',
    //     components: { base: Events }
    // },
    // {
    //     path: '/events/year',
    //     name: 'events.yearly',
    //     components: { base: Events }
    // },
];
