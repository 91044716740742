import http from '@http';
import { download } from '@services/helper.service';

class FileService {
    constructor() {
        if (FileService.instance) {
            return FileService.instance;
        }
        FileService.instance = this;
    }

    getUserFile(file) {
        return http
            .download(`/api/common/files/users/${file.filename}`)
            .then(res => download(res.data, file));
    }

    getUserProfilePicture(filename) {
        return http
            .download(`/api/common/files/profile_pictures/${filename}`)
            .then(res => res.data);
    }

    getFile(file, type) {
        return http
            .download(`/api/common/files/${type}/${file.filename}`)
            .then(res => download(res.data, file));
    }

    uploadUserPicture(formData) {
        return http
            .post('/api/common/settings/upload', formData)
            .then(res => res.data);
    }

    deleteUserPicture() {
        return http.delete('/api/common/settings/delete').then(res => res.data);
    }

    uploadChildProfilePicture(kindergartenId, childId, formData) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/profile-picture`, formData)
            .then(res => res.data);
    }

    getChildProfilePicture(kindergartenId, childId, filename) {
        return http
            .download(`/api/kindergartens/${kindergartenId}/children/${childId}/profile-picture/${filename}`)
            .then(res => res.data);
    }

    deleteChildProfilePicture(kindergartenId, childId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/children/${childId}/profile-picture`)
            .then(res => res.data);
    }

    uploadKindergartenLogo(kindergartenId, formData) {
        return http.post(`/api/kindergartens/${kindergartenId}/logo`, formData)
            .then(res => res.data);
    }

    deleteKindergartenLogo(kindergartenId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/logo`)
            .then(res => res.data);
    }

    uploadSupplierLogo(supplierId, formData) {
        return http.post(`/api/suppliers/${supplierId}/logo`, formData)
            .then(res => res.data);
    }

    deleteSupplierLogo(supplierId) {
        return http.delete(`/api/suppliers/${supplierId}/logo`)
            .then(res => res.data);
    }
}

export default new FileService();
