import http from '@services/http.service';

class GuardianService {
    constructor() {
        if (GuardianService.instance) return GuardianService.instance;
    }

    updateSettings(kindergartenId, guardianId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/guardians/${guardianId}/settings`, data)
            .then(res => res.data);
    }

    store(kindergartenId, childId, data) {
        return http.post(`/api/kindergartens/${kindergartenId}/children/${childId}/guardians`, data)
            .then(res => res.data);
    }

    update(kindergartenId, childId, guardianId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/children/${childId}/guardians/${guardianId}`, data)
            .then(res => res.data);
    }

    detach(kindergartenId, childId, guardianId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/children/${childId}/guardians/${guardianId}/detach`)
            .then(res => res.data);
    }

    delete(kindergartenId, childId, guardianId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/children/${childId}/guardians/${guardianId}/delete`)
            .then(res => res.data);
    }
}

export default new GuardianService();
