import http from '@http';

class ApprovalService {
  constructor() {
    if (ApprovalService.instance) {
      return ApprovalService.instance;
    }

    ApprovalService.instance = this;
  }

  getKindergartenApprovals(kindergartenId) {
    return http
      .get(`/api/kindergartens/${kindergartenId}/approvals`)
      .then(res => res.data);
  }

  updateKindergartenApprovalStatus(kindergartenId, data) {
    return http
      .put(`/api/kindergartens/${kindergartenId}/approvals/${data.approvalId}`, {status: data.status})
      .then(res => res.data);
  }

  deleteKindergartenApproval(kindergartenId, approvalId) {
    return http
      .delete(`/api/kindergartens/${kindergartenId}/approvals/${approvalId}`)
      .then(res => res.data);
  }

  createKindergartenApproval(kindergartenId, question) {
    return http
      .post(`/api/kindergartens/${kindergartenId}/approvals`, {question})
      .then(res => res.data);
  }
}

export default new ApprovalService();
