import store from '@/store';

const Questionnaires = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/questionnaire/view/index');
const EditQuestionnaire = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/questionnaire/manage/components/EditQuestionnaire');
const FillQuestionnaire = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/questionnaire/view/components/FillQuestionnaire');
const QuestionnairesList = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/questionnaire/view/views/QuestionnaireList');
const QuestionnairesManage = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/questionnaire/manage/index');
const QuestionnairesManageList = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/questionnaire/manage/views/QuestionnaireManageList');
const Polls = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/poll/view/index');
const PollsList = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/poll/view/views/PollsList');
const PollsManage = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/poll/manage/index/');
const PollsManageList = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/poll/manage/views/PollsManageList');
const EditPoll = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/poll/manage/components/EditPoll');
const QuestionnaireResults = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/components/QuestionnaireResults');
const QuestionnaireAnswers = () => import(/* webpackChunkName: "kindergarten-module" */ '@views/survey/components/QuestionnaireAnswers');

export default [
    {
        path: 'questionnaires',
        components: { base: Questionnaires },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessQuestionnaires) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: '',
                name: 'surveys.questionnaires.ongoing',
                components: { questionnaires: QuestionnairesList },
                meta: 'ongoing',
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessQuestionnaires) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'answered',
                name: 'surveys.questionnaires.answered',
                components: { questionnaires: QuestionnairesList },
                meta: 'answered',
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessQuestionnaires) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'finished',
                name: 'surveys.questionnaires.finished',
                meta: 'finished',
                components: { questionnaires: QuestionnairesList },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessQuestionnaires) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
        ]
    },
    {
        path: 'questionnaires-manage',
        components: { base: QuestionnairesManage },
        beforeEnter: (to, from, next) => {
            if (store.getters.canManageQuestionnaires) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: '',
                name: 'surveys.questionnaires-manage.ongoing',
                components: { manage_questionnaires: QuestionnairesManageList },
                meta: 'ongoing',
                beforeEnter: (to, from, next) => {
                    if (store.getters.canManageQuestionnaires) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'drafts',
                name: 'surveys.questionnaires-manage.drafts',
                meta: 'draft',
                components: { manage_questionnaires: QuestionnairesManageList },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canManageQuestionnaires) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'upcoming',
                name: 'surveys.questionnaires-manage.upcoming',
                meta: 'upcoming',
                components: { manage_questionnaires: QuestionnairesManageList },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canManageQuestionnaires) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'finished',
                name: 'surveys.questionnaires-manage.finished',
                components: { manage_questionnaires: QuestionnairesManageList },
                meta: 'finished',
                beforeEnter: (to, from, next) => {
                    if (store.getters.canManageQuestionnaires) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },

        ]
    },
    {
        path: '/questionnaires-manage/:survey_id',
        name: 'surveys.questionnaire.edit',
        components: { base: EditQuestionnaire },
        beforeEnter: (to, from, next) => {
            if (store.getters.canManageQuestionnaires) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/questionnaires/:survey_id/answers',
        name: 'surveys.questionnaire.answers',
        meta: 'answers',
        components: { base: QuestionnaireAnswers },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessQuestionnaires) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/questionnaires-manage/:survey_id/answers',
        name: 'surveys.questionnaire.results',
        meta: 'results',
        components: { base: QuestionnaireResults },
        beforeEnter: (to, from, next) => {
            if (store.getters.canManageQuestionnaires) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/questionnaires/:survey_id',
        name: 'surveys.questionnaire.fill',
        components: { base: FillQuestionnaire },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessQuestionnaires) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: 'polls',
        components: { base: Polls },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessPolls) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: '',
                name: 'surveys.polls.ongoing',
                components: { polls: PollsList },
                meta: 'ongoing',
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessPolls) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'answered',
                name: 'surveys.polls.answered',
                components: { polls: PollsList },
                meta: 'answered',
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessPolls) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'finished',
                name: 'surveys.polls.finished',
                meta: 'finished',
                components: { polls: PollsList },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessPolls) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
        ]
    },
    {
        path: 'polls-manage',
        components: { base: PollsManage },
        beforeEnter: (to, from, next) => {
            if (store.getters.canManagePolls) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        },
        children: [
            {
                path: '',
                name: 'surveys.polls-manage.ongoing',
                components: { manage_polls: PollsManageList },
                meta: 'ongoing',
                beforeEnter: (to, from, next) => {
                    if (store.getters.canManageQuestionnaires) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'drafts',
                name: 'surveys.polls-manage.drafts',
                meta: 'draft',
                components: { manage_polls: PollsManageList },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canManagePolls) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'upcoming',
                name: 'surveys.polls-manage.upcoming',
                meta: 'upcoming',
                components: { manage_polls: PollsManageList },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canManagePolls) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'finished',
                name: 'surveys.polls-manage.finished',
                components: { manage_polls: PollsManageList },
                meta: 'finished',
                beforeEnter: (to, from, next) => {
                    if (store.getters.canManagePolls) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },

        ]
    },
    {
        path: '/polls-manage/:survey_id',
        name: 'surveys.poll.edit',
        components: { base: EditPoll },
        beforeEnter: (to, from, next) => {
            if (store.getters.canManagePolls) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    }
];
