<template>
    <img
        v-if="imageUrl"
        class="rounded-circle d-inline-flex profile-image"
        :class="[{'square': square}]"
        :style="style"
        :src="imageUrl"/>
    <UsernameAvatar v-else :size="size" :displayTitle="displayTitle" :square="square">
        {{name}}
    </UsernameAvatar>
</template>

<script>
import UsernameAvatar from '@components/avatar/UsernameAvatar';

export default {
    name: 'ProfileImage',
    props: {
        imageUrl: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: 'E'
        },
        size: {
            type: String,
            default: ''
        },
        displayTitle: {
            type: Boolean,
            default: false
        },
        square: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            iconSizes: {
                xxs: 17,
                xs: 23,
                sm: 36,
                lg: 104,
                xl: 160
            }
        };
    },
    components: {
        UsernameAvatar
    },
    computed: {
        style() {
            const iconSize = this.iconSizes[this.size] || 48;

            return {
                width: `${iconSize}px`,
                height: `${iconSize}px`,
            };
        }
    }
};
</script>

<style scoped>
    .profile-image {
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    }
    .profile-image.square {
        border-radius: .33rem !important;
    }
</style>
