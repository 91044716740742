import http from '@http';

class IntegrationService {
    constructor() {
        if (IntegrationService.instance) {
            return IntegrationService.instance;
        }
        IntegrationService.instance = this;
    }

    getIntegrations() {
        return http.get('/api/admin/integrations').then(res => res.data);
    }

    addIntegration(data) {
        return http.post('/api/admin/integrations', data).then(res => res.data);
    }

    getIntegration(id) {
        return http.get(`/api/admin/integrations/${id}`).then(res => res.data);
    }

    updateIntegrationSettings(id, settings) {
        return http.put(`/api/admin/integrations/${id}`, settings).then(res => res.data);
    }

    getMapIntegrations(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/integrations/map`).then(res => res.data);
    }

    addIntegrationClient(integrationId, data) {
        return http.post(`/api/admin/integrations/${integrationId}/clients`, data).then(res => res.data);
    }

    updateIntegrationClient(clientId, data) {
        return http.put(`/api/admin/integrations/client/${clientId}`, data).then(res => res.data);
    }

    getIntegrationClients(integrationId, queryObj) {
        return http.get(`/api/admin/integrations/${integrationId}/clients`, {params: queryObj}).then(res => res.data);
    }

    deleteIntegrationClient(integrationId) {
        return http.delete(`/api/admin/integrations/client/${integrationId}`).then(res => res.data);
    }

    getKindergartenIntegrations(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/integrations`).then(res => res.data);
    }
}

export default new IntegrationService();