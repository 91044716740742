import {
    RESET_OFFICIAL_STATE,
    GET_OFFICIAL_KINDERGARTENS,
    SET_OFFICIAL_KINDERGARTEN,
    SET_OFFICIAL_KINDERGARTENS,
    UPDATE_OFFICIAL_SETTING
} from './actions';
import OfficialService from '@services/official.service';
import {UPDATE_OFFICIAL_SETTINGS} from '@store/user/actions';

const getDefaultState = () => {
    return {
        kindergartens: [],
        selectedKindergarten: {}
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_OFFICIAL_STATE](state) {
        Object.assign(state, getDefaultState());
        localStorage.removeItem('official_kindergarten_id');
    },
    [SET_OFFICIAL_KINDERGARTENS](state, kindergartens) {
        state.kindergartens = kindergartens;
    },
    [SET_OFFICIAL_KINDERGARTEN](state, kindergarten) {
        state.selectedKindergarten = kindergarten;
        localStorage.setItem('official_kindergarten_id', kindergarten.id);
    }
};

const actions = {
    [GET_OFFICIAL_KINDERGARTENS]({commit, getters}) {
        return OfficialService.getKindergartens(getters.activeOrganizationId, getters.currentOfficial.id).then(res => {
            commit(SET_OFFICIAL_KINDERGARTENS, res);
            const kindergartenId = localStorage.getItem('official_kindergarten_id');
            const selectedKindergarten = kindergartenId ? getters.officialKindergartens.find(k => k.id == kindergartenId): res[0];
            commit(SET_OFFICIAL_KINDERGARTEN, selectedKindergarten);
        });
    },
    [UPDATE_OFFICIAL_SETTING]({commit, getters}, data) {
        OfficialService.updateSettings(getters.activeOrganizationId, data.official_id, data.form).then(res => {
            commit(UPDATE_OFFICIAL_SETTINGS, {official_id: data.official_id, settings: res});
        });
    }
};

const getters = {
    officialKindergartens: state => state.kindergartens,
    selectedKindergarten: state => state.selectedKindergarten
};

export default {
    state,
    mutations,
    actions,
    getters
};