<template>
    <div>
        <div>
            <span class="font-weight-bold">{{ notification.creator.fname }} {{ notification.creator.lname }}</span>
            <span v-if="showKindergarten"> ({{ notification.kindergarten.name}}) </span>
            <span v-if="type === 'status'">
                {{$t('Notification Child will be '+ (data.status ? 'present' : 'absent'), {child: data.child, date: formatDate(data.date)})}}
            </span>
            <span v-if="type === 'comment'">
                {{$t('Notification - Child comment', {child: data.child, date: formatDate(data.date), comment: data.comment})}}
            </span>
            <span v-if="type === 'times'">
                {{$t('Notification - Child times', {child: data.child, date: formatDate(data.date), time: formatTimeString(data.start_time) +' - ' + formatTimeString(data.end_time)})}}
            </span>
            <span v-if="type === 'multi'">
                <template v-if="data.date === data.end_date">
                    {{$t('Notification Child will be '+ (data.status ? 'present' : 'absent'), {child: data.child, date: formatDate(data.date)})}}
                </template>
                <template v-else>
                    {{$t('Notification - Child will be ' + (data.status ? 'present' : 'absent')+' for a longer period',
                         {child: data.child, start_date: formatDate(data.date), end_date: formatDate(data.end_date), comment: data.comment})}}
                </template>

            </span>
        </div>
        <div class="text-black-50">
            {{ formatDateTime(notification.created_at) }}
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {formatDateTime, formatDate, formatTime} from '@/filters';

export default {
    name: 'ChildCalendarNotification',
    props: {
        notification: Object
    },
    computed: {
        ...mapGetters(['userKindergartens']),
        data() {
            return this.notification.data;
        },
        type() {
            return this.data?.type;
        },
        showKindergarten() {
            return this.notification.kindergarten && this.userKindergartens.length > 1;
        }
    },
    methods: {
        formatDateTime, // TODO: Vue3 remove
        formatDate, // TODO: Vue3 remove
        formatTime, // TODO: Vue3 remove
        formatTimeString(time) {
            if(!time) {
                return '';
            }
            return this.formatTime(time);
        }
    }

};
</script>

<style scoped>

</style>
