<template>
    <div class="mb-2">
        <FileUpload 
            isModal
            :title="$t('Add documents')"
            has-submit
            modalName="folder-modal"
            fileUpload
            @single-file="singleFileUpload"/>
        <div v-if="files.length" class="d-flex flex-column mt-2">
            <div v-for="(file, index) in files" :key="index" class="d-flex align-items-center">
                <DisplayFile :fileTooLarge="fileSizeWithinLimit(file)" :displayFile="file" :index="index" class="py-1" @reupload="reUpload(file)"/>
                <!--                <span v-if="!isModal" class="d-flex e3-remove e3-pointer bg-danger rounded-circle ml-auto" :title="$t('Remove')" @click="removeFile(index)">-->
                <!--                    <i class="mdi mdi-close mdi-18px text-white"/>-->
                <!--                </span>-->
            </div>
        </div>
    </div>
</template>

<script>
import FileUpload from '@components/upload/FileUpload.vue';
import DisplayFile from '@components/DisplayFile.vue';
import {mapGetters} from 'vuex';
import {eventBus} from '@/main';

export default {
    name: 'FolderUpload',
    props: ['type'],
    data() {
        return {
            files: [],
            fileSizeMax: 200000000
        };
    },
    computed: {
        ...mapGetters([
            'currentEmployee',
            'currentGuardian',
            'userTargetGroups',
            'activeKindergartenId',
            'userTargetIdentities',
            'folder'
        ]),
    },
    methods: {
        singleFileUpload(file) {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('type', this.type);
            formData.append('uploaded_at', this.$moment().utc().format('YYYY-MM-DD HH:mm:ss.SSS'));
            
            this.files.push(file);
            let indexOfFIle = this.files.length - 1;

            this.$store.dispatch('UPLOAD_FILE', {form: formData, id: this.folder.id}).then( () => {
                this.sendStatusToChild(file, 'success', indexOfFIle);
            }).catch(() => {
                this.sendStatusToChild(file, 'catch', indexOfFIle);
            });
        },
        fileSizeWithinLimit(file) {
            return file.size <= this.fileSizeMax;
        },
        sendStatusToChild(file, status, index) {
            let data = {
                status: '',
                statusType: '',
                uploading: false
            };

            switch (status) {
            case 'success':
                data.statusType = 'success';
                data.status = 'mdi-check';
                data.color = 'text-success';
                break;
            case 'catch':
                data.statusType = 'danger';
                data.color = 'text-danger';
                break;
            default:
                break;
            }

            eventBus.$emit(`${file.name}-${index}`, data);
        },
        // reUpload(file) {
        //
        // }
    },
    components: {
        FileUpload,
        DisplayFile
    },
};
</script>
