import CommonService from '@services/common.service';
import { FETCH_COUNTRIES, SET_COUNTRIES } from '@store/common/actions';

const state = {
    countries: []
};

const mutations = {
    [SET_COUNTRIES](state, countries) {
        state.countries = countries;
    }
};

const actions = {
    [FETCH_COUNTRIES]({ commit, state }) {
        if (state.countries.length) {
            return state.countries;
        }

        return CommonService.getCountries().then(countries => {
            commit(SET_COUNTRIES, countries);
        });
    }
};

const getters = {
    countries: state => {
        return state.countries;
    },
    phoneCodes: state => {
        let phoneCodes = [];
        state.countries.map(country => {
            phoneCodes.push({ code: country.phone_code, abbr: country.code });
        });
        return phoneCodes;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
