<template>
    <div class="d-flex align-items-center" :title="displayFile.name">
        <div class="mr-2">
            <SvgIcon :icon="returnFileIcon('displayFile').img" :size="iconSize" :class="returnFileIcon('displayFile').color"/>
        </div>
        <div class="text-break mr-4">{{ truncate(displayFile.name, 26) }}</div>
        <div>{{ returnFileSize(displayFile.size) }}</div>
        <div v-if="!fileTooLarge" class="text-danger ml-2">{{ $t('File size is too big') }}</div>
        <span v-if="!uploading" class="font-weight-bold ml-2">
            <SvgIcon v-if="statusType === 'success'" :icon="status" size="22" class="text-success mb-1"
                     :style="{'stroke': '#a5c365', 'stroke-width': '1px'}"/>
            <template v-if="statusType === 'danger'">
                <span :class="color">{{ $t('Error while uploading!') }}</span>
            </template>
        </span>
        <div v-else class="spinner-border spinner-border-sm text-primary ml-2" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
import { truncate } from '@/filters';
import {eventBus} from '@/main';
import FileMixin from '@common/mixins/File.mixin';

export default {
    name: 'DisplayFile',
    mixins: [FileMixin],
    props: {
        displayFile: {
            types:[File, Object] //TODO can it be helped?
        },
        fileTooLarge: {
            type: Boolean,
            default: false
        },
        upload: {
            type: Boolean,
            default: true
        },
        index: null,
        iconSize: {
            type: Number,
            default: 27
        },
    },
    data() {
        return {
            status: '',
            color: '',
            statusType: '',
            uploading: true
        };
    },
    methods: {
        truncate, // TDOD: Vue3 remove
        reUpload() {
            this.$emit('reupload', this.displayFile);
        }
    },
    mounted() {
        if(!this.upload) {
            this.uploading = false;
        }
        eventBus.$on(`${this.displayFile.name}-${this.index}`, data => {
            this.status = data.status;
            this.color = data.color;
            this.statusType = data.statusType;
            this.uploading = data.uploading;
        });
    },
};
</script>
