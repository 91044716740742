import {
    GET_SURVEY,
    UPDATE_SURVEY_SETTINGS,
    UPDATE_SURVEY,
    DELETE_SURVEY,
    SET_SURVEY,
    STORE_QUESTION,
    ADD_QUESTION,
    UPDATE_QUESTION,
    SET_QUESTION,
    DELETE_QUESTION,
    REMOVE_QUESTION,
    STORE_QUESTION_OPTION,
    ADD_QUESTION_OPTION,
    UPDATE_QUESTION_OPTION,
    SET_QUESTION_OPTION,
    DELETE_QUESTION_OPTION,
    REMOVE_QUESTION_OPTION,
    REORDER_QUESTION,
    REORDER_QUESTION_OPTION,
    SET_SELECTED_QUESTION,
    SET_QUESTION_OPTIONS,
    ADD_SURVEY_ACL,
    REMOVE_SURVEY_ACL,
    GET_AVAILABLE_SURVEYS,
    SET_AVAILABLE_SURVEYS,
    STOP_ALL_SURVEY_LOADING,
    START_SURVEY_LOADING,
    STOP_SURVEY_LOADING,
    CREATE_QUESTION,
    SET_SURVEY_LOCK_DATA,
    SET_SURVEY_LOCK_DATA_EMPTY,
    SET_QUESTIONS,
} from './actions';
import SurveyService from '@services/survey.service';

const defaultState = () => {
    return {
        survey: null,
        selectedQuestion: null, //currently selected question
        availableSurveys: [],
        surveyLoading: 0,
    };
};

function checkForLock(res) {
    return res.locked;
}

const state = defaultState;

const mutations =  {
    [SET_SURVEY](state, data) {
        state.survey = data;
    },
    [ADD_QUESTION](state, question) {
        state.survey.questions.splice(question.order - 1, 0, question);
    },
    [SET_QUESTION](state, question) {
        let questionData = {
            ...state.selectedQuestion,
            ...question,
        };

        state.selectedQuestion = questionData;

        if(state.survey.questions !== undefined) {
            let activeQuestionIndex = state.survey.questions.findIndex(
                q => q.id === questionData.id
            );
            state.survey.questions.splice(activeQuestionIndex, 1, questionData);
        } else if(state.survey.question) {
            state.survey.question = questionData;
        }
    },
    [SET_QUESTIONS](state, data) {
        state.survey.questions = data;
    },
    [SET_SELECTED_QUESTION](state, question) {
        if (state.selectedQuestion && question != null) {
            let activeQuestionIndex = state.survey.questions.findIndex(
                q => q.id === state.selectedQuestion.id
            );
    
            state.survey.questions.splice(activeQuestionIndex, 1, state.selectedQuestion);
        }

        state.selectedQuestion = question;
    },
    [REMOVE_QUESTION](state, questionId) {
        let questionIndex = state.survey.questions.findIndex(
            q => q.id === questionId
        );

        state.survey.questions.splice(questionIndex, 1);
    },
    [ADD_QUESTION_OPTION](state, questionOption) {
        if (state.selectedQuestion?.id !== questionOption.question_id) {
            let questionIndex = state.survey.questions.findIndex(
                q => q.id === questionOption.question_id
            );

            state.survey.questions[questionIndex].options.push(questionOption);
        } else {
            state.selectedQuestion.options.push(questionOption);
        }
    },
    [SET_QUESTION_OPTION](state, questionOption) {
        let optionIndex = state.selectedQuestion.options.findIndex(
            o => o.id === questionOption.id
        );

        state.selectedQuestion.options.splice(optionIndex, 1, questionOption);
    },
    [REMOVE_QUESTION_OPTION](state, data) {
        let optionIndex = state.selectedQuestion.options.findIndex(
            o => o.id === data.option_id
        );

        state.selectedQuestion.options.splice(optionIndex, 1);
    },
    [SET_QUESTION_OPTIONS](state, data) {
        let optionIndex = state.selectedQuestion.options.findIndex(
            o => o.id === data.option_id
        );
        let option = state.selectedQuestion.options.splice(optionIndex, 1)[0];

        state.selectedQuestion.options.splice(data.order - 1, 0, option);
    },
    [SET_AVAILABLE_SURVEYS](state, data) {
        state.availableSurveys = data;
    },
    [STOP_ALL_SURVEY_LOADING](state) {
        state.surveyLoading = 0;
    },
    [START_SURVEY_LOADING](state) {
        state.surveyLoading++;
    },
    [STOP_SURVEY_LOADING](state) {
        state.surveyLoading--;
        if (state.surveyLoading < 0) {
            state.surveyLoading = 0;
        }
    },
    [SET_SURVEY_LOCK_DATA](state, res) {
        state.survey.locked = res.locked;
        state.survey.locked_at = res.locked_data.locked_at;
        state.survey.locked_by = res.locked_data.locked_by;
        state.survey.locked_owner = res.locked_data.locked_owner;
    },
    [SET_SURVEY_LOCK_DATA_EMPTY](state) {
        state.survey.locked = null;
        state.survey.locked_at = null;
        state.survey.locked_by = null;
        state.survey.locked_owner = null;
    },
};

const actions = {
    [GET_SURVEY]({commit, getters}, id) {
        return SurveyService.getSurvey(getters.activeKindergartenId, id).then(res => {
            commit(SET_SURVEY, res);
            commit(SET_SELECTED_QUESTION, null);
        });
    },
    [UPDATE_SURVEY]({commit, getters}, data) {
        return SurveyService.updateSurvey(getters.activeKindergartenId, data.survey_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_SURVEY, res);
            }
        });
    },
    [DELETE_SURVEY]({getters}, survey_id) {
        return SurveyService.deleteSurvey(getters.activeKindergartenId, survey_id).then(res => {
            return res;
        });
    },
    [UPDATE_SURVEY_SETTINGS]({commit, getters}, data) {
        return SurveyService.updateSurveySettings(getters.activeKindergartenId, data.survey_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_SURVEY, res);
            }
        });
    },
    async [CREATE_QUESTION]({dispatch}, data) {
        let questionRes = await dispatch(STORE_QUESTION, data.question);
        if(questionRes && questionRes !== 'locked') {
            if (data.isDuplicate) {
                await data.question.options.forEach(option => {
                    dispatch(STORE_QUESTION_OPTION, {
                        survey_id: data.question.survey_id,
                        question_id: questionRes.id,
                        label: option.label,
                        order: option.order
                    });
                });
            } else {
                await dispatch(STORE_QUESTION_OPTION, {
                    survey_id: data.question.survey_id,
                    question_id: questionRes.id,
                    label: data.option,
                    order: 1
                });
            }
        }

        return questionRes;
    },
    [STORE_QUESTION]({commit, getters}, data) {
        commit(START_SURVEY_LOADING);
        return SurveyService.addQuestion(getters.activeKindergartenId, data.survey_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(ADD_QUESTION, res);
                return res;
            }
        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [UPDATE_QUESTION]({commit, getters}, data) {
        commit(START_SURVEY_LOADING);
        return SurveyService.updateQuestion(getters.activeKindergartenId, data.survey_id, data.question_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_QUESTION, res);
            }
        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [DELETE_QUESTION]({commit, getters}, data) {
        commit(START_SURVEY_LOADING);
        return SurveyService.deleteQuestion(getters.activeKindergartenId, data.survey_id, data.question_id).then((res) => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_SELECTED_QUESTION, null);
                commit(REMOVE_QUESTION, data.question_id);
            }
        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [REORDER_QUESTION]({commit, getters}, data) {
        return SurveyService.reorderQuestion(getters.activeKindergartenId, data.data.survey_id, data.data.question_id, data.data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_QUESTIONS, data.questions);
            }
        });
    },
    [STORE_QUESTION_OPTION]({commit, getters}, data) {
        commit(START_SURVEY_LOADING);
        return SurveyService.addQuestionOption(getters.activeKindergartenId, data.survey_id, data.question_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(ADD_QUESTION_OPTION, res);
                return res;
            }

        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [UPDATE_QUESTION_OPTION]({commit, getters}, data) {
        commit(START_SURVEY_LOADING);
        return SurveyService.updateQuestionOption(getters.activeKindergartenId, data.survey_id, data.question_id, data.option_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_QUESTION_OPTION, res);
            }
        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [DELETE_QUESTION_OPTION]({commit, getters}, data) {
        commit(START_SURVEY_LOADING);
        return SurveyService.deleteQuestionOption(getters.activeKindergartenId, data.survey_id, data.question_id, data.option_id).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(REMOVE_QUESTION_OPTION, data);
            }
        }).finally(() => {
            commit(STOP_SURVEY_LOADING);
        });
    },
    [REORDER_QUESTION_OPTION]({commit, getters}, data) {
        return SurveyService.reorderQuestionOption(getters.activeKindergartenId, data.survey_id, data.question_id, data.option_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            } else {
                commit(SET_QUESTION_OPTIONS, data);
            }

        });
    },
    [ADD_SURVEY_ACL]({getters, commit}, data) {
        return SurveyService.addAcl(getters.activeKindergartenId, data.survey_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            }
        });
    },
    [REMOVE_SURVEY_ACL]({getters, commit}, data) {
        return SurveyService.removeAcl(getters.activeKindergartenId, data.survey_id, data).then(res => {
            if(checkForLock(res)) {
                commit(SET_SURVEY_LOCK_DATA, res);
                return 'locked';
            }
        });
    },
    [GET_AVAILABLE_SURVEYS]({getters, commit}, type) {
        return SurveyService.getAvailableSurveys(getters.activeKindergartenId, type).then(res => {
           commit(SET_AVAILABLE_SURVEYS, res);
        });
    }
};

const getters = {
    survey: state => state.survey,
    selectedQuestion: state => state.selectedQuestion,
    availableSurveys: state => state.availableSurveys,
    surveyLoading: state => state.surveyLoading,
};

export default {
    state,
    mutations,
    actions,
    getters
};