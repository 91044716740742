<template>
    <div class="position-relative" @mouseleave="checkClosePop()">
        <div v-if="visible && tools">
            <a
                v-if="folder.access === 'manage'" :id="id"
                tabindex="0"
                class="btn text-white bg-primary rounded-circle position-absolute e3-folder-edit position-relative p-1"
                :title="$t('Manage')"
                @click.prevent=""
                @click="keepActive = true">
                <SvgIcon icon="mdi-cog" class="text-white"/>
            </a>
            <b-popover v-if="folder.access === 'manage' && !isLoading" :target="id" triggers="focus" placement="topright" @hide="hidePop()">
                <div class="d-flex">
                    <RoundIconButton
                        class="mr-2"
                        :title="$t('Edit')"
                        @click="openModal"/>
                    <RoundIconButton
                        type="btn-danger"
                        :title="$t('Delete')"
                        @click="openDelModal">
                        <SvgIcon icon="mdi-trash-can" class="text-white"/>
                    </RoundIconButton>
                </div>
            </b-popover>
        </div>
        <div class="e3-folder-container d-flex flex-lg-column align-items-center" :title="folder.name" @mouseover="showVisible()">
            <router-link 
                v-click-outside="unHighlight"
                :to="{name: folder.type+'.folder', params: {'folder': folder.id}}"
                class="e3-folder-box d-flex align-items-center pb-1"
                :class="{'grid flex-column' : isGrid, 'flex-lg-column' : !isGrid, 'e3-new-index-folder': isFolderNew(folder.updated_at) && newCreated}">
                <FolderIcon :isGallery="isGallery" :isReadonly="isReadonly" :isGrid="isGrid"/>
                <div
                    class="d-flex align-items-center text-center text-dark text-break px-3 h-100 folder-name overflow-hidden pt-1"
                    :class="isGrid ? 'px-1' : 'px-lg-1'">
                    <span v-if="!searchValue" class="folder-name-clamp">
                        {{ folder.name }}
                    </span>
                    <span v-else :inner-html.prop="searchHighlight(folder.name, searchValue)" class="folder-name-clamp"/>
                </div>
            </router-link>
            <!--            <div class="e3-buttons-hide d-flex ml-4">-->
            <!--                <button-->
            <!--                    v-if="folder.access === 'manage'" class="btn mdi mdi-lead-pencil mdi-18px bg-primary p-1 mr-2"-->
            <!--                    @click="openModal"/>-->
            <!--                <ConfirmButton-->
            <!--                    v-if="folder.access === 'manage'"-->
            <!--                    icon-->
            <!--                    :msg="$t('Are you sure you want to delete the folder')"-->
            <!--                    :extra="folder.name"-->
            <!--                    confirmText="Delete"-->
            <!--                    color="danger"-->
            <!--                    @confirmed="deleteFolder"/>-->
            <!--            </div>-->
        </div>
        <b-modal :id="`delete-folder-${id}-${type}`" lazy static hide-footer>
            <template slot="modal-header">
                <ModalHeader :modalName="`delete-folder-${id}-${type}`">{{ $t('Delete '+ entity) }}</ModalHeader>
            </template>
            <div class="text-center font-weight-bold">
                <p>{{ $t('Are you sure you want to delete the ' + entity, {name: folder.name}) }}</p>
            </div>
            <div class="row justify-content-end pt-3 pr-3 mt-4">
                <button class="btn btn-secondary mr-2" @click="closeDelModal">
                    {{ $t('Cancel') }}
                </button>
                <button class="btn btn-danger" @click="deleteFolder">
                    {{ $t('Delete') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { eventBus } from '@/main';
import {searchHighlight} from '@/filters';
import FolderIcon from '@views/folder/components/FolderIcon.vue';

export default {
    name: 'FolderContainer',
    props: {
        folder: Object,
        id: String,
        tools: {
            type: Boolean,
            default: false
        },
        type: String,
        searchValue: String,
        isGrid: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            visible: false,
            keepActive: false,
            newCreated: false,
            isLoading: false,
        };
    },
    computed: {
        isGallery() {
            return this.folder.type === 'gallery';
        },
        isReadonly() {
            return this.folder.access === 'view';
        },
        entity() {
            return this.isGallery ? 'album' : 'folder';
        }
    },
    methods: {
        searchHighlight, // TODO: Vue3 remove
        deleteFolder() {
            this.isLoading = true;
            this.$store.dispatch('DELETE_FOLDER', this.folder.id).then(() => {
                this.$toastr.s(this.type === 'document' ? this.$t('Folder deleted') : this.$t('Album deleted'));
            }).finally(() => {
                this.isLoading = false;
            });
        },
        showVisible() {
            if (this.folder.access === 'manage') {
                this.visible = true;
            }
        },
        checkClosePop() {
            if (!this.keepActive) {
                this.visible = false;
            }
        },
        hidePop() {
            this.keepActive = false;
            this.visible = false;
        },
        openModal() {
            eventBus.$emit('show-index-edit-folder-modal', this.folder);
        },
        openDelModal() {
            this.$bvModal.show(`delete-folder-${this.id}-${this.type}`);
        },
        closeDelModal() {
            this.$bvModal.hide(`delete-folder-${this.id}-${this.type}`);
        },
        isFolderNew(updated_at) {
            let createdNow = this.$moment().diff(this.$moment(updated_at), 'seconds') === 0;
            if (createdNow) {
                this.newCreated = true;
            }
            return createdNow;
        },
        unHighlight() {
            if (this.newCreated) {
                this.newCreated = false;
            }
        }
    },
    components: {
        FolderIcon
    }
};
</script>

<style scoped lang="scss">
    .e3-folder-icon {
        height: 65px;
        &::before {
            height: 65px;
        }
    }

    .e3-folder-edit {
        top: 0.25em;
        right: 0.25em;
        display: flex;
        align-items: center;
        height: 28px;
        width: 28px;
    }

    .e3-folder-box:hover{
        text-decoration: none;
    }
    .e3-folder-container:hover {
        .text-center.text-dark.text-break {
            text-decoration: underline;
            color: #f79e80 !important;
        }
    }
    .folder-name {
        max-height: 52px;
    }

</style>
