import store from '@/store';
const DocumentsChildProfileFolder = () => import(/* webpackChunkName: "documents-module" */ '@/views/document/folder/DocumentChildProfileFolder');
const DocumentIndex = () => import(/* webpackChunkName: "documents-module" */ '@views/document/views/DocumentIndex');
const DocumentFolder = () => import(/* webpackChunkName: "documents-module" */ '@views/document/folder/DocumentFolder');
const DocumentDiaryIndex = () => import(/* webpackChunkName: "documents-module" */ '@views/document/views/DocumentDiaryIndex');
const DocumentDiaryFolder = () => import(/* webpackChunkName: "documents-module" */ '@views/document/views/DocumentDiaryFolder');

export default [
    {
        path: '/documents',
        name: 'document.index',
        components: { base: DocumentIndex },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessDocumentsIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
    {
        path: '/documents/child-profile/:child_id?',
        name: 'document.builtin.child-profile',
        components: {base: DocumentsChildProfileFolder},
        beforeEnter: (to, from, next) => {
            const canAccessBuiltin =
                store.getters.canAccessChildFilesEmployee ||
                store.getters.canAccessChildFilesGuardian;
            if (store.getters.canAccessDocumentsIndex && canAccessBuiltin) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        }
    },
    {
        path: '/documents/diary',
        name: 'document.builtin.diary.index',
        components: {base: DocumentDiaryIndex},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessDocumentsIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        }
    },
    {
        path: '/documents/diary/:course_id',
        name: 'document.builtin.diary',
        components: {base: DocumentDiaryFolder},
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessDocumentsIndex) {
                next();
            } else {
                next({path: '/access-restricted'});
            }
        }
    },
    {
        path: '/documents/:folder',
        name: 'document.folder',
        components: { base: DocumentFolder },
        beforeEnter: (to, from, next) => {
            if (store.getters.canAccessDocumentsIndex) {
                next();
            } else {
                next({ path: '/access-restricted' });
            }
        }
    },
];
