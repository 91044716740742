<template>
    <div class="e3-tooltip-values" :class="small ? 'e3-small-tooltip' : 'e3-regular-tooltip'">
        <div :id="id" class="text-primary d-flex tooltip-icon">
            <slot name="icon">
                <svg viewBox="0 0 24 24">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                </svg>
            </slot>
        </div>
        <b-tooltip :custom-class="customClass" :target="id" :placement="rtl ? 'left' : 'right'">
            <slot>Empty</slot>
        </b-tooltip>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    props: {
        id: String,
        small: Boolean,
        customClass: String,
        inline: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['rtl']),
    },
};
</script>

<style scoped lang="scss">
.e3-small-tooltip {
    .tooltip-icon {
        svg {
            width: 16px;
            height: 16px;
        }
    }
}
.e3-regular-tooltip {
    .tooltip-icon {
        svg {
            width: 22px;
            height: 22px;
        }
    }
}
.e3-tooltip-values {
    align-items: center;
    justify-content: center;
    display: inline-flex;
    background-color: transparent;
    > svg {
        &:focus {
            outline: none;
        }
    }
}
.b-tooltip{
    opacity: 1 !important;
    max-width: 600px;
    .tooltip-inner {
        background-color: #292929;
    }
    .arrow::before{
        border-right-color: #292929 !important;
    }
}
@media only screen and (max-width: 1024px) {
    .b-tooltip{
        max-width: 60vw;
    }
}

</style>
