<template>
    <tr :id="`nocollaps_${entity.id}_${indexEntity}`">
        <td v-if="isNumberable">
            {{ entities.current_page && entities.from ? (entities.from + parseInt(indexEntity)) : parseInt(indexEntity) + 1 }}
        </td>
        <td v-for="field in fields" :key="field.key" :class="field.tdClass">
            <slot v-if="!!slots[field.key]" :name="field.key" :item="entity" :row-index="indexEntity" :table-index="tableIndex"/>
            <span v-else>{{ filterIfNeeded(entityField(entity, field.key), field.filter) }}</span>
        </td>
    </tr>
</template>

<script>
import filters from '@/filters';

export default {
    name: 'ETableRow',
    props: {
        entities: [Object, Array],
        entity: Object,
        indexEntity: [Number, String],
        tableIndex: [Number, String],
        opened: Array,
        fields: Array,
        slots: Object,
        isNumberable: Boolean
    },
    methods: {
        filterIfNeeded(entity, filter) {
            if(filter) {
                return filters[filter](entity);
            }
            return entity;
        },
        entityField(entity, key) {
            if (key.includes('.')) {
                const keys = key.split('.');
                if (keys.length > 1) {
                    let obj = entity;
                    for (let i = 0; i < keys.length; i++) {
                        obj = obj[keys[i]];
                    }
                    return obj;
                }
            }
            return entity[key];
        }
    }
};
</script>
