import http from '@http';

class UserService {
    constructor() {
        if (UserService.instance) {
            return UserService.instance;
        }
        UserService.instance = this;
    }

    getUsersBy(searchString) {
        return http.get(`/api/admin/users/search?search=${searchString}`).then(res => res.data);
    }

    getUser() {
        return http.get('/api/common/user').then(res => res.data);
    }

    getUserData(platform) {
        return http.get('/api/common/init', {params: {platform}}).then(res => res.data);
    }

    getAdminPermissions() {
        return http.get('/api/admin/user-permissions').then(res => res.data);
    }

    getSupplierRepPermissions(supplierId) {
        return http.get(`/api/suppliers/${supplierId}/permissions`).then(res => res.data);
    }

    getUserKindergartenPermissions(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/user/permissions`).then(res => res.data);
    }

    getUserOrganizationPermissions(organizationId) {
        return http.get(`/api/organizations/${organizationId}/user/permissions`).then(res => res.data);
    }

    getUserProfile() {
        return http.get('/api/common/user/profile').then(res => res.data);
    }

    getUserKindergartenMenu(id) {
        return http.get(`/api/kindergartens/${id}/menu`).then(res => res.data);
    }

    getUserOrganizationMenu(id) {
        return http.get(`/api/organizations/${id}/menu`).then(res => res.data);
    }

    getUserSupplierMenu(id) {
        return http.get(`/api/suppliers/${id}/menu`).then(res => res.data);
    }

    getUserMenu() {
        return http.get('/api/common/menu').then(res => res.data);
    }

    updateUser(data) {
        return http.put('/api/common/user/update', data).then(res => res.data);
    }

    updateUserName(data) {
        return http.put('/api/common/user/name', data).then(res => res.data);
    }

    getUserTargetGroups(kindergarten_id) {
        return http.get(`/api/kindergartens/${kindergarten_id}/target-groups/available`)
            .then(res => res.data);
    }

    requestEmailChange(userId, email) {
        return http.post(`/api/common/user/${userId}/email-change`, email).then(res => res.data);
    }

    //Find if user with email exists and return user id and name
    checkForUser(kindergartenId, email) {
        return http.get(`/api/kindergartens/${kindergartenId}/user/email`, {params: { email: email}}).then(res => res.data);
    }

    getUserDashboard(params) {
        return http.get('/api/common/user/dashboard', params).then(res => res.data);
    }

    deactivateUser() {
        return http.put('/api/common/user/delete').then(res => res.data);
    }

    confirmPrivacy() {
        return http.put('/api/common/confirm-privacy').then(res => res.data);
    }

    userTokens() {
        return http.get('/api/common/user/tokens').then(res => res.data);
    }

    revokeToken(tokenId) {
        return http.post(`/api/common/user/token/${tokenId}/revoke`).then(res => res.data);
    }

    getUserSettings() {
        return http.get('/api/common/user/settings').then(res => res.data);
    }

    getUserEmployees() {
        return http.get('/api/common/user/employees').then(res => res.data);
    }

    activateSupplier(data) {
        return http.post('/api/common/user/activate-supplier', data)
            .then(res => res.data)
            .catch(err => {
                throw err;
            });
    }

    setUserLanguage(locale) {
        return http.put(`/api/common/user/language/${locale}`).then(res => res.data);
    }

    getUserCart() {
        return http.get('/api/common/user/cart').then(res => res.data);
    }

    userOauthClients() {
        return http.get('/api/common/user/oauth-clients').then(res => res.data);
    }

    revokeOauthClient(clientId) {
        return http.post(`/api/common/user/oauth-client/${clientId}/revoke`).then(res => res.data);
    }

    getPdfSettings(query) {
        return http.get('/api/common/user/settings-pdf', {params: query}).then(res => res.data);
    }

    setPdfSettings(data) {
        return http.put('/api/common/user/settings-pdf', data).then(res => res.data);
    }
}

export default new UserService();
