import store from '@/store';

const Weekplans = () => import(/* webpackChunkName: "plan-module" */ '@views/plan/week/Weekplans');
const Plan = () => import(/* webpackChunkName: "plan-module" */ '@views/plan/components/Plan');
const Overview = () => import(/* webpackChunkName: "plan-module" */ '@views/plan/components/Overview');
const Settings = () => import(/* webpackChunkName: "plan-module" */ '@views/plan/components/Settings');
const Templates = () => import(/* webpackChunkName: "plan-module" */ '@views/plan/components/Templates');

export default [
    {
        path: '/plans/week',
        components: { base: Weekplans },
        children: [
            {
                path: '',
                name: 'plans.wplan',
                components: { weekplan: Plan },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessWeekPlan) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'overview',
                name: 'plans.wplan.overview',
                components: { weekplan: Overview },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessOverView) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'settings',
                name: 'plans.wplan.settings',
                components: { weekplan: Settings },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessPlansSettings) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            },
            {
                path: 'templates',
                name: 'plans.wplan.templates',
                components: { weekplan: Templates },
                beforeEnter: (to, from, next) => {
                    if (store.getters.canAccessTemplates) {
                        next();
                    } else {
                        next({ path: '/access-restricted' });
                    }
                }
            }
        ]
    }
];
