export const GET_TEMPLATES = 'GET_TEMPLATES';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const STORE_TEMPLATE = 'STORE_TEMPLATE';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_ARCHIVE_STATUS = 'UPDATE_TEMPLATE_ARCHIVE_STATUS';
export const GET_USABLE_MAP_TEMPLATES = 'GET_USABLE_MAP_TEMPLATES';
export const UPDATE_MAP_TEMPLATE_CONFIRM_STATUS = 'UPDATE_MAP_TEMPLATE_CONFIRM_STATUS';
export const UPDATE_MAP_TEMPLATE_INTEGRATIONS = 'UPDATE_MAP_TEMPLATE_INTEGRATIONS';
export const SET_TEMPLATE_INTEGRATIONS = 'SET_TEMPLATE_INTEGRATIONS';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
