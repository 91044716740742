import {
    RESET_ORGANIZATION_STATE,
    SET_ORGANIZATION,
    GET_ORGANIZATION_KINDERGARTENS,
    SET_ORGANIZATION_KINDERGARTENS
} from './actions';
import OrganizationService from '@services/organization.service';
const getDefaultState = () => {
    return {
        organization : {},
        kindergartens: [], // for e-table
    };
};

const state = getDefaultState();

const mutations = {
    [RESET_ORGANIZATION_STATE](state) {
        Object.assign(state, getDefaultState());
        localStorage.removeItem('organization_id');
    },
    [SET_ORGANIZATION](state, organization) {
        state.organization = organization;
        localStorage.setItem('organization_id', organization.id);
        localStorage.removeItem('kindergarten_id');
        localStorage.removeItem('supplier_id');
    },
    [SET_ORGANIZATION_KINDERGARTENS](state, kindergartens) {
        state.kindergartens = kindergartens;
    }
};

const actions = {
    [GET_ORGANIZATION_KINDERGARTENS]({commit, getters}) {
        return OrganizationService.getKindergartens(getters.activeOrganizationId).then(res => {
            commit(SET_ORGANIZATION_KINDERGARTENS, res);
        });
    }
};

const getters = {
    organizationName: state => state.organization?.name,
    activeOrganizationId: state => state.organization?.id,
    orgKindergartens: state => state.kindergartens
};

export default {
    state,
    mutations,
    actions,
    getters
};