export const RESET_WORK_SCHEDULE_STORE = 'RESET_WORK_SCHEDULE_STORE';
export const GET_WORK_SCHEDULES = 'GET_WORK_SCHEDULES';
export const SET_WORK_SCHEDULES = 'SET_WORK_SCHEDULES';
export const GET_WORK_SCHEDULE_TYPES = 'GET_WORK_SCHEDULE_TYPES';
export const SET_WORK_SCHEDULE_TYPES = 'SET_WORK_SCHEDULE_TYPES';
export const SAVE_WORK_SCHEDULE_TYPE = 'SAVE_WORK_SCHEDULE_TYPE';
export const ADD_WORK_SCHEDULE_TYPE = 'ADD_WORK_SCHEDULE_TYPE';
export const UPDATE_WORK_SCHEDULE_TYPE = 'UPDATE_WORK_SCHEDULE_TYPE';
export const GET_WORK_SCHEDULE_SETTINGS = 'GET_WORK_SCHEDULE_SETTINGS';
export const SET_WORK_SCHEDULE_SETTINGS = 'SET_WORK_SCHEDULE_SETTINGS';
export const UPDATE_WORK_SCHEDULE_SETTINGS = 'UPDATE_WORK_SCHEDULE_SETTINGS';
export const INSERT_WORK_SCHEDULE = 'INSERT_WORK_SCHEDULE';
export const ADD_WORK_SCHEDULE = 'ADD_WORK_SCHEDULE';
export const SET_WORK_SCHEDULE_ROLES = 'SET_WORK_SCHEDULE_ROLES';
export const SELECT_WORK_SCHEDULE_EVENT = 'SELECT_WORK_SCHEDULE_EVENT';
export const SET_WORK_SCHEDULE_LOCK_DATE = 'SET_WORK_SCHEDULE_LOCK_DATE';
export const UPDATE_WORK_SCHEDULE = 'UPDATE_WORK_SCHEDULE';
export const REMOVE_SCHEDULES_BY_ID = 'REMOVE_SCHEDULES_BY_ID';
export const DELETE_WORK_SCHEDULE = 'DELETE_WORK_SCHEDULE';
export const REMOVE_SCHEDULE = 'REMOVE_SCHEDULE';
export const REMOVE_SCHEDULES_STARTING_FROM = 'REMOVE_SCHEDULES_STARTING_FROM';
export const SET_WORK_SCHEDULE_HOLIDAYS = 'SET_WORK_SCHEDULE_HOLIDAYS';