import http from '@http';

class TemplateService {
    constructor() {
        if (TemplateService.instance) {
            return TemplateService.instance;
        }

        TemplateService.instance = this;
    }

    getTemplates() {
        return http.get('/api/admin/templates').then(res => res.data);
    }

    getAdminTemplate(id) {
        return http.get(`/api/admin/templates/${id}`).then(res => res.data);
    }


    addTemplate(data) {
        return http.post('/api/admin/templates', data).then(res => res.data);
    }
    editTemplate(data) {
        return http.put(`/api/admin/templates/${data.id}`, data).then(res => res.data);
    }

    getKindergartenTemplates(kindergartenId, type) {
        return http.get(`/api/kindergartens/${kindergartenId}/templates`, { params: { type: type }})
            .then(res => res.data);
    }

    getUsableMapTemplates(kindergartenId) {
        return http.get(`/api/kindergartens/${kindergartenId}/templates/maps`).then(res => res.data);
    }

    addKindergartenTemplate(kindergartenId, name) {
        return http.post(`/api/kindergartens/${kindergartenId}/templates/store`, name).then(res => res.data);
    }

    updateKindergartenTemplate(kindergartenId, templateId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/templates/${templateId}/update`, data)
            .then(res => res.data);
    }

    updateTemplateArchiveStatus(kindergartenId, templateId, status) {
        return http.put(`/api/kindergartens/${kindergartenId}/templates/${templateId}/archive`, status)
            .then(res => res.data);
    }

    getFilledFields(kindergartenId, templateId) {
        return http.get(`/api/kindergartens/${kindergartenId}/templates/${templateId}/filled`).then(res => res.data);
    }

    //unused for now
    getMapTemplateIntegrations(kindergartenId, templateId) {
        return http.get(`/api/kindergartens/${kindergartenId}/templates/${templateId}/integrations`).then(res => res.data);
    }

    updateTemplateIntegrations(kindergartenId, templateId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/templates/${templateId}/integrations`, data).then(res => res.data);
    }

    updateMapTemplateConfirmability(kindergartenId, templateId, data) {
        return http.put(`/api/kindergartens/${kindergartenId}/templates/${templateId}/confirmable`, data)
            .then(res => res.data);
    }

    deleteTemplate(kindergartenId, templateId) {
        return http.delete(`/api/kindergartens/${kindergartenId}/templates/${templateId}`).then(res => res.data);
    }
}
export default new TemplateService();
